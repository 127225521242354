import { createReducer } from 'redux-act';
import initialState from './initialState';
import { reducer as actions } from './actions';
import { reducer as setMissingFieldsErrorMessages } from './setMissingFieldsErrorMessages';
import { reducer as setMissingFieldsBody } from './setMissingFieldsBody';
import { reducer as clearMissingFieldsErrorMessages } from './clearMissingFieldsErrorMessages';

const reducer = createReducer(
  {
    ...actions,
    ...setMissingFieldsErrorMessages,
    ...setMissingFieldsBody,
    ...clearMissingFieldsErrorMessages,
  },
  initialState,
);

export default reducer;
