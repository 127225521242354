import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as patientActions from '../../redux/actions/patient.actions';
import { mrnSearchEnabled } from '../../flags/display.flag';
import PatientSearchFrame from './PatientSearch.frame';

const PatientSearchContainer = props => props.showPatientModal && <PatientSearchFrame {...props} />;
PatientSearchContainer.propTypes = {
  showPatientModal: PropTypes.bool.isRequired,
};
function mapStateToProps(state) {
  return {
    patients: state.patient.patients,
    showPatientModal: state.patient.showPatientModal,
    showMRNFields: mrnSearchEnabled(state),
    showSSN: !mrnSearchEnabled(state),
  };
}

export default connect(mapStateToProps, { ...patientActions })(PatientSearchContainer);
