import createMiddleware from '../helper';
import * as mapActions from '../../actions/map.actions';
import * as infoboxActions from '../../actions/infobox.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as patientActions from '../../actions/patient.actions';
import * as requestsActions from '../../actions/requests.actions';
import * as locationActions from '../../actions/location.actions';
import * as chiefComplaintActions from '../../actions/chiefComplaint.actions';
import { chiefComplaintNotAllowed, disabledByPcrStatus } from '../../../flags/prebilling.flag';
import { USER_TYPES } from '../../../enums';

const unsetBillerVerifiedBeforeNextAction =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    const types = [
      mapActions.setMapToWaypoint.match(action),
      mapActions.setMapFromWaypoint.match(action),
      mapActions.setMapPickupLocationByAgency.match(action),
      mapActions.setMapDestinationLocationByAgency.match(action),
    ];

    if (!getState().formFlow.loadRequestInProgress && action && types.includes(action.type)) {
      if (getState().requests.isBillerVerified && action.payload.typeof !== 'facility') {
        dispatch(infoboxActions.billerVerifiedChange(false));
      }
    }

    next(action);
  };

const unsetBillerVerifiedOnPayerChangeMdl =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const { user } = getState();

    if (infoboxActions.setPayerInfo.match(action) && user.model.userType === USER_TYPES.REQUESTER) {
      dispatch(infoboxActions.billerVerifiedChange(false));
    }

    next(action);
  };

const unsetBillerVerifiedAfterNextAction =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    const types = [
      mapActions.setResidence.match(action),
      mapActions.clearPickupPlace.match(action),
      mapActions.clearDestinationPlace.match(action),
      chiefComplaintActions.setChiefComplaint.match(action),
      patientActions.setPatientInfo.match(action),
    ];

    const chiefComplaintSetToOther = state =>
      chiefComplaintActions.setChiefComplaint.match(action) && chiefComplaintNotAllowed(state);

    const patientInfo =
      patientActions.setPatientInfo.match(action) &&
      getState().agency.selectedAgency &&
      getState().agency.selectedAgency.patientAddressRequired &&
      !Object.getOwnPropertyDescriptor(action.payload, 'address2');

    if (!getState().formFlow.loadRequestInProgress && action && types.includes(action.type)) {
      if (
        getState().requests.isBillerVerified &&
        !disabledByPcrStatus(getState()) &&
        (patientInfo || chiefComplaintSetToOther(getState()))
      ) {
        dispatch(infoboxActions.billerVerifiedChange(false));
      }
    }

    if (
      mapActions.setResidence.match(action) &&
      getState().requests.request.id &&
      !getState().formFlow.loadRequestInProgress
    ) {
      dispatch(mapActions.asyncUpdateResidence());
      dispatch(formFlowActions.autoSaveInProgress(true));
    }
  };

const setIsBillerVerified = createMiddleware(infoboxActions.billerVerifiedChange, ({ payload, getState, dispatch }) => {
  if (getState().requests.request.id && !getState().formFlow.loadRequestInProgress) {
    dispatch(
      requestsActions.setIsBillerVerified({
        isBillerVerified: payload,
        billerVerifiedBy: getState().user.model.fullName,
      }),
    );
    dispatch(infoboxActions.asyncAutoSaveBillerVerified());
    dispatch(locationActions.clearSearchResults());
    dispatch(formFlowActions.autoSaveInProgress(true));
  }
});

const middlewares = [
  unsetBillerVerifiedBeforeNextAction,
  unsetBillerVerifiedAfterNextAction,
  setIsBillerVerified,
  unsetBillerVerifiedOnPayerChangeMdl,
];

export default middlewares;
