import React from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import ConfirmationDialog from 'components/common/ConfirmationDialog';

const StyledContainer = styled.div`
  && {
    margin: 15px 0;
  }
`;

const LeftStyledButton = styled(Button)`
  && {
    text-transform: none;
    border: 1px solid #004053;
    padding: 2px 13px 0px 10px;
    border-radius: 6px 0 0 6px;
    opacity: 0.8;
    height: 36px;
    color: #004053;
    font-size: 14px;
    &:hover {
      opacity: 1;
    }
    &.active {
      color: white;
      background-color: #004053;
    }
    &.disabled {
      color: #999;
      background-color: #ccc;
    }
    &.inactive {
      border: 1px solid #ccc;
    }
  }
`;

const RightStyledButton = styled(Button)`
  && {
    text-transform: none;
    padding: 2px 13px 0px 10px;
    border: 1px solid #004053;
    border-radius: 0 6px 6px 0;
    opacity: 0.8;
    height: 36px;
    font-size: 14px;
    color: #004053;
    &:hover {
      opacity: 1;
    }
    &.active {
      color: white;
      background-color: #004053;
    }
    &.disabled {
      color: #000;
      background-color: #ccc;
      border: 1px solid #ccc;
    }
    &.inactive {
      border: 1px solid #000;
      color: #000;
      opacity: 0.26;
    }
  }
`;

const TripTypeToggle = ({ change, isNonTransport, disabled, openDialog, onConfirm, history }) => {
  return (
    <StyledContainer>
      <LeftStyledButton
        onClick={() => change({ path: '/', nonTransport: false }, history)}
        style={{
          width: '50%',
        }}
        disabled={disabled || null}
        className={cx({
          active: !isNonTransport,
          disabled: disabled && !isNonTransport,
          inactive: disabled,
        })}
      >
        Medical Transport
      </LeftStyledButton>
      <RightStyledButton
        onClick={() => change({ path: '/mobile-health', nonTransport: true }, history)}
        style={{
          width: '50%',
        }}
        disabled={disabled || null}
        className={cx({
          active: isNonTransport,
          disabled: disabled && isNonTransport,
          inactive: disabled,
        })}
      >
        Mobile Health Service
      </RightStyledButton>
      <ConfirmationDialog
        open={openDialog}
        onClose={confirm => onConfirm(confirm, history)}
        title="Changing Request Type"
        okText="Yes"
        cancelText="No"
        cancel
      >
        <p>Are you sure you want to change request type?</p>
      </ConfirmationDialog>
    </StyledContainer>
  );
};

export default withRouter(TripTypeToggle);
