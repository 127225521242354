import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'lodash';
import MedicalNecessityStepper from './MedicalNecessityStepper.frame';
import { openMedicalNecessityModal } from './redux/openMedicalNecessityModal';
import { setExpandPayer } from './redux/setExpandPayer';
import { setExpandServiceLevelSection } from './redux/setExpandServiceLevelSection';
import { populatePayer } from './redux/actions';
import store from '../../redux/store/configureStore';
import { resetETA, clickGetETA } from '../../redux/actions/eta.actions';
import { turndownETA } from '../../redux/actions/turndown.actions';
import { requireMedicareSubQuestions } from '../../flags/mnq.flag';

class MedicalNecessityStepperContainer extends React.Component {
  finished = (status, request) => {
    store.dispatch(openMedicalNecessityModal(false));
    switch (status) {
      case 'OK':
        return store.dispatch(resetETA());
      case 'Turndown':
        return request.isWillCallStatus
          ? store.dispatch(resetETA())
          : store.dispatch(turndownETA(window.ambulnzConfig.cancelReasonType.PATIENT_TRANSPORTED_BY_ANOTHER_AGENCY));
      case 'New Request':
        return window.location.reload();
      default:
        return store.dispatch(clickGetETA());
    }
  };

  populatePayer = payer => {
    if (
      !isEmpty(payer) &&
      !isEmpty(this.props.coverages) &&
      !this.props.coverages[payer.coordinationTypeId].payerName
    ) {
      store.dispatch(populatePayer(payer));
    }
  };

  render() {
    return <MedicalNecessityStepper finished={this.finished} populatePayer={this.populatePayer} {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    open: state.medicalNecessity.open,
    requestingAgencyPayer: state.agency.selectedAgency,
    coverages: state.infobox.coverages,
    pickupTime: moment(state.eta.requestedArrivalTime).format('MM/DD'),
    requireMedicareQuestions: requireMedicareSubQuestions(state),
    admittanceDate: moment(state.eta.requestedArrivalTime).subtract(100, 'days').format('MM/DD/YYYY'),
    request: state.requests.request,
  };
}

MedicalNecessityStepperContainer.propTypes = {
  coverages: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]).isRequired,
};
export default connect(mapStateToProps, { openMedicalNecessityModal, setExpandPayer, setExpandServiceLevelSection })(
  MedicalNecessityStepperContainer,
);
