import React from 'react';

export const newETArequired = {
  title: 'New ETA Required',
  body: 'Editing this field will require a new ETA to be requested.',
};

export const newETArequest = {
  title: 'New ETA Required',
  body: 'Your current ETA will NOT be lost until after you accept a new ETA.',
};

export const newETArequiredForProviderTrip = {
  title: 'Update Pick Up Time',
  body: 'This trip will be unassigned from the provider and will require a new ETA to be requested.',
};

export const cannotUpdateZollProviderTripDate = {
  title: 'Edit Error',
  body: 'You cannot change the date on a Zoll request.\nIf you need to change the date, please cancel this request and create a new one.',
};

export const etaRelevantInformation = (
  <span>
    <span style={{ color: 'red' }}>Changes have not been saved!</span> Current ETA will REMAIN until a new ETA is
    accepted.
  </span>
);

export const tripScheduled = <span>Your Trip is now Scheduled</span>;
export const requesterDetailsSaved = 'Requester Details Saved';
export const coverageDetailsSaved = 'Coverage Details Saved';
export const patientConditionSaved = 'Patient Condition Saved';
export const notesSaved = 'Notes Saved';
export const missingPayerMessage = 'At least one Payer must be active.';
export const missingPatientAddressMessage = 'Patient Address must be specified.';
export const missingPatientName = 'Patient information should be specified';
export const missingLocationMessage = 'Both addresses must either be a Facility or a Residence.';
export const zollMsg = 'Cannot unset Prebiller Verified because trip was exported to Zoll.';
export const missingChiefComplaintMessage = 'Chief Complaint must be selected.';
export const chiefComplaintNotAllowedMessage = 'Selected Chief Complaint does not allow verification.';
export const payerNotAllowedMessage = 'Unmatched external payers cannot be used';
export const changesNotSaved = 'Request must be saved first';
export const missingPatientWeightHeight = 'Patient weight and height must have valid values';
