import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createEpicMiddleware } from 'redux-observable';
import createRootReducer from '../reducers';
import rootEpic from '../epics';
import rootMiddleware from '../middleware';

export const history = createBrowserHistory({ basename: '/requester' });

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(...rootMiddleware, epicMiddleware),
});

epicMiddleware.run(rootEpic);

export default store;
