import { createAction } from '@reduxjs/toolkit';

export const addAttachment = createAction('[PatientDocuments] addAttachment');
export const addAttachmentSuccess = createAction('[PatientDocuments] addAttachmentSuccess');

export const deleteAttachment = createAction('[PatientDocuments] deleteAttachment');
export const deleteAttachmentSuccess = createAction('[PatientDocuments] deleteAttachmentSuccess');

export const createAttachments = createAction('[PatientDocuments] createAttachments');

export const loadAttachmentTypes = createAction('[PatientDocuments] loadAttachmentTypes');
export const loadAttachmentTypesSuccess = createAction('[PatientDocuments] loadAttachmentTypesSuccess');
export const loadSignatureActorTypesSuccess = createAction('[PatientDocuments] loadSignatureActorTypesSuccess');

export const removeAttachment = createAction('[PatientDocuments] removeAttachment');

export const loadAttachments = createAction('[async] loadAttachments');
export const loadAttachmentsSuccess = createAction('[PatientDocuments] loadAttachmentsSuccess');
