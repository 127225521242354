import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 16 18" {...props}>
      <path
        d="M8.3,2.4L2,8.7c-1.9,1.9-1.9,5,0,6.9l0,0c1.9,1.9,5,1.9,6.9,0l6.7-6.7l-2.3-2.3L13.9,6c1.3-1.3,1.3-3.4,0-4.6
	l0,0c-1.3-1.3-3.4-1.3-4.6,0l-6,6"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M5.4,16.6c-1.1,0-2.3-0.4-3.1-1.3C1.5,14.4,1,13.3,1,12.1S1.5,9.9,2.3,9l7.3-7.3c1.1-1.1,2.9-1.1,3.9,0
	c0.5,0.5,0.8,1.2,0.8,2c0,0.7-0.3,1.4-0.8,2l-8.2,8.2l-0.7-0.7L12.8,5c0.3-0.3,0.5-0.8,0.5-1.3c0-0.5-0.2-0.9-0.5-1.3
	c-0.7-0.7-1.8-0.7-2.5,0L3,9.7c-0.6,0.6-1,1.5-1,2.4s0.4,1.8,1,2.4c1.3,1.3,3.5,1.3,4.8,0l6.7-6.7l0.7,0.7l-6.7,6.7
	C7.7,16.1,6.6,16.6,5.4,16.6z"
      />
      <path
        style={{ fill: '#014053' }}
        d="M7,13.7c-0.4,0.4-1,0.6-1.5,0.6s-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5l5.5-5.5
	l0.7,0.7l-5.5,5.5c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8c0.4,0.4,1.2,0.4,1.6,0l7.3-7.3c1.1-1.1,1.1-2.8,0-3.9c-1-1-2.9-1-3.9,0
	L2.3,9C1.5,9.9,1,11,1,12.1s0.5,2.3,1.3,3.1c0.8,0.8,1.9,1.3,3.1,1.3s2.3-0.5,3.1-1.3l6.7-6.7L16,9.3L9.3,16c-1,1-2.4,1.6-3.8,1.6
	S2.6,17,1.6,16c-1-1-1.6-2.4-1.6-3.8s0.6-2.8,1.6-3.8l7.3-7.3c1.4-1.4,3.9-1.4,5.3,0c1.5,1.5,1.5,3.9,0,5.3L7,13.7z"
      />
    </svg>
  );
}
