import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 14 18" {...props}>
      <path
        style={{ fill: '#919699' }}
        d="M7,18l-0.4-0.5c-0.2-0.2-3.9-5.2-4.7-6.6C1.4,10,0.4,8.1,0.4,6.6C0.4,3,3.4,0,7,0c3.6,0,6.6,3,6.6,6.6
	    c0,1.8-1.4,4.2-1.5,4.3c-0.9,1.4-4.6,6.4-4.7,6.6L7,18z M7,1C3.9,1,1.4,3.5,1.4,6.6c0,1.2,0.8,2.9,1.3,3.8C3.4,11.5,6,15,7,16.3
	    c1-1.4,3.6-4.9,4.3-6c0.5-0.9,1.3-2.6,1.3-3.8C12.6,3.5,10.1,1,7,1z"
      />
      <path
        style={{ fill: '#919699' }}
        d="M7,9.1C5.6,9.1,4.4,8,4.4,6.6C4.4,5.2,5.6,4,7,4s2.6,1.2,2.6,2.6C9.6,8,8.4,9.1,7,9.1z M7,5
	    C6.1,5,5.4,5.7,5.4,6.6S6.1,8.1,7,8.1s1.6-0.7,1.6-1.6S7.9,5,7,5z"
      />
    </svg>
  );
}
