import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import StyledFrame from './BillerVerified.frame';
import * as infoboxActions from '../../../../redux/actions/infobox.actions';
import { prebillerVerifiedDisabled } from '../../../../flags/prebilling.flag';
import getBillerVerifiedTooltip from '../../../../parsers/tooltip.parser';
import { agencyRequiresCoverage } from 'flags/valid.flag';

const BillerVerifiedContainer = props => <StyledFrame {...props} />;

function mapStateToProps(state) {
  return {
    isBillerVerified: state.requests.isBillerVerified,
    prebillerVerifiedDisabled: prebillerVerifiedDisabled(state),
    isBillerVerifiedTooltip: getBillerVerifiedTooltip(state),
    billerVerifiedAt: moment(state.requests.billerVerifiedAt).format('MM/DD'),
    billerVerifiedBy: state.requests.billerVerifiedBy,
    loadedRequest: state.formFlow.loadedRequest,
    coverageRequired: agencyRequiresCoverage(state),
  };
}

export default connect(mapStateToProps, {
  ...infoboxActions,
})(BillerVerifiedContainer);
