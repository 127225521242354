import * as etaActions from '../../actions/eta.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as requestsActions from '../../actions/requests.actions';
import * as pickupTimeChangeActions from '../../actions/pickupTimeChange.actions';
import { tripScheduled } from '../../../messages';
import { acceptEta, setMessage } from './etaHelper';
import { ETA_TYPES } from '../../../enums';

export const acceptETAMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    const state = getState();

    if (etaActions.acceptETAClick.match(action) && !state.formFlow.etaWasAccepted) {
      acceptEta(getState, dispatch, action.payload.etaId);
    }

    if (etaActions.acceptETAClick.match(action) && state.formFlow.etaWasAccepted) {
      if (state.user.model.isDispatcher) {
        dispatch(pickupTimeChangeActions.openPickupTimeChangeDialog(action.payload.etaId));
      }

      if (state.user.model.isRequester) {
        const user = state.user.model;
        const requesterUpdateType = state.pickupTimeChange.updateTypes.find(t => t.name === 'Requester Requested');
        dispatch(
          pickupTimeChangeActions.setPickupChangeInfo({
            updateTypeId: requesterUpdateType.id,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
          }),
        );

        acceptEta(getState, dispatch, action.payload.etaId);
      }
    }

    if (etaActions.acceptETA.match(action)) {
      dispatch(formFlowActions.setAcceptETAInProgress(true));
      dispatch(formFlowActions.setTriggerValidation(true));
    }
  };

export const acceptedETAMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);
    const state = getState();

    if (etaActions.acceptETASuccess.match(action)) {
      dispatch(formFlowActions.setETASuccess(true));
      dispatch(formFlowActions.setAcceptETAInProgress(false));
      dispatch(formFlowActions.setETAwasAccepted(true));
      dispatch(requestsActions.getRequestCharges(state.requests.request.id));
    }

    if (etaActions.acceptETASuccess.match(action) && state.eta.etaType === ETA_TYPES.PICKUP) {
      dispatch(formFlowActions.setScheduledMessage(tripScheduled));
      dispatch(formFlowActions.changeDialogContent(setMessage(state.requests.request)));
    }
  };

export default [acceptETAMdl, acceptedETAMdl];
