import { duration } from 'moment';

const times = {
  [duration(5, 'm').asSeconds()]: '5m',
  [duration(10, 'm').asSeconds()]: '10m',
  [duration(15, 'm').asSeconds()]: '15m',
  [duration(20, 'm').asSeconds()]: '20m',
  [duration(25, 'm').asSeconds()]: '25m',
  [duration(30, 'm').asSeconds()]: '30m',
  [duration(45, 'm').asSeconds()]: '45m',
  [duration(1, 'h').asSeconds()]: '1h',
  [duration(1, 'h').add(30, 'm').asSeconds()]: '1h 30m',
  [duration(2, 'h').asSeconds()]: '2h',
  [duration(2, 'h').add(30, 'm').asSeconds()]: '2h 30m',
  [duration(3, 'h').asSeconds()]: '3h',
  [duration(4, 'h').asSeconds()]: '4h',
  [duration(5, 'h').asSeconds()]: '5h',
  [duration(6, 'h').asSeconds()]: '6h',
  [duration(7, 'h').asSeconds()]: '7h',
  [duration(8, 'h').asSeconds()]: '8h',
  [duration(9, 'h').asSeconds()]: '9h',
  [duration(10, 'h').asSeconds()]: '10h',
  [duration(11, 'h').asSeconds()]: '11h',
  [duration(12, 'h').asSeconds()]: '12h',
  [duration(13, 'h').asSeconds()]: '13h',
  [duration(14, 'h').asSeconds()]: '14h',
  [duration(15, 'h').asSeconds()]: '15h',
  [duration(16, 'h').asSeconds()]: '16h',
  [duration(17, 'h').asSeconds()]: '17h',
  [duration(18, 'h').asSeconds()]: '18h',
  [duration(19, 'h').asSeconds()]: '19h',
  [duration(20, 'h').asSeconds()]: '20h',
  [duration(21, 'h').asSeconds()]: '21h',
  [duration(22, 'h').asSeconds()]: '22h',
  [duration(23, 'h').asSeconds()]: '23h',
  [duration(24, 'h').asSeconds()]: '24h',
};

export default times;
