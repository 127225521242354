import { createReducer } from 'redux-act';
import * as action from '../actions/route.actions';
import { ViewStatus } from '../../enums';

const initialState = {
  status: ViewStatus.CREATE,
};

const reducer = createReducer(
  {
    [action.atCreateRequest]: state => ({
      ...state,
      status: ViewStatus.CREATE,
    }),
    [action.atEditRequest]: state => ({
      ...state,
      status: ViewStatus.EDIT,
    }),
    [action.atViewRequests]: state => ({
      ...state,
      status: ViewStatus.LIST,
    }),
  },
  initialState,
);

export default reducer;
