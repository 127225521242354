import schema from 'schm';
import computed from 'schm-computed';

export default schema(
  {
    type: String,
    name: String,
    picture_url: String,
    fallback_picture_url: String,
    phone_number: String,
  },
  computed({
    details: values => `${values.name} ${values.phone_number || ''}`,
    image: values => values.picture_url || values.fallback_picture_url,
    fallbackImage: values => `${window.location.origin}/${values.fallback_picture_url}`,
  }),
);
