import { filter, map, switchMap } from 'rxjs/operators';

import { deleteEmailRecipient } from '../../components/RequestShare/redux/deleteEmailRecipient';
import { deleteLoadedRecipient } from '../../components/RequestShare/redux/deleteLoadedRecipient';
import { deletePhoneRecipient } from '../../components/RequestShare/redux/deletePhoneRecipient';
import { loadRecipients } from '../../components/RequestShare/redux/loadRecipients';
import * as subscriptionActions from '../actions/subscription.actions';

import * as SubscriptionService from '../../services/subscription.service';
import { errorPipe } from './epicsUtil';
import { requestId } from './helpers/request.helper';

export const loadSubscriptionsEpic = (action$, store) =>
  action$.pipe(
    filter(subscriptionActions.asyncGetSubscriptions.match),
    switchMap(() => SubscriptionService.getSubscriptions(requestId(store))),
    map(response => loadRecipients(response)),
    errorPipe,
  );

function findId(store, recipient) {
  return store.value.requestShare.loadedRecipients.find(r => r.contact === recipient).id;
}

export const deleteEmailSubscriptionEpic = (action$, store) =>
  action$.pipe(
    filter(deleteEmailRecipient.match),
    switchMap(action => SubscriptionService.deleteSubscription(findId(store, action.payload))),
    map(response => deleteLoadedRecipient(response.id)),
    errorPipe,
  );

export const deleteSmsSubscriptionEpic = (action$, store) =>
  action$.pipe(
    filter(deletePhoneRecipient.match),
    switchMap(action => SubscriptionService.deleteSubscription(findId(store, action.payload))),
    map(response => deleteLoadedRecipient(response.id)),
    errorPipe,
  );
