import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 18 13" {...props}>
      <polygon style={{ fill: '#abe5ff' }} points="17 3 6 3 3.5 6.5 1.5 7.5 1 10.5 17 10.5 17 3 17 3" />
      <path
        style={{ fill: '#ffffff' }}
        d="M16,4V9.5H2.18L2.4,8.16,4,7.39l.22-.11.14-.2L6.51,4H16m1-1H6L3.5,6.5l-2,1-.5,3H17V3Z"
        transform="translate(0)"
      />
      <path
        style={{ fill: '#062d40' }}
        d="M17,2H6a1,1,0,0,0-.85.47L2.8,5.73,1.05,6.6a1.06,1.06,0,0,0-.54.73l-.49,3a1,1,0,0,0,.22.81A1,1,0,0,0,1,11.5H17a1,1,0,0,0,1-1V3A1,1,0,0,0,17,2ZM6,3H7.13V5.5H4.21Zm11,7.5H1l.5-3,2-1H8.13V3H17Z"
        transform="translate(0)"
      />
      <path style={{ fill: '#ffffff' }} d="M6,11A2,2,0,1,1,4,9,2,2,0,0,1,6,11Z" transform="translate(0)" />
      <path
        style={{ fill: '#062d40' }}
        d="M4,10a1,1,0,1,1-1,1,1,1,0,0,1,1-1M4,9a2,2,0,1,0,2,2A2,2,0,0,0,4,9Z"
        transform="translate(0)"
      />
      <path style={{ fill: '#ffffff' }} d="M16,11a2,2,0,1,1-2-2A2,2,0,0,1,16,11Z" transform="translate(0)" />
      <path
        style={{ fill: '#062d40' }}
        d="M14,10a1,1,0,1,1-1,1,1,1,0,0,1,1-1m0-1a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z"
        transform="translate(0)"
      />
      <path
        style={{ fill: '#062d40' }}
        d="M9.63,1a.5.5,0,0,1,.5.5V2h-1V1.5a.5.5,0,0,1,.5-.5m0-1a1.5,1.5,0,0,0-1.5,1.5V3h3V1.5A1.5,1.5,0,0,0,9.63,0Z"
        transform="translate(0)"
      />
    </svg>
  );
}
