import React, { useState } from 'react';
import CloseIcon from '../icons/close';
import { Container, IconButton, TypeAndDelete, Downloadable } from './elements';
import ConfirmationDialog from '../ConfirmationDialog';
import { theme } from '../../../theme';

export default function DocumentTypes({ error, deletable, file, onRemove }) {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const textStyles = {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    error: {
      color: theme.palette.primary.red,
    },
  };

  const textStylesObj = {
    ...textStyles,
    ...(error && textStyles.error),
  };

  const iconButtonStyles = {
    visibility: `${props => (props.deletable ? 'visible' : 'hidden')}`,
    width: 32,
    height: 32,
    fontSize: 19,
    color: theme.palette.grey.primary,
  };

  const closeIconStyles = {
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: 0,
    zIndex: 10,
  };

  const handleMouseEnter = () => {
    if (deletable) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  if (hovered) {
    iconButtonStyles.color = theme.palette.primary.red;
  }

  const onDeleteClick = () => {
    setOpen(true);
  };

  const onDeleteConfirm = confirmed => {
    setOpen(false);
    if (confirmed) {
      onRemove(file);
    }
  };

  const AttachmentWithUrl = ({ name, downloadUrl }) => (
    <>
      <Downloadable href={downloadUrl} download={name} target="_blank" style={textStylesObj}>
        {name}
      </Downloadable>
    </>
  );
  const AttachmentOnly = ({ name }) => (
    <>
      <span style={textStylesObj}>{name}</span>
    </>
  );
  return (
    <Container aria-describedby="file-container">
      <div
        style={{
          width: 315,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {(file.downloadUrl && AttachmentWithUrl(file)) || (file.name && AttachmentOnly(file))}
      </div>
      <TypeAndDelete>
        {file.attachmentType && <div>{file.attachmentType.name}</div>}
        <IconButton
          aria-describedby="delete-file-button"
          deletable={deletable}
          style={iconButtonStyles}
          onClick={onDeleteClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          size="large">
          <span style={{ width: '100%', display: 'flex', alignItems: 'inherit', justifyContent: 'inherit' }}>
            <CloseIcon style={closeIconStyles} />
          </span>
        </IconButton>
      </TypeAndDelete>
      <ConfirmationDialog open={open} onClose={onDeleteConfirm} title="Delete File" okText="CONFIRM" cancel>
        <p style={{ margin: 0 }}>{`Please confirm deletion of ${
          file.attachmentType ? file.attachmentType.name + ' ' : ''
        }${file.name}`}</p>
      </ConfirmationDialog>
    </Container>
  );
}
