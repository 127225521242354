import ApiService from './api.service';

class AgencyService {
  static baseUrl = () => `${window.ambulnzConfig.tenantedRestApiUrl}/RequesterAgencies/`;

  static async getAgencies(name) {
    const path = `${this.baseUrl()}search?name=${name}&filter={'limit':10}`;
    const response = await ApiService.get(path);
    return response.json();
  }

  static async getProviders(agencyId) {
    const path = `${this.baseUrl()}${agencyId}/providers`;
    const response = await ApiService.get(path);
    return response.json();
  }

  static async getTransportReasons(payload) {
    const { agencyId, typeName } = payload;
    const path = `${this.baseUrl()}${agencyId}/TransportReasons?typeName=${typeName}&showInactive=true`;
    const response = await ApiService.get(path);
    return response.json();
  }
}

export default AgencyService;
