import React from 'react';
import { palette } from '../../theme';

const defaultStyles = {
  color: `${palette.grey.grey80}`,
  padding: 0,
  shrink: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  },
};
const AmbInputLabel = ({ htmlFor, children, classes, shrink, required, style, error, ...other }) => {
  const labelStyles = {
    ...defaultStyles,
    ...style,
    ...(shrink && defaultStyles.shrink),
  };
  return (
    <label htmlFor={htmlFor} style={labelStyles} className={classes} {...other}>
      {children}
      {required && <span style={{ color: error ? '#f44336' : 'inherit' }}>&thinsp;*</span>}
    </label>
  );
};

export default AmbInputLabel;
