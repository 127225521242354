import React from 'react';
import PropTypes from 'prop-types';
import ReorderCoveragesIcon from '../../../common/icons/CoverageReorderIcon';

export default function CoverageReorder(props) {
  return (
    <ReorderCoveragesIcon
      svgProps={{
        onClick: props.clickReorder,
      }}
      style={{
        width: 29,
        height: 29,
        position: 'absolute',
        left: '50%',
        marginLeft: -15,
        borderRadius: 15,
        background: '#fff',
        bottom: -35,
        boxShadow: '0px 1px 2px 0px rgba(54, 62, 64, 0.2)',
        zIndex: 1,
        cursor: 'pointer',
      }}
      role="button"
      tabIndex={0}
    />
  );
}

CoverageReorder.propTypes = {
  clickReorder: PropTypes.func.isRequired,
};
