import React from 'react';
import { connect } from 'react-redux';
import DuplicateRequest from './DuplicateRequest.frame';
import * as duplicateRequestActions from './redux/duplicateRequest.actions';

const DuplicateRequestContainer = props => <DuplicateRequest {...props} />;

function mapStateToProps(state) {
  return {
    request: state.requests.request,
    completed: state.duplicateRequest.completed,
    errorMessage: state.duplicateRequest.errorMessage,
    loading: state.duplicateRequest.loading,
  };
}

export default connect(mapStateToProps, { ...duplicateRequestActions })(DuplicateRequestContainer);
