import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import ContactDispatcherWidget from './ContactDispatcherWidget';
import TimeDateWidget from '../TimeDateWidget';
import TelehealthLink from '../TelehealthLink';
import Providers from '../Providers';
import TurndownDialogWidget from './TurndownDialogWidget';
import GetEtaBtn from './GetEtaButton/GetEtaButton.container';
import MultiEta from './Multi/MultiEta';
import AmbCircularProgress from '../common/AmbCircularProgress';
import { theme } from '../../theme';
import ConfirmationDialog from 'components/common/ConfirmationDialog';

const ETAButton = <GetEtaBtn />;

export default class GetETAFrame extends React.PureComponent {
  state = {
    noLongerReservedDialogOpen: false,
  };

  render() {
    const { showAcceptReject, isLoading, pollInProgress, showTelehealthLink } = this.props;
    return (
      <div style={{ backgroundColor: theme.palette.divider }}>
        <TimeDateWidget />
        {showTelehealthLink && <TelehealthLink />}
        <Providers />
        {!this.props.etaAccepted &&
          !this.props.request.isCancelled &&
          !this.props.request.invalidForGetETA &&
          !this.props.hasActiveOffers && (
            <>
              <AmbGrid
                id="GetEtaContainer"
                data-testid="getEtaContainer"
                style={{
                  display: this.props.hideGetEtaBtn ? 'none' : 'flex',
                  minHeight: 100,
                  position: 'relative',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {!pollInProgress && !isLoading && (
                  <>
                    {!showAcceptReject && ETAButton}
                    {showAcceptReject && (
                      <MultiEta
                        showSeeOtherETAs={this.props.showSeeOtherETAs}
                        showCounter={this.props.showCounter}
                        clickSeeOtherETAs={this.props.clickSeeOtherETAs}
                        showEtaInfo={this.props.showEtaInfo}
                        onCountdownComplete={() => this.setState({ noLongerReservedDialogOpen: true })}
                      />
                    )}
                  </>
                )}
                {isLoading && (
                  <div style={{ alignSelf: 'center' }}>
                    <AmbCircularProgress />
                  </div>
                )}

                {pollInProgress && !isLoading && <ContactDispatcherWidget />}
              </AmbGrid>
            </>
          )}

        <TurndownDialogWidget />
        <ConfirmationDialog
          open={this.state.noLongerReservedDialogOpen}
          title="No Longer Reserved"
          onClose={() => {
            this.setState({ noLongerReservedDialogOpen: false });
            this.props.noLongerReserved();
          }}
        >
          <div>Sorry, these availabilities are no longer reserved. Please try again to request new availabilities.</div>
        </ConfirmationDialog>
      </div>
    );
  }
}

GetETAFrame.propTypes = {
  request: PropTypes.shape().isRequired,
  rejectETA: PropTypes.func.isRequired,
  hasActiveOffers: PropTypes.bool.isRequired,
  pollInProgress: PropTypes.bool.isRequired,
  etaAccepted: PropTypes.bool.isRequired,
  hideGetEtaBtn: PropTypes.bool.isRequired,
  clickSeeOtherETAs: PropTypes.func.isRequired,
  showSeeOtherETAs: PropTypes.bool.isRequired,
  showCounter: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  showAcceptReject: PropTypes.bool.isRequired,
  showEtaInfo: PropTypes.bool.isRequired,
};
