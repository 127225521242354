import ApiService from './api.service';

class PCSFormService {
  static async submitPCSForm(pcsFormData) {
    const url = `${window.ambulnzConfig.tenantedRestApiV2Url}/FormFill?type=pcs`;
    const response = await ApiService.post(url, pcsFormData);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.blob();
  }
}

export default PCSFormService;
