import React from 'react';
import { connect } from 'react-redux';
import PCSDialog from './PCSDialog.frame';
import { openPCSModal, donePCSModal } from './redux/openPCSModal';
import { submitPCSForm } from './redux/submitPCSForm';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { clickGetETA } from '../../redux/actions/eta.actions';

const PCSDialogContainer = props => {
  const [showPCSDialog, setShowPCSDialog] = React.useState(false);

  const closeAndGetETA = () => {
    setShowPCSDialog(false);
    props.donePCSModal(true);
    props.clickGetETA();
  };

  const handleSubmit = formData => {
    props.submitPCSForm(formData);
    closeAndGetETA();
  };

  const handleAuthorizationResponse = authorized => {
    if (authorized) {
      setShowPCSDialog(true);
    } else {
      props.donePCSModal(true);
      props.clickGetETA();
    }
    props.openPCSModal(false);
  };

  if (props.open) {
    return <AreYouAuthorizedToSignDialog open={props.open} handleResponse={handleAuthorizationResponse} />;
  }

  return <PCSDialog {...props} onSubmit={handleSubmit} onCancel={closeAndGetETA} open={showPCSDialog} />;
};

const AreYouAuthorizedToSignDialog = ({ open, handleResponse }) => (
  <ConfirmationDialog
    open={open}
    onClose={handleResponse}
    cancel={handleResponse}
    title="Are you authorized to sign?"
    cancelText="No"
    okText="Yes"
  >
    <div>This trip requires a Physician Certification Statement. Are you authorized to sign?</div>
  </ConfirmationDialog>
);

function mapStateToProps(state) {
  const pickupLocation = state.location.fromSelected;
  const destinationLocation = state.location.toSelected;

  const formatDate = date => {
    return date
      ? new Date(date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        })
      : null;
  };

  const formatDateTime = () => {
    const now = new Date();
    return (
      formatDate(now) + ' ' + now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
    );
  };

  return {
    open: state.physicianCertificationStatement.open,
    submitSuccess: state.physicianCertificationStatement.submitSuccess,
    submitError: state.physicianCertificationStatement.submitError,
    prefilledData: {
      origin: `${pickupLocation.name || ''}`.trim(),
      destination: `${destinationLocation.name || ''}`.trim(),
      patientName: `${state.infobox.patientFirstName || ''} ${state.infobox.patientLastName || ''}`.trim(),
      dob: state.infobox.patientBirthday,
      transportDate: state.eta.requestedArrivalTime ? state.eta.requestedArrivalTime.split('T')[0] : null,
      signatureDate: formatDateTime(),
    },
  };
}

export default connect(mapStateToProps, { openPCSModal, donePCSModal, submitPCSForm, clickGetETA })(PCSDialogContainer);
