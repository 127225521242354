import schema from 'schm';
import computed from 'schm-computed';
import { SCHEDULE_STATE } from '../enums';

export default schema(
  {
    state: String,
    order: Number,
  },
  computed({
    toPickup: values => values.state === SCHEDULE_STATE.TO_PICK_UP,
    atPickup: values => values.state === SCHEDULE_STATE.AT_PICK_UP,
    patientPickedUp: values => values.state === SCHEDULE_STATE.PATIENT_PICKED_UP,
    toDropoff: values => values.state === SCHEDULE_STATE.TO_DROP_OFF,
    atDropoff: values => values.state === SCHEDULE_STATE.AT_DROP_OFF,
    allClear: values => values.state === SCHEDULE_STATE.ALL_CLEAR,
    pickupComplete: values => values.order > 2,
  }),
);
