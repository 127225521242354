import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import { SectionHeader } from '../elements';
import AmbGroupToggle from '../../common/AmbGroupToggle';
import AmbBtnWithTooltip from '../../common/AmbBtnWithTooltip';
import ConfirmationDialog from '../../common/ConfirmationDialog';

export default function ServiceFrame({
  handleServiceTypeChange,
  serviceId,
  Curbside,
  curbsideDisabled,
  curbsideTitle,
  Livery,
  liveryDisabled,
  liveryTitle,
  WAV,
  WAVDisabled,
  WAVTitle,
  BLS,
  BLSDisabled,
  BLSTitle,
  ALS,
  ALSDisabled,
  ALSTitle,
  CCT,
  CCTDisabled,
  CCTTitle,
  ETADialogOpen,
  customCloseNewETADialog,
  ETADialogTitle,
  ETADialogBody,
}) {
  return (
    <>
      <AmbGrid style={{ flexDirection: 'column', gap: '6px' }}>
        <AmbGrid>
          <SectionHeader>Service Level</SectionHeader>
        </AmbGrid>
        <AmbGrid style={{ gap: '20px' }}>
          <AmbGrid>
            <AmbGroupToggle onChange={({ key }) => handleServiceTypeChange(key)} value={serviceId}>
              <AmbBtnWithTooltip
                selectedkey={Curbside && Curbside.toString()}
                disabled={curbsideDisabled || null}
                title={curbsideTitle}
                aria-labelledby="service-level-button"
              >
                Uber
              </AmbBtnWithTooltip>
            </AmbGroupToggle>
          </AmbGrid>
          <AmbGrid>
            <AmbGroupToggle onChange={({ key }) => handleServiceTypeChange(key)} value={serviceId}>
              <AmbBtnWithTooltip
                selectedkey={Livery && Livery.toString()}
                disabled={liveryDisabled || null}
                title={liveryTitle}
                aria-labelledby="service-level-button"
              >
                Livery
              </AmbBtnWithTooltip>
              <AmbBtnWithTooltip
                selectedkey={WAV && WAV.toString()}
                disabled={WAVDisabled || null}
                title={WAVTitle}
                aria-labelledby="service-level-button"
              >
                WAV
              </AmbBtnWithTooltip>
              <AmbBtnWithTooltip
                selectedkey={BLS && BLS.toString()}
                disabled={BLSDisabled || null}
                title={BLSTitle}
                aria-labelledby="service-level-button"
              >
                BLS
              </AmbBtnWithTooltip>
              <AmbBtnWithTooltip
                selectedkey={ALS && ALS.toString()}
                disabled={ALSDisabled || null}
                title={ALSTitle}
                aria-labelledby="service-level-button"
              >
                ALS
              </AmbBtnWithTooltip>
              <AmbBtnWithTooltip
                selectedkey={CCT && CCT.toString()}
                disabled={CCTDisabled || null}
                title={CCTTitle}
                aria-labelledby="service-level-button"
              >
                CCT
              </AmbBtnWithTooltip>
            </AmbGroupToggle>
          </AmbGrid>
        </AmbGrid>
      </AmbGrid>
      <ConfirmationDialog open={ETADialogOpen} onClose={customCloseNewETADialog} title={ETADialogTitle} cancel>
        <div>{ETADialogBody}</div>
      </ConfirmationDialog>
    </>
  );
}

ServiceFrame.propTypes = {
  handleServiceTypeChange: PropTypes.func.isRequired,
  curbsideDisabled: PropTypes.bool.isRequired,
  liveryDisabled: PropTypes.bool.isRequired,
  WAVDisabled: PropTypes.bool.isRequired,
  BLSDisabled: PropTypes.bool.isRequired,
  ALSDisabled: PropTypes.bool.isRequired,
  CCTDisabled: PropTypes.bool.isRequired,
  serviceId: PropTypes.number,
  Curbside: PropTypes.number,
  WAV: PropTypes.number,
  Livery: PropTypes.number,
  BLS: PropTypes.number,
  ALS: PropTypes.number,
  CCT: PropTypes.number,
  curbsideTitle: PropTypes.string.isRequired,
  liveryTitle: PropTypes.string.isRequired,
  WAVTitle: PropTypes.string.isRequired,
  BLSTitle: PropTypes.string.isRequired,
  ALSTitle: PropTypes.string.isRequired,
  CCTTitle: PropTypes.string.isRequired,
  ETADialogOpen: PropTypes.bool.isRequired,
  ETADialogTitle: PropTypes.string.isRequired,
  ETADialogBody: PropTypes.string.isRequired,
  customCloseNewETADialog: PropTypes.func.isRequired,
};

ServiceFrame.defaultProps = {
  Curbside: undefined,
  WAV: undefined,
  Livery: undefined,
  BLS: undefined,
  ALS: undefined,
  CCT: undefined,
  serviceId: undefined,
};
