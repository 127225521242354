import ApiService from './api.service';

class CoordinationTypeService {
  static async getCoordinationTypes() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/CoordinationTypes/`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default CoordinationTypeService;
