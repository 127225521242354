import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import AmbDatePicker from '../../../common/AmbDatePicker';
import dayjs from 'dayjs';
import useEventListener from '../../../common/useEventListener.hook';

function PatientBirthday({ patientBirthday, disabled, handleChange, handleAccept, handleBlur, error, required }) {
  const ambDatePicker = useRef(null);

  useEventListener(ambDatePicker, 'blur', val => {
    handleBlur(val);
  });

  return (
    <AmbDatePicker
      ref={ambDatePicker}
      name="patientBirthday"
      margin="normal"
      value={patientBirthday && dayjs(patientBirthday)}
      disabled={disabled || null}
      onChange={value => value && handleChange(value.format('YYYY-MM-DD'))}
      onAccept={value => value && handleAccept(value.format('YYYY-MM-DD'))}
      label="Date of Birth"
      format="MM/DD/YYYY"
      required={required}
      openTo="year"
      disableFuture
      id="PatientBirthdayInput"
      error={error}
    />
  );
}

PatientBirthday.propTypes = {
  patientBirthday: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default PatientBirthday;
