import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 16 18" {...props}>
      <polygon points="12.5,5.5 9.1,9 12.5,12.5 12.5,17 3.5,17 3.5,12.5 6.9,9 3.5,5.5 3.5,1 12.5,1 " />
      <polygon style={{ fill: '#014053' }} points="11.6,5 11.6,4.1 4.4,4.1 4.4,5 8,8.7 " />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M11.4,2.1V5l-3,3.1L8,8.6L7.6,8.2L4.6,5V2.1H11.4 M8,9.4l0.4,0.4l3,3.2v2.9H4.6V13l3-3.2L8,9.4 M12.5,1h-9v4.5
      L6.9,9l-3.4,3.5V17h9v-4.5L9.1,9l3.4-3.5V1L12.5,1z"
      />
      <path
        style={{ fill: '#014053' }}
        d="M12.5,1v4.5L9.1,9l3.4,3.5V17h-9v-4.5L6.9,9L3.5,5.5V1H12.5 M13.5,0h-1h-9h-1v1v4.5v0.4l0.3,0.3L5.5,9l-2.7,2.9
      l-0.3,0.3v0.4V17v1h1h9h1v-1v-4.5v-0.4l-0.3-0.3L10.5,9l2.7-2.8l0.3-0.3V5.5V1L13.5,0L13.5,0z"
      />
    </svg>
  );
}
