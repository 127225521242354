import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

import HelpFrame from './Help.frame';
import { isCar } from '../../flags/service.flag';

function HelpContainer(props) {
  return (
    <>
      {props.requestId &&
        props.phone && <HelpFrame driver={props.personnel[0] && props.personnel[0].details} {...props} />}
    </>
  );
}

function mapStateToProps({ service, requests, schedule, infobox }) {
  return {
    requestId: requests.request.id,
    tripNumber: schedule.id,
    pickupTime: schedule.atPickupTime,
    customer: infobox.patientFirstName && `${infobox.patientFirstName} ${infobox.patientLastName}`,
    phone: get(window.ambulnzConfig.constants, 'UBER_HELP_PHONE'),
    isCar: isCar(service),
  };
}

export default connect(
  mapStateToProps,
  {},
)(HelpContainer);

HelpContainer.propTypes = {
  requestId: PropTypes.number,
};

HelpContainer.defaultProps = {
  requestId: null,
};
