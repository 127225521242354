import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../../../theme';

class CoverageReorderIconFrame extends React.Component {
  state = {
    hovered: false,
  };

  mouseEnter = () => {
    this.setState({ hovered: true });
  };

  mouseLeave = () => {
    this.setState({ hovered: false });
  };

  render() {
    const { svgProps, style } = this.props;

    return (
      <svg
        viewBox="0 0 29 29"
        {...svgProps}
        style={{ ...style, fill: this.state.hovered ? theme.palette.action.active : theme.palette.grey.primary }}
        onMouseEnter={this.mouseEnter}
        onMouseLeave={this.mouseLeave}
      >
        <polygon points="18.5,22.4 14.2,17.4 15.6,16.2 18.5,19.7 21.2,16.3 22.6,17.5 " />
        <rect x="17.5" y="6.5" width="1.7" height="13.3" />
        <polygon points="9.9,6.5 14.2,11.5 12.8,12.7 9.9,9.2 7.1,12.6 5.7,11.4 " />
        <rect x="9.1" y="9.1" width="1.7" height="13.3" />
        <path
          d="M14.5,1.5c7.2,0,13,5.8,13,13s-5.8,13-13,13s-13-5.8-13-13S7.3,1.5,14.5,1.5 M14.5,0C6.5,0,0,6.5,0,14.5
          S6.5,29,14.5,29S29,22.5,29,14.5S22.5,0,14.5,0L14.5,0z"
        />
      </svg>
    );
  }
}

CoverageReorderIconFrame.defaultProps = {
  svgProps: {},
};

CoverageReorderIconFrame.propTypes = {
  style: PropTypes.shape().isRequired,
  svgProps: PropTypes.shape(),
};

export default CoverageReorderIconFrame;
