import { createAction } from '@reduxjs/toolkit';

export const setMissingFieldsBody = createAction('[MissingFieldsModal] setMissingFieldsBody');

const missingFields = [
  'Select a requesting agency',
  'Requester must have a first name',
  'Requester must have a last name',
  'Requester must have a phone number',
  'Select pickup location',
  'Select a dropoff location',
  'All service type questions must be answered',
  'Service Level must be specified',
  'Patient must have a first name',
  'Patient must have a last name',
  'Patient must have a date of birth',
  'Patient must have a height',
  'Patient must have a weight',
  'Patient zip must be 5 digits',
  'Patient phone number must have all digits',
  'Payer inactive. Please select another',
  'Must select a primary payer',
  'Select Chief Complaint',
  'Must specify Chief Complaint description',
];

export const getErrors = errors =>
  Object.values(errors).sort((a, b) => missingFields.indexOf(a) - missingFields.indexOf(b));

export const parseErrors = errors =>
  getErrors(errors)
    .join('\n')
    .replace(/^\s*[\r\n]/gm, '');

export const reducer = {
  [setMissingFieldsBody]: (state, payload) => ({
    ...state,
    body: parseErrors(payload || state.errors),
  }),
};
