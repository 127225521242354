import React from 'react';

export default function (props) {
  return (
    <svg
      style={props.style}
      fill="rgb(250, 250, 250)"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      onClick={props.onClick}
    >
      <path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"></path>
    </svg>
  );
}
