import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 51.1 24" {...props}>
      <title>PEDS-ALS</title>
      <path
        style={{ fill: '#1ec2d8' }}
        d="M2.08,0H36.9a3.41,3.41,0,0,1,2.25,1.21A3.65,3.65,0,0,1,40,3.47.48.48,0,0,1,39.5,4H38.11l6.5,8s2.77.87,4.07,1.13a1.66,1.66,0,0,1,1.39,1.38l.78,4H45.13a3.86,3.86,0,0,0-2.68-1.56A4,4,0,0,0,39.07,19H10.34S8.92,16.9,7.1,16.9A3.65,3.65,0,0,0,3.86,19H.43V1.48A1.43,1.43,0,0,1,2.08,0Z"
        transform="translate(0 0)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M22.61.78a5.69,5.69,0,0,0-5.56,5.09,5.88,5.88,0,0,1,3.11,1.42v-1a2.38,2.38,0,0,1,.76-1.7,2.73,2.73,0,0,1,1.69-.66,2.48,2.48,0,0,1,2.45,2.64v8.58H22V12.28a5.8,5.8,0,0,0-5.84-5.75,5.87,5.87,0,0,0-5.84,5.84A5.79,5.79,0,0,0,16,18.22h2.07V15.11h-2a2.74,2.74,0,1,1,2.73-2.83v5.94h9.43V6.53A5.64,5.64,0,0,0,22.61.78"
          transform="translate(0 0)"
        />
      </g>
      <path
        style={{ fill: '#050505' }}
        d="M1,19H39.07l-.35,1.47H.61A.59.59,0,0,1,0,19.84v-.26A.59.59,0,0,1,.61,19H1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#71ccdd' }}
        d="M39.59,20.45v1.38H3.72A2.93,2.93,0,0,1,1,20.45Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#050505' }} cx="7.1" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="7.1" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="7.1" cy="20.45" r="1.65" />
      <path
        style={{ fill: '#2b242d' }}
        d="M45.13,22.09h4.68c1.21,0,1.29-2.14,1.29-2.34s-.25-1.29-.25-1.29H45.13Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#5f5f61' }} cx="7.1" cy="20.45" r="1.21" />
      <circle style={{ fill: '#050505' }} cx="42.27" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="42.27" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="42.27" cy="20.45" r="1.65" />
      <circle style={{ fill: '#5f5f61' }} cx="42.27" cy="20.45" r="1.21" />
      <path
        style={{ fill: '#ebebed' }}
        d="M35.78,13.35h0a.23.23,0,0,0-.26-.26h-.87a.23.23,0,0,0-.26.26h0a.23.23,0,0,0,.26.26h.87C35.69,13.61,35.86,13.52,35.78,13.35Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M36.9,4.51a1.89,1.89,0,0,1,1.56.61l4.85,6.23L33.61,10V4.51Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ededef' }}
        d="M48,12.92l.82,1.75,1.4.39-.11-.59a1.66,1.66,0,0,0-1.39-1.38Z"
        transform="translate(0 0)"
      />
      <rect style={{ fill: '#ededef' }} x="45.3" y="16.12" width="1.56" height="1.21" />
      <path
        d="M9,3a.17.17,0,0,1,.17-.17h2.17a2,2,0,1,1,0,4h-.95V8.94a.18.18,0,0,1-.17.17H9.15A.17.17,0,0,1,9,8.94Zm2.26,2.53A.71.71,0,0,0,12,4.8a.69.69,0,0,0-.71-.67h-.86V5.51Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M11.32,2.81a2,2,0,1,1,0,4h-.95V8.94a.18.18,0,0,1-.17.17H9.15A.17.17,0,0,1,9,8.94V3a.17.17,0,0,1,.17-.17h2.17m-.94,2.7h.86A.71.71,0,0,0,12,4.8a.69.69,0,0,0-.71-.67h-.86V5.51m.94-2.95H9.15A.42.42,0,0,0,8.73,3v6a.42.42,0,0,0,.42.42h1.06a.42.42,0,0,0,.42-.42V7.07h.7a2.26,2.26,0,1,0,0-4.51Zm-.69,1.82h.61a.44.44,0,0,1,.46.42.45.45,0,0,1-.46.46h-.61V4.38Z"
        transform="translate(0 0)"
      />
      <path
        d="M14.28,3a.17.17,0,0,1,.17-.17h3.73a.17.17,0,0,1,.17.17V4a.17.17,0,0,1-.17.17H15.69V5.25h2a.18.18,0,0,1,.17.17v1a.17.17,0,0,1-.17.17h-2V7.79h2.49a.17.17,0,0,1,.17.17v1a.17.17,0,0,1-.17.17H14.45a.17.17,0,0,1-.17-.17Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M18.18,2.81a.17.17,0,0,1,.17.17V4a.17.17,0,0,1-.17.17H15.69V5.25h2a.18.18,0,0,1,.17.17v1a.17.17,0,0,1-.17.17h-2V7.79h2.49a.17.17,0,0,1,.17.17v1a.17.17,0,0,1-.17.17H14.45a.17.17,0,0,1-.17-.17V3a.17.17,0,0,1,.17-.17h3.73m0-.25H14.45A.42.42,0,0,0,14,3v6a.42.42,0,0,0,.42.42h3.73a.42.42,0,0,0,.42-.42V8a.42.42,0,0,0-.42-.42H15.94V6.82h1.8a.42.42,0,0,0,.42-.42v-1A.42.42,0,0,0,17.74,5h-1.8V4.38h2.24A.42.42,0,0,0,18.6,4V3a.42.42,0,0,0-.42-.42Z"
        transform="translate(0 0)"
      />
      <path
        d="M19.56,3a.17.17,0,0,1,.16-.17h2.19a3.15,3.15,0,1,1,0,6.3H19.72a.17.17,0,0,1-.16-.17Zm2.26,4.8A1.75,1.75,0,0,0,23.58,6a1.75,1.75,0,0,0-1.76-1.82H21V7.78Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M21.91,2.81a3.15,3.15,0,1,1,0,6.3H19.72a.17.17,0,0,1-.16-.17V3a.17.17,0,0,1,.16-.17h2.19m-.95,5h.86A1.75,1.75,0,0,0,23.58,6a1.75,1.75,0,0,0-1.76-1.82H21V7.78m.95-5.22H19.72a.41.41,0,0,0-.41.42v6a.41.41,0,0,0,.41.42h2.19a3.4,3.4,0,1,0,0-6.8Zm-.7,1.82h.61A1.51,1.51,0,0,1,23.33,6a1.51,1.51,0,0,1-1.51,1.58h-.61V4.38Z"
        transform="translate(0 0)"
      />
      <path
        d="M25.81,8.26l.42-.75a.23.23,0,0,1,.31,0A2.79,2.79,0,0,0,27.82,8a.56.56,0,0,0,.62-.54c0-.38-.32-.67-.93-.91-.77-.31-1.74-.91-1.74-2a1.86,1.86,0,0,1,2.07-1.79,3.41,3.41,0,0,1,1.93.67.24.24,0,0,1,.06.29l-.46.7c-.07.1-.23.18-.31.11A2.88,2.88,0,0,0,27.77,4a.52.52,0,0,0-.56.46c0,.34.27.59,1,.88S30,6.11,30,7.34A2,2,0,0,1,27.88,9.2a2.87,2.87,0,0,1-2-.69C25.77,8.42,25.74,8.38,25.81,8.26Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M27.84,2.72a3.41,3.41,0,0,1,1.93.67.24.24,0,0,1,.06.29l-.46.7a.33.33,0,0,1-.24.14.11.11,0,0,1-.07,0A2.88,2.88,0,0,0,27.77,4a.52.52,0,0,0-.56.46c0,.34.27.59,1,.88S30,6.11,30,7.34A2,2,0,0,1,27.88,9.2a2.87,2.87,0,0,1-2-.69c-.08-.09-.11-.13,0-.25l.42-.75a.19.19,0,0,1,.18-.09.18.18,0,0,1,.13,0A2.79,2.79,0,0,0,27.82,8a.56.56,0,0,0,.62-.54c0-.38-.32-.67-.93-.91-.77-.31-1.74-.91-1.74-2a1.86,1.86,0,0,1,2.07-1.79m0-.25a2.12,2.12,0,0,0-2.32,2c0,1,.67,1.74,1.9,2.22.77.31.77.59.77.68s-.19.29-.37.29a2.06,2.06,0,0,1-1.11-.43h0l-.05,0a.46.46,0,0,0-.24-.06.45.45,0,0,0-.39.21l-.43.75a.4.4,0,0,0,.09.55,3.06,3.06,0,0,0,2.2.77,2.23,2.23,0,0,0,2.38-2.11c0-1.5-1.49-2.09-2-2.29-.73-.29-.82-.49-.82-.64s.13-.21.31-.21a2.45,2.45,0,0,1,1.09.46l.08,0a.31.31,0,0,0,.19.06.58.58,0,0,0,.45-.26L30,3.82a.47.47,0,0,0-.13-.64,3.57,3.57,0,0,0-2.06-.71Z"
        transform="translate(0 0)"
      />
      <path
        d="M11.49,16.88l2.81-6.06a.19.19,0,0,1,.16-.1h.09a.18.18,0,0,1,.15.1l2.82,6.06a.16.16,0,0,1-.15.23h-1c-.17,0-.24-.05-.32-.22l-.32-.72H13.28L13,16.9a.33.33,0,0,1-.32.21h-1A.15.15,0,0,1,11.49,16.88ZM15.19,15l-.69-1.48h0L13.82,15Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M14.55,10.72a.18.18,0,0,1,.15.1l2.82,6.06a.16.16,0,0,1-.15.23h-1c-.17,0-.24-.05-.32-.22l-.32-.72H13.28L13,16.9a.33.33,0,0,1-.32.21h-1a.15.15,0,0,1-.15-.23l2.81-6.06a.19.19,0,0,1,.16-.1h.09M13.82,15h1.37l-.69-1.48h0L13.82,15m.73-4.48h-.09a.42.42,0,0,0-.38.24l-2.82,6.06a.43.43,0,0,0,0,.4.42.42,0,0,0,.36.19h1a.6.6,0,0,0,.56-.37l.25-.57h2.13l.25.57a.55.55,0,0,0,.55.37h1a.42.42,0,0,0,.38-.59l-2.82-6.06a.44.44,0,0,0-.38-.24Zm-.34,4.23.29-.64.3.64Z"
        transform="translate(0 0)"
      />
      <path
        d="M18.26,11a.17.17,0,0,1,.17-.17H19.5a.18.18,0,0,1,.17.17v4.81h2.14A.17.17,0,0,1,22,16v1a.17.17,0,0,1-.17.17H18.43a.17.17,0,0,1-.17-.17Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M19.5,10.81a.18.18,0,0,1,.17.17v4.81h2.14A.17.17,0,0,1,22,16v1a.17.17,0,0,1-.17.17H18.43a.17.17,0,0,1-.17-.17V11a.17.17,0,0,1,.17-.17H19.5m0-.25H18.43A.42.42,0,0,0,18,11v6a.42.42,0,0,0,.42.42h3.38a.42.42,0,0,0,.42-.42V16a.42.42,0,0,0-.42-.42H19.92V11a.42.42,0,0,0-.42-.42Z"
        transform="translate(0 0)"
      />
      <path
        d="M22.49,16.26l.43-.75a.22.22,0,0,1,.3,0A2.85,2.85,0,0,0,24.5,16a.56.56,0,0,0,.62-.54c0-.38-.31-.67-.92-.91-.78-.31-1.75-.91-1.75-2a1.87,1.87,0,0,1,2.08-1.79,3.45,3.45,0,0,1,1.93.67.25.25,0,0,1,.05.29l-.46.7c-.06.1-.23.18-.31.11A2.83,2.83,0,0,0,24.46,12a.52.52,0,0,0-.57.46c0,.34.27.59,1,.88s1.82.82,1.82,2.05a2,2,0,0,1-2.13,1.86,2.84,2.84,0,0,1-2-.69C22.45,16.42,22.42,16.38,22.49,16.26Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M24.53,10.72a3.45,3.45,0,0,1,1.93.67.25.25,0,0,1,.05.29l-.46.7a.33.33,0,0,1-.23.14.11.11,0,0,1-.08,0A2.83,2.83,0,0,0,24.46,12a.52.52,0,0,0-.57.46c0,.34.27.59,1,.88s1.82.82,1.82,2.05a2,2,0,0,1-2.13,1.86,2.84,2.84,0,0,1-2-.69c-.09-.09-.12-.13-.05-.25l.43-.75a.19.19,0,0,1,.17-.09.22.22,0,0,1,.13,0A2.85,2.85,0,0,0,24.5,16a.56.56,0,0,0,.62-.54c0-.38-.31-.67-.92-.91-.78-.31-1.75-.91-1.75-2a1.87,1.87,0,0,1,2.08-1.79m0-.25a2.12,2.12,0,0,0-2.33,2c0,1,.66,1.73,1.9,2.22.77.31.77.59.77.68s-.18.29-.37.29a2.06,2.06,0,0,1-1.11-.43h0l-.05,0a.41.41,0,0,0-.24-.06.46.46,0,0,0-.39.21l-.42.75a.4.4,0,0,0,.08.55,3,3,0,0,0,2.2.77,2.23,2.23,0,0,0,2.38-2.11c0-1.5-1.49-2.09-2-2.29-.72-.29-.82-.49-.82-.64s.13-.21.32-.21a2.41,2.41,0,0,1,1.08.46l.08.05a.32.32,0,0,0,.2.06.56.56,0,0,0,.44-.26l.46-.69a.48.48,0,0,0-.14-.64,3.48,3.48,0,0,0-2-.71Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
