import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import MenuCancelButton from '../../common/Menu/MenuCancelButton';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import AmbDropdown from '../../common/AmbDropdown/AmbDropdown';
import { theme } from 'theme';

const labelWrapperStyles = {
  margin: 0,
  border: 0,
  display: 'inline-flex',
  padding: 0,
  position: 'relative',
  minWidth: 0,
  flexDirection: 'column',
};

const LegendComponent = ({ children, style, required }) => {
  return (
    <div style={{ labelWrapperStyles }}>
      <legend style={style}>
        {children}
        {required && <span>&thinsp;*</span>}
      </legend>
    </div>
  );
};

const StyledTextArea = styled.textarea`
  font: inherit;
  height: 100%;
  font-size: 12;
  color: currentColor;
  border: 0;
  margin: 0;
  padding: 0;
  resize: vertical;
  display: block;
  min-width: 0;
  outline: none;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;

  &::placeholder {
    opacity: 0.5;
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 14px;
  padding: 6px 0 7px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: #004053;
  }
`;

const TextArea = ({ name, fullWidth, onChange, required, style, value, placeholder }) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <Wrapper isFocused={isFocused}>
        <div style={{ position: 'relative', width: '100%', height: '73px' }}>
          <StyledTextArea
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            type="text"
            rows="6"
            rowsMax="8"
            required={required}
            onChange={onChange}
            name={name}
            style={{ width: fullWidth ? '100%' : 'auto', ...style }}
            value={value}
            placeholder={placeholder}
          />
        </div>
      </Wrapper>
    </>
  );
};

class CancelRequest extends React.Component {
  componentDidMount() {
    if (this.props.reasonTypes.length === 0) {
      this.props.fetchRequestReasonTypes();
    }
  }

  handleClose(value) {
    if (value) {
      this.props.asyncCancelRequest();
    }
    this.props.openCancelRequestModal(false);
  }

  render() {
    const dropDownContainerStyles = {
      minWidth: 289,
      margin: '8px 0',
      width: '100%',
      border: 0,
    };

    const reasonsDropDown = (
      <div>
        <LegendComponent
          style={{
            fontSize: 14,
            fontFamily: 'inherit',
            color: '#004053',
            marginBottom: 7,
            marginTop: 5,
            minWidth: 240,
            padding: 0,
          }}
          required
        >
          What is the reason for cancellation?
        </LegendComponent>
        <AmbDropdown
          options={this.props.reasonTypes.map(c => ({
            key: c.id,
            value: c.id,
            name: c.name,
          }))}
          inputId="select-cancelReasonTypeId"
          onSelect={this.props.onCancelReasonTypeChange}
          value={this.props.cancelReasonTypeId}
          containerStyles={dropDownContainerStyles}
        >
          <option key="placeholder" value={0} disabled style={{ color: '#919799', fontSize: 13 }}>
            — select cancellation reason --
          </option>
        </AmbDropdown>
      </div>
    );
    const details = (
      <div>
        <LegendComponent
          style={{ fontSize: 14, minWidth: 240, color: theme.palette.primary.dark, marginBottom: 7, marginTop: 5 }}
          required
        >
          Please provide cancellation details
        </LegendComponent>
        <TextArea
          style={{ fontSize: theme.typography.fontSize, minWidth: 289, lineHeight: 1.3 }}
          name="notes"
          id="comments"
          placeholder="Example: Spoke with so and so. Patient is in critical condition. Doctor will admit to the hospital because they're not safe for transport."
          fullWidth
          onChange={e => this.props.onDetailsChange(e.target.value)}
          required
          value={this.props.details}
        />
      </div>
    );

    return (
      <div>
        <MenuCancelButton
          handleClick={this.props.openCancelRequestModal}
          disabled={this.props.menuCancelDisabled || null}
        />
        <ConfirmationDialog
          open={this.props.open}
          onClose={value => this.handleClose(value)}
          disableConfirm={this.props.cancelDisabled}
          title="Cancel Trip?"
          okText="YES, CANCEL TRIP"
          cancelText="NO, KEEP TRIP"
          cancel
        >
          {reasonsDropDown}
          {this.props.showDetailsQuestion && details}
        </ConfirmationDialog>
      </div>
    );
  }
}

CancelRequest.propTypes = {
  open: PropTypes.bool.isRequired,
  menuCancelDisabled: PropTypes.bool.isRequired,
  showDetailsQuestion: PropTypes.bool.isRequired,
  cancelDisabled: PropTypes.bool.isRequired,
  cancelReasonTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  openCancelRequestModal: PropTypes.func.isRequired,
  asyncCancelRequest: PropTypes.func.isRequired,
  onCancelReasonTypeChange: PropTypes.func.isRequired,
  onDetailsChange: PropTypes.func.isRequired,
  fetchRequestReasonTypes: PropTypes.func.isRequired,
  details: PropTypes.string.isRequired,
  reasonTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

CancelRequest.defaultProps = {};

export default CancelRequest;
