import { filter, map } from 'rxjs/operators';

import { ViewStatus } from '../../enums';
import * as mapActions from '../actions/map.actions';
import * as userActions from '../actions/user.actions';
import { errorPipe } from './epicsUtil';

export const setMapLocationByCurrentUserEpic = (action$, store) =>
  action$.pipe(
    filter(userActions.fetchCurrentUserSuccess.match),
    filter(() => store.value.user.model.facilities.length > 0 && store.value.route.status === ViewStatus.CREATE),
    map(() => mapActions.setMapLocationByCurrentUser(store.value)),
    errorPipe,
  );
