import schema from 'schm';
import computed from 'schm-computed';
import translate from 'schm-translate';
import { get } from 'lodash';

// TODO: the 'body' parsing is only correct to 409 error of get eta
// add schemas composed out of this one
// or a dictionary/switch function to hande all the errors
const errorMessage = schema(
  {
    name: '',
    message: '',
    conflict: String,
    status: 0,
    error: Object,
  },
  computed({
    title: values => `${values.name.replace(/([A-Z])/g, ' $1')}`,
    body: values =>
      values.conflict
        ? values.conflict
        : get(values, 'error.message', values.message.substring(0, values.message.indexOf('{'))),
    open: values => values.status !== 0,
  }),
  translate({
    conflict: 'conflicts.0.message',
  }),
);

export default errorMessage;
