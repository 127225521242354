import React from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import WayPointsContainer from './WayPointsContainer';

//     googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${props.googleMapApiKey}&v=3.46&libraries=geometry,drawing,places`,
const containerStyle = {
  width: '100%',
  height: '100%',
};

const libraries = ['places'];

function Map({
  googleMapApiKey,
  params: {
    currentLocation: { lat, lng, zoom },
  },
}) {
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: googleMapApiKey,
    libraries,
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={zoom || 9}
      center={{
        lat,
        lng,
      }}
      options={{
        disableDefaultUI: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      <WayPointsContainer />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(Map);
