import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 14 48" {...props}>
      <path
        style={{ fill: '#014053' }}
        d="M7,1c3.2,0,5.9,2.6,5.9,5.9c0,1.3-0.9,3.1-1.3,3.9C10.7,12.1,7,17,7,17s-3.7-4.9-4.5-6.3C2,10,1.1,8.2,1.1,6.9
      C1.1,3.6,3.8,1,7,1 M7,0C3.2,0,0.1,3.1,0.1,6.9c0,1.9,1.4,4.3,1.5,4.4c0.8,1.4,4.4,6.2,4.6,6.4C6.4,17.9,6.7,18,7,18
      c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,3.7-5,4.6-6.4c0.1-0.1,1.5-2.5,1.5-4.4C13.9,3.1,10.8,0,7,0L7,0z"
      />
      <g>
        <path
          d="M7,17c0,0,3.7-4.9,4.5-6.3c0.5-0.7,1.3-2.5,1.3-3.9C12.9,3.6,10.2,1,7,1C3.8,1,1.1,3.6,1.1,6.9
        c0,1.3,0.9,3.1,1.3,3.9C3.3,12.1,7,17,7,17z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M7,2c2.7,0,4.9,2.2,4.9,4.9c0,1-0.7,2.6-1.2,3.3c-0.6,0.9-2.5,3.6-3.7,5.1c-1.1-1.5-3.1-4.2-3.7-5.1
        C2.9,9.4,2.1,7.9,2.1,6.9C2.1,4.2,4.3,2,7,2 M7,1C3.8,1,1.1,3.6,1.1,6.9c0,1.3,0.9,3.1,1.3,3.9C3.3,12.1,7,17,7,17s3.7-4.9,4.5-6.3
        c0.5-0.7,1.3-2.5,1.3-3.9C12.9,3.6,10.2,1,7,1L7,1z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M7,8.8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C9,7.9,8.1,8.8,7,8.8"
        />
        <path
          style={{ fill: '#014053' }}
          d="M7,9.3c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C9.5,8.2,8.4,9.3,7,9.3z M7,5.3
        C6.2,5.3,5.5,6,5.5,6.8S6.2,8.3,7,8.3s1.5-0.7,1.5-1.5S7.8,5.3,7,5.3z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M7,47.5c-1.9,0-3.5-1.6-3.5-3.5c0-1.9,1.6-3.5,3.5-3.5c1.9,0,3.5,1.6,3.5,3.5C10.5,45.9,8.9,47.5,7,47.5"
        />
        <path
          style={{ fill: '#014053' }}
          d="M7,48c-2.2,0-4-1.8-4-4s1.8-4,4-4c2.2,0,4,1.8,4,4S9.2,48,7,48z M7,41c-1.6,0-3,1.4-3,3s1.4,3,3,3
        c1.6,0,3-1.4,3-3S8.6,41,7,41z"
        />
      </g>
      <path
        style={{ fill: '#D7DEE4' }}
        d="M7,38c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2C8,37.5,7.6,38,7,38z M7,31c-0.6,0-1-0.4-1-1v-2
      c0-0.6,0.4-1,1-1s1,0.4,1,1v2C8,30.5,7.6,31,7,31z M7,24c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2C8,23.5,7.6,24,7,24z"
      />
      <path d="M7,46c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C9,45.1,8.1,46,7,46" />
    </svg>
  );
}
