import * as trackChangesActions from '../actions/trackChanges.actions';
import * as infoboxActions from '../actions/infobox.actions';

export const setPatientSnapshotMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    const actionsToDispatch = [
      infoboxActions.setPatientDetails.match(action),
      infoboxActions.setBirthday.match(action),
    ];

    if (action && actionsToDispatch.includes(action.type)) {
      dispatch(trackChangesActions.addPatientSnapshot(getState().infobox));
    }

    next(action);
  };
export const trackChangesMdl = [setPatientSnapshotMdl];
