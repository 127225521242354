import { createAction } from '@reduxjs/toolkit';

export const setSubmitRecipientsManualCheck = createAction('[RequestShare] setSubmitRecipientsManualCheck');

export const reducer = {
  [setSubmitRecipientsManualCheck]: (state, payload) => ({
    ...state,
    manuallyTriggered: payload,
  }),
};
