import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { get } from 'lodash';
import { RequestStatus } from '../../enums';
import { ReactComponent as InfoRed } from '../common/icons/info-14px_red.svg';
import { theme } from '../../theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

function EtaInfo({ conflict, priorityType, totalDrive, unitId, taskTime, view }) {
  const conflictRequestStatus = get(conflict, 'request.status');
  const isAssigned = conflictRequestStatus !== RequestStatus.SCHEDULED;
  const conflictMsg = `Unit ${unitId} is already ${isAssigned ? 'assigned' : 'scheduled'}.`;
  return (
    <>
      {view === 'single' && (
        <>
          <p style={{ fontSize: 11, textAlign: 'center', paddingTop: 5, paddingBottom: 5, margin: 0 }}>
            <b>{`P${priorityType || '-'} Requester`}</b>
            &nbsp;with Expected Task Time of &nbsp;
            <b>{taskTime}</b>
            &nbsp; and Expected Total Drive of &nbsp;
            <b>{`${totalDrive}mi`}</b>
          </p>
          {conflictRequestStatus && (
            <p
              style={{
                fontSize: 11,
                textAlign: 'center',
                paddingTop: 5,
                paddingBottom: 5,
                margin: 0,
                marginTop: 'initial',
                color: theme.palette.primary.red,
              }}
            >
              {conflictMsg}
            </p>
          )}
        </>
      )}
      {view === 'multi' && (
        <>
          <p style={{ color: '#5C7C8A', size: 12, textAlign: 'left', paddingTop: 5, margin: 0 }}>
            <span style={{ paddingRight: 16 }}>{`Priority: ${priorityType || '-'}`}</span>
            <span style={{ paddingRight: 16 }}>{`Expected Task Time: ${taskTime}`}</span>
            <span style={{ paddingRight: 16 }}>{`Expected Total Drive: ${totalDrive}mi`}</span>
          </p>
          {conflictRequestStatus && (
            <Container>
              <div style={{ marginRight: 6, width: 12, height: 20, paddingTop: 3 }}>
                <InfoRed />
              </div>
              <p
                style={{
                  fontSize: 12,
                  textAlign: 'left',
                  paddingTop: 5,
                  margin: 0,
                  marginTop: 'initial',
                  color: theme.palette.primary.red,
                }}
              >
                {conflictMsg}
              </p>
            </Container>
          )}
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    priorityType: state.agency.priorityType,
  };
}

EtaInfo.propTypes = {
  conflict: PropTypes.shape(),
  totalDrive: PropTypes.number,
  priorityType: PropTypes.number,
  unitId: PropTypes.string,
  taskTime: PropTypes.string,
  view: PropTypes.string,
};

EtaInfo.defaultProps = {
  priorityType: null,
  totalDrive: null,
  unitId: null,
  taskTime: null,
  conflict: null,
  view: 'single',
};

export default connect(mapStateToProps)(EtaInfo);
