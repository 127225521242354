import RequestService from './request.service';
import CommentService from './comment.service';
import { parseCreateRequestPayloadWithStatus } from '../parsers/request.parser';
import { RequestStatus, COMMENTS } from '../enums';
import { parseInfoBoxForSubmit } from '../parsers/infobox.parser';
import { buildSchedulePayload } from '../parsers/schedule.parser';
import { isCar } from '../flags/service.flag';

export const createRequestFromGetEta = async state => {
  let request;
  let schedule = {};

  const multidayPlanner = state.requests.isNonTransport && window.ambulnzConfig.userSettings.isMultidayTripPlanner;
  const status = multidayPlanner ? RequestStatus.CREATED : RequestStatus.NEEDS_ASSIGNMENT;

  request = await RequestService.createRequest(parseCreateRequestPayloadWithStatus(state, status));

  request = await RequestService.submit(
    request.id,
    {
      toPending: true,
      ...parseInfoBoxForSubmit(state),
    },
    state.agency.requesterAgencyGroup,
  );

  if (state.eta.requestedAppointmentTime) {
    const response = await RequestService.getRequestedArrivalTime(request.id, state);
    request = await RequestService.updateRequest(request.id, { requestedArrivalTime: response.estimatedArrivalTime });
  }

  if (!multidayPlanner && !isCar(state.service)) {
    schedule = await RequestService.createNotReserved(request.id, {
      ...buildSchedulePayload(state),
      ...{ pickupTime: request.requestedArrivalTime },
    });
    request.scheduleId = schedule.id;
  }

  CommentService.create(request.id, state.infobox.notes, COMMENTS.TYPES.REQUEST);

  return request;
};
