import React from 'react';
import cx from 'classnames';
import AmbGrid from '../AmbGrid';
import Toggle from './Toggle';
import AmbTooltip from '../AmbTooltip/AmbTooltip';
import AmbChip from '../AmbChip';
import { styled } from '@mui/system';

const StyledAmbChip = styled(AmbChip)({
  '&.root': {
    height: 26,
    width: 'inherit',
    border: '1px solid rgba(0, 64, 83, 0.32)',
    borderRadius: 16,
    color: '#004053',
    fontSize: 15,
    display: 'inline-flex',
    outline: 'none',
    padding: 0,
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
  },
  '&.rootOn': {
    backgroundColor: '#e5fcff',
  },
  '&.rootOff': {
    backgroundColor: '#fff',
  },
  '&.labelOn': {
    color: '#007a8c',
    fontSize: 15,
  },
  '&.labelOff': {
    color: '#004053',
    fontSize: 15,
  },
  '&.clickableOff:hover, &.clickableOff:focus': {
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 64, 83, 0.64)',
  },
  '&.clickableOn:hover, &.clickableOn:focus': {
    backgroundColor: '#e5fcff',
    border: '1px solid rgba(0, 64, 83, 0.64)',
  },
  '&.disabledOff:hover': {
    backgroundColor: '#fff',
  },
  '&.disabledOn:hover': {
    backgroundColor: '#e5fcff',
  },
  '&.disabled': {
    color: '#6c798075',
    fontSize: 15,
  },
  '&.icon': {
    height: 14,
    width: 14,
  },
});

const offColor = (disabled, classes) => (disabled ? classes.disabled : classes.labelOff);

const DivNeededForTooltip = styled('div')``;

export default ({ checked, onChange, label, disabled, tooltip, isFrozen }) => {
  return (
    <AmbGrid>
      <AmbTooltip id={tooltip} data-testid={tooltip} title={tooltip || ''} placement="top-end">
        <DivNeededForTooltip>
          <Toggle on={checked} onToggle={onChange}>
            {({ on }) => {
              return (
                <StyledAmbChip
                  className={on ? cx('root', 'rootOn', 'clickableOn') : cx('root', 'rootOff', 'clickableOff')}
                  onClick={disabled ? null : () => onChange(!on)}
                  isDisabled={disabled || null}
                  isFrozen={isFrozen}
                  isOn={on}
                  text={label}
                  value={label}
                  tooltip={tooltip}
                  labelClassName={on ? 'labelOn' : offColor(disabled, 'labelOff')}
                  selectable
                />
              );
            }}
          </Toggle>
        </DivNeededForTooltip>
      </AmbTooltip>
    </AmbGrid>
  );
};
