import React from 'react';
import PropTypes from 'prop-types';
import AmbTooltip from './AmbTooltip/AmbTooltip';

const BadgeComponent = ({ badgeContent, id }) => {
  return (
    <>
      <span id={id} style={{ display: 'inline-flex', position: 'relative', verticalAlign: 'middle' }}>
        <div></div>
        <span
          style={{
            top: -7,
            right: -8,
            height: 20,
            width: 20,
            borderRadius: 5,
            color: '#fff',
            backgroundColor: '#ff8d18',
            fontWeight: 600,
            display: 'flex',
            zIndex: 1,
            position: 'absolute',
            flexWrap: 'wrap',
            fontSize: '0.75rem',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {badgeContent}
        </span>
      </span>
    </>
  );
};

const AmbBadge = ({ name, title, count, classes, ...props }) =>
  count !== 0 && (
    <AmbTooltip aria-label={`${name}-tooltip`} name={`${name}-tooltip`} title={title} placement="top" {...props}>
      <span>
        <BadgeComponent badgeContent={count} id="locationsWarningCounter" />
      </span>
    </AmbTooltip>
  );

AmbBadge.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  count: PropTypes.number,
};
AmbBadge.defaultProps = {
  title: '',
  count: 0,
};
export default AmbBadge;
