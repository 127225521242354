export default class Location {
  constructor(location) {
    const defaultState = { emsFacilityId: null };
    this.locationAggregate = Object.assign({}, defaultState, location);
  }

  get location() {
    return this.locationAggregate;
  }

  // temporary solution cause of inconsistent address/place/emsFacility entities
  static fromEmsFacility(emsFacility) {
    return new Location({ emsFacilityId: emsFacility.id });
  }

  // temporary solution cause of inconsistent address/place/emsFacility entities
  static fromFacilitySchema(facilitySchema) {
    return new Location({ emsFacilityId: facilitySchema.emsFacilityId });
  }
}
