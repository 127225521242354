import React from 'react';

export default function({ fill = '#004053' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 14 14"
      version="1.1"
      viewBox="0 0 14 14"
      xmlSpace="preserve"
      height="14px"
    >
      <switch>
        <g>
          <g fill={fill}>
            <path d="M7 0C3.1 0 0 3.1 0 7s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7zm0 13c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z" />
            <circle cx="7" cy="4" r="1" />
            <path d="M7 6c-.4 0-.8.3-.8.8v3.5c0 .4.4.7.8.7s.8-.3.8-.8V6.8c0-.5-.4-.8-.8-.8z" />
          </g>
        </g>
      </switch>
    </svg>
  );
}
