import { createReducer } from 'redux-act';
import * as action from '../actions/offer.actions';
import offerSchema from '../../schemas/offer.schema';

export const initialState = {
  offers: [],
  createInProgress: false,
};

const requestsReducer = createReducer(
  {
    [action.getOffersSuccess]: (state, payload) => ({
      ...state,
      offers: payload.map(offer => offerSchema.parse(offer)),
    }),
    [action.setCreateInProgress]: (state, payload) => ({
      ...state,
      createInProgress: payload,
    }),
  },
  initialState,
);

export default requestsReducer;
