import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '../../common/icons/check';
import { ListItemIcon, ListItemText } from '@mui/material';
import { MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import AmbCircularProgress from '../../common/AmbCircularProgress';
import DuplicateRequestIcon from '../../common/icons/DuplicateRequestIcon';
import { styled } from '@mui/system';

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 36,
});

const DuplicateRequest = props => {
  const click = () => {
    props.duplicate();
  };

  const handleClose = () => {
    props.closeErrorModal();
  };

  const shouldShowDuplicate = () => {
    const { request } = props;

    return (
      !window.ambulnzConfig.isAnExternalApp &&
      request &&
      request.id &&
      !request.isWillCall &&
      !request.originalRequestId
    );
  };

  const duplicate = (
    <>
      <StyledListItemIcon>
        <DuplicateRequestIcon />
      </StyledListItemIcon>
      <ListItemText primary="Duplicate" />
    </>
  );
  const loading = (
    <>
      <StyledListItemIcon>
        <AmbCircularProgress
          propsStyles={{
            width: '15px',
            height: '15px',
            position: 'relative',
            top: '3px',
          }}
        />
      </StyledListItemIcon>
      <ListItemText primary="Duplicating" />
    </>
  );
  const finished = (
    <>
      <StyledListItemIcon>
        <CheckIcon style={{ marginRight: 0, width: 24, height: 24 }} />
      </StyledListItemIcon>
      <ListItemText primary="Duplicated" />
    </>
  );
  let content = duplicate;

  if (props.loading) {
    content = loading;
  }

  if (props.completed) {
    content = finished;
  }

  const requestDuplicateButton = (
    <MenuItem onClick={() => click()} disabled={props.completed || null}>
      {content}
    </MenuItem>
  );

  return (
    <>
      {shouldShowDuplicate() ? requestDuplicateButton : ''}

      <Dialog
        open={!!props.errorMessage}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Request Duplication Failed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="primary"
            autoFocus
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(30, 192, 217, 0.1)',
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DuplicateRequest.propTypes = {
  loading: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  duplicate: PropTypes.func.isRequired,
  closeErrorModal: PropTypes.func.isRequired,
  request: PropTypes.shape().isRequired,
};

DuplicateRequest.defaultProps = {};

export default DuplicateRequest;
