import * as moment from 'moment';
import * as agencyActions from '../../actions/agency.actions';
import * as etaActions from '../../actions/eta.actions';

const fetchAgenciesSuccess =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (agencyActions.fetchAgenciesSuccess.match(action)) {
      const agencies = getState().agency.agencies;
      if (agencies.length === 1) {
        const agency = agencies[0];
        dispatch(agencyActions.fetchPlace(agency));
        dispatch(agencyActions.setAgency(agency));
      } else {
        dispatch(agencyActions.setAgencyValidation(true));
      }
    }
  };

const setAgencyGroupEtaOffset =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (agencyActions.fetchPlace.match(action)) {
      const state = getState();
      const selectedTransportReason = state.service.callTypeOptions.find(callType => callType.key === state.service.callTypeId);
      const offset = selectedTransportReason?.defaultFromNowPickupTimeInMins || state.agency.requesterAgencyGroup.defaultTimeOffsetMinutes || 5;
      dispatch(etaActions.setDateTime(moment().add(offset, 'minutes')));
    }
  };
const middlewares = [fetchAgenciesSuccess, setAgencyGroupEtaOffset];
export default middlewares;
