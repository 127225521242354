import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RequestingAgency from './RequestingAgency.validations';
import * as componentActions from './RequestingAgency.actions';
import * as agencyActions from '../../redux/actions/agency.actions';
import { handleSuggestionChange } from '../common/SuggestionsInput/SuggestionsInput';
import { requestingAgencyCollapse, requestingAgencyExpanded } from '../../flags/collapse.flag';

const RequestingAgencyContainer = ({ handleFillAgencyAddress, ...props }) => {
  useLayoutEffect(() => {
    handleFillAgencyAddress();
  }, [handleFillAgencyAddress]);

  const handleChange = (query, downshiftState) => {
    handleSuggestionChange(props.query, props.select, props.clear)(query, downshiftState);
    handleFillAgencyAddress();
  };

  return <RequestingAgency handleChange={handleChange} {...props} />;
};

RequestingAgencyContainer.propTypes = {
  query: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  handleFillAgencyAddress: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    agencies: state.agency.agencies,
    input: state.agency.input,
    selectedAgency: state.agency.selectedAgency,
    requesterAgencyId: state.agency.requesterAgencyId,
    searchAgenciesInProgress: state.agency.searchAgenciesInProgress,
    shouldCollapse: requestingAgencyCollapse(state),
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    triggerValidation: state.agency.triggerValidation,
    autoFocus: state.agency.autoFocus,
    expanded: requestingAgencyExpanded(state),
    user: state.user.model,
  };
}

export default connect(mapStateToProps, {
  ...componentActions,
  ...agencyActions,
})(RequestingAgencyContainer);
