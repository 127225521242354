import { get } from 'lodash';
import { PROGRAM_CODES } from 'enums';
import UserSchema from '../schemas/user.schema';

export default class UserSettingsService {
  constructor(user = {}) {
    this.defaultUserSettings = {
      isBillingEnabled: true,
      ampm: false,
    };

    this.user = UserSchema.parse(user);
    this.settings = {
      ...this.defaultUserSettings,
      isBillingEnabled: this.isBillingEnabled(),
      isMobileHealthServiceRequesterEnabled: this.isMobileHealthServiceRequesterEnabled(),
      isRoutePlanner: this.isRoutePlanner(),
      isRoutePlannerDispatcher: this.isRoutePlannerDispatcher(),
      isMultidayTripPlanner: this.isMultidayTripPlanner(),
      defaultToMedicalHealthServiceAsRequester: this.user.defaultToMedicalHealthServiceAsRequester,
      ampm: this.user.displayAMPM,
    };
  }

  isBillingEnabled() {
    const isBillingEnabledSetting = get(this.user, 'facilities[0].isBillingEnabled');
    return typeof isBillingEnabledSetting === 'boolean' ? isBillingEnabledSetting : true;
  }

  isMobileHealthServiceRequesterEnabled() {
    return !!this.user.programs.find(p => p.code === PROGRAM_CODES.MHS);
  }

  isRoutePlanner() {
    return !!this.user.programs.find(p => p.code === PROGRAM_CODES.GEOSERVICE_TRIP_PLANNER);
  }

  isRoutePlannerDispatcher() {
    return !!this.user.programs.find(p => p.code === PROGRAM_CODES.GEOSERVICE_TRIP_PLANNER_DISPATCHER);
  }

  isMultidayTripPlanner() {
    return !!this.user.programs.find(p => p.code === PROGRAM_CODES.MULTIDAY_TRIP_PLANNER);
  }
}
