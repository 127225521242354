import * as moment from 'moment';

function createEligibilityPatient(state) {
  return {
    firstName: state.infobox.patientFirstName,
    lastName: state.infobox.patientLastName,
    dob: moment(state.infobox.patientBirthday).format('MM/DD/YYYY'),
  };
}

export default function createEligibilityData(state, coordinationTypeId) {
  const coverage = state.infobox.coverages[coordinationTypeId];

  return {
    ...createEligibilityPatient(state),
    policy: coverage.policyNumber,
    payerId: coverage.payer.payerId,
    coordinationTypeId,
    tripDate: state.eta.requestedArrivalTime,
  };
}
