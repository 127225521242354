import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 17 20" {...props}>
      <path style={{ fill: '#919699' }} d="M11,20H0V0h11V20z M1,19h9V1H1V19z" />
      <path style={{ fill: '#919699' }} d="M17,20h-7V8h7V20z M11,19h5V9h-5V19z" />
      <rect x="2.5" y="3" style={{ fill: '#A5A5A5' }} width="6" height="1" />
      <rect x="2.5" y="6" style={{ fill: '#919699' }} width="6" height="1" />
      <rect x="2.5" y="12" style={{ fill: '#919699' }} width="6" height="1" />
      <rect x="2.5" y="15" style={{ fill: '#919699' }} width="6" height="1" />
      <rect x="2.5" y="9" style={{ fill: '#919699' }} width="6" height="1" />
      <rect x="11" y="12" style={{ fill: '#919699' }} width="3.5" height="1" />
      <rect x="11" y="15" style={{ fill: '#919699' }} width="3.5" height="1" />
    </svg>
  );
}
