import React from 'react';
import { connect } from 'react-redux';
import PatientInfo from './PatientInfo.frame';
import * as infoboxActions from '../../../redux/actions/infobox.actions';
import { patientInfoCollapse } from '../../../flags/collapse.flag';
import { formatPatientDetailsTitle } from '../PatientInfo.formatter';

function PatientInfoContainer(props) {
  return <PatientInfo {...props} />;
}

function mapStateToProps(state) {
  return {
    shouldCollapse: patientInfoCollapse(state),
    triggerValidation: state.formFlow.triggerValidation,
    title: formatPatientDetailsTitle(state),
  };
}

export default connect(mapStateToProps, { ...infoboxActions })(PatientInfoContainer);
