import schema from 'schm';

export default schema({
  activeAt: Date,
  activatorId: Number,
  activatorName: String,
  authorizationNumber: '',
  coordinationTypeId: Number,
  isActive: false,
  isBedBound: false,
  medicarePartB: false,
  payer: {},
  payerInput: '',
  payerName: '',
  payers: [],
  payerQuery: '',
  policyNumber: '',
  requiresOxygen: false,
  isRequired: false,
  warnings: [],
});
