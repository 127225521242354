import { createAction } from '@reduxjs/toolkit';

export const deletePhoneRecipient = createAction('[RequestShare] deletePhoneRecipient');

export const reducer = {
  [deletePhoneRecipient]: (state, recipient) => ({
    ...state,
    phoneRecipients: state.phoneRecipients.filter(el => el !== recipient),
  }),
};
