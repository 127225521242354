import { get } from 'lodash';

export const getCoordsByNavigator = state => ({
  lat: state.coords.latitude,
  lng: state.coords.longitude,
});
export const getCoordsByAgency = state => state[0].places[0].locationGps;
export const getCoordsByAddress = state => ({
  lat: state[0].geometry.location.lat(),
  lng: state[0].geometry.location.lng(),
});
export const getAddress = state => {
  const address = { street1: '' };
  state.forEach(l => {
    if (l.types[0] === 'premise') address.street1 += `${l.short_name} `;
    if (l.types[0] === 'street_number') address.street1 += `${l.short_name} `;
    if (l.types[0] === 'route') address.street1 += l.long_name;
    if (l.types[0] === 'locality' || l.types.some(type => type === 'sublocality')) address.city = l.short_name;
    if (l.types[0] === 'administrative_area_level_1') address.state = l.short_name;
    if (l.types[0] === 'postal_code') address.postalCode = l.long_name;
    if (l.types[0] === 'country') address.country = l.short_name;
  });
  return address;
};
export const getPlace = (prediction, state) => ({
  address: getAddress(prediction[0].address_components),
  locationGps: getCoordsByAddress(prediction),
  googlePlaceId: prediction[0].place_id,
  formattedAddress: () => prediction[0].formatted_address,
  typeId: state.location.placeTypeEnum.general,
});

export const getPlaceFromGeopointPrediction = (geopointPrediction, state) => ({
  address: getAddress(geopointPrediction.addressComponents),
  locationGps: geopointPrediction.geopoint,
  googlePlaceId: geopointPrediction.id,
  formattedAddress: () => geopointPrediction.formattedAddress,
  globalPlusCode: get(geopointPrediction.plusCode, 'global_code'),
  compoundPlusCode: get(geopointPrediction.plusCode, 'compound_code'),
  typeId: state.location.placeTypeEnum.geopoint,
});

export const getCoordsByCurrentUser = state => {
  const { facilities } = state.user.model;
  let facility = facilities.find(f => f.type === 1);
  if (!facility) {
    [facility] = facilities;
  }

  if (facility.place) {
    return facility.place.locationGps;
  }

  return undefined;
};

export const getGeopointPrediction = (geocodeResults, geopoint) => {
  const plusCodeGeocodeResults = geocodeResults.filter(
    geocodeResult => geocodeResult.types && geocodeResult.types.length && geocodeResult.types.includes('plus_code'),
  );

  if (!plusCodeGeocodeResults.length) {
    return {
      place_id: null,
      description: `${geopoint.lat}, ${geopoint.lng}`,
      plus_code: null,
      geopoint,
      address_components: [],
      formatted_address: '',
    };
  }

  return {
    place_id: get(plusCodeGeocodeResults, '[0].place_id'),
    description: `${geopoint.lat}, ${geopoint.lng}`,
    geopoint,
    plus_code: get(plusCodeGeocodeResults, '[0].plus_code'),
    address_components: get(plusCodeGeocodeResults, '[0].address_components'),
    formatted_address: get(plusCodeGeocodeResults, '[0].formatted_address'),
  };
};
