import * as Sentry from '@sentry/react';

const SENTRY_DSN = 'https://1880ab4a11c0483fba316211eaa82001@o165371.ingest.sentry.io/4505304531402752';
const HOSTNAMES = ['nychhc-epic.ambulnz.net', 'newyork.ambulnz.net', 'newyork.ambulnz-dev.net'];
const APIS = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)?ambulnz\.net\/api)/i;
// const sentryEnabled = HOSTNAMES.includes(window.location.hostname);
const sentryEnabled = false;

const config = sentryEnabled
  ? {
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['localhost', /^https:\/\/ambulnz\.net\/api/],
        }),
        new Sentry.Replay({
          networkDetailAllowUrls: [
            window.location.origin,
            'https://newyork.ambulnz-dev.net/api',
            'https://newyork.ambulnz.net/api',
            APIS,
          ],
          networkRequestHeaders: ['Cache-Control'],
          networkResponseHeaders: ['Referrer-Policy', 'X-Request-Id'],
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    }
  : {};

if (sentryEnabled) {
  Sentry.init({
    dsn: SENTRY_DSN,
    ...config,
  });
  window.Sentry = Sentry;
}

export function setUser(body) {
  if (!sentryEnabled) return;
  Sentry.setUser(body);
}
export function captureException(error) {
  if (!sentryEnabled) return;
  Sentry.captureException(error);
}
export function captureMessage(msg) {
  if (!sentryEnabled) return;
  Sentry.captureMessage(msg);
}
