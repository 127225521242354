import React from 'react';
import { connect } from 'react-redux';
import TurndownDialogFrame from './TurndownDialogFrame';
import * as turndownActions from '../../../redux/actions/turndown.actions';

const TurndownDialogContainer = props => <TurndownDialogFrame {...props} />;

function mapStateToProps(state) {
  return {
    open: state.turndown.open,
    turndownTypes: state.turndown.turndownTypes,
    comments: state.turndown.comments,
    isDispatcher: state.user.model.isDispatcher,
  };
}
export default connect(mapStateToProps, { ...turndownActions })(TurndownDialogContainer);
