/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MarkerF, OverlayView, OverlayViewF } from '@react-google-maps/api';
import ambulnzIcon from '../common/icons/blue-amb.png';
import uberIcon from '../common/icons/black-uber.png';
import liveryIcon from '../common/icons/livery.png';
import { getServiceTypeName } from '../Timeline/Timeline.parser';

function getIcon(serviceType) {
  switch (serviceType) {
    case 'Curbside':
      return uberIcon;
    case 'Livery':
      return liveryIcon;
    default:
      return ambulnzIcon;
  }
}

const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
  return {
    x: offsetWidth + labelAnchor.x,
    y: offsetHeight + labelAnchor.y,
  };
};

const VehicleMarker = ({ position, heading, serviceType }) => (
  <>
    <MarkerF key="m" icon=" " position={position}></MarkerF>
    <OverlayViewF
      key="mwl"
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(x, y) => getPixelPositionOffset(-(x / 2), -(y / 2), { x: 8, y: 2 })}
    >
      <div id="pickup-marker-overlay" style={{ transform: `rotate(${heading}deg)` }}>
        <img src={getIcon(serviceType)} alt="" />
      </div>
    </OverlayViewF>
  </>
);

VehicleMarker.propTypes = {
  position: PropTypes.shape().isRequired,
  currentSegment: PropTypes.shape().isRequired,
  heading: PropTypes.number,
  serviceType: PropTypes.string.isRequired,
};

VehicleMarker.defaultProps = {
  heading: 0,
};

function mapStateToProps({ service, schedule: { currentSegment } }) {
  return {
    serviceType: getServiceTypeName(service),
    currentSegment,
  };
}

export default connect(mapStateToProps, {})(VehicleMarker);
