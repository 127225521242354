import React, { forwardRef } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import pickersTheme from './AmbPickersTheme';

const AmbDatePicker = forwardRef(({ required, error, ...props }, ref) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={pickersTheme}>
        <DesktopDatePicker
          {...props}
          inputRef={ref}
          slotProps={{ textField: { variant: 'standard', required, error } }}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
});

export default AmbDatePicker;
