import * as eligibilityActions from '../actions/eligibility.actions';
import createMiddleware from './helper';

export const checkEligibilityMdl = createMiddleware(eligibilityActions.checkEligibility, ({ dispatch, payload }) => {
  const { coordinationTypeId } = payload;
  dispatch(eligibilityActions.setLoading({ coordinationTypeId, value: true }));
});

export const checkEligibilitySuccessMdl = createMiddleware(
  eligibilityActions.checkEligibilitySuccess,
  ({ dispatch, payload, getState }) => {
    const { coordinationTypeId, sessionId, error, reportHTML } = payload;
    const { infobox } = getState();
    const coverage = infobox.coverages[coordinationTypeId];

    dispatch(eligibilityActions.loadPortalInfo(coverage.payer.payerId));

    if (error) {
      dispatch(eligibilityActions.resetButtons());
      dispatch(eligibilityActions.error(error.message));

      return;
    }

    if (reportHTML) {
      dispatch(eligibilityActions.resetButtons());
      dispatch(eligibilityActions.setHTML(reportHTML));

      return;
    }

    dispatch(eligibilityActions.setCreating({ coordinationTypeId, value: true, sessionId }));
  },
);

function checkEligibilitySessionId(state, sessionId) {
  return Object.values(state.eligibility.eligibilities).find(eligibility => eligibility.sessionId === sessionId);
}

export const socketResponseReceivedMdl = createMiddleware(
  eligibilityActions.socketResponseReceived,
  ({ dispatch, action, getState }) => {
    const { meta, resource_id } = action.payload;

    if (!checkEligibilitySessionId(getState(), resource_id)) {
      return;
    }

    if (meta.error) {
      dispatch(eligibilityActions.resetButtons());
      dispatch(eligibilityActions.error(meta.error.message));

      return;
    }

    dispatch(eligibilityActions.checkEligibility({ coordinationTypeId: meta.coordination_type_id }));
  },
);

export const portalInfoLoadedMdl = createMiddleware(eligibilityActions.portalInfoLoaded, ({ payload, dispatch }) => {
  const [payerOptions] = payload;

  if (payerOptions) {
    dispatch(eligibilityActions.setPortalInfo(payerOptions.portalInfo || ''));
  }
});

export const closeFrameMdl = createMiddleware(eligibilityActions.closeFrame, ({ dispatch }) => {
  dispatch(eligibilityActions.resetButtons());
});

export const eligibilityMdl = [
  checkEligibilityMdl,
  checkEligibilitySuccessMdl,
  socketResponseReceivedMdl,
  portalInfoLoadedMdl,
  closeFrameMdl,
];
