import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AmbGrid from './AmbGrid';
import ConfirmationDialog from './ConfirmationDialog';
import AmbCircularProgress from './AmbCircularProgress';
import { styled } from '@mui/system';

const ButtonContainer = styled(Button)({
  bottom: 0,
  width: '100%',
  position: 'sticky',
  zIndex: 100,
  color: '#fff',
});

const Btn = ({ handleClick, disabled }) => (
  <ButtonContainer variant="contained" onClick={handleClick} id="SaveButton" disabled={disabled || null}>
    <span>SAVE</span>
  </ButtonContainer>
);

const Spinner = () => (
  <AmbGrid style={{ bottom: 0, width: '100%', position: 'sticky', zIndex: 100, textAlign: 'center', marginTop: 35 }}>
    <AmbCircularProgress />
  </AmbGrid>
);

const SaveBtnConfirmationDialog = ({ dialogOpen, handleClose, title, body }) => (
  <ConfirmationDialog open={dialogOpen} onClose={() => handleClose(false)} title={title}>
    <div>{body}</div>
  </ConfirmationDialog>
);

const SaveButton = props => (
  <>
    {props.showSaveButton && !props.showLoading && <Btn {...props} />}
    {props.showLoading && <Spinner {...props} />}
    {props.dialogOpen && <SaveBtnConfirmationDialog {...props} />}
  </>
);

SaveButton.propTypes = {
  showSaveButton: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool.isRequired,
};

SaveBtnConfirmationDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

Btn.propTypes = {
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SaveButton;
