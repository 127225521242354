import { createAction } from '@reduxjs/toolkit';

export const onCancelReasonTypeChange = createAction('[CancelRequest] onCancelReasonTypeChange');

export const reducer = {
  [onCancelReasonTypeChange]: (state, payload) => ({
    ...state,
    cancelReasonTypeId: payload,
  }),
};
