import React from 'react';
import PropTypes from 'prop-types';
import { Label, Eta } from './elements';

export default function EtaLabel(props) {
  return (
    <>
      <Label className="eta-label__label" htmlFor={props.labelName} {...props}>
        {props.labelName}
      </Label>
      <Eta className="eta-label__eta" id={props.labelName} {...props}>
        {props.eta}
      </Eta>
    </>
  );
}

EtaLabel.propTypes = {
  eta: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
};
