import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 14 18" {...props}>
      <path style={{ fill: '#919699' }} d="M14,0H0V14l4,4H14V0ZM1,13V1H13V17H5V13Zm3,3.59L1.41,14H4v2.59Z" />
      <path
        style={{ fill: '#919699' }}
        d="M7.29,13.05a.36.36,0,0,1-.36.35.35.35,0,0,1-.34-.35v-.9a2.43,2.43,0,0,1-2-1.52.56.56,0,0,1,.34-.71.57.57,0,0,1,.73.36A1.41,1.41,0,0,0,7,11.16a1.24,1.24,0,0,0,1.38-1c0-.67-.48-1-1.16-1.18l-.91-.23c-1.57-.42-1.81-1.31-1.81-2A2.16,2.16,0,0,1,6.59,4.73V4a.35.35,0,0,1,.34-.35A.36.36,0,0,1,7.29,4v.78A2,2,0,0,1,9.08,6.06a.52.52,0,0,1-.29.69.54.54,0,0,1-.7-.33,1.28,1.28,0,0,0-1.21-.65,1.14,1.14,0,0,0-1.26,1c0,.29.11.73,1,1L7.52,8c1.47.39,2.08,1.21,2,2.26a2.22,2.22,0,0,1-2.2,1.93Z"
      />
    </svg>
  );
}
