import React from 'react';

const AmbSwitch = ({ checked, onChange, id, disabled, avatar }) => {
  const switchStyles = {
    position: 'relative',
    display: 'inline-block',
    width: avatar ? 40 : 32,
    height: avatar ? 16 : 12,
    marginRight: 10,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  const sliderStyles = {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: disabled ? 'rgba(0, 0, 0, 0.1)' : checked ? 'rgba(30, 192, 217, 0.5)' : 'rgba(0, 0, 0, 0.4)',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '34px',
  };

  const roundStyles = {
    position: 'absolute',
    height: 20,
    width: 20,
    top: -4,
    left: 0,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    backgroundColor: disabled ? '#BDBDBD' : checked ? '#1EC0D9' : '#FFFFFF',
    borderRadius: '50%',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    transform: checked ? 'translateX(14px)' : 'translateX(0)',
  };

  const avatarStyles = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    width: 26,
    height: 26,
    borderRadius: '50%',
    overflow: 'hidden',
    userSelect: 'none',
    backgroundColor: checked ? '#1EC0D9' : '#BDBDBD',
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: 600,
    paddingTop: 2,
  };

  return (
    <label style={switchStyles}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        style={{ display: 'none' }}
        disabled={disabled}
      />
      <span style={sliderStyles}>
        <span style={roundStyles}>{avatar && <div style={avatarStyles}>{avatar}</div>}</span>
      </span>
    </label>
  );
};

export default AmbSwitch;
