import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiTrendingNeutral } from '@mdi/js';
import EtaLabel from './EtaLabel/EtaLabel';
import SubLabel from './EtaLabel/SubLabel';
import AmbGrid from 'components/common/AmbGrid';

const AppointmentSummary = props => (
  <AmbGrid style={{ fontWeight: 800, textAlign: 'center', gap: '8px', justifyContent: 'center' }}>
    <AmbGrid style={{ flexDirection: 'column' }}>
      <EtaLabel labelName="Pickup" fontSize={12} fontWeight={500} color="#5c7c8a" eta={props.etaTime} />
      <SubLabel {...props} fontSize={10} etaDate={props.etaDate} />
    </AmbGrid>
    <AmbGrid>
      <Icon
        path={mdiTrendingNeutral}
        size={0.67}
        style={{
          marginTop: 12,
          marginLeft: 5,
          marginRight: 5,
        }}
      />
    </AmbGrid>
    <AmbGrid style={{ flexDirection: 'column' }}>
      <EtaLabel labelName="Arrival" fontSize={12} fontWeight={500} color="#5c7c8a" eta={props.dropoffTime} />
      <SubLabel {...props} etaDate={props.dropoffDate} fontSize={10} />
    </AmbGrid>
    <AmbGrid>
      <Icon
        path={mdiTrendingNeutral}
        size={0.67}
        style={{
          marginTop: 12,
          marginLeft: 5,
          marginRight: 5,
        }}
      />
    </AmbGrid>
    <AmbGrid style={{ flexDirection: 'column' }}>
      <EtaLabel labelName="Appt." fontSize={12} fontWeight={500} color="#5c7c8a" eta={props.appointmentTime} />
      <SubLabel {...props} etaDate={props.appointmentDate} fontSize={10} />
    </AmbGrid>
  </AmbGrid>
);

AppointmentSummary.propTypes = {
  acceptETAInProgress: PropTypes.bool.isRequired,
  noAutoEtaRequest: PropTypes.bool.isRequired,
  unitId: PropTypes.string,
  warning: PropTypes.bool,
  fareAmount: PropTypes.string,
  isCar: PropTypes.bool.isRequired,
};

AppointmentSummary.defaultProps = {
  unitId: null,
  warning: false,
  fareAmount: null,
};

export default AppointmentSummary;
