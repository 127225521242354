import * as newOrExistingActions from '../actions/newOrExisting.actions';

export const openNewTripMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (newOrExistingActions.openNewTrip.match(action)) {
      dispatch(newOrExistingActions.openNewOrExistingDialog(false));
    }
  };

export const openExistingTripMdl =
  ({ getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (newOrExistingActions.openExistingTrip.match(action)) {
      window.location.assign(
        `/dispatcher2?requestingAgencyId=${getState().newOrExisting.agencyId}&requestingAgencyName=${
          getState().newOrExisting.agencyName
        }`,
      );
    }
  };

export const newOrExistingMdl = [openNewTripMdl, openExistingTripMdl];
