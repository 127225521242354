import { createAction } from '@reduxjs/toolkit';

export const openPCSModal = createAction('[PhysicianCertificationStatement] openPCSModal');
export const donePCSModal = createAction('[PhysicianCertificationStatement] donePCSModal');

export const reducer = {
  [openPCSModal]: (state, payload) => ({
    ...state,
    open: payload,
  }),
  [donePCSModal]: (state, payload) => ({
    ...state,
    continue: payload,
  }),
};
