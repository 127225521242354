import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MedicareQuestions from './MedicareQuestions';
import AmbRadioInput from '../../components/common/AmbRadioInput';

const Title = styled.div`
  padding-bottom: 5px;
  font-size: 14px;
`;
const SecondTitle = styled.div`
  padding-top: 21px;
  padding-bottom: 5px;
  font-size: 14px;
`;

const StyledLabel = styled.label`
  display: flex;
  margin: 5px 0;
`;

const SectionOne = ({ dispatch, ...props }) => {
  const [checkedTitle, setCheckedTitle] = useState(null);
  const [checkedSecondaryTitle, setCheckedSecondaryTitle] = useState(null);

  const handleChangeTitle = e => {
    dispatch({
      type: 'HAS_PRIOR_AUTHORIZATION',
      hasPriorAuthorization: e.target.value,
    });
    setCheckedTitle(e.target.value);
  };

  const handleChangeSecondaryTitle = e => {
    dispatch({
      type: 'HAS_MEDICARE',
      hasMedicare: e.target.value,
    });
    setCheckedSecondaryTitle(e.target.value);
  };

  return (
    <>
      <Title>
        {`Does the patient have a Prior Authorization number for BLS
            transportation provided by Ambulnz for ${props.pickupTime}?`}
      </Title>
      <div>
        <form aria-label="HasPriorAuthorization" name="HasPriorAuthorization" value={props.hasPriorAuthorization}>
          <StyledLabel>
            <AmbRadioInput
              value="Yes"
              onChange={handleChangeTitle}
              checked={checkedTitle === 'Yes'}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            Yes
          </StyledLabel>
          <StyledLabel>
            <AmbRadioInput
              value="No"
              onChange={handleChangeTitle}
              checked={checkedTitle === 'No'}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            No
          </StyledLabel>
        </form>
      </div>
      <SecondTitle>Does the patient have MediCare Part B?</SecondTitle>
      <div>
        <form aria-label="HasMedicare" name="HasMedicare" value={props.hasMedicare}>
          <StyledLabel>
            <AmbRadioInput
              value="Yes"
              onChange={handleChangeSecondaryTitle}
              checked={checkedSecondaryTitle === 'Yes'}
              disabled={props.hasPriorAuthorization === 'none' || null}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            Yes, patient has Medicare
          </StyledLabel>
          {props.hasMedicare === 'Yes' && props.requireMedicareQuestions && (
            <MedicareQuestions
              admittanceDate={props.admittanceDate}
              dispatch={dispatch}
              medicareSuboption={props.medicareSuboption}
            />
          )}
          <StyledLabel>
            <AmbRadioInput
              value="Medicaid"
              onChange={handleChangeSecondaryTitle}
              checked={checkedSecondaryTitle === 'Medicaid'}
              disabled={props.hasPriorAuthorization === 'none' || null}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            No, patient has Medicaid
          </StyledLabel>
          <StyledLabel>
            <AmbRadioInput
              value="commercial insurance"
              onChange={handleChangeSecondaryTitle}
              checked={checkedSecondaryTitle === 'commercial insurance'}
              disabled={props.hasPriorAuthorization === 'none' || null}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            No, patient has commercial insurance or hospice
          </StyledLabel>
          <StyledLabel>
            <AmbRadioInput
              value="requesting agency covering"
              onChange={handleChangeSecondaryTitle}
              checked={checkedSecondaryTitle === 'requesting agency covering'}
              disabled={props.hasPriorAuthorization === 'none' || null}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            {`No, ${props.requestingAgencyPayer.name} is covering cost of transportation`}
          </StyledLabel>
          <StyledLabel>
            <AmbRadioInput
              value="self pay"
              onChange={handleChangeSecondaryTitle}
              checked={checkedSecondaryTitle === 'self pay'}
              disabled={props.hasPriorAuthorization === 'none' || null}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            No, Patient will be responsible for the invoice
          </StyledLabel>
        </form>
      </div>
    </>
  );
};

SectionOne.propTypes = {
  requestingAgencyPayer: PropTypes.shape().isRequired,
  pickupTime: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasPriorAuthorization: PropTypes.string.isRequired,
  hasMedicare: PropTypes.string.isRequired,
};

export default SectionOne;
