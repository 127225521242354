import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Requester from './Requester.validations';
import * as infoboxActions from '../../../redux/actions/infobox.actions';

const RequesterContainer = props => {
  const [validateRequester, setValidateRequester] = useState(false);
  useEffect(() => {
    setValidateRequester(props.isRequester);
  }, [props.isRequester]);

  return <Requester {...props} validateRequester={validateRequester} />;
};

const expanded = state => (state.formFlow.loadedRequest ? false : state.user.model.isDispatcher);

function mapStateToProps(state) {
  return {
    notes: state.infobox.notes,
    requesterFirstName: state.infobox.requesterFirstName,
    requesterLastName: state.infobox.requesterLastName,
    requesterPhone: state.infobox.requesterPhone,
    requesterExtension: state.infobox.requesterExtension,
    triggerValidation: state.formFlow.triggerValidation,
    focusRequesterFirstName: state.infobox.focusRequesterFirstName,
    showEtaProgress: state.eta.showEtaProgress,
    expanded: expanded(state),
    isRequester: state.user.model.isRequester,
  };
}

export default connect(mapStateToProps, { ...infoboxActions })(RequesterContainer);
