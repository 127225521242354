import React, { useState } from 'react';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import Share from '../common/icons/Share';
import AmbGrid from 'components/common/AmbGrid';
import AmbTextField from 'components/common/AmbTextField';
import { theme } from '../../theme';

const TelehealthLink = props => {
  const [expanded, setExpanded] = useState(true);
  const [telehealthLinkValue, setTelehealthLinkValue] = useState(props.telehealthLinkValue);

  const togglePanel = () => {
    setExpanded(!expanded);
  };

  const handleTelehealthLinkChange = e => {
    setTelehealthLinkValue(e.target.value);
  };

  return (
    <AmbExpansionPanel
      icon={<Share style={{ height: 16, width: 16, marginTop: 2, fill: theme.palette.icon.primary }} />}
      id="telehealth-link-panel"
      title="Telehealth Link"
      expanded={expanded}
      onTogglePanel={togglePanel}
    >
      <AmbGrid style={{ minWidth: '100%', paddingTop: 18 }}>
        <AmbTextField
          id="telehealth-link-input"
          placeholder="Enter Telehealth Visit URL"
          label="Telehealth Link"
          InputLabelProps={{
            style: { ...theme.mixins.inputLabel, marginBottom: 5 },
            shrink: true,
          }}
          onChange={handleTelehealthLinkChange}
          onBlur={e => props.setTelehealthLink(e.target.value)}
          value={telehealthLinkValue}
        />
      </AmbGrid>
    </AmbExpansionPanel>
  );
};

export default TelehealthLink;
