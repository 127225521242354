import { createAction } from '@reduxjs/toolkit';

export const openMedicalNecessityModal = createAction('[MedicalNecessity] openMedicalNecessityModal');

export const reducer = {
  [openMedicalNecessityModal]: (state, payload) => ({
    ...state,
    open: payload,
  }),
};
