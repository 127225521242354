import { createAction } from '@reduxjs/toolkit';

export const queryAgencies = createAction('[Agency] queryAgencies');
export const fetchAgenciesSuccess = createAction('[Agency] fetchAgenciesSuccess');
export const fetchPlace = createAction('[Agency] fetchPlace');
export const getPickupLocationSuccess = createAction('[Agency] getPickupLocationSuccess');
export const getDestinationLocationSuccess = createAction('[Agency] getDestinationLocationSuccess');
export const setAgency = createAction('[Agency] setAgency');
export const clearAgency = createAction('[Agency] clearAgency');
export const setSearchAgenciesInProgress = createAction('[Agency] setSearchAgenciesInProgress');
export const setAutoFocus = createAction('[Agency] setAutoFocus');
export const setAgencyValidation = createAction('[Agency] setAgencyValidation');
export const getPickupLocationByAgency = createAction('[Agency] getPickupLocationByAgency');
export const getDestinationLocationByAgency = createAction('[Agency] getDestinationLocationByAgency');
export const resetAgency = createAction('[Agency] resetAgency');
