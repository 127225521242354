import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as requestsActions from '../../redux/actions/requests.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import TripTypeToggle from 'components/TripTypeToggle';
import MedicalService from 'components/MedicalService';
import RequestingAgency from 'components/RequestingAgencyWidget';
import Requester from 'components/PatientInfoBoxWidget/Requester';
import PickupDestination from 'components/PickupDestination';
import GetETA from 'components/GetETA';
import PatientInfoBox from 'components/PatientInfoBoxWidget';
import ErrorDialog from 'components/common/ErrorDialog';
import Summary from 'components/Summary';
import ConfirmPickupTime from 'components/ConfirmPickupTime';
import NewOrExisting from 'components/NewOrExisting';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import Toast from 'components/Toast';
import SimilarRequestFoundDialog from 'components/SimilarRequestFoundDialog';
import ProposedETAModal from 'components/ProposedETAModal';
import MissingFieldsModal from 'components/MissingFieldsModal';
import RequestShare from 'components/RequestShare';
import SaveButton from 'components/SaveButton';
import { requestShareDisplay } from 'flags/display.flag';
import {
  patientDetailsDisabled,
  requestingAgencyDisabled,
  pickupDestinationDisabled,
  pickupDepartmentsDisabled,
  destinationDepartmentsDisabled,
  serviceLevelDisabled,
  patientDocumentsDisabled,
  patientCoverageDisabled,
  chiefComplaintDisabled,
  otherOptionOfChiefComplaintDisabled,
  notesDisabled,
} from 'flags/disabled.flag';
import { serviceLevelCollapse, serviceLevelExpanded } from 'flags/collapse.flag';
import * as agencyActions from '../../redux/actions/agency.actions';

const CreateMobileHealth = props => {
  return (
    <>
      {props.request.id && <Summary />}
      <TripTypeToggle {...props} />
      <RequestingAgency
        disabled={props.requestingAgencyDisabled || null}
        handleFillAgencyAddress={props.getPickupLocationByAgency}
      />
      <Requester />
      <MedicalService
        {...props}
        expanded={props.serviceLevelExpanded}
        disabled={props.serviceLevelDisabled}
        shouldCollapse={props.serviceLevelCollapse}
      />
      <PickupDestination
        disabled={props.pickupDestinationDisabled || null}
        pickupDepartmentsDisabled={props.pickupDepartmentsDisabled}
        destinationDepartmentsDisabled={props.destinationDepartmentsDisabled}
      />
      <GetETA />
      <PatientInfoBox
        patientDetailsDisabled={props.patientDetailsDisabled}
        patientDocumentsDisabled={props.patientDocumentsDisabled}
        patientCoverageDisabled={props.patientCoverageDisabled}
        chiefComplaintDisabled={props.chiefComplaintDisabled}
        otherOptionOfChiefComplaintDisabled={props.otherOptionOfChiefComplaintDisabled}
        notesDisabled={props.notesDisabled}
      />
      {props.requestShareDisplay && <RequestShare />}
      <SaveButton />
      <ErrorDialog />
      <Toast />
      <ConfirmPickupTime />
      <NewOrExisting />
      <ProposedETAModal />
      <MissingFieldsModal />
      <ConfirmationDialog
        open={props.ETADialogOpen}
        onClose={props.closeNewETADialog}
        title={props.ETADialogTitle}
        cancel
      >
        <div>{props.ETADialogBody}</div>
      </ConfirmationDialog>
      <SimilarRequestFoundDialog />
    </>
  );
};

CreateMobileHealth.propTypes = {
  closeNewETADialog: PropTypes.func.isRequired,
  requestShareDisplay: PropTypes.bool.isRequired,
  patientDetailsDisabled: PropTypes.bool.isRequired,
  serviceLevelDisabled: PropTypes.bool.isRequired,
  destinationDepartmentsDisabled: PropTypes.bool.isRequired,
  pickupDepartmentsDisabled: PropTypes.bool.isRequired,
  pickupDestinationDisabled: PropTypes.bool.isRequired,
  requestingAgencyDisabled: PropTypes.bool.isRequired,
  patientDocumentsDisabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    ETADialogOpen: state.formFlow.ETADialogOpen,
    ETADialogTitle: state.formFlow.ETADialogTitle,
    ETADialogBody: state.formFlow.ETADialogBody,
    similarRequest: state.formFlow.similarRequest,
    request: state.requests.request,
    loadedRequest: state.formFlow.loadedRequest,
    pollInProgress: state.eta.pollInProgress,
    requestShareDisplay: requestShareDisplay(state),
    patientDetailsDisabled: patientDetailsDisabled(state),
    requestingAgencyDisabled: requestingAgencyDisabled(state),
    pickupDestinationDisabled: pickupDestinationDisabled(state),
    pickupDepartmentsDisabled: pickupDepartmentsDisabled(state),
    destinationDepartmentsDisabled: destinationDepartmentsDisabled(state),
    serviceLevelDisabled: serviceLevelDisabled(state),
    patientDocumentsDisabled: patientDocumentsDisabled(state),
    patientCoverageDisabled: patientCoverageDisabled(state),
    chiefComplaintDisabled: chiefComplaintDisabled(state),
    otherOptionOfChiefComplaintDisabled: otherOptionOfChiefComplaintDisabled(state),
    notesDisabled: notesDisabled(state),
    serviceLevelCollapse: serviceLevelCollapse(state),
    serviceLevelExpanded: serviceLevelExpanded(state),
  };
}

export default connect(mapStateToProps, {
  ...requestsActions,
  ...formFlowActions,
  ...agencyActions,
})(CreateMobileHealth);
