import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import OfferCardHeader from './OfferCardHeader';
import OfferCardBody from './OfferCardBody';

const styles = {
  card: {
    width: '100%',
    margin: '10px 0 15px 0',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
};

const OfferedEtaCard = ({ descriptionText = '', ...props }) => {
  return (
    <div style={styles.card} data-testid={`card-offer-${props.id}`}>
      <AmbGrid style={{ flexDirection: 'column' }}>
        <OfferCardHeader
          attachment={props.attachment}
          id={props.id}
          name={props.name}
          displayName={props.displayName}
          actionButtonText={props.actionButtonText}
          descriptionText={props.descriptionText}
          providerId={props.providerId}
          createOffer={props.createOffer}
        />
        <OfferCardBody id={props.id} descriptionText={props.descriptionText} />
      </AmbGrid>
    </div>
  );
};

OfferedEtaCard.propTypes = {
  actionButtonText: PropTypes.string.isRequired,
  attachment: PropTypes.shape().isRequired,
  descriptionText: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  providerId: PropTypes.number.isRequired,
  createOffer: PropTypes.func.isRequired,
};

export default OfferedEtaCard;
