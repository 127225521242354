import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Reply from 'components/common/icons/reply';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SummaryMenu from '../SummaryMenu';
import DuplicateRequest from '../DuplicateRequest';
import UnassignRequest from '../Unassign';
import CancelRequest from '../CancelRequest';
import AmbBadge from '../../common/AmbBadge';
import AmbTooltip from '../../common/AmbTooltip/AmbTooltip';
import HelpContainer from '../../Help/Help.container';
import WindowService from '../../../services/window.service';
import { theme } from 'theme';

export const Container = styled.div`
  background-color: ${({ color }) => color};
  color: #fafafa;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
`;

export const Item = styled.div`
  margin-left: 24px;
`;

export const TripNumberA = styled.a`
  cursor: pointer;
  color: #c5eef4;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

const badgeTooltip = count => `${count} warning${count > 1 ? 's' : ''}.\nPlease check Coverage section.`;

const HeaderFrame = ({ warnings, request, isCar, shareLink, onToast, unitSummary, personnel }) => {
  const tripNumber = (inactive, requestId) => {
    const url = `/requester/requests/${requestId}/edit`;
    const followLink = linkUrl => {
      if (window.ambulnzConfig.isAnExternalApp) {
        window.location = linkUrl;

        return;
      }

      WindowService.windowOpen(linkUrl);
    };

    if (inactive) {
      return <TripNumberA onClick={() => followLink(url)}>{`#${requestId}`}</TripNumberA>;
    }

    WindowService.storeCurrentWindow(url);

    return <span>{`#${requestId}`}</span>;
  };

  const getShareLink = () =>
    window.ambulnzConfig.constants.IS_NEW_SHARE_LINK_ENABLED ? '/orders/share/' : '/requests/shared/';

  const getShareLinkURL = shareToken => `${window.location.host}${getShareLink()}${shareToken}`;

  const tripNumbers = () => {
    if (request.roundTrip) {
      return (
        <>
          {`${'Trip '}`}
          {tripNumber(request.originalRequestId, request.originalRequestId || request.id)}
          <span> / </span>
          {tripNumber(request.returnRequestId, request.returnRequestId || request.id)}
        </>
      );
    }

    return <span>Trip #{request.originalRequestId || request.id}</span>;
  };

  const DivNeededForTooltip = styled.div``;

  return (
    <Container
      justifyContent="space-between"
      color={request.isTurndown ? theme.palette.primary.red : theme.palette.primary.header}
    >
      <div style={{ width: 35, height: 25 }}>
        <AmbBadge name="SummaryBadge" count={warnings} title={badgeTooltip(warnings)} />
      </div>
      <div style={{ flexGrow: 2 }}>
        <Container justifyContent="center">
          <Item
            style={{
              marginLeft: 9,
            }}
            aria-describedby="header-trip-id"
          >
            {tripNumbers()}
          </Item>
          {!isCar && <Item aria-describedby="header-unit">{unitSummary}</Item>}
          <Item style={{ display: 'inline', cursor: 'pointer', fontSize: 12, marginLeft: 5 }}>
            <HelpContainer {...personnel} />
          </Item>
        </Container>
      </div>
      <div>
        <Container justifyContent="flex-end" style={{ padding: 0 }}>
          {!isCar && shareLink && (
            <div>
              <AmbTooltip title="Click to copy share link." placement="bottom">
                <DivNeededForTooltip>
                  <CopyToClipboard text={getShareLinkURL(shareLink)} onCopy={() => onToast('Share link copied')}>
                    <Reply style={{ width: 20, height: 24, cursor: 'pointer' }} />
                  </CopyToClipboard>
                </DivNeededForTooltip>
              </AmbTooltip>
            </div>
          )}
          <div style={{ width: 8 }} />
          <div style={{ width: 24, height: 24 }}>
            <SummaryMenu>
              <DuplicateRequest />
              <UnassignRequest />
              <CancelRequest />
            </SummaryMenu>
          </div>
        </Container>
      </div>
    </Container>
  );
};

HeaderFrame.propTypes = {
  warnings: PropTypes.number.isRequired,
  request: PropTypes.shape().isRequired,
  isCar: PropTypes.bool.isRequired,
  shareLink: PropTypes.string,
  unitSummary: PropTypes.string.isRequired,
  onToast: PropTypes.func.isRequired,
};

HeaderFrame.defaultProps = {
  shareLink: '',
};

export default HeaderFrame;
