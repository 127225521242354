import React from 'react';
import AmbGrid from 'components/common/AmbGrid';
import AmbTextField from 'components/common/AmbTextField';
import AmbInputLabel from 'components/common/AmbInputLabel';
import AmbSelect from 'components/common/AmbSelect';
import { palette } from '../../../theme';
import styled from '@emotion/styled';

const heightFeetOptions = ['', 1, 2, 3, 4, 5, 6, 7, 8].map(option => (
  <option key={option} value={option.toString()}>
    {`${option}'`}
  </option>
));

const heightInchesOptions = [...Array(12).keys()].map(option => (
  <option key={option} value={option.toString()}>
    {`${option}"`}
  </option>
));

const styles = {
  selectWrapper: {
    margin: 0,
    border: 0,
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    width: '100%',
  },
  select: {
    outline: 'none',
    position: 'relative',
    zIndex: 10,
    width: 'calc(100% - 32px)',
    cursor: 'pointer',
    minWidth: 16,
    userSelect: 'none',
    padding: '6px 32px 7px 0',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    font: 'inherit',
    color: '#000',
    border: 0,
    margin: 0,
    display: 'block',
    boxSizing: 'content-box',
    background: 'none',
    verticalAlign: 'middle',
    WebkitTapHighlightColor: 'transparent',
    '&:focus': {
      borderRadius: 0,
      background: 'rgba(0, 0, 0, .05)',
      outline: 'none',
    },
  },
};

const WeightHeight = props => {
  const getHelperTextColor = ({ error, disabled }) => {
    if (error) {
      return palette.primary.red;
    }

    if (disabled) {
      return palette.primary.disabled;
    }

    return palette.primary.helperText;
  };

  const HelperText = styled.span`
    color: ${props => getHelperTextColor(props)};
    padding-top: 5px;
    line-height: 15px;
    display: block;
    font-size: 12px;
  `;
  return (
    <>
      <AmbGrid style={{ gap: '8px' }}>
        <AmbGrid style={{ flexBasis: '50%' }}>
          <AmbTextField
            dataTestId="patient-weight-input"
            min={0}
            step={1}
            InputLabelProps={props.labelProps}
            InputProps={props.inputProps}
            name="patientWeight"
            value={props.patientWeight}
            margin="normal"
            label="Weight (lbs)"
            type="number"
            fullWidth
            required={props.required}
            disabled={props.disabled || null}
            onChange={props.handleWeightChange}
            onBlur={props.handleWeightBlur}
            id="PatientWeightInput"
            error={props.patientWeightError}
            helperText={props.patientWeightError}
          />
        </AmbGrid>
        <div>
          <AmbGrid style={{ flexBasis: '50%', gap: 8 }}>
            <AmbGrid>
              <div style={styles.selectWrapper}>
                <AmbInputLabel
                  htmlFor="PatientHeightSelect"
                  style={props.labelProps.style}
                  shrink={props.labelProps.shrink}
                  required={props.required}
                  error={props.patientHeightError}
                >
                  Height
                </AmbInputLabel>
                <AmbSelect
                  dataTestId="patient-height-input"
                  name="patientHeight"
                  value={props.patientHeight}
                  disabled={props.disabled || null}
                  onChange={props.handleHeightChange}
                  onBlur={props.handleBlur}
                  id="PatientHeightSelect"
                  error={props.patientHeightError}
                  options={heightFeetOptions}
                  style={styles.select}
                ></AmbSelect>
              </div>
            </AmbGrid>
            <AmbGrid>
              <div style={styles.selectWrapper}>
                <AmbInputLabel
                  htmlFor="patientHeightInches"
                  style={props.labelProps.style}
                  shrink={props.labelProps.shrink}
                >
                  in
                </AmbInputLabel>
                <AmbSelect
                  dataTestId="patient-inches-input"
                  name="patientHeightInches"
                  value={props.patientHeightInches}
                  disabled={props.disabled || null}
                  onChange={props.handleHeightChange}
                  onBlur={props.handleBlur}
                  id="PatientHeightInchesSelect"
                  options={heightInchesOptions}
                  style={styles.select}
                  error={props.patientHeightError}
                ></AmbSelect>
              </div>
            </AmbGrid>
          </AmbGrid>
          {props.patientHeightError && (
            <HelperText error={props.patientHeightError}>{props.patientHeightError}</HelperText>
          )}
        </div>
      </AmbGrid>
    </>
  );
};
export default WeightHeight;
