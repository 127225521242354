import { createAction } from '@reduxjs/toolkit';

export const openMissingFieldsModal = createAction('[MissingFieldsModal] openMissingFieldsModal');
export const closeMissingFieldsModal = createAction('[MissingFieldsModal] closeMissingFieldsModal');

export const reducer = {
  [openMissingFieldsModal]: state => ({
    ...state,
    open: true,
  }),
  [closeMissingFieldsModal]: state => ({
    ...state,
    open: false,
  }),
};
