import ApiService from './api.service';

class RequestTypeService {
  static async fetchRequestTypes() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/RequestTypes`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default RequestTypeService;
