import ApiService from './api.service';

class RequestCancelReasonTypesService {
  static async fetchCancelReasonTypes() {
    const filter = {
      where: {
        isActive: true,
      }
    };

    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/RequestCancelReasonTypes?filter=${JSON.stringify(filter)}`;
    let response = await ApiService.get(url);
    response = response.json();
    return response;
  }
}

export default RequestCancelReasonTypesService;
