import React from 'react';
import { connect } from 'react-redux';
import Notes from './Notes.frame';
import * as infoboxActions from '../../../redux/actions/infobox.actions';
import { notesCollapse } from '../../../flags/collapse.flag';

const NotesContainer = props => <Notes {...props} />;

const formatTitle = state => (state.infobox.notes ? state.infobox.notes.replace(/\n/g, ' ') : 'Notes');

function mapStateToProps(state) {
  return {
    notes: state.infobox.notes,
    shouldCollapse: notesCollapse(state),
    title: formatTitle(state),
  };
}

export default connect(mapStateToProps, { ...infoboxActions })(NotesContainer);
