import React from 'react';
import { connect } from 'react-redux';
import SimilarRequestDialogFrame from './SimilarRequestFoundDialog.frame';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import * as requestsActions from '../../redux/actions/requests.actions';

const SimilarRequestFoundDialogContainer = props => <SimilarRequestDialogFrame {...props} />;

function mapStateToProps(state) {
  return {
    targetRequest: state.requests.request,
    similarRequest: state.formFlow.similarRequest,
    similarRequestDialogOpen: state.formFlow.similarRequestDialogOpen,
  };
}
export default connect(mapStateToProps, { ...formFlowActions, ...requestsActions })(SimilarRequestFoundDialogContainer);
