import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 14 17" {...props}>
      <g>
        <path style={{ fill: '#99F5FF' }} d="M7,4.6" />
        <g>
          <g>
            <path
              d="M13.6,4.4c0,0-2.2-0.5-3.8-1.5C8.1,1.9,7,0.7,7,0.7S6,1.9,4.3,2.9c-1.6,1-3.8,1.5-3.8,1.5s-0.1,2.8,1.2,6.2
				S7,16.4,7,16.4s4.1-2.5,5.4-5.9S13.6,4.4,13.6,4.4z"
            />
            <path
              style={{ fill: '#FFFFFF' }}
              d="M7,2.8c0.5,0.4,1.1,0.9,1.9,1.4c1,0.6,2.2,1,3.1,1.3c-0.1,1-0.3,2.7-1,4.5c-0.7,2-2.8,3.8-4,4.6
				c-1.3-0.9-3.2-2.7-4-4.6c-0.7-1.8-0.9-3.5-1-4.5c0.9-0.3,2.1-0.7,3.1-1.3C5.9,3.8,6.5,3.3,7,2.8 M7,0.7c0,0-1.1,1.2-2.7,2.2
				c-1.6,1-3.8,1.5-3.8,1.5s-0.1,2.8,1.2,6.2S7,16.4,7,16.4s4.1-2.5,5.4-5.9s1.2-6.2,1.2-6.2s-2.2-0.5-3.8-1.5C8.1,1.9,7,0.7,7,0.7
				L7,0.7z"
            />
          </g>
          <path
            style={{ fill: '#004251' }}
            d="M7,17l-0.2-0.1c-0.2-0.1-4.3-2.6-5.6-6.1C0,7.3,0,4.5,0,4.4L0,4l0.4-0.1c0,0,2.2-0.5,3.7-1.4s2.6-2.1,2.6-2.1
			L7,0l0.3,0.4c0,0,1,1.2,2.6,2.1c1.6,0.9,3.7,1.4,3.7,1.4L14,4l0,0.4c0,0.1,0.1,2.9-1.2,6.3c-1.3,3.5-5.4,6-5.6,6.1L7,17z M1,4.8
			c0,0.9,0.2,3.1,1.1,5.6c1,2.7,4.1,4.9,4.9,5.5c0.9-0.6,3.9-2.8,4.9-5.5c0.9-2.5,1.1-4.7,1.1-5.6c-0.7-0.2-2.3-0.6-3.6-1.4
			C8.3,2.7,7.5,1.9,7,1.4C6.6,1.9,5.7,2.7,4.6,3.4C3.3,4.1,1.7,4.6,1,4.8z"
          />
        </g>
        <path
          style={{ fill: '#004251' }}
          d="M7,12L7,12c-0.3,0-0.5-0.2-0.5-0.5v-6C6.5,5.2,6.8,5,7,5h0c0.3,0,0.5,0.2,0.5,0.5v6C7.5,11.8,7.3,12,7,12z"
        />
        <path
          style={{ fill: '#004251' }}
          d="M3.5,8.5L3.5,8.5C3.5,8.2,3.8,8,4,8h6c0.3,0,0.5,0.2,0.5,0.5v0C10.5,8.8,10.3,9,10,9H4C3.8,9,3.5,8.8,3.5,8.5z
		"
        />
      </g>
    </svg>
  );
}
