import React from 'react';

export default function (props) {
  return (
    <svg
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
      style={{
        fill: 'currentColor',
        width: 24,
        height: 24,
        display: 'inline-block',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        userSelect: 'none',
        flexShrink: 0,
        ...props.style,
      }}
    >
      <circle cx="12" cy="12" r="12"></circle>
      <text x="12" y="16" textAnchor="middle" style={props.textStyle}>
        {props.text}
      </text>
    </svg>
  );
}
