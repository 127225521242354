import React from 'react';
import Radio from '../../components/common/icons/Radio';

const styles = {
  cursor: 'pointer',
  checked: {
    fill: '#1EC0D9',
  },
  disabled: {
    fill: '#A0A0A0',
    opacity: 0.7,
    cursor: 'auto',
  },
};

const AmbRadioInput = ({
  value,
  onChange,
  onBlur,
  checked,
  name,
  disabled,
  classes,
  style,
  id,
  inputPosition = {},
}) => {
  const svgStyles = {
    ...styles,
    ...(checked && styles.checked),
    ...(disabled && styles.disabled),
  };
  return (
    <>
      <span style={{ ...style, position: 'relative' }}>
        <Radio checked={checked} class={classes} style={{ fill: 'currentColor', ...svgStyles }} />
        <input
          type="radio"
          style={{
            position: 'absolute',
            opacity: 0,
            top: inputPosition.top,
            left: inputPosition.left,
            bottom: inputPosition.bottom,
            right: inputPosition.right,
          }}
          id={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          checked={checked}
          disabled={disabled}
          className={classes}
          name={name}
        />
      </span>
    </>
  );
};

export default AmbRadioInput;
