import { createReducer } from 'redux-act';
import * as action from './saveButton.actions';

export const initialState = {
  saveConfirmationShown: false,
  clicked: false,
};

const reducer = createReducer(
  {
    [action.saveConfirmationShownChange]: (state, payload) => ({
      ...state,
      saveConfirmationShown: payload,
    }),
    [action.clickedChange]: (state, payload) => ({
      ...state,
      clicked: payload,
    }),
  },
  initialState,
);

export default reducer;
