class MapService {
  static async getUserLocation() {
    const getCurrentPosition = (options = {}) =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
    const response = await getCurrentPosition();
    return response;
  }

  static async getDirections(from, to) {
    const getDirectionsByLatLng = () =>
      new Promise((resolve, reject) => {
        try {
          const { google } = window;

          const DirectionsService = new google.maps.DirectionsService();

          DirectionsService.route(
            {
              origin: new google.maps.LatLng(from.lat, from.lng),
              destination: new google.maps.LatLng(to.lat, to.lng),
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                resolve(result);
              } else {
                reject(result);
              }
            },
          );
        } catch (error) {
          console.log('failed to load DirectionsService', error);
        }
      });
    const response = await getDirectionsByLatLng(from, to);
    return response;
  }
}
export default MapService;
