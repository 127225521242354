import schema from 'schm';
import computed from 'schm-computed';

const user = schema(
  {
    userType: Number,
    phone: String,
    firstName: String,
    lastName: String,
    email: String,
    pictures: [Object],
    facilities: [Object],
    programs: [Object],
    id: String,
    hasAccessToAdmin: Boolean,
    phoneExtension: String,
    displayAMPM: Boolean,
    permittedRequesterAgenciesForExternalSearch: [Number],
    hasValidUserExternalSystemCredentials: Boolean,
    prepopulateAsRequester: Boolean,
    defaultToMedicalHealthServiceAsRequester: Boolean,
  },
  computed({
    fullName: values => `${values.firstName} ${values.lastName}`,
    isDispatcher: values => values.userType === 5,
    isRequester: values => values.userType === 3,
    facility: values => values.facilities[0],
    isDara: values => values.facilities.some(f => f.isDaraUser),
  }),
);

export default user;
