import React from 'react';
import styled from '@emotion/styled';
import AmbCircularProgress from './AmbCircularProgress';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TextWithLoader = ({ text, classes, circleStyles }) => (
  <Container>
    <div style={{ fontSize: 13, fontStyle: 'italic', marginRight: 10 }}>{text}</div>
    <AmbCircularProgress size={24} propsStyles={circleStyles} />
  </Container>
);

export default TextWithLoader;
