import ApiService from './api.service';

export default class CallTypeService {
  static async get(userId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/AmbUsers/${userId}/CallTypes`;
    const response = await ApiService.get(url);

    return response.json();
  }
}
