import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 18 18" {...props}>
      <path
        style={{ fill: '#014053' }}
        d="M16.4,9.4C16.4,9.4,16.3,9.4,16.4,9.4c-0.2,0-0.4-0.2-0.4-0.4c0.1-1.9-0.6-3.7-1.9-5c-1.3-1.3-3.1-2-5-1.9
      C8.9,2.1,8.7,2,8.7,1.8c0-0.2,0.1-0.4,0.3-0.4c2.1-0.1,4.1,0.7,5.5,2.1C16.1,5,16.8,7,16.7,9.1C16.7,9.3,16.5,9.4,16.4,9.4z"
      />
      <path
        style={{ fill: '#014053' }}
        d="M13.6,9.3c-0.2,0-0.3-0.1-0.4-0.3c0-1.1-0.5-2.1-1.3-2.8c-0.8-0.8-1.8-1.2-2.8-1.2C9,4.8,8.9,4.7,8.9,4.5
      c0-0.2,0.2-0.3,0.4-0.3c1.2,0.1,2.4,0.6,3.3,1.5c0.9,0.9,1.4,2.1,1.5,3.3C14,9.1,13.9,9.2,13.6,9.3C13.7,9.3,13.7,9.3,13.6,9.3z"
      />
      <g>
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="4,1.6 5.8,5.4 5.1,6.5 4.8,8 5.8,10.6 7.8,12.4 9.9,13.4 11.9,12.9 12.7,12.2 16.2,13.7 16.9,14.4
        15.1,15.9 12.9,16.9 9.9,16.4 6.8,14.8 4.1,12.2 1.9,9.2 1.1,6.9 1.1,4.5 2.6,2.5 	"
        />
        <path
          d="M6.9,11.2c0.7,0.7,1.7,1.3,2.4,1.6c1.5,0.6,2.7-1,3.5-0.9c0.7,0.1,3.7,1.3,4.1,1.8c0.5,0.7-0.4,1.5-0.9,2
        c-0.9,0.8-2.2,1.5-3.4,1.5l0,0c-2.8,0-5.6-1.8-7.7-4l0,0c-2.2-2.1-4-4.9-4-7.7l0,0c0-1.3,0.7-2.5,1.5-3.5c0.5-0.5,1.3-1.5,2-0.9
        c0.6,0.4,1.7,3.4,1.9,4.1c0.1,0.8-1.5,2-0.9,3.5C5.6,9.5,6.2,10.5,6.9,11.2z"
        />
        <path
          style={{ fill: '#014053' }}
          d="M12.4,17.9c-3.6,0-6.6-2.6-8.1-4.2c-1.5-1.5-4.2-4.6-4.2-8.1c0-1.3,0.6-2.7,1.7-3.9c0.6-0.6,1.7-1.9,2.9-1.1
        c0.8,0.6,2,3.9,2.1,4.5c0.1,0.5-0.2,1-0.5,1.5C5.9,7.5,5.6,8,5.9,8.5c0.3,0.7,0.9,1.6,1.5,2.2c0.6,0.6,1.5,1.2,2.2,1.5
        c0.5,0.2,1.1,0,1.8-0.5c0.5-0.3,1-0.6,1.5-0.5c0.6,0.1,3.9,1.3,4.5,2.1c0.8,1.2-0.4,2.3-1,2.8C15.2,17.3,13.7,17.9,12.4,17.9
        C12.4,17.9,12.4,17.9,12.4,17.9z M3.9,1.8c-0.3,0-0.8,0.5-1,0.8C2,3.6,1.4,4.7,1.4,5.7c0,3.1,2.4,5.8,3.8,7.2
        c1.4,1.4,4.1,3.8,7.2,3.8c0,0,0,0,0,0c1,0,2.1-0.5,3-1.4c0.4-0.3,1-0.9,0.8-1.2c-0.3-0.4-2.9-1.5-3.6-1.6c-0.1,0-0.5,0.2-0.7,0.3
        c-0.7,0.4-1.7,1-2.9,0.5c-0.9-0.4-1.9-1-2.6-1.8l0,0C5.7,10.9,5.1,9.9,4.7,9C4.2,7.8,4.8,6.8,5.2,6.1c0.1-0.2,0.3-0.6,0.3-0.7
        C5.4,4.7,4.3,2.1,3.9,1.8C3.9,1.8,3.9,1.8,3.9,1.8z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M3.9,1.1l0,0.7c0.4,0.4,1.5,2.9,1.6,3.5c0,0.1-0.2,0.4-0.3,0.6c-0.4,0.7-1.1,1.8-0.5,3c0.4,0.9,1,1.9,1.8,2.7
        c0.8,0.7,1.8,1.4,2.6,1.8c0.3,0.1,0.6,0.2,1,0.2c0.8,0,1.5-0.4,2.1-0.7c0.2-0.1,0.5-0.3,0.6-0.3c0.7,0.1,3.2,1.2,3.6,1.5
        c0.1,0.1-0.2,0.4-0.8,1l-0.1,0.1c-0.9,0.8-2,1.3-3,1.3c-3,0-5.8-2.3-7.1-3.7c-1.4-1.4-3.7-4.1-3.7-7.1c0-0.9,0.5-2,1.3-2.9l0.1-0.1
        c0.2-0.2,0.7-0.8,0.9-0.8L3.9,1.1 M3.9,1.1c-0.6,0-1.2,0.7-1.6,1.1C1.5,3.1,0.8,4.4,0.8,5.7c0,2.8,1.8,5.6,4,7.7l0,0
        c2.1,2.2,4.9,4,7.7,4c0,0,0,0,0,0c1.3,0,2.5-0.7,3.4-1.5c0.5-0.5,1.5-1.3,0.9-2c-0.4-0.5-3.4-1.7-4.1-1.8c0,0-0.1,0-0.1,0
        C12,11.9,11.1,13,10,13c-0.2,0-0.4,0-0.7-0.1c-0.8-0.3-1.7-0.9-2.4-1.6c-0.7-0.7-1.3-1.6-1.6-2.4c-0.6-1.5,1-2.7,0.9-3.5
        C6.1,4.6,4.9,1.6,4.3,1.3C4.2,1.2,4,1.1,3.9,1.1L3.9,1.1z"
        />
      </g>
    </svg>
  );
}
