import { from as from$ } from 'rxjs';
import { debounceTime, filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import getEta from '../../../adapters/getEta.adapter';
import { parseNoAutoETAPayload } from '../../../parsers/eta.parser';
import * as etaActions from '../../../redux/actions/eta.actions';
import { errorPipe } from '../../../redux/epics/epicsUtil';
import { getRequestId } from '../../../redux/epics/updateRequestUtil';

export const getNewETAAfterAcceptedRequestEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.requestETA.match),
    filter(() => store.value.formFlow.etaWasAccepted),
    filter(() => !store.value.formFlow.noAutoEtaRequest),
    mergeMap(() =>
      from$(getEta(getRequestId(store), store.value)).pipe(
        map(request => etaActions.asyncRequestETASuccess(request)),
        takeUntil(
          action$.pipe(
            filter(
              action => etaActions.asyncRequestETASuccess.match(action) || etaActions.requestETATimeout.match(action),
            ),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const getNoAutoETAEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.getETAFromRequest.match),
    filter(() => store.value.formFlow.noAutoEtaRequest),
    map(() => etaActions.requestETASuccess(parseNoAutoETAPayload(store.value))),
  );

export const getNewNoAutoETAAfterAcceptedEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.requestETA.match),
    filter(() => store.value.formFlow.noAutoEtaRequest),
    filter(() => store.value.formFlow.etaWasAccepted),
    map(() => etaActions.requestETASuccess(parseNoAutoETAPayload(store.value))),
  );

export const getETATimeoutEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.requestETA.match),
    filter(() => store.value.eta.showEtaProgress),
    debounceTime(60000),
    filter(() => store.value.eta.showEtaProgress),
    map(() => etaActions.requestETATimeout()),
  );
