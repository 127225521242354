import { createAction } from '@reduxjs/toolkit';

export const clearMissingFieldsErrorMessages = createAction('[MissingFieldsModal] clearMissingFieldsErrorMessages');

export const reducer = {
  [clearMissingFieldsErrorMessages]: state => ({
    ...state,
    errors: {},
    hasErrors: false,
  }),
};
