import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SaveButton from '../common/SaveButton';
import * as infoboxActions from '../../redux/actions/infobox.actions';
import * as saveButtonActions from './redux/saveButton.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import { setSubmitRecipientsManualCheck } from '../RequestShare/redux/setSubmitRecipientsManualCheck';
import { getConfirmationText, inProgress, showSaveButton, saveButtonDisabled } from './saveButton.helper';

const SaveButtonContainer = props => {
  const prevProps = useRef(props);

  const [saveButtonState, setSaveButtonState] = useState({
    dialogOpen: false,
    clicked: false,
  });

  useEffect(
    () => {
      if (
        props.saveConfirmationShown !== prevProps.current.saveConfirmationShown &&
        props.saveConfirmationShown &&
        props.clicked
      ) {
        setSaveButtonState(prevState => ({ ...prevState, dialogOpen: true }));
      }

      prevProps.current = props;
    },
    [props],
  );

  const handleClickSaveButton = () => {
    if (props.hasErrors) {
      props.setSaveClicked(true);
      props.setTriggerValidation(true);
    } else {
      props.clickedChange(true);
      props.submitPatientInfo();
      props.setSubmitRecipientsManualCheck(true);
    }
  };

  const handleClose = () => {
    setSaveButtonState(prevState => ({ ...prevState, dialogOpen: false }));
    props.clickedChange(false);
  };

  return (
    <SaveButton
      {...props}
      {...saveButtonState}
      handleClick={() => handleClickSaveButton()}
      handleClose={() => handleClose()}
    />
  );
};

function mapStateToProps(state) {
  return {
    showSaveButton: showSaveButton(state),
    disabled: saveButtonDisabled(state),
    showLoading: inProgress(state),
    saveConfirmationShown: state.saveButton.saveConfirmationShown,
    clicked: state.saveButton.clicked,
    ...getConfirmationText(state),
    hasErrors: state.missingFieldsModal.hasErrors,
  };
}

SaveButtonContainer.propTypes = {
  saveConfirmationShown: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  clicked: PropTypes.bool.isRequired,
  submitPatientInfo: PropTypes.func.isRequired,
  setSaveClicked: PropTypes.func.isRequired,
  setTriggerValidation: PropTypes.func.isRequired,
  clickedChange: PropTypes.func.isRequired,
  setSubmitRecipientsManualCheck: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    ...infoboxActions,
    ...saveButtonActions,
    ...formFlowActions,
    setSubmitRecipientsManualCheck,
  },
)(SaveButtonContainer);
