import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DriverContactFrame from './DriverContact.frame';
import * as toastActions from '../../redux/actions/toast.actions';

function DriverContact(props) {
  return <DriverContactFrame {...props} />;
}

function mapStateToProps(state) {
  return {
    requesterPhone: state.infobox.requesterPhone,
    request: state.requests.request,
  };
}

export default connect(
  mapStateToProps,
  { ...toastActions },
)(DriverContact);

DriverContact.propTypes = {
  requestId: PropTypes.number,
};

DriverContact.defaultProps = {
  requestId: null,
};
