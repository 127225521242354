import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@mui/material';
import styled from '@emotion/styled';
import { theme, drawerWidth } from 'theme';
import ExpandMoreIcon from '../icons/expandmore';
import AmbBadge from '../AmbBadge';

const StyledAvatar = styled.div`
  color: theme.palette.secondary.main,
  background: transparent;
  align-items: end;
  height: auto;
  width: 30px;
  display: flex;
  font-size: 1.25rem;
  flex-shrink: 0;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
`;

const CustomAccordion = styled(Accordion)`
  && {
    &&.MuiAccordion-root.Mui-expanded {
      margin: 12px 0;
    }
  }
`;

const CustomAccordionSummary = styled(AccordionSummary)`
  && {
    &&.MuiAccordionSummary-root {
      z-index: 1;
      min-height: auto;
      padding-left: 2px;
    }
    & > .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: 8px;
      transform: translateY(-90%) rotate(0deg);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &.Mui-expanded {
      && .MuiAccordionSummary-content {
        margin: 5px 0;
      }
      && .MuiAccordionSummary-expandIconWrapper {
        transform: rotate(180deg);
      }
    }
  }
`;

const CustomAccordionDetails = styled(AccordionDetails)`
  && {
    &&.MuiAccordionDetails-root {
      margin-top: -27px;
      padding: 10px 10px 4px 10px;
    }
  }
`;

const Badge = styled(AmbBadge)`
  top: 50%;
  position: absolute;
  right: 50px;
  transform: translateY(-50%) rotate(0deg);
`;

function AmbExpansionPanel(props) {
  const [expanded, setExpanded] = useState(Boolean(props.expanded));

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  const togglePanel = () => {
    setExpanded(!expanded);
    props.onTogglePanel();
  };

  const panelSummaryStyles = {
    ...(props.showTitle && { padding: '8px 0 8px 8px' }),
    ...(props.error && { color: theme.palette.primary.red }),
    ...(props.showOneline && {
      width: drawerWidth - 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 8,
    }),
  };

  const showTitle = !expanded || props.showTitle;
  return (
    <CustomAccordion expanded={expanded} onChange={togglePanel} id={props.id}>
      <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} id={props.id} data-testid={props.id}>
        <div style={{ display: showTitle ? 'flex' : 'none' }}>
          {props.icon && <StyledAvatar theme={theme}>{props.icon}</StyledAvatar>}
          <div aria-label="panel-summary" style={panelSummaryStyles}>
            {props.title}
            {props.titleExt && props.titleExt.trim() !== '' && (
              <span className={props.titleExtClass}>{props.titleExt}</span>
            )}
            {props.titleDesc && props.titleDesc.trim() !== '' && (
              <div style={{ color: theme.palette.grey.dark, fontSize: 12 }}>{props.titleDesc}</div>
            )}
          </div>
          <Badge name={props.id} count={props.warnings} />
        </div>
      </CustomAccordionSummary>
      <CustomAccordionDetails>{props.children}</CustomAccordionDetails>
      {props.actions && <AccordionActions>{props.actions}</AccordionActions>}
    </CustomAccordion>
  );
}

AmbExpansionPanel.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onTogglePanel: PropTypes.func,
  expanded: PropTypes.bool,
  showTitle: PropTypes.bool,
  id: PropTypes.string,
  titleExt: PropTypes.string,
  titleExtClass: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  titleDesc: PropTypes.string,
  actions: PropTypes.node,
  showOneline: PropTypes.bool,
  warnings: PropTypes.number,
  error: PropTypes.bool,
};

AmbExpansionPanel.defaultProps = {
  onTogglePanel: () => null,
  expanded: true,
  id: null,
  titleExt: '',
  titleExtClass: '',
  titleDesc: '',
  showTitle: false,
  actions: '',
  showOneline: true,
  warnings: 0,
};

export default AmbExpansionPanel;
