import { isCar } from './service.flag';
import { showCoverageInformation } from './agency.flag';
import { userCanExternalPatientSearch } from './patientSearch.flag';
import { USER_TYPES } from '../enums';

export const patientAdditionalDetailsDisplay = state => !isCar(state.service);

export const patientCoverageDisplay = state => {
  if (!showCoverageInformation(state)) return false;
  if (isCar(state.service) && !state.agency.uberCoverageRequired) return false;
  return true;
};

export const chiefComplaintDisplay = state => !isCar(state.service);

export const notesDisplay = state =>
  !isCar(state.service) || Boolean(window.ambulnzConfig.constants.ENABLE_PICKUP_INSTRUCTIONS_FOR_UBER);

export const requestShareDisplay = state => !isCar(state.service);

export const mrnSearchEnabled = state =>
  Boolean(window.ambulnzConfig.constants.MRN_LOOKUP_ENABLED && userCanExternalPatientSearch(state));

export const showSSN = state => state.user.model.userType === USER_TYPES.DISPATCHER;

export const displayPatientLookupTooltip = state =>
  state.agency.requesterAgencyGroup &&
  state.agency.requesterAgencyGroup.patientStoreAdapters &&
  state.agency.requesterAgencyGroup.patientStoreAdapters.length &&
  (!state.user.model.permittedRequesterAgenciesForExternalSearch.includes(state.agency.requesterAgencyId) ||
    !state.user.model.hasValidUserExternalSystemCredentials) &&
  !window.ambulnzConfig.isAnExternalApp;

export const displayCharges = state =>
  Boolean(
    window.ambulnzConfig.constants.IS_FARE_DISPLAY_ENABLED &&
      state.requests.charges &&
      state.requests.request &&
      (state.requests.request.isActive || state.requests.request.isCompleted),
  );
