import * as formFlowActions from '../actions/formFlow.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as agencyActions from '../actions/agency.actions';
import { fetchProviderCapabilitesSuccess } from '../actions/service.actions';
import { setMissingFieldsErrorMessages } from '../../components/MissingFieldsModal/redux/setMissingFieldsErrorMessages';
import { isCar } from '../../flags/service.flag';

const triggerValidation =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (formFlowActions.setTriggerValidation.match(action)) {
      dispatch(agencyActions.setAgencyValidation(action.payload));
    }
  };

const setSaveErrorMessage =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (infoboxActions.setSaveErrorMessage.match(action)) {
      dispatch(setMissingFieldsErrorMessages(action.payload));
    }
  };

const fetchProviderCapabilitesSuccessMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (fetchProviderCapabilitesSuccess.match(action) && isCar(getState().service)) {
      dispatch(formFlowActions.setTriggerValidation(true));
    }
  };

export default [triggerValidation, setSaveErrorMessage, fetchProviderCapabilitesSuccessMdl];
