import ApiService from './api.service';

export async function getChiefComplaints() {
  const filter = { where: { typeId: 1 } };
  const path = `${window.ambulnzConfig.tenantedRestApiUrl}/ChiefComplaints?filter=${JSON.stringify(filter)}`;
  const response = await ApiService.get(path);
  return response.json();
}

export async function updateChiefComplaint(requestId, payload) {
  const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/ChiefComplaints`;
  const response = await ApiService.put(path, payload);

  return response.json();
}

export async function getChiefComplaintForPatient(patientExternalId) {
  const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Patients/${patientExternalId}/ChiefComplaint`;
  const response = await ApiService.get(path);

  return response.json();
}

export async function getChiefComplaintById(id) {
  const path = `${window.ambulnzConfig.tenantedRestApiUrl}/ChiefComplaints/${id}`;
  const response = await ApiService.get(path);

  return response.json();
}
