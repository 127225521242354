import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon } from '@mui/material';
import AmbGrid from '../AmbGrid';
import DropFacility from '../icons/DropFacility';
import Aliases from './Aliases';
import { drawerWidth } from '../../../theme';

class FacilityItem extends React.Component {
  getMatchingSubstring(string) {
    const { inputValue } = this.props;
    const substringMatchStart = string.toLowerCase().indexOf(inputValue.toLowerCase());

    if (substringMatchStart === -1) {
      return <span>{string}</span>;
    }

    return (
      <span>
        {string.slice(0, substringMatchStart)}
        <b>{string.slice(substringMatchStart, inputValue.length)}</b>
        {string.slice(inputValue.length)}
      </span>
    );
  }

  render() {
    const { children, inputValue } = this.props;
    return (
      <AmbGrid style={{ width: drawerWidth - 50, gap: '12px' }}>
        <AmbGrid style={{ width: 24 }}>
          <ListItemIcon>
            <DropFacility style={{ height: 18, width: 18, margin: '8px 0 0 5px' }} />
          </ListItemIcon>
        </AmbGrid>
        <AmbGrid style={{ flexDirection: 'column' }}>
          <aside
            style={{
              width: drawerWidth - 100,
              marginLeft: -5,
              marginBottom: -3,
              color: '#2e424d',
              whiteSpace: 'nowrap',
              fontSize: 14,
              lineHeight: '24px',
            }}
          >
            {children.name}
          </aside>
          <span
            style={{
              width: drawerWidth - 100,
              marginLeft: -5,
              color: '#797d80',
              whiteSpace: 'nowrap',
              fontWeight: 400,
            }}
          >
            {children.formattedAddress()}
          </span>
          <Aliases aliases={children.matchedAliases} inputValue={inputValue} />
        </AmbGrid>
      </AmbGrid>
    );
  }
}

FacilityItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.node]).isRequired,
  inputValue: PropTypes.string.isRequired,
};

export default FacilityItem;
