import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import EtaLabel from './EtaLabel';
import FareLabel from './FareLabel';
import EtaInfo from '../EtaInfo';
import { ReactComponent as ArrowTime } from '../../common/icons/arrow-time.svg';
import { Label, Eta } from './elements';

const CardBody = ({
  noAutoEtaRequest,
  eta,
  dropoffTime,
  unitId,
  totalDrive,
  taskTime,
  warning,
  showEtaInfo,
  fareAmount,
  returnFareAmount,
  conflict,
  etaId,
  requestedAppointmentTime,
  isNonTransport,
  displayWindowEndAt,
  displayWindowStartAt,
  displayWindowDay,
}) => (
  <div data-testid={`body-${etaId}`}>
    <AmbGrid style={{ padding: '10px 16px 2px' }}>
      {window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && isNonTransport && (
        <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
          <Label className="eta-label__label">Date</Label>
          <Eta className="eta-label__eta">{displayWindowDay}</Eta>
        </AmbGrid>
      )}
      {window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && isNonTransport && (
        <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
          <Label className="eta-label__label" htmlFor={`${displayWindowStartAt}-window`}>
            Arrival Window
          </Label>
          <Eta className="eta-label__eta" id={`${displayWindowStartAt}-window`} fontWeight={800}>
            {displayWindowStartAt} - {displayWindowEndAt}
          </Eta>
        </AmbGrid>
      )}
      {!window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && isNonTransport && (
        <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
          <EtaLabel eta={eta} labelName="Arrival" fontWeight={800} />
        </AmbGrid>
      )}
      {!isNonTransport && (
        <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
          <EtaLabel eta={eta} labelName={noAutoEtaRequest ? 'Requested Pickup' : 'Pickup'} fontWeight={800} />
        </AmbGrid>
      )}
      {!noAutoEtaRequest && (
        <>
          {!isNonTransport && (
            <AmbGrid style={{ padding: 4, flexDirection: 'column', alignSelf: 'flex-end' }}>
              <div style={{ width: 12, height: 24, margin: '0px 8px' }}>
                <ArrowTime />
              </div>
            </AmbGrid>
          )}
          {!isNonTransport && (
            <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
              <EtaLabel eta={dropoffTime} labelName={requestedAppointmentTime ? 'Arrival' : 'At Destination'} />
            </AmbGrid>
          )}
          <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
            <div style={{ width: 12, height: 24, margin: '0px 8px' }} />
          </AmbGrid>
          {fareAmount && (
            <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
              <FareLabel fareAmount={fareAmount} returnFareAmount={returnFareAmount} />
            </AmbGrid>
          )}
          {!fareAmount && (
            <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
              <EtaLabel labelName="Unit" eta={unitId} warning={warning} />
            </AmbGrid>
          )}
        </>
      )}
    </AmbGrid>
    <AmbGrid style={{ padding: '2px 16px 12px' }}>
      {!noAutoEtaRequest && showEtaInfo && (
        <AmbGrid style={{ padding: 4, flexDirection: 'column' }}>
          <EtaInfo view="multi" conflict={conflict} totalDrive={totalDrive} unitId={unitId} taskTime={taskTime} />
        </AmbGrid>
      )}
    </AmbGrid>
  </div>
);

CardBody.propTypes = {
  noAutoEtaRequest: PropTypes.bool.isRequired,
  eta: PropTypes.string.isRequired,
  dropoffTime: PropTypes.string.isRequired,
  unitId: PropTypes.string,
  warning: PropTypes.bool,
  fareAmount: PropTypes.string,
  returnFareAmount: PropTypes.string,
  showEtaInfo: PropTypes.bool.isRequired,
  requestedAppointmentTime: PropTypes.string,
  taskTime: PropTypes.string,
  totalDrive: PropTypes.number,
  etaId: PropTypes.number,
  conflict: PropTypes.shape(),
};

CardBody.defaultProps = {
  unitId: null,
  etaId: 1,
  warning: false,
  fareAmount: null,
  returnFareAmount: null,
  taskTime: '',
  totalDrive: 0,
  conflict: null,
  requestedAppointmentTime: null,
};

export default CardBody;
