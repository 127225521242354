import withValidations from '../../../common/withValidations';
import styledFrame from './Coverage.frame';

function coverageChanged(props, nextProps) {
  const policyChanged = nextProps.policyNumber !== props.policyNumber;
  const authChanged = nextProps.authorizationNumber !== props.authorizationNumber;

  return policyChanged || authChanged;
}

export function shouldValidateAll(props, nextProps) {
  const validateAll = nextProps.shouldValidateAll !== props.shouldValidateAll && nextProps.shouldValidateAll;

  const triggerValidation = nextProps.triggerValidation !== props.triggerValidation && nextProps.triggerValidation;
  const triggerPatientValidation =
    nextProps.triggerPatientValidation !== props.triggerPatientValidation && nextProps.triggerPatientValidation;

  const attachmentsChanged = nextProps.attachments.length !== props.attachments.length;
  const isDisabledChanged = nextProps.disabled !== props.disabled;
  const coverageRequiredChanged = nextProps.coverageRequired !== props.coverageRequired;
  const payerInactiveChanged =
    props.payer.inactive && !nextProps.errors.payerInput && !(props.disabled && props.payer.inactive);

  return (
    validateAll ||
    triggerValidation ||
    triggerPatientValidation ||
    coverageChanged(props, nextProps) ||
    attachmentsChanged ||
    coverageRequiredChanged ||
    payerInactiveChanged ||
    isDisabledChanged
  );
}

const validations = {
  payerInput: (value, nextProps) => {
    if (nextProps.isRequired && !nextProps.facesheetAttached && !value && nextProps.coverageRequired) {
      return `Must select a ${nextProps.insuranceName.toLowerCase()}`;
    }

    if (value && nextProps.payerInput !== nextProps.payerName) {
      return `Must select a ${nextProps.insuranceName.toLowerCase()} from the list`;
    }

    if (nextProps.payer.inactive && !nextProps.disabled) {
      return `Payer inactive. Please select another`;
    }

    return null;
  },
  policyNumber: (value, nextProps) => {
    if (!value && nextProps.payerInput && nextProps.payer.showPolicyNumber && nextProps.coverageRequired) {
      return 'Specify Policy Number';
    }

    return null;
  },

  authorizationNumber: (value, nextProps) => {
    if (!value && nextProps.payerInput && nextProps.payer.authFieldName && nextProps.coverageRequired) {
      return 'Specify Authorization Number';
    }

    return null;
  },
};

export default withValidations(validations)(styledFrame);
