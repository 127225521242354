import InfoboxValidator from './infobox.validator';
import { isLocationValid } from './location.validator';
import * as FieldValidator from './field.validators';
import { isBariatricValid } from '../flags/service.flag';

export default async function (state, otherValidations = true) {
  const validator = new InfoboxValidator(state.infobox);
  const infoboxValid = await validator.isValid();
  const careProvidedAtValid =
    state.requests.careProvidedAtReceivingFacilityDescription || !state.requests.careProvidedAtReceivingFacility;

  const result = !!(
    !state.service.questionMode &&
    careProvidedAtValid &&
    infoboxValid &&
    otherValidations &&
    state.requests.chiefComplaintName &&
    isBariatricValid(state) &&
    FieldValidator.requesterFieldsValid(state.infobox) &&
    FieldValidator.additionalFieldsValid(state) &&
    isLocationValid(state)
  );

  return result;
}
