import ApiService from './api.service';

export default {
  async checkEligibility(eligibilityData) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/EligibilityLookups/`;
    const response = await ApiService.post(url, eligibilityData);

    return response.json();
  },
};
