import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g>
        <g>
          <path d="M14,6h-4C8.9,6,8,6.9,8,8l0,5.5h2V22h4v-8.5h2V8C16,6.9,15.1,6,14,6z" />
          <circle cx="12" cy="3.5" r="1.5" />
        </g>
      </g>
    </svg>
  );
}
