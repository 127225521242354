import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../../common/AmbGrid';
import { SectionHeader } from '../elements';
import Checkbox from '../../common/icons/Checkbox';
import { theme } from '../../../theme';

const Criticality = props => {
  const checkboxStyles = {
    width: 'auto',
    height: 'auto',
    color: theme.palette.secondary.main,
    position: 'relative',
    disabled: {
      width: 'auto',
      height: 'auto',
      color: theme.palette.secondary.dark,
      opacity: '0.2',
    },
  };

  const checkboxStylesObj = {
    ...checkboxStyles,
    ...(props.disabled && checkboxStyles.disabled),
  };

  const labelStyles = {
    padding: `${theme.spacing.unit * 0.3}px ${theme.spacing.unit * 0.3}px`,
    marginLeft: 5,
    whiteSpace: 'nowrap',
  };

  return (
    <AmbGrid style={{ flexDirection: 'column', marginTop: '-15px', marginLeft: '6px' }}>
      <>
        {props.isLightsAndSirensEnabled && (
          <>
            <SectionHeader>Criticality</SectionHeader>
            <AmbGrid>
              <span style={checkboxStylesObj}>
                <Checkbox checked={!!props.isLightsAndSirens} />
                <input
                  type="checkbox"
                  checked={!!props.isLightsAndSirens}
                  id="isLightsAndSirens"
                  disabled={props.disabled || null}
                  style={{ position: 'absolute', top: 1, left: 5, opacity: 0 }}
                  onChange={e => props.lightsAndSirensChange(e.target.checked)}
                />
              </span>
              <label htmlFor="isLightsAndSirens" style={labelStyles}>
                Lights and Sirens
              </label>
            </AmbGrid>
          </>
        )}
      </>
    </AmbGrid>
  );
};

Criticality.propTypes = {
  isLightsAndSirensEnabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLightsAndSirens: PropTypes.bool.isRequired,
  lightsAndSirensChange: PropTypes.func.isRequired,
};

export default Criticality;
