import React from 'react';
import { connect } from 'react-redux';
import GetETA from './GetETA.frame';
import * as etaActions from '../../redux/actions/eta.actions';
import * as turndownActions from '../../redux/actions/turndown.actions';
import { isCar } from '../../flags/service.flag';
import { providerHasAutoEta } from '../../parsers/request.parser';
import { getThemeName } from '../../theme';
import { AMBULNZ_THEME, REQUEST_TYPE } from '../../enums';

function GetETAContainer(props) {
  return <GetETA {...props} />;
}

const isTelehealth = state => {
  if (state.requests.requestType && state.requests.requestType.name === REQUEST_TYPE.TELEHEALTH) {
    return true;
  }

  const telehealthEq = state.service.equipment.find(e => e.name === 'Telehealth');

  if (telehealthEq) {
    return state.service.therapistEquipmentIds.includes(telehealthEq.id);
  }

  return false;
};

const showAcceptReject = state =>
  !!state.eta.offeredEtas.length && !state.formFlow.acceptETAInProgress && !state.eta.showEtaProgress;

const showSeeOtherETAs = state =>
  !isCar(state.service) && showAcceptReject(state) && state.user.model.isDispatcher && !state.requests.isNonTransport;

const showCounter = state => window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && state.requests.isNonTransport;

const hideGetEtaBtn = state =>
  (state.requests.willCall && !state.requests.loadedWillCall) || !providerHasAutoEta(state);

const hasActiveOffers = state => state.offers.offers.some(offer => ['accepted', 'created'].includes(offer.statusName));

const ambulnzProvider = user => getThemeName(window.ambulnzConfig.constants, user) === AMBULNZ_THEME;

const showProviderLogo = state =>
  ambulnzProvider(state.user.model) &&
  !!state.eta.offeredEtas.length &&
  !state.formFlow.acceptETAInProgress &&
  !state.eta.showEtaProgress;

function mapStateToProps(state) {
  return {
    priorityType: state.agency.priorityType,
    pickupTime: state.eta.pickupTime,
    pollInProgress: state.eta.pollInProgress,
    etaAccepted: state.formFlow.etaAccepted,
    hasActiveOffers: hasActiveOffers(state),
    isLoading: state.formFlow.acceptETAInProgress || state.eta.showEtaProgress || state.offers.createInProgress,
    scheduled: state.eta.scheduled,
    willCall: state.requests.willCall,
    hideGetEtaBtn: hideGetEtaBtn(state),
    loadedWillCall: state.requests.loadedWillCall,
    inProgressState: state.formFlow.inProgressState,
    requestId: state.requests.request.id,
    request: state.requests.request,
    noAutoEtaRequest: state.formFlow.noAutoEtaRequest,
    maxDateFormatted: state.formFlow.maxDateFormatted,
    showSeeOtherETAs: showSeeOtherETAs(state),
    showCounter: showCounter(state),
    isCar: isCar(state.service),
    showPriorityContent: state.user.model.isDispatcher,
    showEtaInfo: !isCar(state.service) && state.user.model.isDispatcher,
    isDispatcher: state.user.model.isDispatcher,
    showAcceptReject: showAcceptReject(state),
    turndown: state.requests.request.isTurndown && !window.ambulnzConfig.constants.ENABLE_REACTIVATION,
    showProviderLogo: showProviderLogo(state),
    offeredEtas: state.eta.offeredEtas,
    offers: state.eta.offers,
    showTelehealthLink: state.requests.request.id && isTelehealth(state),
  };
}
export default connect(mapStateToProps, { ...etaActions, ...turndownActions })(GetETAContainer);
