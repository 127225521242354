import { styled } from '@mui/system';
import { palette } from '../../theme';

export const SectionHeader = styled('label')`
  && {
    font-size: 11px;
    font-weight: normal;
    color: ${palette.grey.grey80};
    margin-top: -5px;
    margin-bottom: 3px;
  }
`;

export const CareProvidedContainer = styled('div')`
  margin-top: -10px;
  margin-right: 23px;
  margin-left: -10px;
  width: 100%;
`;
