import { createReducer } from 'redux-act';
import * as action from '../actions/pickupTimeChange.actions';
import parsePhone from '../../parsers/phone.parser';

const initialState = {
  open: false,
  updateTypes: [],
  updateTypeId: 0,
  firstName: '',
  lastName: '',
  phone: '',
  details: '',
  etaId: null,
};

const pickupTimeChangeReducer = createReducer(
  {
    [action.openPickupTimeChangeDialog]: (state, payload) => ({
      ...state,
      open: true,
      etaId: payload,
    }),
    [action.closePickupTimeChangeDialog]: state => ({
      ...state,
      open: false,
    }),
    [action.setPickupChangeInfo]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setPickupChangeContactPhone]: (state, payload) => ({
      ...state,
      phone: parsePhone(payload),
    }),
    [action.getRequesterUpdateTypesSuccess]: (state, payload) => ({
      ...state,
      updateTypes: payload,
    }),
    [action.requesterUpdateSuccess]: state => ({
      ...initialState,
      updateTypes: state.updateTypes,
    }),
  },
  initialState,
);

export default pickupTimeChangeReducer;
