import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { parseInfoBox } from '../../parsers/infobox.parser';
import RequesterService from '../../services/requester.service';
import * as FieldValidator from '../../validators/field.validators';
import * as errorActions from '../actions/error.actions';
import * as infoboxActions from '../actions/infobox.actions';

const validForSave = state => state.requests.request.id && FieldValidator.requesterFieldsValid(state.infobox);

export const updateRequesterEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.autoSaveRequesterInfo.match),
    filter(() => store.value.infobox.requesterDirty),
    filter(() => validForSave(store.value)),
    switchMap(() =>
      from$(
        RequesterService.updateRequester(store.value.requests.request.id, parseInfoBox(store.value).requester),
      ).pipe(
        mergeMap(response =>
          concat$(
            of$(infoboxActions.updateRequesterSuccess()),
            of$(infoboxActions.setRequesterDirty(false)),
            of$(errorActions.onError(response)),
          ),
        ),
      ),
    ),
  );

export const autoSaveRequesterSetDirtyEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        infoboxActions.setRequesterInfo.match,
        infoboxActions.setRequesterPhone.match,
        infoboxActions.setRequester.match,
      ].some(match => match(action)),
    ),
    map(() => infoboxActions.setRequesterDirty(true)),
  );
