import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { timeFormat } from '../../../helpers/dateFormatter';

const StyledTableHead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  box-sizing: inherit;
  color: #00838f;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  height: 56px;
`;

const StyledTableBody = styled.tbody`
  color: #004053;
  font-size: 13px;
  border-collapse: collapse;
  text-align: left;
  border-bottom: 1px solid rgba(254, 254, 254, 1);
  & td {
    display: table-cell;
    height: 39px;
    box-sizing: content-box;
  }
`;

const Cell = styled.td`
  text-align: center;
  padding: 4px 24px;
`;

const Candidate = styled.td`
  background-color: #d5d5d540;
  text-align: center;
  padding: 4px 24px;
`;

const ProposedETATableFrame = props => {
  const timeDisplay = time => (time ? timeFormat(time) : '-');
  return (
    <table>
      <colgroup>
        <col style={{ width: '10%' }} />
        <col style={{ width: '16%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '16%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>
      <StyledTableHead>
        <tr>
          <td style={{ textAlign: 'left', padding: '4px 56px 4px 24px' }}>Unit</td>
          <Cell>Previous Trip All Clear</Cell>
          <Candidate>En Route</Candidate>
          <Candidate>Mileage</Candidate>
          <Candidate>ETA</Candidate>
          <Candidate>All Clear</Candidate>
          <Cell>Next Trip Start</Cell>
          <Cell style={{ paddingRight: 24 }}>Conflict</Cell>
        </tr>
      </StyledTableHead>
      <StyledTableBody>
        {props.etas.map(eta => (
          <tr key={eta.id} style={{ textAlign: 'center', fontWeight: eta.id === 0 ? 'bold' : 'normal' }}>
            <td style={{ textAlign: 'left', padding: '4px 56px 4px 24px' }}>{eta.unit}</td>
            <Cell>{timeDisplay(eta.previous)}</Cell>
            <Candidate>{timeDisplay(eta.enRoute)}</Candidate>
            <Candidate>{eta.mileage} mi</Candidate>
            <Candidate>{timeDisplay(eta.eta)}</Candidate>
            <Candidate>{timeDisplay(eta.allClear)}</Candidate>
            <Cell>{timeDisplay(eta.next)}</Cell>
            <Cell style={{ paddingRight: 24 }}>{timeDisplay(eta.conflict)}</Cell>
          </tr>
        ))}
      </StyledTableBody>
    </table>
  );
};

ProposedETATableFrame.propTypes = {
  etas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ProposedETATableFrame;
