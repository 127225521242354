import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Coverages from './Coverages.frame';
import * as infoboxActions from '../../../redux/actions/infobox.actions';
import { patientInfoCollapse, coverageExpanded } from '../../../flags/collapse.flag';
import { formatCoverageTitle } from '../PatientInfo.formatter';

function CoveragesContainer(props) {
  return <Coverages {...props} />;
}

CoveragesContainer.propTypes = {
  setPatientInfo: PropTypes.func.isRequired,
};

const countWarnings = ({ coverages }) =>
  Object.keys(coverages).reduce((acc, k) => coverages[k].warnings.length + acc, 0);

function mapStateToProps(state) {
  return {
    submitInProgress: state.formFlow.submitInProgress,
    shouldCollapse: patientInfoCollapse(state),
    triggerValidation: state.formFlow.triggerValidation,
    expanded: coverageExpanded(state),
    title: formatCoverageTitle(state),
    warnings: countWarnings(state.infobox),
  };
}

export default connect(mapStateToProps, { ...infoboxActions })(CoveragesContainer);
