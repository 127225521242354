import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ServiceLevel from './Service.frame';
import * as serviceActions from '../../../redux/actions/service.actions';
import * as formFlowActions from '../../../redux/actions/formFlow.actions';
import * as requestsActions from '../../../redux/actions/requests.actions';
import * as flags from '../ServiceLevel.flags';
import * as titles from '../ServiceLevel.titles';

const ServiceContainer = props => {
  const [etaState, setEtaState] = useState({
    ETADialogOpen: false,
    newEtaCallback: null,
  });

  const customCloseNewETADialog = okPressed => {
    if (okPressed) {
      props.closeNewETADialog(true);
      etaState.newEtaCallback();
    }
    setEtaState({ ETADialogOpen: false, newEtaCallback: null });
  };

  const applyServiceTypeChange = serviceId => () => {
    if (!props.showEquipment) {
      props.setLightsAndSirens(false);
      props.roundTripChange({ crewMustWaitForPatient: false });
    }

    props.serviceTypeChange(Number(serviceId));
  };

  const handleServiceTypeChange = serviceId => {
    if (props.changeRequiresNewETA) {
      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyServiceTypeChange(serviceId),
      });
      return;
    }

    applyServiceTypeChange(serviceId)();
  };

  return (
    <ServiceLevel
      handleServiceTypeChange={handleServiceTypeChange}
      ETADialogOpen={etaState.ETADialogOpen}
      customCloseNewETADialog={customCloseNewETADialog}
      serviceId={props.serviceId}
      Curbside={props.Curbside}
      curbsideDisabled={props.curbsideDisabled}
      curbsideTitle={props.curbsideTitle}
      Livery={props.Livery}
      liveryDisabled={props.liveryDisabled}
      liveryTitle={props.liveryTitle}
      WAV={props.WAV}
      WAVDisabled={props.WAVDisabled}
      WAVTitle={props.WAVTitle}
      BLS={props.BLS}
      BLSDisabled={props.BLSDisabled}
      BLSTitle={props.BLSTitle}
      ALS={props.ALS}
      ALSDisabled={props.ALSDisabled}
      ALSTitle={props.ALSTitle}
      CCT={props.CCT}
      CCTDisabled={props.CCTDisabled}
      CCTTitle={props.CCTTitle}
      ETADialogTitle={props.ETADialogTitle}
      ETADialogBody={props.ETADialogBody}
    />
  );
};

function mapStateToProps(state) {
  const { Curbside, WAV, Livery, BLS, ALS, CCT } = state.service.serviceTypes;
  return {
    Curbside,
    WAV,
    Livery,
    BLS,
    ALS,
    CCT,
    serviceId: state.service.serviceId,
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    ETADialogTitle: state.formFlow.ETADialogTitle,
    ETADialogBody: state.formFlow.ETADialogBody,
    showEquipment: flags.showEquipment(state),
    curbsideDisabled: flags.serviceTypeDisabled(Curbside, state),
    WAVDisabled: flags.serviceTypeDisabled(WAV, state),
    liveryDisabled: flags.serviceTypeDisabled(Livery, state),
    BLSDisabled: flags.serviceTypeDisabled(BLS, state),
    ALSDisabled: flags.serviceTypeDisabled(ALS, state),
    CCTDisabled: flags.serviceTypeDisabled(CCT, state),
    curbsideTitle: titles.getCustomServiceLevelTitle(Curbside, 'Car', state),
    WAVTitle: titles.getCustomServiceLevelTitle(WAV, 'Wheelchair', state),
    liveryTitle: titles.getCustomServiceLevelTitle(Livery, 'Livery', state),
    BLSTitle: titles.BLSTitle(state),
    ALSTitle: titles.ALSTitle(state),
    CCTTitle: titles.CCTTitle(state),
  };
}

ServiceContainer.propTypes = {
  serviceTypeChange: PropTypes.func.isRequired,
  setLightsAndSirens: PropTypes.func.isRequired,
  roundTripChange: PropTypes.func.isRequired,
  showEquipment: PropTypes.bool.isRequired,
  keyDownETAField: PropTypes.func.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  closeNewETADialog: PropTypes.func.isRequired,
  ETADialogTitle: PropTypes.string.isRequired,
  ETADialogBody: PropTypes.string.isRequired,
  serviceId: PropTypes.number,
  Curbside: PropTypes.number,
  curbsideDisabled: PropTypes.bool.isRequired,
  curbsideTitle: PropTypes.string.isRequired,
  Livery: PropTypes.number,
  liveryDisabled: PropTypes.bool.isRequired,
  liveryTitle: PropTypes.string.isRequired,
  WAV: PropTypes.number,
  WAVDisabled: PropTypes.bool.isRequired,
  WAVTitle: PropTypes.string.isRequired,
  BLS: PropTypes.number,
  BLSDisabled: PropTypes.bool.isRequired,
  BLSTitle: PropTypes.string.isRequired,
  ALS: PropTypes.number,
  ALSDisabled: PropTypes.bool.isRequired,
  ALSTitle: PropTypes.string.isRequired,
  CCT: PropTypes.number,
  CCTDisabled: PropTypes.bool.isRequired,
  CCTTitle: PropTypes.string.isRequired,
};

ServiceContainer.defaultProps = {
  serviceId: undefined,
};

export default connect(
  mapStateToProps,
  {
    ...serviceActions,
    ...formFlowActions,
    ...requestsActions,
  },
)(ServiceContainer);
