import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';
import Telephone from '../../common/Telephone';
import { POLL_IN_PROGRESS_REASONS } from '../../../enums';
import AmbGrid from 'components/common/AmbGrid';

function RequesterFrame({
  requesterPhone,
  request,
  requesterAgencyGroup,
  pollInProgressReason,
  rejectReason,
  etaRejectedMsg,
}) {
  const defaultMessage = (
    <>
      Please continue completing the details below, our dispatch will call you at <Telephone phone={requesterPhone} />{' '}
      with an ETA shortly, or you may call us at <Telephone phone={window.ambulnzConfig.constants.REQUEST_ETA_PHONE} />{' '}
      with trip number {request.id}.
    </>
  );

  const msgMap = {
    [POLL_IN_PROGRESS_REASONS.SCHEDULING_UNAVAILABLE]: requesterAgencyGroup.uberNoDriversAvailableMessage,
    [POLL_IN_PROGRESS_REASONS.ETA_REJECTED]: etaRejectedMsg,
    [POLL_IN_PROGRESS_REASONS.APPMT_CANNOT_BE_MET]: rejectReason,
  };

  return (
    <AmbGrid style={{ alignItems: 'center', width: '100%' }}>
      <ListItem style={{ width: '100%', padding: '12px 0 0 0' }}>
        <div style={{ width: '100%', textAlign: 'center' }}>{msgMap[pollInProgressReason] || defaultMessage}</div>
      </ListItem>
    </AmbGrid>
  );
}

RequesterFrame.propTypes = {
  request: PropTypes.shape().isRequired,
  requesterAgencyGroup: PropTypes.shape(),
  requesterPhone: PropTypes.string.isRequired,
  pollInProgressReason: PropTypes.string.isRequired,
  rejectReason: PropTypes.string.isRequired,
  etaRejectedMsg: PropTypes.string.isRequired,
};

RequesterFrame.defaultProps = {
  requesterAgencyGroup: {},
};

export default RequesterFrame;
