import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

function AmbTooltip(props) {
  const [open, setOpen] = useState(false);
  const { children, title, ...rest } = props;

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      enterDelay={300}
      leaveDelay={300}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={open}
      title={title}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}

AmbTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default AmbTooltip;
