import { get } from 'lodash';
import { SCHEDULE_STATE, SCHEDULE_TEMPLATE_NAME } from '../../enums';
import { theme } from '../../theme';
import { isCar } from '../../flags/service.flag';

export const Color = {
  [SCHEDULE_STATE.TO_PICK_UP]: theme.palette.timeline.toPickup,
  [SCHEDULE_STATE.AT_PICK_UP]: theme.palette.timeline.atPickup,
  [SCHEDULE_STATE.PATIENT_PICKED_UP]: theme.palette.timeline.pickedUp,
  [SCHEDULE_STATE.TO_DROP_OFF]: theme.palette.timeline.toDropoff,
  [SCHEDULE_STATE.AT_DROP_OFF]: theme.palette.timeline.atDropoff,
  [SCHEDULE_STATE.TO_APPOINTMENT]: theme.palette.timeline.toPickup,
  [SCHEDULE_STATE.AT_APPOINTMENT]: theme.palette.timeline.atPickup,
  [SCHEDULE_STATE.MADE_PATIENT_CONTACT]: theme.palette.timeline.pickedUp,
  [SCHEDULE_STATE.APPOINTMENT_END]: theme.palette.timeline.atDropoff,
};

export const Label = {
  [SCHEDULE_STATE.TO_PICK_UP]: 'TO PICKUP',
  [SCHEDULE_STATE.AT_PICK_UP]: 'ON SCENE',
  [SCHEDULE_STATE.PATIENT_PICKED_UP]: 'PATIENT CONTACT',
  [SCHEDULE_STATE.TO_DROP_OFF]: 'TO DESTINATION',
  [SCHEDULE_STATE.AT_DROP_OFF]: 'AT DESTINATION',
  [SCHEDULE_STATE.TO_APPOINTMENT]: 'TO APPOINTMENT',
  [SCHEDULE_STATE.AT_APPOINTMENT]: 'ON SCENE',
  [SCHEDULE_STATE.MADE_PATIENT_CONTACT]: 'Patient Contact',
  [SCHEDULE_STATE.APPOINTMENT_END]: 'APPOINTMENT END',
};

export function getStatusMap(service) {
  const map = JSON.parse(get(window.ambulnzConfig.constants, 'SCHEDULE_STATE_MAP', 'null'));

  if (!map) {
    return {};
  }

  return isCar(service) ? map.uber : map.ambulnz;
}

const displayPromised = state =>
  state === SCHEDULE_STATE.AT_PICK_UP ||
  state === SCHEDULE_STATE.AT_DROP_OFF ||
  state === SCHEDULE_STATE.AT_APPOINTMENT;

const setDetails = (promisedSegments, segment) => {
  const details = [];

  details.push({ label: 'Actual', value: segment.start_at });
  if (displayPromised(segment.state) && promisedSegments && promisedSegments.length > 0) {
    details.push({
      label: 'Promised',
      value: promisedSegments.find(p => p.state === segment.state).start_at,
    });
  }

  return details;
};

const setFutureState = prevSegments => {
  const segments = [...prevSegments];

  segments.reverse().some(s => {
    if (!s.active) {
      s.future = true;
      s.details.find(d => d.label === 'Actual').label = 'Current';
    }
    return s.active;
  });
  return segments.reverse();
};

export const getServiceTypeName = ({ serviceId, serviceTypeLookup }) =>
  serviceTypeLookup && serviceTypeLookup[serviceId] ? serviceTypeLookup[serviceId].name : '';

const parseTransportSegments = (schedule, service, requests) => {
  const statusMap = getStatusMap(service);
  return schedule.segments.map(segment => ({
    id: segment.order,
    active: segment.state === schedule.scheduleState,
    type: Color[segment.state],
    status: statusMap[segment.state],
    details: setDetails(schedule.promisedSegments, segment),
  }));
};

export const nonTransportStatus = {
  to_pick_up: 'To Appointment',
  at_pick_up: 'On Scene',
  patient_picked_up: 'Patient Contact',
  to_drop_off: 'Appointment End',
  at_drop_off: 'Appointment End',
  to_appointment: 'To Appointment',
  at_appointment: 'On Scene',
  made_patient_contact: 'Patient Contact',
  appointment_end: 'Appointment End',
};

const threeSegmentNonTranportMapping = schedule => {
  const segments = schedule.segments.reduce((nonTransportSegments, segment) => {
    nonTransportSegments.push({
      id: segment.order,
      active: segment.state === schedule.scheduleState,
      type: Color[segment.state],
      status: nonTransportStatus[segment.state],
      details: setDetails(schedule.promisedSegments, segment),
    });

    return nonTransportSegments;
  }, []);

  const madePatientContactSegment = schedule.segments.find(
    segment => segment.state === SCHEDULE_STATE.MADE_PATIENT_CONTACT,
  );

  segments.push({
    id: 4,
    active: schedule.scheduleState === SCHEDULE_STATE.APPOINTMENT_END,
    type: Color[SCHEDULE_STATE.APPOINTMENT_END],
    status: nonTransportStatus[SCHEDULE_STATE.APPOINTMENT_END],
    details: [{ label: 'Actual', value: madePatientContactSegment.end_at }],
  });

  return segments;
};

const parseNonTransportSegments = schedule => {
  if (schedule.label.name === SCHEDULE_TEMPLATE_NAME.MOBILE_HEALTH_TRIP) {
    return threeSegmentNonTranportMapping(schedule);
  }

  const segments = schedule.segments.reduce((nonTransportSegments, segment) => {
    if (
      ![SCHEDULE_STATE.TO_PICK_UP, SCHEDULE_STATE.AT_PICK_UP, SCHEDULE_STATE.PATIENT_PICKED_UP].includes(segment.state)
    ) {
      return nonTransportSegments;
    }

    nonTransportSegments.push({
      id: segment.order,
      active: segment.state === schedule.scheduleState,
      type: Color[segment.state],
      status: nonTransportStatus[segment.state],
      details: setDetails(schedule.promisedSegments, segment),
    });

    return nonTransportSegments;
  }, []);

  const atDropOffSegment = schedule.segments.find(segment => segment.state === SCHEDULE_STATE.AT_DROP_OFF);

  segments.push({
    id: 4,
    active: [SCHEDULE_STATE.TO_DROP_OFF, SCHEDULE_STATE.AT_DROP_OFF].includes(schedule.scheduleState),
    type: Color[SCHEDULE_STATE.AT_DROP_OFF],
    status: nonTransportStatus[SCHEDULE_STATE.AT_DROP_OFF],
    details: [{ label: 'Actual', value: atDropOffSegment.end_at }],
  });

  return segments;
};

const parseSegments = (schedule, service, requests) => {
  if (requests.isNonTransport) {
    return parseNonTransportSegments(schedule);
  }

  return parseTransportSegments(schedule, service, requests);
};

export const parse = ({ schedule, service, requests }) => {
  if (!schedule.segments || schedule.segments.length === 0) {
    return [];
  }

  const segments = parseSegments(schedule, service, requests);
  return requests.request.isCompleted ? segments : setFutureState(segments);
};
