import schema from 'schm';
import translate from 'schm-translate';

const location = schema(
  {
    address: Object,
    locationGps: Object,
    id: String,
    typeId: Number,
  },
  translate({
    address: '0.places.0.address',
    locationGps: '0.places.0.locationGps',
    id: '0.places.0.id',
    typeId: '0.places.0.typeId',
  }),
);

export default location;
