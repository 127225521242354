import { filter, map, switchMap } from 'rxjs/operators';
import { from as from$ } from 'rxjs';
import * as errorActions from '../../../../redux/actions/error.actions';
import * as locationActions from '../../../../redux/actions/location.actions';
import { errorPipe } from '../../../../redux/epics/epicsUtil';
import { requestId, updateDepartments } from '../../../../redux/epics/helpers/request.helper';
import * as PcrService from '../../../../services/pcr.service';
import PlaceTypeService from '../../../../services/placeTypeService';
import * as componentActions from '../actions/component.actions';

export const getPcrStatus = (action$, store) =>
  action$.pipe(
    filter(componentActions.getPcrStatus.match),
    filter(() => requestId(store)),
    switchMap(() => PcrService.getPcrStatus(requestId(store))),
    map(response => componentActions.getPcrStatusSuccess(response)),
  );

export const autoSaveDepartmentEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        componentActions.selectPickupDepartment.match,
        componentActions.selectDestinationDepartment.match,
        componentActions.clearPickupDepartment.match,
        componentActions.clearDestinationDepartment.match,
      ].some(match => match(action)),
    ),
    filter(() => requestId(store)),
    filter(() => !store.value.formFlow.loadRequestInProgress),
    switchMap(() => updateDepartments(store)),
    map(response => errorActions.onError(response)),
  );

export const fetchPlaceTypeEpic = action$ =>
  action$.pipe(
    filter(locationActions.fetchPlaceTypes.match),
    switchMap(() =>
      from$(PlaceTypeService.get()).pipe(
        map(result => locationActions.fetchPlaceTypesSuccess(result)),
        errorPipe,
      ),
    ),
  );
