import React from 'react';
import { connect } from 'react-redux';
import PatientAdditionalInfo from './PatientAdditionalInfo.validations';
import { formatPatientAdditionalDetailsTitle } from '../../PatientInfo.formatter';
import * as componentActions from './PatientAdditionalInfo.actions';
import * as infoboxActions from '../../../../redux/actions/infobox.actions';
import { patientAdditionalDetailsDisabled } from '../../../../flags/disabled.flag';

const PatientAdditionalInfoContainer = props => <PatientAdditionalInfo {...props} />;

function mapStateToProps(state) {
  return {
    address1: state.patient.address1,
    address2: state.patient.address2,
    city: state.patient.city,
    state: state.patient.state,
    zip: state.patient.zip,
    title: formatPatientAdditionalDetailsTitle(state.patient),
    disabled: patientAdditionalDetailsDisabled(state),
    isNonTransport: state.requests.isNonTransport,
  };
}

export default connect(mapStateToProps, {
  ...componentActions,
  ...infoboxActions,
})(PatientAdditionalInfoContainer);
