import React from 'react';
import PropTypes from 'prop-types';
import { Label, Vertical } from './elements';
import useDirections from '../../MapWidget/useDirections.hook';

function LoadedMileageFrame({ fromWaypoint, toWaypoint, variant, grow }) {
  const directions = useDirections(fromWaypoint, toWaypoint);

  switch (variant) {
    case 'vertical':
      return <>{directions && <Vertical grow={grow}>{directions.distance}</Vertical>}</>;
    default:
      return (
        <>
          {directions && (
            <Label>
              Loaded Mileage:&nbsp;
              {directions.distance}
            </Label>
          )}
        </>
      );
  }
}

LoadedMileageFrame.propTypes = {
  fromWaypoint: PropTypes.shape(),
  toWaypoint: PropTypes.shape(),
  variant: PropTypes.string,
  grow: PropTypes.bool.isRequired,
};

LoadedMileageFrame.defaultProps = {
  variant: null,
  fromWaypoint: null,
  toWaypoint: null,
};

export default LoadedMileageFrame;
