import React from 'react';
import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import * as showdown from 'showdown';
import Button from '@mui/material/Button';
import { drawerWidth } from '../../../theme';
import AmbCircularProgress from '../../common/AmbCircularProgress';

const StyledButton = styled(({ expandTop, ...props }) => <Button {...props} />)`
  && {
    position: absolute;
    top: ${({ expandTop }) => expandTop};
    right: calc((100% - ${drawerWidth}px) / 2 - 10px);
    z-index: 1;
    color: #17b6d4;
  }
`;

class PortalInfoFrame extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const frame = document.createElement('iframe');
    this.loader = document.createElement('div');
    document.body.appendChild(frame);
    document.body.appendChild(this.loader);
    frame.style.display = 'none';
    frame.style.position = 'absolute';
    frame.style.zIndex = 0;
    frame.style.top = '49px';
    frame.style.right = 0;
    frame.style.width = `calc(100% - ${drawerWidth}px)`;
    frame.style.height = '200px';
    frame.style.maxHeight = '200px';
    frame.style.background = '#fff';
    frame.style.border = 'none';
    frame.style.overflow = 'scroll';
    this.frame = frame;
    this.converter = new showdown.Converter();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.renderInfo(newProps);

    if (newProps.portalInfo) {
      this.showIframe();
    } else {
      this.hideIframe();
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.frame);
    document.body.removeChild(this.loader);
  }

  showIframe() {
    this.frame.style.display = 'block';
    this.frame.style.zIndex = 1;
  }

  hideIframe() {
    this.frame.style.display = 'none';
  }

  expand() {
    this.props.expandPortalInfo(!this.props.expanded);
  }

  renderInfo(props) {
    let expandTop = 210;
    let loaderTop = 250;

    if (props.portalInfo) {
      const html = this.converter.makeHtml(props.portalInfo);
      const { frame } = this;

      frame.contentDocument.open();
      frame.contentDocument.write(html);
      frame.contentDocument.close();

      if (props.expanded) {
        frame.style.height = 'calc(50% - 47px)';
        frame.style['max-height'] = 'calc(50% - 47px)';
        expandTop = 'calc(50% - 40px)';
        loaderTop = 'calc(50% + 10px)';
      } else {
        frame.style.height = '200px';
        frame.style['max-height'] = '200px';
      }
    }
    const loader = (
      <AmbCircularProgress
        propsStyles={{
          width: 30,
          height: 30,
          position: 'absolute',
          top: loaderTop,
          right: `calc((100% - ${drawerWidth}px) / 2 + 15px)`,
          zIndex: 1,
          color: '#17B6D4',
        }}
      />
    );
    const expand = (
      <StyledButton expandTop={expandTop} onClick={() => this.expand()}>
        {props.expanded ? 'Collapse' : 'Expand'}
      </StyledButton>
    );

    if (props.portalInfo) {
      createPortal(
        <div>
          {props.isLoading ? loader : null}
          {expand}
        </div>,
        this.loader,
      );
    } else {
      createPortal(<div />, this.loader);
    }
  }

  render() {
    return null;
  }
}

PortalInfoFrame.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  portalInfo: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandPortalInfo: PropTypes.func.isRequired,
};

export default PortalInfoFrame;
