import moment from 'moment-timezone';
import tzlookup from 'tz-lookup';
import _ from 'lodash';

export function formatDateTime(date) {
  return moment(date).format('MM/DD/YY HH:mm z');
}

export function formatDate(date) {
  return moment(date).format('MM/DD/YY');
}

export function formatDateWithoutTimezone(date, format = 'YYYY-MM-DD') {
  if (!date) {
    return date;
  }

  return moment(date.split('T')[0]).format(format);
}

export function formatTime(date) {
  return moment(date).format('HH:mm z');
}

export const formatDOB = date => date.format('YYYY-MM-DD');

export const formatTimeByTimezone = (locationGps, time) => {
  if (_.isEmpty(locationGps)) {
    return {
      isoString: time,
      dateFormatted: moment(time).format('HH:mm z'),
    };
  }
  const tz = tzlookup(locationGps.lat, locationGps.lng);

  return moment.tz(time, tz);
};

export const dateFormat = (time, customFormat) => {
  const format = window.ambulnzConfig.userSettings.ampm ? 'MM/DD h:mm A z' : 'MM/DD HH:mm z';
  return moment(time).format(customFormat || format);
};

export const timeFormat = (time, showTimezone) => {
  const timezone = showTimezone ? 'z' : '';
  const format = window.ambulnzConfig.userSettings.ampm ? `h:mm A ${timezone}` : `HH:mm ${timezone}`;
  return moment(time).format(format);
};

export const timeFormatWithTimezone = time => timeFormat(time, true);
