import React, { useState } from 'react';
import { Button, MenuItem, TextField } from '@mui/material';
import KeyboardArrowDown from '../../common/icons/keyboardArrowDown';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  boxShadow: 'rgba(0, 64, 82, 0.35) 0px 1px 4px 0px',
  color: '#fff',
  marginLeft: 10,
  marginBottom: 2,
  fontSize: 14,
  fontWeight: 700,
  maxWidth: '83px',
  maxHeight: '30px',
  minWidth: '83px',
  minHeight: '30px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const StyledMenuItem = styled(MenuItem)({
  background: 'transparent',
  padding: '12px 16px',
});

const StyledTextField = styled(TextField)({
  minWidth: 80,
  width: '100%',
  position: 'absolute',
  marginLeft: -100,
  visibility: 'hidden',
});

export default function DocumentTypes({ fileTypes, type, onTypeSelected }) {
  const [open, setOpen] = useState(false);
  const onSelect = event => {
    onTypeSelected(event.target.value);
    setOpen(false);
  };

  return (
    <>
      <div style={{ position: 'relative', marginTop: 5 }}>
        <span>ATTACHMENTS</span>
        <StyledButton onClick={() => setOpen(true)} variant="contained" color="primary">
          UPLOAD
          <KeyboardArrowDown
            style={{ marginLeft: 2, width: '1em', fill: '#fff', flexShrink: 0, display: 'inline-block' }}
          />
        </StyledButton>
        <StyledTextField
          id="select-doctype"
          select
          label={''}
          value={type}
          SelectProps={{
            MenuProps: {
              open,
              onClose: () => setOpen(false),
            },
          }}
          onChange={onSelect}
          margin="none"
        >
          {fileTypes.map(ft => (
            <StyledMenuItem key={ft.id} value={ft.id} name={ft.name}>
              {ft.name}
            </StyledMenuItem>
          ))}
        </StyledTextField>
      </div>
    </>
  );
}
