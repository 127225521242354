import React from 'react';
import { connect } from 'react-redux';
import * as eligibilityActions from '../../../redux/actions/eligibility.actions';
import EligibilityIframeFrame from './EligibilityIframe.frame';

function EligibilityIframeContainer(props) {
  return <EligibilityIframeFrame {...props} />;
}

function mapStateToProps(state) {
  return {
    html: state.eligibility.html,
    portalInfoExpanded: state.eligibility.infoExpanded,
    portalInfo: state.eligibility.portalInfo,
  };
}

export default connect(mapStateToProps, { ...eligibilityActions })(EligibilityIframeContainer);
