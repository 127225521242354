import ApiService from './api.service';

class EmsFacilityTypeService {
  static async getEmsFacilityTypes() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/EmsFacilityTypes`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default EmsFacilityTypeService;
