import { RequestStatus, RequestType, CHIEF_COMPLAINT } from '../enums';
import { isPickupValid, isDestinationValid } from '../validators/location.validator';
import { buildManualPickupSecs } from './schedule.parser';

const getRequestedArrivalTime = (state, fromSchedule) => {
  if (!state.formFlow.etaWasAccepted || fromSchedule) {
    return {
      requestedArrivalTime: state.eta.pickupTime || state.eta.requestedArrivalTime,
    };
  }
  return {};
};

export const getPickupPlace = state => {
  if (isPickupValid(state)) {
    return {
      ...state.map.pickupPlace,
      isResidence: state.map.isPickupResidence,
    };
  }
  return undefined;
};

export const getDestinationPlace = state => {
  if (isDestinationValid(state)) {
    return {
      ...state.map.destinationPlace,
      isResidence: state.map.isDestinationResidence,
    };
  }
  return undefined;
};

const parseValues = state => {
  return {
    creatorId: state.user.model.id,
    requesterAgencyId: state.agency.requesterAgencyId,
    pickupPlace: getPickupPlace(state),
    destinationPlace: getDestinationPlace(state),
    isPickupResidence: state.map.isPickupResidence,
    isDestinationResidence: state.map.isDestinationResidence,
    type: state.requests.request.type || RequestType.PRE_SCHEDULED,
    roundTrip: state.requests.isRoundTrip,
    estimatedWaitForReturnMins: state.requests.isRoundTrip ? +state.requests.estimatedWaitForReturnMins : 0,
    doNotWaitForReturn: !state.requests.crewMustWaitForPatient,
    erBound: state.location.erBound,
    therapistEquipmentIds: state.service.therapistEquipmentIds,
    mobilityId: state.service.mobilityId,
    callTypeId: Number(state.service.callTypeId),
    serviceTypeIds: state.service.serviceTypeIds,
    isBedBound: state.infobox.isBedBound,
    requiresOxygen: state.infobox.requiresOxygen,
    medicarePartB: state.infobox.medicarePartB,
    isLightsAndSirens: state.requests.request.isLightsAndSirens,
    manualPickupSecs: buildManualPickupSecs(state).toString(),
    manualDropOffSecs: state.location.manualDropOffSecs,
    puRoomNumber: state.location.puRoomNumber,
    doRoomNumber: state.location.doRoomNumber,
    pickupDepartmentId: state.requests.pickupDepartmentId || null,
    destinationDepartmentId: state.requests.destinationDepartmentId || null,
    careProvidedAtReceivingFacility: state.requests.careProvidedAtReceivingFacility,
    careProvidedAtReceivingFacilityDescription: state.requests.careProvidedAtReceivingFacilityDescription,
    providerSystemId: state.requests.request.providerSystemId,
    requestedAppointmentTime: state.eta.requestedAppointmentTime,
    isWillCallReturn: state.requests.request.isWillCallReturn,
    canRequestBeActivatedByPatient: state.requests.request.canRequestBeActivatedByPatient,
    requirementIds: state.service.requirementIds,
    transportReasons: state.service.transportReasons,
    telehealthUrl: state.requests.request.telehealthUrl,
  };
};

export const parseRequestValues = (state, fromSchedule = false) => {
  return {
    ...parseValues(state),
    ...getRequestedArrivalTime(state, fromSchedule),
    ...parseStatus(state),
    ...parseRequestType(state),
  };
};

export const parseRequestLocations = state => ({
  pickupPlace: getPickupPlace(state),
  destinationPlace: getDestinationPlace(state),
});

const activatingWillCall = (state, getETA) =>
  (!state.requests.willCall && state.requests.request.isWillCallStatus) || (state.requests.willCall && getETA);

export const parseStatus = (state, getETA) => {
  if (state.requests.willCall && !getETA) {
    return {
      status: RequestStatus.WILL_CALL,
      isWillCall: true,
      requestedArrivalTime: null,
    };
  }

  if (activatingWillCall(state, getETA)) {
    return {
      status: RequestStatus.NEEDS_ASSIGNMENT,
      isWillCall: false,
    };
  }

  if (state.requests.request.status !== undefined) {
    return { status: state.requests.request.status || RequestStatus.CREATED };
  }

  return {};
};

const getEquipmentIdForTelehealth = state => {
  const { equipment } = state.service;

  const telehealthEquipment = equipment.find(e => e.name === 'Telehealth');
  return telehealthEquipment && telehealthEquipment.id;
};

export const parseRequestType = state => {
  const pcpEquipmentIds = state.service.equipment.filter(e => e.name.includes('PCP')).map(eq => eq.id);
  const isTelehealth = state.service.therapistEquipmentIds.includes(getEquipmentIdForTelehealth(state));
  const isPCP = pcpEquipmentIds.some(id => state.service.therapistEquipmentIds.includes(id));

  if (isTelehealth || isPCP) {
    return { typeId: window.ambulnzConfig.requestTypes.TELEHEALTH };
  } else if (state.requests.isNonTransport) {
    return { typeId: window.ambulnzConfig.requestTypes.MOBILE_HEALTH_SERVICE };
  } else {
    return { typeId: window.ambulnzConfig.requestTypes.MEDICAL_TRANSPORT };
  }
};

export const parseRequest = (state, getETA = false) => {
  return {
    ...parseRequestValues(state),
    ...parseStatus(state, getETA),
  };
};

export const parseCreateRequestPayload = (state, getETA = false) => {
  return {
    ...parseRequestValues(state),
    ...parseStatus(state, getETA),
  };
};

export const parseCreateRequestPayloadWithStatus = (state, status) => {
  return {
    ...parseRequest(state),
    ...{ status },
    ...parseRequestType(state),
  };
};

export const parseRequestWithoutRequestedArrivalTime = (state, getETA = false) => {
  return {
    ...parseValues(state),
    ...parseStatus(state, getETA),
    ...parseRequestType(state),
  };
};

export const parseRequestWithStatus = (state, status) => {
  return {
    ...parseRequest(state),
    ...{ status },
  };
};

export const parseRoundTrip = response => ({
  estimatedWaitForReturnMins: response.estimatedWaitForReturnMins,
  isRoundTrip: response.roundTrip,
  crewMustWaitForPatient: !response.doNotWaitForReturn,
});

export function parseChiefComplaint(request) {
  const rcc = request.requestChiefComplaints && request.requestChiefComplaints[0];
  if (!rcc)
    return {
      chiefComplaintName: '',
      chiefComplaintId: null,
      chiefComplaintType: null,
    };

  const { id, name: chiefComplaintName, type } = rcc.chiefComplaint;
  const chiefComplaintType = CHIEF_COMPLAINT.TYPES[type.name];
  const isNonStandard = chiefComplaintType === CHIEF_COMPLAINT.TYPES.NON_STANDARD;

  return {
    chiefComplaintId: isNonStandard ? 0 : id,
    chiefComplaintName,
    chiefComplaintType: CHIEF_COMPLAINT.TYPES[type.name],
  };
}

export const getProvider = state =>
  state.service.providers.find(p => String(p.id) === String(state.requests.request.providerSystemId)) || {};

export const providerHasAutoEta = state => {
  if (!state.user.model.isDara || !state.requests.request.providerSystemId) {
    return true;
  }

  const provider = getProvider(state);
  return provider.autoEta;
};
