import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../redux/store/configureStore';
import FormContainer from 'components/FormWidget/FormContainer';
import TripTypeToggle from 'components/TripTypeToggle';
import AmbCircularProgress from 'components/common/AmbCircularProgress';
import * as routeActions from '../redux/actions/route.actions';
import * as requestsActions from '../redux/actions/requests.actions';
import EditMobileTransport from './EditMobileTransport';
import { REQUEST_TYPE } from '../enums';

function Edit({ atEditRequest, match, request, loadRequestInProgress, requestType }) {
  const shouldSetNonTransport =
    requestType &&
    (requestType.name === REQUEST_TYPE.MOBILE_HEALTH_SERVICE || requestType.name === REQUEST_TYPE.TELEHEALTH);
  useEffect(() => {
    atEditRequest(match.params.id);
  }, [atEditRequest, match.params.id]);

  useEffect(() => {
    if (shouldSetNonTransport) {
      store.dispatch(requestsActions.setNonTransport(true));
    }
  }, [requestType]);

  let view;

  if (shouldSetNonTransport) {
    view = <EditMobileTransport />;
  } else if (request) {
    view = (
      <>
        <TripTypeToggle />
        <FormContainer />
      </>
    );
  }

  return request && !loadRequestInProgress ? (
    view
  ) : (
    <AmbCircularProgress
      propsStyles={{
        position: 'absolute',
        top: 250,
        left: 240,
        zIndex: 1,
      }}
    />
  );
}

function mapStateToProps(state) {
  return {
    request: state.requests.request,
    loadRequestInProgress: state.formFlow.loadRequestInProgress,
    requestType: state.requests.requestType,
  };
}

Edit.propTypes = {
  request: PropTypes.shape().isRequired,
  atEditRequest: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  loadRequestInProgress: PropTypes.bool.isRequired,
  requestType: PropTypes.oneOfType([PropTypes.shape().isRequired, PropTypes.oneOf([undefined]).isRequired]),
};

Edit.defaultProps = {};

export default connect(mapStateToProps, { ...requestsActions, ...routeActions })(Edit);
