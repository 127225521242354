import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { careProvidedAtValid } from '../../../flags/service.flag';
import * as errorActions from '../../../redux/actions/error.actions';
import { requestId, updateRequest } from '../../../redux/epics/helpers/request.helper';
import { careProvidedAtChbChange, careProvidedAtDescChange } from './actions';

export const autoSaveCareProvidedAtEpic = (action$, store) =>
  action$.pipe(
    filter(action => [careProvidedAtChbChange.match, careProvidedAtDescChange.match].some(match => match(action))),
    debounceTime(1000),
    filter(() => requestId(store)),
    filter(() => !store.value.formFlow.loadRequestInProgress),
    filter(() => careProvidedAtValid(store.value.requests)),
    switchMap(() =>
      updateRequest(store, {
        careProvidedAtReceivingFacility: store.value.requests.careProvidedAtReceivingFacility,
        careProvidedAtReceivingFacilityDescription: store.value.requests.careProvidedAtReceivingFacilityDescription,
      }),
    ),
    map(response => errorActions.onError(response)),
  );
