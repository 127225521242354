import React from 'react';
import styled from '@emotion/styled';
import AmbGrid from 'components/common/AmbGrid';
import Button from '@mui/material/Button';

export const Container = styled.div`
  && {
    margin-top: 18px;
  }
`;

const Label = styled.label`
  && {
    font-size: 10px;
    font-weight: normal;
    color: #919799;
    margin-top: 3px;
    margin-left: 5px;
  }
`;

const Logo = styled.img`
  && {
    width: ${({ sortOrder }) => ([0, 1].includes(sortOrder) ? 68 : 52)}px;
    height: 40px;
    padding: 5px;
    object-fit: contain;
  }
`;

const StyledButton = styled(({ selected, name, ...props }) => <Button {...props} />)`
  && {
    backgroundcolor: ${({ selected, name }) => (selected === name ? '#f1fbff' : '#ffffff')};
    border: ${({ selected, name }) => (selected === name ? '1px solid #0099d9' : '1px solid #c7ced1')};
  }
`;

export const Provider = styled(
  ({ name, sortOrder, attachment, className, handleChange, selected, label, disabled }) => {
    const size = sortOrder === 0 || sortOrder === 1 ? '50%' : '30%';
    return (
      <AmbGrid style={{ flexBasis: size }}>
        {sortOrder === 0 && <Label>Primary</Label>}
        {sortOrder === 1 && <Label>Secondary</Label>}
        <StyledButton
          className={className}
          onClick={() => handleChange()}
          selected={selected}
          name={name}
          disabled={disabled || null}
        >
          <Logo src={attachment && attachment.downloadUrl} alt="" />
          {name}
        </StyledButton>
      </AmbGrid>
    );
  },
)`
  && {
      width: 100%;
      border-radius: 5px;
      text-transform: none;
      padding: 0;
      justify-content: unset;
      &:disabled {
        background-color: #f2f2f2;
        border: 1px solid #e1e5e6;
      }
    }
  }
`;

export const Title = styled(({ name, attachment, className }) => {
  return (
    <div className={className}>
      {attachment && attachment.downloadUrl && <Logo src={attachment.downloadUrl} alt="" />}
      {name || 'Providers'}
    </div>
  );
})`
  && {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
`;
