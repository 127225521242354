import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../../common/AmbGrid';
import { SectionHeader } from '../elements';
import AmbToggleButton from '../../common/AmbToggleButton';

const Personnel = props => {
  const forcedRequirementIds = props.forcedCapabilities.reduce((acc, forcedCapability) => {
    if (forcedCapability.capability.type === 'Requirement') {
      acc.push(forcedCapability.capability.id);
    }

    return acc;
  }, []);
  const handleChangeOrShowWarning = (requirement, checked) => {
    if (forcedRequirementIds.includes(requirement.id) && !props.showRequirementWarning) {
      props.setRequirementWarning(!props.showRequirementWarning);
    }

    if (!forcedRequirementIds.includes(requirement.id)) {
      props.handlePersonnelChange(requirement, checked);
    }
  };

  return (
    <>
      <SectionHeader>Personnel</SectionHeader>
      <AmbGrid style={{ gap: '6px', marginBottom: 16 }}>
        {props.requirements.map(requirement => (
          <AmbToggleButton
            label={requirement.displayName}
            checked={props.requirementIds.includes(requirement.id)}
            onChange={checked => handleChangeOrShowWarning(requirement, checked)}
            key={requirement.id}
            disabled={props.disabled || null}
          />
        ))}
      </AmbGrid>
    </>
  );
};

Personnel.propTypes = {
  handlePersonnelChange: PropTypes.func.isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requirementIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  forcedCapabilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf([null]).isRequired]),
  showRequirementWarning: PropTypes.bool.isRequired,
  setRequirementWarning: PropTypes.func.isRequired,
};

export default Personnel;
