import _ from 'lodash';

export const hasFacesheet = ({ patientDocuments }) =>
  !!patientDocuments.attachments.some(a => a.attachmentType.name === 'Facesheet');

export const agencyRequiresCoverage = ({ agency }) =>
  _.get(agency, 'selectedAgency.insuranceInformationRequired', true);

export const agencyRequiresPatientInfo = ({ agency, requests }) =>
  _.get(agency, 'selectedAgency.patientInformationRequired', false);

export const agencyRequiresChiefComplaint = ({ agency }) =>
  _.get(agency, 'selectedAgency.chiefComplaintRequired', true);
