import * as toastActions from '../../actions/toast.actions';
import { asyncSubmitRecipientsResult } from '../../../components/RequestShare/redux/actions';

const asyncSubmitRecipientsResultMiddleware =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (asyncSubmitRecipientsResult.match(action)) {
      const response = action.payload;
      if (response.error) {
        dispatch(toastActions.onToast(response.error.message));
      }
    }
  };

const middlewares = [asyncSubmitRecipientsResultMiddleware];
export default middlewares;
