import ApiService from './api.service';

export default class UberCommunicationService {
  constructor(request) {
    this.request = request;
    this.tenantedRestApiUrl = window.ambulnzConfig.tenantedRestApiUrl;
  }

  async send(message) {
    const path = `${this.tenantedRestApiUrl}/Requests/${this.request.id}/Message`;
    const response = await ApiService.post(path, { message });
    return response.json();
  }
}
