import { createReducer } from 'redux-act';
import * as action from '../actions/newOrExisting.actions';

const initialState = {
  open: false,
  agencyName: '',
  agencyId: 0,
};

const NewOrExistingReducer = createReducer(
  {
    [action.openNewOrExistingDialog]: (state, payload) => ({
      ...state,
      open: payload,
    }),
    [action.setRequestingAgencyName]: (state, payload) => ({
      ...state,
      agencyName: payload,
    }),
    [action.setRequestingAgencyId]: (state, payload) => ({
      ...state,
      agencyId: payload,
    }),
  },
  initialState,
);

export default NewOrExistingReducer;
