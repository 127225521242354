import * as initActions from '../actions/init.actions';
import * as etaActions from '../actions/eta.actions';
import * as toastActions from '../actions/toast.actions';
import * as serviceActions from '../actions/service.actions';
import * as pickupTimeChangeActions from '../actions/pickupTimeChange.actions';
import { setMaxDate } from '../actions/formFlow.actions';
import { setManualPickupTimeSecs, setManualDropoffTimeSecs, fetchPlaceTypes } from '../actions/location.actions';
import { roundTripChange } from '../actions/requests.actions';

const initApp =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (initActions.initApp.match(action)) {
      const {
        REQUEST_DEFAULT_AT_PICKUP_SECS,
        REQUEST_DEFAULT_AT_DROPOFF_SECS,
        ROUND_TRIP_DEFAULT_WAIT_MINS,
        MAX_DATE,
      } = window.ambulnzConfig.constants;

      dispatch(setMaxDate(Number(MAX_DATE)));
      dispatch(setManualPickupTimeSecs(REQUEST_DEFAULT_AT_PICKUP_SECS));
      dispatch(setManualDropoffTimeSecs(REQUEST_DEFAULT_AT_DROPOFF_SECS));
      dispatch(
        roundTripChange({
          estimatedWaitForReturnMins: ROUND_TRIP_DEFAULT_WAIT_MINS,
        }),
      );
      dispatch(fetchPlaceTypes());
      dispatch(serviceActions.fetchChiefComplaints());
      dispatch(serviceActions.fetchProviderCapabilites());
      dispatch(pickupTimeChangeActions.getRequesterUpdateTypes());
    }
  };

const online =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (initActions.online.match(action)) {
      dispatch(toastActions.onToast('App is back online'));
    }
  };

const offline =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (initActions.offline.match(action)) {
      dispatch(toastActions.onToast('App is currently offline'));
      if (getState().eta.showEtaProgress) {
        dispatch(etaActions.requestETATimeout());
      }
    }
  };

export const middlewares = [initApp, online, offline];
export default middlewares;
