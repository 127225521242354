import * as initActions from './redux/actions/init.actions';

export default function(dispatch) {
  window.addEventListener('offline', () => {
    dispatch(initActions.offline());
  });

  window.addEventListener('online', () => {
    dispatch(initActions.online());
  });
}
