import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import SummaryItem from './SummaryItem';

const OfferSummary = ({ offer, request, phone }) => {
  let providerName = offer.provider.displayName;

  if (!providerName) {
    providerName = offer.provider.name.toUpperCase();
  }

  const formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  const summary = request.isScheduled ? (
    <>
      Trip is scheduled with&nbsp;
      {providerName}.
    </>
  ) : (
    <></>
  );

  return (
    <SummaryItem title={request.isScheduled ? null : `Trip is offered to ${providerName}`} message="">
      <AmbGrid style={{ justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
        {summary}
        <div>
          Contact&nbsp;Ambulnz&nbsp;at&nbsp;
          <span>{formattedPhone}</span>
          &nbsp;if you have any questions
        </div>
      </AmbGrid>
    </SummaryItem>
  );
};

OfferSummary.propTypes = {
  offer: PropTypes.shape().isRequired,
  request: PropTypes.shape().isRequired,
};

export default OfferSummary;
