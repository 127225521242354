import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import { Title, Provider } from './elements';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import Providers from '../common/icons/Providers';

const ProvidersFrame = props => {
  const [expanded, setExpanded] = useState(Boolean(props.expanded));

  useEffect(() => {
    if (props.shouldCollapse) setExpanded(false);
  }, [props.shouldCollapse]);

  const togglePanel = () => {
    setExpanded(!expanded);
  };

  return (
    <AmbExpansionPanel
      icon={<Providers style={{ height: 13, width: 18, alignSelf: 'center' }} />}
      title={<Title {...props.provider} />}
      expanded={expanded}
      onTogglePanel={togglePanel}
      id="ProvidersPanel"
    >
      <AmbGrid style={{ marginTop: 12 }}>
        {props.providers &&
          props.providers.map(p => (
            <Provider key={p.id} handleChange={() => props.setProvider(p.id)} selected={props.provider.name} {...p} />
          ))}
      </AmbGrid>
    </AmbExpansionPanel>
  );
};

ProvidersFrame.propTypes = {
  classes: PropTypes.shape().isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ProvidersFrame;
