import React from 'react';
import PropTypes from 'prop-types';
import { CallType } from '../../../enums';
import Question from './Question';

export default function QuestionsFrame({
  answers: { canTravelByCar, requiresWheelChair, isBedbound },
  mobilities,
  handleTravelByCarChange,
  handleWheelchairChange,
  handleBedboundChange,
  canTravelByCarTitle,
  requiresWheelChairTitle,
  canTravelByCarDisabled,
  requiresWheelChairDisabled,
  isBedboundDisabled,
  hasErrors,
  callTypeId,
}) {
  return (
    <>
      {callTypeId !== CallType.DEFAULT && (
        <Question
          name="canTravelByCar"
          description="Transport by car"
          legend="Can the patient travel by car?"
          value={canTravelByCar}
          yesValue={mobilities.walk && mobilities.walk.toString()}
          handleChange={handleTravelByCarChange}
          yesTooltipTitle={canTravelByCarTitle}
          yesDisabled={canTravelByCarDisabled}
          error={hasErrors && canTravelByCar === ''}
          isOnlyQuestion={requiresWheelChairDisabled && isBedboundDisabled}
        />
      )}
      {canTravelByCar !== '' && (
        <Question
          name="requiresWheelChair"
          description="Transport by WAV"
          legend="Does the patient require a wheelchair?"
          value={requiresWheelChair}
          yesValue={mobilities.wheelchair && mobilities.wheelchair.toString()}
          handleChange={handleWheelchairChange}
          yesTooltipTitle={requiresWheelChairTitle}
          yesDisabled={requiresWheelChairDisabled}
          error={hasErrors && requiresWheelChair === ''}
          isOnlyQuestion={canTravelByCarDisabled && isBedboundDisabled}
        />
      )}
      {requiresWheelChair !== '' && (
        <Question
          name="isBedbound"
          description="Transport by BLS"
          legend="Is the patient bed-bound?"
          value={isBedbound}
          yesValue={mobilities.bedbound && mobilities.bedbound.toString()}
          yesDisabled={isBedboundDisabled}
          noValue={mobilities.walk ? mobilities.walk.toString() : mobilities.bedbound.toString()}
          handleChange={handleBedboundChange}
          error={hasErrors && isBedbound === ''}
        />
      )}
    </>
  );
}

QuestionsFrame.propTypes = {
  handleTravelByCarChange: PropTypes.func.isRequired,
  handleWheelchairChange: PropTypes.func.isRequired,
  handleBedboundChange: PropTypes.func.isRequired,
  mobilities: PropTypes.shape().isRequired,
  answers: PropTypes.shape().isRequired,
  canTravelByCar: PropTypes.string,
  requiresWheelChair: PropTypes.string,
  isBedbound: PropTypes.string,
  canTravelByCarTitle: PropTypes.string.isRequired,
  requiresWheelChairTitle: PropTypes.string.isRequired,
  canTravelByCarDisabled: PropTypes.bool.isRequired,
  requiresWheelChairDisabled: PropTypes.bool.isRequired,
  isBedboundDisabled: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  callTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

QuestionsFrame.defaultProps = {
  canTravelByCar: '',
  requiresWheelChair: '',
  isBedbound: '',
};
