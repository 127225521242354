import React from 'react';
import PropTypes from 'prop-types';
import Distance from '../Distance';
import { theme } from 'theme';

const Title = ({
  pickupErrorStyle,
  selectedPickupLocation,
  destinationErrorStyle,
  selectedDestinationLocation,
  erVisibility,
  location,
  showPickup,
  showDestination,
}) => (
  <div>
    {showPickup && (
      <div style={pickupErrorStyle}>
        {(selectedPickupLocation.facilityFull &&
        location.placeTypeEnum &&
        selectedPickupLocation.facilityFull.typeId === location.placeTypeEnum.geopoint
          ? `${selectedPickupLocation.facilityFull.locationGps.lat}, ${selectedPickupLocation.facilityFull.locationGps.lng}`
          : selectedPickupLocation.name) || (showDestination ? 'Pickup Location missing' : 'Select Service Location')}
      </div>
    )}
    <div style={{ color: theme.palette.grey.dark, fontSize: '11px' }}>{selectedPickupLocation.facility}</div>
    {showDestination && (
      <div style={{ paddingTop: 10 }}>
        <span style={destinationErrorStyle}>
          {(selectedDestinationLocation.facilityFull &&
          location.placeTypeEnum &&
          selectedDestinationLocation.facilityFull.typeId === location.placeTypeEnum.geopoint
            ? `${selectedDestinationLocation.facilityFull.locationGps.lat}, ${selectedDestinationLocation.facilityFull.locationGps.lng}`
            : selectedDestinationLocation.name) || 'Destination missing'}
        </span>
        <span style={{ visibility: erVisibility, color: theme.palette.secondary.main }}>&nbsp;(ER)</span>
        <div style={{ color: theme.palette.grey.dark, fontSize: '11px' }}>{selectedDestinationLocation.facility}</div>
      </div>
    )}
    {showDestination && <Distance variant="vertical" />}
  </div>
);

Title.propTypes = {
  pickupErrorStyle: PropTypes.shape(),
  destinationErrorStyle: PropTypes.shape(),
  selectedPickupLocation: PropTypes.shape().isRequired,
  selectedDestinationLocation: PropTypes.shape().isRequired,
  erVisibility: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
};

Title.defaultProps = {
  pickupErrorStyle: {},
  destinationErrorStyle: {},
};

export default Title;
