import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { DARA_THEME, AMBULNZ_THEME } from './enums';

export function getThemeName(config, user) {
  return user.isDara ? DARA_THEME : AMBULNZ_THEME;
}

export const drawerWidth = 530;
export const toolbarHeight = 48;
export const patientSearchTop = 214;
export const palette = {
  primary: {
    light: '#00bcd4',
    main: '#1EC0D9',
    dark: '#004053',
    contrastText: '#6c7980',
    orange: '#ED724A',
    alertText: '#e02f26',
    red: '#f44336',
    disabled: '#A0A0A0',
    helperText: '#1793a6',
    header: '#17B6D4',
  },
  secondary: {
    light: '#26c6da',
    main: '#1EC0D9',
    dark: '#00838f',
    contrastText: '#fff',
  },
  grey: {
    dark: '#919799',
    light: '#e0e0e0',
    semiLight: '#f7f8fb',
    semiLight2: '#F5F7F8',
    primary: '#B6BCBF',
    light2: '#f0f1f2',
    light3: '#5c7c8a',
    navy: '#859da7',
    grey80: '#4F636B',
    silver: '#9e9e9e',
  },
  text: {
    primary: '#004053',
    secondary: '#00838f',
    tertiary: '#003247',
    disabled: '#6c7980',
    hint: '#fff',
    dark: '#2e424d',
    warning: '#D9711E',
  },
  divider: '#fafafa',
  action: {
    active: '#21C0D9',
    hover: 'rgba(0, 0, 0, 0.08)',
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
  timeline: {
    toPickup: '#fc6a71',
    atPickup: '#fcb21e',
    pickedUp: '#5c94cc',
    toDropoff: '#edd537',
    atDropoff: '#43d191',
  },
  icon: {
    primary: '#AFF0F9',
  },
};

export const daraPalette = {
  primary: {
    light: '#00bcd4',
    main: '#004053',
    dark: '#004053',
    contrastText: '#6c7980',
    orange: '#ED724A',
    alertText: '#e02f26',
    red: '#f44336',
    disabled: '#A0A0A0',
    helperText: '#006ea6',
    header: '#006ea6',
  },
  secondary: {
    light: '#26c6da',
    main: '#0090DA',
    dark: '#006ea6',
    contrastText: '#fff',
  },
  grey: {
    dark: '#919799',
    light: '#e0e0e0',
    semiLight: '#f7f8fb',
    primary: '#B6BCBF',
    grey80: '#4F636B',
  },
  text: {
    primary: '#062d40',
    secondary: '#00838f',
    disabled: '#6c7980',
    hint: '#fff',
    dark: '#2e424d',
  },
  divider: '#fafafa',
  action: {
    active: '#0090DA',
    hover: 'rgba(0, 0, 0, 0.08)',
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
  timeline: {
    toPickup: '#fc6a71',
    atPickup: '#fcb21e',
    pickedUp: '#5c94cc',
    toDropoff: '#edd537',
    atDropoff: '#43d191',
  },
  icon: {
    primary: '#ABE5FF',
  },
};

const mixins = {
  toolbar: {
    minHeight: toolbarHeight,
  },
  inputLabel: {
    fontSize: 15,
    color: '#4F636B',
    '&$disabled': {
      color: '#A0A0A0', // Example disabled color
    },
  },
  input: {
    color: '#2e424d',
    fontWeight: 400,
    fontSize: 14,
  },
  divider: {
    position: 'absolute',
    left: -10,
    width: '108%',
    height: 1,
    backgroundColor: 'rgba(0, 0, 0, .2)',
  },
};

const overrides = palette => ({
  MuiCssBaseline: {
    '@global': {
      html: {
        fontSize: '1rem',
      },
    },
  },
  MuiTypography: {
    caption: {
      color: '#004052',
    },
  },
  MuiCardHeader: {
    title: {
      lineHeight: 1.46429,
    },
  },
  MuiTab: {
    root: {
      minWidth: 0,
      '@media (min-width:600px)': {
        minWidth: 0,
      },
      '@media (min-width:960px)': {
        minWidth: 0,
      },
    },
  },
  MuiInputLabel: {
    root: {
      fontSize: 15,
      color: palette.grey.grey80,
    },
  },
  MuiInput: {
    root: {
      fontSize: 14,
    },
    underline: {
      '&$disabled:before': {
        backgroundSize: '4px 1px',
      },
    },
    inputMultiline: {
      resize: 'vertical',
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: 14,
    },
  },
  MuiFormHelperText: {
    root: {
      color: palette.primary.helperText,
      fontSize: 12,
    },
  },
  MuiCheckbox: {
    default: {
      color: palette.secondary.main,
    },
  },
  MuiExpansionPanelSummary: {
    contentExpanded: {
      margin: '5px 0',
    },
    expandIconExpanded: {
      transform: 'translateY(-15%) rotate(180deg)',
    },
  },
  MuiExpansionPanel: {
    root: {
      '&$expanded': {
        margin: '10px 0',
      },
    },
  },
  MuiInputAdornment: {
    positionEnd: {
      marginLeft: 0,
    },
  },
  MuiStepIcon: {
    root: {
      color: palette.secondary.main,
      '&.Mui-completed': {
        color: palette.secondary.main,
      },
    },
  },
  MuiRadio: {
    default: {
      color: palette.grey.navy,
    },
  },
  MuiStepPosition: {
    text: {
      fill: '#FFFFFF',
    },
  },
  MuiListItemText: {
    primary: {
      fontSize: 16,
    },
  },
  MuiButton: {
    containedPrimary: {
      backgroundColor: palette.primary.main,
      color: '#FFF',
      '&:hover': {
        backgroundColor: palette.secondary.dark,
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 14,
      maxWidth: 220,
      whiteSpace: 'pre-line',
    },
    tooltipPlacementTop: {
      margin: '5px 5px 5px',
    },
  },
  MuiAutocomplete: {
    listbox: {
      overflowX: 'hidden',
      maxHeight: 256,
      overflowY: 'auto',
    },
  },
});

const typography = {
  fontFamily: ['Roboto', '"Helvetica Neue"', 'sans-serif'].join(','),
  fontSize: 14,
};

export const theme = createTheme(
  adaptV4Theme({
    palette,
    mixins,
    typography,
    overrides: overrides(palette),
  }),
);

const daraTheme = createTheme(
  adaptV4Theme({
    palette: daraPalette,
    mixins,
    typography,
    overrides: overrides(daraPalette),
  }),
);

export const customTheme = name => (name === DARA_THEME ? daraTheme : theme);
