import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 20 19" style={{ enableBackground: 'new 0 0 20 19', width: 24, height: 24 }} {...props}>
      <path
        style={{ fill: '#004053' }}
        d="M13,5H3.9l3.2-3.2L5.8,0.3L0.1,6l5.7,5.7l1.4-1.4L3.9,7H13c2.8,0,5,2.2,5,5s-2.2,5-5,5H8.5v2H13c3.9,0,7-3.1,7-7C20,8.1,16.9,5,13,5z"
      />
    </svg>
  );
}
