import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { palette } from '../../theme';

const pickersTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#1793a6',
        dark: '#1793a6',
        grey: '#e0e0e0',
      },
    },
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: '#004053',
        },
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: '#004053',
        },
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: '#004053',
        },
        thumb: {
          border: '14px solid #1793a6',
          backgroundColor: '#004053',
        },
      },
      MuiPickersClockNumber: {
        clockNumberSelected: {
          backgroundColor: '#004053',
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: '#004053',
        },
        current: {
          color: '#000',
        },
      },
      MuiPickersModal: {},
      MuiButton: {
        textPrimary: {
          color: '#000',
        },
      },
      MuiIconButton: {
        root: {
          color: '#1EC0D9',
          marginRight: 3,
        },
      },
      MuiPickersDay: {
        root: {
          fontSize: '1em',
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
          marginTop: 13,
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: 15,
          color: '#4F636B',
          top: '-11px',
          transform: 'translate(0, 1.5px) scale(0.75)',
          transformOrigin: 'top left',
          '&.Mui-focused': {
            color: '#4F636B',
          },
          '&.Mui-error': {
            '& .MuiFormLabel-asterisk': {
              color: 'rgb(244, 67, 54)',
            },
          },
        },
      },
      MuiInput: {
        root: {
          fontSize: 14,
        },
        formControl: {
          marginTop: '0 !important',
        },
        underline: {
          '&:after': {
            borderBottom: '2px solid rgba(0, 0, 0, 0.42);',
          },
          '&$disabled:before': {
            borderBottomStyle: 'hidden',
            background: 'transparent',
            backgroundSize: '4px 1px',
            backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.42) 33%, transparent 0%)',
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'left top',
            height: 1,
          },
        },
      },
      MuiInputBase: {
        root: {
          paddingTop: 11,
          paddingBottom: 3,
          '&.Mui-error:before, &.Mui-error:after': {
            borderBottomColor: 'rgb(244, 67, 54) !important',
            transform: 'scaleX(1)',
          },
        },
        input: {
          fontSize: 14,
          padding: '3px 0',
          '&$disabled': {
            color: palette.primary.grey,
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&.Mui-error': {
            color: '#4F636B',
          },
        },
        focused: {},
      },
      MuiFormControlLabel: {
        label: {
          fontSize: 14,
        },
      },
      MuiFormHelperText: {
        root: {
          color: '#1793a6',
        },
      },
      MuiPickersYear: {
        root: {
          fontSize: 16,
          fontWeight: 400,
        },
        yearSelected: {
          fontSize: 24,
          fontWeight: 400,
        },
      },
      MuiTypography: {
        root: {
          fontWeight: 400,
        },
      },
    },
  }),
);

export default pickersTheme;
