import _ from 'lodash';
import { infoboxForm, requester, payer } from '../schemas/infobox.schema';
import { hasFacesheet } from '../flags/valid.flag';

class infoboxValidator {
  constructor(state) {
    this.state = state;
  }

  async manualFormValid() {
    try {
      const infoboxValid = await infoboxForm.validate(this.state).catch(() => {
        return false;
      });
      const payersValid = await this.payersValid().catch(() => {
        return false;
      });

      return infoboxValid && payersValid;
    } catch (e) {
      return false;
    }
  }

  async payersValid() {
    try {
      const filteredCoverages = _.filter(this.state.coverages, coverage => coverage.payer.payerId);
      const filteredRequiredCoverages = _.filter(filteredCoverages, coverage => coverage.isRequired);

      // At least one required coverage should exist
      if (!filteredRequiredCoverages.length) {
        return false;
      }

      await Promise.all(
        filteredCoverages.map(coverage =>
          payer
            .parse({
              ...coverage.payer,
              ...coverage,
            })
            .validate(),
        ),
      ).catch(() => {
        return false;
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async uploadFormValid() {
    try {
      await requester.validate(this.state).catch(() => {
        return false;
      });
      return hasFacesheet(this.state);
    } catch (e) {
      return false;
    }
  }

  async isValid() {
    const manualFormValid = await this.manualFormValid();
    const uploadFormValid = await this.uploadFormValid();

    return manualFormValid || uploadFormValid;
  }
}

export default infoboxValidator;
