import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../../../common/AmbGrid';
import AmbTooltip from '../../../common/AmbTooltip/AmbTooltip';
import Info from '../../../common/icons/info/info-16px';
import AmbSwitch from 'components/common/AmbSwitch';

const BillerVerifiedFrame = ({
  prebillerVerifiedDisabled,
  billerVerifiedChange,
  isBillerVerified,
  isBillerVerifiedTooltip,
  billerVerifiedAt,
  billerVerifiedBy,
  loadedRequest,
  coverageRequired,
}) => {
  const prebillerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '20px -12px 0 -12px',
    borderTop: 'rgba(0, 0, 0, 0.09) 1px solid',
    maxWidth: 'inherit',
    backgroundColor: '#f7f7f7',
    padding: 10,
  };

  const bgColor = isBillerVerified
    ? { backgroundColor: '#f3fcf9', borderLeft: '#33cc99 2px solid', borderRight: '#33cc99 2px solid' }
    : { backgroundColor: '#fff5f5', borderLeft: '#ff5252 2px solid', borderRight: '#ff5252 2px solid' };

  const getPrebillerStyles = () => {
    if (loadedRequest && coverageRequired) {
      return {
        ...prebillerStyles,
        ...bgColor,
      };
    }
    return prebillerStyles;
  };
  const prebillerLabelStyles = prebillerVerifiedDisabled ? { opacity: 0.5 } : {};

  return (
    <AmbGrid style={getPrebillerStyles()}>
      <label id="prebiller-verified-label" style={prebillerLabelStyles}>
        <AmbSwitch
          id="prebiller-verified-switch"
          disabled={prebillerVerifiedDisabled || null}
          onChange={e => billerVerifiedChange(e.target.checked)}
          checked={isBillerVerified}
        />
        PREBILLER VERIFIED
      </label>
      {isBillerVerifiedTooltip && (
        <AmbTooltip id="prebiller-tooltip" title={isBillerVerifiedTooltip} placement="top">
          <div>
            <Info />
          </div>
        </AmbTooltip>
      )}
      {isBillerVerified && (
        <div id="prebiller-verified-by" style={{ fontSize: 12, textAlign: 'right', opacity: 0.8, lineHeight: 1 }}>
          <div>{`as of ${billerVerifiedAt}`}</div>
          <div>{`by ${billerVerifiedBy}`}</div>
        </div>
      )}
    </AmbGrid>
  );
};

BillerVerifiedFrame.propTypes = {
  billerVerifiedAt: PropTypes.string,
  billerVerifiedBy: PropTypes.string,
  billerVerifiedChange: PropTypes.func.isRequired,
  loadedRequest: PropTypes.bool.isRequired,
  isBillerVerified: PropTypes.bool.isRequired,
  isBillerVerifiedTooltip: PropTypes.string.isRequired,
  prebillerVerifiedDisabled: PropTypes.bool.isRequired,
};

BillerVerifiedFrame.defaultProps = {
  billerVerifiedAt: '',
  billerVerifiedBy: '',
};

export default BillerVerifiedFrame;
