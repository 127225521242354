import ApiService from './api.service';

export default {
  async getRequester(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/requester`;
    const response = await ApiService.get(url);
    return response.json();
  },

  async updateRequester(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/requester`;
    const response = await ApiService.put(url, { data: payload });
    return response.json();
  },
};
