import { createReducer } from 'redux-act';
import * as action from '../actions/eligibility.actions';
import { updateDynamicState, updateDynamicStateExcept } from './helpers/dynamicReducer.helper';
import eligibilityStateSchema from '../../schemas/eligibilityState.schema';

const updateEligibilities = updateDynamicState('eligibilities', () => eligibilityStateSchema.parse({}));
const updateEligibilitiesExcept = updateDynamicStateExcept('eligibilities', () => eligibilityStateSchema.parse({}));

export const initialState = {
  eligibilities: {},
  html: '',
  portalInfo: '',
  infoExpanded: false,
  errorMessage: '',
};

const eligibilityReducer = createReducer(
  {
    [action.setInfo]: (state, { coordinationTypeId, ...payload }) => ({
      ...state,
      ...updateEligibilities(state, coordinationTypeId, payload),
    }),
    [action.setCreating]: (state, { coordinationTypeId, value, sessionId }) => ({
      ...state,
      ...updateEligibilitiesExcept(
        state,
        coordinationTypeId,
        {
          isEligibilityCreating: value,
          sessionId,
        },
        {
          sessionId: null,
          isEligibilityCreating: false,
          isEligibilityLoading: false,
          isEligibilityDisabled: true,
        },
      ),
    }),
    [action.setLoading]: (state, { coordinationTypeId, value }) => ({
      ...state,
      ...updateEligibilitiesExcept(
        state,
        coordinationTypeId,
        {
          isEligibilityLoading: value,
        },
        {
          isEligibilityLoading: false,
          isEligibilityCreating: false,
          isEligibilityDisabled: true,
        },
      ),
    }),
    [action.setDisabled]: (state, { coordinationTypeId, value }) => ({
      ...state,
      ...updateEligibilities(state, coordinationTypeId, {
        isEligibilityDisabled: value,
      }),
    }),
    [action.resetButtons]: state => ({
      ...state,
      ...updateEligibilitiesExcept(
        state,
        -1,
        {},
        {
          isEligibilityCreating: false,
          isEligibilityLoading: false,
          isEligibilityDisabled: false,
        },
      ),
    }),
    [action.initEligibility]: (state, coordinationTypeId) => ({
      ...state,
      ...updateEligibilities(state, coordinationTypeId, {}),
    }),
    [action.setHTML]: (state, payload) => ({
      ...state,
      html: payload,
      errorMessage: '',
    }),
    [action.error]: (state, payload) => ({
      ...state,
      errorMessage: payload,
    }),
    [action.closeErrorModal]: state => ({
      ...state,
      errorMessage: '',
    }),
    [action.closeFrame]: state => ({
      ...state,
      html: '',
      portalInfo: '',
      infoExpanded: false,
    }),
    [action.setPortalInfo]: (state, payload) => ({
      ...state,
      portalInfo: payload,
    }),
    [action.expandPortalInfo]: (state, payload) => ({
      ...state,
      infoExpanded: payload,
    }),
  },
  initialState,
);

export default eligibilityReducer;
