import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { palette } from '../../../theme';

const Label = styled.label`
  font-size: 12px;
  line-height: 1.3;
  padding-bottom: 2px;
  color: ${palette.grey.light3};
`;

const Fare = styled.div`
  font-size: 16px;
  padding-bottom: 4px;
`;

const ReturnFareLabel = styled.span`
  color: ${palette.grey.dark};
`;

export default function FareLabel({ fareAmount, returnFareAmount }) {
  return (
    <>
      <Label className="eta-label__label" htmlFor="fareAmount">
        Fare
      </Label>
      <Fare className="eta-label__fare" id="fareAmount">
        <span className="eta-label__fare-amount">{`$${fareAmount}`}</span>
        {returnFareAmount && (
          <ReturnFareLabel className="eta-label__return-fare-amount">
            &nbsp;/&nbsp;
            {`$${returnFareAmount}`}
          </ReturnFareLabel>
        )}
      </Fare>
    </>
  );
}

FareLabel.defaultProps = {
  fareAmount: null,
  returnFareAmount: null,
};

FareLabel.propTypes = {
  fareAmount: PropTypes.string,
  returnFareAmount: PropTypes.string,
};
