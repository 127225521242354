import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import AmbGroupToggle from '../../common/AmbGroupToggle';
import * as etaActions from '../../../redux/actions/eta.actions';
import * as formFlowActions from '../../../redux/actions/formFlow.actions';
import { ETA_TYPES } from '../../../enums';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { newETArequired } from '../../../messages';
import { etaTypeDisabled } from '../../../flags/disabled.flag';

const EtaType = ({
  disabled,
  setEtaType,
  etaType,
  ETADialogTitle,
  ETADialogBody,
  changeRequiresNewETA,
  clearEtaFromEtaTypeToggle,
  changeDialogContent,
}) => {
  const [open, setOpen] = useState(false);
  const [newType, setNewType] = useState(null);

  const handleChange = key => {
    setNewType(key);
    if (changeRequiresNewETA) {
      changeDialogContent(newETArequired);
      setOpen(true);
      return;
    }

    setEtaType(key);
  };

  const handleCloseDialog = confirm => {
    if (confirm) {
      clearEtaFromEtaTypeToggle();
      setEtaType(newType);
    }
    setOpen(false);
  };
  return (
    <>
      <AmbGroupToggle value={etaType} onChange={({ key }) => handleChange(key)}>
        <Button selectedkey={ETA_TYPES.PICKUP} disabled={disabled || null}>
          Pickup
        </Button>
        <Button selectedkey={ETA_TYPES.APPOINTMENT} disabled={disabled || null}>
          Appointment
        </Button>
      </AmbGroupToggle>
      <ConfirmationDialog
        open={open}
        onClose={handleCloseDialog}
        title={ETADialogTitle}
        fullWidth
        maxWidth="360px"
        cancel
      >
        <div>{ETADialogBody}</div>
      </ConfirmationDialog>
    </>
  );
};

EtaType.propTypes = {
  setEtaType: PropTypes.func.isRequired,
  unReserve: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  closeNewETADialog: PropTypes.func.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  ETADialogTitle: PropTypes.string.isRequired,
  ETADialogBody: PropTypes.string.isRequired,
  etaType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    requestedArrivalTime: state.eta.requestedArrivalTime,
    etaType: state.eta.etaType,
    disabled: etaTypeDisabled(state),
  };
}

export default connect(mapStateToProps, {
  ...etaActions,
  ...formFlowActions,
})(EtaType);
