import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Timeline from './Timeline.frame';
import { parse, getServiceTypeName } from './Timeline.parser';

const TimelineContainer = props => props.display && <Timeline {...props} />;

function mapStateToProps(state) {
  const isProviderTrip = state.schedule.label && state.schedule.label.name === 'provider_trip';
  return {
    segments: parse(state),
    serviceType: getServiceTypeName(state.service),
    display: !isProviderTrip && (state.requests.request.isActive || state.requests.request.isCompleted),
    isNonTransport: state.requests.isNonTransport,
  };
}

TimelineContainer.prototype = {
  display: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {})(TimelineContainer);
