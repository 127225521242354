import React from 'react';
import { connect } from 'react-redux';
import PullBackRequest from './Unassign.frame';
import * as unassignActions from './redux/actions';

const PullBackRequestContainer = props => <PullBackRequest {...props} />;

function mapStateToProps(state) {
  return {
    request: state.requests.request,
    completed: state.unassignRequest.completed,
    loading: state.unassignRequest.loading,
    error: state.unassignRequest.error,
    offer: state.offers.offers[0],
    userType: state.user.model.userType,
  };
}

export default connect(mapStateToProps, { ...unassignActions })(PullBackRequestContainer);
