import React from 'react';
import AmbTooltip from './AmbTooltip/AmbTooltip';
import { Button } from '@mui/material';

const BtnWithTooltip = ({ name, title, children, ...rest }) => (
  <AmbTooltip aria-label={`${name}-tooltip`} name={`${name}-tooltip`} title={title} placement="top">
    <span>
      <Button {...rest}>{children}</Button>
    </span>
  </AmbTooltip>
);

export default BtnWithTooltip;
