import { createAction } from '@reduxjs/toolkit';

export const addLoadedRecipients = createAction('[RequestShare] addLoadedRecipients');

export const reducer = {
  [addLoadedRecipients]: (state, recipients) => ({
    ...state,
    loadedRecipients: state.loadedRecipients.concat(recipients),
  }),
};
