import ApiService from './api.service';

export default class PlaceTypeService {
  static async get() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/PlaceTypes`;
    const response = await ApiService.get(url);

    return response.json();
  }
}
