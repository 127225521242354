import * as querystringActions from '../actions/querystring.actions';
import * as agencyActions from '../actions/agency.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as newOrExistingActions from '../actions/newOrExisting.actions';
import { removePrefix, parseAgencyName } from '../../parsers/querystring.parser';

export const querystringLoadedMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (querystringActions.querystringLoaded.match(action)) {
      const qs = action.payload;
      if (qs.callerName) {
        dispatch(newOrExistingActions.setRequestingAgencyName(parseAgencyName(qs.callerName)));
        dispatch(agencyActions.queryAgencies(parseAgencyName(qs.callerName)));
      }
      if (qs.callerNumber) {
        dispatch(infoboxActions.setRequesterPhone(removePrefix(qs.callerNumber)));
      }
    }
  };

export const querystringMdl = [querystringLoadedMdl];
