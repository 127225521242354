import * as userActions from '../actions/user.actions';
import * as serviceActions from '../actions/service.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as agencyActions from '../actions/agency.actions';
import { ViewStatus } from '../../enums';
import { parseAgency } from '../../parsers/agency.parser';

const fetchCurrentUserSuccess =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (userActions.fetchCurrentUserSuccess.match(action)) {
      if (getState().route.status === ViewStatus.CREATE) {
        if (getState().user.model.prepopulateAsRequester) {
          dispatch(infoboxActions.setRequester(action.payload));
        }
      }

      dispatch(serviceActions.fetchCallTypes());
    }

    if (userActions.fetchUserFacilitiesSuccess.match(action)) {
      const userRequesterAgencyFacilities = action.payload.filter(el => el.requesterAgencyId);
      const facility = userRequesterAgencyFacilities.length === 1 && userRequesterAgencyFacilities[0];

      if (getState().route.status === ViewStatus.CREATE) {
        if (facility) {
          dispatch(agencyActions.fetchPlace(parseAgency(facility.requesterAgency)));
        }
      }
    }
  };

export const middlewares = [fetchCurrentUserSuccess];
export default middlewares;
