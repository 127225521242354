import React from 'react';
import PropTypes from 'prop-types';

function CommonItem(props) {
  return (
    <div style={{ marginTop: 4, marginLeft: 5, marginBottom: 4, marginRight: 0, width: '100%' }}>
      <div style={{ color: '#2e424d' }}>
        <span style={{ whiteSpace: 'nowrap', fontWeight: 400 }}>{props.children.name}</span>
      </div>
    </div>
  );
}

CommonItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.node]).isRequired,
};

export default CommonItem;
