import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { setETAWasAcceptedState } from '../../../redux/actions/eta.actions';
import {
  createOffer,
  createOfferSuccess,
  getOfferSocketMessage,
  getOffers,
  getOffersSuccess,
  setCreateInProgress,
} from '../../../redux/actions/offer.actions';
import OfferService from '../../../services/offer.service';

export const getOffersEpic = (action$, store) =>
  action$.pipe(
    filter(getOffers.match),
    switchMap(() => from$(OfferService.getOffers([store.value.requests.request.id.toString()]))),
    mergeMap(offers => concat$(of$(getOffersSuccess(offers), setCreateInProgress(false)))),
  );

export const getOfferSocket = (action$, store) =>
  action$.pipe(
    filter(getOfferSocketMessage.match),
    filter(({ payload }) => payload.meta.request_id === store.value.requests.request.id),
    map(() => getOffers()),
  );

export const createOfferEpic = (action$, store) =>
  action$.pipe(
    filter(createOffer.match),
    switchMap(({ payload }) => from$(OfferService.createOffer(payload.providerId, store.value.requests.request.id))),
    mergeMap(() => concat$(of$(getOffers()), of$(createOfferSuccess()), of$(setETAWasAcceptedState()))),
  );
