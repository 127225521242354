import _ from 'lodash';
import moment from 'moment-timezone';
import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import tzlookup from 'tz-lookup';
import { ETA_TYPES } from '../../enums';
import * as mapParser from '../../parsers/map.parser';
import MapService from '../../services/map.service';
import * as errorActions from '../actions/error.actions';
import * as etaActions from '../actions/eta.actions';
import * as mapActions from '../actions/map.actions';
import { requestId, updateRequestLocations } from './helpers/request.helper';
import { getVehicleLocation } from './helpers/vehicleLocation.helper';

export const getUserLocationEpic = action$ =>
  action$.pipe(
    filter(mapActions.mapInit.match),
    switchMap(() =>
      from$(MapService.getUserLocation()).pipe(map(result => mapActions.setMapLocationByNavigator(result))),
    ),
  );

export const updateRequestLocationsEpic = (action$, store) =>
  action$.pipe(
    filter(mapActions.asyncUpdateResidence.match),
    switchMap(() => updateRequestLocations(store)),
    mergeMap(response =>
      concat$(of$(mapActions.asyncUpdateResidenceSuccess(response)), of$(errorActions.onError(response))),
    ),
  );

export const asyncGetVehicleLocationEpic = (action$, store) =>
  action$.pipe(
    filter(mapActions.asyncGetVehicleLocation.match),
    filter(() => store.value.requests.request.inProgress),
    filter(() => store.value.requests.request.providerId),
    switchMap(() => getVehicleLocation(requestId(store))),
    mergeMap(response =>
      concat$(of$(mapActions.asyncGetVehicleLocationSucces(response)), of$(errorActions.onError(response))),
    ),
  );

export const setDefaultTimezoneByPickupEpic = (action$, store) =>
  action$.pipe(
    filter(mapActions.setMapFromWaypoint.match),
    filter(() => !store.value.eta.requestedAppointmentTime),
    map(action => {
      const { locationGps } = action.payload;
      const tz = tzlookup(locationGps.lat, locationGps.lng);
      moment.tz.setDefault(tz);
      return etaActions.setTimezone(tz);
    }),
  );

export const setDefaultTimezoneByDropoffEpic = (action$, store) =>
  action$.pipe(
    filter(mapActions.setMapToWaypoint.match),
    filter(() => store.value.eta.requestedAppointmentTime),
    map(action => {
      const { locationGps } = action.payload;
      const tz = tzlookup(locationGps.lat, locationGps.lng);
      moment.tz.setDefault(tz);
      return etaActions.setTimezone(tz);
    }),
  );

export const setDefaultTimezoneByAgencyPickupEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [mapActions.setMapPickupLocationByAgency.match, mapActions.setMapDestinationLocationByAgency.match].some(match =>
        match(action),
      ),
    ),
    map(action => {
      const locationGps = mapParser.getCoordsByAgency(action.payload);
      const tz = tzlookup(locationGps.lat, locationGps.lng);
      moment.tz.setDefault(tz);
      return etaActions.setTimezone(tz);
    }),
  );

export const setDefaultTimezoneByEtaTypeEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.setEtaType.match),
    map(action => {
      const state = store.value;
      const locationGps =
        action.payload === ETA_TYPES.APPOINTMENT
          ? state.map.destinationPlace.locationGps
          : state.map.pickupPlace.locationGps;
      if (!_.isEmpty(locationGps)) {
        const tz = tzlookup(locationGps.lat, locationGps.lng);
        moment.tz.setDefault(tz);
        return etaActions.setTimezone(tz);
      }
    }),
  );
