import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'center',
});

const StyledDialogContent = styled(DialogContent)({
  textAlign: 'center',
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center',
});

const StyledParagraph = styled('p')({
  margin: 0,
});

const NewOrExistingFrame = props => {
  // workaround for deprecated disableBackdropClick prop on Dialog
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    props.onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="select-new-or-existing-dialog"
      open={props.open}
    >
      <StyledDialogTitle>
        <StyledParagraph>Thank you for calling your favorite Ambulnz service.</StyledParagraph>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledParagraph>
          Are you calling from <b>{props.agencyName}</b>
          <br />
          about a <b>New trip</b> or about an <b>Existing</b> one?
        </StyledParagraph>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          id="open-new-trip"
          onClick={props.openNewTrip}
          color="primary"
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(30, 192, 217, 0.1)',
            },
          }}
        >
          NEW TRIP
        </Button>
        <Button
          id="open-existing-trip"
          onClick={props.openExistingTrip}
          color="primary"
          sx={{
            '&:hover': {
              backgroundColor: 'rgba(30, 192, 217, 0.1)',
            },
          }}
        >
          EXISTING TRIP
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

NewOrExistingFrame.propTypes = {
  openNewTrip: PropTypes.func.isRequired,
  openExistingTrip: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  agencyName: PropTypes.string.isRequired,
};

export default NewOrExistingFrame;
