import React from 'react';
import AmbGrid from '../../components/common/AmbGrid';
import PropTypes from 'prop-types';
import parseRecipients from 'parsers/recipient.parser';
import AmbExpansionPanel from '../common/AmbExpansionPanel/AmbExpansionPanel';
import Share from '../common/icons/Share';
import ChipInput from '../common/ChipInput';
import { isValidEmail, isValidPhone } from './RequestShare.validations';
import parseDigits from '../../parsers/digit.parser';
import { formatPhone } from './RequestShare.formatter';
import AmbChip from 'components/common/AmbChip';
import { theme } from '../../theme';

/**
 * RequestShare Component
 *
 * It collects recipients array. You can add, remove and reset recipients
 * Recipients are not persisted
 */
class RequestShareFrame extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      expanded: false,
      emailError: '',
      phoneError: '',
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.shouldCollapse) {
      this.setState({ expanded: false });
    }
  }

  /**
   * @param {string} rawValue
   * @return {boolean}
   */
  validateEmail = rawValue => {
    const value = rawValue.trim();
    const isEmpty = !value.length;
    const isValid = isEmpty || isValidEmail(value);

    this.setState({ emailError: isValid ? '' : 'Please use a valid address' });

    return isValid;
  };

  /**
   * @param {string} rawValue
   * @return {boolean}
   */
  validatePhone = input =>
    parseRecipients(input).every(rawValue => {
      const value = rawValue.trim();
      const isEmpty = !value.length;
      const parsedValue = parseDigits(value);
      const isValid =
        isEmpty || (isValidPhone(parsedValue) && !this.isDuplicate(this.props.phoneRecipients, parsedValue));

      this.setState({
        phoneError: isValid ? '' : 'Please use a valid phone number',
      });

      return isValid;
    });

  clearEmailError = () => {
    if (this.state.emailError) {
      this.setState({ emailError: '' });
    }
  };

  clearPhoneError = () => {
    if (this.state.phoneError) {
      this.setState({ phoneError: '' });
    }
  };

  isDuplicate = (array, value) => array.find(el => el === value);

  togglePanel = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  scrollToView = () => {
    this.containerRef.current.scrollIntoView();
  };

  render() {
    const inputProps = {
      style: { ...theme.mixins.input, width: '100%' },
      autoComplete: 'new-password',
    };

    const inputLabelProps = {
      style: { ...theme.mixins.inputLabel },
      shrink: true,
    };

    const chipHelperTextProps = {
      style: { color: theme.palette.primary.alertText },
    };

    const phoneChipRenderer = ({ text, isFocused, isDisabled, handleClick, handleDelete, style }, key) => (
      <AmbChip
        key={key}
        onClick={handleClick}
        onDelete={handleDelete}
        style={style}
        isFocused={isFocused}
        isDisabled={isDisabled}
        text={formatPhone(text)}
        value={formatPhone(text)}
      />
    );

    return (
      <AmbExpansionPanel
        icon={<Share style={{ height: 16, width: 16, marginTop: 2, fill: theme.palette.icon.primary }} />}
        title={this.props.title}
        expanded={this.state.expanded}
        onTogglePanel={this.togglePanel}
        id="RequestSharePanel"
        CollapseProps={{
          onEntered: this.scrollToView,
        }}
      >
        <AmbGrid style={{ flexDirection: 'column', width: '100%' }}>
          <div ref={this.containerRef} style={{ marginBottom: 10 }} />
          <AmbGrid style={{ margin: '10px 0' }}>
            <ChipInput
              blurBehavior="add"
              chiphelpertextprops={chipHelperTextProps}
              helperText={this.state.emailError}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              label="Emails (press Enter/Tab to add, up to 5)"
              newChipKeyCodes={[13, 9]}
              onAdd={this.props.addEmailRecipient}
              onBeforeAdd={this.validateEmail}
              error={!!this.state.emailError}
              onDelete={this.props.deleteEmailRecipient}
              onUpdateInput={this.clearEmailError}
              value={this.props.emailRecipients}
              disabled={this.props.disabled || null}
              fullWidth
            />
          </AmbGrid>
          <AmbGrid style={{ margin: '10px 0' }}>
            <ChipInput
              blurBehavior="add"
              chipRenderer={phoneChipRenderer}
              chiphelpertextprops={chipHelperTextProps}
              helperText={this.state.phoneError}
              InputLabelProps={inputLabelProps}
              InputProps={inputProps}
              label="Phone Numbers (press Enter/Tab to add, up to 5)"
              newChipKeyCodes={[13, 9]}
              onAdd={this.props.addPhoneRecipient}
              onBeforeAdd={this.validatePhone}
              error={!!this.state.phoneError}
              onDelete={this.props.deletePhoneRecipient}
              onUpdateInput={this.clearPhoneError}
              value={this.props.phoneRecipients}
              disabled={this.props.disabled || null}
              fullWidth
            />
          </AmbGrid>
        </AmbGrid>
      </AmbExpansionPanel>
    );
  }
}

RequestShareFrame.propTypes = {
  shouldCollapse: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  addEmailRecipient: PropTypes.func.isRequired,
  addPhoneRecipient: PropTypes.func.isRequired,
  deleteEmailRecipient: PropTypes.func.isRequired,
  deletePhoneRecipient: PropTypes.func.isRequired,
};

export default RequestShareFrame;
