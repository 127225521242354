import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 17 13" {...props}>
      <path
        style={{ fill: '#004251' }}
        d="M16.5,3h-5.2c-0.3,0-0.5-0.2-0.5-0.5v0C10.8,2.2,11,2,11.3,2h5.2C16.8,2,17,2.2,17,2.5v0C17,2.8,16.8,3,16.5,3z
	"
      />
      <path
        style={{ fill: '#004251' }}
        d="M16.5,7h-5.7c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5h5.7C16.8,6,17,6.2,17,6.5v0C17,6.8,16.8,7,16.5,7
	z"
      />
      <path
        style={{ fill: '#004251' }}
        d="M16.5,11h-2.8c-0.3,0-0.5-0.2-0.5-0.5v0c0-0.3,0.2-0.5,0.5-0.5h2.8c0.3,0,0.5,0.2,0.5,0.5v0
	C17,10.8,16.8,11,16.5,11z"
      />
      <g>
        <g>
          <path style={{ fill: '#004251' }} d="M6.1,8C4.1,8,2.6,6.2,2.6,4s1.5-4,3.5-4s3.5,1.8,3.5,4S8,8,6.1,8z" />
        </g>
      </g>
      <path style={{ fill: '#FFFFFF' }} d="M6.1,7C4.6,7,3.5,5.6,3.5,4s1.2-3,2.6-3s2.6,1.4,2.6,3S7.5,7,6.1,7z" />
      <g>
        <g>
          <path d="M6.1,6c-1,0-1.7-0.9-1.7-2s0.8-2,1.7-2s1.7,0.9,1.7,2S7,6,6.1,6z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path d="M1.1,12c0.1-1.7,1.7-4,5-4s4.9,2.3,5,4H1.1z" />
            <path
              style={{ fill: '#004251' }}
              d="M6.1,8c3.2,0,4.9,2.3,5,4h-10C1.2,10.3,2.8,8,6.1,8 M6.1,7c-3.9,0-5.9,2.9-6,4.9L0,13h1.1h10h1.1l-0.1-1.1
				C11.9,9.9,10,7,6.1,7L6.1,7z"
            />
          </g>
          <path
            style={{ fill: '#FFFFFF' }}
            d="M6.1,9c1.9,0,3.2,1,3.7,2H2.4C2.9,10,4.1,9,6.1,9 M6.1,8c-3.2,0-4.9,2.3-5,4h10C11,10.3,9.3,8,6.1,8L6.1,8z"
          />
        </g>
      </g>
    </svg>
  );
}
