import { useState, useEffect } from 'react';
import { PATIENT_SIGNATURE_TYPE } from '../../enums';

export default function useAttachmentTypes(
  loadedTypes,
  { patientPresent, loadAttachmentTypes, attachments, isRequester },
) {
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  useEffect(() => {
    loadAttachmentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function filterTypes() {
    const signatureAttached = attachments.map(att => att.attachmentType.name).includes(PATIENT_SIGNATURE_TYPE);
    let result = loadedTypes.filter(
      type => (patientPresent && !signatureAttached) || type.name !== PATIENT_SIGNATURE_TYPE,
    );
    if (isRequester) {
      result = result.filter(type => type.type !== 'PCR');
    }
    setAttachmentTypes(result);
  }
  useEffect(
    () => {
      filterTypes(attachmentTypes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [patientPresent, loadedTypes.length, attachments.length, isRequester],
  );

  return attachmentTypes;
}
