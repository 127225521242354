import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import AmbExpansionPanel from '../../common/AmbExpansionPanel';
import Notes from '../../common/icons/Notes';
import AmbTextField from 'components/common/AmbTextField';
import { theme } from '../../../theme';

class NotesFrame extends React.Component {
  state = {
    expanded: false,
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.shouldCollapse) {
      this.setState({ expanded: false });
    }
  }

  handleInputChange = event => {
    this.props.setNotes({ [event.target.name]: event.target.value });
  };

  togglePanel = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { title } = this.props;
    const { expanded } = this.state;
    return (
      <AmbExpansionPanel
        icon={<Notes style={{ height: 16, width: 16, marginTop: 2, fill: theme.palette.icon.primary }} />}
        title={title}
        expanded={expanded}
        onTogglePanel={this.togglePanel}
        id="NotesPanel"
      >
        <div style={{ width: '100%' }}>
          <AmbGrid style={{ flexDirection: 'column' }}>
            <hr
              style={{ margin: '15px 0 5px 0', backgroundColor: '#fafafa', height: 1, border: 'none', flexShrink: 0 }}
            />
            <AmbTextField
              id="notes"
              InputProps={{
                style: { fontSize: theme.typography.fontSize },
              }}
              InputLabelProps={{
                style: { ...theme.mixins.inputLabel },
                shrink: true,
              }}
              label="Pickup Instructions"
              value={this.props.notes}
              name="notes"
              multiline
              rows="8"
              fullWidth
              onChange={this.handleInputChange}
              disabled={this.props.disabled || null}
            />
          </AmbGrid>
        </div>
      </AmbExpansionPanel>
    );
  }
}

NotesFrame.propTypes = {
  setNotes: PropTypes.func.isRequired,
  shouldCollapse: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  notes: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NotesFrame.defaultProps = {
  notes: '',
};

export default NotesFrame;
