import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../common/AmbGrid';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import PickupDestination from '../common/icons/PickupDestination';
import PickupDestinationLong from '../common/icons/PickupDestinationLong';
import AmbWarning from '../common/AmbWarning';
import Title from './Title';
import Location from './Location';
import Distance from './Distance/container';
import AmbSwitch from 'components/common/AmbSwitch';
import { theme } from '../../theme';

class PickupDestinationFrame extends React.Component {
  state = {
    expanded: this.props.expanded,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldCollapse) {
      this.setState({ expanded: false });
    }

    if (nextProps.triggerValidation !== this.props.triggerValidation && nextProps.triggerValidation) {
      this.props.validateAll(nextProps);
    }

    this.props.setSaveErrorMessage(nextProps.errors);
  }

  togglePanel = () => {
    if (this.state.expanded) {
      this.props.validateAll(this.props);
    }

    this.setState({ expanded: !this.state.expanded });
  };

  handlePickupLocationChange = (query, downshiftState) => {
    this.props.handlePickupLocationChange(query, downshiftState);
    this.props.validate('selectedPickupLocation', this.props);
  };

  handleDestinationChange = (query, downshiftState) => {
    this.props.handleDestinationChange(query, downshiftState);
    this.props.validate('selectedDestinationLocation', this.props);
  };

  render() {
    const { isERBound, manualPickupSecs, manualDropOffSecs, user } = this.props;

    const pickupErrorStyle = this.props.errors.selectedPickupLocation ? { color: theme.palette.primary.red } : {};
    const destinationErrorStyle = this.props.errors.selectedDestinationLocation
      ? { color: theme.palette.primary.red }
      : {};

    const display = this.props.hideEstTime || user.isRequester ? 'none' : '';

    const icon =
      (this.props.selectedPickupLocation.facility || this.props.selectedDestinationLocation.facility) &&
      this.props.showDestination ? (
        <PickupDestinationLong style={{ height: 62, width: 14, fill: theme.palette.icon.primary, marginTop: 2 }} />
      ) : (
        <PickupDestination style={{ height: 48, width: 14, fill: theme.palette.icon.primary }} />
      );

    return (
      <AmbExpansionPanel
        icon={icon}
        id="PickupDestinationPanel"
        title={
          <Title
            pickupErrorStyle={pickupErrorStyle}
            selectedPickupLocation={this.props.selectedPickupLocation}
            destinationErrorStyle={destinationErrorStyle}
            selectedDestinationLocation={this.props.selectedDestinationLocation}
            erVisibility={isERBound && this.props.selectedDestinationLocation ? 'visible' : 'hidden'}
            location={this.props.location}
            showPickup={this.props.showPickup}
            showDestination={this.props.showDestination}
          />
        }
        expanded={this.state.expanded}
        onTogglePanel={this.togglePanel}
        warnings={this.props.showDestination ? this.props.warningCount : 0}
      >
        <AmbGrid style={{ minWidth: '100%', flexDirection: 'column', marginBottom: '-6px' }}>
          <AmbGrid style={{ flexDirection: 'column', position: 'relative', width: '100%', marginBottom: 6 }}>
            {this.props.showPickup && (
              <Location
                lockedByBillerVerified={this.props.lockedByBillerVerified}
                display={display}
                inputValue={this.props.pickupLocation}
                selectedItem={this.props.selectedPickupLocation}
                helperText={
                  this.props.errors.selectedPickupLocation
                    ? this.props.errors.selectedPickupLocation
                    : this.props.selectedPickupLocation.facility
                }
                onInputValueChange={this.handlePickupLocationChange}
                onInputClick={this.props.handlePickupLocationClick}
                items={this.props.pickupLocations}
                inputId="pickup-location-input"
                inputPlaceholder={this.props.showDestination ? 'Pickup Location' : 'Service Location'}
                loading={this.props.searchPickupLocationInProgress}
                suggestionFieldName="pickupTitle"
                error={!!this.props.errors.selectedPickupLocation}
                propsStyles={{ display }}
                estTimePlaceholder={this.props.showDestination ? 'Est. Time on Scene' : 'Duration'}
                showEstTime={this.props.showDestination}
                onSelectWaitTime={this.props.setManualPickupTimeSecs}
                waitTimeValue={manualPickupSecs || this.props.defaultPickupSec}
                user={this.props.user}
                roomFieldName={'puRoomNumber'}
                residenceHandleChange={checked => this.props.setResidence({ isPickupResidence: checked })}
                isResidence={this.props.isPickupResidence}
                isResidenceDisabled={
                  this.props.disabled ||
                  this.props.pickupResidenceDisabled ||
                  (this.props.lockedByBillerVerified && this.props.lockedByPcr)
                }
                disabled={this.props.disabled || null}
                subLabel={this.props.subLabel}
                departmentOptionsDisabled={this.props.pickupDepartmentsDisabled}
                departmentOptions={this.props.pickupDepartments}
                handleDepartmentChange={this.props.selectPickupDepartment}
                department={this.props.pickupDepartmentId}
              />
            )}
            {this.props.showDestination && (
              <div style={{ marginTop: '-30px' }}>
                <Location
                  lockedByBillerVerified={this.props.lockedByBillerVerified}
                  display={display}
                  inputValue={this.props.destination}
                  selectedItem={this.props.selectedDestinationLocation}
                  helperText={
                    this.props.errors.selectedDestinationLocation
                      ? this.props.errors.selectedDestinationLocation
                      : this.props.selectedDestinationLocation.facility
                  }
                  onInputValueChange={this.handleDestinationChange}
                  onInputClick={this.props.handleDestinationClick}
                  items={this.props.destinations}
                  inputId="destination-input"
                  inputPlaceholder="Destination"
                  autoFocus={this.props.destinationAutoFocus}
                  loading={this.props.searchDestinationInProgress}
                  suggestionFieldName="destinationTitle"
                  error={!!this.props.errors.selectedDestinationLocation}
                  propsStyles={{ display }}
                  estTimePlaceholder="Est. Time at Dest."
                  showEstTime={true}
                  onSelectWaitTime={this.props.setManualDropoffTimeSecs}
                  waitTimeValue={manualDropOffSecs || this.props.defaultDropoffSec}
                  user={this.props.user}
                  roomFieldName="doRoomNumber"
                  residenceHandleChange={checked => this.props.setResidence({ isDestinationResidence: checked })}
                  isResidence={this.props.isDestinationResidence}
                  isResidenceDisabled={
                    this.props.disabled ||
                    this.props.destinationResidenceDisabled ||
                    (this.props.lockedByBillerVerified && this.props.lockedByPcr)
                  }
                  disabled={this.props.disabled || null}
                  subLabel={this.props.subLabel}
                  departmentOptionsDisabled={this.props.destinationDepartmentsDisabled}
                  departmentOptions={this.props.destinationDepartments}
                  handleDepartmentChange={this.props.selectDestinationDepartment}
                  department={this.props.destinationDepartmentId}
                />
              </div>
            )}
            {this.props.showDestination && (
              <div style={{ position: 'absolute', right: 100, top: 186 }}>
                <AmbSwitch
                  onChange={this.props.handleToggleERBound}
                  checked={isERBound}
                  disabled={this.props.disabled}
                  id="ERBoundSwitch"
                  avatar="ER"
                />
              </div>
            )}
          </AmbGrid>
          {this.props.showDestination && this.props.showWarning && <AmbWarning messages={this.props.warnings} />}
          {this.props.showDestination && <Distance />}
        </AmbGrid>
      </AmbExpansionPanel>
    );
  }
}

PickupDestinationFrame.propTypes = {
  pickupLocation: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  destination: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  handlePickupLocationChange: PropTypes.func.isRequired,
  handlePickupLocationClick: PropTypes.func.isRequired,
  handleDestinationChange: PropTypes.func.isRequired,
  handleDestinationClick: PropTypes.func.isRequired,
  handleToggleERBound: PropTypes.func.isRequired,
  shouldCollapse: PropTypes.bool.isRequired,
  isERBound: PropTypes.bool,
  selectedPickupLocation: PropTypes.shape().isRequired,
  selectedDestinationLocation: PropTypes.shape().isRequired,
  setManualPickupTimeSecs: PropTypes.func.isRequired,
  setManualDropoffTimeSecs: PropTypes.func.isRequired,
  searchPickupLocationInProgress: PropTypes.bool.isRequired,
  searchDestinationInProgress: PropTypes.bool.isRequired,
  hideEstTime: PropTypes.bool,
  errors: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  validateAll: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  setSaveErrorMessage: PropTypes.func.isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  destinationAutoFocus: PropTypes.bool.isRequired,
  manualPickupSecs: PropTypes.string.isRequired,
  manualDropOffSecs: PropTypes.string.isRequired,
  defaultDropoffSec: PropTypes.string.isRequired,
  defaultPickupSec: PropTypes.string.isRequired,
  setResidence: PropTypes.func.isRequired,
  isPickupResidence: PropTypes.bool.isRequired,
  isDestinationResidence: PropTypes.bool.isRequired,
  pickupResidenceDisabled: PropTypes.bool.isRequired,
  destinationResidenceDisabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  lockedByBillerVerified: PropTypes.bool.isRequired,
  lockedByPcr: PropTypes.bool.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
  showWarning: PropTypes.bool.isRequired,
  warningCount: PropTypes.number.isRequired,
};

PickupDestinationFrame.defaultProps = {
  hideEstTime: false,
  isERBound: false,
};

export default PickupDestinationFrame;
