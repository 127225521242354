import React from 'react';
import Alarm from '../common/icons/alarm';
import styled from '@emotion/styled';
import ContactPhone from '../common/icons/contactPhone';
import ReportProblem from '../common/icons/reportProblem';
import SummaryItem from './SummaryItem';
import AppointmentSummary from './AppointmentSummary';
import OfferSummary from './OfferSummary';

const ListItem = styled.li`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 12px 16px;
`;

const AmbulnzSummary = ({ ...props }) => {
  const {
    appointmentDate,
    appointmentTime,
    dropoffDate,
    dropoffTime,
    etaDate,
    etaTime,
    isNonTransport,
    offer,
    pickupTime,
    request,
    scheduleState,
    scheduledMessage,
    showStatusItem,
    displayWindowEndAt,
    displayWindowStartAt,
    displayWindowDay,
    phone,
  } = props;

  const appmtSummary = appointmentTime && (
    <AppointmentSummary
      {...props}
      etaOnly
      etaTime={etaTime}
      etaDate={etaDate}
      dropoffTime={dropoffTime}
      dropoffDate={dropoffDate}
      appointmentDate={appointmentDate}
    />
  );

  const etaMessage = `ETA ${etaTime}`;
  const startTimeMessage = `Pickup Time ${pickupTime}`;
  const arrivalWindowMessage = `${displayWindowDay}, ${displayWindowStartAt} - ${displayWindowEndAt}`;

  return (
    <>
      {request.isScheduled && (
        <SummaryItem
          title={scheduledMessage}
          message={`${
            window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && isNonTransport ? arrivalWindowMessage : etaMessage
          }`}
          Icon={Alarm}
        >
          {appmtSummary}
        </SummaryItem>
      )}
      {offer && <OfferSummary offer={offer} request={request} phone={phone} />}
      {request.inProgress && (
        <SummaryItem
          title={scheduleState}
          message={`${
            window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && isNonTransport
              ? arrivalWindowMessage
              : startTimeMessage
          }`}
          Icon={Alarm}
        >
          {appmtSummary}
        </SummaryItem>
      )}

      {request.isCompleted && (
        <SummaryItem
          title={`Trip ${request.statusName}`}
          message={`${
            window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && isNonTransport
              ? arrivalWindowMessage
              : startTimeMessage
          }`}
          Icon={Alarm}
        >
          {appmtSummary}
        </SummaryItem>
      )}

      {request.isWillCallStatus && (
        <ListItem>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <ContactPhone style={{ fill: '#21C0D9', display: 'inline', verticalAlign: 'middle', width: 24 }} />
            <h2
              style={{ textAlign: 'center', display: 'inline', verticalAlign: 'middle', marginLeft: 10 }}
            >{`Trip Saved as ${request.statusName}`}</h2>
          </div>
        </ListItem>
      )}

      {request.isTurndown && (
        <ListItem>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <ReportProblem style={{ fill: '#21C0D9', display: 'inline', verticalAlign: 'middle', width: 24 }} />
            <h2 style={{ textAlign: 'center', display: 'inline', verticalAlign: 'middle', marginLeft: 10 }}>
              {`Trip Saved as ${request.statusName}`}
            </h2>
          </div>
        </ListItem>
      )}

      {showStatusItem && (
        <ListItem>
          <div
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            <ReportProblem style={{ fill: '#21C0D9', display: 'inline', verticalAlign: 'middle', width: 24 }} />
            <h2 style={{ textAlign: 'center', display: 'inline', verticalAlign: 'middle', marginLeft: 10 }}>
              {`Trip ${request.statusName}`}
            </h2>
          </div>
        </ListItem>
      )}
    </>
  );
};

export default AmbulnzSummary;
