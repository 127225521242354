import React from 'react';
import styled from '@emotion/styled';
import BLS from '../common/icons/vehicles/BLS';
import ALS from '../common/icons/vehicles/ALS';
import CCT from '../common/icons/vehicles/CCT';
import WAV from '../common/icons/vehicles/WAV';
import Livery from '../common/icons/vehicles/livery';
import PEDSCCT from '../common/icons/vehicles/PEDSCCT';
import PEDSALS from '../common/icons/vehicles/PEDSALS';
import NICUBLS from '../common/icons/vehicles/NICUBLS';
import Uber from '../common/icons/vehicles/Uber';
import { timeFormat } from '../../helpers/dateFormatter';

const Divider = styled.div`
  width: 1px;
  height: 38px;
  align-self: center;
  opacity: 0.25;
  background-color: #899ba6;
`;

const StatusDivider = styled.div`
  width: 1px;
  height: 16px;
  position: absolute;
  opacity: 0.5;
  background-color: #899ba6;
  bottom: 11px;
  right: 0;
`;

const Details = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Times = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px 5px 0 5px;
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: -4px;
`;

const StatusLine = styled.div`
  position: relative;
  margin-top: 27px;
`;

const Time = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  color: #004053;
  display: inline;
  margin: 0;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: normal;
  color: #899ba6;
`;

const VehicleContainer = styled.div`
  height: 25px;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 16px;
`;

const BLSv = styled(BLS)`
  && {
    width: 52px;
    height: 24px;
  }
`;

const ALSv = styled(ALS)`
  && {
    width: 52px;
    height: 24px;
  }
`;

const CCTv = styled(CCT)`
  && {
    width: 52px;
    height: 24px;
  }
`;

const WAVv = styled(WAV)`
  && {
    width: 52px;
    height: 24px;
  }
`;

const PEDSCCTv = styled(PEDSCCT)`
  && {
    width: 52px;
    height: 24px;
  }
`;

const PEDSALSv = styled(PEDSALS)`
  && {
    width: 52px;
    height: 24px;
  }
`;

const NICUBLSv = styled(NICUBLS)`
  && {
    width: 52px;
    height: 24px;
  }
`;

const Uberv = styled(Uber)`
  && {
    width: 56px;
    height: 20px;
  }
`;

const Liveryv = styled(Livery)`
  && {
    width: 56px;
    height: 20px;
  }
`;

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  margin-top: 16px;
  margin-left: 16px;
  height: 92px;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0 0 8px 0 rgba(34, 75, 82, 0.35);
  overflow: hidden;
  background-image: url(timeline.svg);
  background-size: auto 66px;
  background-position-y: 9px;
  background-repeat: repeat-x;
`;

export const Segment = styled.div`
  height: 92px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

export const Status = styled(({ active, future, type, ...props }) => {
  const color = future ? '#8edae6' : '#d7dfe4';
  return (
    <StatusLine aria-current={active}>
      <StatusDivider />
      <p style={{ backgroundColor: active ? type : color }} {...props} />
    </StatusLine>
  );
})`
  && {
    font-size: 11px;
    font-weight: bold;
    line-height: 1.64;
    text-align: center;
    color: #ffffff;
    width: 121px;
    height: 16px;
  }
`;

export const Vehicle = ({ type, active }) => {
  const ariaLabel = active ? `${type}-icon` : 'test';
  const vehicleByType = () => {
    switch (type) {
      case 'BLS':
        return <BLSv />;
      case 'ALS':
        return <ALSv />;
      case 'CCT':
        return <CCTv />;
      case 'WAV':
        return <WAVv />;
      case 'Livery':
        return <Liveryv />;
      case 'PEDS CCT':
        return <PEDSCCTv />;
      case 'PEDS ALS':
        return <PEDSALSv />;
      case 'NICU BLS':
        return <NICUBLSv />;
      case 'Curbside':
        return <Uberv />;
      default:
        return <BLSv />;
    }
  };
  return (
    <VehicleContainer active={active} serviceType={type} aria-label={ariaLabel}>
      {active && vehicleByType(type)}
    </VehicleContainer>
  );
};

export const TimesDetails = ({ times }) => (
  <Details>
    <Divider />
    <Times>
      {times &&
        times.map(d => (
          <Line key={d.label}>
            <Label>{d.label}</Label>
            <Time aria-labelledby={d.label}>{timeFormat(d.value)}</Time>
          </Line>
        ))}
    </Times>
  </Details>
);
