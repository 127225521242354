import ApiService from './api.service';

export default class TransportReasonService {
  static async get(transportReasonId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/TransportReasons/${transportReasonId}`;
    const response = await ApiService.get(url);

    return response.json();
  }
}
