import React from 'react';

export default function (props) {
  return (
    <svg
      viewBox="0 0 11 14"
      style={{ enableBackground: 'new 0 0 11 14', width: 24, height: 24, ...props.style }}
      {...props}
    >
      <path d="M5.6,14h-3C1.2,14,0,12.8,0,11.4v-6c0-1.4,1.2-2.6,2.6-2.6h3c1.4,0,2.6,1.2,2.6,2.6v6C8.3,12.8,7.1,14,5.6,14z M2.6,4C1.9,4,1.3,4.6,1.3,5.4v6c0,0.8,0.6,1.4,1.4,1.4h3c0.8,0,1.4-0.6,1.4-1.4v-6C7,4.6,6.4,4,5.6,4H2.6z" />
      <path d="M8.4,0h-3C4.4,0,3.6,0.5,3.1,1.3h5.2c0.8,0,1.4,0.6,1.4,1.4v8.2c0.7-0.5,1.3-1.3,1.3-2.2v-6C11,1.2,9.8,0,8.4,0z" />
    </svg>
  );
}
