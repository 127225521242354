import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import EligibilityClose from './EligibilityClose';
import EligibilityIframe from './EligibilityIframe';
import PortalInfo from './PortalInfo';
import { theme } from '../../theme';

class EligibilityFrame extends React.Component {
  handleCloseErrorModal() {
    this.props.closeErrorModal();
  }

  render() {
    const top = this.props.portalInfo ? 247 : 47;

    return (
      <div>
        <EligibilityClose isLoading={this.props.isLoading} />
        <PortalInfo isLoading={this.props.isLoading} />
        <EligibilityIframe top={top} />
        <Dialog
          open={!!this.props.errorMessage}
          onClose={() => this.handleCloseErrorModal()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Eligibility Check Failed</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ color: theme.palette.primary.dark }}>
              {this.props.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleCloseErrorModal()}
              color="primary"
              autoFocus
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(30, 192, 217, 0.1)',
                },
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EligibilityFrame.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  closeErrorModal: PropTypes.func.isRequired,
  portalInfo: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EligibilityFrame;
