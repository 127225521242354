import ApiService from './api.service';

const notificationServiceUrl = () => window.ambulnzConfig.tenantedNotificationServiceUrl || '';

async function createSubscription(requestId, recipient) {
  const url = `${notificationServiceUrl()}/subscriptions`;
  const response = await ApiService.post(url, {
    contact: recipient.contact,
    contact_type: recipient.contact_type,
    resource_type: 'Request',
    resource_id: requestId,
  });
  return response.json();
}

export async function getSubscriptions(requestId) {
  return [];

  // const url = `${notificationServiceUrl()}/subscriptions?resource_id=${requestId}&resource_type=Request`;
  // const response = await ApiService.get(url);
  // return response.json();
}

export async function deleteSubscription(id) {
  const url = `${notificationServiceUrl()}/subscriptions/${id}`;
  const response = await ApiService.delete(url);
  return response.json();
}

export async function createSubscriptions(requestId, recipients) {
  return Promise.all(recipients.map(async r => createSubscription(requestId, r)));
}
