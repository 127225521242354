import * as pickupTimeChangeActions from '../actions/pickupTimeChange.actions';
import * as etaActions from '../actions/eta.actions';
import * as formFlowActions from '../actions/formFlow.actions';

export const pickupTimeChangeConfirmMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (pickupTimeChangeActions.confirmPickupTimeChangeDialog.match(action)) {
      dispatch(pickupTimeChangeActions.closePickupTimeChangeDialog());
      dispatch(etaActions.acceptETA(getState().pickupTimeChange.etaId));
      dispatch(formFlowActions.setAcceptETAInProgress(true));
    }
  };

export const pickupTimeChangeMdl = [pickupTimeChangeConfirmMdl];
