import '@ambulnz/amb-autocomplete';
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import AmbExpansionPanel from 'components/common/AmbExpansionPanel';
import AmbToggleButton from 'components/common/AmbToggleButton';
import MedicalServiceIcon from 'components/common/icons/MedicalService';
import AmbChip from '../common/AmbChip';
import ChipInput from '../common/ChipInput';
import ConfirmationDialog from '../common/ConfirmationDialog';
import AmbInputLabel from '../common/AmbInputLabel';
import AmbGrid from 'components/common/AmbGrid';
import * as Enums from 'enums';
import { theme } from '../../theme';
import { buildServiceChip } from './helper/helper';

export const AmbContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 8px;
`;

const serviceChipRenderer = ({ text, isFocused, isDisabled, handleClick, handleDelete, style }, key) => (
  <AmbChip
    key={key}
    onClick={handleClick}
    onDelete={handleDelete}
    style={style}
    isFocused={isFocused}
    isDisabled={isDisabled}
    text={text}
    value={text}
  />
);

const filterMedicalServiceList = serviceList => serviceList.filter(t => !t.inactive).map(t => buildServiceChip(t));

const MedicalService = props => {
  const [expanded, setExpanded] = useState(Boolean(props.expanded));
  const [medicalServiceList, setMedicalServiceList] = useState([]);
  const [services, setServices] = useState(props.services);
  const dataSourceConfig = { text: 'name', value: 'id' };
  const { candidatesForDisabledEquipments, telehealthEquipmentId, handleEquipmentChange, therapistEquipmentIds } =
    props;

  const setService = (props, setServices) => {
    if (!props.transportReasons && props.callTypeId) {
      const serviceByCallType = props.medicalServiceList.find(s => s.id === props.callTypeId);
      setServices(serviceByCallType ? [buildServiceChip(serviceByCallType)] : []);
    } else if (props.transportReasons) {
      const trs = [];
      props.transportReasons.forEach(tr => {
        const transportReason = props.medicalServiceList.find(s => s.id === tr.transportReasonId);
        if (transportReason) trs.push(buildServiceChip(transportReason));
      });
      setServices(trs);
    }
  };

  useEffect(() => {
    const result = filterMedicalServiceList(props.medicalServiceList);
    setMedicalServiceList(result);
    setService(props, setServices);
  }, [props.medicalServiceList, props.services]);

  useEffect(() => {
    setService(props, setServices);
  }, [props, props.transportReasons, props.callTypeId]);

  useEffect(() => {
    if (props.shouldCollapse) setExpanded(false);
  }, [props.shouldCollapse]);

  const togglePanel = () => {
    if (expanded) {
      props.validateAll(props);
    }
    setExpanded(!expanded);
  };

  const handleAddOptionChange = v => {
    if (v && v.id) {
      services.push(v);
      setServices(services);
      props.handleChange(services, v.id, true);
    }
  };

  const handleDeleteOptionChange = v => {
    if (v) {
      const index = services.indexOf(services.find(service => service.id === v));
      if (index !== -1) {
        services.splice(index, 1);
        setServices(services);
        props.handleChange(services, v, false);
      }
    }
  };

  return (
    <AmbExpansionPanel
      icon={<MedicalServiceIcon style={{ height: 18, width: 18, marginTop: 3, fill: theme.palette.icon.primary }} />}
      title={props.title}
      expanded={expanded}
      onTogglePanel={togglePanel}
      error={!!props.errors.selectedMedicalService && props.callTypeId === Enums.CallType.DEFAULT}
      id="RequestedServicePanel"
    >
      <AmbContainer style={{ marginTop: 15 }}>
        <>
          <div
            style={{
              paddingTop: 5,
              width: '100%',
              margin: 0,
              border: 0,
              display: 'inline-flex',
              padding: 0,
              minWidth: 0,
              flexDirection: 'column',
            }}
          >
            <AmbInputLabel htmlFor="medicalServiceSelect" shrink required>
              Requested Service
            </AmbInputLabel>
            {medicalServiceList.length && (
              <ChipInput
                id="medicalServiceSelect"
                blurBehavior="add"
                chipRenderer={serviceChipRenderer}
                newChipKeyCodes={[13, 9]}
                onAdd={handleAddOptionChange}
                onDelete={handleDeleteOptionChange}
                disabled={props.serviceSelectDisabled}
                disableUnderline
                value={services}
                options={medicalServiceList}
                dataSourceConfig={dataSourceConfig}
                error={props.hasErrors}
                FormHelperTextProps={{ style: { transform: `translateY(-10px)` } }}
                helperText={props.errors.selectedMedicalService}
              />
            )}
          </div>
        </>
        <AmbGrid style={{ flexWrap: 'wrap', gap: 6, margin: '-4px 0 5px' }}>
          {props.equipment.map(e => {
            let isDisabled;
            if (props.equipmentSelectDisabled) {
              isDisabled = true;
            } else if (props.formFlowMode === 'edit' && e.id === telehealthEquipmentId) {
              isDisabled = true;
            } else if (candidatesForDisabledEquipments && candidatesForDisabledEquipments.includes(e.id)) {
              isDisabled = true;
            } else isDisabled = false;

            return (
              <AmbToggleButton
                label={e.label}
                checked={therapistEquipmentIds && therapistEquipmentIds.includes(e.id)}
                onChange={on => handleEquipmentChange(e.id, on)}
                key={e.id}
                disabled={isDisabled}
                tooltip={e.sublabel}
              />
            );
          })}
        </AmbGrid>
      </AmbContainer>
      <ConfirmationDialog
        open={props.ETADialogOpen}
        onClose={props.customCloseNewETADialog}
        title={props.ETADialogTitle}
        cancel
      >
        <div>{props.ETADialogBody}</div>
      </ConfirmationDialog>
    </AmbExpansionPanel>
  );
};

MedicalService.propTypes = {
  callTypeId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  serviceSelectDisabled: PropTypes.bool,
  shouldCollapse: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired,
    PropTypes.oneOf([undefined]).isRequired,
  ]),
  medicalServiceList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  errors: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  validateAll: PropTypes.func.isRequired,
};

export default MedicalService;
