import schema from 'schm';
import computed from 'schm-computed';

export default schema(
  {
    make: String,
    model: String,
    vehicle_color: String,
    license_plate: String,
  },
  computed({
    details: values =>
      Object.values(values)
        .filter(v => v)
        .join(' - '),
  }),
);
