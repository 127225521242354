import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 56 20.2" {...props}>
      <path
        style={{ fill: '#151819' }}
        d="M55.5,10.1c-1.6-2.9-9.4-3.5-11-4c-0.4-0.1-1.2-0.7-2.2-1.2c-4.1-2-10-4.7-18.6-4.7c-6.1,0-10.4,1.3-14.1,2.7
	c-2.7,1-7.9,1.6-8.2,2.4C1.3,5.5,1.3,6,1.7,6.4c0,0.1-0.1,0.2-0.1,0.3C1.5,6.9,1,7.2,1,7.8c0,0.6,0.1,2.4,0,3c0,0.2-1,0.6-1,1.7v1.4
	c0,1.9,1.6,3.3,3.5,3.3h4.8H11h43.2c1,0,1.8-0.2,1.8-1.8C56,15.1,56.2,11.3,55.5,10.1z"
      />
      <path
        style={{ fill: '#DCD9DC' }}
        d="M12.6,4.9L11.1,4c3.4-1.4,8.1-2.1,13.8-2C30.3,2.1,37.5,4,41.4,6.2L12.6,4.9z"
      />
      <path
        style={{ fill: '#151819' }}
        d="M24.8,1.8l0.7,3.7l2.6,0.1L27.4,2c-0.9-0.1-1.7-0.1-2.5-0.2C24.9,1.8,24.8,1.8,24.8,1.8z"
      />
      <path style={{ fill: '#505759' }} d="M0.4,15.4c0.6,1.1,1.8,1.8,3.1,1.8h4.8H11h43.2c1,0,1.8-0.2,1.8-1.8H0.4z" />
      <path
        style={{ fill: '#505759' }}
        d="M16,17.2c0.1-0.3,0.1-0.6,0.1-1c0-2.5-2-4.5-4.5-4.5c-2.5,0-4.5,2-4.5,4.5c0,0.3,0,0.6,0.1,1h1.1H11H16z"
      />
      <circle style={{ fill: '#181919' }} cx="11.6" cy="16.2" r="4" />
      <circle style={{ fill: '#FFFFFF' }} cx="11.6" cy="16.2" r="2.2" />
      <path
        style={{ fill: '#505759' }}
        d="M44.5,11.7c-2.5,0-4.5,2-4.5,4.5c0,0.3,0,0.6,0.1,1h8.7c0.1-0.3,0.1-0.6,0.1-1C49,13.7,47,11.7,44.5,11.7z"
      />
      <circle style={{ fill: '#181919' }} cx="44.5" cy="16.2" r="4" />
      <circle style={{ fill: '#FFFFFF' }} cx="44.5" cy="16.2" r="2.2" />
      <g id="Group">
        <path
          id="Fill-1"
          style={{ fill: '#FFFFFF' }}
          d="M18.6,7.5v3c0,1-0.4,1.4-1.5,1.4c-1,0-1.5-0.4-1.5-1.4V7.3h-1c-0.1,0-0.1,0-0.1,0.1v3.1
		c0,1.7,1.1,2.3,2.6,2.3c1.6,0,2.6-0.6,2.6-2.3V7.3h-1C18.6,7.3,18.6,7.4,18.6,7.5"
        />
        <path
          id="Fill-2"
          style={{ fill: '#FFFFFF' }}
          d="M33.1,8.2c0.1,0,0.1,0,0.1-0.1l0.3-0.7c0,0,0-0.1,0-0.1h-4.1c-0.4,0-0.5,0.1-0.5,0.4v4.7
		c0,0.2,0.1,0.3,0.4,0.3h3.8c0.1,0,0.1,0,0.1-0.1l0.3-0.7c0,0,0-0.1,0-0.1h-3.5v-1c0-0.3,0.2-0.5,0.7-0.5h1.5c0.1,0,0.1,0,0.1-0.1
		l0.3-0.7c0,0,0-0.1,0-0.1h-2.6V8.2H33.1"
        />
        <path
          id="Fill-3"
          style={{ fill: '#FFFFFF' }}
          d="M24.9,11.9h-2v-1c0-0.3,0.2-0.5,0.7-0.5h1.3c0.6,0,0.8,0.2,0.8,0.7C25.8,11.6,25.6,11.9,24.9,11.9
		z M23,8.2h1.8c0.6,0,0.8,0.2,0.8,0.7c0,0.5-0.2,0.7-0.8,0.7H23V8.2z M26,9.9c0.4-0.2,0.6-0.7,0.6-1.2c0-1.2-1-1.4-2-1.4h-2.3
		c-0.4,0-0.5,0.1-0.5,0.4v4.7c0,0.2,0.1,0.3,0.4,0.3h2.8c1.1,0,1.8-0.4,1.8-1.5C26.9,10.6,26.6,10,26,9.9L26,9.9z"
        />
        <path
          id="Fill-4"
          style={{ fill: '#FFFFFF' }}
          d="M38.5,9.8h-1.9V8.2h1.9c0.7,0,0.9,0.3,0.9,0.8C39.4,9.6,39.2,9.8,38.5,9.8z M40.6,12.6l-1.2-2.1
		C40,10.4,40.5,10,40.5,9c0-1.3-0.8-1.7-2.1-1.7H36c-0.4,0-0.5,0.1-0.5,0.4v4.9c0,0.1,0,0.1,0.1,0.1h1v-1.6c0-0.3,0.2-0.5,0.7-0.5h1
		l1.1,2c0,0.1,0.1,0.1,0.1,0.1L40.6,12.6C40.6,12.7,40.6,12.7,40.6,12.6L40.6,12.6z"
        />
      </g>
    </svg>
  );
}
