import { isCar } from './service.flag';
import { POLL_IN_PROGRESS_REASONS } from '../enums';

const bottomSaveButtonCollapse = state => state.formFlow.submitInProgress && !state.eta.showEtaProgress;

export const requestingAgencyExpanded = state =>
  state.formFlow.loadedRequest
    ? false
    : state.user.model.isDispatcher || (!state.user.model.isDispatcher && !state.agency.selectedAgency);

export const requestingAgencyCollapse = state => state.eta.showEtaProgress;

export const pickupDestinationExpanded = state => !state.formFlow.loadedRequest;

export const pickupDestinationCollapse = state => state.eta.showEtaProgress;

export const transportReasonCollapse = state => state.eta.showEtaProgress || state.requests.request.id;

export const serviceLevelCollapse = state => state.eta.showEtaProgress;

export const serviceLevelExpanded = state =>
  !state.requests.request.id || state.medicalNecessity.expandServiceLevelSection;

export const waitAndReturnCollapse = state => state.eta.showEtaProgress;

export const timeDateCollapse = state => state.eta.showEtaProgress;

export const timeDateExpand = state =>
  state.requests.loadedWillCall || state.eta.pollInProgressReason === POLL_IN_PROGRESS_REASONS.APPMT_CANNOT_BE_MET;

export const providersCollapse = state => state.eta.showEtaProgress;

export const patientDetailsExpanded = state =>
  isCar(state.service) && state.missingFieldsModal.hasErrors && !!state.requests.request.id;

export const coverageExpanded = state =>
  (state.formFlow.loadedRequest &&
    state.user.model.isDispatcher &&
    !state.requests.loadedWillCall &&
    !state.requests.isBillerVerified) ||
  state.medicalNecessity.expandPayer;

export const patientInfoCollapse = state => bottomSaveButtonCollapse(state);

export const chiefComplaintCollapse = state => bottomSaveButtonCollapse(state);

export const notesCollapse = state => bottomSaveButtonCollapse(state);

export const requestShareCollapse = state => bottomSaveButtonCollapse(state);
