import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import SelectArrow from 'components/common/icons/SelectArrow';
import styled from '@emotion/styled';

const Item = styled.div`
  margin-right: 10px;
  position: relative;
  width: 100%;
  color: #1ec0d9;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.45);
  &:focus-within {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
  }
`;

const Label = styled.label`
  font-size: 11px;
  color: #004053;
`;

const Select = styled.select`
  width: 50%;
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  outline: none;
  border: 0;
  margin: 0;
  display: block;
  font: inherit;
  box-sizing: content-box;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #000000;
  opacity: 0.87;
  background-color: transparent;
  padding: 0 20px 5px 0;
  z-index: 10;
  position: relative;
`;

const WaitTime = ({ disabledClass, disabled, hour, handleHourChange, min, handleMinChange }) => (
  <div style={{ padding: 5 }}>
    <Label htmlFor="min" className={disabledClass}>
      Appointment Duration
    </Label>
    <AmbGrid>
      <Item style={{ borderBottom: disabled ? '0.5px dotted #6C7980' : '' }}>
        <Select disabled={disabled || null} value={hour} onChange={handleHourChange} id="hour-select">
          <option value="0" />
          {[1, 2, 3, 4, 5, 6].map(val => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </Select>
        <SelectArrow style={{ position: 'absolute', top: 0, right: 0 }} />
      </Item>
      <Item style={{ borderBottom: disabled ? '0.5px dotted #6C7980' : '' }}>
        <Select disabled={disabled || null} value={min} onChange={handleMinChange} id="min">
          <option value="" />
          <option value={0}>00</option>
          {[10, 20, 30, 40, 50].map(val => (
            <option key={val} value={val}>
              {val}
            </option>
          ))}
        </Select>
        <SelectArrow style={{ position: 'absolute', top: 0, right: 0 }} />
      </Item>
    </AmbGrid>
  </div>
);

WaitTime.propTypes = {
  disabledClass: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleHourChange: PropTypes.func.isRequired,
  handleMinChange: PropTypes.func.isRequired,
  hour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default WaitTime;
