import { getStatusMap, nonTransportStatus } from '../Timeline/Timeline.parser';

export const unitSummary = ({ offers, schedule }) => {
  if (!schedule) {
    return 'No Unit Assigned';
  }
  let unitId;
  const offerUnitId = offers.offers.length && offers.offers[0].trip && offers.offers[0].trip.unitId;
  const requestUnitId = schedule.unit;
  unitId = offerUnitId ? offerUnitId : requestUnitId;

  return unitId ? `Unit ${unitId}` : 'No Unit Assigned';
};

export const presentScheduleState = (scheduleState, requestStatus, service, isNonTransport) => {
  if (!scheduleState) {
    return requestStatus;
  }

  const map = {
    all_clear: 'Completed',
    reserved: 'Scheduled',
    cancelled: 'Cancelled',
  };
  const mapping = map[scheduleState];
  if (mapping) {
    return mapping;
  }

  if (isNonTransport) {
    return nonTransportStatus[scheduleState];
  }

  const statusMap = getStatusMap(service);
  return statusMap[scheduleState];
};
