import { pipe, of as of$, from as from$ } from 'rxjs';
import { catchError, distinctUntilChanged, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { captureException } from '../../logger';

export const autoCompletePipe = pipe(
  // tap(action => console.log(`BEFORE: ${action.payload}`)),
  filter(action => action.payload && action.payload.length > 2),
  debounceTime(200),
  distinctUntilChanged((p, q) => p.payload === q.payload),
  // tap(action => console.log(`AFTER: ${action.payload}`)),
);

export const autoCompletePipeDynamic = pipe(
  // tap(data => console.log('before', data.payload)),
  filter(action => action.payload.query && action.payload.query.length > 2),
  debounceTime(200),
  distinctUntilChanged((p, q) => p.payload.query === q.payload.query),
  // tap(data => console.log('after', data.payload)),
);

export const errorPipe = pipe(
  catchError(error => {
    const err = { error };
    captureException(err);
    return of$({
      type: 'ERROR',
      payload: err,
    });
  }),
);

export const ajaxToAction = (serviceFn, actionFn) =>
  switchMap(action =>
    from$(serviceFn(action.payload)).pipe(
      map(payload => actionFn(payload)),
      errorPipe,
    ),
  );
