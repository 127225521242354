import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

function MaskedSsnInput(props) {
  const { inputRef, onFocus, ...other } = props;
  const handleFocus = e => {
    onFocus(e);
    e.stopPropagation();
    e.target.setSelectionRange(0, -1);
  };

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      onFocus={handleFocus}
    />
  );
}

MaskedSsnInput.propTypes = {
  inputRef: PropTypes.func,
};

export default MaskedSsnInput;
