import React from 'react';
import PropTypes from 'prop-types';
import AmbExpansionPanel from '../../common/AmbExpansionPanel';
import PatientDetails from '../PatientDetailsWidget';
import Demographics from '../../common/icons/Demographics';
import { theme } from 'theme';

class PatientInfoFrame extends React.Component {
  state = {
    value: true,
    expanded: this.props.expanded,
    detailsError: false,
  };

  togglePanel = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleError = name => value => {
    this.setState({
      [name]: value,
    });
  };

  notValid = state => state.detailsError || state.additionalInfoError;

  UNSAFE_componentWillReceiveProps({ shouldCollapse, expanded }) {
    if (shouldCollapse) {
      this.setState({ expanded: false });
    }
    if (expanded && this.state.expanded !== expanded) {
      this.setState({ expanded });
    }
  }

  render() {
    return (
      <AmbExpansionPanel
        icon={<Demographics style={{ height: 17, width: 17, marginTop: 2, fill: theme.palette.icon.primary }} />}
        title={this.props.title}
        expanded={this.state.expanded}
        onTogglePanel={this.togglePanel}
        id="PatientDetailsPanel"
        error={this.notValid(this.state)}
      >
        <div style={{ width: '100%', paddingTop: 25 }}>
          <PatientDetails
            handleError={this.handleError}
            shouldValidateAll={!this.state.expanded}
            triggerValidation={this.props.triggerValidation}
            disabled={this.props.disabled || null}
          />
        </div>
      </AmbExpansionPanel>
    );
  }
}

PatientInfoFrame.propTypes = {
  shouldCollapse: PropTypes.bool.isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  expanded: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

PatientInfoFrame.defaultProps = {
  expanded: true,
};

export default PatientInfoFrame;
