export const isPickupValid = ({ location }) =>
  location.fromSelected.facility ||
  (location.fromSelected.name && location.fromSelected.postalCode) ||
  (location.placeTypeEnum &&
    location.fromSelected.typeId === location.placeTypeEnum.geopoint &&
    location.fromSelected.facilityFull.locationGps);

export const isDestinationValid = ({ location }) =>
  location.toSelected.facility ||
  (location.toSelected.name && location.toSelected.postalCode) ||
  (location.placeTypeEnum &&
    location.toSelected.typeId === location.placeTypeEnum.geopoint &&
    location.toSelected.facilityFull.locationGps);

export const isLocationValid = state =>
  !!(isPickupValid(state) && isDestinationValid(state) && state.agency.requesterAgencyId);
