import { from as from$ } from 'rxjs';
import AgencyService from '../../../services/agency.service';
import ExternalRequestService from 'services/externalRequest.service';

export const getAgencies = payload => from$(AgencyService.getAgencies(payload));

export const getCommonRequesterAgencyGroup = user => {
  const requesterAgencyGroupIds = user.model.facilities
    .map(facility => facility.requesterAgency)
    .filter(Boolean)
    .map(requesterAgency => requesterAgency.requesterAgencyGroupId);

  return requesterAgencyGroupIds.reduce((acc, id) => acc && requesterAgencyGroupIds[0] === id, true)
    ? requesterAgencyGroupIds[0]
    : null;
};

export const getAgencyTransportReasons = payload => from$(AgencyService.getTransportReasons(payload));

export const getExternalRequestData = agencyGroup =>
  from$(ExternalRequestService.getExternalRequestData(agencyGroup.id));
