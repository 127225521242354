import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 14 62" {...props}>
      <path
        style={{ fill: '#014053' }}
        d="M7,55a2.5,2.5,0,1,1-2.5,2.5A2.5,2.5,0,0,1,7,55m0-2a4.5,4.5,0,1,0,4.5,4.5A4.51,4.51,0,0,0,7,53Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ffffff' }}
        d="M7,54a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,7,54Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#bbf3fc' }}
        d="M7,60a2.5,2.5,0,1,1,2.5-2.5A2.49,2.49,0,0,1,7,60Z"
        transform="translate(0 0)"
      />
      <rect style={{ fill: '#d7dee4' }} x="6" y="21" width="2" height="5" rx="1" ry="1" />
      <rect style={{ fill: '#d7dee4' }} x="6" y="29" width="2" height="5" rx="1" ry="1" />
      <rect style={{ fill: '#d7dee4' }} x="6" y="37" width="2" height="5" rx="1" ry="1" />
      <rect style={{ fill: '#d7dee4' }} x="6" y="45" width="2" height="5" rx="1" ry="1" />
      <path
        style={{ fill: '#014053' }}
        d="M7,2a5,5,0,0,1,5,5c0,2.76-5,8.14-5,8.14S2,9.76,2,7A5,5,0,0,1,7,2M7,0A7,7,0,0,0,0,7c0,3.32,4.27,8.21,5.57,9.54L7,18l1.43-1.46C9.73,15.21,14,10.32,14,7A7,7,0,0,0,7,0Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ffffff' }}
        d="M7,15.14,7,1A6,6,0,0,0,1,7c0,3.08,4.75,8.29,5.29,8.84l.71.73.71-.73C8.25,15.29,13,10.08,13,7A6,6,0,0,0,7,1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#bbf3fc' }}
        d="M12,7c0,2.76-5,8.14-5,8.14S2,9.76,2,7A5,5,0,0,1,12,7Z"
        transform="translate(0 0)"
      />
      <path style={{ fill: '#ffffff' }} d="M7,9A2,2,0,1,1,9,7,2,2,0,0,1,7,9" transform="translate(0 0)" />
      <path
        style={{ fill: '#014053' }}
        d="M7,9.5A2.5,2.5,0,0,1,4.5,7,2.54,2.54,0,0,1,7,4.5,2.53,2.53,0,0,1,9.5,7,2.5,2.5,0,0,1,7,9.5Zm0-4A1.5,1.5,0,1,0,8.5,7,1.52,1.52,0,0,0,7,5.5Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
