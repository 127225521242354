import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toLower } from 'ramda';
import ServiceLevel from './ServiceLevel.validations';
import * as Presenters from './presenters';
import * as serviceActions from '../../redux/actions/service.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import * as requestsActions from '../../redux/actions/requests.actions';
import * as infoboxActions from '../../redux/actions/infobox.actions';
import { serviceLevelCollapse, serviceLevelExpanded } from '../../flags/collapse.flag';
import { PatientType } from '../../enums';
import * as flags from './ServiceLevel.flags';
import * as titles from './ServiceLevel.titles';
import {
  shouldDisplayHospitalToHospitalConfirmation,
  isWeightHeightRequired,
  nonMedicalTransportTypes,
} from '../../flags/service.flag';

const ServiceLevelContainer = props => {
  const svgStyles = {
    fill: 'currentColor',
    width: 24,
    height: 24,
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: 0,
  };

  const [etaState, setEtaState] = useState({
    ETADialogOpen: false,
    newEtaCallback: null,
  });

  const customCloseNewETADialog = okPressed => {
    if (okPressed) {
      props.closeNewETADialog(true);
      etaState.newEtaCallback();
    }

    setEtaState({ ETADialogOpen: false, newEtaCallback: null });
  };

  const handlePatientDetailsChange = event => props.setPatientDetails({ [event.target.name]: event.target.value });

  const handleWeightBlur = event => {
    const bariatric = Number(props.serviceTypes.Bariatric);

    if (props.allowBariatric) {
      const isAboveThreshold = +event.target.value >= +window.ambulnzConfig.constants.BARIATRIC_WEIGHT_THRESHOLD;
      handleEquipmentAsServiceTypeChange(bariatric, isAboveThreshold);
    }
  };

  const handleCallTypeChange = callTypeId => {
    props.setLightsAndSirens(
      +callTypeId === window.ambulnzConfig.callTypes.EMERGENCY &&
        props.user.isDispatcher &&
        window.ambulnzConfig.constants.SET_LIGHTS_AND_SIRENS_FOR_EMERGENCY_CALLTYPE,
    );

    props.callTypeChange(callTypeId);

    if (+callTypeId === window.ambulnzConfig.callTypes.EMERGENCY) {
      props.setAnswersForEmergency();
      props.mobilityChange(Number(props.mobilities.bedbound));
      props.serviceTypeChange(Number(props.serviceTypes.BLS));
      props.setQuestionMode(false);
    }
  };

  const applyMobilityChange = mobility => () => {
    props.mobilityChange(Number(mobility));
  };

  const handleMobilityChange = mobility => {
    if (props.changeRequiresNewETA) {
      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyMobilityChange(mobility),
      });

      return;
    }

    applyMobilityChange(mobility)();
  };

  const applyPatientTypeChange = patientTypeId => () => {
    props.patientTypeChange(patientTypeId);
  };

  const handlePatientTypeChange = patientTypeId => {
    if (props.changeRequiresNewETA) {
      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyPatientTypeChange(patientTypeId),
      });

      return;
    }

    applyPatientTypeChange(patientTypeId)();
  };

  const applyEquipmentChange = (equipmentId, checked) => () => {
    props.equipmentChange({ equipmentId, checked });
  };

  const handleEquipmentChange = (equipmentId, checked) => {
    if (props.changeRequiresNewETA) {
      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyEquipmentChange(equipmentId, checked),
      });

      return;
    }

    applyEquipmentChange(equipmentId, checked)();
  };

  const applyEquipmentAsServiceTypeChange = (equipment, checked) => () => {
    // hack to support bariatric as serviceType instead of equipment
    props.equipmentAsServiceTypeChange({
      active: checked,
      value: equipment,
    });
  };

  const handleEquipmentAsServiceTypeChange = (equipment, checked) => {
    if (props.changeRequiresNewETA) {
      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyEquipmentAsServiceTypeChange(equipment, checked),
      });

      return;
    }

    applyEquipmentAsServiceTypeChange(equipment, checked)();
  };

  const applyPersonnelChange = (personnel, checked) => () => {
    props.personnelChange({
      id: personnel.id,
      checked,
    });
  };

  const handlePersonnelChange = (personnel, checked) => {
    if (props.changeRequiresNewETA) {
      setEtaState({
        ETADialogOpen: true,
        newEtaCallback: applyPersonnelChange(personnel, checked),
      });

      return;
    }

    applyPersonnelChange(personnel, checked)();
  };

  const setRequirementWarning = show => {
    props.setRequirementWarning(show);
  };

  const disabledPatientTypeIds = [];
  const { patientTypeList } = props;
  const NICU = patientTypeList.find(patientType => patientType.name === toLower(PatientType.NICU));
  const PEDS = patientTypeList.find(patientType => patientType.name === toLower(PatientType.PEDS));
  const Adult = patientTypeList.find(patientType => patientType.name === toLower(PatientType.Adult));

  if (props.NICUDisabled) {
    disabledPatientTypeIds.push(NICU.id);
  }

  if (props.PEDSDisabled) {
    disabledPatientTypeIds.push(PEDS.id);
  }

  if (props.AdultDisabled) {
    disabledPatientTypeIds.push(Adult.id);
  }

  return (
    <ServiceLevel
      handleCallTypeChange={handleCallTypeChange}
      handleMobilityChange={handleMobilityChange}
      handlePatientTypeChange={handlePatientTypeChange}
      handlePersonnelChange={handlePersonnelChange}
      handleEquipmentChange={handleEquipmentChange}
      handleEquipmentAsServiceTypeChange={handleEquipmentAsServiceTypeChange}
      ETADialogOpen={etaState.ETADialogOpen}
      customCloseNewETADialog={customCloseNewETADialog}
      disabledPatientTypeIds={disabledPatientTypeIds}
      requirements={props.requirements}
      requirementIds={props.requirementIds}
      forcedCapabilities={props.forcedCapabilities}
      setRequirementWarning={setRequirementWarning}
      showRequirementWarning={props.showRequirementWarning}
      svgStyles={svgStyles}
      weightHeightRequired={props.weightHeightRequired}
      handleWeightChange={handlePatientDetailsChange}
      handleWeightBlur={handleWeightBlur}
      handleHeightChange={handlePatientDetailsChange}
      bariatricDisabled={props.bariatricDisabled}
      bariatricEquipmentId={props.bariatricEquipmentId}
      {...props}
    />
  );
};

function mapStateToProps(state) {
  const { Curbside, WAV, Livery, BLS, ALS, CCT, Bariatric } = state.service.serviceTypes;
  return {
    serviceLevelDescription: Presenters.presentServiceLevelTitle(state.service),
    panelTitleDesc: Presenters.presentSubtitle(state.service),
    shouldCollapse: serviceLevelCollapse(state),
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    ETADialogTitle: state.formFlow.ETADialogTitle,
    ETADialogBody: state.formFlow.ETADialogBody,
    isLightsAndSirens: state.requests.request.isLightsAndSirens,
    isLightsAndSirensEnabled: flags.isLightsAndSirensEnabled(state),
    user: state.user.model,
    showQuestions: state.service.questionMode && !state.requests.request.id,
    triggerValidation: state.formFlow.triggerValidation,
    expanded: serviceLevelExpanded(state),
    showEquipment: flags.showEquipment(state),
    walkDisabled: flags.walkDisabled(state),
    wheelchairDisabled: flags.wheelchairDisabled(state),
    bedboundDisabled: flags.bedboundDisabled(state),
    canTravelByCarTitle: titles.canTravelByCarTitle(state),
    requiresWheelChairTitle: titles.requiresWheelChairTitle(state),
    showCareProvidedAtReceivingFacility: shouldDisplayHospitalToHospitalConfirmation(),
    patinetTypes: state.service.patientTypes,
    NICUDisabled: flags.NICUDisabled(state),
    PEDSDisabled: flags.PEDSDisabled(state),
    AdultDisabled: flags.AdultDisabled(state),
    curbsideDisabled: flags.serviceTypeDisabled(Curbside, state),
    WAVDisabled: flags.serviceTypeDisabled(WAV, state),
    liveryDisabled: flags.serviceTypeDisabled(Livery, state),
    BLSDisabled: flags.serviceTypeDisabled(BLS, state),
    ALSDisabled: flags.serviceTypeDisabled(ALS, state),
    CCTDisabled: flags.serviceTypeDisabled(CCT, state),
    bariatricDisabled: flags.bariatricDisabled(state),
    bariatricEquipmentId: Bariatric,
    patientTypeList: state.service.patientTypeList,
    requirements: state.service.requirements,
    requirementIds: state.service.requirementIds,
    forcedCapabilities: state.service.forcedCapabilities,
    showRequirementWarning: state.service.showRequirementWarning,
    enableTransportReasonsDispatcherEdit: flags.enabledTransportReasonsDispatcherEdit(state),
    patientWeight: state.infobox.patientWeight,
    patientHeight: state.infobox.patientHeight,
    patientHeightInches: state.infobox.patientHeightInches,
    isBariatric: state.service.bariatric,
    weightHeightRequired: isWeightHeightRequired(state),
    allowBariatric: !nonMedicalTransportTypes(state),
    ...state.service,
  };
}

ServiceLevelContainer.propTypes = {
  callTypeChange: PropTypes.func.isRequired,
  clearAnswers: PropTypes.func.isRequired,
  setLightsAndSirens: PropTypes.func.isRequired,
  setAnswer: PropTypes.func.isRequired,
  setAnswersForEmergency: PropTypes.func.isRequired,
  setQuestionMode: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  mobilityChange: PropTypes.func.isRequired,
  serviceTypeChange: PropTypes.func.isRequired,
  patientTypeChange: PropTypes.func.isRequired,
  keyDownETAField: PropTypes.func.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  showEquipment: PropTypes.bool.isRequired,
  closeNewETADialog: PropTypes.func.isRequired,
  ETADialogTitle: PropTypes.string.isRequired,
  ETADialogBody: PropTypes.string.isRequired,
  equipmentChange: PropTypes.func.isRequired,
  equipmentAsServiceTypeChange: PropTypes.func.isRequired,
  fetchCallTypes: PropTypes.func.isRequired,
  fetchChiefComplaints: PropTypes.func.isRequired,
  fetchProviderCapabilites: PropTypes.func.isRequired,
  personnelChange: PropTypes.func.isRequired,
  roundTripChange: PropTypes.func.isRequired,
  serviceId: PropTypes.number,
  mobilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobilities: PropTypes.shape().isRequired,
  serviceTypes: PropTypes.shape().isRequired,
  patientTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  NICUDisabled: PropTypes.bool.isRequired,
  PEDSDisabled: PropTypes.bool.isRequired,
  AdultDisabled: PropTypes.bool.isRequired,
  patientTypeList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requirementIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  forcedCapabilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showRequirementWarning: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ServiceLevelContainer.defaultProps = {
  serviceId: undefined,
  mobilityId: null,
};

export default connect(mapStateToProps, {
  ...serviceActions,
  ...formFlowActions,
  ...requestsActions,
  ...infoboxActions,
})(ServiceLevelContainer);
