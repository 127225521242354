import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon } from '@mui/material';
import AmbGrid from '../AmbGrid';
import DropLocation from '../icons/DropLocation';
import { drawerWidth } from '../../../theme';

function LocationItem(props) {
  return (
    <AmbGrid style={{ width: drawerWidth - 55 }}>
      <AmbGrid style={{ width: 32 }}>
        <ListItemIcon>
          <DropLocation
            style={{ height: 18, width: 18, marginTop: 6, marginLeft: 2, marginBottom: 0, marginRight: 12 }}
          />
        </ListItemIcon>
      </AmbGrid>
      <AmbGrid style={{ padding: 4 }}>
        <div
          style={{
            width: drawerWidth - 40,
            marginLeft: -7,
            color: '#2e424d',
            whiteSpace: 'nowrap',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '20px',
          }}
        >
          {props.children.name}
        </div>
      </AmbGrid>
    </AmbGrid>
  );
}

LocationItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.node]).isRequired,
};

export default LocationItem;
