import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';

import Questionnaire from './Questionnaire';

const MedicalNecessityStepper = ({ ...props }) => {
  return (
    <Dialog maxWidth={false} aria-labelledby="medicalNecessity-modal" open={props.open}>
      <DialogContent style={{ width: 570 }}>
        <Questionnaire handleCancel={() => props.openMedicalNecessityModal(false)} {...props} />
      </DialogContent>
    </Dialog>
  );
};

MedicalNecessityStepper.propTypes = {
  open: PropTypes.bool.isRequired,
  openMedicalNecessityModal: PropTypes.func.isRequired,
  setExpandPayer: PropTypes.func.isRequired,
  setExpandServiceLevelSection: PropTypes.func.isRequired,
  finished: PropTypes.func.isRequired,
  populatePayer: PropTypes.func.isRequired,
  requestingAgencyPayer: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  pickupTime: PropTypes.string.isRequired,
  requireMedicareQuestions: PropTypes.bool.isRequired,
};

export default MedicalNecessityStepper;
