import React from 'react';
import { connect } from 'react-redux';
import CancelRequest from './CancelRequest.frame';
import { openCancelRequestModal } from './redux/openCancelRequestModal';
import { onCancelReasonTypeChange } from './redux/onCancelReasonTypeChange';
import { onDetailsChange } from './redux/onDetailsChange';
import { asyncCancelRequest } from './redux/actions';
import { fetchRequestReasonTypes } from './redux/fetchRequestReasonTypes';
import { menuCancelDisabled, showDetailsQuestion, cancelDisabled } from './CancelRequest.flags';

const CancelRequestContainer = props => <CancelRequest {...props} />;

function mapStateToProps(state) {
  return {
    menuCancelDisabled: menuCancelDisabled(state),
    showDetailsQuestion: showDetailsQuestion(state),
    cancelDisabled: cancelDisabled(state),
    ...state.cancelRequest,
  };
}

export default connect(mapStateToProps, {
  openCancelRequestModal,
  asyncCancelRequest,
  onDetailsChange,
  fetchRequestReasonTypes,
  onCancelReasonTypeChange,
})(CancelRequestContainer);
