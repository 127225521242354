import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 18 15" {...props}>
      <path
        style={{ fill: '#014053' }}
        d="M11,1c0.6,0,1,0.4,1,1v2.4c0,0.6-0.4,1-1,1H7c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1H11 M11,0H7C5.9,0,5,0.9,5,2
      v2.4c0,1.1,0.9,2,2,2h4c1.1,0,2-0.9,2-2V2C13,0.9,12.1,0,11,0L11,0z"
      />
      <g>
        <g>
          <path
            d="M17,13.7c0,0.1-0.1,0.3-0.3,0.3H1.3C1.1,14,1,13.9,1,13.7V4.7c0-0.1,0.1-0.3,0.3-0.3h15.5
          c0.1,0,0.3,0.1,0.3,0.3V13.7z"
          />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M16,5.4V13H2V5.4H16 M16.7,4.4H1.3C1.1,4.4,1,4.5,1,4.7v9.1C1,13.9,1.1,14,1.3,14h15.5c0.1,0,0.3-0.1,0.3-0.3
          V4.7C17,4.5,16.9,4.4,16.7,4.4L16.7,4.4z"
          />
        </g>
        <path
          style={{ fill: '#014053' }}
          d="M16.7,4.4c0.1,0,0.3,0.1,0.3,0.3v9.1c0,0.1-0.1,0.3-0.3,0.3H1.3C1.1,14,1,13.9,1,13.7V4.7
        c0-0.1,0.1-0.3,0.3-0.3H16.7 M16.7,3.4H1.3C0.6,3.4,0,4,0,4.7v9.1C0,14.4,0.6,15,1.3,15h15.5c0.7,0,1.3-0.6,1.3-1.3V4.7
        C18,4,17.4,3.4,16.7,3.4L16.7,3.4z"
        />
      </g>
      <g>
        <rect x="8.5" y="6.2" style={{ fill: '#014053' }} width="1" height="6" />
        <rect x="6" y="8.7" style={{ fill: '#014053' }} width="6" height="1" />
      </g>
    </svg>
  );
}
