import React from 'react';
import { connect } from 'react-redux';
import MissingFieldsModal from './MissingFieldsModal.frame';
import { closeMissingFieldsModal } from './redux/actions';

const MissingFieldsModalContainer = props => <MissingFieldsModal {...props} />;

function mapStateToProps(state) {
  return {
    open: state.missingFieldsModal.open,
    body: state.missingFieldsModal.body,
  };
}

export default connect(mapStateToProps, { closeMissingFieldsModal })(MissingFieldsModalContainer);
