const charFromNumber = (n, index) => n.toString().charAt(index);
const c1 = n => charFromNumber(n, 0);
const c2 = n => charFromNumber(n, 1);
const c3 = n => charFromNumber(n, 2);
const c4 = n => charFromNumber(n, 3);
const inRange = (m, index, range) => Number(charFromNumber(m, index) + charFromNumber(m, index + 1)) <= range;
const inMinutes = (m, index) => inRange(m, index, 59);
const inHours12 = (m, index) => inRange(m, index, 12);
const inHours24 = (m, index) => inRange(m, index, 24);
const ampmIndexed = (time, ampm) => time.indexOf(ampm) > 0;
const isAM = time => ampmIndexed(time, 'a');
const isPM = time => ampmIndexed(time, 'p');
const toNumber = (time, ampm = '') => Number(time.split(ampm).join(''));
const parseColon = time => {
  const a = time.split(':');
  if (a.length === 1 || a[0].length === 0) {
    return time;
  }
  const digitsAfterColon = a[1].replace(/[^0-9.]/g, '');
  const minutesAfterColon = digitsAfterColon.length === 1 ? String(Number(digitsAfterColon) * 10) : digitsAfterColon;
  a[1] = a[1].replace(digitsAfterColon, minutesAfterColon);
  return a.join('');
};
const ampmToNumber = (time, ampm = '') => Number(time.split(ampm)[0].trim());
const toAMNumber = time => ampmToNumber(time, 'a');
const toPMNumber = time => ampmToNumber(time, 'p');
const parseTime = time => {
  const t = parseColon(time);
  if (isAM(t)) {
    return toAMNumber(t);
  } else if (isPM(t)) {
    return toPMNumber(t);
  }
  return toNumber(t);
};

const parseAMPM = (time, startAt) => {
  if ((time.length === 4 && time.startsWith('00')) || time.startsWith('24')) {
    return `12:${time.substring(2, 4)} AM`;
  }

  const startAtSuffix = h => (h >= startAt && startAt <= h && h !== 12 ? 'AM' : 'PM');
  const calcSuffix = h => {
    if (isAM(time.toLowerCase())) {
      return 'AM';
    } else if (isPM(time.toLowerCase())) {
      return 'PM';
    }
    return startAtSuffix(h);
  };
  const parseHour = hour => `${hour}:00 ${calcSuffix(hour)}`;

  const t = parseTime(time.toLowerCase());
  const length = t ? t.toString().length : 0;
  let timeParsed = '';

  switch (length) {
    case 1:
      // timeParsed = parseHour(t);
      break;
    case 2:
      // if (t <= 12) {
      //   timeParsed = parseHour(t);
      // } else if (t < 24) {
      //   const suffix = t >= 12 ? 'PM' : 'AM';
      //   timeParsed = `${t - 12}:00 ${suffix}`;
      // }
      break;
    case 3:
      if (inMinutes(t, 1)) {
        timeParsed = `${c1(t)}:${c2(t)}${c3(t)} ${calcSuffix(Number(c1(t)))}`;
      }
      break;
    case 4:
      if (inHours12(t, 0) && inMinutes(t, 2)) {
        timeParsed = `${c1(t)}${c2(t)}:${c3(t)}${c4(t)} ${calcSuffix(Number(c1(t) + c2(t)))}`;
      } else if (inHours24(t, 0) && inMinutes(t, 2)) {
        timeParsed = `${Number(c1(t) + c2(t)) - 12}:${c3(t)}${c4(t)} PM`;
      }
      break;
    default:
      timeParsed = '';
      break;
  }
  return timeParsed;
};

const parseMilitary = time => {
  const t = parseColon(time);
  const tNum = Number(t);
  const minutes = tNum % 100;
  if (isNaN(tNum) || minutes > 59 || tNum >= 2400) {
    return '';
  }

  // const parseHour = hour => `${hour}:00`;

  let timeParsed = '';

  switch (t?.length) {
    case 1:
      break;
    case 2:
      // timeParsed = parseHour(t);
      break;
    case 3:
      if (inMinutes(t, 1)) {
        timeParsed = `${c1(t)}:${c2(t)}${c3(t)}`;
      }
      break;
    case 4:
      if (inHours24(t, 0) && inMinutes(t, 2)) {
        timeParsed = `${c1(t)}${c2(t)}:${c3(t)}${c4(t)}`;
      }
      break;
  }
  return timeParsed;
};

const createTimeOptions = (ampm, interval = 15, startFrom = 0, endAt = 24) => {
  const timeOptions = [];
  for (let i = startFrom; i < endAt; i++) {
    const hours = ampm ? ((i + 11) % 12) + 1 : i;
    const suffix = ampm ? (i >= 12 ? ' PM' : ' AM') : '';
    timeOptions.push(`${hours}:00${suffix}`);
    if (interval === 5 || interval === 10 || interval === 15 || interval === 20 || interval === 30) {
      for (let j = interval; j < 60; j += interval) {
        timeOptions.push(`${hours}:${j < 10 ? '0' + j : j}${suffix}`);
      }
    }
  }
  return timeOptions;
};

const hourAsNumber = h => Number(h.split(':')[0]);

const createOptionsFromHour = (interval, ampm, time) => {
  let t = hourAsNumber(time);
  t += ampm && time.split(' ')[1] === 'PM' ? 12 : 0;
  return createTimeOptions(ampm, Number(interval), t);
};

const getIndex = (options, time) => {
  return options.find(t => t === time) ? options.indexOf(time) : options.indexOf(time.replace(/(:).{2}/, ':00'));
};

export { parseAMPM, parseMilitary, createTimeOptions, createOptionsFromHour, getIndex };
