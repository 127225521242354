import React, { useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AppFrame from './components/AppFrame';
import SideContent from './components/AppFrame/SideContent';
import { customTheme, getThemeName } from './theme';
import * as routeActions from './redux/actions/route.actions';

const App = props => {
  const { routeChange, match } = props;
  useEffect(() => {
    routeChange(match);
  }, [match, routeChange]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme(getThemeName(window.ambulnzConfig.constants, props.user))}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppFrame {...props}>
            <SideContent />
          </AppFrame>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

App.propTypes = {
  user: PropTypes.shape().isRequired,
  routeChange: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  phone: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user.model,
    phone: state.supportPhone.phone,
  };
}

export default connect(mapStateToProps, { ...routeActions })(App);
