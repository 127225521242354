import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 15 16" {...props}>
      <ellipse cx="8.5" cy="9.5" rx="6.5" ry="6.5" />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M11.5,8C11.2,8,11,8.2,11,8.5v2.3c0,1.4-1.1,2.4-2.5,2.4c-1.4,0-2.5-1-2.5-2.4v-0.6c2.2-0.5,4-2.6,4-5.2V1.5
	  C10,1.3,9.8,1,9.5,1S9,1.3,9,1.5V5c0,2-1.3,3.6-3,4.1L5,9.3l0,1.4c0,1.9,1.6,3.4,3.5,3.4s3.5-1.5,3.5-3.4V8.5C12,8.2,11.8,8,11.5,8z
	  "
      />
      <path
        style={{ fill: '#004251' }}
        d="M8.5,15.1c-2.5,0-4.5-2-4.5-4.5V8.9c0-0.3,0.2-0.5,0.5-0.5S5,8.7,5,8.9v1.7c0,1.9,1.6,3.5,3.5,3.5
	  s3.5-1.6,3.5-3.5v-3c0-0.3,0.2-0.5,0.5-0.5S13,7.4,13,7.6v3C13,13.1,11,15.1,8.5,15.1z"
      />
      <path
        style={{ fill: '#004251' }}
        d="M4.5,9.4C2,9.4,0,7.4,0,5.1V0.5C0,0.2,0.2,0,0.5,0H2c0.3,0,0.5,0.2,0.5,0.5S2.3,1,2,1H1v4.1
	  c0,1.8,1.6,3.3,3.5,3.3S8,6.9,8,5.1V1H7C6.7,1,6.5,0.8,6.5,0.5S6.7,0,7,0h1.5C8.8,0,9,0.2,9,0.5v4.6C9,7.4,7,9.4,4.5,9.4z"
      />
      <g>
        <circle style={{ fill: '#FFFFFF' }} cx="12.5" cy="8.6" r="1.5" />
        <path
          style={{ fill: '#004251' }}
          d="M12.5,10.6c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C14.5,9.7,13.6,10.6,12.5,10.6z M12.5,7.6
		  c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S13.1,7.6,12.5,7.6z"
        />
      </g>
    </svg>
  );
}
