import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import PickupDestination from './PickupDestination.validations';
import * as locationActions from '../../redux/actions/location.actions';
import * as requestsActions from '../../redux/actions/requests.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import * as infoboxActions from '../../redux/actions/infobox.actions';
import * as mapActions from '../../redux/actions/map.actions';
import * as componentActions from './redux/actions/component.actions';
import { handleSuggestionChange } from '../common/SuggestionsInput/SuggestionsInput';
import { pickupDestinationCollapse, pickupDestinationExpanded } from '../../flags/collapse.flag';
import { islockedByBillerVerified, islockedByPcr } from '../../flags/disabled.flag';
import { showHospitalToHospitalWarning } from '../../flags/service.flag';

const billerVerifiedMsg = 'You must unset Prebiller Verified to change location.';
const zollMsg = 'Trip was exported to Zoll. Location can only change to a matching facility.';
const hospitalToHospitalWarnings = [
  '<p id="hospitalToHospitalWarning">Acute Hospital to Hospital transfers require additional reasoning in the section below.</p>',
];
const warningCount = state => (showHospitalToHospitalWarning(state) ? hospitalToHospitalWarnings.length : 0);

class PickupDestinationContainer extends React.Component {
  state = {
    subLabel: '',
  };

  componentDidMount() {
    this.props.getPcrStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.lockedByBillerVerified !== nextProps.lockedByBillerVerified && !nextProps.lockedByBillerVerified) {
      this.setState({
        subLabel: '',
      });
    }
  }

  handlePickupLocationChange = (query, downshiftState) => {
    const {
      changeRequiresNewETA,
      request,
      keyDownETAField,
      lockedByPcr,
      queryPickupFacilitiesOnly,
      selectPickupLocation,
      clearNothing,
      lockedByBillerVerified,
      queryPickupLocations,
      clearPickupLocation,
    } = this.props;

    if (
      changeRequiresNewETA &&
      request.needsAssignment &&
      downshiftState.type !== Downshift.stateChangeTypes.mouseUp &&
      downshiftState.type !== Downshift.stateChangeTypes.blurInput
    ) {
      keyDownETAField();
      return;
    }

    if (lockedByPcr) {
      this.setState({
        subLabel: zollMsg,
      });

      handleSuggestionChange(queryPickupFacilitiesOnly, selectPickupLocation, clearNothing)(query, downshiftState);
      return;
    }

    if (lockedByBillerVerified) {
      this.setState({
        subLabel: billerVerifiedMsg,
      });

      return;
    }

    handleSuggestionChange(queryPickupLocations, selectPickupLocation, clearPickupLocation)(query, downshiftState);
  };

  handlePickupLocationClick = () => {
    const { lockedByPcr, queryPickupFacilitiesOnly, lockedByBillerVerified } = this.props;

    if (lockedByPcr) {
      queryPickupFacilitiesOnly();
      this.setState({
        subLabel: zollMsg,
      });

      return;
    }

    if (lockedByBillerVerified) {
      this.setState({
        subLabel: billerVerifiedMsg,
      });
    }
  };

  handleDestinationChange = (query, downshiftState) => {
    const {
      changeRequiresNewETA,
      request,
      queryDestinationFacilitiesOnly,
      selectDestinationLocation,
      clearNothing,
      queryDestinations,
      clearDestinationLocation,
      lockedByPcr,
      lockedByBillerVerified,
    } = this.props;

    if (
      changeRequiresNewETA &&
      request.needsAssignment &&
      downshiftState.type !== Downshift.stateChangeTypes.mouseUp &&
      downshiftState.type !== Downshift.stateChangeTypes.blurInput
    ) {
      this.props.keyDownETAField();
      return;
    }

    if (lockedByPcr) {
      this.setState({
        subLabel: zollMsg,
      });

      handleSuggestionChange(
        queryDestinationFacilitiesOnly,
        selectDestinationLocation,
        clearNothing,
      )(query, downshiftState);

      return;
    }

    if (lockedByBillerVerified) {
      this.setState({
        subLabel: billerVerifiedMsg,
      });

      return;
    }

    handleSuggestionChange(
      queryDestinations,
      selectDestinationLocation,
      clearDestinationLocation,
    )(query, downshiftState);
  };

  handleDestinationClick = () => {
    if (this.props.lockedByPcr) {
      this.props.queryDestinationFacilitiesOnly();
      this.setState({
        subLabel: zollMsg,
      });

      return;
    }

    if (this.props.lockedByBillerVerified) {
      this.setState({
        subLabel: billerVerifiedMsg,
      });
    }
  };

  handleToggleERBound = event => {
    if (this.props.changeRequiresNewETA) {
      this.props.keyDownETAField();
      return;
    }

    this.props.toggleERBound(event.target.checked);
  };

  render() {
    return (
      <PickupDestination
        handlePickupLocationChange={this.handlePickupLocationChange}
        handlePickupLocationClick={this.handlePickupLocationClick}
        handleDestinationChange={this.handleDestinationChange}
        handleDestinationClick={this.handleDestinationClick}
        handleToggleERBound={this.handleToggleERBound}
        defaultPickupSec={window.ambulnzConfig.constants.REQUEST_DEFAULT_AT_PICKUP_SECS}
        defaultDropoffSec={window.ambulnzConfig.constants.REQUEST_DEFAULT_AT_DROPOFF_SECS}
        showPickup={true}
        showDestination={!this.props.isNonTransport}
        {...this.props}
        {...this.state}
      />
    );
  }
}

PickupDestinationContainer.propTypes = {
  queryPickupLocations: PropTypes.func.isRequired,
  queryDestinations: PropTypes.func.isRequired,
  selectPickupLocation: PropTypes.func.isRequired,
  selectDestinationLocation: PropTypes.func.isRequired,
  clearPickupLocation: PropTypes.func.isRequired,
  clearDestinationLocation: PropTypes.func.isRequired,
  toggleERBound: PropTypes.func.isRequired,
  keyDownETAField: PropTypes.func.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  lockedByBillerVerified: PropTypes.bool.isRequired,
  lockedByPcr: PropTypes.bool.isRequired,
  queryPickupFacilitiesOnly: PropTypes.func.isRequired,
  queryDestinationFacilitiesOnly: PropTypes.func.isRequired,
  clearNothing: PropTypes.func.isRequired,
  getPcrStatus: PropTypes.func.isRequired,
  request: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  return {
    location: state.location,
    pickupLocation: state.location.from,
    selectedPickupLocation: state.location.fromSelected,
    pickupLocations: state.location.fromItems,
    destination: state.location.to,
    selectedDestinationLocation: state.location.toSelected,
    destinations: state.location.toItems,
    shouldCollapse: pickupDestinationCollapse(state),
    isERBound: state.location.erBound,
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    manualPickupSecs: state.location.manualPickupSecs,
    manualDropOffSecs: state.location.manualDropOffSecs,
    searchPickupLocationInProgress: state.location.searchPickupLocationInProgress,
    searchDestinationInProgress: state.location.searchDestinationInProgress,
    hideEstTime: window.ambulnzConfig.hideEstTime,
    user: state.user.model,
    loadedWillCall: state.requests.loadedWillCall,
    triggerValidation: state.formFlow.triggerValidation,
    request: state.requests.request,
    isNonTransport: state.requests.isNonTransport,
    destinationAutoFocus: state.location.destinationAutoFocus,
    isPickupResidence: state.map.isPickupResidence,
    isDestinationResidence: state.map.isDestinationResidence,
    pickupResidenceDisabled: state.map.pickupResidenceDisabled,
    destinationResidenceDisabled: state.map.destinationResidenceDisabled,
    expanded: pickupDestinationExpanded(state),
    lockedByBillerVerified: islockedByBillerVerified(state),
    lockedByPcr: islockedByPcr(state),
    pickupDepartments: state.location.pickupDepartments,
    destinationDepartments: state.location.destinationDepartments,
    pickupDepartmentId: state.requests.pickupDepartmentId,
    destinationDepartmentId: state.requests.destinationDepartmentId,
    warnings: hospitalToHospitalWarnings,
    showWarning: showHospitalToHospitalWarning(state),
    warningCount: warningCount(state),
  };
}

export default connect(mapStateToProps, {
  ...locationActions,
  ...requestsActions,
  ...formFlowActions,
  ...infoboxActions,
  ...mapActions,
  ...componentActions,
})(PickupDestinationContainer);
