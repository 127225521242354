import RequestService from './request.service';

export async function updateReturnRequestCanBeActivated(request, state) {
  if (
    request.isWillCallReturn &&
    request.returnRequestId &&
    request.patientId &&
    state.agency.canRequestBeActivatedByPatient
  ) {
    await RequestService.updateRequest(request.returnRequestId, {
      canRequestBeActivatedByPatient: state.requests.canReturnRequestBeActivatedByPatient,
    });
  }

  return request;
}
