import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 14 13" {...props}>
      <g>
        <g>
          <path d="M1.6,9.4c0.4-1.9,1.7-4.9,6-4.9H8V3.1L11.6,6L8,8.8V7.4H7.5C4.6,7.4,2.6,8.5,1.6,9.4z" />
          <path
            style={{ fill: '#FFFFFF' }}
            d="M8.5,4.1L10.8,6L8.5,7.7V6.9h-1c-2.2,0-3.9,0.6-5.1,1.3C3.1,6.6,4.5,5,7.5,5h1V4.1 M7.5,2.1V4
			  c-6.7,0-6.7,6.9-6.7,6.9s1.9-3,6.7-3v1.9L12.4,6L7.5,2.1L7.5,2.1z"
          />
        </g>
        <path
          style={{ fill: '#004251' }}
          d="M7.5,2.1L12.4,6L7.5,9.8V7.9c-4.8,0-6.7,3-6.7,3S0.9,4,7.5,4V2.1 M6.6,0v2v1C3.3,3.4,1.6,5.3,0.8,7
		  C0,8.7,0,10.1,0,10.8C0,11.5,0.1,13,0.1,13s1.1-1.4,1.8-2c0.7-0.7,1.8-1.8,4.7-2.1v0.9v2l1.6-1.2L13,6.7L14,6l-1-0.8L8.2,1.3L6.6,0
		  L6.6,0z"
        />
      </g>
    </svg>
  );
}
