import { asyncCancelRequestResponse } from './actions';
import * as toastActions from '../../../../redux/actions/toast.actions';
import * as errorActions from '../../../../redux/actions/error.actions';
import * as requestsActions from '../../../../redux/actions/requests.actions';

const asyncCancelRequestResponseMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (asyncCancelRequestResponse.match(action)) {
      const response = action.payload;
      if (response.error) {
        dispatch(toastActions.onToast(response.error.message));
        dispatch(errorActions.onError(response));
      } else {
        dispatch(requestsActions.updateRequestSuccess(response));
      }
    }
  };

export default [asyncCancelRequestResponseMdl];
