import { get } from 'lodash';
import { createAction } from '@reduxjs/toolkit';
import { Calc } from '../../../enums';

export function getMileage({ segments }) {
  const etaSegmentIndex = segments.findIndex(segment => segment.eta_segment);
  const segmentsUntilEta = segments.slice(0, etaSegmentIndex);
  const meters = +segmentsUntilEta.reduce((sum, { meters }) => sum + meters, 0);
  return (meters / Calc.MILES).toFixed(1);
}

export function parse(payload) {
  const eta = payload.find(eta => eta.type === 'provider');
  const ambulnzBest = get(eta, 'sortedUnits');
  if (!ambulnzBest) {
    return [];
  }

  return ambulnzBest.map(({ candidateSchedules }, i) => ({
    id: i,
    unit: candidateSchedules[0].unit,
    previous: candidateSchedules[0].previous_plan ? candidateSchedules[0].previous_plan.end_at : null,
    enRoute: candidateSchedules[0].start_at,
    mileage: getMileage(candidateSchedules[0]),
    eta: candidateSchedules[0].eta,
    allClear: candidateSchedules[0].end_at,
    next: candidateSchedules[0].next_plan ? candidateSchedules[0].next_plan.start_at : null,
    requestType: candidateSchedules[0].request_type,
    displayWindowEndAt: candidateSchedules[0].display_window_end_at,
    displayWindowStartAt: candidateSchedules[0].display_window_start_at,
    conflict: null,
  }));
}

export const setProposedETAs = createAction('[ProposedETAModal] setProposedETAs');

export const reducer = {
  [setProposedETAs]: (state, payload) => ({
    ...state,
    etas: parse(payload),
  }),
};
