const getFromRequester = (
  { requesterFirstName: firstName, requesterLastName: lastName, requesterPhone: phone },
  { updateTypes },
) => {
  const requesterRequestedUpdateType = updateTypes.find(t => t.name.toLowerCase() === 'requester requested');

  return {
    firstName,
    lastName,
    phone,
    updateTypeId: requesterRequestedUpdateType.id,
    details: 'N/A',
  };
};

// eslint-disable-next-line import/prefer-default-export
export const parseRequesterUpdate = ({ pickupTimeChange, infobox }) =>
  pickupTimeChange.firstName ? pickupTimeChange : getFromRequester(infobox, pickupTimeChange);
