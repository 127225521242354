import React from 'react';

import TripTypeToggle from '../../components/TripTypeToggle';
import FormContainer from '../../components/FormWidget/FormContainer';

const CreateRequest = props => {
  return (
    <>
      <TripTypeToggle {...props} />
      <FormContainer {...props} />
    </>
  );
};
export default CreateRequest;
