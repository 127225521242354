const PHONE_LENGTH = 14;
const ZIP_LENGTH = 5;
const SSN_LENGTH = 9;

export const requesterFieldsValid = state =>
  state.requesterFirstName &&
  state.requesterLastName &&
  state.requesterPhone &&
  state.requesterPhone.length === PHONE_LENGTH;

export const additionalFieldsValid = state => {
  const presentWithLength = (val, length) => !val || val.length === length;

  return (
    presentWithLength(state.infobox.patientSsn, SSN_LENGTH) &&
    presentWithLength(state.patient.phone, PHONE_LENGTH) &&
    presentWithLength(state.patient.zip, ZIP_LENGTH)
  );
};
