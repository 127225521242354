import { createReducer } from 'redux-act';
import initialState from './initialState';
import { reducer as openMedicalNecessityModal } from './openMedicalNecessityModal';
import { reducer as setExpandPayer } from './setExpandPayer';
import { reducer as setExpandServiceLevelSection } from './setExpandServiceLevelSection';

const reducer = createReducer(
  {
    ...openMedicalNecessityModal,
    ...setExpandPayer,
    ...setExpandServiceLevelSection,
  },
  initialState,
);

export default reducer;
