import React from 'react';
import AmbTextField from '../AmbTextField';
import { theme } from '../../../theme';

function SuggestionsTextField(props) {
  const { id, placeholder, helperText, subLabel, InputLabelProps, error, ...other } = props;
  return (
    <div style={{ width: '100%', fontSize: theme.typography.fontSize }}>
      <AmbTextField
        error={error}
        id={id}
        label={placeholder}
        helperText={helperText}
        subLabel={subLabel}
        InputLabelProps={{
          style: { ...theme.mixins.inputLabel },
          shrink: true,
        }}
        {...other}
      />
    </div>
  );
}

export default SuggestionsTextField;
