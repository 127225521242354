import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../icons/Checkbox';
import { theme } from '../../../theme';

const AmbCheckbox = ({ checked, inputId, handleChange, disabled, label, propsStyles, helperText, showHelperText }) => {
  const formControlStyles = {
    width: '100%',
    margin: 0,
    border: 0,
    display: 'inline-flex',
    padding: 8,
    position: 'relative',
    minWidth: 0,
    alignItems: 'center',
  };

  const helperTextStyles = {
    position: 'absolute',
    top: 46,
    color: theme.palette.primary.helperText,
    fontSize: '0.85rem',
    textAlign: 'left',
    minHeight: '1em',
    lineHeight: '1em',
    minWidth: 78,
  };

  const checkboxStyles = {
    width: 'auto',
    height: 'auto',
    color: theme.palette.secondary.main,
    position: 'relative',
  };

  const checkboxDisabledStyles = {
    width: 'auto',
    height: 'auto',
    color: theme.palette.primary.dark,
    opacity: '0.2',
    pointerEvents: 'none',
    position: 'relative',
  };

  const formLabelStyles = {
    fontSize: '14px',
    paddingLeft: 6,
    marginLeft: 0,
  };

  return (
    <div style={formControlStyles}>
      {helperText && showHelperText && (
        <div style={helperTextStyles}>
          <p>{helperText}</p>
        </div>
      )}
      <span style={{ ...propsStyles, ...(disabled ? checkboxDisabledStyles : checkboxStyles) }}>
        <Checkbox checked={checked} disabled={disabled || null} />
        <input
          type="checkbox"
          checked={checked}
          id={inputId}
          onChange={e => handleChange(e.target.checked)}
          disabled={disabled || null}
          style={{ position: 'absolute', top: 1, left: 5, opacity: 0 }}
        />
      </span>
      <label htmlFor={inputId} style={{ ...propsStyles, ...formLabelStyles }}>
        {label}
      </label>
    </div>
  );
};

AmbCheckbox.propTypes = {
  label: PropTypes.shape().isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  propsStyles: PropTypes.shape(),
  helperText: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperTextMaxLength: PropTypes.number,
};

AmbCheckbox.defaultProps = {
  disabled: false,
  propsStyles: {},
  helperText: '',
  showHelperText: true,
  helperTextMaxLength: 100,
};

export default AmbCheckbox;
