import { useState, useEffect } from 'react';
import PersonnelService from '../../services/personnelService';
import vehicleScema from '../../schemas/vehicle.schema';
import personnelScema from '../../schemas/personnel.schema';

function parsePersonnel(personnel) {
  return (personnel || []).map(p => personnelScema.parse(p)).sort((a, b) => a.order - b.order);
}

function usePersonnel(request) {
  const [personnel, setPersonnel] = useState({
    personnel: [],
    vehicle: {},
  });

  async function getPersonnel() {
    if (!request.id || request.needsAssignment) {
      return setPersonnel({ personnel: [], vehicle: {} });
    }

    const service = new PersonnelService(request);
    let response;
    try {
      response = await service.getPersonnel();
      response = {
        personnel: parsePersonnel(response.personnel),
        vehicle: vehicleScema.parse(response.vehicle || {}),
      };
    } catch (error) {
      response = { personnel: [], vehicle: {} };
    }

    setPersonnel(response);
    return () => {}; // todo: unsubscribe
  }

  useEffect(
    () => {
      getPersonnel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request.status],
  );

  return personnel;
}

export default usePersonnel;
