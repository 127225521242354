import React from 'react';
import PropTypes from 'prop-types';
import NotesWidget from './NotesWidget';
import PatientInfo from './PatientInfoWidget';
import Coverages from './Coverages';
import ChiefComplaintWidget from './ChiefComplaintWidget';
import PatientDocuments from '../PatientDocuments';
import { theme } from '../../theme';

const PatientInfoBoxFrame = ({
  patientDocumentsDisabled,
  patientInfoExpanded,
  patientDetailsDisabled,
  patientCoverageDisplay,
  patientCoverageDisabled,
  chiefComplaintDisplay,
  chiefComplaintDisabled,
  otherOptionOfChiefComplaintDisabled,
  notesDisplay,
  notesDisabled,
}) => (
  <div style={{ backgroundColor: theme.palette.background.paper }}>
    <PatientDocuments disabled={patientDocumentsDisabled} />
    <PatientInfo expanded={patientInfoExpanded} disabled={patientDetailsDisabled} />
    {patientCoverageDisplay && <Coverages disabled={patientCoverageDisabled} />}
    {chiefComplaintDisplay && (
      <ChiefComplaintWidget disabled={chiefComplaintDisabled} otherDisabled={otherOptionOfChiefComplaintDisabled} />
    )}
    {notesDisplay && <NotesWidget disabled={notesDisabled} />}
  </div>
);

PatientInfoBoxFrame.propTypes = {
  patientCoverageDisplay: PropTypes.bool.isRequired,
  chiefComplaintDisplay: PropTypes.bool.isRequired,
  notesDisplay: PropTypes.bool.isRequired,
  patientDocumentsDisabled: PropTypes.bool.isRequired,
  patientInfoExpanded: PropTypes.bool.isRequired,
  patientDetailsDisabled: PropTypes.bool.isRequired,
  patientCoverageDisabled: PropTypes.bool.isRequired,
  chiefComplaintDisabled: PropTypes.bool.isRequired,
  otherOptionOfChiefComplaintDisabled: PropTypes.bool.isRequired,
  notesDisabled: PropTypes.bool.isRequired,
};

export default PatientInfoBoxFrame;
