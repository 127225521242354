import { filter, map, switchMap } from 'rxjs/operators';

import { retractOffer, unReserve } from '../../../../redux/epics/helpers/request.helper';
import { asyncUnassignTripFromProviderRequest, asyncUnassignTripFromProviderResponse } from './actions';

export const unassignRequestEpic = (action$, store) =>
  action$.pipe(
    filter(asyncUnassignTripFromProviderRequest.match),
    switchMap(() => {
      const { offers } = store.value;
      const isOfferCreated = offers.offers.find(offer => offer.offerStatus.name === 'created');
      return isOfferCreated ? retractOffer(store) : unReserve(store);
    }),
    map(payload => asyncUnassignTripFromProviderResponse(payload)),
  );
