import { combineReducers } from '@reduxjs/toolkit';

import agency from './agency.reducer';
import app from './app.reducer';
import cancelRequest from '../../components/Summary/CancelRequest/redux/reducer';
import duplicateRequest from '../../components/Summary/DuplicateRequest/redux/duplicateRequest.reducer';
import eligibility from './eligibility.reducer';
import error from './error.reducer';
import eta from './eta.reducer';
import formFlow from './formFlow.reducer';
import infobox from './infobox.reducer';
import location from './location.reducer';
import map from './map.reducer';
import medicalNecessity from '../../components/MedicalNecessityStepper/redux/reducer';
import missingFieldsModal from '../../components/MissingFieldsModal/redux/reducer';
import newOrExisting from './newOrExisting.reducer';
import offers from './offers.reducer';
import patient from './patient.reducer';
import patientDocuments from '../../components/PatientDocuments/redux/reducer';
import physicianCertificationStatement from '../../components/PhysicianCertificationStatement/redux/reducer';
import pickupTimeChange from './pickupTimeChange.reducer';
import proposedETAModal from '../../components/ProposedETAModal/redux/reducer';
import requestShare from '../../components/RequestShare/redux/reducer';
import requests from './requests.reducer';
import route from './route.reducer';
import saveButton from '../../components/SaveButton/redux/saveButton.reducer';
import schedule from './schedule.reducer';
import service from './service.reducer';
import toast from './toast.reducer';
import trackChanges from './trackChanges.reducer';
import turndown from './turndown.reducer';
import unassignRequest from '../../components/Summary/Unassign/redux/reducer';
import user from './user.reducer';
import userAccount from './userAccount.reducer';
import supportPhone from './supportPhone.reducer';
import epic from './epicIntegration.reducer';

const createRootReducer = history =>
  combineReducers({
    agency,
    app,
    cancelRequest,
    duplicateRequest,
    eligibility,
    error,
    eta,
    epic,
    formFlow,
    infobox,
    location,
    map,
    medicalNecessity,
    missingFieldsModal,
    newOrExisting,
    offers,
    patient,
    patientDocuments,
    physicianCertificationStatement,
    pickupTimeChange,
    proposedETAModal,
    requestShare,
    requests,
    route,
    saveButton,
    schedule,
    service,
    toast,
    trackChanges,
    turndown,
    unassignRequest,
    user,
    userAccount,
    supportPhone,
  });

export default createRootReducer;
