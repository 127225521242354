import React from 'react';
import styled from '@emotion/styled';

export const Label = styled.p`
  && {
    padding: 8px;
    font-style: italic;
    font-size: 14px;
    margin: 0;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 33px;
  margin: 3px;
  align-self: center;
  opacity: 0.25;
  background-color: #899ba6;
`;

const Container = styled.div`
  && {
    height: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
    top: 50%;
    position: absolute;
    transform: translateY(-50%) rotate(0deg);
    right: ${({ grow }) => (grow ? 80 : 48)}px;
  }
`;

export const VerticalLabel = styled.p`
  && {
    font-size: 12px;
    margin: 0;
  }
`;

export const Vertical = ({ children, grow }) => (
  <Container grow={grow}>
    <Divider />
    <VerticalLabel>{children}</VerticalLabel>
    <Divider />
  </Container>
);
