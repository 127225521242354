import { groupBy } from 'lodash';

const titleByType = attachments => {
  const groupByType = groupBy(attachments, 'attachmentType.name');
  return Object.keys(groupByType)
    .map(k => `${k}: ${groupByType[k].length}`)
    .join(' | ');
};

const formatTitle = state => {
  let { attachments } = state.patientDocuments;
  if (state.user.model.isRequester) {
    attachments = attachments.filter(att => att.attachmentType.type !== 'PCR');
  }

  return attachments.length > 0 ? titleByType(attachments) : 'Attachments';
};

export default formatTitle;
