import moment from 'moment';

import * as infoboxActions from '../actions/infobox.actions';
import * as requestsActions from '../actions/requests.actions';
import * as willCallActions from '../actions/willCall.actions';
import { isSamePatient, parsePatient } from '../epics/helpers/patient.helper';
import * as trackChangesActions from '../actions/trackChanges.actions';

const updatePatientSuccess =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    if (action && infoboxActions.updatePatientSuccess.match(action)) {
      const { isJustActivated, willCall } = getState().requests;

      const patientChanges = getState().trackChanges.patient;
      const patientToCheck = {
        firstName: patientChanges.length ? patientChanges[0].patientFirstName : '',
        lastName: patientChanges.length ? patientChanges[0].patientLastName : '',
        dob: patientChanges.length ? patientChanges[0].patientBirthday : moment(),
      };

      if ((!isSamePatient(parsePatient(getState()), patientToCheck) && !willCall) || isJustActivated) {
        if (isJustActivated) {
          dispatch(willCallActions.setIsJustActivated(false));
        }

        dispatch(trackChangesActions.addPatientSnapshot(getState().infobox));
        dispatch(requestsActions.setTriggeredBy(null));
        dispatch(requestsActions.asyncGetSimilarRequests());
      }
    }

    next(action);
  };

export const submitPatientInfo =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (infoboxActions.submitPatientInfo.match(action)) {
      dispatch(infoboxActions.setSubmitPatientInfoInProgress(true));
    }
  };

export const submitPatientInfoSuccess =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (infoboxActions.submitPatientInfoSuccess.match(action)) {
      dispatch(infoboxActions.setSubmitPatientInfoInProgress(false));
    }
  };

export const patientMdl = [updatePatientSuccess, submitPatientInfo, submitPatientInfoSuccess];
