import React from 'react';

const SelectArrow = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      focusable="false"
      aria-hidden="true"
      className={props.class}
      style={{
        ...props.style,
        width: '1em',
        height: '1em',
        fontSize: '1.5rem',
        fill: 'currentColor',
        display: 'inline-block',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        userSelect: 'none',
        flexShrink: 0,
        cursor: 'pointer',
      }}
    >
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );
};

export default SelectArrow;
