import schema from 'schm';
import translate from 'schm-translate';

const geopointPrediction = schema(
  {
    id: String,
    name: String,
    geopoint: Object,
    plusCode: Object,
    addressComponents: [Object],
    formattedAddress: String,
    typeof: 'geopointPrediction',
  },
  translate({
    id: 'place_id',
    name: 'description',
    geopoint: 'geopoint',
    plusCode: 'plus_code',
    addressComponents: 'address_components',
    formattedAddress: 'formatted_address',
  }),
);

export default geopointPrediction;
