import moment from 'moment';
import { createReducer } from 'redux-act';
import { ETA_TYPES, RequestStatus } from '../../enums';
import * as action from '../actions/eta.actions';

export const initialState = {
  requestedArrivalTime: moment().toISOString(),
  requestedAppointmentTime: null,
  isDisabled: true,
  status: RequestStatus.CREATED,
  pickupTime: null,
  unitId: '',
  showEtaProgress: false,
  pollInProgress: false,
  pollInProgressReason: '',
  scheduled: false,
  scheduleId: null,
  rejectReason: '',
  taskTime: '',
  totalDrive: 0,
  ETAWasAcceptedState: false,
  offeredEtas: [],
  offers: [],
  rejectedEtas: [],
  etaPayload: {},
  etaType: ETA_TYPES.PICKUP,
  timezone: '',
  enforcePromisedWindow: false,
  preferredTimeOfDay: 'Anytime',
};

const loadTimes = payload => {
  if (payload.status === RequestStatus.WILL_CALL) {
    return {
      requestedArrivalTime: initialState.requestedArrivalTime,
    };
  }

  return {
    requestedArrivalTime: payload.requestedArrivalTime,
    pickupTime: payload.requestedArrivalTime,
  };
};

const etaReducer = createReducer(
  {
    [action.setDateTime]: (state, payload) => {
      const newState = { ...state };
      return {
        ...newState,
        requestedArrivalTime: payload.toISOString(),
        pickupTime: payload.toISOString(),
      };
    },
    [action.setPreferredTimeOfDay]: (state, payload) => {
      const newState = { ...state };
      return {
        ...newState,
        preferredTimeOfDay: payload,
      };
    },
    [action.setAppmtTime]: (state, payload) => ({
      ...state,
      requestedAppointmentTime: payload.toISOString(),
    }),
    [action.setETAButtonDisabled]: (state, payload) => ({
      ...state,
      isDisabled: payload,
    }),
    [action.requestETASuccess]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.requestETAError]: (state, payload) => ({
      ...state,
      pollInProgressReason: payload,
    }),
    [action.acceptETASuccess]: state => ({
      ...state,
      ETAWasAcceptedState: false,
    }),
    [action.updateScheduleSuccess]: (state, payload) => ({
      ...state,
      ...{
        unitId: payload.unit,
        scheduled: true,
        scheduleId: payload.id,
        status: payload.status,
        pickupTime: payload.eta,
      },
    }),
    [action.requestETAinProgress]: (state, payload) => ({
      ...state,
      showEtaProgress: payload,
    }),
    [action.rejectETASuccess]: state => ({
      ...initialState,
      requestedArrivalTime: state.requestedArrivalTime,
      requestedAppointmentTime: state.requestedAppointmentTime,
      isDisabled: false,
      etaType: state.etaType,
      timezone: state.timezone,
      maxDateAllowed: state.maxDateAllowed,
      enforcePromisedWindow: state.enforcePromisedWindow,
    }),
    [action.turndownSuccess]: state => ({
      ...initialState,
      etaType: state.etaType,
      timezone: state.timezone,
      maxDateAllowed: state.maxDateAllowed,
      enforcePromisedWindow: state.enforcePromisedWindow,
    }),
    [action.loadRequestDetails]: (state, payload) => ({
      ...state,
      ...loadTimes(payload),
      requestedAppointmentTime: payload.requestedAppointmentTime,
      etaType: payload.requestedAppointmentTime ? ETA_TYPES.APPOINTMENT : ETA_TYPES.PICKUP,
    }),
    [action.setAutoETAFailure]: (state, payload) => {
      const newState = {
        ...state,
        ...payload,
        unitId: '',
        pickupTime: null,
        offeredEtas: [],
        offers: [],
      };

      return {
        ...newState,
        etaType: state.etaType,
      };
    },
    [action.clearPollInProgress]: state => ({
      ...state,
      showEtaProgress: false,
      pollInProgress: false,
      isDisabled: false,
    }),
    [action.setPollInProgress]: (state, payload) => ({
      ...state,
      pollInProgress: payload,
    }),
    [action.setETAWasAcceptedState]: state => ({
      ...state,
      eta: '',
      isDisabled: false,
      ETAWasAcceptedState: true,
      offeredEtas: [],
      offers: [],
    }),
    [action.resetETA]: state => ({
      ...state,
      ETAWasAcceptedState: false,
      unitId: '',
      offeredEtas: [],
      offers: [],
      pickupTime: null,
      isDisabled: false,
    }),
    [action.reserve]: (state, payload) => ({
      ...state,
      pickupTime: payload,
    }),
    [action.setEtaType]: (state, payload) => ({
      ...state,
      etaType: payload,
      requestedAppointmentTime: payload === ETA_TYPES.APPOINTMENT ? state.requestedArrivalTime : null,
      requestedArrivalTime: payload === ETA_TYPES.PICKUP ? state.requestedAppointmentTime : null,
      pickupTime: null,
    }),
    [action.getRequestedArrivalTimeSuccess]: (state, payload) => ({
      ...state,
      requestedArrivalTime: payload,
      pickupTime: payload,
    }),
    [action.setTimezone]: (state, payload) => ({
      ...state,
      timezone: payload,
    }),
    [action.resetETAtimes]: state => ({
      ...state,
      ...initialState,
      maxDateAllowed: state.maxDateAllowed,
      enforcePromisedWindow: state.enforcePromisedWindow,
    }),
    [action.setMaxDateAllowed]: (state, payload) => ({
      ...state,
      maxDateAllowed: payload instanceof Object ? payload.toISOString() : null,
    }),
    [action.setEnforcePromisedWindow]: (state, payload) => ({
      ...state,
      enforcePromisedWindow: payload,
    }),
  },
  initialState,
);

export default etaReducer;
