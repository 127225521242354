import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Label, Vertical } from './elements';
import RequestService from '../../../services/request.service';

function LinearDistanceFrame({ fromWaypoint, toWaypoint, variant, grow }) {
  const [distance, setDistance] = useState();

  async function getLinearDistance() {
    if (get(fromWaypoint, 'lat') && get(toWaypoint, 'lat')) {
      const response = await RequestService.getLinearDistance(fromWaypoint, toWaypoint);
      setDistance(response.mi);
    }
  }
  useEffect(
    () => {
      getLinearDistance();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [get(fromWaypoint, 'lat'), get(toWaypoint, 'lat')],
  );

  switch (variant) {
    case 'vertical':
      return (
        <>
          {distance && (
            <Vertical grow={grow}>
              {distance}
              mi
            </Vertical>
          )}
        </>
      );
    default:
      return (
        <>
          {distance && (
            <Label>
              Linear Mileage:&nbsp;
              {distance}
              &nbsp;mi
            </Label>
          )}
        </>
      );
  }
}

LinearDistanceFrame.propTypes = {
  fromWaypoint: PropTypes.shape(),
  toWaypoint: PropTypes.shape(),
  variant: PropTypes.string,
  grow: PropTypes.bool.isRequired,
};

LinearDistanceFrame.defaultProps = {
  variant: null,
  fromWaypoint: null,
  toWaypoint: null,
};

export default LinearDistanceFrame;
