import { get } from 'lodash';
import { hasPath, toLower } from 'ramda';
import { PatientType, USER_TYPES } from '../enums';
import { hasCareProvidedAtDesc } from './service.flag';

const LICENSE_TYPE_REQUIRING_MEDICARE_SUBQUESTIONS = 'Skilled Nursing Facility';

const requesterAgencyMnqRequired = state =>
  hasPath(['agency', 'selectedAgency', 'mnqRequired'], state) && state.agency.selectedAgency.mnqRequired;

const shouldDisplayQuestionnaireForUser = state =>
  window.ambulnzConfig.constants.MN_FOR_REQUESTER_USER_TYPE ||
  (!window.ambulnzConfig.constants.MN_FOR_REQUESTER_USER_TYPE && state.user.model.userType === USER_TYPES.DISPATCHER) ||
  requesterAgencyMnqRequired(state);

const isOfCorrectServiceType = state => +state.service.serviceId === +state.service.serviceTypes.BLS;

const isNotEmergencyCallType = service => {
  if (!service.callTypeOptions || service.callTypeOptions.length === 0) {
    return true;
  }

  const currentTransportReason = service.callTypeOptions.find(
    transportReason => transportReason.key === +service.callTypeId,
  );

  if (!currentTransportReason) {
    return true;
  }

  return !currentTransportReason.emergency;
};

const isOfCorrectPatientType = state => {
  const patientType = state.service.patientTypes.find(
    singlePatientType => singlePatientType.name === toLower(PatientType.Adult),
  );
  if (!patientType) {
    return false;
  }

  return state.service.patientTypeId === patientType.id;
};

const isFirstGetETA = state => !state.requests.request || !state.requests.request.id;
const hasNoCoverage = state => {
  const coverages = Object.values(state.infobox.coverages);

  if (!coverages.length) {
    return true;
  }

  const payers = coverages.filter(e => e && e.payer && e.payer.payerId);

  return !payers.length;
};

export const needsMedicalNecessityQuestionnaire = state =>
  window.ambulnzConfig.userSettings.isBillingEnabled &&
  (window.ambulnzConfig.constants.MEDICAL_NECESSITY_QUESTIONNAIRE_ACTIVE || requesterAgencyMnqRequired(state)) &&
  shouldDisplayQuestionnaireForUser(state) &&
  isOfCorrectServiceType(state) &&
  isOfCorrectPatientType(state) &&
  isNotEmergencyCallType(state.service) &&
  (isFirstGetETA(state) || hasNoCoverage(state)) &&
  !state.requests.isNonTransport &&
  !hasCareProvidedAtDesc(state.requests);

export const requireMedicareSubQuestions = state =>
  get(state.location.fromSelected, 'facilityFull.licenseTypeName') === LICENSE_TYPE_REQUIRING_MEDICARE_SUBQUESTIONS;
