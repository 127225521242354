import React from 'react';

export default function (props) {
  return (
    <svg
      className={props.className}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      aria-haspopup="true"
      id="SummaryMenuButton"
      fill="rgb(250, 250, 250)"
      onClick={props.onClick}
      style={props.style}
    >
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
    </svg>
  );
}
