import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withValidations from '../common/withValidations';
import Frame from './RequestingAgency.frame';

const validations = {
  requesterAgencyId: (value, props) => {
    if (!props.selectedAgency || !props.selectedAgency.name) {
      return 'Select a requesting agency';
    }
    return null;
  },
};

const RequestingAgency = props => {
  useEffect(
    () => {
      if (props.triggerValidation) props.validateAll(props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.triggerValidation],
  );
  useEffect(() => {
    props.setErrors(props.errors);
  });
  return <Frame {...props} />;
};

RequestingAgency.propTypes = {
  hasErrors: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  validateAll: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
};

export default withValidations(validations)(RequestingAgency);
