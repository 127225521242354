import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import EnumsService from '../../../services/orders/enums.service';
import * as enumsActions from '../../actions/orders/enums.actions';

export const fetchEnumsEpic = action$ =>
  action$.pipe(
    filter(enumsActions.fetchEnums.match),
    switchMap(() => from(EnumsService.getEnums()).pipe(map(response => enumsActions.fetchEnumsSuccess(response)))),
  );
