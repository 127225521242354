import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import Downshift from 'downshift';
import { useTranslation } from 'react-i18next';
import SuggestionsInput from '../common/SuggestionsInput';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import RequestingAgency from '../common/icons/RequestingAgency';
import { theme } from 'theme';

const RequestingAgencyFrame = props => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(Boolean(props.expanded));

  useEffect(() => {
    if (props.shouldCollapse) setExpanded(false);
  }, [props.shouldCollapse]);

  const handleChange = (query, downshiftState) => {
    if (
      props.changeRequiresNewETA &&
      downshiftState.type !== Downshift.stateChangeTypes.mouseUp &&
      downshiftState.type !== Downshift.stateChangeTypes.blurInput
    ) {
      props.keyDown();
      return;
    }

    props.handleChange(query, downshiftState);
  };

  const togglePanel = () => {
    if (expanded) {
      props.validateAll(props);
    }
    setExpanded(!expanded);
  };

  return (
    <AmbExpansionPanel
      icon={<RequestingAgency style={{ height: 18, width: 18, marginTop: 3, fill: theme.palette.icon.primary }} />}
      title={props.input || t('Requester Agency')}
      expanded={expanded}
      error={props.hasErrors}
      onTogglePanel={togglePanel}
      id="RequestingAgencyPanel"
    >
      <AmbGrid style={{ marginTop: 15, minWidth: '100%' }}>
        <SuggestionsInput
          inputValue={props.input}
          selectedItem={props.selectedAgency}
          onInputValueChange={handleChange}
          items={props.agencies}
          inputId="request-agency-input"
          inputPlaceholder={t('Requesting Agency')}
          required
          autoFocus={props.autoFocus}
          loading={props.searchAgenciesInProgress}
          error={!!props.errors.requesterAgencyId}
          name="requesterAgencyId"
          disabled={props.disabled || null}
        />
      </AmbGrid>
    </AmbExpansionPanel>
  );
};

RequestingAgencyFrame.propTypes = {
  input: PropTypes.string.isRequired,
  selectedAgency: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  shouldCollapse: PropTypes.bool.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  searchAgenciesInProgress: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  autoFocus: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  keyDown: PropTypes.func.isRequired,
  validateAll: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default RequestingAgencyFrame;
