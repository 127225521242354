import { createReducer } from 'redux-act';
import initialState from './initialState';
import { reducer as addEmailRecipient } from './addEmailRecipient';
import { reducer as deleteEmailRecipient } from './deleteEmailRecipient';
import { reducer as addPhoneRecipient } from './addPhoneRecipient';
import { reducer as deletePhoneRecipient } from './deletePhoneRecipient';
import { reducer as resetRecipients } from './resetRecipients';
import { reducer as loadRecipients } from './loadRecipients';
import { reducer as addLoadedRecipients } from './addLoadedRecipients';
import { reducer as deleteLoadedRecipient } from './deleteLoadedRecipient';
import { reducer as setSubmitRecipientsManualCheck } from './setSubmitRecipientsManualCheck';

const reducer = createReducer(
  {
    ...addEmailRecipient,
    ...addPhoneRecipient,
    ...deleteEmailRecipient,
    ...deletePhoneRecipient,
    ...resetRecipients,
    ...setSubmitRecipientsManualCheck,
    ...loadRecipients,
    ...addLoadedRecipients,
    ...deleteLoadedRecipient,
  },
  initialState,
);

export default reducer;
