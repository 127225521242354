import React from 'react';
import PropTypes from 'prop-types';
import AmbSelect from '../AmbSelect';
import AmbInputLabel from '../AmbInputLabel';
import AmbGrid from '../AmbGrid';
import { theme } from '../../../theme';

class AmbDropdown extends React.Component {
  handleClick = event => {
    const { value } = event.target;
    this.props.onSelect(value, event);
  };

  render() {
    const {
      options,
      inputId,
      placeholder,
      value,
      disabled,
      error,
      children,
      menuItemStyles,
      containerStyles,
      selectInputStyles,
    } = this.props;

    const selectStyles = {
      position: 'relative',
      zIndex: 10,
      width: 'calc(100% - 32px)',
      cursor: 'pointer',
      minWidth: 16,
      userSelect: 'none',
      padding: '6px 32px 7px 0',
      outline: 'none',
      MozAppearance: 'none',
      WebkitAppearance: 'none',
      font: 'inherit',
      fontSize: 13,
      border: 0,
      margin: 0,
      display: 'block',
      boxSizing: 'content-box',
      background: 'none',
      verticalAlign: 'middle',
      WebkitTapHighlightColor: 'transparent',
      '&:focus': {
        borderRadius: 0,
        background: 'rgba(0, 0, 0, .01)',
        outline: 'none',
      },
    };

    const inputLabelStyles = {
      ...theme.mixins.inputLabel,
    };

    const dropDownOpts = options.map(option => (
      <option
        key={option.key}
        style={menuItemStyles}
        value={option.value}
        name={option.name}
        disabled={option.disabled}
      >
        {option.name}
      </option>
    ));
    return (
      <AmbGrid style={{ flexDirection: 'column', margin: 0, padding: 0, flex: 1 }}>
        {placeholder && (
          <AmbInputLabel htmlFor={inputId} style={inputLabelStyles} shrink>
            {placeholder}
          </AmbInputLabel>
        )}
        <div style={containerStyles}>
          <AmbSelect
            defaultOption={children}
            options={dropDownOpts}
            id={inputId}
            value={value}
            onChange={this.handleClick}
            disabled={disabled || null}
            error={error}
            style={{ ...selectStyles, ...selectInputStyles }}
          />
        </div>
      </AmbGrid>
    );
  }
}

AmbDropdown.defaultProps = {
  value: '',
  placeholder: '',
  margin: 'none',
  disabled: false,
  error: false,
  menuItemStyles: {},
  selectInputStyles: {},
  containerStyles: {},
};

AmbDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape()]),
    }),
  ).isRequired,
  inputId: PropTypes.string.isRequired,
  menuItemStyles: PropTypes.shape(),
  selectInputStyles: PropTypes.shape(),
  containerStyles: PropTypes.shape(),
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape()]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

export default AmbDropdown;
