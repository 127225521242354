import React from 'react';
import Selected from './Selected';
import { theme } from '../../../theme';

const buttonStyles = {
  defaultButton: {
    textTransform: 'none',
    border: '1px solid rgba(0,64,82,0.258)',
    minWidth: 25,
    fontSize: 14,
    fontWeight: 400,
    height: 32,
    margin: 0,
    padding: '2px 13px 0 10px',
    lineHeight: '1.5em',
    color: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid rgba(0, 64, 83, 0.64)',
      '& > svg': {
        opacity: '1 !important',
      },
    },
  },
  buttonSelected: {
    fontSize: 14,
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  oneButton: {
    borderRadius: '6px 6px 6px 6px',
  },
  firstButton: {
    borderRadius: '6px 0 0 6px',
  },
  middleButton: {
    borderRadius: '0',
  },
  lastButton: {
    borderRadius: '0 6px 6px 0',
  },
};

const AmbGroupToggle = ({ children, value, onChange, defaultSelectedKey }) => {
  const wrapperStyles = {
    paddingBottom: 12,
    paddingLeft: 5,
  };

  return (
    <div style={wrapperStyles}>
      <Selected defaultSelectedKey={defaultSelectedKey} selectedKey={value ? value.toString() : ''} onSelect={onChange}>
        {({ getSelectableProps, selectedKey }) =>
          React.Children.map(children, (child, i) => {
            const key = child.props.selectedkey;
            const getClasses = () => {
              const buttonClasses = {
                defaultButton: true,
                oneButton: !children.length,
                firstButton: children.length && i === 0,
                middleButton: i > 0 && i !== children.length - 1,
                lastButton: i === children.length - 1,
                buttonSelected: selectedKey === key,
              };
              return Object.entries(buttonClasses)
                .filter(([, value]) => value)
                .map(([className]) => className);
            };

            const classes = getClasses();

            const getStyles = classes.reduce((acc, className) => {
              if (buttonStyles[className]) {
                return { ...acc, ...buttonStyles[className] };
              }
              return acc;
            }, {});

            const childProps = {
              ...child.props,
              ...getSelectableProps(key, value),
              sx: getStyles,
            };

            const clonedChild = React.cloneElement(child, childProps);

            return clonedChild;
          })
        }
      </Selected>
    </div>
  );
};

export default AmbGroupToggle;
