import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Info from '../../components/common/icons/info/info-14px';

const StyledTooltipBox = styled.div`
  padding-top: 6px;
  width: 20px;
`;

const TooltipIcon = ({ on, disabled }) => (
  <>
    <StyledTooltipBox>
      <Info fill={on ? '#007a8c' : disabled ? '#6c798075' : '#004053'} />
    </StyledTooltipBox>
  </>
);
TooltipIcon.propTypes = {
  on: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

const CloseIcon = ({ onClick, style }) => (
  <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" onClick={onClick} style={style}>
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
  </svg>
);
CloseIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.shape(),
};

const AmbChip = ({
  value,
  text,
  isDisabled,
  onClick,
  onDelete,
  className,
  isOn,
  labelClassName,
  selectable,
  tooltip,
  style,
  isFrozen,
}) => {
  const spanStyles = {
    cursor: 'inherit',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    paddingLeft: 12,
    paddingRight: 12,
    pointerEvents: isDisabled || isFrozen ? 'none' : '',
    cursor: isDisabled || isFrozen ? 'none' : 'pointer',
  };

  const svgStyles = {
    color: 'rgba(0, 64, 83, 0.26)',
    cursor: 'pointer',
    margin: '0 4px 0 -8px',
    fill: 'currentColor',
    width: 24,
    height: 24,
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: 0,
    pointerEvents: isDisabled || isFrozen ? 'none' : '',
  };

  return (
    <div
      className={className}
      onClick={onClick}
      label={text}
      role="button"
      style={{
        pointerEvents: isDisabled || isFrozen ? 'none' : '',
        color: isDisabled ? '#919799' : '',
        backgroundColor: isDisabled ? '#eaeaea' : '',
        border: isDisabled ? '1px solid #919799' : '',
        ...style,
      }}
      value={isOn}
      tabIndex={0}
    >
      <span style={spanStyles} className={labelClassName}>
        {value}
      </span>
      {tooltip ? (
        <TooltipIcon disabled={isDisabled} on={isOn} />
      ) : selectable ? (
        ''
      ) : (
        <CloseIcon onClick={onDelete} style={svgStyles} />
      )}
    </div>
  );
};

AmbChip.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  className: PropTypes.string,
  isOn: PropTypes.bool,
  labelStyles: PropTypes.string,
  selectable: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default AmbChip;
