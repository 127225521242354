import { createReducer } from 'redux-act';
import * as action from '../actions/init.actions';

const initialState = {
  online: true,
};

const reducer = createReducer(
  {
    [action.online]: state => ({
      ...state,
      online: true,
    }),
    [action.offline]: state => ({
      ...state,
      online: false,
    }),
  },
  initialState,
);

export default reducer;
