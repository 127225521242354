import { createAction } from '@reduxjs/toolkit';
import parseRecipients from '../../../parsers/recipient.parser';
import parseDigits from '../../../parsers/digit.parser';

export const addPhoneRecipient = createAction('[RequestShare] addPhoneRecipient');

export const reducer = {
  [addPhoneRecipient]: (state, recipients) => ({
    ...state,
    phoneRecipients: [...state.phoneRecipients, ...parseRecipients(recipients).map(r => parseDigits(r.trim()))],
  }),
};
