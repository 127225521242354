import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 51.1 24" {...props}>
      <title>CCT</title>
      <path
        style={{ fill: '#1ec2d8' }}
        d="M2.08,0H36.9a3.41,3.41,0,0,1,2.25,1.21A3.65,3.65,0,0,1,40,3.47.48.48,0,0,1,39.5,4H38.11l6.5,8s2.77.87,4.07,1.13a1.66,1.66,0,0,1,1.39,1.38l.78,4H45.13a3.86,3.86,0,0,0-2.68-1.56A4,4,0,0,0,39.07,19H10.34S8.92,16.9,7.1,16.9A3.65,3.65,0,0,0,3.86,19H.43V1.48A1.43,1.43,0,0,1,2.08,0Z"
        transform="translate(0 0)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M22.61.78a5.69,5.69,0,0,0-5.56,5.09,5.88,5.88,0,0,1,3.11,1.42v-1a2.38,2.38,0,0,1,.76-1.7,2.73,2.73,0,0,1,1.69-.66,2.48,2.48,0,0,1,2.45,2.64v8.58H22V12.28a5.8,5.8,0,0,0-5.84-5.75,5.87,5.87,0,0,0-5.84,5.84A5.79,5.79,0,0,0,16,18.22h2.07V15.11h-2a2.74,2.74,0,1,1,2.73-2.83v5.94h9.43V6.53A5.64,5.64,0,0,0,22.61.78"
          transform="translate(0 0)"
        />
      </g>
      <path
        style={{ fill: '#050505' }}
        d="M1,19H39.07l-.35,1.47H.61A.59.59,0,0,1,0,19.84v-.26A.59.59,0,0,1,.61,19H1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#71ccdd' }}
        d="M39.59,20.45v1.38H3.72A2.93,2.93,0,0,1,1,20.45Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#050505' }} cx="7.1" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="7.1" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="7.1" cy="20.45" r="1.65" />
      <path
        style={{ fill: '#2b242d' }}
        d="M45.13,22.09h4.68c1.21,0,1.29-2.14,1.29-2.34s-.25-1.29-.25-1.29H45.13Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#5f5f61' }} cx="7.1" cy="20.45" r="1.21" />
      <circle style={{ fill: '#050505' }} cx="42.27" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="42.27" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="42.27" cy="20.45" r="1.65" />
      <circle style={{ fill: '#5f5f61' }} cx="42.27" cy="20.45" r="1.21" />
      <path
        style={{ fill: '#ebebed' }}
        d="M35.78,13.35h0a.23.23,0,0,0-.26-.26h-.87a.23.23,0,0,0-.26.26h0a.23.23,0,0,0,.26.26h.87C35.69,13.61,35.86,13.52,35.78,13.35Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M36.9,4.51a1.89,1.89,0,0,1,1.56.61l4.85,6.23L33.61,10V4.51Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ededef' }}
        d="M48,12.92l.82,1.75,1.4.39-.11-.59a1.66,1.66,0,0,0-1.39-1.38Z"
        transform="translate(0 0)"
      />
      <rect style={{ fill: '#ededef' }} x="45.3" y="16.12" width="1.56" height="1.21" />
      <path
        d="M11.42,15.56A5.3,5.3,0,1,1,11.42,5,5,5,0,0,1,15,6.34a.55.55,0,0,1,.18.37A.53.53,0,0,1,15,7.1L14.15,8a.45.45,0,0,1-.35.16A.54.54,0,0,1,13.44,8a3,3,0,0,0-2-.75,2.91,2.91,0,0,0-2.87,3,2.91,2.91,0,0,0,2.89,3,3,3,0,0,0,2-.74.52.52,0,0,1,.69,0l.87.9a.44.44,0,0,1,.14.32.53.53,0,0,1-.16.41A5,5,0,0,1,11.42,15.56Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M11.42,5.22a4.71,4.71,0,0,1,3.39,1.31.26.26,0,0,1,0,.39L14,7.8a.22.22,0,0,1-.17.09.26.26,0,0,1-.18-.09A3.29,3.29,0,0,0,11.47,7a3.17,3.17,0,0,0-3.13,3.26,3.17,3.17,0,0,0,3.14,3.23,3.39,3.39,0,0,0,2.14-.79.27.27,0,0,1,.18-.07.21.21,0,0,1,.17.07l.87.89a.27.27,0,0,1,0,.38,4.81,4.81,0,0,1-3.41,1.36,5,5,0,1,1,0-10.09m0-.5a5.55,5.55,0,1,0,0,11.09,5.31,5.31,0,0,0,3.76-1.5.8.8,0,0,0,.23-.6.67.67,0,0,0-.24-.51l-.84-.87a.75.75,0,0,0-.53-.22.78.78,0,0,0-.51.19,2.86,2.86,0,0,1-1.81.67,2.66,2.66,0,0,1-2.64-2.73,2.66,2.66,0,0,1,2.63-2.76,2.76,2.76,0,0,1,1.82.7.78.78,0,0,0,.51.21.71.71,0,0,0,.55-.26l.84-.86a.82.82,0,0,0,.22-.56.79.79,0,0,0-.27-.56,5.23,5.23,0,0,0-3.72-1.43Z"
        transform="translate(0 0)"
      />
      <path
        d="M21,15.56A5.3,5.3,0,1,1,21,5a5,5,0,0,1,3.56,1.37.5.5,0,0,1,.17.37.52.52,0,0,1-.15.39L23.73,8a.48.48,0,0,1-.35.16A.52.52,0,0,1,23,8a3,3,0,0,0-2-.75,2.91,2.91,0,0,0-2.87,3,2.9,2.9,0,0,0,2.88,3,3.08,3.08,0,0,0,2-.74.5.5,0,0,1,.34-.12.48.48,0,0,1,.34.14l.88.9a.48.48,0,0,1,.14.32.62.62,0,0,1-.16.41A5,5,0,0,1,21,15.56Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M21,5.22a4.73,4.73,0,0,1,3.39,1.31.26.26,0,0,1,0,.39l-.85.88a.22.22,0,0,1-.17.09.24.24,0,0,1-.18-.09A3.32,3.32,0,0,0,21,7a3.17,3.17,0,0,0-3.12,3.26,3.16,3.16,0,0,0,3.13,3.23,3.39,3.39,0,0,0,2.15-.79.26.26,0,0,1,.18-.07.23.23,0,0,1,.17.07l.87.89a.27.27,0,0,1,0,.38A4.77,4.77,0,0,1,21,15.31,5,5,0,1,1,21,5.22m0-.5a5.55,5.55,0,1,0,0,11.09,5.28,5.28,0,0,0,3.75-1.5.81.81,0,0,0,.24-.6.72.72,0,0,0-.25-.51l-.83-.87a.77.77,0,0,0-.53-.22.78.78,0,0,0-.51.19,2.88,2.88,0,0,1-1.82.67,2.66,2.66,0,0,1-2.63-2.73A2.66,2.66,0,0,1,21,7.48a2.79,2.79,0,0,1,1.83.7.74.74,0,0,0,.51.21.69.69,0,0,0,.54-.26l.84-.86A.74.74,0,0,0,25,6.71a.78.78,0,0,0-.26-.56A5.25,5.25,0,0,0,21,4.72Z"
        transform="translate(0 0)"
      />
      <path
        d="M28.19,15.42a.53.53,0,0,1-.52-.52V7.3H25.78a.53.53,0,0,1-.52-.52V5.63a.53.53,0,0,1,.52-.52H31.9a.52.52,0,0,1,.52.52V6.78a.52.52,0,0,1-.52.52H30v7.6a.53.53,0,0,1-.52.52Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M31.9,5.36a.27.27,0,0,1,.27.27V6.78a.27.27,0,0,1-.27.27H29.76V14.9a.28.28,0,0,1-.27.27h-1.3a.28.28,0,0,1-.27-.27V7.05H25.78a.28.28,0,0,1-.27-.27V5.63a.28.28,0,0,1,.27-.27H31.9m0-.5H25.78a.78.78,0,0,0-.77.77V6.78a.78.78,0,0,0,.77.77h1.64V14.9a.78.78,0,0,0,.77.77h1.3a.78.78,0,0,0,.77-.77V7.55H31.9a.78.78,0,0,0,.77-.77V5.63a.78.78,0,0,0-.77-.77Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
