import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../common/AmbGrid';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import Attachments from '../common/icons/Attachments';
import AmbFileInput from '../common/AmbFileInput';
import { theme } from '../../theme';

const PatientDocuments = ({ title, onChange, onRemove, disabled, deletable, attachments, attachmentTypes }) => {
  const [expanded, setExpanded] = useState(false);

  const togglePanel = () => {
    setExpanded(!expanded);
  };

  return (
    <AmbExpansionPanel
      icon={<Attachments style={{ height: 16, width: 16, marginTop: 2, fill: theme.palette.icon.primary }} />}
      title={title}
      expanded={expanded}
      onTogglePanel={togglePanel}
      id="PatientDocuments"
    >
      <div style={{ paddingTop: 5, width: '100%' }}>
        <AmbGrid style={{ minWidth: '100%' }}>
          <AmbFileInput
            onChange={onChange}
            onRemove={onRemove}
            buttonText="Upload Facesheet"
            disabled={disabled || null}
            deletable={deletable}
            fileTypes={attachmentTypes}
            attachments={attachments}
          />
        </AmbGrid>
      </div>
    </AmbExpansionPanel>
  );
};

PatientDocuments.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  deletable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default PatientDocuments;
