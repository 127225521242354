import { createReducer } from 'redux-act';
import { asyncUnassignTripFromProviderRequest, unassignTripSuccessfullyDone, unassignTripHasFailed } from './actions';

const initialState = {
  loading: false,
  error: false,
};

const unAssignReducer = createReducer(
  {
    [unassignTripSuccessfullyDone]: state => ({
      ...state,
      error: false,
      loading: false,
    }),
    [unassignTripHasFailed]: state => ({
      ...state,
      error: true,
      loading: false,
    }),
    [asyncUnassignTripFromProviderRequest]: state => ({
      ...state,
      loading: true,
      error: false,
    }),
  },
  initialState,
);

export default unAssignReducer;
