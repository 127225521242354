import withValidations from '../common/withValidations';
import styledFrame from './ConfirmPickupTime.frame';

const validations = {
  updateTypeId: value => {
    if (value === 0) {
      return 'Must select a reason';
    }
    return null;
  },
  firstName: value => {
    if (value.length === 0) {
      return 'Must have a first name';
    }
    return null;
  },
  lastName: value => {
    if (value.length === 0) {
      return 'Must have a last name';
    }
    return null;
  },
  phone: value => {
    if (value.length === 0) {
      return 'Must have a phone number';
    }
    if (value.trim().length < 13) {
      return 'Must have all digits';
    }
    return null;
  },
  details: value => {
    if (value.length === 0) {
      return 'Must have details';
    }
    return null;
  },
};
export default withValidations(validations)(styledFrame);
