import React from 'react';

const Checkbox = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={props.class}
      style={{
        width: '1em',
        height: '1em',
        fontSize: '1.5rem',
        fill: 'currentColor',
        display: 'inline-block',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        userSelect: 'none',
        flexShrink: 0,
      }}
    >
      {props.checked ? (
        <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
      ) : (
        <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
      )}
    </svg>
  );
};

export default Checkbox;
