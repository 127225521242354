import * as requestActions from '../actions/requests.actions';
import * as routeActions from '../actions/route.actions';

const routeChangeMdl =
  ({ getState, dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (action.type !== routeActions.routeChange.match(action)) {
      return null;
    }

    const { path } = action.payload;
    if (path.includes('standing-order') && !path.includes('edit')) {
      dispatch(
        requestActions.roundTripChange({
          isRoundTrip: true,
          estimatedWaitForReturnMins: 240,
        }),
      );
    }
  };

export const middlewares = [routeChangeMdl];
export default middlewares;
