import * as mapActions from '../actions/map.actions';
import * as agencyActions from '../actions/agency.actions';
import * as willCallActions from '../actions/willCall.actions';
import * as etaActions from '../actions/eta.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as serviceActions from '../actions/service.actions';
import * as locationActions from '../actions/location.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as patientActions from '../actions/patient.actions';
import { etaRelevantInformation } from '../../messages';
import * as requestsActions from '../actions/requests.actions';
import { loadAttachments } from '../../components/PatientDocuments/redux/actions';

export const keyDownETAField =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (formFlowActions.keyDownETAField.match(action)) {
      if (!getState().requests.request.isCompleted) {
        dispatch(formFlowActions.openNewETADialog(true));
      }
    }
  };

export const closeNewETADialogMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    const confirmedNewETA = formFlowActions.closeNewETADialog.match(action) && action.payload;
    if (confirmedNewETA) {
      dispatch(formFlowActions.setChangeRequiresNewETA(false));
    }
    if (confirmedNewETA && getState().eta.pollInProgress) {
      dispatch(etaActions.clearPollInProgress());
    }
    if (confirmedNewETA && getState().formFlow.etaWasAccepted) {
      if (getState().offers.offers.some(offer => offer.offerStatus.name !== 'accepted')) {
        dispatch(formFlowActions.setScheduledMessage(etaRelevantInformation));
      }
      dispatch(etaActions.setETAWasAcceptedState());
      dispatch(formFlowActions.setETASuccess(false));
    } else if (confirmedNewETA) {
      dispatch(etaActions.rejectETASuccess());
      dispatch(formFlowActions.setETASuccess(false));
    }
  };

export const clearEtaFromEtaTypeToggle =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (formFlowActions.clearEtaFromEtaTypeToggle.match(action)) {
      dispatch(formFlowActions.setChangeRequiresNewETA(false));
      dispatch(etaActions.clearPollInProgress());
      dispatch(etaActions.rejectETASuccess());
      dispatch(formFlowActions.setETASuccess(false));
      dispatch(etaActions.unReserve());
    }
  };

const isTriggeredByUpperForm = type => {
  const actions = [
    agencyActions.fetchPlace.match,
    agencyActions.clearAgency.match,
    mapActions.setMapLocationSuccess.match,
    locationActions.clearPickupLocation.match,
    locationActions.clearDestinationLocation.match,
    locationActions.selectPickupLocationSuccess.match,
    locationActions.selectDestinationLocationSuccess.match,
    willCallActions.setWillCall.match,
    etaActions.acceptETASuccess.match,
    etaActions.requestETA.match,
    serviceActions.respiratoryTherapistChange.match,
    serviceActions.rtOptionChange.match,
    serviceActions.toggleRequiredAttendant.match,
    serviceActions.serviceTypeChange.match,
    serviceActions.callTypeChange.match,
    serviceActions.setTransportReasons.match,
    infoboxActions.loadRequestDetails.match,
  ];
  return actions.some(match => match(type));
};

const isTriggeredBySectionWithOwnAutoSave = type => {
  const actions = [
    infoboxActions.setNotes.match,
    infoboxActions.loadRequestDetails.match,
    serviceActions.callTypeChange.match,
    serviceActions.setTransportReasons.match,
    loadAttachments.match,
    infoboxActions.setPayerInfo.match,
    infoboxActions.setPayerActive.match,
    infoboxActions.setRequesterInfo.match,
    infoboxActions.setRequesterPhone.match,
    infoboxActions.setPatientDetails.match,
    infoboxActions.setBirthday.match,
    patientActions.setPatientInfo.match,
    patientActions.setPhone.match,
  ];
  return actions.some(match => match(type));
};

export const setValidMdl =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (formFlowActions.setValid.match(action)) {
      dispatch(formFlowActions.setTriggerValidation(false));
      dispatch(requestsActions.setTriggeredBy(action.payload.byType));
      if (
        !getState().formFlow.loadRequestInProgress &&
        action.payload.isValid &&
        ((!getState().formFlow.etaWasAccepted && !isTriggeredBySectionWithOwnAutoSave(action.payload.byType)) ||
          (getState().formFlow.etaWasAccepted && !isTriggeredByUpperForm(action.payload.byType)))
      ) {
        dispatch(formFlowActions.autoSave());
      }
    }
  };

export const setChangeRequiresNewETAMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (formFlowActions.setChangeRequiresNewETA.match(action) && action.payload) {
      dispatch(formFlowActions.setTriggerValidation(true));
    }
  };

export const setNonTransportDestination =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (locationActions.selectPickupLocation.match(action) && getState().requests.isNonTransport) {
      dispatch(locationActions.selectDestinationLocation(action.payload));
    }

    if (agencyActions.getPickupLocationSuccess.match(action) && getState().requests.isNonTransport) {
      dispatch(agencyActions.getDestinationLocationSuccess(action.payload));
    }
  };

export const formFlowMdl = [
  keyDownETAField,
  closeNewETADialogMdl,
  setValidMdl,
  setChangeRequiresNewETAMdl,
  clearEtaFromEtaTypeToggle,
  setNonTransportDestination,
];
