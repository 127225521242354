import React from 'react';
import styled from '@emotion/styled';
import img from '../../common/icons/AmbulnzbyDocGo.png';

const Container = styled.div`
  text-align: center;
  padding: 5px;
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const Logo = styled.img`
  width: 50%;
  margin-top: 20px;
`;

export const NoMap = () => (
  <Container>
    <Logo src={img} alt="Ambulnz by Docgo" />
    <Message>Map view is not supported in this browser version</Message>
  </Container>
);
