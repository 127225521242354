import openSocket from 'socket.io-client';
import * as socketActions from '../redux/actions/socket.actions';
import * as eligibilityActions from '../redux/actions/eligibility.actions';
import * as offerActions from '../redux/actions/offer.actions';
import { RelayRooms, RelayCommands } from '../enums';

class SocketService {
  init(store) {
    this.socket = openSocket(`${window.ambulnzConfig.relayUri}`);
    // this.attachListeners();
    this.store = store;
    this.defaultRoom = this.getAccountRoomName(store.getState().userAccount.selectedUserAccount);

    this.join(this.defaultRoom);
    this.subscribe();
  }

  join(room) {
    this.socket.emit(RelayCommands.JOIN_ROOM, { room });
  }

  leave(room) {
    this.socket.emit(RelayCommands.LEAVE_ROOM, { room });
  }

  joinDefaultRoom(room) {
    this.defaultRoom = room;
    this.join(this.defaultRoom);
  }

  leaveDefaultRoom() {
    if (!this.defaultRoom) {
      return;
    }

    this.leave(this.defaultRoom);
  }

  getAccountRoomName(account) {
    return `${RelayRooms.ACCOUNT_EVENTS}_${account.id}`;
  }

  attachListeners() {
    this.socket.on('error', response => console.log('error', response));
    this.socket.on('connect_error', response => console.log('connect_error', response));
    this.socket.on('reconnect_error', response => console.log('reconnect_error', response));
    this.socket.on('connect', response => console.log('connect', response));
    this.socket.on('connect_timeout', response => console.log('connect_timeout', response));
    this.socket.on('connecting', response => console.log('connecting', response));
    this.socket.on('reconnect_attempt', response => console.log('reconnect_attempt', response));
    this.socket.on('disconnect', response => console.log('disconnect', response));
    this.socket.on('connect_timeout', response => console.log('connect_timeout', response));
    this.socket.on(RelayCommands.JOIN_SUCCESSFUL, response => console.log('JOIN_SUCCESSFUL', response));
  }

  subscribe() {
    this.socket.on(RelayCommands.SCHEDULE_UPDATED, response =>
      this.store.dispatch(socketActions.scheduleUpdated(response)),
    );
    this.socket.on(RelayCommands.REQUEST_STATUS_UPDATE, response =>
      this.store.dispatch(socketActions.scheduleUpdated(response)),
    );
    this.socket.on(RelayCommands.REQUEST_LOCATION_UPDATED, response =>
      this.store.dispatch(socketActions.scheduleUpdated(response)),
    );
    this.socket.on(RelayCommands.ELIGIBILITY_REPORT_UPDATE_EVENT, response =>
      this.store.dispatch(eligibilityActions.socketResponseReceived(response)),
    );
    this.socket.on('offer.updated', response => this.store.dispatch(offerActions.getOfferSocketMessage(response)));

    this.socket.on('offer.created', response => {
      this.store.dispatch(offerActions.createOfferSuccess(response));
    });
  }

  joinEligibilityReportRoom(room, action) {
    this.join(room);
    this.socket.on(RelayCommands.SEND_ZIRMED_ELIGIBILITY_REPORT, response => {
      this.store.dispatch(action(response));
    });
  }
}

export default new SocketService();
