import { setTransportReasons, setTransportReasonsAndEquipmentIdsDictionary } from '../actions/service.actions';

export const setTransportReasonsAndEquipmentIdsDictionaryMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    if (setTransportReasons.match(action)) {
      const payload = action.payload;
      dispatch(setTransportReasonsAndEquipmentIdsDictionary(payload));
    }
    next(action);
  };
export const serviceMdl = [setTransportReasonsAndEquipmentIdsDictionaryMdl];
