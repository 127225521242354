import { filter, switchMap } from 'rxjs/operators';
import { applySelectedUserAccount } from '../../services/account.service';
import * as userAccountActions from '../actions/userAccount.actions';
import { errorPipe } from './epicsUtil';

export const applySelectedUserAccountEpic = (action$, store) =>
  action$.pipe(
    filter(userAccountActions.selectUserAccount.match),
    switchMap(() => applySelectedUserAccount(store.value.userAccount.selectedUserAccount)),
    errorPipe,
  );
