import { from as from$ } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { RequestStatus } from '../../enums';
import RequestService from '../../services/request.service';
import * as requestsActions from '../actions/requests.actions';
import { errorPipe } from './epicsUtil';

const getReturnRequestStatus = state => {
  if (state.requests.request.status === RequestStatus.WILL_CALL) {
    return { status: RequestStatus.WILL_CALL };
  }

  if (state.requests.request.status === RequestStatus.NEEDS_ASSIGNMENT) {
    return { status: RequestStatus.NEEDS_ASSIGNMENT };
  }

  return {};
};

export const updateReturnRequestOnWillCallChangeEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.updateRequestSuccess.match),
    filter(() => store.value.requests.request.returnRequestId && store.value.requests.request.isWillCallStatus),
    switchMap(() =>
      from$(
        RequestService.updateRequest(store.value.requests.request.returnRequestId, {
          ...getReturnRequestStatus(store.value),
          requestedArrivalTime: store.value.requests.request.returnTripArrivalTime(),
        }),
      ).pipe(
        map(response => requestsActions.updateReturnRequestSuccess(response)),
        errorPipe,
      ),
    ),
  );
