import { createReducer } from 'redux-act';
import * as action from '../actions/map.actions';
import * as parser from '../../parsers/map.parser';
import location from '../../schemas/location.schema';

const initialState = {
  location: {
    lat: 40.785091,
    lng: -73.968285,
    zoom: 9,
  },
  fromWaypoint: null,
  toWaypoint: null,
  pickupPlace: {
    id: null,
    locationGps: {},
    address: {},
    typeId: null,
    globalPlusCode: null,
    compoundPlusCode: null,
  },
  destinationPlace: {
    id: null,
    locationGps: {},
    address: {},
    typeId: null,
    globalPlusCode: null,
    compoundPlusCode: null,
  },
  isPickupResidence: false,
  pickupResidenceDisabled: false,
  ResidenceDisabled: false,
  isDestinationResidence: false,
  destinationResidenceDisabled: false,
  vehicleLocation: {},
};

const mapReducer = createReducer(
  {
    [action.setMapLocationByCurrentUser]: (state, payload) => {
      const coordsByCurrentUser = parser.getCoordsByCurrentUser(payload);

      if (coordsByCurrentUser) {
        return {
          ...state,
          location: coordsByCurrentUser,
        };
      }

      return { ...state };
    },
    [action.setMapLocationByNavigator]: (state, payload) => ({
      ...state,
      location: parser.getCoordsByNavigator(payload),
    }),
    [action.setMapPickupLocationByAgency]: (state, payload) => ({
      ...state,
      location: parser.getCoordsByAgency(payload),
      fromWaypoint: parser.getCoordsByAgency(payload),
      pickupPlace: location.parse(payload),
      pickupResidenceDisabled: true,
      isPickupResidence: false,
    }),
    [action.setMapDestinationLocationByAgency]: (state, payload) => ({
      ...state,
      location: parser.getCoordsByAgency(payload),
      toWaypoint: parser.getCoordsByAgency(payload),
      destinationPlace: location.parse(payload),
      destinationResidenceDisabled: true,
      isDestinationResidence: false,
    }),
    [action.setMapLocationByAddress]: (state, payload) => ({
      ...state,
      location: parser.getCoordsByAddress(payload),
    }),
    [action.setMapFromWaypoint]: (state, payload) => ({
      ...state,
      location: payload.locationGps,
      fromWaypoint: payload.locationGps,
      pickupPlace: {
        id: payload.id,
        locationGps: payload.locationGps,
        address: payload.address,
        typeId: payload.typeId,
        globalPlusCode: payload.globalPlusCode,
        compoundPlusCode: payload.compoundPlusCode,
      },
    }),
    [action.setResidence]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setResidenceDisabled]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setMapToWaypoint]: (state, payload) => ({
      ...state,
      location: payload.locationGps,
      toWaypoint: payload.locationGps,
      destinationPlace: {
        id: payload.id,
        locationGps: payload.locationGps,
        address: payload.address,
        typeId: payload.typeId,
        globalPlusCode: payload.globalPlusCode,
        compoundPlusCode: payload.compoundPlusCode,
      },
    }),
    [action.clearPickupPlace]: state => ({
      ...state,
      pickupPlace: null,
      fromWaypoint: null,
      pickupResidenceDisabled: false,
      isPickupResidence: false,
    }),
    [action.clearDestinationPlace]: state => ({
      ...state,
      destinationPlace: null,
      toWaypoint: null,
      isDestinationResidence: false,
      destinationResidenceDisabled: false,
    }),
    [action.asyncGetVehicleLocationSucces]: (state, { lat, lng }) => ({
      ...state,
      vehicleLocation: { lat, lng },
    }),
    [action.setVehicleLocation]: (state, { lat, lng }) => ({
      ...state,
      vehicleLocation: { lat, lng },
    }),
    [action.resetMap]: state => ({
      ...state,
      ...initialState,
    }),
  },
  initialState,
);

export default mapReducer;
