import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../../../theme';
import { drawerWidth } from '../../../../theme';

export default class AliasesFrame extends React.Component {
  getComma(index) {
    return index + 1 === this.props.aliases.length ? '' : ',';
  }
  getAliasName(alias, index) {
    const { inputValue } = this.props;
    const { name } = alias;
    const matchStart = name.toLowerCase().indexOf(inputValue.toLowerCase());

    if (matchStart === -1) {
      return (
        <span style={{ marginLeft: 5 }} key={name}>
          {name}
          {this.getComma(index)}
        </span>
      );
    }

    return (
      <span style={{ marginLeft: 5 }} key={name}>
        {name.substring(0, matchStart)}
        <span style={{ color: theme.palette.secondary.main }}>{name.substring(matchStart, inputValue.length)}</span>
        {name.substring(inputValue.length)}
        {this.getComma(index)}
      </span>
    );
  }

  render() {
    const { aliases } = this.props;

    if (!aliases || !aliases.length) {
      return null;
    }

    return (
      <span
        style={{
          width: drawerWidth - 100,
          marginLeft: -5,
          color: '#797d80',
          whiteSpace: 'nowrap',
          fontWeight: 400,
        }}
      >
        <span>
          <b>aka:</b>
        </span>
        {aliases.map((alias, index) => this.getAliasName(alias, index))}
      </span>
    );
  }
}

AliasesFrame.defaultProps = {
  aliases: [],
};

AliasesFrame.propTypes = {
  aliases: PropTypes.arrayOf(PropTypes.shape()),
  inputValue: PropTypes.string.isRequired,
};
