import React from 'react';

function BedBound(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g>
        <g>
          <path d="M4,13V7c0-0.6-0.4-1-1-1S2,6.4,2,7v6v2v3h2v-3h9v-2H4z" />
          <path d="M18,9h-4v6h6v3h2v-3v-1.5V13C22,10.8,20.2,9,18,9z" />
          <circle cx="6.5" cy="10.5" r="1.5" />
          <path d="M9,12h4V9h-1C10.3,9,9,10.3,9,12z" />
        </g>
      </g>
    </svg>
  );
}

export default BedBound;
