import moment from 'moment';
import { filter, map, switchMap } from 'rxjs/operators';
import { COMMENTS } from '../../enums';
import * as errorActions from '../actions/error.actions';
import * as requestsActions from '../actions/requests.actions';
import { errorPipe } from './epicsUtil';
import { updateComments } from './helpers/comment.helper';
import { cancelRequest, getSimilarRequests } from './helpers/request.helper';
import { getRequestId } from './updateRequestUtil';

export const getSimilarRequestsEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.asyncGetSimilarRequests.match),
    switchMap(() =>
      getSimilarRequests(getRequestId(store)).pipe(
        map(response => requestsActions.getSimilarRequestsSuccess(response)),
      ),
    ),
  );

export const asyncCancelSimilarRequestEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.asyncCancelSimilarRequest.match),
    switchMap(() =>
      cancelRequest(store, window.ambulnzConfig.cancelReasonTypes.DUPLICATE).pipe(
        map(response => requestsActions.asyncCancelSimilarRequestSuccess(response)),
      ),
    ),
  );

const createCancelCommentText = store => {
  const date = moment().format('MM/DD/YY HH:mm');
  const user = store.value.user.model;
  const userFullName = `${user.firstName} ${user.lastName}`;
  const trip = store.value.formFlow.similarRequest.id;

  return `Cancelled at ${date} by ${userFullName} because of duplication of trip number #${trip}`;
};

export const createCancelCommentEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.createCancelComment.match),
    switchMap(() =>
      updateComments(store, createCancelCommentText(store), COMMENTS.TYPES.CANCEL).pipe(
        map(payload => errorActions.onError(payload)),
        errorPipe,
      ),
    ),
  );
