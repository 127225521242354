import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AmbRadioInput from '../../components/common/AmbRadioInput';

const Red = styled.div`
  padding-bottom: 5px;
  color: red;
  font-size: 14px;
`;
const Title = styled.div`
  padding-top: 5px;
  padding-bottom: 8px;
  font-size: 14px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 5px 0;
  height: 40px;
`;

const SectionThree = ({ answer, dispatch, requestingAgencyPayer, medicaid }) => {
  const [checked, setChecked] = useState(null);

  const handleChange = e => {
    dispatch({ type: 'WHO_WILL_COVER', answer: e.target.value });
    setChecked(e.target.value);
  };
  return (
    <>
      <Red>
        {medicaid
          ? `Medicaid requires a Prior Authorization number. Please select a different second payer or provide an Auth to get a transport.`
          : `Patient does not meet medical necessity for medicare reimbursement.`}
      </Red>
      <Title>{`Who will cover the payment for this patient's transportation?`}</Title>
      <div>
        <form aria-label="WhoWillCover" name="WhoWillCover" value={answer}>
          {!medicaid && (
            <Label>
              <AmbRadioInput
                value="commercial PPO"
                onChange={handleChange}
                checked={checked === 'commercial PPO'}
                style={{ marginRight: 15, color: '#859DA7' }}
                inputPosition={{ left: -1 }}
              />
              Patient has commercial PPO Insurance which covers non-emergent transportation services
            </Label>
          )}
          <Label>
            <AmbRadioInput
              value="requesting agency payer"
              onChange={handleChange}
              checked={checked === 'requesting agency payer'}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            {`${requestingAgencyPayer.name} will cover`}
          </Label>
          {!medicaid && (
            <Label>
              <AmbRadioInput
                value="self pay"
                onChange={handleChange}
                checked={checked === 'self pay'}
                style={{ marginRight: 15, color: '#859DA7' }}
                inputPosition={{ left: -1 }}
              />
              Patient will cover
            </Label>
          )}
          <Label>
            <AmbRadioInput
              value="lower level of transport"
              onChange={handleChange}
              checked={checked === 'lower level of transport'}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            I will select a lower level of transport
          </Label>
          <Label>
            <AmbRadioInput
              value="other means"
              onChange={handleChange}
              checked={checked === 'other means'}
              style={{ marginRight: 15, color: '#859DA7' }}
              inputPosition={{ left: -1 }}
            />
            Patient will be transported by other means
          </Label>
        </form>
      </div>
    </>
  );
};

SectionThree.propTypes = {
  requestingAgencyPayer: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  answer: PropTypes.string.isRequired,
  medicaid: PropTypes.bool.isRequired,
};

export default SectionThree;
