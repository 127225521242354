import React from 'react';

function WheelChair(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g>
        <g>
          <path
            d="M11.58,5.22c0.81-0.16,1.34-0.95,1.18-1.76C12.6,2.65,11.81,2.12,11,2.28
				c-0.81,0.16-1.34,0.95-1.18,1.76C9.98,4.86,10.77,5.38,11.58,5.22z"
          />
          <path
            d="M20.13,17.49l-1.91-4.62c-0.16-0.38-0.53-0.62-0.92-0.62c0,0,0,0-0.01,0h-4.5v-2.4
				c0.94,0.61,2.03,0.94,3.17,0.94c0.55,0,1-0.45,1-1s-0.45-1-1-1c-1.02,0-1.99-0.4-2.71-1.12l-0.67-0.67
				c-0.26-0.44-0.74-0.74-1.29-0.74c-0.83,0-1.5,0.67-1.5,1.5v5c0,0.83,0.67,1.5,1.5,1.5c0.01,0,0.01,0,0.02,0c0,0,0.01,0,0.01,0
				h5.31l1.66,4c0.21,0.51,0.8,0.75,1.31,0.54C20.1,18.58,20.34,18,20.13,17.49z"
          />
          <path
            d="M15.63,17.63c-1.05,1.34-2.63,2.12-4.34,2.12c-3.03,0-5.5-2.47-5.5-5.5
				c0-1.88,0.95-3.62,2.54-4.64c0.47-0.3,0.6-0.92,0.3-1.38s-0.92-0.6-1.38-0.3c-2.17,1.39-3.46,3.75-3.46,6.32
				c0,4.14,3.36,7.5,7.5,7.5c2.32,0,4.48-1.05,5.91-2.88c0.34-0.44,0.26-1.06-0.17-1.4C16.59,17.12,15.97,17.2,15.63,17.63z"
          />
        </g>
      </g>
    </svg>
  );
}

export default WheelChair;
