import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Summary from './Summary.frame';
import * as toastActions from '../../redux/actions/toast.actions';
import { isCar, acceptedCarTrip } from '../../flags/service.flag';
import { displayCharges } from '../../flags/display.flag';
import { presentScheduleState } from './summaryPresenter';
import { dateFormat, timeFormatWithTimezone } from '../../helpers/dateFormatter';

class SummaryContainer extends React.Component {
  state = {
    active: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.scheduleState !== this.props.scheduleState) {
      this.setState({ active: true }, () => {
        setTimeout(() => this.setState({ active: false }), 1500);
      });
    }
  }

  render() {
    return <Summary {...this.props} {...this.state} />;
  }
}

SummaryContainer.propTypes = {
  scheduleState: PropTypes.string,
};

SummaryContainer.defaultProps = {
  scheduleState: '',
};

const countWarnings = ({ coverages }) =>
  Object.keys(coverages).reduce((acc, k) => coverages[k].warnings.length + acc, 0);

function getPickupTime({ eta: { pickupTime } }) {
  return pickupTime ? dateFormat(pickupTime) : '--';
}

function mapStateToProps(state) {
  return {
    pickupTime: getPickupTime(state),
    unitId: state.eta.unitId,
    request: state.requests.request,
    shareLink: state.requests.shareLink,
    scheduled: state.eta.scheduled,
    pollInProgress: state.eta.pollInProgress,
    isCar: isCar(state.service),
    isActive: state.requests.request.isActive,
    showPersonnel: isCar(state.service) || window.ambulnzConfig.constants.SHOW_AMBULNZ_PERSONNEL,
    acceptedCar: acceptedCarTrip(state),
    showStatusItem:
      (state.requests.request.needsAssignment || state.requests.request.isCancelled) && !state.offers.offers.length,
    ...state.formFlow,
    scheduleState: presentScheduleState(
      state.schedule.scheduleState,
      state.requests.request.statusName,
      state.service,
      state.requests.isNonTransport,
    ),
    warnings: countWarnings(state.infobox),
    etaTime: timeFormatWithTimezone(state.schedule.scheduleEta || state.eta.pickupTime),
    displayWindowEndAt: timeFormatWithTimezone(state.schedule.displayWindowEndAt),
    displayWindowStartAt: timeFormatWithTimezone(state.schedule.displayWindowStartAt),
    displayWindowDay: dateFormat(state.schedule.displayWindowStartAt, 'MMM D'),
    etaDate: moment(state.eta.requestedArrivalTime).format('MM/DD'),
    dropoffTime: state.schedule.atDropoffTime
      ? timeFormatWithTimezone(
          moment(state.schedule.atDropoffTime).add(
            window.ambulnzConfig.constants.APPOINTMENT_DEFAULT_TRANSFER_OF_CARE_SECS,
            'seconds',
          ),
        )
      : '--',
    dropoffDate: moment(state.schedule.atDropoffTime)
      .add(window.ambulnzConfig.constants.APPOINTMENT_DEFAULT_TRANSFER_OF_CARE_SECS, 'seconds')
      .format('MM/DD'),
    appointmentTime:
      state.eta.requestedAppointmentTime && timeFormatWithTimezone(moment(state.eta.requestedAppointmentTime)),
    appointmentDate: moment(state.eta.requestedAppointmentTime).format('MM/DD'),
    charges: state.requests.charges,
    showCharges: displayCharges(state),
    isNonTransport: state.requests.isNonTransport,
    offer: state.offers.offers[0],
    phone: state.supportPhone.phone,
  };
}

export default connect(mapStateToProps, { ...toastActions })(SummaryContainer);
