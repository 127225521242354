import { useEffect } from 'react';

function bindEvent(el, eventName, eventHandler) {
  if (el.addEventListener) {
    el.addEventListener(eventName, eventHandler, false);
  } else if (el.attachEvent) {
    el.attachEvent(`on${eventName}`, eventHandler);
  }
}

const useEventListener = (target, type, listener, ...options) => {
  useEffect(
    () => {
      const targetIsRef = target.hasOwnProperty('current');
      const currentTarget = targetIsRef ? target.current : target;

      if (currentTarget) {
        bindEvent(currentTarget, type, listener);
      }
      return () => {
        if (currentTarget) currentTarget.removeEventListener(type, listener, ...options);
      };
    },
    [target, type, listener, options],
  );
};

export default useEventListener;
