import { filter, map } from 'lodash';
import { isCar } from '../flags/service.flag';

const isPatientPresent = state =>
  state.infobox.patientFirstName &&
  state.infobox.patientLastName &&
  state.infobox.patientBirthday &&
  (!state.service.bariatric || (state.infobox.patientWeight && state.infobox.patientHeight));

const buildCoverage = (coordinationTypeId, payerId, auth, policy, policyActive, policyActiveAt, policyActivatorId) => ({
  coordinationTypeId,
  payerId,
  authorizationNumber: `{#${auth}#}`,
  policyNumber: `{#${policy}#}`,
  policyActive,
  policyActiveAt,
  policyActivatorId,
});

const parseCoverages = infobox => {
  const filteredCoverages = filter(infobox.coverages, coverage => coverage.payer && coverage.payer.payerId);

  return map(filteredCoverages, coverage =>
    buildCoverage(
      coverage.coordinationTypeId,
      coverage.payer.payerId,
      coverage.authorizationNumber,
      coverage.policyNumber,
      coverage.isActive,
      coverage.activeAt,
      coverage.activatorId,
    ),
  );
};

const parsePatient = state => ({
  firstName: state.infobox.patientFirstName,
  middleName: state.infobox.patientMiddleName,
  lastName: state.infobox.patientLastName,
  email: state.infobox.patientEmail,
  dob: state.infobox.patientBirthday,
  height: `${state.infobox.patientHeight}'${state.infobox.patientHeightInches}"`,
  weight: state.infobox.patientWeight,
  ssn: state.infobox.patientSsn,
  mrn: state.infobox.patientMRN,
  address1: state.patient.address1,
  address2: state.patient.address2,
  city: state.patient.city,
  state: state.patient.state,
  zip: state.patient.zip,
  phone: state.patient.phone,
});

const parsePatientAndCoverage = state => {
  if (!isPatientPresent(state)) {
    return {};
  }

  if (isCar(state.service) && !state.agency.uberCoverageRequired) {
    return {
      patient: parsePatient(state),
      coverages: [],
    };
  }

  return {
    patient: parsePatient(state),
    coverages: parseCoverages(state.infobox),
  };
};

export const parseInfoBox = state => ({
  requester: {
    firstName: state.infobox.requesterFirstName,
    lastName: state.infobox.requesterLastName,
    phone: state.infobox.requesterPhone,
    extension: state.infobox.requesterExtension,
  },
  patient: parsePatient(state),
  coverages: parseCoverages(state.infobox),
});

export const parseRooms = state => ({
  puRoomNumber: state.location.puRoomNumber,
  doRoomNumber: state.location.doRoomNumber,
});

export const parseInfoBoxForSubmit = state => ({
  requester: {
    firstName: state.infobox.requesterFirstName,
    lastName: state.infobox.requesterLastName,
    phone: state.infobox.requesterPhone,
    extension: state.infobox.requesterExtension,
  },
  ...parsePatientAndCoverage(state),
  additionalRequestData: parseRooms(state),
});

export const getNullCoverageFields = () => ({
  activatorId: null,
  activatorName: null,
  isActive: false,
  activeAt: null,
});

export default parseInfoBox;
