import React from 'react';

export default function (props) {
  const fill = props.fill || '#AFF0F9';
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g>
        <g>
          <g>
            <circle style={{ fill: '#FFFFFF' }} cx="8" cy="8" r="7.5" />
            <path
              style={{ fill: '#014053' }}
              d="M8,16c-4.4,0-8-3.5-8-8c0-4.4,3.5-8,8-8c4.4,0,8,3.5,8,8S12.4,16,8,16z M8,1.1C4.2,1.1,1,4.3,1,8s3.1,7,7,7
            s7-3.1,7-7S11.8,1.1,8,1.1z"
            />
          </g>
        </g>
        <g>
          <circle style={{ fill, transition: 'fill .4s ease' }} cx="8" cy="8" r="6" />
        </g>
      </g>
      <g>
        <path
          style={{ fill: '#014053' }}
          d="M7.1,4.6c0-0.7,0.5-1.2,1.2-1.2c0.7,0,1.2,0.5,1.2,1.2S9,5.8,8.3,5.8C7.6,5.8,7.1,5.2,7.1,4.6z"
        />
        <path
          style={{ fill: '#014053' }}
          d="M10,11.7c0.1,0.2-0.3,0.9-2,0.9c-0.9,0-1.5-0.7-1.4-1.7c0.2-1,0.5-2.2,0.5-3.2C7.1,6.8,6,7.2,6,6.9
        s0.4-0.2,0.9-0.2c0.4,0,1.3,0,1.9,0c0.1,0,0.2,0,0.3,0.2c0.1,0.2-0.7,4.1-0.7,4.5C8.6,12.5,9.9,11.5,10,11.7z"
        />
      </g>
    </svg>
  );
}
