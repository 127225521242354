import moment from 'moment';
import * as etaActions from '../../actions/eta.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as requestsActions from '../../actions/requests.actions';
import * as willCallActions from '../../actions/willCall.actions';
import * as infoboxActions from '../../actions/infobox.actions';
import * as errorActions from '../../actions/error.actions';
import { openProposedETAsModal } from '../../../components/ProposedETAModal/redux/openProposedETAsModal';
import { setProposedETAs } from '../../../components/ProposedETAModal/redux/setProposedETAs';
import { setSubmitRecipientsManualCheck } from '../../../components/RequestShare/redux/setSubmitRecipientsManualCheck';
import { appointmentTimeTooSoonResponse, parseETAs } from '../../../parsers/eta.parser';
import { captureMessage } from '../../../logger';
import { isCar } from '../../../flags/service.flag';
import { parseRequestType } from 'parsers/request.parser';

export const clickGetETAMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.clickGetETA.match(action)) {
      dispatch(etaActions.resetETA());

      if (getState().requests.willCall) {
        dispatch(willCallActions.setIsJustActivated(true));
        dispatch(willCallActions.setWillCall(false));
      }

      const state = getState();
      const { requestedArrivalTime } = state.eta;
      const selectedTransportReason = state.service.callTypeOptions.find(callType => callType.key === state.service.callTypeId);
      const offset = selectedTransportReason?.defaultFromNowPickupTimeInMins ||
        state.agency.requesterAgencyGroup.defaultTimeOffsetMinutes ||
        5;

      if (requestedArrivalTime && moment(requestedArrivalTime) < moment()) {
        dispatch(etaActions.setDateTime(moment().add(offset, 'minutes')));
      }

      if (isCar(state.service)) {
        dispatch(formFlowActions.setTriggerValidation(true));
      }

      dispatch(setSubmitRecipientsManualCheck(true));
      dispatch(etaActions.requestETAinProgress(true));
      dispatch(etaActions.requestETA());
    }
  };

export const clickSeeOtherETAsMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.clickSeeOtherETAs.match(action)) {
      dispatch(openProposedETAsModal(true));
    }
  };

export const asyncRequestETASuccessMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.asyncRequestETASuccess.match(action)) {
      const { eta, requests } = getState();

      dispatch(setProposedETAs(action.payload));
      dispatch(errorActions.onError(action.payload));

      if (eta.requestedAppointmentTime && moment(requests.request.requestedArrivalTime).isBefore(moment())) {
        return dispatch(etaActions.requestETASuccess(appointmentTimeTooSoonResponse()));
      }

      const { typeId } = parseRequestType(getState());
      const isTelehealth = typeId === window.ambulnzConfig.requestTypes.TELEHEALTH;
      const isParseMulti =
        getState().requests.isNonTransport && window.ambulnzConfig.userSettings.isMultidayTripPlanner && !isTelehealth;

      dispatch(etaActions.requestETASuccess(action.payload.error ? {} : parseETAs(action.payload, isParseMulti)));
    }
  };

export const asyncUpsertScheduleMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.asyncUpsertScheduleSuccess.match(action)) {
      dispatch(etaActions.updateScheduleSuccess(action.payload.schedule));
      dispatch(requestsActions.updateRequestSuccess(action.payload.request));
      dispatch(infoboxActions.submitPatientInfo());
      dispatch(etaActions.getETAFromRequest());
      dispatch(errorActions.onError(action.payload.schedule));
      dispatch(errorActions.onError(action.payload.request));
    }
  };

export const asyncCreateNotReservedMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.asyncCreateNotReservedSuccess.match(action)) {
      dispatch(requestsActions.createRequestSuccess(action.payload));
      dispatch(etaActions.getRequestedArrivalTimeSuccess(action.payload.requestedArrivalTime));
      dispatch(etaActions.getETAFromRequest());
      dispatch(errorActions.onError(action.payload));
    }
  };

export const requestETATimeout =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.requestETATimeout.match(action)) {
      captureMessage('Request Timed Out');
      if (getState().user.model.isDispatcher) {
        dispatch(
          etaActions.requestETAError(
            'Something went wrong while we tried to get an ETA. Please complete the request and try to Refresh the ETA.',
          ),
        );
      }
      dispatch(infoboxActions.submitPatientInfo());
      dispatch(etaActions.setPollInProgress(true));
      dispatch(etaActions.requestETAinProgress(false));
    }
  };

export const noLongerReserved =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.noLongerReserved.match(action)) {
      dispatch(etaActions.resetETA());
    }
  };

export default [
  clickGetETAMdl,
  clickSeeOtherETAsMdl,
  asyncRequestETASuccessMdl,
  requestETATimeout,
  asyncUpsertScheduleMdl,
  asyncCreateNotReservedMdl,
  noLongerReserved,
];
