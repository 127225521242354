import { from as from$ } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';

import getEta from '../../../adapters/getEta.adapter';
import * as etaActions from '../../../redux/actions/eta.actions';
import { errorPipe } from '../../../redux/epics/epicsUtil';
import { getRequestId } from '../../../redux/epics/updateRequestUtil';

export const getETAFromRequestEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.getETAFromRequest.match),
    filter(() => !store.value.formFlow.noAutoEtaRequest),
    map(() => etaActions.getAutoETA()),
  );

export const getAutoETAEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.getAutoETA.match),
    mergeMap(() =>
      from$(getEta(getRequestId(store), store.value)).pipe(
        map(response => etaActions.asyncRequestETASuccess(response)),
        takeUntil(
          action$.pipe(
            filter(
              action => etaActions.asyncRequestETASuccess.match(action) || etaActions.requestETATimeout.match(action),
            ),
          ),
        ),
        errorPipe,
      ),
    ),
  );
