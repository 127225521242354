import { createReducer } from 'redux-act';
import initialState from './initialState';
import { reducer as setProposedETAs } from './setProposedETAs';
import { reducer as openProposedETAsModal } from './openProposedETAsModal';

const reducer = createReducer(
  {
    ...setProposedETAs,
    ...openProposedETAsModal,
  },
  initialState,
);

export default reducer;
