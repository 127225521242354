export const arrToObj = arr => {
  if (!arr || arr.length === 0) {
    return {};
  }

  const reducer = (obj, item) => {
    obj[item.name] = item.id;
    return obj;
  };
  return arr.reduce(reducer, {});
};
