import { userCanExternalPatientSearch } from './patientSearch.flag';
import { disabledByPcrStatus } from './prebilling.flag';
import { acceptedCarTrip, isCar } from './service.flag';

const willCallActivationRequired = state => state.requests.request.id && state.requests.request.isWillCallStatus;

export const requestingAgencyDisabled = state => acceptedCarTrip(state);

export const pickupDestinationDisabled = state => acceptedCarTrip(state);

export const serviceLevelDisabled = state => state.requests.request.invalidForGetETA || acceptedCarTrip(state);

export const isWaitAndReturnDisabled = state => state.requests.request.invalidForGetETA || acceptedCarTrip(state);

export const isCrewMustWaitDisabled = state =>
  !state.requests.isRoundTrip || state.requests.request.invalidForGetETA || isCar(state.service);

export const willCallDisabled = state =>
  willCallActivationRequired(state) ||
  state.requests.request.invalidForGetETA ||
  state.eta.showEtaProgress ||
  acceptedCarTrip(state);

export const etaTypeDisabled = state =>
  state.requests.request.invalidForGetETA || state.eta.showEtaProgress || acceptedCarTrip(state);

export const timeDateDisabled = state => state.requests.request.invalidForGetETA || state.eta.showEtaProgress;

export const patientDocumentsDisabled = () => false;

export const islockedByBillerVerified = ({ requests: { request, isBillerVerified } }) => {
  const locked = request.isCompleted || request.isCancelled;
  return locked && isBillerVerified;
};

export const islockedByPcr = state => disabledByPcrStatus(state);

const cannotUpdatePatientDemographics = state =>
  Boolean(userCanExternalPatientSearch(state) && state.agency.requesterAgencyGroup.cannotUpdatePatientDemographics);

const patientDetailsFlags = state => {
  const {
    requests: { isBillerVerified },
  } = state;

  return acceptedCarTrip(state) || cannotUpdatePatientDemographics(state) || !!isBillerVerified;
};

export const patientDetailsDisabled = state => patientDetailsFlags(state) || window.ambulnzConfig.isAnExternalApp;

export const patientAdditionalDetailsDisabled = state => {
  const {
    requests: { isBillerVerified },
  } = state;

  return (
    (islockedByBillerVerified(state) && islockedByPcr(state)) ||
    cannotUpdatePatientDemographics(state) ||
    window.ambulnzConfig.isAnExternalApp ||
    !!isBillerVerified
  );
};

export const mrnDisabled = state => {
  const {
    requests: { isBillerVerified },
  } = state;

  return acceptedCarTrip(state) || window.ambulnzConfig.isAnExternalApp || !!isBillerVerified;
};

export const patientPhoneDisabled = state => acceptedCarTrip(state);

export const patientCoverageDisabled = ({ requests: { isBillerVerified } }) => !!isBillerVerified;

export const chiefComplaintDisabled = () => false;

export const notesDisabled = () => false;

export const requestShareDisabled = () => false;

export const pickupDepartmentsDisabled = state =>
  !state.location.pickupDepartments.length || (islockedByBillerVerified(state) && islockedByPcr(state));

export const destinationDepartmentsDisabled = state =>
  !state.location.destinationDepartments.length || (islockedByBillerVerified(state) && islockedByPcr(state));

export const otherOptionOfChiefComplaintDisabled = state =>
  (islockedByBillerVerified(state) && islockedByPcr(state)) || state.requests.request.isCompleted;
