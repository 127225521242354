import { filter, map, switchMap } from 'rxjs/operators';
import { addEmailRecipient } from '../../components/RequestShare/redux/addEmailRecipient';
import { addLoadedRecipients } from '../../components/RequestShare/redux/addLoadedRecipients';
import { addPhoneRecipient } from '../../components/RequestShare/redux/addPhoneRecipient';
import { createRequestSuccess } from '../actions/requests.actions';
import { errorPipe } from './epicsUtil';
import { createEmailSubscriptionsHelper, createSmsSubscriptionsHelper } from './helpers/requestShare.helper';
import { getRequestId } from './updateRequestUtil';

export const submitSmsRecipientsEpic = (action$, store) =>
  action$.pipe(
    filter(action => [addPhoneRecipient.match, createRequestSuccess.match].some(match => match(action))),
    filter(() => getRequestId(store)),
    switchMap(() =>
      createSmsSubscriptionsHelper(store).pipe(
        map(result => addLoadedRecipients(result)),
        errorPipe,
      ),
    ),
  );

export const submitEmailRecipientsEpic = (action$, store) =>
  action$.pipe(
    filter(action => [addEmailRecipient.match, createRequestSuccess.match].some(match => match(action))),
    filter(() => getRequestId(store)),
    switchMap(() =>
      createEmailSubscriptionsHelper(store).pipe(
        map(result => addLoadedRecipients(result)),
        errorPipe,
      ),
    ),
  );
