import { createReducer } from 'redux-act';
import * as action from '../actions/agency.actions';
import { PriorityType } from '../../enums';

export const initialState = {
  agencies: [],
  input: '',
  selectedAgency: '',
  requesterAgencyId: null,
  requesterAgencyGroup: {},
  searchAgenciesInProgress: false,
  autoFocus: true,
  triggerValidation: false,
  priorityType: PriorityType.DEFAULT,
  doNotSetCoverageFromPatientSearch: false,
  uberCoverageRequired: false,
};

const agencyReducer = createReducer(
  {
    [action.queryAgencies]: (state, payload) => ({
      ...state,
      input: payload,
    }),
    [action.fetchAgenciesSuccess]: (state, payload) => ({
      ...state,
      agencies: payload.map(i => ({
        requesterAgencyId: i.id,
        name: i.entity.name,
        payer: i.payers[0],
        priorityType: i.requesterAgencyPriorityType,
        requesterAgencyGroup: i.requesterAgencyGroup || {},
        ...(i.requesterOptions || {}),
        ...i,
      })),
    }),
    [action.fetchPlace]: (state, payload) => ({
      ...state,
      input: payload.name,
      selectedAgency: payload,
      ...payload,
    }),
    [action.setAgency]: (state, payload) => ({
      ...state,
      input: payload.name,
      selectedAgency: payload,
      ...payload,
    }),
    [action.clearAgency]: state => ({
      ...state,
      requesterAgencyId: null,
      input: '',
      selectedAgency: '',
    }),
    [action.setSearchAgenciesInProgress]: (state, payload) => ({
      ...state,
      searchAgenciesInProgress: payload,
    }),
    [action.setAutoFocus]: (state, payload) => ({
      ...state,
      autoFocus: payload,
    }),
    [action.setAgencyValidation]: (state, payload) => ({
      ...state,
      triggerValidation: payload,
    }),
    [action.resetAgency]: state => ({
      ...state,
      ...initialState,
    }),
  },
  initialState,
);

export default agencyReducer;
