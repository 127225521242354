import * as R from 'ramda';
import { combineLatest, concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import CallTypeService from '../../services/callTypeService';
import * as ProviderService from '../../services/provider.service';
import { getServiceTypes } from '../../services/serviceType.service';
import TransportReasonService from '../../services/transportReasonService';
import * as agencyActions from '../actions/agency.actions';
import * as errorActions from '../actions/error.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as requestsActions from '../actions/requests.actions';
import * as serviceActions from '../actions/service.actions';
import { errorPipe } from './epicsUtil';
import {
  updateCallType,
  updateLightsAndSirens,
  updateTransportReasons,
  updateTherapistEquipmentIds,
} from './helpers/request.helper';

const shouldFetchTransportReason = state =>
  !R.isEmpty(state.service.callTypeOptions) &&
  R.has('id', state.requests.request) &&
  !R.pluck('key', state.service.callTypeOptions).includes(state.requests.request.callTypeId);

export function autoSaveLightsAndSirensEpic(action$, store) {
  return action$.pipe(
    filter(serviceActions.asyncAutoSaveLightsAndSirens.match),
    switchMap(() => updateLightsAndSirens(store)),
    mergeMap(response =>
      concat$(of$(requestsActions.updateRequestSuccess(response)), of$(errorActions.onError(response))),
    ),
  );
}

export const autoSaveTransportReasonsEpic = (action$, store) =>
  action$.pipe(
    filter(serviceActions.autoSaveTransportReasons.match),
    switchMap(() =>
      updateTransportReasons(store).pipe(
        mergeMap(request =>
          concat$(
            of$(serviceActions.autoSaveTransportReasonsSuccess()),
            of$(formFlowActions.autoSaveInProgress(false)),
            of$(requestsActions.updateRequestSuccess(request)),
            of$(infoboxActions.submitPatientInfoSuccess(request.id)),
            of$(errorActions.onError(request)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const autoSaveTheraphistEquipmentIdsEpic = (action$, store) =>
  action$.pipe(
    filter(serviceActions.autoSaveTheraphistEquipmentIds.match),
    switchMap(() =>
      updateTherapistEquipmentIds(store).pipe(
        mergeMap(request =>
          concat$(
            of$(serviceActions.autoSaveTheraphistEquipmentIdsSuccess()),
            of$(formFlowActions.autoSaveInProgress(false)),
            of$(requestsActions.updateRequestSuccess(request)),
            of$(infoboxActions.submitPatientInfoSuccess(request.id)),
            of$(errorActions.onError(request)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const autoSaveCallTypeEpic = (action$, store) =>
  action$.pipe(
    filter(serviceActions.asyncAutoSaveCallType.match),
    switchMap(() =>
      updateCallType(store).pipe(
        mergeMap(request =>
          concat$(
            of$(serviceActions.asyncAutoSaveCallTypeSuccess()),
            of$(formFlowActions.autoSaveInProgress(false)),
            of$(requestsActions.updateRequestSuccess(request)),
            of$(infoboxActions.submitPatientInfoSuccess(request.id)),
            of$(errorActions.onError(request)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const fetchCallTypeEpic = (action$, store) =>
  action$.pipe(
    filter(serviceActions.fetchCallTypes.match),
    switchMap(() =>
      from$(CallTypeService.get(store.value.user.model.id)).pipe(
        map(result => serviceActions.fetchCallTypesSuccess(result)),
        errorPipe,
      ),
    ),
  );

export const fetchServiceTypeEpic = action$ =>
  action$.pipe(
    filter(serviceActions.fetchServiceTypes.match),
    switchMap(() =>
      from$(getServiceTypes()).pipe(
        map(result => serviceActions.fetchServiceTypesSuccess(result)),
        errorPipe,
      ),
    ),
  );

export const fetchProviderCapabilites = (action$, store) =>
  action$.pipe(
    filter(serviceActions.fetchProviderCapabilites.match),
    filter(() => !(store.value.user.model.isDara && store.value.agency.requesterAgencyId)),
    switchMap(() => of$(serviceActions.fetchProviderCapabilitesSuccess())),
  );

export const fetchProviderCapabilitesForDaraUser = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        agencyActions.setAgency.match,
        agencyActions.getPickupLocationSuccess.match,
        agencyActions.getDestinationLocationSuccess.match,
      ].some(match => match(action)),
    ),
    filter(() => store.value.user.model.isDara && store.value.agency.requesterAgencyId),
    switchMap(() =>
      from$(ProviderService.getProvidersForDaraUser(store.value.agency.requesterAgencyId)).pipe(
        map(result => serviceActions.fetchProviderCapabilitesSuccess(result)),
        errorPipe,
      ),
    ),
  );

export const fetchTransportReasonEpic = (action$, store) =>
  combineLatest([
    action$.pipe(filter(action => action.type === serviceActions.fetchCallTypesSuccess.type)),
    action$.pipe(filter(action => action.type === requestsActions.editRequestSuccess.type)),
    action$.pipe(filter(action => action.type === serviceActions.fetchTransportReason.type)),
  ]).pipe(
    map(data => data[2]),
    filter(() => shouldFetchTransportReason(store.value)),
    switchMap(() =>
      from$(TransportReasonService.get(store.value.requests.request.callTypeId)).pipe(
        map(result => serviceActions.fetchAndDisableTransportReasonSuccess(result)),
        errorPipe,
      ),
    ),
  );

const serviceTypeIdsWithoutEquipment = state => {
  const { Curbside, Livery, WAV } = state.serviceTypes;
  return [Curbside, Livery, WAV];
};

const mobilityIdsWithoutEquipment = state => {
  const { wheelchair } = state.mobilities;
  return [wheelchair];
};

const serviceLevelWithoutEquipmentAndPersonnel = (state, mobilityId, selectServiceTypeId) => {
  const isServiceTypeWithoutEquipment =
    serviceTypeIdsWithoutEquipment(state).includes(selectServiceTypeId) && !R.isNil(selectServiceTypeId);
  const isMobilityWithoutEquipment = mobilityIdsWithoutEquipment(state).includes(mobilityId);

  if (isMobilityWithoutEquipment) {
    return true;
  }

  if (isServiceTypeWithoutEquipment) {
    return true;
  }

  return false;
};

const buildForcedCapabilities = (
  mobilityId,
  selectPatientTypeId,
  selectServiceTypeId,
  therapistEquipmentIds,
  requirementIds,
  state,
) => {
  const { serviceLevelMap } = state;
  const isServiceLevelWithoutEquipmentAndPersonnel = serviceLevelWithoutEquipmentAndPersonnel(
    state,
    mobilityId,
    selectServiceTypeId,
  );
  const forcedCapabilities = serviceLevelMap.calcRequiredCapabilities(
    mobilityId,
    selectPatientTypeId,
    selectServiceTypeId,
    therapistEquipmentIds,
    requirementIds,
  );

  return {
    forcedCapabilities: isServiceLevelWithoutEquipmentAndPersonnel ? [] : forcedCapabilities,
    showRequirementWarning: false,
  };
};

export const serviceLevelChangeEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        serviceActions.mobilityChange.match,
        serviceActions.patientTypeChange.match,
        serviceActions.serviceTypeChange.match,
        serviceActions.equipmentChange.match,
        serviceActions.equipmentAsServiceTypeChange.match,
        serviceActions.personnelChange.match,
        serviceActions.loadRequestDetails.match,
        serviceActions.setTransportReasons.match,
      ].some(match => match(action)),
    ),
    switchMap(() => {
      const serviceState = store.value.service;
      return of$(
        serviceActions.forcedCapabilitiesChange(
          buildForcedCapabilities(
            serviceState.mobilityId,
            serviceState.patientTypeId,
            serviceState.serviceId,
            serviceState.therapistEquipmentIds,
            serviceState.requirementIds,
            serviceState,
          ),
        ),
      );
    }),
  );

export const forcedCapabilitiesChangeEpic = (action$, store) =>
  action$.pipe(
    filter(serviceActions.forcedCapabilitiesChange.match),
    switchMap(() => {
      const serviceState = store.value.service;
      const forcedRequirements = serviceState.forcedCapabilities.filter(
        forcedCapability =>
          forcedCapability.capability.type === 'Requirement' &&
          !serviceState.requirementIds.includes(forcedCapability.capability.id),
      );

      return from$(
        forcedRequirements.map(forcedRequirement =>
          serviceActions.personnelChange({ id: forcedRequirement.capability.id, checked: true }),
        ),
      );
    }),
  );
