import moment from 'moment';
import { from as from$ } from 'rxjs';
import PatientService from '../../../services/patient.service';
import { parseInfoBox } from '../../../parsers/infobox.parser';
import { requestId } from './request.helper';

export const parsePatient = state => parseInfoBox(state).patient;

export const updatePatient = store => from$(PatientService.updatePatient(requestId(store), store.value));

export const searchPatient = state =>
  from$(
    PatientService.searchPatient(
      parseInfoBox(state).patient,
      state.agency.selectedAgency.requesterAgencyId,
      state.infobox.patientMRN,
      getExternalIdType(state.userAccount.selectedUserAccount.id),
    ),
  );

export const parseLastSearch = state =>
  JSON.stringify({
    patient: parseInfoBox(state).patient,
    requesterAgencyId: state.agency.selectedAgency.requesterAgencyId,
    patientMRN: state.infobox.patientMRN,
  });

export const getPatient = store => from$(PatientService.getPatient(requestId(store)));

export const isPatientPresent = state =>
  state.infobox.patientFirstName &&
  state.infobox.patientLastName &&
  state.infobox.patientBirthday &&
  (!state.service.bariatric || (state.infobox.patientWeight && state.infobox.patientHeight));

export const isSamePatient = (patient1, patient2, skipMiddleNameCheck = true) => {
  if (patient1 && patient2) {
    return (
      patient1.firstName.toLowerCase() === patient2.firstName.toLowerCase() &&
      patient1.lastName.toLowerCase() === patient2.lastName.toLowerCase() &&
      moment(patient1.dob).isSame(patient2.dob)
    );
  }
  return false;
};

export const getProfile = (accountId, patient, requesterAgencyId) =>
  from$(PatientService.getProfile(patient, requesterAgencyId, getExternalIdType(accountId)));

export const getExternalIdType = accountId => {
  const onDemandAccountIds = window.ambulnzConfig?.constants?.ON_DEMAND_ACCOUNT_IDS;
  if (onDemandAccountIds) {
    return JSON.parse(onDemandAccountIds).includes(accountId) ? 'athena' : null;
  } else {
    return null;
  }
};
