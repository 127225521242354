import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, mergeMap, switchMap, tap } from 'rxjs/operators';

import { isCar } from '../../../flags/service.flag';
import * as errorActions from '../../../redux/actions/error.actions';
import * as etaActions from '../../../redux/actions/eta.actions';
import * as formFlowActions from '../../../redux/actions/formFlow.actions';
import * as pickupTimeChangeActions from '../../../redux/actions/pickupTimeChange.actions';
import * as requestsActions from '../../../redux/actions/requests.actions';
import * as scheduleActions from '../../../redux/actions/schedule.actions';
import { errorPipe } from '../../../redux/epics/epicsUtil';
import { updatePatient } from '../../../redux/epics/helpers/patient.helper';
import { acceptEta, unreserveIfNoAutoETA, updateRequest } from '../../../redux/epics/updateRequestUtil';
import { parseRequestType } from 'parsers/request.parser';

export const acceptAndUpdateScheduleEpic = (action$, store) => {
  let etaId;

  return action$.pipe(
    filter(etaActions.acceptETA.match),
    tap(action => {
      etaId = action.payload;
    }),
    switchMap(() =>
      isCar(store.value.service) || store.value.requests.isNonTransport ? updatePatient(store) : of$({}),
    ),
    switchMap(() => from$(unreserveIfNoAutoETA(store.value))),
    switchMap(() => acceptEta(store, etaId)),
    switchMap(schedule =>
      from$(
        updateRequest(schedule, store.value, {
          requestedArrivalTime: schedule.eta || store.value.eta.requestedArrivalTime,
          requestedAppointmentTime: store.value.eta.requestedAppointmentTime,
          requesterAgencyId: store.value.agency.requesterAgencyId,
          ...parseRequestType(store.value),
        }),
      ).pipe(
        mergeMap(request =>
          concat$(
            of$(etaActions.updateSchedule(schedule || {})),
            of$(requestsActions.updateRequestSuccess(request)),
            of$(errorActions.onError(request)),
          ),
        ),
        errorPipe,
      ),
    ),
  );
};

export const handleScheduleSuccessEpic = action$ =>
  action$.pipe(
    filter(etaActions.updateSchedule.match),
    filter(action => !action.payload.error),
    mergeMap(action =>
      concat$(
        of$(etaActions.acceptETASuccess()),
        of$(etaActions.updateScheduleSuccess(action.payload)),
        of$(pickupTimeChangeActions.requesterUpdateSuccess()),
        of$(scheduleActions.scheduleUpdate(action.payload)),
      ),
    ),
  );

export const handleScheduleFailureEpic = action$ =>
  action$.pipe(
    filter(etaActions.updateSchedule.match),
    filter(action => action.payload.error),
    mergeMap(action =>
      concat$(
        of$(etaActions.rejectETASuccess()),
        of$(formFlowActions.setETASuccess(false)),
        of$(pickupTimeChangeActions.requesterUpdateSuccess()),
        of$(errorActions.onError(action.payload)),
      ),
    ),
  );
