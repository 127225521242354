import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../common/ConfirmationDialog';

const MissingFieldsModalFrame = props => {
  return (
    <ConfirmationDialog open={props.open} onClose={props.closeMissingFieldsModal} title="Missing Fields">
      <p style={{ whiteSpace: 'pre-wrap', margin: 0 }}>{props.body}</p>
    </ConfirmationDialog>
  );
};

MissingFieldsModalFrame.propTypes = {
  open: PropTypes.bool.isRequired,
  body: PropTypes.string.isRequired,
  closeMissingFieldsModal: PropTypes.func.isRequired,
};

export default MissingFieldsModalFrame;
