import { createAction } from '@reduxjs/toolkit';

export const openPickupTimeChangeDialog = createAction('[Pickup Time Change] openPickupTimeChangeDialog');
export const closePickupTimeChangeDialog = createAction('[Pickup Time Change] closePickupTimeChangeDialog');
export const confirmPickupTimeChangeDialog = createAction('[Pickup Time Change] confirmPickupTimeChangeDialog');
export const setPickupChangeContactPhone = createAction('[Pickup Time Change] setPickupChangeContactPhone');
export const setPickupChangeInfo = createAction('[Pickup Time Change] setPickupChangeInfo');
export const requesterUpdateSuccess = createAction('[Pickup Time Change] requesterUpdateSuccess');
export const getRequesterUpdateTypes = createAction('[Pickup Time Change] getRequesterUpdateTypes');
export const getRequesterUpdateTypesSuccess = createAction('[Pickup Time Change] getRequesterUpdateTypesSuccess');
