import { USER_TYPES } from '../enums';

const isRequester = state => state.user.model.userType === USER_TYPES.REQUESTER;

const isCallTypePcsRequired = service => {
  const { callTypeOptions, callTypeId } = service;
  if (!callTypeOptions?.length) return false;

  const callType = callTypeOptions.find(({ key }) => key === callTypeId);
  return callType?.pcsRequired;
};

export const isAgencyGroupPcsEnabled = state => state.agency.requesterAgencyGroup?.pcsEnabled;

export const needsPCSForm = state =>
  isRequester(state) && isAgencyGroupPcsEnabled(state) && isCallTypePcsRequired(state.service);
