import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as requestsActions from '../../redux/actions/requests.actions';
import * as infoboxActions from '../../redux/actions/infobox.actions';
import * as locationActions from '../../redux/actions/location.actions';
import * as mapActions from '../../redux/actions/map.actions';
import * as agencyActions from '../../redux/actions/agency.actions';
import * as serviceActions from '../../redux/actions/service.actions';
import * as etaActions from '../../redux/actions/eta.actions';
import * as willCallActions from '../../redux/actions/willCall.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import TripTypeToggle from './TripTypeToggle.frame';

const TripTypeToggleContainer = props => {
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState(null);
  const disabled = !!props.request.id;

  const onToggle = (value, history) => {
    setPath(value);
    if (!!props.dirty) {
      setOpen(true);
    } else {
      redirect(value, history);
    }
  };

  const onConfirm = (confirm, history) => {
    if (confirm) {
      reset();
      redirect(path, history);
    }
    setOpen(false);
  };

  const reset = () => {
    props.resetMap();
    props.resetDestination();
    props.resetService();
    props.resetWillCall();
    props.resetETAtimes();
    props.setLightsAndSirens(false);
    props.setDirty(false);
  };

  const redirect = (value, history) => {
    const path = value.path.indexOf('mobile') === -1 ? `${value.path}transport` : value.path;

    props.setNonTransport(value.nonTransport);

    if (value.nonTransport) {
      props.setDestinationAsPickup();
    }

    history.replace(path);
  };

  return props.display ? (
    <TripTypeToggle
      change={onToggle}
      disabled={disabled || null}
      isNonTransport={props.isNonTransport}
      openDialog={open}
      onConfirm={onConfirm}
    />
  ) : null;
};

function mapStateToProps(state) {
  return {
    display:
      !window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED &&
      (window.ambulnzConfig.userSettings.isMobileHealthServiceRequesterEnabled ||
        window.ambulnzConfig.constants.MOBILE_HEALTH_SERVICE_ENABLED),
    request: state.requests.request,
    isNonTransport: state.requests.isNonTransport,
    dirty: state.formFlow.dirty,
  };
}

TripTypeToggleContainer.prototype = {
  display: PropTypes.bool.isRequired,
  requests: PropTypes.shape().isRequired,
  isNonTransport: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  ...requestsActions,
  ...infoboxActions,
  ...mapActions,
  ...serviceActions,
  ...locationActions,
  ...etaActions,
  ...agencyActions,
  ...formFlowActions,
  ...willCallActions,
})(TripTypeToggleContainer);
