import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import GetETAElem from './GetEtaLabel/GetEtaLabel';
import { styled } from '@mui/system';

const DisabledButton = props => {
  const { loadedWillCall, isVisible, isNonTransport } = props;

  const StyledButton = styled(Button)({
    visibility: isVisible ? 'visible' : 'hidden',
    backgroundColor: '#FAFAFA',
    color: 'rgba(0, 0, 0, 0.26)',
    fontWeight: 800,
    fontSize: 16,
    width: isNonTransport ? 151 : 72,
    height: isNonTransport ? 43 : 56,
    borderRadius: 8,
    border: '1px solid #00405242',
    boxShadow: '0px 1px 4px 0px rgba(0, 64, 82, 0.17)',
    padding: 0,
    pointerEvents: 'none',
  });

  return (
    <StyledButton variant="contained" id="ETAButton" data-testid="disabledETAButton">
      <GetETAElem loadedWillCall={loadedWillCall} isNonTransport={isNonTransport} />
    </StyledButton>
  );
};

DisabledButton.propTypes = {
  loadedWillCall: PropTypes.bool.isRequired,
  isNonTransport: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default DisabledButton;
