import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 51.1 24" {...props}>
      <title>PEDS-CCT</title>
      <path
        style={{ fill: '#1ec2d8' }}
        d="M2.08,0H36.9a3.41,3.41,0,0,1,2.25,1.21A3.65,3.65,0,0,1,40,3.47.48.48,0,0,1,39.5,4H38.11l6.5,8s2.77.87,4.07,1.13a1.66,1.66,0,0,1,1.39,1.38l.78,4H45.13a3.86,3.86,0,0,0-2.68-1.56A4,4,0,0,0,39.07,19H10.34S8.92,16.9,7.1,16.9A3.65,3.65,0,0,0,3.86,19H.43V1.48A1.43,1.43,0,0,1,2.08,0Z"
        transform="translate(0 0)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M22.61.78a5.69,5.69,0,0,0-5.56,5.09,5.88,5.88,0,0,1,3.11,1.42v-1a2.38,2.38,0,0,1,.76-1.7,2.73,2.73,0,0,1,1.69-.66,2.48,2.48,0,0,1,2.45,2.64v8.58H22V12.28a5.8,5.8,0,0,0-5.84-5.75,5.87,5.87,0,0,0-5.84,5.84A5.79,5.79,0,0,0,16,18.22h2.07V15.11h-2a2.74,2.74,0,1,1,2.73-2.83v5.94h9.43V6.53A5.64,5.64,0,0,0,22.61.78"
          transform="translate(0 0)"
        />
      </g>
      <path
        style={{ fill: '#050505' }}
        d="M1,19H39.07l-.35,1.47H.61A.59.59,0,0,1,0,19.84v-.26A.59.59,0,0,1,.61,19H1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#71ccdd' }}
        d="M39.59,20.45v1.38H3.72A2.93,2.93,0,0,1,1,20.45Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#050505' }} cx="7.1" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="7.1" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="7.1" cy="20.45" r="1.65" />
      <path
        style={{ fill: '#2b242d' }}
        d="M45.13,22.09h4.68c1.21,0,1.29-2.14,1.29-2.34s-.25-1.29-.25-1.29H45.13Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#5f5f61' }} cx="7.1" cy="20.45" r="1.21" />
      <circle style={{ fill: '#050505' }} cx="42.27" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="42.27" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="42.27" cy="20.45" r="1.65" />
      <circle style={{ fill: '#5f5f61' }} cx="42.27" cy="20.45" r="1.21" />
      <path
        style={{ fill: '#ebebed' }}
        d="M35.78,13.35h0a.23.23,0,0,0-.26-.26h-.87a.23.23,0,0,0-.26.26h0a.23.23,0,0,0,.26.26h.87C35.69,13.61,35.86,13.52,35.78,13.35Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M36.9,4.51a1.89,1.89,0,0,1,1.56.61l4.85,6.23L33.61,10V4.51Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ededef' }}
        d="M48,12.92l.82,1.75,1.4.39-.11-.59a1.66,1.66,0,0,0-1.39-1.38Z"
        transform="translate(0 0)"
      />
      <rect style={{ fill: '#ededef' }} x="45.3" y="16.12" width="1.56" height="1.21" />
      <path
        d="M9.15,9.24a.3.3,0,0,1-.29-.3V3a.3.3,0,0,1,.29-.3h2.17a2.14,2.14,0,1,1,0,4.27h-.82v2a.31.31,0,0,1-.3.3Zm2.09-3.85a.58.58,0,0,0,.59-.59.56.56,0,0,0-.59-.54h-.73V5.39Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M11.32,2.81a2,2,0,1,1,0,4h-.94V8.94a.18.18,0,0,1-.18.17H9.15A.17.17,0,0,1,9,8.94V3a.17.17,0,0,1,.17-.17h2.17m-.93,2.7h.85A.71.71,0,0,0,12,4.8a.69.69,0,0,0-.71-.67h-.85V5.51m.93-2.95H9.15A.42.42,0,0,0,8.73,3v6a.42.42,0,0,0,.42.42h1.06a.43.43,0,0,0,.43-.42V7.07h.69a2.26,2.26,0,1,0,0-4.51Zm-.68,1.82h.6a.44.44,0,0,1,.46.42.45.45,0,0,1-.46.46h-.6V4.38Z"
        transform="translate(0 0)"
      />
      <path
        d="M14.45,9.24a.3.3,0,0,1-.29-.3V3a.3.3,0,0,1,.29-.3h3.73a.31.31,0,0,1,.3.3V4a.3.3,0,0,1-.3.3H15.81v.86h1.93a.3.3,0,0,1,.29.3v1a.3.3,0,0,1-.29.3H15.81v1h2.37a.31.31,0,0,1,.3.3v1a.31.31,0,0,1-.3.3Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M18.18,2.81a.17.17,0,0,1,.17.17V4a.17.17,0,0,1-.17.17H15.69V5.25h2a.18.18,0,0,1,.17.17v1a.17.17,0,0,1-.17.17h-2V7.79h2.49a.17.17,0,0,1,.17.17v1a.17.17,0,0,1-.17.17H14.45a.17.17,0,0,1-.17-.17V3a.17.17,0,0,1,.17-.17h3.73m0-.25H14.45A.42.42,0,0,0,14,3v6a.42.42,0,0,0,.42.42h3.73a.42.42,0,0,0,.42-.42V8a.42.42,0,0,0-.42-.42H15.94V6.82h1.8a.42.42,0,0,0,.42-.42v-1A.42.42,0,0,0,17.74,5h-1.8V4.38h2.24A.42.42,0,0,0,18.6,4V3a.42.42,0,0,0-.42-.42Z"
        transform="translate(0 0)"
      />
      <path
        d="M19.72,9.24a.3.3,0,0,1-.29-.3V3a.3.3,0,0,1,.29-.3h2.19a3.28,3.28,0,1,1,0,6.56Zm2.1-1.59A1.63,1.63,0,0,0,23.45,6a1.63,1.63,0,0,0-1.63-1.69h-.74V7.65Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M21.91,2.81a3.15,3.15,0,1,1,0,6.3H19.72a.17.17,0,0,1-.16-.17V3a.17.17,0,0,1,.16-.17h2.19m-.95,5h.86A1.75,1.75,0,0,0,23.58,6a1.75,1.75,0,0,0-1.76-1.82H21V7.78m.95-5.22H19.72a.41.41,0,0,0-.41.42v6a.41.41,0,0,0,.41.42h2.19a3.4,3.4,0,1,0,0-6.8Zm-.7,1.82h.61A1.51,1.51,0,0,1,23.33,6a1.51,1.51,0,0,1-1.51,1.58h-.61V4.38Z"
        transform="translate(0 0)"
      />
      <path
        d="M27.88,9.33a2.94,2.94,0,0,1-2.11-.73.29.29,0,0,1-.07-.41l.43-.74a.31.31,0,0,1,.28-.15.39.39,0,0,1,.2,0,2.26,2.26,0,0,0,1.21.48.44.44,0,0,0,.49-.42c0-.31-.27-.56-.84-.79a2.45,2.45,0,0,1-1.83-2.11,2,2,0,0,1,2.2-1.92,3.51,3.51,0,0,1,2,.7.34.34,0,0,1,.08.45l-.46.7a.43.43,0,0,1-.34.2h0l-.11,0a2.64,2.64,0,0,0-1.21-.51.4.4,0,0,0-.44.33c0,.21.11.44.9.76.47.19,1.9.75,1.9,2.17A2.1,2.1,0,0,1,27.88,9.33Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M27.84,2.72a3.41,3.41,0,0,1,1.93.67.24.24,0,0,1,.06.29l-.46.7a.33.33,0,0,1-.24.14.11.11,0,0,1-.07,0A2.88,2.88,0,0,0,27.77,4a.52.52,0,0,0-.56.46c0,.34.27.59,1,.88S30,6.11,30,7.34A2,2,0,0,1,27.88,9.2a2.81,2.81,0,0,1-2-.69c-.09-.09-.12-.13-.05-.25l.42-.75a.19.19,0,0,1,.18-.09.18.18,0,0,1,.13,0A2.79,2.79,0,0,0,27.82,8a.56.56,0,0,0,.62-.54c0-.38-.32-.67-.93-.91-.77-.31-1.74-.91-1.74-2a1.86,1.86,0,0,1,2.07-1.79m0-.25a2.12,2.12,0,0,0-2.32,2c0,1,.67,1.74,1.9,2.22.77.31.77.59.77.68s-.19.29-.37.29a2.06,2.06,0,0,1-1.11-.43h0l-.05,0a.46.46,0,0,0-.24-.06.45.45,0,0,0-.39.21l-.43.75a.4.4,0,0,0,.09.55,3.06,3.06,0,0,0,2.2.77,2.23,2.23,0,0,0,2.38-2.11c0-1.5-1.49-2.09-2-2.29-.73-.29-.82-.49-.82-.64s.13-.21.31-.21a2.45,2.45,0,0,1,1.09.46l.08,0a.31.31,0,0,0,.19.06.58.58,0,0,0,.45-.26L30,3.82a.47.47,0,0,0-.13-.64,3.57,3.57,0,0,0-2.06-.71Z"
        transform="translate(0 0)"
      />
      <path
        d="M14.48,17.33a3.37,3.37,0,1,1,0-6.74,3.13,3.13,0,0,1,2.26.87.33.33,0,0,1,.11.22.31.31,0,0,1-.09.22l-.7.73a.32.32,0,0,1-.21.08.32.32,0,0,1-.2-.08,1.7,1.7,0,0,0-1.12-.43,1.72,1.72,0,0,0,0,3.44,1.72,1.72,0,0,0,1.13-.41.32.32,0,0,1,.2-.07.28.28,0,0,1,.21.1l.7.74a.26.26,0,0,1,.07.21.32.32,0,0,1-.08.21A3.24,3.24,0,0,1,14.48,17.33Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M14.48,10.72a3,3,0,0,1,2.18.84.17.17,0,0,1,0,.25l-.7.73a.21.21,0,0,1-.12.05.16.16,0,0,1-.11-.05,1.79,1.79,0,0,0-3,1.39,1.79,1.79,0,0,0,1.77,1.83,1.88,1.88,0,0,0,1.21-.43.16.16,0,0,1,.12,0,.15.15,0,0,1,.12.05l.7.74a.2.2,0,0,1,0,.25,3.11,3.11,0,0,1-2.19.87,3.24,3.24,0,1,1,0-6.48m0-.25a3.49,3.49,0,1,0,0,7,3.33,3.33,0,0,0,2.36-.94.43.43,0,0,0,0-.6l-.69-.74a.4.4,0,0,0-.3-.13.39.39,0,0,0-.28.1,1.68,1.68,0,0,1-1.05.37,1.59,1.59,0,0,1,0-3.18,1.58,1.58,0,0,1,1,.39.4.4,0,0,0,.28.12.41.41,0,0,0,.29-.13l.71-.73a.42.42,0,0,0,.12-.3.41.41,0,0,0-.14-.31,3.28,3.28,0,0,0-2.35-.9Z"
        transform="translate(0 0)"
      />
      <path
        d="M20.54,17.33a3.37,3.37,0,1,1,0-6.74,3.15,3.15,0,0,1,2.26.87.32.32,0,0,1,.1.22.27.27,0,0,1-.09.22l-.7.73a.28.28,0,0,1-.2.08.3.3,0,0,1-.2-.08,1.71,1.71,0,0,0-1.13-.43,1.72,1.72,0,0,0,0,3.44,1.8,1.8,0,0,0,1.13-.41.32.32,0,0,1,.19-.07.29.29,0,0,1,.22.1l.7.74a.26.26,0,0,1,.07.21.29.29,0,0,1-.09.21A3.21,3.21,0,0,1,20.54,17.33Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M20.54,10.72a3,3,0,0,1,2.18.84.17.17,0,0,1,0,.25l-.71.73a.15.15,0,0,1-.22,0,1.85,1.85,0,0,0-1.22-.46,1.84,1.84,0,0,0,0,3.68,1.92,1.92,0,0,0,1.21-.43.15.15,0,0,1,.11,0,.13.13,0,0,1,.12.05l.7.74a.18.18,0,0,1,0,.25,3.13,3.13,0,0,1-2.19.87,3.24,3.24,0,1,1,0-6.48m0-.25a3.49,3.49,0,1,0,0,7,3.35,3.35,0,0,0,2.36-.94.43.43,0,0,0,0-.6l-.7-.74a.37.37,0,0,0-.3-.13.38.38,0,0,0-.27.1,1.68,1.68,0,0,1-1.05.37,1.59,1.59,0,0,1,0-3.18,1.58,1.58,0,0,1,1.05.39.4.4,0,0,0,.28.12.41.41,0,0,0,.29-.13l.71-.73a.42.42,0,0,0,.12-.3.46.46,0,0,0-.15-.31,3.24,3.24,0,0,0-2.34-.9Z"
        transform="translate(0 0)"
      />
      <path
        d="M24.78,17.24a.31.31,0,0,1-.29-.3V12.26H23.33A.29.29,0,0,1,23,12V11a.3.3,0,0,1,.3-.3h4a.3.3,0,0,1,.3.3v1a.29.29,0,0,1-.3.3H26.16v4.68a.31.31,0,0,1-.29.3Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M27.32,10.81a.17.17,0,0,1,.17.17v1a.17.17,0,0,1-.17.17H26v4.81a.18.18,0,0,1-.17.17H24.78a.18.18,0,0,1-.17-.17V12.13H23.33a.17.17,0,0,1-.17-.17V11a.17.17,0,0,1,.17-.17h4m0-.25h-4a.42.42,0,0,0-.42.42v1a.42.42,0,0,0,.42.42h1v4.56a.43.43,0,0,0,.42.42h1.09a.43.43,0,0,0,.42-.42V12.38h1a.42.42,0,0,0,.42-.42V11a.42.42,0,0,0-.42-.42Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
