import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import AmbGrid from 'components/common/AmbGrid';
import Price from '../common/icons/Price';

function ChargeItem({ total, final, name, withBorder, selected }) {
  const styles = {
    width: '100%',
    margin: 0,
    borderTop: withBorder && '1px solid #fff',
    fontWeight: selected && 'bold',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  if (withBorder) {
    styles.marginTop = '4px';
    styles.paddingTop = '4px';
  }

  return (
    <AmbGrid style={styles}>
      <AmbGrid style={{ padding: '4px' }}>{name}</AmbGrid>
      <AmbGrid style={{ position: 'relative', padding: '4px', width: '75%' }}>
        <div style={{ position: 'absolute', left: '-4px' }}>{!final && '~'}</div>
        {total}
        {final ? ' (final)' : ' (estimate)'}
      </AmbGrid>
    </AmbGrid>
  );
}

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ tooltip: className }} />)({
  backgroundColor: 'rgba(0, 0, 0, .95)',
  color: '#fff',
  width: 220,
  fontSize: '0.75rem',
});

export function ChargesFrame({ requestId, requestBreakdown, summary }) {
  return (
    <AmbGrid style={{ marginLeft: 0, marginRight: 0, marginBottom: 0, alignItems: 'center', justifyContent: 'center' }}>
      <AmbGrid style={{ padding: 8 }}>
        <span style={{ fontSize: '16px', textAlign: 'center' }}>
          Total Fare:&nbsp;
          {summary.final ? '' : '~'}
          {summary.total}
          {summary.final ? ' (final)' : ' (estimate)'}
        </span>
      </AmbGrid>
      {requestBreakdown.length > 1 && (
        <AmbGrid style={{ padding: '0' }}>
          <StyledTooltip
            title={
              <React.Fragment>
                {requestBreakdown.map(breakdown => (
                  <ChargeItem
                    key={breakdown.requestId}
                    total={breakdown.total}
                    final={breakdown.final}
                    name={breakdown.requestId}
                    selected={requestId === breakdown.requestId}
                  />
                ))}
                <ChargeItem total={summary.total} final={summary.final} withBorder name="Total" />
              </React.Fragment>
            }
          >
            <Price style={{ display: 'block', width: '19px', height: '18px' }} />
          </StyledTooltip>
        </AmbGrid>
      )}
    </AmbGrid>
  );
}

ChargesFrame.propTypes = {
  requestBreakdown: PropTypes.arrayOf(PropTypes.shape).isRequired,
  summary: PropTypes.shape().isRequired,
  requestId: PropTypes.number.isRequired,
};

export default ChargesFrame;
