import React from 'react';
import PropTypes from 'prop-types';
import { DirectionsRenderer } from '@react-google-maps/api';

import PickupMarker from './PickupMarker';
import DestinationMarker from './DestinationMarker';
import VehicleMarker from './VehicleMarker';

const WayPoints = ({
  pickupTime,
  fromWaypoint,
  toWaypoint,
  directions,
  pickupComplete,
  dropoffComplete,
  showVehicle,
  vehicleLocation,
  complete,
  dropoffTime,
  showRoute,
  isNonTransport,
}) => (
  <>
    {showVehicle && <VehicleMarker position={vehicleLocation} heading={directions && directions.heading} />}
    {showRoute && (
      <DirectionsRenderer
        directions={directions}
        options={{
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: complete ? '#8E8F91' : '#0088FF',
            strokeWeight: 6,
            strokeOpacity: 0.6,
          },
        }}
      />
    )}
    {fromWaypoint && (
      <PickupMarker
        position={{
          lat: fromWaypoint.lat,
          lng: fromWaypoint.lng,
        }}
        pickupTime={pickupTime}
        complete={pickupComplete}
        isNonTransport={isNonTransport}
      />
    )}
    {toWaypoint && (
      <DestinationMarker
        position={{
          lat: toWaypoint.lat,
          lng: toWaypoint.lng,
        }}
        dropoffTime={dropoffTime}
        complete={dropoffComplete}
      />
    )}
  </>
);

WayPoints.propTypes = {
  directions: PropTypes.shape(),
};

WayPoints.defaultProps = {
  directions: null,
};

export default WayPoints;
