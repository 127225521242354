import * as R from 'ramda';

import { SERVICE_TYPE_NAMES } from '../../enums';

export const presentSubtitle = state => {
  if (state.callTypeOptions.length) {
    const callTypeName = state.callTypeOptions.find(ct => ct.key === state.callTypeId);
    return callTypeName ? `Call Type: ${callTypeName.value}` : '';
  }

  return '';
};

export const presentServiceLevelTitle = state => {
  const serviceTypeTitle = [];
  const bariatricId = state.serviceTypeDictionary[SERVICE_TYPE_NAMES.Bariatric].id;
  const serviceId = state.serviceTypeIds.find(id => id !== bariatricId);
  serviceTypeTitle.push(state.serviceTypeLookup[serviceId].name);

  const requirementTitle = [];
  state.requirements.forEach(requirement => {
    if (state.requirementIds.includes(requirement.id)) {
      requirementTitle.push(requirement.displayName);
    }
  });

  const therapistEquipmentTitle = [];
  state.equipment.forEach(singleEquipment => {
    if (state.therapistEquipmentIds.includes(singleEquipment.id)) {
      therapistEquipmentTitle.push(singleEquipment.label);
    }
  });

  state.equipmentAsService.forEach(singleEquipment => {
    if (state.therapistEquipmentIds.includes(singleEquipment.id)) {
      therapistEquipmentTitle.push(singleEquipment.label);
    }
  });

  return R.join(
    ' +  ',
    R.reject(R.isEmpty, [serviceTypeTitle, R.join(', ', requirementTitle), R.join(', ', therapistEquipmentTitle)]),
  );
};
