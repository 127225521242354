import ApiService from './api.service';
import { emsFacilityFilter } from './filters/emsFacility.filters';

class EmsFacilityService {
  static async getFacilities(query) {
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/EmsFacilities/search?query=${query.trim()}`;
    let response = await ApiService.get(path);
    response = await response.json();

    // For backwards compat
    // Delete me once backend is deployed for a week
    return response.map(facility => {
      if (!facility.entity.name) {
        facility.entity.name = facility.entityName;
      }
      return facility;
    });
  }

  static async getEmsFacilityById(id) {
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/EmsFacilities/${id}?filter=${JSON.stringify(
      emsFacilityFilter,
    )}`;
    const response = await ApiService.get(path);

    return response.json();
  }

  static async getDepartments(id) {
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/EmsFacilities/${id}/Departments`;
    const response = await ApiService.get(path);

    return response.json();
  }
}

export default EmsFacilityService;
