import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const translations = {
  'Requester Agency': 'Requester Agency',
  'Requesting Agency': 'Requesting Agency',
  BLS: 'BLS',
  ALS: 'ALS',
  CCT: 'CCT',
  WAV: 'WAV',
  Curbside: 'Uber',
  NICU: 'NICU',
  PEDS: 'PEDS',
  Adult: 'Adult',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations,
      },
    },
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
  });

export default i18n;
