export function updateDynamicState(storeKey, defaultFn) {
  return (state, coordinationTypeId, updateObj) => {
    const stateValue = state[storeKey][coordinationTypeId] || defaultFn();

    return {
      [storeKey]: {
        ...state[storeKey],
        [coordinationTypeId]: {
          ...stateValue,
          ...updateObj,
        },
      },
    };
  };
}

export function updateDynamicStateExcept(storeKey, defaultFn) {
  return (state, exclusiveCoordinationTypeId, exclusiveUpdateObj, updateObj) => {
    const storage = state[storeKey];
    const keys = Object.keys(storage);
    const newState = keys.filter(key => key !== exclusiveCoordinationTypeId).reduce((acc, key) => {
      const stateValue = storage[key];

      return {
        ...acc,
        [key]: {
          ...stateValue,
          ...updateObj,
        },
      };
    }, {});
    const exclusiveState = storage[exclusiveCoordinationTypeId] || defaultFn();

    newState[exclusiveCoordinationTypeId] = {
      ...exclusiveState,
      ...exclusiveUpdateObj,
    };

    return {
      ...state,
      [storeKey]: newState,
    };
  };
}
