import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Map from './Map';
import * as mapActions from '../../redux/actions/map.actions';
import { NoMap } from '../AppFrame/Logo/ByDocgoLogo';

const MapContainer = ({ currentLocation }) => {
  return (
    <Suspense fallback={<></>}>
      <Map params={{ currentLocation }} googleMapApiKey={window.ambulnzConfig.googleMapApiKey} />
    </Suspense>
  );
};

MapContainer.propTypes = {
  currentLocation: PropTypes.shape({
    lng: PropTypes.number,
    lat: PropTypes.number,
    zoom: PropTypes.number,
  }).isRequired,
};

function mapStateToProps({ map, requests }) {
  return {
    currentLocation: { ...map.location, zoom: requests.isNonTransport ? 16 : map.location.zoom },
  };
}

export default connect(mapStateToProps, { ...mapActions })(MapContainer);
