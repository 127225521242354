import { get } from 'lodash';
import ApiService from './api.service';
import { parseInfoBox } from '../parsers/infobox.parser';

class PatientService {
  static async getPatient(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/patient`;
    const response = await ApiService.get(url);
    return response.json();
  }

  static async getProfile(patient, requesterAgencyId, externalIdType) {
    const params = [
      `primaryKey=${JSON.stringify({
        externalId: patient?.externalId,
        externalIdType,
        clinicId: patient?.clinic?.id,
      })}`,
    ];

    if (requesterAgencyId) {
      params.push(`requesterAgencyId=${requesterAgencyId}`);
    }

    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Patients/Profile?${params.join('&')}`;

    const response = await ApiService.get(url);
    return response.json();
  }

  static async updatePatient(requestId, state) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/patient`;

    try {
      const data = parseInfoBox(state).patient;
      const response = await ApiService.put(url, { data });
      const { patient } = await response.json();
      await this.updatePatientMrn(patient.id, data.mrn, state.agency.requesterAgencyGroup);

      return patient;
    } catch (error) {
      return Promise.resolve({
        error: {
          name: `Failed to update patient`,
          message: 'Please contact support',
          status: 500,
        },
      });
    }
  }

  static async searchPatient({ firstName, lastName, dob, ssn, email }, requesterAgencyId, mrn, externalIdType) {
    if (!(firstName || lastName || dob || ssn || mrn || email)) {
      return [];
    }

    const urlSearchParams = PatientService.getPatientUrlSearchParams(
      firstName,
      lastName,
      dob,
      ssn,
      email,
      requesterAgencyId,
      mrn,
      externalIdType,
    );

    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Patients/search?${urlSearchParams.toString()}`;
    const response = await ApiService.get(url);

    return response.json();
  }

  static getPatientUrlSearchParams(firstName, lastName, dob, ssn, email, requesterAgencyId, mrn, externalIdType) {
    const urlSearchParams = new URLSearchParams();

    if (firstName) {
      urlSearchParams.append('firstName', firstName);
    }

    if (lastName) {
      urlSearchParams.append('lastName', lastName);
    }

    if (dob) {
      urlSearchParams.append('dob', dob);
    }

    if (ssn) {
      urlSearchParams.append('ssn', ssn);
    }

    if (email) {
      urlSearchParams.append('email', email);
    }

    if (requesterAgencyId) {
      urlSearchParams.append('requesterAgencyId', requesterAgencyId);
    }

    if (mrn) {
      urlSearchParams.append('externalId', mrn);

      if (externalIdType) {
        urlSearchParams.append('externalIdType', externalIdType);
      }
    }

    return urlSearchParams;
  }

  static async updatePatientMrn(patientId, mrn, requesterAgencyGroup) {
    if (!mrn) {
      return null;
    }

    const requesterAgencyGroupId = get(requesterAgencyGroup, ['id'], null);
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Patients/${patientId}/externalId`;
    const params = { externalId: mrn };

    if (requesterAgencyGroupId) {
      params.requesterAgencyGroupId = requesterAgencyGroupId;
    }

    const response = await ApiService.put(url, params);
    return response.json();
  }
}

export default PatientService;
