import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '../common/icons/close';
import ProposedETATable from './ProposedETATable';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    maxWidth: 1133,
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'left',
  backgroundColor: '#d5d5d540',
  borderBottom: 'rgba(0, 0, 0, 0.09) 1px solid',
  padding: '12px 24px',
});

const StyledDialogContent = styled(DialogContent)({
  padding: '0 24px',
});

const IconButton = styled('button')({
  position: 'absolute',
  right: 10,
  top: 5,
  width: 15,
  height: 15,
  margin: 0,
  marginLeft: 'auto',
  color: 'rgba(0, 0, 0, 0.54)',
  padding: 0,
  fontSize: '1.5rem',
  textAlign: 'center',
  border: 0,
  cursor: 'pointer',
  display: 'inline-flex',
  outline: 'none',
  alignItems: 'center',
  userSelect: 'none',
  verticalAlign: 'middle',
  justifyContent: 'center',
  MozAppearance: 'none',
  textDecoration: 'none',
  backgroundColor: 'transparent',
  WebkitAppearance: 'none',
  WebkitTapHighlightColor: 'transparent',
});

const ProposedETAModalFrame = props => {
  return (
    <StyledDialog fullWidth aria-labelledby="proposedETA-modal" open={props.open}>
      <StyledDialogTitle>
        <p style={{ margin: 0 }}>Optimal ETAs</p>
      </StyledDialogTitle>
      <StyledDialogContent>
        <IconButton tabIndex={0} type="button" onClick={() => props.openProposedETAsModal(false)} size="large">
          <CloseIcon style={{ width: 24, height: 24, fill: 'currentColor' }} />
        </IconButton>
        <ProposedETATable etas={props.etas} />
      </StyledDialogContent>
    </StyledDialog>
  );
};

ProposedETAModalFrame.propTypes = {
  open: PropTypes.bool.isRequired,
  etas: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  openProposedETAsModal: PropTypes.func.isRequired,
};

export default ProposedETAModalFrame;
