import React from 'react';
import { connect } from 'react-redux';
import ConfirmPickupTime from './ConfirmPickupTime.validations';
import * as pickupTimeChangeActions from '../../redux/actions/pickupTimeChange.actions';

const ConfirmPickupTimeContainer = props => <ConfirmPickupTime {...props} />;

function mapStateToProps(state) {
  return {
    open: state.pickupTimeChange.open,
    updateTypes: state.pickupTimeChange.updateTypes,
    updateTypeId: state.pickupTimeChange.updateTypeId,
    firstName: state.pickupTimeChange.firstName,
    lastName: state.pickupTimeChange.lastName,
    phone: state.pickupTimeChange.phone,
    details: state.pickupTimeChange.details,
  };
}

export default connect(mapStateToProps, { ...pickupTimeChangeActions })(ConfirmPickupTimeContainer);
