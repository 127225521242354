import ApiService from './api.service';

class TurndownService {
  static async turndownETA({ requestId, typeId }) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/turndown`;
    const response = await ApiService.put(url, { typeId });
    return response.json();
  }

  static async getTurndownTypes() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/TurndownTypes`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default TurndownService;
