import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import FacilityItem from './FacilityItem';
import LocationItem from './LocationItem';
import PayerItem from './PayerItem';
import CommonItem from './CommonItem';

const StyledMenuItem = styled(MenuItem)(({ theme, ishighlighted, isselected }) => ({
  fontWeight: isselected ? 500 : 400,
  backgroundColor: ishighlighted ? '#f3f3f3' : '#fff',
  paddingLeft: 5,
  fontSize: 14,
  paddingTop: 2,
  paddingBottom: 2,
  height: 'auto',
  color: isselected ? theme.palette.text.secondary : '#2E424D',
  width: 'inherit',
}));

function SuggestionsOption(props) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem, showDivider, inputValue } = props;
  const isFacility = suggestion.typeof === 'facility'; // TODO: temp until we have a design
  const isPayer = suggestion.typeof === 'payer';
  const isAddress = suggestion.typeof === 'prediction' || suggestion.typeof === 'geopointPrediction';
  const isCommon = !isFacility && !isAddress && !isPayer;

  return (
    <>
      <StyledMenuItem
        {...itemProps}
        component="div"
        disableGutters
        ishighlighted={highlightedIndex === index ? 1 : 0}
        isselected={selectedItem === suggestion.label ? 1 : 0}
      >
        {isFacility && <FacilityItem inputValue={inputValue}>{suggestion}</FacilityItem>}
        {isAddress && <LocationItem>{suggestion}</LocationItem>}
        {isPayer && <PayerItem inputValue={inputValue}>{suggestion}</PayerItem>}
        {isCommon && <CommonItem>{suggestion}</CommonItem>}
      </StyledMenuItem>
      {showDivider && (
        <hr
          style={{ margin: 0, marginTop: 10, marginBottom: 10, backgroundColor: '#e5e5e5', height: 1, border: 'none' }}
        />
      )}
    </>
  );
}

SuggestionsOption.propTypes = {
  inputValue: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  highlightedIndex: PropTypes.number,
  suggestion: PropTypes.shape(),
  index: PropTypes.number,
  itemProps: PropTypes.shape(),
  selectedItem: PropTypes.shape(),
  showDivider: PropTypes.bool,
};

export default SuggestionsOption;
