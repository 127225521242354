import { from as from$ } from 'rxjs';

import RequestService from '../../../services/request.service';
import { RequestStatus } from '../../../enums';

export function updateRequest(requestId, isWillCall, requestedArrivalTime, canRequestBeActivatedByPatient) {
  const status = isWillCall ? RequestStatus.WILL_CALL : RequestStatus.NEEDS_ASSIGNMENT;

  return from$(
    RequestService.updateRequest(requestId, {
      status,
      isWillCall,
      canRequestBeActivatedByPatient,
      requestedArrivalTime: isWillCall ? null : requestedArrivalTime,
    }),
  );
}

export function unReserve(requestId) {
  return from$(RequestService.unReserve(requestId, RequestStatus.WILL_CALL));
}
