import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { parseAMPM, parseMilitary, createTimeOptions, getIndex } from '../../parsers/time.parser';
import { styled } from '@mui/system';
import { debounce } from 'lodash';

const MILITARY_FORMAT = 'H:mm';
const AMPM_FORMAT = 'h:mm A';

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: 110,
  marginTop: 6,
  '& .MuiInputBase-root': {
    justifyContent: 'flex-end',
    paddingRight: '0 !important',
    '& > .MuiInputBase-input': {
      flexGrow: 'unset',
      paddingRight: 10,
    },
    '& > .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
  },
  '& .MuiInputLabel-root': {
    fontSize: 15,
    width: 130,
    color: theme.palette.grey.grey80,
    '&.Mui-focused': {
      color: theme.palette.grey.grey80,
    },
  },
}));

const ReadonlyTextField = styled(TextField)(({ theme }) => ({
  position: 'absolute',
  left: 78,
  top: 21,
  '& .MuiInputBase-input': {
    paddingTop: '5px !important',
  },
  '& .MuiInput-root:hover:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
}));

export default function TimeSelect({ id, ampm, value, disabled, onChange, timezone }) {
  const format = ampm ? AMPM_FORMAT : MILITARY_FORMAT;
  const options = createTimeOptions(ampm);

  const parseDefaultTime = time => {
    let formattedTime = '';
    if (moment(time).isValid()) {
      formattedTime = moment(time).format(format);
    }
    return formattedTime;
  };

  const parsedValue = parseDefaultTime(value);
  const timezoneAbbr = moment.tz(timezone).format('z');

  const parseOnchange = e => {
    const parsedValue = ampm ? parseAMPM(e.target.value) : parseMilitary(e.target.value);
    return onChange(parsedValue, null);
  };

  const debouncedOnChange = debounce(parseOnchange, 1000);

  const handleClick = e => {
    const currentVal = e.target.value;
    if (currentVal) {
      const i = getIndex(options, currentVal);
      const matchedOption = Array.from(document.querySelectorAll('.MuiAutocomplete-option'))[i];
      matchedOption && matchedOption.scrollIntoView();
    }
  };

  return (
    <Autocomplete
      id={id}
      sx={{ width: 260, position: 'relative' }}
      options={options}
      autoHighlight
      autoFocus={true}
      value={parsedValue}
      freeSolo={true}
      onChange={(e, value) => onChange(null, value)}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            mr: 2,
            flexShrink: 0,
            minWidth: 110,
            minHeight: '48px !important',
          }}
          {...props}
        >
          {option}
        </Box>
      )}
      renderInput={params => {
        return (
          <>
            <StyledTextField
              {...params}
              id={id}
              label="Time"
              disabled={disabled}
              variant="standard"
              onChange={debouncedOnChange}
              onClick={handleClick}
              onFocus={event => event.target.select()}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                maxLength: ampm ? 8 : 5,
                style: { minWidth: ampm ? 84 : 70 },
                autoFocus: true,
                'data-testid': 'time-input',
              }}
            />
            <ReadonlyTextField
              value={timezoneAbbr}
              variant="standard"
              readOnly
              inputProps={{ 'data-testid': 'timezone-abbr' }}
            />
          </>
        );
      }}
    />
  );
}
