import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Button, TextField } from '@mui/material';
import AmbGrid from 'components/common/AmbGrid';
import { styled as muiStyled } from '@mui/system';
import PhoneInput from '../common/PhoneInput';

const StyledTextField = muiStyled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: theme.typography.fontSize,
    '&:after': {
      borderBottom: '2px solid #004053',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: 15,
    color: theme.palette.grey.grey80,
  },
}));

const StyledTextArea = muiStyled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: theme.typography.fontSize,
    '&:after': {
      borderBottom: '2px solid #004053',
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: 15,
    color: theme.palette.grey.grey80,
  },
}));

const StyledParagraph = styled.p`
  margin: 0;
`;

function ConfirmPickupTimeFrame(props) {
  const handlePhoneNumberChange = event => {
    props.setPickupChangeContactPhone(event.target.value.replace(/\D/g, ''));
  };

  const handleInputChange = event => {
    props.setPickupChangeInfo({ [event.target.name]: event.target.value });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="confirm-pickup-time-change-dialog-title"
      open={props.open}
    >
      <DialogTitle>Confirm Pickup Time Change</DialogTitle>
      <DialogContent>
        <AmbGrid style={{ height: 95, gap: '8px' }}>
          <AmbGrid id="confirm-pickup-time-change-reason" style={{ width: '100%', flexDirection: 'column' }}>
            <StyledParagraph>You must specify a reason for Pickup Time change:*</StyledParagraph>
            <StyledTextField
              name="updateTypeId"
              value={props.updateTypeId}
              select
              required
              fullWidth
              margin="normal"
              onChange={handleInputChange}
              variant="standard"
            >
              {props.updateTypes.map((updateType, index) => (
                <MenuItem
                  id={`confirm-pickup-time-change-reason-value-${index}`}
                  key={updateType.id}
                  value={updateType.id}
                >
                  {updateType.name}
                </MenuItem>
              ))}
            </StyledTextField>
          </AmbGrid>
        </AmbGrid>
        <StyledParagraph>Contact Person:*</StyledParagraph>
        <AmbGrid style={{ height: 95, gap: '8px' }}>
          <AmbGrid>
            <StyledTextField
              id="confirm-pickup-time-change-firstname"
              name="firstName"
              value={props.firstName}
              required
              fullWidth
              margin="normal"
              label="First Name"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              error={!!props.errors.firstName}
              variant="standard"
            />
          </AmbGrid>
          <AmbGrid>
            <StyledTextField
              id="confirm-pickup-time-change-lastname"
              name="lastName"
              value={props.lastName}
              required
              fullWidth
              margin="normal"
              label="Last Name"
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
              error={!!props.errors.lastName}
              variant="standard"
            />
          </AmbGrid>
          <AmbGrid>
            <PhoneInput
              id="confirm-pickup-time-change-phone"
              name="phone"
              value={props.phone}
              required
              style={{ marginTop: 5, width: '100%' }}
              label="Phone Number"
              inputLabelProps={{ style: { transform: 'translate(0, 10px) scale(0.75)' } }}
              onChange={handlePhoneNumberChange}
              error={!!props.errors.phone}
            />
          </AmbGrid>
        </AmbGrid>
        <AmbGrid style={{ width: '100%', flexDirection: 'column' }}>
          <StyledParagraph>Please provide a detailed reason for this change:*</StyledParagraph>
          <StyledTextArea
            id="confirm-pickup-time-change-details"
            value={props.details}
            name="details"
            multiline
            rows="4"
            maxRows="7"
            required
            fullWidth
            label="Details"
            onChange={handleInputChange}
            error={!!props.errors.details}
            variant="standard"
          />
        </AmbGrid>
      </DialogContent>
      <DialogActions sx={{ padding: '8px 16px 8px 0' }}>
        <Button
          id="confirm-pickup-time-change-cancel"
          onClick={props.closePickupTimeChangeDialog}
          sx={{
            color: '#004053',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: 'rgba(30, 192, 217, 0.1)',
            },
          }}
        >
          CANCEL
        </Button>
        <Button
          id="confirm-pickup-time-change-ok"
          onClick={props.confirmPickupTimeChangeDialog}
          disabled={!props.allTouched || props.hasErrors}
          sx={{
            color: !props.allTouched || props.hasErrors ? 'grey' : '#004053',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: 'rgba(30, 192, 217, 0.1)',
            },
          }}
        >
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmPickupTimeFrame.propTypes = {
  confirmPickupTimeChangeDialog: PropTypes.func.isRequired,
  closePickupTimeChangeDialog: PropTypes.func.isRequired,
  setPickupChangeContactPhone: PropTypes.func.isRequired,
  setPickupChangeInfo: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateTypeId: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  allTouched: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
};

export default ConfirmPickupTimeFrame;
