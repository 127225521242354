import React from 'react';
import PropTypes from 'prop-types';
import { drawerWidth } from '../../../theme';

class EligibilityIframeFrame extends React.Component {
  state = {
    frameLoaded: false,
  };

  UNSAFE_componentWillMount() {
    if (!this.state.frameLoaded) {
      const frame = document.createElement('iframe');
      document.body.appendChild(frame);

      this.setState({ frameLoaded: true });
      frame.style.position = 'absolute';
      frame.style.zIndex = 0;
      frame.style.right = 0;
      frame.style.top = `${this.props.top}px`;
      frame.style.width = `calc(100% - ${drawerWidth}px)`;
      frame.style.border = 'none';
      this.frame = frame;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.html !== '') {
      this.updateFrame(nextProps);
    }

    if (nextProps.html === '') {
      this.hideFrame();
    }
  }

  hideFrame() {
    this.frame.style.display = 'none';
  }

  updateFrame(props) {
    const { frame } = this;
    frame.style.display = 'block';
    frame.style.zIndex = 1;

    if (props.portalInfo && props.portalInfoExpanded) {
      frame.style.top = '50%';
      frame.style.height = '50%';
    } else {
      frame.style.top = `${props.top}px`;
      frame.style.height = `calc(100% - ${props.top}px)`;
    }

    frame.contentDocument.open();
    frame.contentDocument.write(props.html);
    frame.contentDocument.close();
  }

  render() {
    return <div />;
  }
}

EligibilityIframeFrame.propTypes = {
  html: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  portalInfoExpanded: PropTypes.bool.isRequired,
  portalInfo: PropTypes.string.isRequired,
};

export default EligibilityIframeFrame;
