import React from 'react';
import PropTypes from 'prop-types';
import { DirectionsRenderer } from '@react-google-maps/api';
import VehicleMarker from './VehicleMarker';
import useDirections from './useDirections.hook';
import { theme } from '../../theme';

function ToPickUp({ from, to }) {
  const directions = useDirections(from, to);

  return (
    <>
      {directions && <VehicleMarker position={from} heading={directions && directions.heading} />}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            suppressMarkers: true,
            polylineOptions: {
              strokeColor: theme.palette.timeline.toPickup,
              strokeWeight: 6,
              strokeOpacity: 0.6,
            },
          }}
        />
      )}
    </>
  );
}

ToPickUp.propTypes = {
  directions: PropTypes.shape(),
};

ToPickUp.defaultProps = {
  directions: null,
};

export default ToPickUp;
