import React from 'react';
import styled from '@emotion/styled';

const TelLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export default function ({ phone }) {
  const cleaned = ('' + phone).replace(/\D/g, '');
  if (cleaned.length !== 10) return phone;

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (
    <TelLink href={`tel:${phone}`}>
      ({match[1]}) {match[2]}-{match[3]}
    </TelLink>
  );
}
