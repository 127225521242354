import withValidations from '../common/withValidations';
import Frame from './PickupDestination.frame';

const noPostalCode = value => value && !value.facility && value.name && !value.postalCode;

const validations = {
  selectedPickupLocation: (value, props) => {
    if (value && !value.name) {
      return props.showDestination ? 'Select pickup location' : 'Select Service Location';
    }
    if (noPostalCode(value) && value.facilityFull.typeId !== props.location.placeTypeEnum.geopoint) {
      return 'Pickup location must include postal code';
    }
    return null;
  },
  selectedDestinationLocation: (value, props) => {
    if (value && !value.name) {
      return 'Select a dropoff location';
    }
    if (noPostalCode(value) && value.facilityFull.typeId !== props.location.placeTypeEnum.geopoint) {
      return 'Destination must include postal code';
    }
    return null;
  },
};
export default withValidations(validations)(Frame);
