import { createAction } from '@reduxjs/toolkit';

export const deleteLoadedRecipient = createAction('[RequestShare] deleteLoadedRecipient');

export const reducer = {
  [deleteLoadedRecipient]: (state, id) => ({
    ...state,
    loadedRecipients: state.loadedRecipients.filter(el => Number(el.id) !== Number(id)),
  }),
};
