import React, { useEffect } from 'react';
import withValidations from '../common/withValidations';
import Styled from './MedicalService.frame';
import { CallType } from 'enums';

const validations = {
  selectedMedicalService: (value, props) => {
    if (props.callTypeId === CallType.DEFAULT && (!props.transportReasons || props.transportReasons.length === 0)) {
      return 'Select medical service';
    } else if (
      props.callTypeId === CallType.DEFAULT &&
      props.pcpEquipmentIds.some(eq => props.therapistEquipmentIds.includes(eq)) &&
      props.transportReasons.length > 1
    ) {
      return 'Cannot combine a Primary care service with another service';
    }
    return null;
  },
};

const Validated = props => {
  useEffect(
    () => {
      if (props.validateMedicalService) props.validateAll(props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.validateMedicalService, props.transportReasons, props.therapistEquipmentIds],
  );
  useEffect(
    () => {
      props.setSaveErrorMessage(props.errors);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.errors],
  );
  return <Styled {...props} />;
};

export default withValidations(validations)(Validated);
