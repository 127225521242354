import createMiddleware from './helper';
import { createOffer, setCreateInProgress, getOffersSuccess } from '../actions/offer.actions';
import { newETArequiredForProviderTrip } from '../../messages'
import * as formFlowActions from '../actions/formFlow.actions';

const offerCreateMdl = createMiddleware(createOffer, ({ dispatch }) => {
  dispatch(setCreateInProgress(true));
});

const getOffersSuccessfully = createMiddleware(getOffersSuccess, ({dispatch, getState}) => {
  const { offers } = getState();
  const isOfferAccepted = offers.offers.some(offer => offer.offerStatus.name === 'accepted');

  if (isOfferAccepted) {
    dispatch(formFlowActions.changeDialogContent(newETArequiredForProviderTrip))
  }
});


export default [offerCreateMdl, getOffersSuccessfully];
