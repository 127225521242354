import { get } from 'lodash';
import { createReducer } from 'redux-act';
import * as actions from './actions';

export const initialState = {
  attachments: [],
  attachmentTypes: [],
  signatureActorTypeId: null,
};

const reducer = createReducer(
  {
    [actions.removeAttachment]: (state, payload) => ({
      ...state,
      attachments: state.attachments.filter(el => el.index !== payload.index),
    }),
    [actions.loadAttachmentsSuccess]: (state, payload) => ({
      ...state,
      attachments: payload.map((att, i) => {
        att.index = i;
        return att;
      }),
    }),
    [actions.addAttachmentSuccess]: (state, payload) => {
      const attachment = payload;
      attachment.index = state.attachments.length + 1;
      return {
        ...state,
        attachments: [...state.attachments, attachment],
      };
    },
    [actions.loadAttachmentTypesSuccess]: (state, payload) => ({
      ...state,
      attachmentTypes: payload.filter(type => type.name !== 'EMT Signature'),
    }),
    [actions.loadSignatureActorTypesSuccess]: (state, payload) => ({
      ...state,
      signatureActorTypeId: get(payload.find(type => type.name === 'AmbUser'), 'id'),
    }),
  },
  initialState,
);

export default reducer;
