import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 13 18" {...props}>
      <circle style={{ fill: '#335B6C' }} cx="8" cy="8" r="8" />
      <g>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M7.1,9.8c0-0.6,0.1-1.1,0.2-1.4c0.1-0.4,0.4-0.7,0.8-1C8.4,7,8.7,6.7,8.8,6.5C9,6.3,9,6.1,9,5.8
          c0-0.7-0.3-1.1-1-1.1c-0.3,0-0.6,0.1-0.8,0.3C7.1,5.3,7,5.5,7,5.9H5.1c0-0.8,0.3-1.4,0.8-1.9C6.4,3.5,7.1,3.3,8,3.3
          c0.9,0,1.6,0.2,2.1,0.7c0.5,0.4,0.7,1,0.7,1.8c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.7-0.8,1.1L9.3,8.3C8.9,8.7,8.8,9,8.7,9.4l0,0.4
          H7.1z M6.9,11.8c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
          c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.6-0.1-0.7-0.3C7,12.3,6.9,12,6.9,11.8z"
        />
      </g>
    </svg>
  );
}
