import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import TelehealthLink from './TelehealthLink.frame';
import * as serviceActions from '../../redux/actions/service.actions';

const TelehealthLinkContainer = props => {
  return <TelehealthLink {...props} />;
};

function mapStateToProps(state) {
  return {
    telehealthLinkValue: get(state, ['requests', 'request', 'telehealthDetail', 'telehealthLink'], ''),
  };
}
export default connect(mapStateToProps, {
  ...serviceActions,
})(TelehealthLinkContainer);
