import * as etaActions from '../../actions/eta.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as requestsActions from '../../actions/requests.actions';
import { tripScheduled } from '../../../messages';
import { POLL_IN_PROGRESS_REASONS } from '../../../enums';
import { rejectEtas } from './rejectEta.helper';

export const rejectETAMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (etaActions.rejectETA.match(action)) {
      dispatch(formFlowActions.setTriggerValidation(true));
    }

    if (etaActions.rejectETA.match(action) && !getState().formFlow.etaWasAccepted) {
      const { payload } = action;
      const { offeredEtas, rejectedEtas } = rejectEtas(getState().eta.offeredEtas, payload.etaIds);
      if (offeredEtas.find(offeredEta => offeredEta.unitId)) {
        dispatch(etaActions.requestETAinProgress(true));
        setTimeout(() => {
          dispatch(
            etaActions.requestETASuccess({
              offeredEtas,
            }),
          );
          dispatch(etaActions.clearPollInProgress());
        }, 800);
      } else {
        dispatch(
          etaActions.setAutoETAFailure({
            pollInProgress: true,
            pollInProgressReason: payload.pollInProgressReason || POLL_IN_PROGRESS_REASONS.ETA_REJECTED,
            rejectedEtas,
          }),
        );
      }
    }

    if (etaActions.rejectETA.match(action) && getState().formFlow.etaWasAccepted) {
      dispatch(requestsActions.editRequest(getState().requests.request.id));
      dispatch(formFlowActions.setScheduledMessage(tripScheduled));
    }
  };

export default [rejectETAMdl];
