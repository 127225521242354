import { createAction } from '@reduxjs/toolkit';

export const setDateTime = createAction('[ETA] setDateTime');
export const setPreferredTimeOfDay = createAction('[ETA] setPreferredTimeOfDay');
export const setETAButtonDisabled = createAction('[ETA] setETAButtonDisabled');
export const requestETA = createAction('[ETA] requestETA');
export const requestETAinProgress = createAction('[ETA] requestETAinProgress');
export const requestETASuccess = createAction('[ETA] requestETASuccess');
export const requestETAError = createAction('[ETA] requestETAError');
export const acceptETAClick = createAction('[ETA] acceptETAClick');
export const acceptETA = createAction('[ETA] acceptETA');
export const acceptETASuccess = createAction('[ETA] acceptETASuccess');
export const rejectETA = createAction('[ETA] rejectETA');
export const resetETA = createAction('[ETA] resetETA');
export const rejectETASuccess = createAction('[ETA] rejectETASuccess');
export const turndownETA = createAction('[ETA] turndownETA');
export const loadRequestDetails = createAction('[ETA] loadRequestDetails');
export const requestSchedulefromPoll = createAction('[ETA] requestSchedulefromPoll');
export const turndownSuccess = createAction('[ETA] turndownSuccess');
export const parseETAFromRequest = createAction('[ETA] parseETAFromRequest');
export const updateSchedule = createAction('[ETA] updateSchedule');
export const updateScheduleSuccess = createAction('[ETA] updateScheduleSuccess');
export const getETAFromRequest = createAction('[ETA] getETAFromRequest');
export const setAutoETAFailure = createAction('[ETA] setAutoETAFailure');
export const setETAWasAcceptedState = createAction('[ETA] setETAWasAcceptedState');
export const clearPollInProgress = createAction('[ETA] clearPollInProgress');
export const setPollInProgress = createAction('[ETA] setPollInProgress');
export const clickGetETA = createAction('[ETA] clickGetETA');
export const clickSeeOtherETAs = createAction('[ETA] clickSeeOtherETAs');
export const asyncRequestETASuccess = createAction('[ETA] asyncRequestETASuccess');
export const requestETATimeout = createAction('[ETA] requestETATimeout');
export const asyncUpsertScheduleSuccess = createAction('[ETA] asyncUpsertScheduleSuccess');
export const asyncCreateNotReservedSuccess = createAction('[ETA] asyncCreateNotReservedSuccess');
export const reserve = createAction('[ETA] reserve');
export const createNotReserved = createAction('[ETA] createNotReserved');
export const setEtaType = createAction('[ETA] setEtaType');
export const getAutoETA = createAction('[ETA] getAutoETA');
export const getEtaForAppmt = createAction('[ETA] getEtaForAppmt');
export const getRequestedArrivalTimeSuccess = createAction('[ETA] getRequestedArrivalTimeSuccess');
export const setAppmtTime = createAction('[ETA] setAppmtTime');
export const unReserve = createAction('[ETA] unReserve');
export const setTimezone = createAction('[ETA] setTimezone');
export const resetETAtimes = createAction('[ETA] resetETAtimes');
export const noLongerReserved = createAction('[ETA] noLongerReserved');
export const setMaxDateAllowed = createAction('[ETA] setMaxDateAllowed');
export const setEnforcePromisedWindow = createAction('[ETA] setEnforcePromisedWindow');
