import { createAction } from '@reduxjs/toolkit';

export const onDetailsChange = createAction('[CancelRequest] onDetailsChange');

export const reducer = {
  [onDetailsChange]: (state, payload) => ({
    ...state,
    details: payload,
  }),
};
