import { createReducer } from 'redux-act';
import * as action from '../actions/supportPhone.actions';

const initialState = '';

const supportPhoneReducer = createReducer(
  {
    [action.setSupportPhone]: (state, payload) => ({
      ...state,
      phone: payload,
    }),
  },
  initialState,
);

export default supportPhoneReducer;
