import React from 'react';
import { connect } from 'react-redux';

import PatientInfoBoxFrame from './PatientInfoBoxFrame';
import { patientCoverageDisplay, chiefComplaintDisplay, notesDisplay } from '../../flags/display.flag';
import * as infoboxActions from '../../redux/actions/infobox.actions';
import { patientDetailsExpanded } from '../../flags/collapse.flag';

const PatientInfoBoxContainer = props => <PatientInfoBoxFrame {...props} />;

function mapStateToProps(state) {
  return {
    patientCoverageDisplay: patientCoverageDisplay(state),
    chiefComplaintDisplay: chiefComplaintDisplay(state),
    notesDisplay: notesDisplay(state),
    patientInfoExpanded: patientDetailsExpanded(state),
  };
}

export default connect(
  mapStateToProps,
  { ...infoboxActions },
)(PatientInfoBoxContainer);
