import { createAction } from '@reduxjs/toolkit';

export const setMissingFieldsErrorMessages = createAction('[MissingFieldsModal] setMissingFieldsErrorMessages');

export const reducer = {
  [setMissingFieldsErrorMessages]: (state, payload) => ({
    ...state,
    errors: { ...state.errors, ...payload },
    hasErrors: Object.values({ ...state.errors, ...payload }).some(i => i !== null),
  }),
};
