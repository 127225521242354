import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { COMMENTS } from '../../enums';
import { parseTurndownPayload } from '../../parsers/turndown.parser';
import TurndownService from '../../services/turndown.service';
import * as errorActions from '../actions/error.actions';
import * as etaActions from '../actions/eta.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as requestsActions from '../actions/requests.actions';
import * as toastActions from '../actions/toast.actions';
import * as turndownActions from '../actions/turndown.actions';
import { errorPipe } from './epicsUtil';
import { updateComments } from './helpers/comment.helper';

export const turndownETAEpic = (action$, store) =>
  action$.pipe(
    filter(turndownActions.turndownETA.match),
    switchMap(() =>
      from$(TurndownService.turndownETA(parseTurndownPayload(store.value))).pipe(
        mergeMap(response =>
          concat$(
            of$(etaActions.rejectETASuccess()),
            of$(etaActions.turndownETA()),
            of$(turndownActions.createComment()),
            of$(turndownActions.setTurndownInProgress(false)),
            of$(requestsActions.updateRequestSuccess(response)),
            of$(formFlowActions.setCurrentView('edit')),
            of$(toastActions.onToast('Turndown Saved')),
            of$(errorActions.onError(response)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const createTurndownCommentEpic = (action$, store) =>
  action$.pipe(
    filter(turndownActions.createComment.match),
    filter(() => store.value.turndown.comments),
    switchMap(() =>
      updateComments(store, store.value.turndown.comments, COMMENTS.TYPES.CANCEL).pipe(
        map(payload => errorActions.onError(payload)),
        errorPipe,
      ),
    ),
  );

export const fetchTurndownTypesEpic = action$ =>
  action$.pipe(
    filter(turndownActions.setTurndownDialogOpen.match),
    filter(action => action.payload),
    switchMap(() =>
      from$(TurndownService.getTurndownTypes()).pipe(
        map(response => turndownActions.setTurndownTypes(response)),
        errorPipe,
      ),
    ),
  );
