import { createReducer } from 'redux-act';
import * as action from '../actions/toast.actions';
import toastMessage from '../../schemas/toastMessage.schema';

const initialState = {
  toastMessage: toastMessage.parse({}),
};

const toastReducer = createReducer(
  {
    [action.onToast]: (state, payload) => ({
      ...state,
      toastMessage: toastMessage.parse({ message: payload, open: true }),
    }),
    [action.clearToast]: state => ({
      ...state,
      toastMessage: toastMessage.parse({}),
    }),
  },
  initialState,
);

export default toastReducer;
