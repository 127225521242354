import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ErrorOutline from '../icons/errorOutline';
import { styled } from '@mui/system';
import { theme } from 'theme';
import store from '../../../redux/store/configureStore';
import * as errorActions from '../../../redux/actions/error.actions';

const Title = styled(DialogTitle)(({ color, titlestyles }) => ({
  textAlign: titlestyles.textAlign,
  color: color,
  '& > svg': {
    verticalAlign: 'text-bottom',
    marginRight: 5,
  },
}));

const StyledDialog = styled(Dialog)(({ maxWidth }) => ({
  '.MuiDialog-paper': {
    margin: 'auto',
    maxWidth: maxWidth,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '10px 24px 24px 24px',
  '& > p': {
    color: theme.palette.primary.dark,
  },
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  padding: 0,
  margin: '12px 12px 12px 0',
  '& > .MuiButton-root': {
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: 'rgba(30, 192, 217, 0.1)',
    },
  },
}));

class ConfirmationDialog extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.node,
    children: PropTypes.node.isRequired,
    cancel: PropTypes.bool,
    disableConfirm: PropTypes.bool,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    warning: PropTypes.bool,
    maxWidth: PropTypes.string,
    titlestyles: PropTypes.shape(),
  };
  static defaultProps = {
    cancel: false,
    disableConfirm: false,
    okText: 'Ok',
    cancelText: 'Cancel',
    warning: false,
    maxWidth: '360px',
    titlestyles: { textAlign: 'center' },
  };

  handleCancel = () => {
    this.props.onClose(false);
    this.resetHarError();
  };

  handleOk = () => {
    this.props.onClose(true);
    this.resetHarError();
  };

  downloadHarFile = () => {
    const harJson = this.props.harJson;
    const blob = new Blob([harJson], { type: 'application/har+json' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = 'harFile.har';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.resetHarError();
  };

  resetHarError = () => {
    store.dispatch(errorActions.setHarError(null));
  };

  // workaround for deprecated disableBackdropClick prop on Dialog
  handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    this.props.onClose();
    this.resetHarError();
  };

  render() {
    const {
      title,
      subTitle,
      children,
      cancel,
      disableConfirm,
      okText,
      cancelText,
      warning,
      titlestyles,
      onClose,
      harJson,
      ...other
    } = this.props;
    return (
      <StyledDialog
        onClose={this.handleClose}
        disableEscapeKeyDown
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-content"
        {...other}
      >
        {title && (
          <Title
            id="confirmation-dialog-title"
            color={warning ? theme.palette.primary.red : ''}
            titlestyles={titlestyles}
          >
            {warning && <ErrorOutline />}
            {title}
            <div />
            {subTitle}
          </Title>
        )}
        <StyledDialogContent id="confirmation-dialog-content">{children}</StyledDialogContent>
        <StyledDialogActions>
          {harJson && (
            <Button id="confirmation-dialog-download" onClick={this.downloadHarFile} color="secondary">
              Download <br /> Debug Logs
            </Button>
          )}
          {cancel && (
            <Button id="confirmation-dialog-cancel" onClick={this.handleCancel} color="secondary">
              {cancelText}
            </Button>
          )}
          <Button id="confirmation-dialog-ok" onClick={this.handleOk} color="secondary" disabled={disableConfirm}>
            {okText}
          </Button>
        </StyledDialogActions>
      </StyledDialog>
    );
  }
}

export default ConfirmationDialog;
