import { createAction } from '@reduxjs/toolkit';

export const searchPatient = createAction('[Patient] searchPatient');
export const updatePatientsList = createAction('[Patient] updatePatientsList');
export const controlPatientModal = createAction('[Patient] controlPatientModal');
export const loadPatient = createAction('[Patient] loadPatient');
export const selectPatient = createAction('[Patient] selectPatient');
export const savePatient = createAction('[Patient] savePatient');
export const setPatientInfo = createAction('[Patient] setPatientInfo');
export const setPhone = createAction('[Patient] setPhone');
export const autoSavePatientDetails = createAction('[Patient] autoSavePatientDetails');
export const setDirty = createAction('[Patient] setDirty');
export const selectPatientFromExternal = createAction('[Patient] selectPatientFromExternal');
export const saveSearch = createAction('[Patient] saveSearch');
export const asyncSearchPatient = createAction('[Patient] asyncSearchPatient');
export const setSearchInProgress = createAction('[Patient] setSearchInProgress');
export const cancelSearchPatient = createAction('[Patient] cancelSearchPatient');
