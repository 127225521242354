import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PatientCoverage from './PatientCoverage.validations';
import * as infoboxActions from '../../../redux/actions/infobox.actions';
import * as eligibilityActions from '../../../redux/actions/eligibility.actions';
import { prebillerVerifiedDisabled } from '../../../flags/prebilling.flag';
import { hasFacesheet, agencyRequiresCoverage } from '../../../flags/valid.flag';
import CoverageService from '../../../services/coverage.service';
import getBillerVerifiedTooltip from '../../../parsers/tooltip.parser';

const PatientCoverageContainer = props => {
  const [errors, setErrors] = useState({});
  const [coordinationTypes, setCoordinationTypes] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const getCoordinationTypes = async () => {
      const result = await CoverageService.getCoordinationTypes();

      const sorted = _.sortBy(result, type => type.sortOrder);
      sorted.length = Math.min(sorted.length, window.ambulnzConfig.constants.MAX_COORDINATION_TYPES_FOR_DISPLAY || 3);

      if (isMounted) {
        setCoordinationTypes(sorted);
      }
    };

    getCoordinationTypes();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleErrors = (coordinationTypeId, hasErrors) => {
    const updatedErrors = {
      ...errors,
      [coordinationTypeId]: hasErrors,
    };

    setErrors(updatedErrors);

    const hasError = _.reduce(updatedErrors, (acc, error) => acc || error, false);
    props.handleError(hasError);
  };

  return (
    !props.loadCoverageInProgress && (
      <PatientCoverage {...props} handleErrors={handleErrors} coordinationTypes={coordinationTypes} />
    )
  );
};

PatientCoverageContainer.propTypes = {
  handleError: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    triggerPatientValidation: state.formFlow.triggerPatientValidation,
    html: state.eligibility.html,
    isBillerVerified: state.requests.isBillerVerified,
    prebillerVerifiedDisabled: prebillerVerifiedDisabled(state),
    patientFirstName: state.infobox.patientFirstName,
    patientLastName: state.infobox.patientLastName,
    patientBirthday: state.infobox.patientBirthday,
    errorMessage: state.eligibility.errorMessage,
    isBillerVerifiedTooltip: getBillerVerifiedTooltip(state),
    billerVerifiedAt: moment(state.requests.billerVerifiedAt).format('MM/DD'),
    billerVerifiedBy: state.requests.billerVerifiedBy,
    user: state.user.model,
    loadedRequest: state.formFlow.loadedRequest,
    facesheetAttached: hasFacesheet(state),
    coverageRequired: agencyRequiresCoverage(state),
    eligibilities: state.infobox.eligibilities,
    loadCoverageInProgress: state.infobox.loadCoverageInProgress,
  };
}

export default connect(mapStateToProps, {
  ...infoboxActions,
  ...eligibilityActions,
})(PatientCoverageContainer);
