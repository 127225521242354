import { createAction } from '@reduxjs/toolkit';

export const fetchRequestReasonTypes = createAction('[CancelRequest] fetchRequestReasonTypes');
export const fetchRequestReasonTypesResponse = createAction('[CancelRequest] fetchRequestReasonTypesResponse');

export const reducer = {
  [fetchRequestReasonTypesResponse]: (state, payload) => ({
    ...state,
    reasonTypes: payload.filter(r => r.id !== window.ambulnzConfig.cancelReasonTypes.DRY_RUN),
  }),
};
