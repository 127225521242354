import React from 'react';
import PropTypes from 'prop-types';
import AmbTextField from 'components/common/AmbTextField';
import AmbGrid from 'components/common/AmbGrid';
import PhoneInput from '../../common/PhoneInput';
import AmbExpansionPanel from '../../common/AmbExpansionPanel';
import Requester from '../../common/icons/Requester';
import { theme } from 'theme';

class RequesterFrame extends React.Component {
  state = {
    expanded: this.props.expanded,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showEtaProgress) {
      this.setState({ expanded: false });
    }
    if (nextProps.hasErrors) {
      this.setState({ expanded: true });
    }
    if (nextProps.triggerValidation !== this.props.triggerValidation && nextProps.triggerValidation) {
      this.props.validateAll(nextProps);
    }
    if (nextProps.validateRequester !== this.props.validateRequester && nextProps.validateRequester) {
      this.props.validateAll(nextProps);
    }
    this.props.setSaveErrorMessage(nextProps.errors);
  }

  onPhoneNumberChange = event => {
    this.props.setRequesterPhone(event.target.value.replace(/\D/g, ''));
  };

  handleExtensionChange = event => {
    this.props.setRequesterInfo({ [event.target.name]: event.target.value.replace(/\D/g, '') });
  };

  handleInputChange = event => {
    this.props.setRequesterInfo({ [event.target.name]: event.target.value });
  };

  handleInputBlur = () => {
    this.props.autoSaveRequesterInfo();
  };

  togglePanel = () => {
    if (this.state.expanded) {
      this.props.validateAll(this.props);
    }
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { expanded } = this.state;
    const { requesterFirstName, requesterLastName, requesterPhone, requesterExtension } = this.props;
    const title = (
      <>
        {requesterFirstName} {requesterLastName} {requesterPhone || 'Requester'}
        {requesterExtension ? `, Ext. ${requesterExtension}` : ''}
      </>
    );
    const inputProps = {
      style: { ...theme.mixins.input },
    };
    const customLabelProps = {
      style: { width: 160, fontSize: 14, color: theme.palette.grey.grey80 },
      shrink: true,
    };

    return (
      <AmbExpansionPanel
        icon={<Requester style={{ height: 15, width: 15, marginTop: 2, fill: theme.palette.icon.primary }} />}
        title={title}
        expanded={expanded}
        error={this.props.hasErrors}
        onTogglePanel={this.togglePanel}
        id="RequesterPanel"
      >
        <div style={{ width: '100%' }}>
          <AmbGrid style={{ height: 85, gap: '8px', padding: '15px 5px 5px 0' }}>
            <AmbGrid style={{ maxWidth: 110 }}>
              <AmbTextField
                value={this.props.requesterFirstName}
                InputProps={inputProps}
                InputLabelProps={customLabelProps}
                name="requesterFirstName"
                id="RequesterFirstNameInput"
                fullWidth
                label="Requester First Name"
                required
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                error={!!this.props.errors.requesterFirstName}
                disabled={this.props.disabled || null}
              />
            </AmbGrid>
            <AmbGrid style={{ maxWidth: 120 }}>
              <AmbTextField
                value={this.props.requesterLastName}
                InputProps={inputProps}
                InputLabelProps={customLabelProps}
                name="requesterLastName"
                id="RequesterLastNameInput"
                fullWidth
                label="Requester Last Name"
                required
                onChange={this.handleInputChange}
                onBlur={this.handleInputBlur}
                error={!!this.props.errors.requesterLastName}
                disabled={this.props.disabled || null}
              />
            </AmbGrid>
            <AmbGrid style={{ maxWidth: 150, marginTop: -2 }}>
              <PhoneInput
                value={this.props.requesterPhone}
                name="requesterPhone"
                id="RequesterPhoneInput"
                label="Requester Phone Number"
                required
                onChange={this.onPhoneNumberChange}
                onBlur={this.handleInputBlur}
                error={!!this.props.errors.requesterPhone}
                disabled={this.props.disabled || null}
              />
            </AmbGrid>
            <AmbGrid style={{ maxWidth: 50 }}>
              <AmbTextField
                value={this.props.requesterExtension || ''}
                inputProps={{ maxLength: 10 }}
                InputLabelProps={customLabelProps}
                name="requesterExtension"
                id="RequesterExtensionInput"
                fullWidth
                label="Extension"
                onChange={this.handleExtensionChange}
                onBlur={this.handleInputBlur}
                disabled={this.props.disabled || null}
              />
            </AmbGrid>
          </AmbGrid>
        </div>
      </AmbExpansionPanel>
    );
  }
}

RequesterFrame.propTypes = {
  setRequesterPhone: PropTypes.func.isRequired,
  autoSaveRequesterInfo: PropTypes.func.isRequired,
  requesterFirstName: PropTypes.string.isRequired,
  requesterLastName: PropTypes.string.isRequired,
  requesterPhone: PropTypes.string.isRequired,
  requesterExtension: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setRequesterInfo: PropTypes.func.isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  validateAll: PropTypes.func.isRequired,
  focusRequesterFirstName: PropTypes.bool.isRequired,
  showEtaProgress: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

RequesterFrame.defaultProps = {
  disabled: false,
  requesterExtension: '',
};

export default RequesterFrame;
