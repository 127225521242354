import React from 'react';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import AmbSwitch from 'components/common/AmbSwitch';

export const WillCallFrame = ({
  handleChange,
  willCall,
  disabled,
  open,
  request,
  handleClose,
  handlePatientCanActivateChange,
  canRequestBeActivatedByPatient,
  isCanRequestBeActivatedByPatientEnabled,
}) => {
  return (
    <>
      <label style={{ marginTop: 10, marginRight: 10 }}>
        <AmbSwitch id="WillCallSwitch" disabled={disabled || null} onChange={handleChange} checked={willCall} />
        Will Call
      </label>
      {willCall && request.originalRequestId && isCanRequestBeActivatedByPatientEnabled && (
        <label style={{ marginTop: 10 }}>
          <AmbSwitch
            disabled={disabled || null}
            onChange={handlePatientCanActivateChange}
            checked={canRequestBeActivatedByPatient}
          />
          Can patient activate?
        </label>
      )}
      {open && (
        <ConfirmationDialog open={open} onClose={handleClose} title="Unassign Unit" fullWidth cancel warning>
          <p>Please confirm that you wish to unassign the unit and change this trip to Will Call.</p>
        </ConfirmationDialog>
      )}
    </>
  );
};

export default WillCallFrame;
