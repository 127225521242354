import styled from '@emotion/styled';
import { palette } from '../../../theme';

export const Label = styled.label`
  font-size: 12px;
  line-height: 1.3;
  padding-bottom: 2px;
  color: ${palette.grey.light3};
`;

export const Eta = styled.output`
  font-size: 16px;
  padding-bottom: 4px;
  color: ${({ warning }) => (warning ? palette.primary.red : palette.text.tertiary)};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  display: block;
`;
