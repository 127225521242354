import { createAction } from '@reduxjs/toolkit';

export const resetRecipients = createAction('[RequestShare] resetRecipients');

export const reducer = {
  [resetRecipients]: state => ({
    ...state,
    emailRecipients: [],
    phoneRecipients: [],
  }),
};
