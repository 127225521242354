import withValidations from '../../common/withValidations';

const validations = {
  patientFirstName: (value, props) => {
    if (props.agencyRequiresPatientInfo && (!props.facesheetAttached || props.isCar) && value.length === 0) {
      return 'Patient must have a first name';
    }
    return null;
  },
  patientLastName: (value, props) => {
    if (props.agencyRequiresPatientInfo && (!props.facesheetAttached || props.isCar) && value.length === 0) {
      return 'Patient must have a last name';
    }
    return null;
  },
  patientEmail: value => {
    if (value && !/^[\w+-]+(\.[+\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
      return 'Patient must have a valid email';
    }
    return null;
  },
  patientBirthday: (value, props) => {
    if (props.agencyRequiresPatientInfo && (!props.facesheetAttached || props.isCar) && !value) {
      return 'Patient must have a date of birth';
    }
    return null;
  },
  patientSsn: value => {
    if (!value) {
      return null;
    }

    if (!/^\d{9}/i.test(value)) {
      return 'SSN has a wrong format. Try 999-99-9999 or empty field if patient does not have SSN';
    }

    return null;
  },
  phone: (value, props) => {
    if (
      (props.isNonTransport || (!props.isNonTransport && props.agencyRequiresPatientInfo && props.requirePhone)) &&
      (!value || (value && value.length === 0))
    ) {
      return 'Patient must have a phone number';
    }
    if (value && value.length < 14) {
      return 'Patient phone number must have all digits';
    }
    return null;
  },
};

export default component => withValidations(validations)(component);
