import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import { DEFAULT_SHIFT_MSG_MAP, USER_TYPES } from '../../../enums';
import ConfirmConflictDialogWidget from '../ConfirmConflictDialogWidget';
import AmbGrid from 'components/common/AmbGrid';

const styles = {
  card: {
    width: '100%',
    margin: '10px 0 15px 0',
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  },
};
export default function OfferedEtaCard(props) {
  const [conflictDialogOpen, setConflictDialogOpen] = useState(false);
  const [shiftMsgMap, setshiftMsgMap] = useState(DEFAULT_SHIFT_MSG_MAP);
  useEffect(
    () => {
      const {
        leasedShiftInternalUserMessage,
        nonLeasedShiftInternalUserMessage,
        leasedShiftExternalUserMessage,
        nonLeasedShiftExternalUserMessage,
        leasedShiftNoEtaMessage,
      } = props.agency;
      const map = {
        [USER_TYPES.DISPATCHER]: {
          provider: nonLeasedShiftInternalUserMessage,
          leasedShiftProvider: leasedShiftInternalUserMessage,
        },
        [USER_TYPES.REQUESTER]: {
          provider: nonLeasedShiftExternalUserMessage,
          leasedShiftProvider: leasedShiftExternalUserMessage,
        },
        noEta: {
          leasedShiftProvider: leasedShiftNoEtaMessage,
        },
      };

      setshiftMsgMap(map);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.agency.requesterAgencyId],
  );

  return (
    <>
      {(props.eta || shiftMsgMap.noEta[props.type]) && (
        <div style={styles.card} data-testid={`card-${props.etaId}`}>
          <AmbGrid style={{ flexDirection: 'column' }}>
            <AmbGrid style={{ width: '100%' }}>
              <CardHeader
                {...props}
                shiftMsgMap={shiftMsgMap}
                handleOpenConflictDialog={() => setConflictDialogOpen(true)}
              />
            </AmbGrid>
            {props.eta && (
              <AmbGrid style={{ width: '100%' }}>
                <CardBody {...props} />
              </AmbGrid>
            )}
          </AmbGrid>
        </div>
      )}
      {conflictDialogOpen && (
        <ConfirmConflictDialogWidget
          unitId={props.unitId}
          conflict={props.conflict}
          handleAcceptETAClick={() => props.acceptETAClick({ etaId: props.etaId })}
          handleClose={() => setConflictDialogOpen(false)}
          open={conflictDialogOpen}
        />
      )}
    </>
  );
}

OfferedEtaCard.propTypes = {
  conflict: PropTypes.shape(),
  showEtaInfo: PropTypes.bool.isRequired,
  acceptETAClick: PropTypes.func,
  eta: PropTypes.string,
  etaId: PropTypes.number,
  unitId: PropTypes.string,
  agency: PropTypes.shape().isRequired,
};

OfferedEtaCard.defaultProps = {
  conflict: null,
  eta: null,
  etaId: 1,
  unitId: null,
};
