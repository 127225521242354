import { formatDateWithoutTimezone } from '../../helpers/dateFormatter';

const parseVal = val => (val ? `${val}, ` : '');
const buildTitle = state =>
  `${parseVal(state.address1)}${parseVal(state.address2)}${parseVal(state.city)}${parseVal(state.state)}${
    state.zip || ''
  }`;

export const formatPatientAdditionalDetailsTitle = state => {
  const { address1, address2, city, zip } = state;
  if (!address1 && !address2 && !city && !state.state && !zip) {
    return '';
  }

  const title = buildTitle(state);
  if (title.length > 48) {
    return `${title.substring(0, 51)}...`;
  }

  return title;
};

const additionalInfo = state =>
  formatPatientAdditionalDetailsTitle(state.patient).length
    ? `, ${formatPatientAdditionalDetailsTitle(state.patient)}`
    : '';

export const formatPatientDetailsTitle = state => {
  const { patientFirstName, patientMiddleName, patientLastName, patientBirthday } = state.infobox;
  if (patientFirstName && patientLastName && patientBirthday) {
    return `${patientFirstName} ${patientMiddleName} ${patientLastName} ${formatDateWithoutTimezone(
      patientBirthday,
      'MM/DD/YYYY',
    )} ${state.patient.phone} ${additionalInfo(state)}`.trim();
  }
  return 'Patient Demographics';
};

export const formatCoverageTitle = state => {
  const names = [];
  const reduceNames = (acc, k) => {
    const { payerName } = state.infobox.coverages[k];
    if (payerName) names.push(payerName);
  };
  Object.keys(state.infobox.coverages).reduce(reduceNames, '');
  return names.length ? names.join(', ') : 'Coverage';
};
