import ApiService from './api.service';

class CoverageService {
  static async getCoverages(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/coverages`;
    const response = await ApiService.get(url);
    return response.json();
  }

  static async updateCoverages(requestId, payload) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/submit`;

    try {
      const response = await ApiService.put(url, { coverages: payload });
      return response.json();
    } catch (error) {
      return Promise.resolve({
        error: {
          name: `Failed to update coverages`,
          message: 'Please contact support',
          status: 500,
        },
      });
    }
  }

  static async checkPayerRules(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/PayerRules`;
    const response = await ApiService.get(url);
    return response.json();
  }

  static async getCoordinationTypes() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/coordinationTypes`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default CoverageService;
