import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import AmbTextField from 'components/common/AmbTextField';
import AmbCheckbox from '../../../common/AmbCheckbox';
import { truncate } from '../../../common/helpers';
import SuggestionsInput from '../../../common/SuggestionsInput';
import AmbWarning from '../../../common/AmbWarning';
import CheckEligibility from '../../../Eligibility/CheckEligibility';
import CoverageReorder from '../CoverageReorder';
import { shouldValidateAll } from './Coverage.validations';
import displayIf from '../../../../helpers/displayIfHelper';
import AmbRadioInput from 'components/common/AmbRadioInput';
import { theme } from '../../../../theme';

const StyledFieldset = styled.fieldset`
  margin: 0;
  border: 0;
  padding: 0;
`;

const CoverageFrame = ({ first = false, ...props }) => {
  const [checked, setChecked] = useState({});

  const handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    onInputChange(e);
    setChecked(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const prevProps = useRef(props);

  const payerInvalid = () =>
    !props.payerName || !!props.errors.payerInput || !!props.errors.policyNumber || !!props.errors.authorizationNumber;

  const getSublabel = () => {
    if (props.errors.payerInput) {
      return props.errors.payerInput;
    }

    if (props.disabled && props.payer.inactive) {
      return 'Inactive Payer';
    }

    return '';
  };

  useEffect(
    () => {
      if (props.hasErrors !== prevProps.current.hasErrors) {
        props.handleErrors(prevProps.current.coordinationTypeId, props.hasErrors);
      }

      if (shouldValidateAll(prevProps.current, props)) {
        props.validateAll(props);
      }

      if (prevProps.current.payer.payerId !== props.payer.payerId) {
        props.validateAll(props);
      }

      if (prevProps.current.isActive && payerInvalid()) {
        props.checkPayerActive(false);
      }

      props.setSaveErrorMessage(prevProps.current.transformErrors(props.errors));
      prevProps.current = props;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props],
  );

  const {
    activeHelperText,
    checkPayerActive,
    errors,
    insuranceName,
    disabled,
    isDispatcher,
    onInputChange,
    onInputBlur,
    payer,
    inputId,
  } = props;

  const labelProps = {
    style: {
      ...theme.mixins.inputLabel,
    },
    shrink: true,
  };

  let rootStyles = {
    marginBottom: 60,
    position: 'relative',
  };

  let dividerStyles = {
    ...theme.mixins.divider,
    margin: 0,
    marginTop: -30,
    left: '-10px',
    width: '108%',
    height: 1,
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, .1)',
    border: 'none',
  };

  if (!props.reorder) {
    rootStyles = { ...rootStyles, marginBottom: 30 };
  }

  if (props.first) {
    dividerStyles = { ...dividerStyles, marginTop: -15 };
  }

  return (
    <div style={rootStyles} data-testid="coverage-component">
      <hr style={{ visibility: props.isLineHidden ? 'hidden' : 'visible', ...dividerStyles }} />
      <p style={{ marginTop: 15, fontSize: 14, fontWeight: 'bold' }}>{insuranceName}</p>
      <AmbGrid style={{ flexDirection: 'column' }}>
        <AmbGrid style={{ minWidth: '100%' }}>
          {isDispatcher && (
            <CheckEligibility
              onClick={() => props.checkEligibility()}
              isLoading={props.isEligibilityLoading}
              disabled={props.isEligibilityDisabled || props.isPayerInvalid()}
              upper
              isCreating={props.isEligibilityCreating}
            />
          )}
          <AmbGrid style={{ flexBasis: '70%' }}>
            <SuggestionsInput
              inputValue={props.payerInput}
              onInputValueChange={props.onPayerChange}
              items={props.payers}
              inputId={`${inputId}-payer-input`}
              dataTestId={`${inputId}-payer-input`}
              inputPlaceholder={`${insuranceName} name`}
              required={props.isRequired}
              error={!!errors.payerInput}
              disabled={disabled || null}
              subLabel={getSublabel()}
            />
            {isDispatcher && (
              <AmbCheckbox
                checked={props.isActive}
                name={`is${inputId}Active`}
                inputId={`${inputId}PayerActiveCheckbox`}
                handleChange={checkPayerActive}
                label={<>Active</>}
                propsStyles={{ marginTop: 15 }}
                disabled={payerInvalid() || props.disabled || null}
                helperText={truncate(activeHelperText, 40)}
                showHelperText={Boolean(props.payerName) && Boolean(activeHelperText)}
              />
            )}
          </AmbGrid>
        </AmbGrid>
        <AmbGrid style={{ display: displayIf(payer.showPolicyNumber), marginBottom: '15px' }}>
          <div>
            <AmbTextField
              InputLabelProps={labelProps}
              fullWidth
              name="policyNumber"
              value={props.policyNumber}
              label="Policy Number"
              onChange={onInputChange}
              onBlur={onInputBlur}
              required={props.coverageRequired}
              error={!!errors.policyNumber}
              id={`${inputId}PayerPolicyInput`}
              disabled={disabled || null}
            />
          </div>
        </AmbGrid>
        <AmbGrid style={{ display: displayIf(payer.authFieldName) }}>
          <div>
            <AmbTextField
              InputLabelProps={labelProps}
              fullWidth
              name="authorizationNumber"
              value={props.authorizationNumber}
              label={payer.authFieldName}
              onChange={onInputChange}
              onBlur={onInputBlur}
              required={props.coverageRequired}
              error={!!errors.authorizationNumber}
              id={`${inputId}PayerAuthorizationInput`}
              disabled={disabled || null}
            />
          </div>
        </AmbGrid>
        <AmbGrid
          style={{
            display: displayIf(payer.requiresMedicalNecessity),
            marginTop: 10,
          }}
        >
          <StyledFieldset>
            <legend style={{ fontSize: 12, color: theme.palette.primary.dark }}>
              Is Bed Bound?
              <span>&thinsp;*</span>
            </legend>
            <div
              role="radiogroup"
              aria-label="isBedBound"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: 40 }}
            >
              <label style={{ marginRight: 12, display: 'flex' }}>
                <AmbRadioInput
                  name="isBedBound"
                  value="true"
                  disabled={disabled || null}
                  onChange={e => handleInputChange(e)}
                  onBlur={onInputBlur}
                  checked={checked['isBedBound'] === 'true'}
                  style={{ marginRight: 4, color: theme.palette.secondary.main }}
                  inputPosition={{ left: 0 }}
                />
                <span>Yes</span>
              </label>
              <label style={{ marginRight: 12, display: 'flex' }}>
                <AmbRadioInput
                  name="isBedBound"
                  disabled={disabled || null}
                  value="false"
                  onChange={e => handleInputChange(e)}
                  onBlur={onInputBlur}
                  checked={checked['isBedBound'] === 'false'}
                  style={{ marginRight: 4, color: theme.palette.secondary.main }}
                  inputPosition={{ left: 0 }}
                />
                <span>No</span>
              </label>
            </div>
          </StyledFieldset>
        </AmbGrid>
        <AmbGrid
          style={{
            display: displayIf(payer.requiresMedicalNecessity),
            marginTop: 10,
          }}
        >
          <StyledFieldset>
            <legend style={{ fontSize: 12, color: theme.palette.primary.dark }}>
              Requires Oxygen?
              <span>&thinsp;*</span>
            </legend>
            <div
              aria-label="requiresOxygen"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: 40 }}
            >
              <label style={{ marginRight: 12, display: 'flex' }}>
                <AmbRadioInput
                  name="requiresOxygen"
                  disabled={disabled || null}
                  value="true"
                  onChange={e => handleInputChange(e)}
                  onBlur={onInputBlur}
                  checked={checked['requiresOxygen'] === 'true'}
                  style={{ marginRight: 4, color: theme.palette.secondary.main }}
                  inputPosition={{ left: 0 }}
                />
                <span>Yes</span>
              </label>
              <label style={{ marginRight: 12, display: 'flex' }}>
                <AmbRadioInput
                  name="requiresOxygen"
                  disabled={disabled || null}
                  value="false"
                  onChange={e => handleInputChange(e)}
                  onBlur={onInputBlur}
                  checked={checked['requiresOxygen'] === 'false'}
                  style={{ marginRight: 4, color: theme.palette.secondary.main }}
                  inputPosition={{ left: 0 }}
                />
                <span>No</span>
              </label>
            </div>
          </StyledFieldset>
        </AmbGrid>
        <AmbGrid style={{ display: displayIf(payer.showMedicarePartB), marginTop: 10 }}>
          <StyledFieldset>
            <legend style={{ fontSize: 12, color: theme.palette.primary.main }}>
              Has Medicare Part B?
              <span>&thinsp;*</span>
            </legend>
            <div
              aria-label="medicarePartB"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: 40 }}
            >
              <label style={{ marginRight: 12, display: 'flex' }}>
                <AmbRadioInput
                  name="medicarePartB"
                  disabled={disabled || null}
                  value="true"
                  onChange={e => handleInputChange(e)}
                  onBlur={onInputBlur}
                  checked={checked['medicarePartB'] === 'true'}
                  style={{ marginRight: 4, color: theme.palette.secondary.main }}
                  inputPosition={{ left: 0 }}
                />
                <span>Yes</span>
              </label>
              <label style={{ marginRight: 12, display: 'flex' }}>
                <AmbRadioInput
                  name="medicarePartB"
                  disabled={disabled || null}
                  value="false"
                  onChange={e => handleInputChange(e)}
                  onBlur={onInputBlur}
                  checked={checked['medicarePartB'] === 'false'}
                  style={{ marginRight: 4, color: theme.palette.secondary.main }}
                  inputPosition={{ left: 0 }}
                />
                <span>No</span>
              </label>
            </div>
          </StyledFieldset>
        </AmbGrid>
        <AmbWarning messages={props.warnings} />
        <AmbGrid style={{ position: 'absolute', top: '106%', left: '46%' }}>
          {props.reorder && !disabled && <CoverageReorder clickReorder={props.clickReorder} />}
        </AmbGrid>
      </AmbGrid>
    </div>
  );
};

CoverageFrame.propTypes = {
  isEligibilityLoading: PropTypes.bool.isRequired,
  isEligibilityDisabled: PropTypes.bool.isRequired,
  isEligibilityCreating: PropTypes.bool.isRequired,
  policyNumber: PropTypes.string.isRequired,
  authorizationNumber: PropTypes.string.isRequired,
  isBedBound: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  requiresOxygen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  medicarePartB: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  payer: PropTypes.shape().isRequired,
  payers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  payerInput: PropTypes.string.isRequired,
  payerName: PropTypes.string.isRequired,
  onPayerChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  checkPayerActive: PropTypes.func.isRequired,
  activeHelperText: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onInputBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  insuranceName: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  errors: PropTypes.shape().isRequired,
  validateAll: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isPayerInvalid: PropTypes.func.isRequired,
  checkEligibility: PropTypes.func.isRequired,
  setSaveErrorMessage: PropTypes.func.isRequired,
  transformErrors: PropTypes.func.isRequired,
  isLineHidden: PropTypes.bool.isRequired,
  reorder: PropTypes.bool.isRequired,
  clickReorder: PropTypes.func.isRequired,
  isDispatcher: PropTypes.bool.isRequired,
  handleErrors: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
  first: PropTypes.bool,
  coverageRequired: PropTypes.bool.isRequired,
};

export default CoverageFrame;
