import { createReducer } from 'redux-act';
import * as actions from './duplicateRequest.actions';

const initialState = {
  loading: false,
  completed: false,
  errorMessage: '',
};

const duplicateRequestReducer = createReducer(
  {
    [actions.complete]: state => ({
      ...state,
      completed: true,
    }),
    [actions.error]: (state, payload) => ({
      ...state,
      errorMessage: payload.message,
      completed: false,
      loading: false,
    }),
    [actions.duplicate]: state => ({
      ...state,
      loading: true,
    }),
    [actions.closeErrorModal]: state => ({
      ...state,
      errorMessage: '',
    }),
  },
  initialState,
);

export default duplicateRequestReducer;
