import { concat as concat$, of as of$ } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { autoSavePatientAdditionalDetails } from '../../components/PatientInfoBoxWidget/PatientDetailsWidget/PatientAdditionalInfoWidget/PatientAdditionalInfo.actions';
import * as FieldValidator from '../../validators/field.validators';
import * as errorActions from '../actions/error.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as patientActions from '../actions/patient.actions';
import { searchPatient, updatePatient, getProfile } from './helpers/patient.helper';
import { errorPipe } from './epicsUtil';

const validForSave = state =>
  state.infobox.patientFirstName &&
  state.infobox.patientLastName &&
  state.infobox.patientBirthday &&
  FieldValidator.additionalFieldsValid(state) &&
  (!state.service.bariatric || (state.infobox.patientWeight && state.infobox.patientHeight));

export const updatePatientEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.autoSavePatient.match),
    switchMap(() => updatePatient(store)),
    mergeMap(response =>
      concat$(
        of$(infoboxActions.updatePatientSuccess()),
        of$(patientActions.setDirty(false)),
        of$(formFlowActions.autoSaveInProgress(false)),
        of$(errorActions.onError(response)),
      ),
    ),
    catchError(error => of$(errorActions.onUhandledError('patient'))),
  );

export const autoSavePatientEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [patientActions.autoSavePatientDetails.match, autoSavePatientAdditionalDetails.match].some(match =>
        match(action),
      ),
    ),
    filter(
      () => !store.value.formFlow.submitInProgress && store.value.requests.request.id && store.value.patient.dirty,
    ),
    filter(() => validForSave(store.value)),
    mergeMap(() => concat$(of$(formFlowActions.autoSaveInProgress(true)), of$(infoboxActions.autoSavePatient()))),
  );

export const searchPatientData = (action$, store) =>
  action$.pipe(
    filter(patientActions.asyncSearchPatient.match),
    switchMap(() => searchPatient(store.value)),
    mergeMap(response =>
      concat$(
        of$(patientActions.saveSearch(store.value)),
        of$(patientActions.setSearchInProgress(false)),
        of$(!response.error ? patientActions.updatePatientsList(response) : errorActions.onError(response)),
      ),
    ),
  );

export const autoSavePatientSetDirtyEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        infoboxActions.setPatientDetails.match,
        infoboxActions.setBirthday.match,
        patientActions.setPatientInfo.match,
        patientActions.setPhone.match,
      ].some(match => match(action)),
    ),
    map(() => patientActions.setDirty(true)),
  );

export const getPatientCoverageEpic = (action$, store) => {
  let selectedPatient;
  return action$.pipe(
    filter(patientActions.selectPatientFromExternal.match),
    switchMap(action =>
      getProfile(
        store.value.userAccount.selectedUserAccount.id,
        (selectedPatient = action.payload),
        store.value.agency.selectedAgency.requesterAgencyId,
      ),
    ),
    mergeMap(response =>
      concat$(
        of$(!response.error ? patientActions.selectPatient(response) : patientActions.selectPatient(selectedPatient)),
      ),
    ),
    errorPipe,
  );
};
