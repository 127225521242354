import ApiService from './api.service';

// eslint-disable-next-line import/prefer-default-export
export async function getPcrStatus(requestId) {
  const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/pcrStatus`;

  let response = await ApiService.get(url);
  response = response.json();
  return response;
}
