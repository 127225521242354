import * as componentActions from '../../../components/RequestingAgencyWidget/RequestingAgency.actions';
import * as agencyActions from '../../actions/agency.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as infoboxActions from '../../actions/infobox.actions';

const query =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (componentActions.query.match(action)) {
      dispatch(agencyActions.queryAgencies(action.payload));
    }
  };

const select =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (componentActions.select.match(action)) {
      dispatch(agencyActions.fetchPlace(action.payload));
    }
  };

const clear =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (componentActions.clear.match(action)) {
      dispatch(agencyActions.clearAgency(action.payload));
    }
  };

const keyDown =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (componentActions.keyDown.match(action)) {
      dispatch(formFlowActions.keyDownETAField());
    }
  };

const setErrors =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (componentActions.setErrors.match(action)) {
      dispatch(infoboxActions.setSaveErrorMessage(action.payload));
    }
  };

const middlewares = [query, select, clear, keyDown, setErrors];
export default middlewares;
