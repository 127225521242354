import { flatten } from 'lodash';
import { parseETA } from '../../../parsers/eta.parser';

export function rejectEtas(offeredEtas, rejectedEtaIds) {
  const rejectedEtas = offeredEtas.filter(oe => rejectedEtaIds.includes(oe.id));
  const nextCandidateEtas = flatten(rejectedEtas.map(oe => parseETA(oe, oe.candidateIndex)));
  const newOfferedEtas = offeredEtas.filter(oe => !rejectedEtaIds.includes(oe.id)).concat(nextCandidateEtas);
  return {
    offeredEtas: newOfferedEtas,
    rejectedEtas,
  };
}
