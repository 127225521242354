/* eslint-disable camelcase */
import ApiService from '../api.service';

const orderService = {
  baseUrl: () => window.ambulnzConfig.tenantedOrderServiceUrl,

  async createOrder({
    attachments,
    call_type_id,
    chief_complaint_id,
    mobility_id,
    order_requirements,
    order_type_id,
    provider_id,
    requester_agency_id,
    service_type_ids,
    order_equipment_ids,
    requester,
    patient,
    coverages,
    locations,
    recurrence_rules,
    comments,
    requested_wait_secs,
  }) {
    const response = await ApiService.makeRequest(`${this.baseUrl()}orders`, 'POST', {
      attachments,
      call_type_id,
      chief_complaint_id,
      mobility_id,
      order_requirements,
      order_type_id,
      provider_id,
      requester_agency_id,
      service_type_ids,
      order_equipment_ids,
      requester,
      patient,
      coverages,
      locations,
      recurrence_rules,
      comments,
      requested_wait_secs,
    });
    return response.json();
  },

  async getOrders() {
    const response = await ApiService.get(`${this.baseUrl()}/orders`);
    return response.json();
  },

  async updateOrder(
    orderId,
    {
      call_type_id,
      chief_complaint_id,
      mobility_id,
      order_requirements,
      order_type_id,
      provider_id,
      requester_agency_id,
      requester_id,
      service_type_ids,
    },
  ) {
    const response = await ApiService.patch(`${this.baseUrl()}/orders/${orderId}`, {
      call_type_id,
      chief_complaint_id,
      mobility_id,
      order_requirements,
      order_type_id,
      provider_id,
      requester_agency_id,
      requester_id,
      service_type_ids,
    });
    return response.json();
  },

  async getOrder(orderId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}`);
    return response.json();
  },

  async getOrderByRequestId(requestId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/recurring/by_request_id/${requestId}`);
    return response.json();
  },

  async replaceOrder(orderId) {
    const response = await ApiService.put(`${this.baseUrl()}/orders/${orderId}`);
    return response.json();
  },

  async getOrderRequester(orderId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/requester`);
    return response.json();
  },

  async replaceOrderRequester(orderId, { requester_id }) {
    const response = await ApiService.put(`${this.baseUrl()}/orders/${orderId}/requester`, { requester_id });
    return response.json();
  },

  async updateOrderRequester(orderId, { requester_id }) {
    const response = await ApiService.patch(`${this.baseUrl()}/orders/${orderId}/requester`, { requester_id });
    return response.json();
  },

  async getOrderLocations(orderId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/locations`);
    return response.json();
  },

  async createOrderLocations(orderId, { department_id, location_type_id, place_id, room_number }) {
    const response = await ApiService.post(`${this.baseUrl()}/orders/${orderId}/locations`, {
      department_id,
      location_type_id,
      place_id,
      room_number,
    });
    return response.json();
  },

  async getOrderLocation(orderId, locationId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/locations/${locationId}`);
    return response.json();
  },

  async replaceOrderLocation(orderId, locationId) {
    const response = await ApiService.put(`${this.baseUrl()}/orders/${orderId}/locations/${locationId}`);
    return response.json();
  },

  async updateOrderLocation(orderId, locationId, { department_id, location_type_id, place_id, room_number }) {
    const response = await ApiService.patch(`${this.baseUrl()}/orders/${orderId}/locations/${locationId}`, {
      department_id,
      location_type_id,
      place_id,
      room_number,
    });
    return response.json();
  },

  async getOrderComments(orderId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/comments`);
    return response.json();
  },

  async createOrderComments(orderId, { comment_id }) {
    const response = await ApiService.post(`${this.baseUrl()}/orders/${orderId}/comments`, { comment_id });
    return response.json();
  },

  async getOrderComment(orderId, commentId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/comments/${commentId}`);
    return response.json();
  },

  async replaceOrderComment(orderId, commentId) {
    const response = await ApiService.put(`${this.baseUrl()}/orders/${orderId}/comments/${commentId}`);
    return response.json();
  },

  async updateOrderComment(orderId, commentId, { comment_id }) {
    const response = await ApiService.patch(`${this.baseUrl()}/orders/${orderId}/comments/${commentId}`, {
      comment_id,
    });
    return response.json();
  },

  async getOrderPatient(orderId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/patient`);
    return response.json();
  },

  async updateOrderPatient(orderId, { patient_id }) {
    const response = await ApiService.patch(`${this.baseUrl()}/orders/${orderId}/patient`, { patient_id });
    return response.json();
  },

  async replaceOrderPatient(orderId, { patient_id }) {
    const response = await ApiService.put(`${this.baseUrl()}/orders/${orderId}/patient`, { patient_id });
    return response.json();
  },

  async createOrderPatient(orderId) {
    const response = await ApiService.post(`${this.baseUrl()}/orders/${orderId}/patient`);
    return response.json();
  },

  async getOrderPatientCoverages(orderId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/patient/coverages`);
    return response.json();
  },

  async createOrderPatientCoverages(orderId, { coverage_id }) {
    const response = await ApiService.post(`${this.baseUrl()}/orders/${orderId}/patient/coverages`, { coverage_id });
    return response.json();
  },

  async getOrderPatientCoverage(orderId, coverageId) {
    const response = await ApiService.get(`${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}`);
    return response.json();
  },

  async replaceOrderPatientCoverage(orderId, coverageId) {
    const response = await ApiService.put(`${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}`);
    return response.json();
  },

  async updateOrderPatientCoverage(orderId, coverageId, { coverage_id }) {
    const response = await ApiService.patch(`${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}`, {
      coverage_id,
    });
    return response.json();
  },

  async getOrderPatientCoverageAuthorizations(orderId, coverageId) {
    const response = await ApiService.get(
      `${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}/authorizations`,
    );
    return response.json();
  },

  async createOrderPatientCoverageAuthorizations(orderId, coverageId, { authorization_id }) {
    const response = await ApiService.post(
      `${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}/authorizations`,
      { authorization_id },
    );
    return response.json();
  },

  async getOrderPatientCoverageAuthorization(orderId, coverageId, authorizationId) {
    const response = await ApiService.get(
      `${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}/authorizations/${authorizationId}`,
    );
    return response.json();
  },

  async replaceOrderPatientCoverageAuthorization(orderId, coverageId, authorizationId) {
    const response = await ApiService.put(
      `${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}/authorizations/${authorizationId}`,
    );
    return response.json();
  },

  async updateOrderPatientCoverageAuthorization(orderId, coverageId, authorizationId, { authorization_id }) {
    const response = await ApiService.patch(
      `${this.baseUrl()}/orders/${orderId}/patient/coverages/${coverageId}/authorizations/${authorizationId}`,
      { authorization_id },
    );
    return response.json();
  },

  async getTransportReasons(showInactive = false, onlyDefault = true, externalProviderIds = undefined, externalIdType = undefined) {
    let externalIdTypeParam = '';

    if (externalProviderIds && externalIdType) {
      const externalProviderIdsURLArray = externalProviderIds.map(id => `external_provider_ids[]=${id}`).join('&');

      externalIdTypeParam = `&${externalProviderIdsURLArray}&external_id_type=${externalIdType}`;
    }

    const response = await ApiService.get(
      `${this.baseUrl()}/transport_reasons?type_name=medical_health_service&show_inactive=${showInactive}&default=${onlyDefault}${externalIdTypeParam}`,
    );
    return response.json();
  },
};

export default orderService;
