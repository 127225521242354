import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as eligibilityActions from '../../../redux/actions/eligibility.actions';
import EligibilityCloseFrame from './EligibilityClose.frame';

class EligibilityCloseContainer extends React.Component {
  shouldShow = () => (this.props.portalInfo || this.props.html) && !this.props.isLoading;

  render() {
    return <EligibilityCloseFrame shouldShow={this.shouldShow} {...this.props} />;
  }
}

EligibilityCloseContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  portalInfo: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    html: state.eligibility.html,
    portalInfo: state.eligibility.portalInfo,
    eligibilities: state.eligibility.eligibilities,
  };
}

export default connect(mapStateToProps, { ...eligibilityActions })(EligibilityCloseContainer);
