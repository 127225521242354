import '@ambulnz/amb-autocomplete';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useEventListener from '../../common/useEventListener.hook';

const CallType = props => {
  const autocompleteEl = useRef(null);

  useEventListener(autocompleteEl, 'valueChange', v => {
    if (v.detail) {
      props.handleCallTypeChange(v.detail.id);
    }
  });

  return (
    <div style={{ minWidth: 300, width: 300 }}>
      {props.callTypeOptions.length && (
        <amb-autocomplete
          ref={autocompleteEl}
          id="select-calltype"
          value={props.callTypeId}
          options={JSON.stringify(
            props.callTypeOptions.map(c => ({
              id: c.key,
              name: c.value,
              disabled: c.disabled,
            })),
          )}
          placeholder="Select transport reason"
          disabled={props.disabled || null}
          error={props.hasErrors && !props.callTypeSelected ? 'Select transport reason' : ''}
        />
      )}
    </div>
  );
};

CallType.propTypes = {
  callTypeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  callTypeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleCallTypeChange: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  callTypeSelected: PropTypes.bool.isRequired,
};

export default CallType;
