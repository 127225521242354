import moment from 'moment-timezone';
import { MOBILE_HEALTH_MULTI_TRIP_CONFIG } from '../enums';

const isTelehealth = state => {
  const telehealthEq = state.service.equipment.find(e => e.name === 'Telehealth');
  if (telehealthEq) {
    return state.service.therapistEquipmentIds.includes(telehealthEq.id);
  }
  return false;
};

const isPCP = state => {
  const pcpEquipmentIds = state.service.equipment.filter(e => e.name.includes('PCP')).map(eq => eq.id);
  return pcpEquipmentIds.some(id => state.service.therapistEquipmentIds.includes(id));
};

export const getDefaultDate = state => {
  moment.tz.setDefault(state.eta.timezone);
  const defaultToToday = isTelehealth(state) || isPCP(state);
  const defaultDays = defaultToToday ? 0 : MOBILE_HEALTH_MULTI_TRIP_CONFIG['DEFAULT_DAYS'];
  const selectedTransportReason = state.service.callTypeOptions.find(callType => callType.key === state.service.callTypeId);
  const offset = selectedTransportReason?.defaultFromNowPickupTimeInMins || state.agency.requesterAgencyGroup.defaultTimeOffsetMinutes || 5;
  return moment().add(defaultDays, 'days').add(offset, 'minutes');
};

export const getMaxAllowedDate = state => {
  const { constants } = window.ambulnzConfig;
  moment.tz.setDefault(state.eta.timezone);
  const daysOfPromisedWindow = constants.ROUTE_PLANNER_DEFAULT_DAYS || 2;
  const selectedTransportReason = state.service.callTypeOptions.find(callType => callType.key === state.service.callTypeId);
  const offset = selectedTransportReason?.defaultFromNowPickupTimeInMins || state.agency.requesterAgencyGroup.defaultTimeOffsetMinutes || 5;
  return moment().add(daysOfPromisedWindow, 'days').add(offset, 'minutes');
};
