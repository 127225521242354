import { from as from$ } from 'rxjs';
import LocationService from '../../../services/location.service';
import PlaceTypeService from '../../../services/placeTypeService';
import { fetchPlaceTypesSuccess } from '../../actions/location.actions';

export const getAgencyAddress = id => from$(LocationService.getAgencyAddress(id));

export const searchAddresses = address => from$(LocationService.searchAddresses(address));

export const getPlaceLocation = (payload, state) => from$(LocationService.getPlaceLocation(payload, state));

export const fetchPlaceTypesHelper = async action => {
  const types = await PlaceTypeService.get();
  fetchPlaceTypesSuccess(types);
  return action;
};

export const formatAddress = payload => () =>
  `${payload.address.street1 || ''} ${payload.address.city || ''}, ${payload.address.postalCode || ''}, ${
    payload.address.state || ''
  }`;
