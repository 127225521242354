import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { get } from 'lodash';
import AmbGrid from '../../common/AmbGrid';
import { RequestStatus } from '../../../enums';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import Time from '../../common/Time';

const Dl = styled(props => <AmbGrid style={{ gap: 0, flexWrap: 'wrap' }} {...props} />)`
  margin-block-start: initial;
  margin-block-end: initial;
`;

// <di> tag doesn't exist but no good way to semantically group <dt> and <dd>, so using a <div>. See: https://github.com/whatwg/html/pull/1945.
const Di = styled(({ full, ...props }) => (
  <AmbGrid style={{ width: full ? '100%' : '50%', flexDirection: 'column' }} {...props} />
))`
  && {
    margin-top: 16px;
  }
`;

const Dt = styled(props => <span {...props} />)`
  && {
    opacity: 0.6;
  }
`;

const Dd = styled(props => <p {...props} />)`
  margin: 0;
`;

const Address = styled(props => <address {...props} />)`
  && {
    margin-top: -1px;
    opacity: 0.8;
    display: block;
    font-style: normal;
    font-size: 12px;
  }
`;

const A = styled.a`
  color: #0090a6;
`;

function FacilityAddress({ conflict, field }) {
  const facilityName = get(conflict, `request.${field}.name`);
  const address = get(conflict, `request.${field}.address`, {});
  const msg = `${address.street1}, ${address.city}, ${address.state}`;
  return (
    <>
      {facilityName}
      <Address>{msg}</Address>
    </>
  );
}

function ConfirmConflictDialogFrame({ unitId, handleAcceptETAClick, handleClose, conflict }) {
  const onClose = ok => (ok ? handleAcceptETAClick() : handleClose());

  const link = conflict ? (
    <A href={`/requester/requests/${conflict.request.id}/edit`} target="_blank">
      {`trip #${conflict.request.id}`}
    </A>
  ) : (
    '-'
  );
  const requestingAgency = get(conflict, 'request.requesterAgency.entity.name', '-');
  const priorityType = get(conflict, 'request.requesterAgency.requesterOptions.priorityType');
  const priorityTypeText = priorityType ? `P${priorityType}` : '-';
  const status = get(conflict, 'request.statusName', '-');
  const isAssigned = conflict.request.status !== RequestStatus.SCHEDULED;
  const title = isAssigned ? 'Warning' : 'Information';
  return (
    <ConfirmationDialog
      title={title}
      onClose={onClose}
      okText="Confirm"
      cancelText="Go back"
      open
      cancel
      warning={isAssigned}
    >
      <div>
        <span>{`Accepting this ETA will unassign Unit ${unitId} from their current`}</span>
        &nbsp;
        <span>{link}</span>
        &#46;&nbsp;
        <span>Confirm changes before proceeding.</span>
      </div>

      <Dl>
        <Di>
          <Dt>Current Status</Dt>
          <Dd>{status}</Dd>
        </Di>
        <Di>
          <Dt>Priority</Dt>
          <Dd>{priorityTypeText}</Dd>
        </Di>
        <Di>
          <Dt>Requested Pickup</Dt>
          <Dd>
            <Time datetime={get(conflict, 'source.promised_eta')} />
          </Dd>
        </Di>
        <Di>
          <Dt>Expected On Scene</Dt>
          <Dd>
            <Time datetime={get(conflict, 'source.eta')} />
          </Dd>
        </Di>
        <Di full>
          <Dt>Requesting Agency</Dt>
          <Dd>{requestingAgency}</Dd>
        </Di>
        <Di full>
          <Dt>Pickup Location</Dt>
          <FacilityAddress conflict={conflict} field="pickupPlace" />
        </Di>
        <Di full>
          <Dt>Destination</Dt>
          <FacilityAddress conflict={conflict} field="destinationPlace" />
        </Di>
      </Dl>
    </ConfirmationDialog>
  );
}

ConfirmConflictDialogFrame.propTypes = {
  unitId: PropTypes.string.isRequired,
  handleAcceptETAClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  conflict: PropTypes.shape().isRequired,
};

export default ConfirmConflictDialogFrame;
