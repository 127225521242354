import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';
import AmbGrid from 'components/common/AmbGrid';

const DispatcherFrame = props => {
  const { rejectReason } = props;

  return (
    <AmbGrid style={{ flexDirection: 'column' }}>
      <ListItem>
        <div style={{ width: '100%', textAlign: 'center', fontSize: 18, fontWeight: 500 }}>
          {props.pollInProgressReason}
        </div>
      </ListItem>
      {rejectReason && (
        <ListItem style={{ width: '100%', padding: 0 }}>
          <div style={{ width: '100%', textAlign: 'center' }}>{rejectReason}</div>
        </ListItem>
      )}
    </AmbGrid>
  );
};

DispatcherFrame.propTypes = {
  rejectReason: PropTypes.string.isRequired,
  pollInProgressReason: PropTypes.string.isRequired,
};

export default DispatcherFrame;
