import ApiService from './api.service';

export default class PersonnelService {
  constructor(request) {
    this.request = request;
    this.tenantedRestApiUrl = window.ambulnzConfig.tenantedRestApiUrl;
  }

  async getPersonnel() {
    const path = `${this.tenantedRestApiUrl}/Requests/${this.request.id}/personnel`;
    const response = await ApiService.get(path);
    return response.json();
  }

  async getPhoneNumber(callerPhoneNumber) {
    const path = `${this.tenantedRestApiUrl}/Requests/${
      this.request.id
    }/personnel/PhoneNumber?callerPhoneNumber=${callerPhoneNumber}`;
    const response = await ApiService.get(path);
    return response.json();
  }
}
