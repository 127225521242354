import { from as from$ } from 'rxjs';

import CoverageService from '../../../services/coverage.service';
import { parseInfoBox } from '../../../parsers/infobox.parser';
import { requestId } from './request.helper';
import { isPatientPresent } from './patient.helper';

export const updateCoverages = store =>
  from$(CoverageService.updateCoverages(requestId(store), parseInfoBox(store.value).coverages));

export const isCoverageDataValid = state =>
  !state.formFlow.submitInProgress && state.requests.request.id && isPatientPresent(state);

export const checkPayerRules = store => from$(CoverageService.checkPayerRules(requestId(store)));

export const getCoverages = store => from$(CoverageService.getCoverages(requestId(store)));
