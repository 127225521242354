import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';

const OfferCardBody = ({ id, descriptionText }) => (
  <AmbGrid data-testid={`body-offer-${id}`} style={{ padding: '10px 16px 12px', alignItems: 'center' }}>
    <div>{descriptionText}</div>
  </AmbGrid>
);

OfferCardBody.propTypes = {
  descriptionText: PropTypes.string.isRequired,
  id: PropTypes.number,
};

export default OfferCardBody;
