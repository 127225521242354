import React, { useState, useEffect, useRef } from 'react';
import AmbGrid from '../common/AmbGrid';
import styled from '@emotion/styled';
import { get } from 'lodash';
import phoneBlue from '../common/icons/phone-blue.png';
import messageBlue from '../common/icons/message-blue.svg';
import ConfirmationDialog from '../common/ConfirmationDialog';
import PersonnelService from '../../services/personnelService';
import UberCommunicationService from '../../services/uberCommunication.service';
import PhoneInput from '../common/PhoneInput';
import AmbCircularProgress from '../common/AmbCircularProgress';
import { palette } from '../../theme';
import AmbInputLabel from 'components/common/AmbInputLabel';

const DriverContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DriverContact = styled.div`
  color: #00838f;
  font-size: 13px;
  cursor: pointer;
  height: 22px;
  white-space: nowrap;
`;

const DriverContactText = styled.div`
  display: inline-block;
  height: 20px;
  vertical-align: middle;
`;

const DriverPhone = styled.div`
  color: ${palette.primary.main};
  font-size: 20px;
  font-weight: 500;
  margin: 5px 0;
`;

const ErrorText = styled.div`
  color: ${palette.primary.red};
  margin-top: 15px;
`;

const RequestInstructionsText = styled.div`
  margin-bottom: 15px;
`;

const StyledTextArea = styled.textarea`
  font: inherit;
  color: currentColor;
  border: 0;
  margin: 0;
  padding: 0;
  resize: vertical;
  display: block;
  min-width: 0;
  outline: none;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
`;

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 14px;
  padding: 6px 0 7px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: #004053;
  }
`;

function HelpFrame(props) {
  const [open, setOpen] = useState(false);
  const [openMessage, setMessageOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [driverPhone, setDriverPhone] = useState(null);
  const [requesterPhone, setRequesterPhone] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');

  const onCloseMessage = submit => {
    if (submit && !messageSent) {
      setMessageSent(true);
      setInProgress(true);
      setError(false);
    } else {
      setMessageOpen(false);
      setMessageSent(false);
      setMessage('');
      setError(false);
    }
  };

  async function getDriverPhone() {
    const service = new PersonnelService(props.request);
    const response = await service.getPhoneNumber(requesterPhone);

    setInProgress(false);
    if (response.error) {
      setError(true);
      setSubmitted(false);
    } else {
      setDriverPhone(response.phoneNumber);
    }
  }

  async function sendDriverMessage() {
    const { onToast } = props;
    const service = new UberCommunicationService(props.request);
    const response = await service.send(message);

    setInProgress(false);
    if (response.error) {
      setError(true);
      setMessageSent(false);
    } else {
      onToast('The message was successfully sent to Uber');
      onCloseMessage(true);
    }
  }

  useEffect(
    () => {
      if (submitted) {
        getDriverPhone();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitted],
  );

  useEffect(
    () => {
      if (messageSent) {
        sendDriverMessage();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageSent],
  );

  const onClose = submit => {
    if (submit && !submitted) {
      setSubmitted(true);
      setInProgress(true);
      setError(false);
    } else {
      setOpen(false);
      setSubmitted(false);
      setDriverPhone(null);
      setRequesterPhone('');
      setError(false);
    }
  };

  const listenToChange = e => {
    setRequesterPhone(e.target.value);
    if (error) setError(false);
  };

  const listenToMessageChange = e => {
    setMessage(e.target.value);
    if (error) setError(false);
  };

  const TextArea = ({ fullWidth, id, onChange }) => {
    const [isFocused, setIsFocused] = useState(false);
    const textareaRef = useRef();

    useEffect(() => {
      if (isFocused) {
        textareaRef.current.selectionStart = textareaRef.current.selectionEnd = textareaRef.current.value.length;
      }
    }, [isFocused]);
    return (
      <Wrapper isFocused={isFocused}>
        <StyledTextArea
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          type="text"
          rows="4"
          onChange={onChange}
          id={id}
          style={{ width: fullWidth ? '100%' : 'auto' }}
          value={message}
          autoFocus
          ref={textareaRef}
        />
      </Wrapper>
    );
  };

  return (
    <>
      <DriverContainer>
        <DriverContact id="driver-contact-btn" onClick={() => setOpen(true)}>
          <img src={phoneBlue} alt="" style={{ paddingRight: 4, verticalAlign: 'middle' }} />
          <DriverContactText>CAll DRIVER</DriverContactText>
        </DriverContact>
        {open && (
          <ConfirmationDialog
            title={driverPhone ? "Driver's Phone Number" : "Get Driver's Phone Number"}
            onClose={onClose}
            okText={submitted && !inProgress ? 'Close' : 'Send Phone Number'}
            cancelText={submitted && !inProgress ? 'Close' : 'Cancel'}
            open={open}
            titlestyles={{ textAlign: 'center' }}
            cancel={!submitted}
            disableConfirm={get(requesterPhone.match(/\d/g), 'length') !== 10 || inProgress}
            maxWidth="sm"
          >
            <div>
              {driverPhone && (
                <>
                  <div>You can now call the Uber driver on the number:</div>
                  <DriverPhone>{driverPhone}</DriverPhone>
                  <div>
                    from the number you specified before
                    {requesterPhone}
                  </div>
                </>
              )}
              {!driverPhone && (
                <>
                  <RequestInstructionsText>
                    Specify the phone number you will use to call the driver.
                    <br />
                    Uber will then provide the driver&apos;s phone number.
                  </RequestInstructionsText>
                  <AmbGrid>
                    <AmbGrid>
                      <PhoneInput
                        value={requesterPhone}
                        name="requesterPhone"
                        id="RequesterPhoneInput"
                        style={{ margin: 0, width: '100%' }}
                        onChange={e => listenToChange(e)}
                        autoFocus
                        disabled={inProgress}
                        label="Your Phone Number *"
                      />
                    </AmbGrid>
                  </AmbGrid>
                </>
              )}
              {error && (
                <ErrorText>
                  Uber could not provide the driver&apos;s phone number.
                  <br />
                  Try again or cancel the contact request.
                </ErrorText>
              )}
            </div>
            {inProgress && (
              <div style={{ textAlign: 'center', margin: '29px 0 0 0', position: 'relative' }}>
                <AmbCircularProgress size={35} propsStyles={{ position: 'relative' }} />
              </div>
            )}
          </ConfirmationDialog>
        )}
        <DriverContact id="driver-contact-btn" onClick={() => setMessageOpen(true)} style={{ marginLeft: 20 }}>
          <img src={messageBlue} alt="" style={{ paddingRight: 6, verticalAlign: 'middle', maxWidth: '22px' }} />
          <DriverContactText>SEND MESSAGE</DriverContactText>
        </DriverContact>
        {openMessage && (
          <ConfirmationDialog
            title="Message Driver"
            onClose={onCloseMessage}
            okText="Send Message"
            cancelText="Cancel"
            open={openMessage}
            titlestyles={{ textAlign: 'center' }}
            cancel
            maxWidth="xs"
            fullWidth
            disableConfirm={get(message, 'length') === 0 || inProgress}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <AmbInputLabel htmlFor="standard-multiline-static" shrink style={{ fontSize: 15 }}>
                Message for driver
              </AmbInputLabel>
              <TextArea id="standard-multiline-static" fullWidth onChange={e => listenToMessageChange(e)} />
              {error && (
                <ErrorText>
                  There was an error sending your message to Uber.
                  <br />
                  Please try again.
                </ErrorText>
              )}
            </div>
            {inProgress && (
              <div style={{ textAlign: 'center', marginTop: '10px', position: 'relative' }}>
                <AmbCircularProgress size={35} propsStyles={{ position: 'relative' }} />
              </div>
            )}
          </ConfirmationDialog>
        )}
      </DriverContainer>
    </>
  );
}

export default HelpFrame;
