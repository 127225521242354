import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { theme } from '../../theme';

function AmbCircularProgress({ size = 50, propsStyles = {} }) {
  const circularProgressStyles = {
    color: theme.palette.secondary.main,
    ...propsStyles,
  };

  return <CircularProgress style={circularProgressStyles} size={size} />;
}

AmbCircularProgress.propTypes = {
  size: PropTypes.number,
  propsStyles: PropTypes.shape(),
};

export default AmbCircularProgress;
