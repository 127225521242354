import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

import * as errorActions from '../../../redux/actions/error.actions';
import * as etaActions from '../../../redux/actions/eta.actions';
import { errorPipe } from '../../../redux/epics/epicsUtil';
import { getRequestId } from '../../../redux/epics/updateRequestUtil';
import RequestService from '../../../services/request.service';

// eslint-disable-next-line import/prefer-default-export
export const rejectEtaEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.setAutoETAFailure.match),
    switchMap(() =>
      from$(RequestService.rejectEtas(getRequestId(store), store.value.eta.rejectedEtas)).pipe(
        mergeMap(payload => concat$(of$(errorActions.onError(payload)))),
        errorPipe,
      ),
    ),
  );
