import React from 'react';
import PropTypes from 'prop-types';
import DisabledButton from './DisabledButton';
import EtaButton from './EtaButton';

const GetETAButtonFrame = props => {
  const { isDisabled, isVisible, loadedWillCall, isNonTransport } = props;

  return (
    <div>
      {isDisabled && (
        <DisabledButton loadedWillCall={loadedWillCall} isVisible={isVisible} isNonTransport={isNonTransport} />
      )}
      {!isDisabled && <EtaButton {...props} />}
    </div>
  );
};

GetETAButtonFrame.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  loadedWillCall: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isNonTransport: PropTypes.bool.isRequired,
};

export default GetETAButtonFrame;
