import schema from 'schm';
import translate from 'schm-translate';
import methods from 'schm-methods';

const facility = schema(
  {
    name: String,
    id: String,
    typeof: 'facility',
    address: Object,
    locationGps: Object,
    matchedAliases: Object,
    emsFacilityTypeId: Number,
    emsFacilityId: Number,
    marketId: Number,
    licenseTypeName: String,
  },
  translate({
    name: 'entity.name',
    emsFacilityTypeId: 'typeId',
    emsFacilityId: 'id',
    id: 'facilityLocations.0.place.id',
    address: 'facilityLocations.0.place.address',
    locationGps: 'facilityLocations.0.place.locationGps',
    licenseTypeName: 'licenseType.name',
  }),
  methods({
    formattedAddress: values =>
      `${values.address.street1} ${values.address.city}, ${values.address.postalCode}, ${values.address.state}`,
  }),
);

export default facility;
