import React from 'react';
import PropTypes from 'prop-types';
import Aliases from './Aliases';
import { drawerWidth } from '../../../theme';
import Payer from '../icons/Payer';
import AmbGrid from '../AmbGrid';

function PayerItem(props) {
  const { children, inputValue } = props;
  return (
    <AmbGrid style={{ width: drawerWidth - 140 }}>
      <AmbGrid style={{ alignSelf: 'center' }}>
        <Payer style={{ height: 18, width: 18, margin: '8px 12px 0 5px' }} />
      </AmbGrid>
      <AmbGrid style={{ alignSelf: 'center', padding: 4, flexDirection: 'column' }}>
        <aside
          style={{
            width: drawerWidth - 100,
            marginLeft: -5,
            marginBottom: -3,
            color: '#2e424d',
            whiteSpace: 'nowrap',
            fontSize: 14,
            lineHeight: '24px',
          }}
        >
          {children.name}
        </aside>
        <Aliases aliases={children.matchedAliases} inputValue={inputValue} />
      </AmbGrid>
    </AmbGrid>
  );
}

PayerItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.shape(), PropTypes.node]).isRequired,
  inputValue: PropTypes.string.isRequired,
};

export default PayerItem;
