import { formFlowMdl } from './formFlow.middleware';
import { pickupTimeChangeMdl } from './pickupTimeChange.middleware';
import { patientMdl } from './patient.middleware';
import { patientSearchMdl } from './patientSearch.middleware';
import { infoboxMdl } from './infobox.middleware';
import { querystringMdl } from './querystring.middleware';
import { socketMdl } from './socket.middleware';
import { duplicateRequestMdl } from '../../components/Summary/DuplicateRequest/redux/duplicateRequest.middleware';
import cancelRequest from '../../components/Summary/CancelRequest/redux/middleware';
import unassignRequest from '../../components/Summary/Unassign/redux/middleware';
import { newOrExistingMdl } from './newOrExisting.middleware';
import initMdl from './init.middleware';
import { eligibilityMdl } from './eligibility.middleware';
import { trackChangesMdl } from './trackChanges.middleware';
import route from './route.middleware';
import user from './user.middleware';
import validation from './validation.middleware';
import saveButton from '../../components/SaveButton/redux/saveButton.middleware';
import offerMdl from './offer.middleware';
import componentsMiddleware from './components';
import asyncMiddleware from './async';
import prebillingMiddleware from './prebilling';
import etaMiddleware from './eta';
import { serviceMdl } from './service.middleware';

const rootMiddleware = [
  ...cancelRequest,
  ...duplicateRequestMdl,
  ...eligibilityMdl,
  ...formFlowMdl,
  ...infoboxMdl,
  ...initMdl,
  ...newOrExistingMdl,
  ...offerMdl,
  ...patientMdl,
  ...patientSearchMdl,
  ...pickupTimeChangeMdl,
  ...querystringMdl,
  ...route,
  ...saveButton,
  ...socketMdl,
  ...trackChangesMdl,
  ...unassignRequest,
  ...user,
  ...validation,
  ...serviceMdl,
]
  .concat(componentsMiddleware)
  .concat(prebillingMiddleware)
  .concat(etaMiddleware)
  .concat(asyncMiddleware);

export default rootMiddleware;
