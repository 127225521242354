import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import WayPoints from './WayPoints';
import ToPickup from './ToPickup';
import useDirections from './useDirections.hook';

function WayPointsContainer(props) {
  const {
    fromWaypoint,
    toWaypoint,
    directionsFrom,
    vehicleLocation,
    currentSegment,
    location,
    inProgress,
    atDropoffTime,
    pickupTime,
    isNonTransport,
  } = props;

  const directions = useDirections(directionsFrom, toWaypoint);
  const getDropoffTime = () => {
    if (!pickupTime) {
      return null;
    }

    return inProgress
      ? atDropoffTime
      : moment(pickupTime)
          .add(directions ? directions.duration : 0, 's')
          .toISOString();
  };

  return (
    <>
      {currentSegment.toPickup && <ToPickup from={vehicleLocation} to={fromWaypoint} />}
      <WayPoints
        {...props}
        directions={directions}
        showRoute={directions && !currentSegment.atDropoff && !isNonTransport}
        dropoffTime={getDropoffTime()}
        showVehicle={inProgress && !currentSegment.toPickup && vehicleLocation.lat && !isNonTransport}
        fromWaypoint={fromWaypoint || location}
        toWaypoint={isNonTransport ? false : toWaypoint}
      />
    </>
  );
}

WayPointsContainer.propTypes = {
  pickupTime: PropTypes.string,
};

WayPointsContainer.defaultProps = {
  pickupTime: null,
};

const directionsFrom = ({ fromWaypoint, vehicleLocation }, currentSegment) =>
  currentSegment.order && !currentSegment.toPickup && vehicleLocation.lat ? vehicleLocation : fromWaypoint;

function mapStateToProps({
  map,
  schedule: { scheduleState, complete, currentSegment, atPickupTime, atDropoffTime },
  eta,
  requests: { request, isNonTransport },
}) {
  return {
    ...map,
    scheduleState,
    directionsFrom: directionsFrom(map, currentSegment),
    complete,
    pickupComplete: complete || currentSegment.pickupComplete,
    dropoffComplete: complete,
    inProgress: request.inProgress,
    pickupTime: request.inProgress ? atPickupTime : eta.pickupTime,
    atDropoffTime,
    currentSegment,
    effectGuard: [],
    isNonTransport,
  };
}

export default connect(mapStateToProps, {})(WayPointsContainer);
