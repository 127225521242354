import { from as from$ } from 'rxjs';

import CommentService from '../../../services/comment.service';
import { requestId } from './request.helper';
import { COMMENTS } from '../../../enums';

export const updateComments = (store, payload, commentType = COMMENTS.TYPES.REQUEST) =>
  from$(CommentService.create(requestId(store), payload || store.value.infobox.notes, commentType));

export const getComment = id => from$(CommentService.getComment(id));
