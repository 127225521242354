import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import AmbGrid from 'components/common/AmbGrid';
import { styled } from '@mui/system';
import { theme } from '../../../../theme';

const StyledButton = styled(Button)({
  borderRadius: 4,
  boxShadow: 'rgba(0, 64, 82, 0.35) 0px 1px 4px 0px',
});

const Logo = styled('img')({
  width: 72,
  height: 24,
  objectFit: 'contain',
});

function CardHeader({ attachment, name, displayName, actionButtonText, providerId, createOffer }) {
  const acceptBtn = (
    <StyledButton
      id="AcceptETA"
      variant="contained"
      color="primary"
      margin="dense"
      onClick={() => {
        createOffer({ providerId });
      }}
    >
      {actionButtonText.toUpperCase()}
    </StyledButton>
  );

  return (
    <>
      <AmbGrid
        style={{
          padding: '8px 16px',
          backgroundColor: theme.palette.grey.semiLight2,
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'space-between',
        }}
      >
        <AmbGrid style={{ gap: '8px' }}>
          <AmbGrid>
            {attachment.downloadUrl && <Logo src={attachment.downloadUrl} alt="" />}
            {!attachment.downloadUrl && name}
          </AmbGrid>
          <AmbGrid>
            <span style={{ fontSize: '12px', color: '#004053' }}>{displayName || 'External Provider'}</span>
          </AmbGrid>
        </AmbGrid>
        <AmbGrid>{acceptBtn}</AmbGrid>
      </AmbGrid>
    </>
  );
}

CardHeader.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  attachment: PropTypes.shape(),
  actionButtonText: PropTypes.string.isRequired,
  providerId: PropTypes.number.isRequired,
  createOffer: PropTypes.func.isRequired,
};

CardHeader.defaultProps = {
  attachment: {},
};

export default CardHeader;
