import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as requestsActions from '../../redux/actions/requests.actions';
import AmbSwitch from 'components/common/AmbSwitch';

const CanRequestBeActivated = ({
  disabled,
  disabledClass,
  canReturnRequestBeActivatedByPatient,
  setReturnRequestCanBeActivatedByPatient,
}) => {
  const handleChange = e => {
    setReturnRequestCanBeActivatedByPatient(e.target.checked);
  };

  return (
    <label className={disabledClass} style={{ marginTop: 10, marginRight: 0 }}>
      <AmbSwitch disabled={disabled || null} onChange={handleChange} checked={canReturnRequestBeActivatedByPatient} />
      Patient can activate
    </label>
  );
};

CanRequestBeActivated.propTypes = {
  disabled: PropTypes.bool.isRequired,
  disabledClass: PropTypes.string.isRequired,
  canRoundTripBeActivatedByPatient: PropTypes.bool.isRequired,
  setRequestCanBeActivatedByPatient: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    canReturnRequestBeActivatedByPatient: state.requests.canReturnRequestBeActivatedByPatient,
  };
}

export default connect(mapStateToProps, {
  setReturnRequestCanBeActivatedByPatient: requestsActions.setReturnRequestCanBeActivatedByPatient,
})(CanRequestBeActivated);
