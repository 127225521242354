import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSSN } from 'flags/display.flag';
import { mrnDisabled, patientPhoneDisabled } from '../../../flags/disabled.flag';
import { formatDateWithoutTimezone } from '../../../helpers/dateFormatter';
import { hasFacesheet, agencyRequiresPatientInfo } from 'flags/valid.flag';
import PatientDetailsFrame from './PatientDetails.frame';
import withValidations from './PatientDetails.validations';
import * as infoboxActions from '../../../redux/actions/infobox.actions';
import * as patientActions from '../../../redux/actions/patient.actions';
import { inputChange } from './PatientAdditionalInfoWidget/PatientAdditionalInfo.actions';
import { isCar } from '../../../flags/service.flag';
import {
  patientAdditionalDetailsDisplay,
  displayPatientLookupTooltip,
  mrnSearchEnabled,
} from '../../../flags/display.flag';

class PatientDetailsContainer extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.facesheetAttached !== this.props.facesheetAttached) {
      this.props.validateAll(nextProps);
    }

    if (nextProps.hasErrors !== this.props.hasErrors) {
      this.props.handleError('detailsError')(nextProps.hasErrors);
    }
    if (nextProps.shouldValidateAll !== this.props.shouldValidateAll && nextProps.shouldValidateAll) {
      this.props.validateAll(nextProps);
    }

    if (nextProps.triggerValidation !== this.props.triggerValidation && nextProps.triggerValidation) {
      this.props.validateAll(nextProps);
    }

    if (this.props.agencyRequiresPatientInfo !== nextProps.agencyRequiresPatientInfo) {
      this.props.validateAll(nextProps);
    }

    if (
      nextProps.triggerPatientValidation !== this.props.triggerPatientValidation &&
      nextProps.triggerPatientValidation
    ) {
      this.props.validateAll(nextProps);
    }

    this.props.setSaveErrorMessage(nextProps.errors);
  }

  handleInputChange = event => {
    this.props.setPatientDetails({ [event.target.name]: event.target.value });
  };

  saveDetails = () => {
    this.props.autoSavePatientDetails();
  };

  handlePhoneChange = e => this.props.inputChange({ [e.target.name]: e.target.value });

  handleMrnBlur = e => {
    this.props.autoSavePatientDetails();

    if (!this.props.mrnSearchEnabled) {
      return;
    }

    if (e.target.value) {
      this.props.searchPatient();
    }
  };

  handleSsnChange = event => {
    this.props.setPatientDetails({
      [event.target.name]: event.target.value.replace(/\D/g, ''),
    });
  };

  handleSearchPatient = e => {
    if (e.target.value) {
      this.props.searchPatient();
    }
    this.saveDetails();
  };

  render() {
    return (
      <PatientDetailsFrame
        handleInputChange={this.handleInputChange}
        handleInputBlur={this.saveDetails}
        handleAcceptBirthday={this.saveDetails}
        handleMrnBlur={this.handleMrnBlur}
        handlePhoneChange={this.handlePhoneChange}
        handleSsnChange={this.handleSsnChange}
        handleSearchPatient={this.handleSearchPatient}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    agencyRequiresPatientInfo: agencyRequiresPatientInfo(state),
    displayPatientLookupTooltip: displayPatientLookupTooltip(state),
    doRoomNumber: state.infobox.doRoomNumber,
    facesheetAttached: hasFacesheet(state),
    isCar: isCar(state.service),
    mrnDisabled: mrnDisabled(state),
    mrnSearchEnabled: mrnSearchEnabled(state),
    patientAdditionalDetailsDisplay: patientAdditionalDetailsDisplay(state),
    patientBirthday: formatDateWithoutTimezone(state.infobox.patientBirthday),
    patientFirstName: state.infobox.patientFirstName,
    patientLastName: state.infobox.patientLastName,
    patientEmail: state.infobox.patientEmail,
    patientMRN: state.infobox.patientMRN,
    patientMiddleName: state.infobox.patientMiddleName,
    patientPhoneDisabled: patientPhoneDisabled(state),
    patientSsn: state.infobox.patientSsn,
    phone: state.patient.phone,
    puRoomNumber: state.infobox.puRoomNumber,
    requirePhone: isCar(state.service),
    searchInProgress: state.patient.searchInProgress,
    showSSN: showSSN(state),
    triggerPatientValidation: state.formFlow.triggerPatientValidation,
    user: state.user.model,
    isNonTransport: state.requests.isNonTransport,
  };
}

PatientDetailsContainer.propTypes = {
  autoSavePatientDetails: PropTypes.func.isRequired,
  displayMRN: PropTypes.bool,
  displaySSN: PropTypes.bool,
  errors: PropTypes.shape().isRequired,
  facesheetAttached: PropTypes.bool.isRequired,
  handleError: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  inputChange: PropTypes.func.isRequired,
  mrnSearchEnabled: PropTypes.bool.isRequired,
  patientMRN: PropTypes.string,
  searchPatient: PropTypes.func.isRequired,
  setPatientDetails: PropTypes.func.isRequired,
  setSaveErrorMessage: PropTypes.func.isRequired,
  shouldValidateAll: PropTypes.bool.isRequired,
  triggerPatientValidation: PropTypes.bool.isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  validateAll: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  ...infoboxActions,
  ...patientActions,
  inputChange,
})(withValidations(PatientDetailsContainer));
