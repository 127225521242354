import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AmbRadioInput from '../../components/common/AmbRadioInput';

const MedicareQuestions = ({ dispatch, ...props }) => {
  const [checked, setChecked] = useState(null);

  const options = [
    {
      value: 'less than 100 days',
      label: `Patient was admitted less than 100 days ago — after ${props.admittanceDate}`,
    },
    {
      value: 'more than 100 days',
      label: `Patient was admitted 100 or more days ago — on or before ${props.admittanceDate}`,
    },
    {
      value: 'date is unknown',
      label: 'Patient’s admission date is unknown',
    },
  ];

  const handleClick = e => {
    dispatch({ type: 'MEDICARE_SUBOPTION', medicareSuboption: e.target.value });
    setChecked(e.target.value);
  };

  return (
    <>
      <div
        role="radiogroup"
        aria-label="HasMedicare"
        name="HasMedicare"
        value={props.medicareSuboption}
        style={{ paddingLeft: 30 }}
      >
        {options.map((option, index) => (
          <div key={index}>
            <label style={{ display: 'flex', margin: '5px 0' }}>
              <AmbRadioInput
                value={option.value}
                onChange={handleClick}
                checked={checked === option.value}
                style={{ marginRight: 15, color: '#859DA7' }}
                inputPosition={{ left: -1 }}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

MedicareQuestions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  admittanceDate: PropTypes.string.isRequired,
  medicareSuboption: PropTypes.string.isRequired,
};

export default MedicareQuestions;
