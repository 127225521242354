import ApiService from './api.service';
import ProxyPolyfillBuilder from 'proxy-polyfill/src/proxy';
const proxyPolyfill = ProxyPolyfillBuilder();

// eslint-disable-next-line import/prefer-default-export
export async function getServiceTypes() {
  const path = `${window.ambulnzConfig.tenantedRestApiUrl}/ServiceTypes`;
  const response = await ApiService.get(path);

  return response.json();
}

export function buildProxy(obj) {
  return new proxyPolyfill(obj, {
    get: (obj, prop) => (prop in obj ? obj[prop] : {}),
  });
}

export function buildServiceTypeDictionary(payload) {
  const serviceTypeDictionary = payload.reduce((acc, currentValue) => {
    acc[currentValue.name] = currentValue;

    return acc;
  }, {});

  return buildProxy(serviceTypeDictionary);
}

export function buildServiceTypeLookup(payload) {
  const serviceTypeLookup = payload.reduce((acc, currentValue) => {
    acc[currentValue.id] = currentValue;

    return acc;
  }, {});

  return buildProxy(serviceTypeLookup);
}
