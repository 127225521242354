import { createReducer } from 'redux-act';
import * as action from '../actions/schedule.actions';
import segmentSchema from '../../schemas/segment.schema';
import { SCHEDULE_STATE } from '../../enums';

export const initialState = {
  id: null,
  scheduleState: '',
  segments: [],
  scheduleEta: '',
  vehicleId: null,
  currentSegment: segmentSchema.parse({}),
  complete: false,
  startAt: null,
  unit: null,
  label: null,
};

function getCurrentSegment(segments, scheduleState) {
  if (!segments) {
    return segmentSchema.parse({});
  }

  const current = segments.find(s => s.state === scheduleState) || {};
  return segmentSchema.parse(current);
}

export function getTimeFromSegment(segments, state) {
  if (!segments) {
    return null;
  }

  const segment = segments.find(s => s.state === state) || {};
  return segment.start_at;
}

const scheduleReducer = createReducer(
  {
    [action.scheduleUpdate]: (
      state,
      {
        id,
        state: scheduleState,
        segments,
        eta: scheduleEta,
        promised_segments: promisedSegments,
        vehicleId,
        start_at: startAt,
        end_at: endAt,
        unit,
        label,
        display_window_end_at: displayWindowEndAt,
        display_window_start_at: displayWindowStartAt,
      },
    ) => ({
      ...state,
      id,
      startAt,
      scheduleState,
      segments,
      scheduleEta,
      promisedSegments,
      vehicleId,
      currentSegment: getCurrentSegment(segments, scheduleState),
      atPickupTime: getTimeFromSegment(segments, SCHEDULE_STATE.AT_PICK_UP),
      atDropoffTime: getTimeFromSegment(segments, SCHEDULE_STATE.AT_DROP_OFF) || endAt,
      complete: scheduleState === SCHEDULE_STATE.ALL_CLEAR,
      unit,
      label,
      displayWindowEndAt,
      displayWindowStartAt,
    }),
    [action.resetSchedule]: () => ({ ...initialState }),
  },
  initialState,
);

export default scheduleReducer;
