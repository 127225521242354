import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 15 17" {...props}>
      <g>
        <path
          style={{ fill: '#004051' }}
          d="M12,2c0.5,0,1,0.5,1,1v7.1c0,0.6-0.5,1-1,1H8.4l-3,2.2l0-2.2l-2.4,0c-0.5,0-1-0.4-1-1V3c0-0.5,0.5-1,1-1L12,2
		 M12,0L3,0C1.3,0,0,1.4,0,3v7.1c0,1.7,1.3,3,3,3l0.5,0V17l5.7-3.9H12c1.7,0,3-1.3,3-3V3C15,1.4,13.7,0,12,0L12,0z"
        />
        <path
          d="M12,11.1H8.5l-3,2.2v-2.2l-2.4,0c-0.5,0-1-0.4-1-1V3c0-0.5,0.5-1,1-1l9,0c0.5,0,1,0.4,1,1v7.1
		C13,10.6,12.6,11.1,12,11.1z"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M12,2c0.5,0,1,0.5,1,1v7.1c0,0.6-0.5,1-1,1H8.5l-2.9,2.1v-2.1l-2.5,0c-0.5,0-1-0.4-1-1V3c0-0.5,0.5-1,1-1L12,2
		 M12,1L3,1C1.9,1,1,1.9,1,3v7.1c0,1.1,0.9,2,2,2l1.5,0v3l4.4-3H12c1.1,0,2-0.9,2-2V3C14,1.9,13.1,1,12,1L12,1z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#014053' }}
          d="M7.7,4.7c0.5,0,0.9-0.4,0.9-0.9S8.2,2.9,7.7,2.9c-0.5,0-0.9,0.4-0.9,0.9S7.1,4.7,7.7,4.7z"
        />
        <path
          style={{ fill: '#014053' }}
          d="M8.7,9.3C8.5,9.4,8.2,9.6,8.1,9.6c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.5-3.3,0.5-3.5c0-0.2-0.1-0.3-0.3-0.3
		c-0.1,0-0.2,0-0.4,0c-0.3,0-0.6,0-0.9,0c-0.3,0-0.3,0-0.4,0H6.2h0C6.1,5.5,6,5.6,6,5.7v0c0,0.1,0.1,0.2,0.2,0.2h0h0.2
		c0.2,0,0.3,0.1,0.3,0.3c0,0.1-0.4,2.4-0.5,2.9c-0.1,0.6,0.5,0.9,0.7,1c0.2,0.1,0.5,0.1,0.7,0.1C8.4,10.2,9,9.6,9,9.5
		C9,9.3,8.8,9.2,8.7,9.3z"
        />
      </g>
    </svg>
  );
}
