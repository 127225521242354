import React from 'react';
import PropTypes from 'prop-types';
import AmbExpansionPanel from '../../common/AmbExpansionPanel';
import PatientCoverage from '../PatientCoverageWidget';
import Insurance from '../../common/icons/Insurance';
import { theme } from '../../../theme';

class CoveragesFrame extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  state = {
    value: true,
    expanded: this.props.expanded,
    coverageError: false,
  };

  togglePanel = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleError = name => value => {
    this.setState({
      [name]: value,
    });
  };

  notValid = state => state.coverageError;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldCollapse) {
      this.setState({ expanded: false });
    }
    if (this.props.expanded !== nextProps.expanded && nextProps.expanded && !this.state.expanded) {
      this.setState({ expanded: true });
    }
  }

  render() {
    return (
      <AmbExpansionPanel
        icon={<Insurance style={{ height: 17, width: 17, marginTop: 2, fill: theme.palette.icon.primary }} />}
        title={this.props.title}
        expanded={this.state.expanded}
        onTogglePanel={this.togglePanel}
        id="CoveragesPanel"
        error={this.notValid(this.state)}
        warnings={this.props.warnings}
      >
        <div style={{ width: '100%', paddingTop: 25 }} ref={this.containerRef}>
          <PatientCoverage
            handleError={this.handleError('coverageError')}
            shouldValidateAll={!this.state.expanded}
            triggerValidation={this.props.triggerValidation}
            disabled={this.props.disabled || null}
          />
        </div>
      </AmbExpansionPanel>
    );
  }
}

CoveragesFrame.propTypes = {
  shouldCollapse: PropTypes.bool.isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  warnings: PropTypes.number.isRequired,
};

export default CoveragesFrame;
