/* global google */
import { get } from 'lodash';
import { useState, useEffect } from 'react';
import MapService from '../../services/map.service';

function useDirections(from, to) {
  const [directions, setDirections] = useState(null);
  async function getDirections() {
    if (get(from, 'lat') && get(to, 'lat')) {
      const response = await MapService.getDirections(from, to);
      const currentStep = response.routes[0].legs[0].steps[0].end_location;
      const point1 = new google.maps.LatLng(from.lat, from.lng);
      const point2 = new google.maps.LatLng(currentStep.lat(), currentStep.lng());
      response.heading = google.maps.geometry.spherical.computeHeading(point1, point2);
      response.duration = get(response, 'routes[0].legs[0].duration.value', null);
      response.distance = get(response, 'routes[0].legs[0].distance.text', null);

      setDirections(response);
    }
  }

  useEffect(
    () => {
      getDirections();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [get(from, 'lat'), get(to, 'lat')],
  );

  return directions;
}

export default useDirections;
