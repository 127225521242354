import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import UnassignRequest from '../../common/icons/UnassignRequest';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import AmbCircularProgress from '../../common/AmbCircularProgress';
import { USER_TYPES } from '../../../enums';
import { styled } from '@mui/system';

const StyledListItemIcon = styled(ListItemIcon)({
  color: 'primary.main',
  marginRight: 0,
});

const StyledRoot = styled('div')({
  minWidth: 36,
});

const PullBackRequest = props => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [closeErrorDialog, setCloseErrorDialog] = useState(false);

  const onClickUnassignBtn = () => {
    const { onClick } = props;
    setOpenConfirmation(true);
    if (onClick) {
      onClick();
    }
  };

  const handleCloseConfirmationDialog = value => {
    if (value) {
      const { asyncUnassignTripFromProviderRequest } = props;
      asyncUnassignTripFromProviderRequest();
    }
    setOpenConfirmation(false);
    setCloseErrorDialog(false);
  };

  const handleCloseErrorDialog = () => {
    setCloseErrorDialog(true);
  };

  const renderLoadingIcon = () => {
    return (
      <StyledListItemIcon>
        <AmbCircularProgress
          propsStyles={{
            width: '15px',
            height: '15px',
            position: 'relative',
            top: '3px',
            marginRight: '5px',
          }}
        />
      </StyledListItemIcon>
    );
  };

  const renderUnassignIcon = () => {
    return (
      <StyledRoot>
        <UnassignRequest />
      </StyledRoot>
    );
  };

  const renderInsideMenuItem = () => {
    const { loading } = props;
    const renderText = <ListItemText primary="Unassign from Provider" />;
    return (
      <>
        {loading ? renderLoadingIcon() : renderUnassignIcon()}
        {renderText}
      </>
    );
  };

  const renderConfirmationDialog = () => {
    const { loading } = props;

    return (
      <ConfirmationDialog
        open={openConfirmation && !loading}
        onClose={value => handleCloseConfirmationDialog(value)}
        title="Unassign from provider"
        okText="Confirm"
        cancelText="Cancel"
        cancel
      >
        <p>{'This trip will be unassigned from the provider and will require a new ETA to be requested'}</p>
      </ConfirmationDialog>
    );
  };

  const renderErrorDialog = () => {
    const { loading, error } = props;

    return (
      <Dialog
        open={!closeErrorDialog && !loading && error}
        onClose={() => handleCloseErrorDialog()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Failed to unassign</DialogTitle>
        <DialogContent>
          <p>This trip could not be unassigned from the provider.</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseErrorDialog()}
            color="primary"
            autoFocus
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(30, 192, 217, 0.1)',
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const { request, offer, userType } = props;
  const isDispatcher = userType === USER_TYPES.DISPATCHER;
  const isUnassignBtnEnabled = offer ? offer.offerStatus.name === 'accepted' || 'created' : false;

  const { needsAssignment, isCancelled, isScheduled, providerSystemId } = request;
  const UberSystemId = '9';
  const shouldShowUnassignItem = needsAssignment === false && isCancelled === false && isScheduled === true;
  const isNotAssignedToUber = providerSystemId !== UberSystemId;
  if (isDispatcher) {
    return (
      <Fragment>
        <MenuItem disabled={!isUnassignBtnEnabled} onClick={() => onClickUnassignBtn()}>
          {renderInsideMenuItem()}
        </MenuItem>
        {renderConfirmationDialog()}
        {renderErrorDialog()}
      </Fragment>
    );
  }
  if (shouldShowUnassignItem) {
    return (
      <Fragment>
        <MenuItem disabled={isNotAssignedToUber} onClick={() => onClickUnassignBtn()}>
          {renderInsideMenuItem()}
        </MenuItem>
        {renderConfirmationDialog()}
        {renderErrorDialog()}
      </Fragment>
    );
  }
  return renderErrorDialog();
};

PullBackRequest.propTypes = {
  request: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  asyncUnassignTripFromProviderRequest: PropTypes.func.isRequired,
  offer: PropTypes.shape(),
  userType: PropTypes.number.isRequired,
};

PullBackRequest.defaultProps = {
  onClick: null,
  offer: null,
};

export default PullBackRequest;
