import { createReducer } from 'redux-act';
import * as R from 'ramda';
import * as action from '../actions/service.actions';
import { CallType, PatientType, SERVICE_TYPE_NAMES } from '../../enums';
import { arrToObj } from '../../helpers/queryStringHelper';
import ServiceLevelMap, { mapServiceTypeToPatientType } from '../../components/ServiceLevel/ServiceLevelMap';
import { buildProxy, buildServiceTypeDictionary, buildServiceTypeLookup } from '../../services/serviceType.service';
import sortByOrder from '../../parsers/sort.parser';

export const initialState = {
  therapistEquipmentIds: [],
  serviceTypeIds: [1], // 1: BLS, 5: Gur, 4: Bariatric, 2: ALS, 3: CCT
  serviceId: 1,
  callTypeId: CallType.DEFAULT,
  callTypeOptions: [],
  questionMode: true,
  answers: {
    isBedbound: '',
    canTravelByCar: '',
    requiresWheelChair: '',
  },
  mobilityId: null,
  patientTypeId: 1,
  patientTypes: [],
  equipmentAsService: [],
  equipment: [],
  personnel: [],
  mobilities: {},
  serviceTypes: {},
  serviceLevelMap: new ServiceLevelMap({}),
  serviceTypeDictionary: buildProxy({}),
  serviceTypeLookup: buildProxy({}),
  lockedProviderId: null,
  providers: [],
  scope: {},
  bariatric: false,
  patientTypeList: [],
  requirements: [],
  requirementIds: [],
  isServiceTypeWithoutEquipment: [],
  forcedCapabilities: [],
  showRequirementWarning: false,
  agencyTransportReasons: [],
  transportReasons: [],
  transportReasonCheckList: [],
  transportReasonsAndEquipmentIdsDictionary: {},
  telehealthLink: '',
};

const setServiceTypes = (serviceTypeIds, value, patientTypeId, serviceTypeDictionary, patientTypes) => {
  const bariatricId = serviceTypeDictionary[SERVICE_TYPE_NAMES.Bariatric].id;
  const gurneyId = serviceTypeDictionary[SERVICE_TYPE_NAMES.Gurney].id;
  const blsId = serviceTypeDictionary[SERVICE_TYPE_NAMES.BLS].id;
  const alsId = serviceTypeDictionary[SERVICE_TYPE_NAMES.ALS].id;
  const cctId = serviceTypeDictionary[SERVICE_TYPE_NAMES.CCT].id;
  const NICU = patientTypes.find(patientType => patientType.name === R.toLower(PatientType.NICU));
  const PEDS = patientTypes.find(patientType => patientType.name === R.toLower(PatientType.PEDS));

  const equip = serviceTypeIds.filter(id => [bariatricId, gurneyId].includes(id));
  if (R.isNil(value)) {
    return equip;
  }

  let serviceTypeId = value;
  if (patientTypeId === NICU.id && value === blsId) {
    serviceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.NICU_BLS].id;
  } else if (patientTypeId === PEDS.id && value === blsId) {
    serviceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.PEDS_BLS].id;
  } else if (patientTypeId === PEDS.id && value === alsId) {
    serviceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.PEDS_ALS].id;
  } else if (patientTypeId === PEDS.id && value === cctId) {
    serviceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.PEDS_CCT].id;
  }

  return equip.concat(Number(serviceTypeId));
};

const setEquipmentAsServiceType = (serviceTypeIds, payload) => {
  const { active, value } = payload;
  if (active) {
    return serviceTypeIds.concat(value);
  }
  return serviceTypeIds.filter(id => id !== value);
};

const setCallTypeOptions = payload => {
  return payload.map(ct => ({
    key: ct.id,
    value: ct.name,
    emergency: ct.emergency,
    defaultFromNowPickupTimeInMins: ct.defaultFromNowPickupTimeInMins,
    disabled: false,
    pcsRequired: ct.pcsRequired,
  }));
};

const checkedEquipmentFromGroup = (checked, equipmentGroup, equipmentId) =>
  checked && !R.isEmpty(equipmentGroup) && equipmentGroup.includes(equipmentId);

const getEquipmentGroup = (equipment, equipmentId) => {
  const { group } = equipment.find(singleEquipment => singleEquipment.id === equipmentId);
  if (R.isNil(group)) {
    return [];
  }

  return R.pluck(
    'id',
    equipment.filter(singleEquipment => singleEquipment.group && singleEquipment.group.id === group.id),
  );
};

const therapistEquipment = (state, payload) => {
  const { equipment, therapistEquipmentIds } = state;
  const { equipmentId, checked, isNonTransport } = payload;

  if (isNonTransport) {
    return therapistEquipmentForNonTransport(state, payload);
  }

  const equipmentGroup = getEquipmentGroup(equipment, equipmentId);

  if (checkedEquipmentFromGroup(checked, equipmentGroup, equipmentId)) {
    return therapistEquipmentIds
      .filter(therapistEquipmentId => !equipmentGroup.includes(therapistEquipmentId))
      .concat(equipmentId);
  }

  if (checked) {
    return therapistEquipmentIds.concat(equipmentId);
  }

  return therapistEquipmentIds.filter(therapistEquipmentId => therapistEquipmentId !== equipmentId);
};

const therapistEquipmentForNonTransport = (state, { equipmentId, checked }) => {
  const { transportReasonsAndEquipmentIdsDictionary, therapistEquipmentIds } = state;
  let updatedTherapistEquipmentIds;

  if (checked) {
    updatedTherapistEquipmentIds = [...new Set([...therapistEquipmentIds, equipmentId])];
    return updatedTherapistEquipmentIds;
  }

  const requiredEquipmentIdsFromTransportReasons = []
    .concat(...Object.values(transportReasonsAndEquipmentIdsDictionary))
    .filter(id => id !== getEquipmentIdForTelehealth(state));

  updatedTherapistEquipmentIds = therapistEquipmentIds.filter(
    id => requiredEquipmentIdsFromTransportReasons.includes(id) || id !== equipmentId,
  );

  return updatedTherapistEquipmentIds;
};

const setEquipmentAsTherapistEquipment = (therapistEquipmentIds, payload) => {
  const { active, value } = payload;
  if (active) {
    return therapistEquipmentIds.concat(value);
  }

  return therapistEquipmentIds.filter(id => id !== value);
};

const serviceTypeIdsWithoutEquipment = state => {
  const { Curbside, Livery, WAV } = state.serviceTypes;
  return [Curbside, Livery, WAV];
};

const mobilityIdsWithoutEquipment = state => {
  const { wheelchair } = state.mobilities;
  return [wheelchair];
};

const patientTypeIdsWithoutEquipment = state => {
  const NICU = state.patientTypes.find(patientType => patientType.name === R.toLower(PatientType.NICU));
  return [NICU.id];
};

const buildEquipmentState = (state, selectServiceTypeId, selectedTherapistEquipmentIds) => {
  const isServiceTypeWithoutEquipment = serviceTypeIdsWithoutEquipment(state).includes(selectServiceTypeId);

  return {
    therapistEquipmentIds: isServiceTypeWithoutEquipment
      ? initialState.therapistEquipmentIds
      : selectedTherapistEquipmentIds,
    bariatric: isServiceTypeWithoutEquipment ? initialState.bariatric : state.bariatric,
  };
};

const buildPersonnelState = (state, selectServiceTypeId, selectedRequirementIds) => {
  const isServiceTypeWithoutEquipment = serviceTypeIdsWithoutEquipment(state).includes(selectServiceTypeId);

  return {
    requirementIds: isServiceTypeWithoutEquipment ? initialState.requirementIds : R.uniq(selectedRequirementIds),
  };
};

const getRequiredEquipmentIdsByTransportReasonId = (state, transportReasonId) => {
  const matchingTransportReasonCapability = state.serviceLevelMap.capabilityRelations.find(
    item => item.parentCapability.type === 'TransportReason' && item.parentCapability.id === Number(transportReasonId),
  );

  return matchingTransportReasonCapability
    ? matchingTransportReasonCapability.childrenCapabilities.map(item => item.id)
    : [];
};

const getEquipmentIdForTelehealth = state => {
  const { equipment } = state;

  const telehealthEquipment = equipment.find(e => e.name === 'Telehealth');
  return telehealthEquipment && telehealthEquipment.id;
};

const getPCPEquipmentIds = state => state.equipment.filter(e => e.name.includes('PCP')).map(eq => eq.id);

const checkIfTelehealthShouldBeRemoved = (dictionary, telehealthEquipmentId) => {
  const values = Object.values(dictionary);
  const containsTelehealthEquipment = values.some(value => value.includes(telehealthEquipmentId));
  const containsNonTelehealthEquipment = values.some(value => !value.includes(telehealthEquipmentId));
  const hasMixedEquipmentTypes = containsTelehealthEquipment && containsNonTelehealthEquipment;
  return hasMixedEquipmentTypes;
};

const toTransportReasonIdStrings = transportReasons =>
  transportReasons.map(reason => reason.transportReasonId.toString());

const getAllRequiredEquipmentIds = dictionary => Object.values(dictionary).flat();

const getUpdatedEquipmentIdsForNonTransport = (reasonIds, requiredIds, telehealthId, shouldRemoveTelehealth, mode) => {
  if (reasonIds.length === 0) {
    return [];
  }
  let uniqueRequiredEquipmentIds = [...new Set(requiredIds)];
  if (shouldRemoveTelehealth || mode === 'edit') {
    uniqueRequiredEquipmentIds = uniqueRequiredEquipmentIds.filter(id => id !== telehealthId);
  }
  return uniqueRequiredEquipmentIds;
};

const serviceReducer = createReducer(
  {
    [action.mobilityChange]: (state, payload) => {
      const mobilityId = payload;
      const { serviceId } = state;
      const { patientTypeId, serviceLevelMap, therapistEquipmentIds, lockedProviderId, requirementIds } = state;
      const isMobilityWithoutEquipment = mobilityIdsWithoutEquipment(state).includes(mobilityId);
      const selectedTherapistEquipmentIds = isMobilityWithoutEquipment ? [] : therapistEquipmentIds;
      const selectedRequirementIds = isMobilityWithoutEquipment ? [] : requirementIds;

      const scope = serviceLevelMap.calcScope(
        mobilityId,
        patientTypeId,
        serviceId,
        selectedTherapistEquipmentIds,
        selectedRequirementIds,
        lockedProviderId,
      );

      const selectPatientTypeId = serviceLevelMap.calcPatientTypeIdToSelect(scope.patientTypeScope, patientTypeId);
      const selectServiceTypeId = serviceLevelMap.calcServiceTypeIdToSelect(scope.serviceTypeScope, serviceId);
      const equipmentState = buildEquipmentState(state, selectServiceTypeId, selectedTherapistEquipmentIds);
      const personnelState = buildPersonnelState(state, selectServiceTypeId, selectedRequirementIds);

      return {
        ...state,
        ...equipmentState,
        ...personnelState,
        mobilityId,
        patientTypeId: selectPatientTypeId,
        serviceTypeIds: isMobilityWithoutEquipment
          ? [+selectServiceTypeId]
          : setServiceTypes(
              state.serviceTypeIds,
              selectServiceTypeId,
              selectPatientTypeId,
              state.serviceTypeDictionary,
              state.patientTypeList,
            ),
        serviceId: selectServiceTypeId,
        scope,
      };
    },
    [action.patientTypeChange]: (state, payload) => {
      const patientTypeId = payload;
      const { mobilityId, serviceId, serviceLevelMap, therapistEquipmentIds, lockedProviderId, requirementIds } = state;

      const isPatientTypeWithoutEquipment = patientTypeIdsWithoutEquipment(state).includes(patientTypeId);
      const selectedTherapistEquipmentIds = isPatientTypeWithoutEquipment ? [] : therapistEquipmentIds;
      const selectedRequirementIds = isPatientTypeWithoutEquipment ? [] : requirementIds;

      const scope = serviceLevelMap.calcScope(
        mobilityId,
        patientTypeId,
        serviceId,
        selectedTherapistEquipmentIds,
        selectedRequirementIds,
        lockedProviderId,
      );

      const selectServiceTypeId = serviceLevelMap.calcServiceTypeIdToSelect(scope.serviceTypeScope, serviceId);
      const equipmentState = buildEquipmentState(state, selectServiceTypeId, selectedTherapistEquipmentIds);
      const personnelState = buildPersonnelState(state, selectServiceTypeId, selectedRequirementIds);

      return {
        ...state,
        ...equipmentState,
        ...personnelState,
        patientTypeId,
        serviceId: selectServiceTypeId,
        serviceTypeIds: setServiceTypes(
          state.serviceTypeIds,
          selectServiceTypeId,
          patientTypeId,
          state.serviceTypeDictionary,
          state.patientTypeList,
        ),
        scope,
      };
    },
    [action.personnelChange]: (state, payload) => {
      const requirementIds = payload.checked
        ? R.uniq(state.requirementIds.concat(payload.id))
        : state.requirementIds.filter(requirement => requirement !== payload.id);

      const { mobilityId, patientTypeId, serviceId, serviceLevelMap, lockedProviderId, therapistEquipmentIds } = state;
      const scope = serviceLevelMap.calcScope(
        mobilityId,
        patientTypeId,
        serviceId,
        therapistEquipmentIds,
        requirementIds,
        lockedProviderId,
      );

      const selectServiceTypeId = serviceLevelMap.calcServiceTypeIdToSelect(scope.serviceTypeScope, serviceId);

      return {
        ...state,
        serviceId: selectServiceTypeId,
        serviceTypeIds: setServiceTypes(
          state.serviceTypeIds,
          selectServiceTypeId,
          patientTypeId,
          state.serviceTypeDictionary,
          state.patientTypeList,
        ),
        requirementIds,
        scope,
      };
    },
    [action.equipmentChange]: (state, payload) => {
      const therapistEquipmentIds = therapistEquipment(state, payload);
      const {
        mobilityId,
        patientTypeId,
        serviceId,
        serviceLevelMap,
        lockedProviderId,
        requirementIds,
        serviceTypeDictionary,
      } = state;
      const telehealthEquipmentId = getEquipmentIdForTelehealth(state);
      const pcpEquipmentIds = getPCPEquipmentIds(state);
      const strictlyMappedEquipments = [...pcpEquipmentIds, telehealthEquipmentId].filter(Boolean);

      const scope = serviceLevelMap.calcScope(
        mobilityId,
        patientTypeId,
        serviceId,
        therapistEquipmentIds,
        requirementIds,
        lockedProviderId,
      );

      const selectServiceTypeId = serviceLevelMap.calcServiceTypeIdToSelect(scope.serviceTypeScope, serviceId);

      // temporary workaraound until new service type for TH introduced
      const serviceTypeIds = strictlyMappedEquipments.some(id => therapistEquipmentIds.includes(id))
        ? [serviceTypeDictionary[SERVICE_TYPE_NAMES.CCT].id]
        : setServiceTypes(
            state.serviceTypeIds,
            selectServiceTypeId,
            patientTypeId,
            state.serviceTypeDictionary,
            state.patientTypeList,
          );

      return {
        ...state,
        therapistEquipmentIds,
        serviceId: selectServiceTypeId,
        serviceTypeIds,
        scope,
      };
    },
    [action.equipmentAsServiceTypeChange]: (state, payload) => {
      const serviceTypeIds = R.uniq(setEquipmentAsServiceType(state.serviceTypeIds, payload));
      const therapistEquipmentIds = R.uniq(setEquipmentAsTherapistEquipment(state.therapistEquipmentIds, payload));

      return {
        ...state,
        serviceTypeIds,
        bariatric: serviceTypeIds.includes(state.serviceTypeDictionary[SERVICE_TYPE_NAMES.Bariatric].id),
        therapistEquipmentIds,
      };
    },
    [action.serviceTypeChange]: (state, payload) => {
      const serviceId = payload;
      const { mobilityId, patientTypeId, serviceLevelMap, therapistEquipmentIds, lockedProviderId, requirementIds } =
        state;
      const isServiceTypeWithoutEquipment = serviceTypeIdsWithoutEquipment(state).includes(serviceId);
      const selectedTherapistEquipmentIds = isServiceTypeWithoutEquipment ? [] : therapistEquipmentIds;
      const selectedRequirementIds = isServiceTypeWithoutEquipment ? [] : requirementIds;

      const scope = serviceLevelMap.calcScope(
        mobilityId,
        patientTypeId,
        serviceId,
        selectedTherapistEquipmentIds,
        requirementIds,
        lockedProviderId,
      );

      const selectServiceTypeId = serviceLevelMap.calcServiceTypeIdToSelect(scope.serviceTypeScope, serviceId);
      const equipmentState = buildEquipmentState(state, selectServiceTypeId, selectedTherapistEquipmentIds);
      const personnelState = buildPersonnelState(state, selectServiceTypeId, selectedRequirementIds);
      return {
        ...state,
        ...equipmentState,
        ...personnelState,
        serviceTypeIds: isServiceTypeWithoutEquipment
          ? [+payload]
          : setServiceTypes(
              state.serviceTypeIds,
              selectServiceTypeId,
              state.patientTypeId,
              state.serviceTypeDictionary,
              state.patientTypeList,
            ),
        serviceId: selectServiceTypeId,
        scope,
      };
    },
    [action.forcedCapabilitiesChange]: (state, payload) => {
      return {
        ...state,
        ...payload,
      };
    },
    [action.fetchPatientTypesSuccess]: (state, payload) => ({
      ...state,
      patientTypeList: R.sort(R.ascend(R.prop('displayOrder')), payload),
    }),
    [action.fetchServiceTypesSuccess]: (state, payload) => ({
      ...state,
      serviceTypeDictionary: buildServiceTypeDictionary(payload),
      serviceTypeLookup: buildServiceTypeLookup(payload),
    }),
    [action.fetchCallTypesSuccess]: (state, payload) => ({
      ...state,
      callTypeOptions: setCallTypeOptions(payload),
    }),
    [action.callTypeChange]: (state, payload) => ({
      ...state,
      callTypeId: payload,
      transportReasons: [{ transportReasonId: payload }],
    }),
    [action.setCallType]: (state, payload) => ({
      ...state,
      callTypeId: payload,
      transportReasons: [{ transportReasonId: payload }],
    }),
    [action.setAnswer]: (state, payload) => {
      const answers = { ...state.answers, ...payload };
      return {
        ...state,
        answers,
        questionMode: Object.values(answers).includes(''),
      };
    },
    [action.setAnswersForEmergency]: state => ({
      ...state,
      answers: {
        isBedbound: true,
        canTravelByCar: false,
        requiresWheelChair: false,
      },
    }),
    [action.clearAnswers]: state => ({
      ...state,
      answers: initialState.answers,
    }),
    [action.setQuestionMode]: (state, payload) => ({
      ...state,
      questionMode: payload,
    }),
    [action.loadRequestDetails]: (state, payload) => {
      const { serviceLevelMap, lockedProviderId } = state;

      const bariatricId = state.serviceTypeDictionary[SERVICE_TYPE_NAMES.Bariatric].id;
      const gurneyId = state.serviceTypeDictionary[SERVICE_TYPE_NAMES.Gurney].id;
      const serviceTypeId = payload.serviceTypeIds.find(id => ![bariatricId, gurneyId].includes(id));
      const serviceAndPatientTypeMapping = mapServiceTypeToPatientType(
        serviceTypeId,
        state.serviceTypeDictionary,
        state.patientTypes,
      );
      const therapistEquipmentIds = payload.therapistEquipment.map(
        singleTherapistEquipmnet => singleTherapistEquipmnet.equipmentId,
      );

      const requirementIds = R.pluck('id', payload.requirements);

      const scope = serviceLevelMap.calcScope(
        payload.mobilityId,
        serviceAndPatientTypeMapping.patientTypeId,
        serviceAndPatientTypeMapping.serviceId,
        therapistEquipmentIds,
        requirementIds,
        lockedProviderId,
      );

      return {
        ...state,
        serviceTypeIds: payload.serviceTypeIds,
        callTypeId: payload.callTypeId,
        mobilityId: payload.mobilityId,
        serviceId: serviceAndPatientTypeMapping.serviceId,
        patientTypeId: serviceAndPatientTypeMapping.patientTypeId,
        bariatric: payload.serviceTypeIds.includes(bariatricId),
        therapistEquipmentIds,
        requirementIds,
        scope,
      };
    },
    [action.preloadProviderCapabilities]: (state, payload) => {
      const {
        providers,
        equipment,
        mobilities,
        personnel,
        serviceTypes,
        serviceLevelMap,
        patientTypes,
        capabilityRelations,
        capabilityCombinations,
        capabilityCombinationVariants,
        requirements,
      } = payload;

      let transportReasons = payload.transportReasons;

      const patientTypesSorted = R.sort(R.ascend(R.prop('displayOrder')), patientTypes);

      const mobilitiesMap = arrToObj(mobilities);
      const serviceTypesMap = arrToObj(serviceTypes);
      let sortedEquipment = sortByOrder(equipment.filter(e => e.requestFieldMapping !== 'serviceTypeIds'));

      const serviceLevelMapInit = new ServiceLevelMap(
        serviceLevelMap,
        mobilities,
        personnel,
        serviceTypes,
        providers,
        capabilityRelations,
        capabilityCombinations,
        capabilityCombinationVariants,
        patientTypesSorted,
      );
      return {
        ...state,
        providers,
        equipment: sortedEquipment,
        equipmentAsService: equipment.filter(e => e.requestFieldMapping === 'serviceTypeIds'),
        personnel,
        mobilities: mobilitiesMap,
        serviceTypes: serviceTypesMap,
        serviceLevelMap: serviceLevelMapInit,
        patientTypes: patientTypesSorted,
        requirements: R.sort(R.ascend(R.prop('displayOrder')), requirements),
        transportReasonCheckList: transportReasons,
      };
    },
    [action.fetchProviderCapabilitesSuccess]: state => ({
      ...state,
    }),
    [action.mapServiceTypeToPatientType]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setLockedProvider]: (state, { id }) => ({
      ...state,
      lockedProviderId: id,
    }),
    [action.fetchAndDisableTransportReasonSuccess]: (state, payload) => ({
      ...state,
      callTypeOptions: [
        {
          defaultFromNowPickupTimeInMins: payload.defaultFromNowPickupTimeInMins,
          disabled: true,
          emergency: payload.emergency,
          key: payload.id,
          value: payload.name,
        },
      ].concat(state.callTypeOptions),
    }),
    [action.resetService]: state => ({
      ...state,
      isRespiratoryTherapistRequired: 0,
      requiredAttendantGenderId: null,
      respiratoryTherapistEquipmentId: null,
      therapistEquipmentIds: [],
      serviceId: 1,
      serviceTypeIds: [1],
      questionMode: true,
      callTypeId: CallType.DEFAULT,
      answers: {
        isBedbound: '',
        canTravelByCar: '',
        requiresWheelChair: '',
      },
      mobilityId: null,
      patientTypeId: 1,
      bariatric: false,
      transportReasons: [],
    }),
    [action.setRequirementWarning]: (state, payload) => ({
      ...state,
      showRequirementWarning: payload,
    }),
    [action.setAgencyTransportReasons]: (state, payload) => ({
      ...state,
      agencyTransportReasons: payload,
    }),
    [action.setTransportReasons]: (state, payload) => {
      const { transportReasons, isNonTransport, mode } = payload;
      const { serviceTypeDictionary } = state;
      const dictionary = state.transportReasonsAndEquipmentIdsDictionary;
      const telehealthEquipmentId = getEquipmentIdForTelehealth(state);
      const pcpEquipmentIds = getPCPEquipmentIds(state);
      const strictlyMappedEquipments = [...pcpEquipmentIds, telehealthEquipmentId].filter(Boolean);
      const transportReasonIds = toTransportReasonIdStrings(transportReasons);
      const requiredEquipmentIds = getAllRequiredEquipmentIds(dictionary);
      const shouldRemoveTeleHealth = checkIfTelehealthShouldBeRemoved(dictionary, telehealthEquipmentId);

      const updatedTherapistEquipmentIds = isNonTransport
        ? getUpdatedEquipmentIdsForNonTransport(
            transportReasonIds,
            requiredEquipmentIds,
            telehealthEquipmentId,
            shouldRemoveTeleHealth,
            mode,
          )
        : [...new Set(requiredEquipmentIds)];

      // temporary workaraound until new service type for TH introduced
      const updatedServiceTypeIds = strictlyMappedEquipments.some(id => updatedTherapistEquipmentIds.includes(id))
        ? [serviceTypeDictionary[SERVICE_TYPE_NAMES.CCT].id]
        : state.serviceTypeIds;

      return {
        ...state,
        transportReasons,
        therapistEquipmentIds: updatedTherapistEquipmentIds,
        serviceTypeIds: updatedServiceTypeIds,
      };
    },
    [action.setTheraphistEquipmentIds]: (state, payload) => {
      return {
        ...state,
        therapistEquipmentIds: payload.map(item => item.equipmentId),
      };
    },
    [action.setTelehealthLink]: (state, payload) => {
      return {
        ...state,
        telehealthLink: payload,
      };
    },
    [action.setTransportReasonsAndEquipmentIdsDictionary]: (state, payload) => {
      const newState = { ...state };
      let transportReasonsAndEquipmentIdsDictionary = { ...newState.transportReasonsAndEquipmentIdsDictionary };
      if (payload.selectedValue) {
        const transportReasonId = payload.selectedValue;
        const requiredEquipmentIds = getRequiredEquipmentIdsByTransportReasonId(newState, transportReasonId);
        if (payload.isToAdd) {
          transportReasonsAndEquipmentIdsDictionary[transportReasonId] = requiredEquipmentIds;
        } else {
          if (transportReasonsAndEquipmentIdsDictionary.hasOwnProperty(transportReasonId)) {
            const { [transportReasonId]: _, ...updatedDictionary } = transportReasonsAndEquipmentIdsDictionary;
            transportReasonsAndEquipmentIdsDictionary = updatedDictionary;
          }
        }
      } else if (payload.transportReasons) {
        payload.transportReasons.forEach(transportReason => {
          const transportReasonId = transportReason.transportReasonId;
          const requiredEquipmentIds = getRequiredEquipmentIdsByTransportReasonId(newState, transportReasonId);
          transportReasonsAndEquipmentIdsDictionary = {
            ...transportReasonsAndEquipmentIdsDictionary,
            [transportReasonId]: requiredEquipmentIds,
          };
        });
      }
      return {
        ...newState,
        transportReasonsAndEquipmentIdsDictionary,
      };
    },
  },
  initialState,
);

export default serviceReducer;
