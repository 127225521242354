import { concat as concat$, of as of$ } from 'rxjs';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';

import { agencyRequiresPatientInfo, hasFacesheet } from '../../flags/valid.flag';
import { updateReturnRequestCanBeActivated } from '../../services/returnRequestCanBeActivated.service';
import * as errorActions from '../actions/error.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as requestsActions from '../actions/requests.actions';
import { autoCompletePipeDynamic } from './epicsUtil';
import { searchPayer } from './helpers/infobox.helper';
import { isPatientPresent } from './helpers/patient.helper';
import { submitRequest } from './helpers/request.helper';

export const submitPatientInfoSuccessOnClickSaveEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.submitPatientInfoSuccess.match),
    filter(() => !store.value.formFlow.autoSaveInProgress),
    mergeMap(() => concat$(of$(formFlowActions.setSubmitInProgress(false)), of$(formFlowActions.setDirty(false)))),
  );

export const submitPatientInfoSuccessOnAutoSaveEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        infoboxActions.submitPatientInfoSuccess.match,
        infoboxActions.updateRequesterSuccess.match,
        infoboxActions.updatePatientSuccess.match,
        infoboxActions.updateCoveragesSuccess.match,
        infoboxActions.updateNotesSuccess.match,
      ].some(match => match(action)),
    ),
    filter(() => store.value.formFlow.autoSaveInProgress),
    mergeMap(() => concat$(of$(formFlowActions.autoSaveInProgress(false)), of$(formFlowActions.setDirty(false)))),
  );

export const searchPayerEpic = action$ =>
  action$.pipe(filter(infoboxActions.queryPayers.match), autoCompletePipeDynamic, searchPayer);

export const submitOnUpdatePatientInfoEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.updatePatientInfo.match),
    filter(() => !store.value.requests.request.isCompleted),
    filter(() => !agencyRequiresPatientInfo(store.value) || isPatientPresent(store.value) || hasFacesheet(store.value)),
    switchMap(() => submitRequest(store)),
    switchMap(result => updateReturnRequestCanBeActivated(result, store.value)),
    mergeMap(response =>
      concat$(
        of$(requestsActions.updateRequestSuccess(response)),
        of$(infoboxActions.updatePatientSuccess(response)),
        of$(infoboxActions.checkPayerRules()),
        of$(errorActions.onError(response)),
      ),
    ),
    catchError(error => of$(errorActions.onUhandledError('patient'))),
  );
