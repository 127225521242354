import React from 'react';

const withValidations =
  (validations = {}) =>
  WrappedComponent => {
    class ValidatableComponent extends React.Component {
      constructor() {
        super();
        const touched = Object.keys(validations).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {});
        this.state = {
          errors: {},
          hasErrors: false,
          touched,
          allTouched: false,
        };
        this.validate = this.validate.bind(this);
        this.validateAll = this.validateAll.bind(this);
      }
      componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
          let newState = {};
          Object.keys(validations).forEach(key => {
            if (prevProps[key] !== this.props[key]) {
              const currentErrors = newState.errors || this.state.errors;
              newState = this.validate(key, this.props, currentErrors);
            }
          });
          this.setState(newState);
        }
      }

      validate(name, nextProps, currentErrors) {
        const validator = validations[name];
        const value = nextProps[name];
        const errors = {
          ...currentErrors,
          [name]: validator(value, nextProps),
        };
        const hasErrors = Object.keys(errors).some(key => errors[key] !== null);
        const touched = { ...this.state.touched, [name]: true };
        const allTouched = !Object.keys(touched).some(key => !touched[key]);
        return {
          errors,
          hasErrors,
          touched,
          allTouched,
        };
      }

      validateAll(props) {
        const errors = {};
        Object.keys(validations).forEach(key => {
          const validator = validations[key];
          const value = props[key];
          errors[key] = validator(value, props);
        });
        const hasErrors = Object.keys(errors).some(key => errors[key] !== null);
        this.setState({ errors, hasErrors });
      }

      render() {
        return (
          <WrappedComponent {...this.props} {...this.state} validateAll={this.validateAll} validate={this.validate} />
        );
      }
    }

    return ValidatableComponent;
  };

export default withValidations;
