import ApiService from './api.service';

class RequesterUpdateTypesService {
  static async requesterUpdateTypes() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/RequesterUpdateTypes`;
    let response = await ApiService.get(url);
    response = response.json();
    return response;
  }
}

export default RequesterUpdateTypesService;
