import { serviceTypeByProvider } from './ServiceLevel.flags';

const transportationMessage = (prefix, notProvided) =>
  notProvided ? `${prefix} medical transportation is not yet provided in this market.` : '';

// const patientTypeMessage = (prefix, notSupported) =>
//   notSupported
//     ? `${prefix} Patient Type is not yet supported in this market.`
//     : '';

const serviceLockMessage = locked =>
  locked
    ? `Current trip can't change the selected Service Level to this one anymore. Please cancel the trip and create a new request instead.`
    : '';

export const canTravelByCarTitle = ({ service }) =>
  transportationMessage('Car', !service.serviceTypes.Curbside && !service.serviceTypes.Livery);

export const requiresWheelChairTitle = ({ service }) =>
  transportationMessage('Wheelchair', !service.mobilities.wheelchair);

export const NICUTitle = () => ''; // patientTypeMessage('NICO', NICUPatientDisabled(state));

export const PEDSTitle = () => ''; // patientTypeMessage('PEDS', PEDSPatientDisabled(state));

export const AdultTitle = () => ''; // patientTypeMessage('Adult', AdultPatientDisabled(state));

export const getCustomServiceLevelTitle = (serviceTypeId, name, state) => {
  const hasService = serviceTypeId;
  return hasService
    ? serviceLockMessage(serviceTypeByProvider(state, hasService))
    : transportationMessage(name, !hasService);
};

export const BLSTitle = state => serviceLockMessage(serviceTypeByProvider(state, state.service.serviceTypes.BLS));

export const ALSTitle = state => serviceLockMessage(serviceTypeByProvider(state, state.service.serviceTypes.ALS));

export const CCTTitle = state => serviceLockMessage(serviceTypeByProvider(state, state.service.serviceTypes.CCT));
