import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import GetETAElem from './GetEtaLabel/GetEtaLabel';

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isNonTransport' && prop !== 'isVisible',
})(({ isNonTransport, isVisible }) => ({
  visibility: isVisible ? 'visible' : 'hidden',
  fontWeight: 800,
  fontSize: 14,
  width: isNonTransport ? '151px' : '100%',
  height: isNonTransport ? '43px' : '61px',
  maxWidth: 220,
  padding: '0 5px 0 5px',
  borderRadius: 8,
  boxShadow: '0px 1px 4px 0px rgba(0, 64, 82, 0.35)',
}));

const EtaLabel = styled('span', {
  shouldForwardProp: prop => prop !== 'isNonTransport',
})(({ isNonTransport, disabled }) => ({
  paddingBottom: isNonTransport ? '0px' : '3px',
  color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#fff',
}));

const EtaButton = props => {
  const { isDisabled, handleGetEtaClick, isVisible, loadedWillCall, isNonTransport } = props;

  return (
    <StyledButton
      variant="contained"
      id="ETAButton"
      data-testid="ETAButton"
      disabled={isDisabled || null}
      onClick={handleGetEtaClick}
      color="primary"
      isNonTransport={isNonTransport}
      isVisible={isVisible}
    >
      <EtaLabel isNonTransport={isNonTransport} disabled={isDisabled}>
        <GetETAElem loadedWillCall={loadedWillCall} isNonTransport={isNonTransport} />
      </EtaLabel>
    </StyledButton>
  );
};

EtaButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  loadedWillCall: PropTypes.bool.isRequired,
  handleGetEtaClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isNonTransport: PropTypes.bool.isRequired,
};

export default EtaButton;
