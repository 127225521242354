/* eslint-disable class-methods-use-this */
import * as R from 'ramda';
import { PatientType, SERVICE_TYPE_NAMES } from '../../enums';
import { arrToObj } from '../../helpers/queryStringHelper';

const equalIgnoreOrder = R.compose(R.isEmpty, R.symmetricDifference);

export default class ServiceLevelMap {
  constructor(
    serviceLevelMap,
    mobilities = [],
    personnel = [],
    serviceTypes = [],
    providers,
    capabilityRelations = [],
    capabilityCombinations = [],
    capabilityCombinationVariants = [],
    patientTypes = [],
  ) {
    this.serviceLevelMap = serviceLevelMap;
    this.mobilities = mobilities;
    this.personnel = personnel;
    this.serviceTypes = serviceTypes;
    this.providers = providers;
    this.capabilityRelations = capabilityRelations;
    this.capabilityCombinations = capabilityCombinations;
    this.capabilityCombinationVariants = capabilityCombinationVariants;
    this.patientTypes = patientTypes;
    this.serviceTypesMap = arrToObj(serviceTypes);

    const presentCapabilitiesAsCapabilityScope = R.applySpec({
      type: R.pipe(R.head, R.prop('type')),
      capabilities: R.map(
        R.applySpec({
          id: R.prop('id'),
          type: R.prop('type'),
        }),
      ),
    });

    this.defaultScope = { capabilities: [] };
    this.defaultMobilityScope = R.isEmpty(this.mobilities)
      ? Object.assign(this.defaultScope, { type: 'Mobility' })
      : presentCapabilitiesAsCapabilityScope(this.mobilities);
    this.defaultPatientTypeScope = R.isEmpty(this.patientTypes)
      ? Object.assign(this.defaultScope, { type: 'PatientType' })
      : presentCapabilitiesAsCapabilityScope(this.patientTypes);
    this.defaultServiceTypeScope = R.isEmpty(this.serviceTypes)
      ? Object.assign(this.defaultScope, { type: 'ServiceType' })
      : presentCapabilitiesAsCapabilityScope(this.serviceTypes);
  }

  toObject() {
    return this.serviceLevelMap;
  }

  getSelectedCapabilityRelations(capabilityRelations, selectedCapability) {
    const isSelectedCapabilityCombination = !!selectedCapability.capabilities;

    return capabilityRelations.filter(capabilityRelation => {
      const idMatch = relation => relation.parentCapability.id === selectedCapability.id;
      const typeMatch = relation => relation.parentCapability.type === selectedCapability.type;
      const combinationTypeMatch = relation => relation.parentCapability.type === 'CapabilityCombination';

      return isSelectedCapabilityCombination
        ? combinationTypeMatch(capabilityRelation) && idMatch(capabilityRelation)
        : typeMatch(capabilityRelation) && idMatch(capabilityRelation);
    });
  }

  groupByCapabilityType(selectedCapabilityRelation) {
    const capabilityScopeGrouped = [];
    selectedCapabilityRelation.childrenCapabilities.forEach(capability => {
      const scopeIndex = capabilityScopeGrouped.findIndex(item => item.type === capability.type);
      if (scopeIndex >= 0) {
        capabilityScopeGrouped[scopeIndex].capabilities.push(capability);
      } else {
        capabilityScopeGrouped.push({
          relationParentType: selectedCapabilityRelation.parentCapability.type,
          relationParentId: selectedCapabilityRelation.parentCapability.id,
          type: capability.type,
          capabilities: [capability],
        });
      }
    });

    return capabilityScopeGrouped;
  }

  joinScopes(scopesAcc, currentScope) {
    const existingScopeIndex = this.findExistingScopeIndex(scopesAcc, currentScope);
    if (existingScopeIndex === -1) {
      return scopesAcc.concat(this.transformToScopeWithMultipleParents(currentScope));
    }

    const existingScope = scopesAcc[existingScopeIndex];
    const newScopesAcc = this.scopesWithoutExistingScope(scopesAcc, existingScopeIndex);

    const joinedCapabilities = R.innerJoin(
      (item1, item2) => item1.id === item2.id,
      existingScope.capabilities,
      currentScope.capabilities,
    );

    const joinedScope = {
      type: existingScope.type,
      capabilities: joinedCapabilities,
      relationParents: existingScope.relationParents.concat([
        { type: currentScope.relationParentType, id: currentScope.relationParentId },
      ]),
    };

    return newScopesAcc.concat(joinedScope);
  }

  transformToScopeWithMultipleParents(scope) {
    return {
      type: scope.type,
      capabilities: scope.capabilities,
      relationParents: [{ type: scope.relationParentType, id: scope.relationParentId }],
    };
  }

  scopesWithoutExistingScope(scopes, existingScopeIndex) {
    return R.remove(existingScopeIndex, 1, scopes);
  }

  findExistingScopeIndex(scopesAcc, currentScope) {
    return scopesAcc.findIndex(scope => scope.type === currentScope.type);
  }

  reorderSelectedCapabilityScopes(selectedCapabilityScopes) {
    const orderSpec = [
      'Mobility',
      'PatientType',
      'CapabilityCombination',
      'ServiceType',
      'TherapistEquipment',
      'Requirement',
    ];

    const priority = (firstCapability, secondCapability) =>
      R.findIndex(R.equals(firstCapability.relationParentType), orderSpec) -
      R.findIndex(R.equals(secondCapability.relationParentType), orderSpec);
    return R.sort(priority, R.flatten(selectedCapabilityScopes));
  }

  innerJoinByType(selectedCapabilityScopes) {
    const scopes = selectedCapabilityScopes;
    return R.reduce(this.joinScopes.bind(this), [], scopes);
  }

  buildCapabilityScopes(selectedCapabilities, capabilityRelations) {
    const selectedCapabilityScopes = selectedCapabilities.map(selectedCapability => {
      const selectedCapabilityRelations = this.getSelectedCapabilityRelations(capabilityRelations, selectedCapability);
      return selectedCapabilityRelations.map(selectedCapabilityRelation =>
        this.groupByCapabilityType(selectedCapabilityRelation),
      );
    });
    const reorderedByPrioritySelectedCapabilityScopes = this.reorderSelectedCapabilityScopes(selectedCapabilityScopes);
    return this.innerJoinByType(reorderedByPrioritySelectedCapabilityScopes);
  }

  buildCriteriaForSelectedCombination(selectedCapabilities) {
    return this.capabilityCombinationVariants.map(capabilityCombinationVariant => {
      const combinationCapabilities = [];
      capabilityCombinationVariant.capabilityTypes.forEach(capabilityType => {
        const combinationCapability = selectedCapabilities.find(capability => capability.type === capabilityType);
        combinationCapabilities.push(combinationCapability);
      });

      return combinationCapabilities;
    });
  }

  /**
   * Find a CapabilityCombination by a provided set of capabilities (criteria)
   */
  findSelectedCombination(combinationCriteria) {
    const selectedCombination = this.capabilityCombinations.find(capabilityCombination =>
      equalIgnoreOrder(capabilityCombination.capabilities, combinationCriteria),
    );

    if (selectedCombination) {
      return selectedCombination;
    }

    const partialCriteria = this.buildPartialCombinationCriteria(combinationCriteria);
    if (!partialCriteria) {
      return null;
    }

    return this.getFirstAvailableCombinationByPartialCriteria(partialCriteria);
  }

  matchVariant(combinationCriteria) {
    return this.capabilityCombinationVariants.find(capabilityCombinationVariant =>
      equalIgnoreOrder(capabilityCombinationVariant.capabilityTypes, R.map(R.prop('type'), combinationCriteria)),
    );
  }

  buildPartialCombinationCriteria(combinationCriteria) {
    const matchedVariant = this.matchVariant(combinationCriteria);
    if (!matchedVariant) {
      return null;
    }

    return combinationCriteria.filter(el => el.type === matchedVariant.capabilityTypes[0]);
  }

  /**
   * NOTE: This fallback works ONLY for combinations with two capabilities
   *
   * Return a first available CapabilityCombination
   * which matches the partial criteria.
   * The partial criteria is constructed with the first capability type of CapabilityCombination variant
   *
   * For ex. switching "Bed Bound, NICU" -> "Can Walk, NICU"
   * 1. "Can Walk, NICU" is not found,
   * 2. thus we need to take first available combination.
   * 3. Search for combination with "Can Walk" capability
   * 4. Take the first one: "Can Walk, Adult"
   */
  getFirstAvailableCombinationByPartialCriteria(partialCriteria) {
    return this.capabilityCombinations.find(capabilityCombination =>
      capabilityCombination.capabilities.some(capability => R.equals(capability, partialCriteria[0])),
    );
  }

  getSelectedCapabilityCombinationsByVariants(selectedCapabilities) {
    // 1. build selected capability combination criterias by variants
    const selectedCombinationCriterias = this.buildCriteriaForSelectedCombination(selectedCapabilities);
    // 2. find selected capability combination from available combinations and set the combination id
    return R.reject(
      R.isNil,
      selectedCombinationCriterias.map(selectedCombinationCriteria =>
        this.findSelectedCombination(selectedCombinationCriteria),
      ),
    );
  }

  buildSelectedCapabilities(mobilityId, patientTypeId, serviceTypeId, therapistEquipmentIds, requirementIds) {
    const selectedCapabilities = [
      { id: Number(mobilityId), type: 'Mobility' },
      { id: Number(patientTypeId), type: 'PatientType' },
    ];

    if (serviceTypeId) {
      selectedCapabilities.push({ id: Number(serviceTypeId), type: 'ServiceType' });
    }

    const selectedCapabilitiesTherapistEquipment = therapistEquipmentIds.map(therapistEquipmentId => ({
      id: therapistEquipmentId,
      type: 'TherapistEquipment',
    }));

    const selectedCapabilitiesRequirements = requirementIds.map(requirementId => ({
      id: requirementId,
      type: 'Requirement',
    }));

    return selectedCapabilities.concat(selectedCapabilitiesTherapistEquipment).concat(selectedCapabilitiesRequirements);
  }

  getCapabilityScopes(mobilityId, patientTypeId, serviceTypeId, therapistEquipmentIds = [], requirementIds = []) {
    const selectedCapabilities = this.buildSelectedCapabilities(
      mobilityId,
      patientTypeId,
      serviceTypeId,
      therapistEquipmentIds,
      requirementIds,
    );
    return this.buildCapabilityScopes(
      selectedCapabilities.concat(this.getSelectedCapabilityCombinationsByVariants(selectedCapabilities)),
      this.capabilityRelations.filter(singleCapabilityRelation => singleCapabilityRelation.type === 'allow'),
    );
  }

  getFirstCapabilityIdFromScope(capabilityScope) {
    return R.path(['capabilities', 0, 'id'], capabilityScope);
  }

  isCapabilitiesPresent() {
    return (
      !R.isEmpty(this.capabilityRelations) &&
      !R.isEmpty(this.capabilityCombinations) &&
      !R.isEmpty(this.capabilityCombinationVariants)
    );
  }

  /**
   * TODO: Refactor to separate methods
   */
  isEnabled(scope, mobilityId, patientTypeId, serviceTypeId) {
    if (!this.isCapabilitiesPresent() || R.isEmpty(scope)) {
      return {
        mobility: false,
        patientType: false,
        serviceType: false,
      };
    }

    const isMobilityEnabled = !!scope.mobilityScope.capabilities.find(
      singleMobility => singleMobility.id === Number(mobilityId),
    );
    const isPatientTypeEnabled = !!scope.patientTypeScope.capabilities.find(
      singlePatientType => singlePatientType.id === Number(patientTypeId),
    );
    const isServiceTypeEnabled = !!scope.serviceTypeScope.capabilities.find(
      singleServiceType => singleServiceType.id === Number(serviceTypeId),
    );

    return {
      mobility: isMobilityEnabled,
      patientType: isPatientTypeEnabled,
      serviceType: isServiceTypeEnabled,
    };
  }

  getServiceTypeScope(capabilityScopes, serviceTypeId, providerId) {
    const serviceTypeScope = capabilityScopes.find(
      singleServiceTypeScope => singleServiceTypeScope.type === 'ServiceType',
    );

    if (!serviceTypeScope && providerId) {
      const provider = this.providers.find(singleProvider => singleProvider.id === providerId);
      const serviceTypeCapabilityFromScope = serviceTypeScope.capabilities.find(
        singleServiceType => singleServiceType.id === serviceTypeId,
      );
      if (serviceTypeCapabilityFromScope && provider.serviceTypeIds.includes(serviceTypeCapabilityFromScope.id)) {
        return serviceTypeScope;
      }

      return this.defaultServiceTypeScope;
    }

    if (serviceTypeScope) {
      return serviceTypeScope;
    }

    return this.defaultServiceTypeScope;
  }

  hasServiceTypeTherapistEquipment(serviceTypeId) {
    if (!serviceTypeId) {
      return true;
    }

    const { Curbside, Livery, WAV } = this.serviceTypesMap;
    return ![Curbside, Livery, WAV].includes(serviceTypeId);
  }

  /**
   * Calculate capability scopes driven by CapabilityRelation, ProviderCapability and CapabilityCombination
   */
  calcScope(
    mobilityIdParam,
    patientTypeIdParam,
    serviceTypeIdParam,
    therapistEquipmentIdsParam,
    requirementIdsParam,
    providerId,
  ) {
    if (!this.isCapabilitiesPresent()) {
      return {
        capabilityScopes: [],
        mobilityScope: {},
        patientTypeScope: {},
        serviceTypeScope: {},
      };
    }

    const capabilityScopes = this.getCapabilityScopes(
      mobilityIdParam,
      patientTypeIdParam,
      serviceTypeIdParam,
      therapistEquipmentIdsParam,
      requirementIdsParam,
    );

    // If there is no scope calculated for a capability, fallback to all available list of such capabilities

    const mobilityScope =
      capabilityScopes.find(singleMobilityScope => singleMobilityScope.type === 'Mobility') ||
      this.defaultMobilityScope;
    const patientTypeScope =
      capabilityScopes.find(singlePatientTypeScope => singlePatientTypeScope.type === 'PatientType') ||
      this.defaultPatientTypeScope;
    const serviceTypeScope = this.getServiceTypeScope(capabilityScopes, Number(serviceTypeIdParam), providerId);

    return {
      capabilityScopes,
      mobilityScope,
      patientTypeScope,
      serviceTypeScope,
    };
  }

  calcRequiredCapabilities(mobilityId, patientTypeId, serviceTypeId, therapistEquipmentIds = [], requirementIds = []) {
    const selectedCapabilities = this.buildSelectedCapabilities(
      mobilityId,
      patientTypeId,
      serviceTypeId,
      therapistEquipmentIds,
      requirementIds,
    );
    const requireCapabilityRelations = this.capabilityRelations.filter(
      singleCapabilityRelation => singleCapabilityRelation.type === 'require',
    );

    const requiredCapabilities = R.flatten(
      selectedCapabilities.reduce((capabilityAccumulator, selectedCapability) => {
        const selectedCapabilityRelations = requireCapabilityRelations.filter(
          requireCapabilityRelation =>
            requireCapabilityRelation.parentCapability.type === selectedCapability.type &&
            requireCapabilityRelation.parentCapability.id === selectedCapability.id,
        );

        capabilityAccumulator.push(selectedCapabilityRelations);

        return capabilityAccumulator;
      }, []),
    );

    const uniqForcedCapabilities = R.uniqBy(
      capability => `${capability.type}:${capability.id}`,
      R.flatten(R.pluck('childrenCapabilities', requiredCapabilities)),
    );

    return requiredCapabilities.reduce((acc, requiredCapability) => {
      const capability = uniqForcedCapabilities.find(uniqForcedCapability =>
        requiredCapability.childrenCapabilities.includes(uniqForcedCapability),
      );

      if (capability) {
        acc.push({ parentCapability: requiredCapability.parentCapability, capability });
      }

      return acc;
    }, []);
  }

  calcMobilityIdToSelect(mobilityScope, mobilityIdParam) {
    const isMobilityAllowedByScope = !!mobilityScope.capabilities.find(
      singleMobility => singleMobility.id === Number(mobilityIdParam),
    );
    return isMobilityAllowedByScope ? Number(mobilityIdParam) : this.getFirstCapabilityIdFromScope(mobilityScope);
  }

  calcPatientTypeIdToSelect(patientTypeScope, patientTypeIdParam) {
    const isPatientTypeAllowedByScope = !!patientTypeScope.capabilities.find(
      singlePatientType => singlePatientType.id === Number(patientTypeIdParam),
    );
    return isPatientTypeAllowedByScope
      ? Number(patientTypeIdParam)
      : this.getFirstCapabilityIdFromScope(patientTypeScope);
  }

  calcServiceTypeIdToSelect(serviceTypeScope, serviceTypeIdParam) {
    const isServiceTypeAllowedByScope = !!serviceTypeScope.capabilities.find(
      singleServiceType => singleServiceType.id === Number(serviceTypeIdParam),
    );
    return isServiceTypeAllowedByScope
      ? Number(serviceTypeIdParam)
      : this.getFirstCapabilityIdFromScope(serviceTypeScope);
  }

  calcTherapistEquipmentIdsToSelect(serviceTypeId, therapistEquipmentIdsParam) {
    return this.hasServiceTypeTherapistEquipment(serviceTypeId) ? therapistEquipmentIdsParam : [];
  }
}

export function mapServiceTypeToPatientType(serviceId, serviceTypeDictionary, patientTypes) {
  const nicuBlsServiceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.NICU_BLS].id;
  const pedsBlsServiceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.PEDS_BLS].id;
  const pedsAlsServiceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.PEDS_ALS].id;
  const pedsCctServiceTypeId = serviceTypeDictionary[SERVICE_TYPE_NAMES.PEDS_CCT].id;
  const NICU = patientTypes.find(patientType => patientType.name === R.toLower(PatientType.NICU));
  const PEDS = patientTypes.find(patientType => patientType.name === R.toLower(PatientType.PEDS));
  const Adult = patientTypes.find(patientType => patientType.name === R.toLower(PatientType.Adult));

  switch (serviceId) {
    case nicuBlsServiceTypeId:
      return {
        serviceId: serviceTypeDictionary[SERVICE_TYPE_NAMES.BLS].id,
        patientTypeId: NICU.id,
      };
    case pedsBlsServiceTypeId:
      return {
        serviceId: serviceTypeDictionary[SERVICE_TYPE_NAMES.BLS].id,
        patientTypeId: PEDS.id,
      };
    case pedsAlsServiceTypeId:
      return {
        serviceId: serviceTypeDictionary[SERVICE_TYPE_NAMES.ALS].id,
        patientTypeId: PEDS.id,
      };
    case pedsCctServiceTypeId:
      return {
        serviceId: serviceTypeDictionary[SERVICE_TYPE_NAMES.CCT].id,
        patientTypeId: PEDS.id,
      };
    default:
      return {
        serviceId,
        patientTypeId: Adult.id,
      };
  }
}
