import * as infoboxActions from '../../actions/infobox.actions';
import createMiddleware from '../helper';

const setPayerActive = createMiddleware(infoboxActions.checkPayerActive, ({ dispatch, action, getState }) => {
  const { payload } = action;
  const { coordinationTypeId, isActive } = payload;
  const state = getState();

  dispatch(
    infoboxActions.setPayerInfo({
      coordinationTypeId,
      isActive,
      activeAt: isActive ? new Date().toISOString() : null,
      activatorId: isActive ? state.user.model.id : null,
      activatorName: isActive ? state.user.model.fullName : '',
    }),
  );
  dispatch(infoboxActions.setPayerActive());
  dispatch(infoboxActions.autoSavePayerInfo());

  const isPayersActive = Object.values(getState().infobox.coverages)
    .map(coverage => coverage.isActive)
    .reduce((acc, value) => acc || value, false);

  if (!isPayersActive) {
    dispatch(infoboxActions.billerVerifiedChange(false));
  }
});

const middlewares = [setPayerActive];

export default middlewares;
