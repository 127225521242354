import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import AmbInputLabel from 'components/common/AmbInputLabel';
import AmbSelect from 'components/common/AmbSelect';
import { theme } from '../../../theme';

const StyledTextArea = styled.textarea`
  font: inherit;
  color: currentColor;
  border: 0;
  margin: 0;
  padding: 0;
  resize: vertical;
  display: block;
  min-width: 0;
  outline: none;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
`;

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 14px;
  padding: 6px 0 7px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: #004053;
  }
`;

const TextArea = ({ id, name, fullWidth, onChange, required }) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <Wrapper isFocused={isFocused}>
        <div style={{ position: 'relative', width: '100%', height: '62px' }}>
          <StyledTextArea
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            type="text"
            rows="3"
            rowsMax="7"
            required={required}
            onChange={onChange}
            name={name}
            id={id}
            style={{ width: fullWidth ? '100%' : 'auto' }}
          />
        </div>
      </Wrapper>
    </>
  );
};

export class TurndownDialogFrame extends React.PureComponent {
  static propTypes = {
    setTurndownInfo: PropTypes.func.isRequired,
    setTurndownInProgress: PropTypes.func.isRequired,
    setTurndownDialogOpen: PropTypes.func.isRequired,
    turndownETA: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isDispatcher: PropTypes.bool,
    comments: PropTypes.string,
    turndownTypes: PropTypes.arrayOf(PropTypes.shape()),
  };

  handleInputChange = event => {
    this.props.setTurndownInfo({ [event.target.name]: event.target.value });
  };

  handleClose = value => {
    if (value) {
      this.props.setTurndownInProgress(true);
      this.props.turndownETA();
    }
    if (!value) {
      this.props.setTurndownDialogOpen(false);
    }
  };

  render() {
    const options = this.props.turndownTypes.map(type => (
      <option key={type.id} value={type.id}>
        {type.name}
      </option>
    ));

    const labelProps = {
      style: { ...theme.mixins.inputLabel },
      shrink: true,
    };

    return (
      <ConfirmationDialog
        open={this.props.open}
        onClose={this.handleClose}
        title="Turndown ETA"
        disableConfirm={this.props.isDispatcher && !this.props.comments}
        fullWidth
        maxWidth="360px"
        cancel
      >
        {this.props.isDispatcher && (
          <>
            <div
              style={{
                margin: 0,
                border: 0,
                display: 'inline-flex',
                padding: 0,
                position: 'relative',
                minWidth: 0,
                flexDirection: 'column',
              }}
            >
              <AmbInputLabel htmlFor="turndown-type" style={labelProps.style} shrink required>
                Turndown Reason
              </AmbInputLabel>
              <AmbSelect
                style={{
                  position: 'relative',
                  zIndex: 10,
                  width: 'calc(100% - 32px)',
                  cursor: 'pointer',
                  minWidth: 16,
                  outline: 'none',
                  userSelect: 'none',
                  padding: '6px 32px 7px 0',
                  MozAppearance: 'none',
                  WebkitAppearance: 'none',
                  font: 'inherit',
                  color: '#000',
                  border: 0,
                  margin: 0,
                  display: 'block',
                  boxSizing: 'content-box',
                  background: 'none',
                  verticalAlign: 'middle',
                  WebkitTapHighlightColor: 'transparent',
                }}
                onChange={this.handleInputChange}
                id="turndown-type"
                name="typeId"
                options={options}
              />
            </div>
          </>
        )}
        <div
          style={{
            margin: 0,
            marginTop: 15,
            width: '100%',
            border: 0,
            display: 'inline-flex',
            padding: 0,
            position: 'relative',
            minWidth: 0,
            flexDirection: 'column',
          }}
        >
          <AmbInputLabel htmlFor="comments" style={labelProps.style} shrink required={this.props.isDispatcher}>
            Comments
          </AmbInputLabel>
          <TextArea
            name="comments"
            id="comments"
            fullWidth
            onChange={this.handleInputChange}
            required={this.props.isDispatcher}
          />
        </div>
      </ConfirmationDialog>
    );
  }
}

export default TurndownDialogFrame;
