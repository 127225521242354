import React from 'react';
import PropTypes from 'prop-types';
import withToolTip from '../../common/withTooltip';
import AmbGrid from '../AmbGrid';
import AmbRadioInput from '../AmbRadioInput';
import { theme } from '../../../theme';

const AmbRadio = ({
  classes,
  legend,
  onChange,
  yesDisabled,
  noDisabled,
  name,
  yesTooltipTitle,
  noTooltipTitle,
  error,
  value,
  noValue,
  yesValue,
  required,
}) => {
  const YesRadio = withToolTip(name, yesTooltipTitle)(AmbRadioInput);
  const NoRadio = withToolTip(name, noTooltipTitle)(AmbRadioInput);

  const defaultLegendStyles = {
    fontSize: 12,
    minWidth: 240,
  };

  const errorLegendStyles = {
    color: theme.palette.primary.red,
  };

  const legendStyles = error ? { ...defaultLegendStyles, ...errorLegendStyles } : defaultLegendStyles;

  return (
    <>
      <legend style={legendStyles}>
        {legend}
        {required ? '*' : null}
      </legend>
      <div role="radiogroup" aria-label={name} name={name} style={{ display: 'flex', height: 40, marginTop: 4 }}>
        <label style={{ marginRight: 10 }}>
          <AmbGrid>
            <YesRadio
              disabled={yesDisabled || null}
              classes={classes}
              onChange={onChange}
              name={name}
              checked={value === yesValue}
              value={yesValue}
              style={{ marginRight: 4, color: '#1EC0D9' }}
              inputPosition={{ left: -1 }}
            />
            Yes
          </AmbGrid>
        </label>

        <label>
          <AmbGrid>
            <NoRadio
              disabled={noDisabled || null}
              classes={classes}
              onChange={onChange}
              name={name}
              checked={value === noValue}
              value={noValue}
              style={{ marginRight: 4, color: '#1EC0D9' }}
              inputPosition={{ left: -1 }}
            />
            No
          </AmbGrid>
        </label>
      </div>
    </>
  );
};

AmbRadio.propTypes = {
  // classes: PropTypes.shape().isRequired,
  error: PropTypes.bool,
  legend: PropTypes.string,
  name: PropTypes.string.isRequired,
  noDisabled: PropTypes.bool,
  noTooltipTitle: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  yesDisabled: PropTypes.bool,
  yesTooltipTitle: PropTypes.string,
  yesValue: PropTypes.string,
  noValue: PropTypes.string,
  required: PropTypes.bool,
};

AmbRadio.defaultProps = {
  error: false,
  noDisabled: false,
  noTooltipTitle: '',
  yesDisabled: false,
  yesTooltipTitle: '',
  yesValue: 'true',
  noValue: 'false',
  required: true,
  legend: '',
};

export default AmbRadio;
