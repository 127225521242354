import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import AmbCircularProgress from '../../common/AmbCircularProgress';

function CheckEligibilityFrame(props) {
  let text = 'Check eligibility';

  if (props.isLoading) {
    text = 'Checking';
  }

  if (props.isCreating) {
    text = 'Creating report';
  }

  return (
    <div
      style={{
        position: 'absolute',
        right: '3px',
        zIndex: 2,
        height: '36px',
        marginTop: props.upper ? '-24px' : '-15px',
      }}
    >
      <Button
        color="primary"
        id="SaveButton"
        disabled={props.isLoading || props.disabled || props.isCreating}
        onClick={() => props.onClick()}
      >
        {props.isLoading || props.isCreating ? (
          <AmbCircularProgress
            propsStyles={{
              width: '15px',
              height: '15px',
              position: 'relative',
              top: '3px',
              marginRight: '4px',
            }}
          />
        ) : null}
        <span>{text}</span>
      </Button>
    </div>
  );
}

CheckEligibilityFrame.defaultProps = {
  upper: false,
};

CheckEligibilityFrame.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isCreating: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  upper: PropTypes.bool,
};

export default CheckEligibilityFrame;
