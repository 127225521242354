import { createReducer } from 'redux-act';
import * as action from '../actions/turndown.actions';

export const initialState = {
  open: false,
  comments: '',
  typeId: 1,
  turndown: false,
  turndownTypes: [],
  turndownInProgress: false,
};

const turndownReducer = createReducer(
  {
    [action.setTurndownInfo]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setTurndownDialogOpen]: (state, payload) => ({
      ...state,
      open: payload,
    }),
    [action.turndownETA]: state => ({
      ...state,
      open: false,
      turndown: true, // todo: derive this from request.status
    }),
    [action.setTurndownTypes]: (state, payload) => {
      const defaultTurndownType = payload.find(turndownType => turndownType.name === 'Auto ETA Rejected');

      return {
        ...state,
        typeId: defaultTurndownType ? defaultTurndownType.id : initialState.typeId,
        turndownTypes: payload,
      };
    },
    [action.setTurndownInProgress]: (state, payload) => ({
      ...state,
      turndownInProgress: payload,
    }),
  },
  initialState,
);

export default turndownReducer;
