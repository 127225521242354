import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import ErrorOutline from '../common/icons/errorOutline';
import ConfirmationDialog from '../common/ConfirmationDialog/index';
import { theme } from 'theme';

export class SimilarRequestFoundDialogFrame extends React.Component {
  handleButtonClick = value => {
    if (!value) {
      this.props.asyncCancelSimilarRequest();
      this.props.setSimilarRequestFoundDialogOpen(false);
    } else {
      this.props.setSimilarRequestFoundDialog(null);
    }
  };

  handleRequestIdClick = requestId => {
    if (!window.mapDispatcherRef || window.mapDispatcherRef.closed) {
      window.mapDispatcherRef = window.open(`/dispatcher2/?requestId=${requestId}`, '_ambulnz_dispatcher');
    } else {
      window.mapDispatcherRef.focus();
    }
  };

  render() {
    const { targetRequest, similarRequest, similarRequestDialogOpen } = this.props;

    const getPatientName = request =>
      request.patient &&
      request.patient.demographics &&
      request.patient.demographics &&
      request.patient.demographics.length
        ? [similarRequest.patient.demographics[0].firstName, similarRequest.patient.demographics[0].lastName]
        : ['', ''];

    const getPlaceFacility = (request, placeType) =>
      request[placeType].facilityLocation && request[placeType].facilityLocation.emsFacility
        ? request[placeType].facilityLocation.emsFacility.entity.name
        : '';

    const getPlaceAddress = (request, placeType) => {
      if (!request[placeType].address) {
        return '';
      }
      return [request[placeType].address.street1, request[placeType].address.city, request[placeType].address.state]
        .filter(e => e)
        .join(', ');
    };

    const getPlaceAddressStyle = (request, placeType) =>
      getPlaceFacility(request, placeType) !== '' ? { fontSize: 12, color: theme.palette.grey.dark } : { fontSize: 13 };

    const getRequesterAgencyName = request =>
      request.requesterAgency && request.requesterAgency.entity ? request.requesterAgency.entity.name : '';

    const getPromisedEta = request =>
      request.schedule ? moment(request.schedule.promised_eta).format('HH:mm MM/DD/YY') : ' - ';

    return (
      <ConfirmationDialog
        open={similarRequestDialogOpen}
        title={
          <div style={{ textAlign: 'left', display: 'flex', fontSize: 17, color: theme.palette.primary.alertText }}>
            <ErrorOutline style={{ color: 'error' }} />
            <div style={{ marginLeft: 5 }}>Warning!</div>
          </div>
        }
        onClose={this.handleButtonClick}
        cancel
        fullWidth
        maxWidth="360px"
        cancelText={`Cancel #${targetRequest.id}`}
        okText={'Continue'}
      >
        <div>
          {similarRequest && (
            <AmbGrid style={{ flexWrap: 'wrap' }}>
              <AmbGrid style={{ width: '100%' }}>
                <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                  Another trip
                  <span
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: theme.palette.secondary.main,
                      margin: '0 3px',
                    }}
                    onClick={() => this.handleRequestIdClick(similarRequest.id)}
                  >
                    #{similarRequest.id}
                  </span>
                  for this date and patient has been found
                </div>
              </AmbGrid>
              <AmbGrid style={{ width: '100%' }}>
                <div style={{ fontSize: 13, marginTop: 10, color: theme.palette.text.disabled }}>Requesting Agency</div>
              </AmbGrid>
              <AmbGrid style={{ width: '100%' }}>
                <div style={{ fontSize: 13 }}>{getRequesterAgencyName(similarRequest)}</div>
              </AmbGrid>
              <AmbGrid style={{ width: '100%' }}>
                <div style={{ fontSize: 13, marginTop: 10, color: theme.palette.text.disabled }}>Pickup Location</div>
              </AmbGrid>
              <AmbGrid style={{ width: '100%' }}>
                <div style={{ fontSize: 13 }}>{getPlaceFacility(similarRequest, 'pickupPlace')}</div>
                <div style={getPlaceAddressStyle(similarRequest, 'pickupPlace')}>
                  {getPlaceAddress(similarRequest, 'pickupPlace')}
                </div>
              </AmbGrid>
              <AmbGrid style={{ width: '100%' }}>
                <div style={{ fontSize: 13, marginTop: 10, color: theme.palette.text.disabled }}>Destination</div>
              </AmbGrid>
              <AmbGrid style={{ width: '100%' }}>
                <div style={{ fontSize: 13 }}>{getPlaceFacility(similarRequest, 'destinationPlace')}</div>
                <div style={getPlaceAddressStyle(similarRequest, 'destinationPlace')}>
                  {getPlaceAddress(similarRequest, 'destinationPlace')}
                </div>
              </AmbGrid>
              <AmbGrid style={{ width: '50%', flexDirection: 'column' }}>
                <div style={{ fontSize: 13, marginTop: 10, color: theme.palette.text.disabled }}>Requested Pickup</div>
                <div style={{ fontSize: 13 }}>{moment(similarRequest.activeDate).format('HH:mm MM/DD/YY')}</div>
              </AmbGrid>
              <AmbGrid style={{ width: '50%', flexDirection: 'column' }}>
                <div style={{ fontSize: 13, marginTop: 10, color: theme.palette.text.disabled }}>Promised ETA</div>
                <div style={{ fontSize: 13 }}>{getPromisedEta(similarRequest)}</div>
              </AmbGrid>
              <AmbGrid style={{ width: '50%', flexDirection: 'column' }}>
                <div style={{ fontSize: 13, marginTop: 10, color: theme.palette.text.disabled }}>
                  Patient First Name
                </div>
                <div style={{ fontSize: 13 }}>{getPatientName(similarRequest)[0]}</div>
              </AmbGrid>
              <AmbGrid style={{ width: '50%', flexDirection: 'column' }}>
                <div style={{ fontSize: 13, marginTop: 10, color: theme.palette.text.disabled }}>Patient Last Name</div>
                <div style={{ fontSize: 13 }}>{getPatientName(similarRequest)[1]}</div>
              </AmbGrid>
            </AmbGrid>
          )}
        </div>
      </ConfirmationDialog>
    );
  }
}

SimilarRequestFoundDialogFrame.propTypes = {
  targetRequest: PropTypes.shape(),
  similarRequest: PropTypes.shape(),
  similarRequestDialogOpen: PropTypes.bool.isRequired,
  asyncCancelSimilarRequest: PropTypes.func.isRequired,
  setSimilarRequestFoundDialog: PropTypes.func.isRequired,
  setSimilarRequestFoundDialogOpen: PropTypes.func.isRequired,
};

export default SimilarRequestFoundDialogFrame;
