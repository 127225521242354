import { createAction } from '@reduxjs/toolkit';

export const autoSave = createAction('[FormFlow] autoSave');
export const autoSaveInProgress = createAction('[FormFlow] autoSaveInProgress');
export const changeDialogContent = createAction('[FormFlow] changeDialogContent');
export const closeNewETADialog = createAction('[FormFlow] closeNewETADialog');
export const keyDownETAField = createAction('[FormFlow] keyDownETAField');
export const loadRequestDetails = createAction('[FormFlow] loadRequestDetails');
export const openNewETADialog = createAction('[FormFlow] openNewETADialog');
export const rejectETASuccess = createAction('[FormFlow] rejectETASuccess');
export const setAcceptETAInProgress = createAction('[FormFlow] setAcceptETAInProgress');
export const setChangeRequiresNewETA = createAction('[FormFlow] setChangeRequiresNewETA');
export const setCurrentView = createAction('[FormFlow] setCurrentView');
export const setDirty = createAction('[FormFlow] setDirty');
export const setETASuccess = createAction('[FormFlow] setETASuccess');
export const setETAwasAccepted = createAction('[FormFlow] setETAwasAccepted');
export const setLoadRequestInProgress = createAction('[FormFlow] setLoadRequestInProgress');
export const setLoadedRequest = createAction('[FormFlow] setLoadedRequest');
export const setMaxDate = createAction('[FormFlow] setMaxDate');
export const setNoAutoEtaRequest = createAction('[FormFlow] setNoAutoEtaRequest');
export const setSaveClicked = createAction('[FormFlow] setSaveClicked');
export const setSaveMessage = createAction('[FormFlow] setSaveMessage');
export const setScheduledMessage = createAction('[FormFlow] setScheduledMessage');
export const setSubmitInProgress = createAction('[FormFlow] setSubmitInProgress');
export const setTriggerValidation = createAction('[FormFlow] triggerValidation');
export const setValid = createAction('[FormFlow] setValid');
export const toggleInProgressState = createAction('[FormFlow] toggleInProgressState');
export const setTriggerPatientValidation = createAction('[FormFlow] setTriggerPatientValidation');
export const setSimilarRequestFoundDialog = createAction('[FormFlow] setSimilarRequestFoundDialog');
export const setSimilarRequestFoundDialogOpen = createAction('[FormFlow] setSimilarRequestFoundDialogOpen');

export const clearEtaFromEtaTypeToggle = createAction('[FormFlow] clearEtaFromEtaTypeToggle');

export const openZollDialog = createAction('[FormFlow] openZollDialog');
export const closeZollDialog = createAction('[FormFlow] closeZollDialog');
