import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../../common/AmbGrid';
import styled from '@emotion/styled';
import SsnInput from './SsnInput';
import MrnInput from './MrnInput';
import PatientAdditionalInfo from './PatientAdditionalInfoWidget';
import PhoneInput from '../../common/PhoneInput';
import PatientSearchContainer from '../../PatientSearch';
import PatientBirthday from './PatientBirthday';
import TextWithLoader from '../../common/TextWithLoader';
import AmbTooltip from '../../common/AmbTooltip/AmbTooltip';
import Info from '../../common/icons/Info';
import AmbTextField from 'components/common/AmbTextField';
import { theme } from 'theme';

const PatientDetailsFrame = props => {
  const {
    disabled,
    displayPatientLookupTooltip,
    handleAcceptBirthday,
    handleInputBlur,
    handleInputChange,
    handleMrnBlur,
    handlePhoneChange,
    handleSearchPatient,
    handleSsnChange,
    mrnDisabled,
    patientPhoneDisabled,
    searchInProgress,
    showSSN,
  } = props;

  const inputProps = {
    style: { ...theme.mixins.input },
  };
  const labelProps = {
    style: { ...theme.mixins.inputLabel },
    shrink: true,
  };
  const customLabelProps = {
    style: { fontSize: 11, color: theme.palette.grey.grey80 },
    shrink: false,
  };
  const phoneLabelProps = {
    style: { fontSize: 15, marginTop: -11, color: theme.palette.grey.grey80 },
    shrink: true,
  };

  const textFieldProps = {
    onChange: handleInputChange,
    disabled,
    InputLabelProps: labelProps,
    InputProps: inputProps,
    fullWidth: true,
  };

  const Divider = styled.hr`
    margin: 15px -10px 15px -10px;
    opacity: 0.3;
  `;

  const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 25px;
    margin-bottom: 10px;
  `;

  const Tooltip = styled.div`
    margin-left: 2px;
    margin-top: -10px;
  `;

  const Searching = styled.div`
    margin-left: 10px;
  `;

  return (
    <>
      <PatientSearchContainer />
      <Container>
        <div>
          <h4>Patient</h4>
        </div>
        {displayPatientLookupTooltip && (
          <Tooltip>
            <AmbTooltip
              id="patient-lookup-tooltip"
              title="This Requesting Agency provides an automatic patient lookup integration, but you are not configured to use it."
              placement="right"
            >
              <span>
                <Info style={{ height: 14, width: 14 }} fill="#00000000" />
              </span>
            </AmbTooltip>
          </Tooltip>
        )}
        {searchInProgress && (
          <Searching>
            <TextWithLoader text="Searching for patients..." />
          </Searching>
        )}
      </Container>
      <AmbGrid style={{ gap: '8px', flexDirection: 'column' }}>
        <AmbGrid style={{ gap: '8px' }}>
          <AmbGrid style={{ flexDirection: 'column', flexBasis: '40%' }}>
            <MrnInput
              inputLabelProps={customLabelProps}
              value={props.patientMRN}
              disabled={mrnDisabled || null}
              onChange={handleInputChange}
              onBlur={handleMrnBlur}
            />
          </AmbGrid>
          <AmbGrid style={{ flexDirection: 'column', flexBasis: '40%' }}>
            {showSSN && (
              <SsnInput
                inputLabelProps={customLabelProps}
                value={props.patientSsn}
                onChange={handleSsnChange}
                error={!!props.errors.patientSsn}
                onBlur={handleSearchPatient}
              />
            )}
          </AmbGrid>
        </AmbGrid>
        <AmbGrid style={{ gap: '6px' }}>
          <AmbGrid style={{ flexBasis: '40%' }}>
            <AmbTextField
              name="patientFirstName"
              label="First Name"
              id="PatientFirstNameInput"
              value={props.patientFirstName}
              error={!!props.errors.patientFirstName}
              onBlur={handleSearchPatient}
              {...(props.agencyRequiresPatientInfo ? { required: true } : {})}
              {...textFieldProps}
              wrapperStyle={{ marginTop: 16, marginBottom: 8 }}
            />
          </AmbGrid>
          <AmbGrid style={{ flexBasis: '20%' }}>
            <AmbTextField
              id="patientMiddleName"
              name="patientMiddleName"
              value={props.patientMiddleName}
              label="MI"
              onBlur={handleInputBlur}
              {...textFieldProps}
              wrapperStyle={{ marginTop: 16, marginBottom: 8 }}
            />
          </AmbGrid>
          <AmbGrid style={{ flexBasis: '40%' }}>
            <AmbTextField
              name="patientLastName"
              label="Last Name"
              id="PatientLastNameInput"
              value={props.patientLastName}
              error={!!props.errors.patientLastName}
              onBlur={handleSearchPatient}
              {...(props.agencyRequiresPatientInfo ? { required: true } : {})}
              {...textFieldProps}
              wrapperStyle={{ marginTop: 16, marginBottom: 8 }}
            />
          </AmbGrid>
        </AmbGrid>
      </AmbGrid>
      <AmbGrid style={{ gap: '8px' }}>
        <AmbGrid style={{ flexBasis: '70%', maxWidth: 260 }}>
          <AmbTextField
            name="patientEmail"
            label="Email"
            id="PatientEmailInput"
            value={props.patientEmail || ''}
            error={!!props.errors.patientEmail}
            onBlur={handleSearchPatient}
            {...textFieldProps}
          />
        </AmbGrid>
      </AmbGrid>
      <AmbGrid style={{ gap: '8px' }}>
        <AmbGrid style={{ flexBasis: '48%' }}>
          <PatientBirthday
            disabled={disabled || null}
            error={!!props.errors.patientBirthday}
            handleBlur={handleSearchPatient}
            handleAccept={handleAcceptBirthday}
            {...(props.agencyRequiresPatientInfo ? { required: true } : {})}
          />
        </AmbGrid>
        <AmbGrid style={{ flex: '1 0 48%' }}>
          <PhoneInput
            value={props.phone}
            disabled={patientPhoneDisabled}
            onChange={handlePhoneChange}
            inputLabelProps={phoneLabelProps}
            error={!!props.errors.phone}
            {...(props.isNonTransport ? { required: true } : {})}
            onBlur={handleInputBlur}
            label="Patient Phone Number"
            id="PatientPhoneInput"
            style={{ marginTop: '-5px' }}
            wrapperStyle={{ marginTop: 12 }}
          />
        </AmbGrid>
      </AmbGrid>
      <Divider />
      {props.patientAdditionalDetailsDisplay && (
        <PatientAdditionalInfo handleError={props.handleError('additionalInfoError')} {...props} />
      )}
    </>
  );
};

PatientDetailsFrame.propTypes = {
  autoSavePatientDetails: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.shape().isRequired,
  facesheetAttached: PropTypes.bool.isRequired,
  handleError: PropTypes.func.isRequired,
  handleInputBlur: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleMrnBlur: PropTypes.func.isRequired,
  handlePhoneChange: PropTypes.func.isRequired,
  handleSearchPatient: PropTypes.func.isRequired,
  handleSsnChange: PropTypes.func.isRequired,
  mrnDisabled: PropTypes.bool.isRequired,
  patientAdditionalDetailsDisplay: PropTypes.bool.isRequired,
  patientBirthday: PropTypes.string,
  patientFirstName: PropTypes.string.isRequired,
  patientLastName: PropTypes.string.isRequired,
  patientEmail: PropTypes.string,
  patientMRN: PropTypes.string,
  patientMiddleName: PropTypes.string.isRequired,
  patientPhoneDisabled: PropTypes.bool.isRequired,
  patientSsn: PropTypes.string,
  phone: PropTypes.string.isRequired,
  searchInProgress: PropTypes.bool.isRequired,
  searchPatient: PropTypes.func.isRequired,
  setPatientDetails: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

PatientDetailsFrame.defaultProps = {
  patientMRN: '',
  patientSsn: '',
  patientBirthday: '',
};

export default PatientDetailsFrame;
