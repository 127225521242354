import React from 'react';
import moment from 'moment';
import { timeFormatWithTimezone } from '../../helpers/dateFormatter';

const GetETAContextContainer = ({ offeredEtaPayload, ...props }) =>
  React.cloneElement(props.children, {
    ...props,
    ...offeredEtaPayload,
    etaId: offeredEtaPayload.id,
    dropoffTime: timeFormatWithTimezone(
      moment(offeredEtaPayload.atDropoffTime).add(
        window.ambulnzConfig.constants.APPOINTMENT_DEFAULT_TRANSFER_OF_CARE_SECS,
        'seconds',
      ),
    ),
    etaDate: moment(props.requestedArrivalTime).format('MM/DD'),
    appointmentTime: timeFormatWithTimezone(props.requestedAppointmentTime),
    appointmentDate: moment(props.requestedAppointmentTime).format('MM/DD'),
    requestedTime: timeFormatWithTimezone(props.requestedAppointmentTime || props.requestedArrivalTime),
    conflict: offeredEtaPayload.conflicts[0],
    warning: !!offeredEtaPayload.conflicts[0],
  });

GetETAContextContainer.propTypes = {};

export default GetETAContextContainer;
