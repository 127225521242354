class WindowService {
  constructor() {
    this.windowMap = new Map();
  }

  storeCurrentWindow(url, currentWindow = window) {
    currentWindow.name = url;

    this.windowMap.set(currentWindow, currentWindow);
  }

  getActiveWindow(url) {
    const currentWindow = this.windowMap.get(url);

    if (!currentWindow || currentWindow.closed) return null;

    return currentWindow;
  }

  openWindow(url) {
    const newWindow = window.open(url, url);

    this.windowMap.set(url, newWindow);

    return newWindow;
  }

  focusWindow(url) {
    const openedWindow = this.windowMap.get(url);

    openedWindow.focus();

    return openedWindow;
  }

  windowOpen(url) {
    const activeWindow = this.getActiveWindow(url);

    if (!activeWindow) return this.openWindow(url);

    return this.focusWindow(url);
  }
}

export default new WindowService();
