import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import HighlightOff from '../icons/highlightOff';

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 36,
});

const MenuCancelButton = props => {
  return (
    <MenuItem onClick={() => props.handleClick(true)} disabled={props.disabled || null} aria-disabled={props.disabled}>
      <StyledListItemIcon>
        <HighlightOff />
      </StyledListItemIcon>
      <ListItemText primary="Cancel" />
    </MenuItem>
  );
};

MenuCancelButton.propTypes = {};

MenuCancelButton.defaultProps = {};

export default MenuCancelButton;
