import React from 'react';

export default function({ fill = '#004053' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 16 16"
      version="1.1"
      viewBox="0 0 16 16"
      xmlSpace="preserve"
      height="16px"
    >
      <switch>
        <g>
          <g fill={fill}>
            <circle cx="8" cy="5" r="1" />
            <path d="M8 7c-.4 0-.8.3-.8.8v3.5c0 .4.4.7.8.7s.8-.3.8-.8V7.8c0-.5-.4-.8-.8-.8z" />
          </g>
          <path
            fill={fill}
            d="M8 1c3.9 0 7 3.1 7 7s-3.1 7-7 7-7-3.1-7-7 3.1-7 7-7m0-1C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"
          />
        </g>
      </switch>
    </svg>
  );
}
