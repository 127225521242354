import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConfirmationDialog from './ConfirmationDialog';
import * as errorActions from '../../redux/actions/error.actions';
import * as etaActions from '../../redux/actions/eta.actions';

class ErrorDialog extends React.Component {
  handleClose = value => {
    if (value) {
      this.props.clearError();
      switch (this.props.title) {
        case 'Scheduling Temporarily Unavailable':
          this.props.rejectETA({
            pollInProgressReason: 'Scheduling Temporarily Unavailable',
          });
          this.props.requestETAinProgress(false);
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { title, open, body, errorHarJson } = this.props;
    return (
      <ConfirmationDialog harJson={errorHarJson} open={open} onClose={this.handleClose} title={title}>
        <div>{body}</div>
      </ConfirmationDialog>
    );
  }
}

ErrorDialog.propTypes = {
  clearError: PropTypes.func.isRequired,
  rejectETA: PropTypes.func.isRequired,
  requestETAinProgress: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

function getTitle(str, title) {
  let newTitle;
  switch (true) {
    case /not available/i.test(str):
      newTitle = 'Scheduling Temporarily Unavailable';
      break;
    default:
      newTitle = title;
      break;
  }

  return newTitle.trim();
}

function mapStateToProps(state) {
  const { message, title, open } = state.error.errorMessage;
  const body = state.error.errorMessage.body || message;
  return {
    body,
    open,
    errorHarJson: state.error.errorHarJson,
    request: state.requests.request,
    title: getTitle(body && body.trim().toLowerCase(), title),
  };
}
export default connect(mapStateToProps, {
  ...errorActions,
  ...etaActions,
})(ErrorDialog);
