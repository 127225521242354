import React from 'react';
import AmbTextField from 'components/common/AmbTextField';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as locationActions from '../../redux/actions/location.actions';
import { theme } from '../../theme';

const LABELS = {
  roomNumber: 'Room',
  puRoomNumber: 'Pickup Room #',
  doRoomNumber: 'Drop Off Room #',
};

const RoomNumber = ({ name, inputId, handleBlur, handleChange, ...props }) => (
  <AmbTextField
    InputLabelProps={{
      style: { ...theme.mixins.inputLabel },
      shrink: true,
    }}
    name={name}
    id={inputId}
    value={props[name] || ''}
    label={LABELS[name]}
    onChange={e => handleChange({ [name]: e.target.value })}
    onBlur={handleBlur}
  />
);

RoomNumber.propTypes = {
  name: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    puRoomNumber: state.location.puRoomNumber,
    doRoomNumber: state.location.doRoomNumber,
  };
}

export default connect(mapStateToProps, {
  handleChange: locationActions.setRoomNumber,
  handleBlur: locationActions.asyncAutoSaveRoomNumber,
})(RoomNumber);
