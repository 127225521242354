import { map, switchMap, filter } from 'rxjs/operators';

import * as chiefComplaintActions from '../actions/chiefComplaint.actions';
import * as requestsActions from '../actions/requests.actions';
import * as errorActions from '../actions/error.actions';
import { getRequestId } from './updateRequestUtil';
import { updateHelper } from './helpers/chiefComplain.helper';

// eslint-disable-next-line import/prefer-default-export
export const updateComplaintEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [chiefComplaintActions.saveChiefComplaint.match, requestsActions.createRequestSuccess.match].some(match =>
        match(action),
      ),
    ),
    filter(() => getRequestId(store)),
    filter(() => store.value.requests.chiefComplaintName),
    switchMap(() => updateHelper(store)),
    map(response => errorActions.onError(response)),
  );
