import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../../common/AmbGrid';
import { SectionHeader } from '../elements';
import AmbGroupToggle from '../../common/AmbGroupToggle';
import AmbBtnWithTooltip from '../../common/AmbBtnWithTooltip';
import NICU from '../../common/icons/NICU';
import PEDS from '../../common/icons/PEDS';
import Adult from '../../common/icons/adult';
import WeightHeight from './WeightHeight';
import { theme } from 'theme';

export default function Patient({
  errors,
  patientTypeList,
  patientTypeId,
  handlePatientTypeChange,
  disabledPatientTypeIds,
  svgStyles,
  weightHeightRequired,
  handleWeightChange,
  handleWeightBlur,
  handleHeightChange,
  patientWeight,
  patientHeight,
  patientHeightInches,
}) {
  const patientType = patientTypeList.find(singlePatientType => singlePatientType.id === patientTypeId);
  const setOpacity = patientType
    ? patientTypeDisplayName => (patientType.displayName === patientTypeDisplayName ? 1 : 0.8)
    : 0.8;
  const componentMap = { NICU, PEDS, Adult };
  const labelProps = {
    style: { ...theme.mixins.inputLabel },
    shrink: true,
  };
  const inputProps = {
    style: { marginTop: 0 },
  };

  return (
    <>
      <AmbGrid style={{ flexDirection: 'column', margin: '10px 0px' }}>
        <AmbGrid>
          <SectionHeader>Patient</SectionHeader>
        </AmbGrid>
        {/* {'TODO: should fix key as String to Number?'} */}
        <AmbGroupToggle value={patientTypeId} onChange={({ key }) => handlePatientTypeChange(Number(key))}>
          {patientTypeList.map(singlePatientType => {
            const DynamicComponent = componentMap[singlePatientType.displayName];
            return (
              <AmbBtnWithTooltip
                key={singlePatientType.id}
                selectedkey={singlePatientType.id.toString()}
                disabled={disabledPatientTypeIds.includes(singlePatientType.id) || null}
                title=""
                aria-labelledby="patient-type-button"
              >
                <DynamicComponent
                  style={{ ...svgStyles, marginRight: 4, opacity: setOpacity(singlePatientType.displayName) }}
                />
                {singlePatientType.displayName}
              </AmbBtnWithTooltip>
            );
          })}
        </AmbGroupToggle>
        <WeightHeight
          required={weightHeightRequired}
          labelProps={labelProps}
          inputProps={inputProps}
          patientWeight={patientWeight}
          patientWeightError={errors.patientWeight}
          patientHeight={patientHeight}
          patientHeightError={errors.patientHeight}
          patientHeightInches={patientHeightInches}
          handleWeightChange={handleWeightChange}
          handleHeightChange={handleHeightChange}
          handleWeightBlur={handleWeightBlur}
        />
      </AmbGrid>
    </>
  );
}

Patient.propTypes = {
  patientTypeList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  patientTypeId: PropTypes.number.isRequired,
  handlePatientTypeChange: PropTypes.func.isRequired,
  disabledPatientTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  svgStyles: PropTypes.shape().isRequired,
};
