import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 51.1 24" {...props}>
      <title>ALS</title>
      <path
        style={{ fill: '#1ec2d8' }}
        d="M2.08,0H36.9a3.41,3.41,0,0,1,2.25,1.21A3.65,3.65,0,0,1,40,3.47.48.48,0,0,1,39.5,4H38.11l6.5,8s2.77.87,4.07,1.13a1.66,1.66,0,0,1,1.39,1.38l.78,4H45.13a3.86,3.86,0,0,0-2.68-1.56A4,4,0,0,0,39.07,19H10.34S8.92,16.9,7.1,16.9A3.65,3.65,0,0,0,3.86,19H.43V1.48A1.43,1.43,0,0,1,2.08,0Z"
        transform="translate(0 0)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M22.61.78a5.69,5.69,0,0,0-5.56,5.09,5.88,5.88,0,0,1,3.11,1.42v-1a2.38,2.38,0,0,1,.76-1.7,2.73,2.73,0,0,1,1.69-.66,2.48,2.48,0,0,1,2.45,2.64v8.58H22V12.28a5.8,5.8,0,0,0-5.84-5.75,5.87,5.87,0,0,0-5.84,5.84A5.79,5.79,0,0,0,16,18.22h2.07V15.11h-2a2.74,2.74,0,1,1,2.73-2.83v5.94h9.43V6.53A5.64,5.64,0,0,0,22.61.78"
          transform="translate(0 0)"
        />
      </g>
      <path
        style={{ fill: '#050505' }}
        d="M1,19H39.07l-.35,1.47H.61A.59.59,0,0,1,0,19.84v-.26A.59.59,0,0,1,.61,19H1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#71ccdd' }}
        d="M39.59,20.45v1.38H3.72A2.93,2.93,0,0,1,1,20.45Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#050505' }} cx="7.1" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="7.1" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="7.1" cy="20.45" r="1.65" />
      <path
        style={{ fill: '#2b242d' }}
        d="M45.13,22.09h4.68c1.21,0,1.29-2.14,1.29-2.34s-.25-1.29-.25-1.29H45.13Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#5f5f61' }} cx="7.1" cy="20.45" r="1.21" />
      <circle style={{ fill: '#050505' }} cx="42.27" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="42.27" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="42.27" cy="20.45" r="1.65" />
      <circle style={{ fill: '#5f5f61' }} cx="42.27" cy="20.45" r="1.21" />
      <path
        style={{ fill: '#ebebed' }}
        d="M35.78,13.35h0a.23.23,0,0,0-.26-.26h-.87a.23.23,0,0,0-.26.26h0a.23.23,0,0,0,.26.26h.87C35.69,13.61,35.86,13.52,35.78,13.35Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M36.9,4.51a1.89,1.89,0,0,1,1.56.61l4.85,6.23L33.61,10V4.51Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ededef' }}
        d="M48,12.92l.82,1.75,1.4.39-.11-.59a1.66,1.66,0,0,0-1.39-1.38Z"
        transform="translate(0 0)"
      />
      <rect style={{ fill: '#ededef' }} x="45.3" y="16.12" width="1.56" height="1.21" />
      <path
        d="M7.43,14.73l4.36-9.42A.24.24,0,0,1,12,5.15h.14a.24.24,0,0,1,.24.16l4.32,9.42a.25.25,0,0,1-.24.37H15.26a.42.42,0,0,1-.41-.28l-.68-1.52H10l-.69,1.52a.41.41,0,0,1-.4.28H7.67A.25.25,0,0,1,7.43,14.73Zm6.05-3-1.4-3.08h0l-1.38,3.08Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M12.16,5.15a.24.24,0,0,1,.24.16l4.32,9.42a.25.25,0,0,1-.24.37H15.26a.42.42,0,0,1-.41-.28l-.68-1.52H10l-.69,1.52a.41.41,0,0,1-.4.28H7.67a.25.25,0,0,1-.24-.37l4.36-9.42A.24.24,0,0,1,12,5.15h.14m-1.5,6.6h2.82l-1.4-3.08h0l-1.38,3.08m1.5-7.1H12a.74.74,0,0,0-.68.43L7,14.52a.76.76,0,0,0,0,.73.75.75,0,0,0,.65.35H8.89a.93.93,0,0,0,.87-.6l.54-1.2h3.54L14.4,15a.89.89,0,0,0,.86.58h1.22a.75.75,0,0,0,.69-1.08L12.86,5.1a.76.76,0,0,0-.7-.45Zm-.73,6.6.63-1.41.64,1.41Z"
        transform="translate(0 0)"
      />
      <path
        d="M18,5.56a.27.27,0,0,1,.26-.27h1.29a.28.28,0,0,1,.27.27v7.86h3.57a.26.26,0,0,1,.26.26v1.15a.27.27,0,0,1-.26.27H18.25a.27.27,0,0,1-.26-.27Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M19.54,5.29a.28.28,0,0,1,.27.27v7.86h3.57a.26.26,0,0,1,.26.26v1.15a.27.27,0,0,1-.26.27H18.25a.27.27,0,0,1-.26-.27V5.56a.27.27,0,0,1,.26-.27h1.29m0-.5H18.25a.77.77,0,0,0-.76.77v9.27a.77.77,0,0,0,.76.77h5.13a.77.77,0,0,0,.76-.77V13.68a.76.76,0,0,0-.76-.76H20.31V5.56a.78.78,0,0,0-.77-.77Z"
        transform="translate(0 0)"
      />
      <path
        d="M24.48,13.72l.5-.86a.33.33,0,0,1,.48-.1,4.69,4.69,0,0,0,2.12.87,1.17,1.17,0,0,0,1.27-1.08c0-.72-.6-1.21-1.78-1.68s-2.63-1.38-2.63-3c0-1.25.92-2.69,3.15-2.69a4.92,4.92,0,0,1,2.8.94.35.35,0,0,1,.09.46l-.54.8a.37.37,0,0,1-.49.17,4.56,4.56,0,0,0-1.94-.77,1.12,1.12,0,0,0-1.24,1c0,.66.52,1.11,1.66,1.57,1.36.54,2.92,1.36,2.92,3.16a3,3,0,0,1-3.22,2.78,4.49,4.49,0,0,1-3.08-1.1C24.42,14,24.35,14,24.48,13.72Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M27.59,5.15a4.92,4.92,0,0,1,2.8.94.35.35,0,0,1,.09.46l-.54.8a.44.44,0,0,1-.34.21.28.28,0,0,1-.15,0,4.56,4.56,0,0,0-1.94-.77,1.12,1.12,0,0,0-1.24,1c0,.66.52,1.11,1.66,1.57,1.36.54,2.92,1.36,2.92,3.16a3,3,0,0,1-3.22,2.78,4.49,4.49,0,0,1-3.08-1.1c-.13-.12-.2-.19-.07-.42l.5-.86a.32.32,0,0,1,.48-.1,4.69,4.69,0,0,0,2.12.87,1.17,1.17,0,0,0,1.27-1.08c0-.72-.6-1.21-1.78-1.68s-2.63-1.38-2.63-3c0-1.25.92-2.69,3.15-2.69m0-.5c-2.51,0-3.65,1.66-3.65,3.19,0,2.3,2.22,3.2,2.94,3.49,1.3.52,1.47.91,1.47,1.22s-.33.58-.77.58a3.77,3.77,0,0,1-1.8-.76l0,0a.82.82,0,0,0-1.2.26l-.5.86a.76.76,0,0,0,.15,1,4.93,4.93,0,0,0,3.43,1.24,3.43,3.43,0,0,0,3.72-3.28c0-2.32-2.27-3.24-3.24-3.63-1.18-.48-1.34-.82-1.34-1.1s.22-.48.74-.48a3.63,3.63,0,0,1,1.65.68.8.8,0,0,0,.44.13.93.93,0,0,0,.76-.43l.53-.8A.81.81,0,0,0,31,6.18a.84.84,0,0,0-.33-.5,5.49,5.49,0,0,0-3.09-1Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
