import { createReducer } from 'redux-act';
import initialState from './initialState';
import { reducer as openPCSModal } from './openPCSModal';
import { reducer as submitForm } from './submitPCSForm';

const reducer = createReducer(
  {
    ...openPCSModal,
    ...submitForm,
  },
  initialState,
);

export default reducer;
