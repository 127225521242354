import { createAction } from '@reduxjs/toolkit';

export const openCancelRequestModal = createAction('[CancelRequest] openCancelRequestModal');

export const reducer = {
  [openCancelRequestModal]: (state, payload) => ({
    ...state,
    open: payload,
  }),
};
