import { scheduleUpdated } from '../actions/socket.actions';
import * as mapActions from '../actions/map.actions';
import * as requestsActions from '../actions/requests.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as etaActions from '../actions/eta.actions';
import * as willCallActions from '../actions/willCall.actions';
import { RelayCommands, RequestStatus } from '../../enums';

function requestLocationUpdateApplies(action, state) {
  return (
    action.payload.event_type === RelayCommands.REQUEST_LOCATION_UPDATED &&
    action.payload.resource_id === state.requests.request.id
  );
}

function scheduleStateUpdateApplies(action, state) {
  return (
    action.payload.meta &&
    action.payload.event_type === RelayCommands.SCHEDULE_UPDATED &&
    action.payload.meta.request_id === state.requests.request.id
  );
}

function requestStatusUpdateApplies(action, state) {
  return (
    action.payload.event_type === RelayCommands.REQUEST_STATUS_UPDATE &&
    action.payload.resource_id === state.requests.request.id
  );
}
function scheduleUpdate(action, eventType, state) {
  return (
    !state.formFlow.acceptETAInProgress &&
    scheduleUpdated.match(action) &&
    action.payload.event_type === eventType &&
    (requestLocationUpdateApplies(action, state) ||
      requestStatusUpdateApplies(action, state) ||
      scheduleStateUpdateApplies(action, state))
  );
}

function handleUnreserve(status, state, dispatch) {
  const { NEEDS_ASSIGNMENT, NEEDS_DISPATCHER_ACTION } = RequestStatus;
  const needsAssignment = [NEEDS_ASSIGNMENT, NEEDS_DISPATCHER_ACTION].includes(status);
  if (needsAssignment && state.requests.request.isActive) {
    dispatch(etaActions.resetETA());
    dispatch(formFlowActions.setETASuccess(false));
    dispatch(formFlowActions.setETAwasAccepted(false));
  }
}

function handleReserve(status, state, dispatch) {
  if (status === RequestStatus.SCHEDULED && state.requests.request.needsAssignment) {
    // if unreserved from socket, eta.pickupTime is null
    const pickupTime = state.eta.pickupTime || state.schedule.startAt;
    dispatch(etaActions.reserve(pickupTime));
    dispatch(formFlowActions.setETASuccess(true));
  }
}

function handleWillCall(status, state, dispatch) {
  const { NEEDS_ASSIGNMENT, NEEDS_DISPATCHER_ACTION } = RequestStatus;
  const needsAssignment = [NEEDS_ASSIGNMENT, NEEDS_DISPATCHER_ACTION].includes(status);
  const { request } = state.requests;
  if (needsAssignment && request.status === RequestStatus.WILL_CALL) {
    dispatch(willCallActions.setWillCall(false));
  }
  if (status === RequestStatus.WILL_CALL && request.status !== RequestStatus.WILL_CALL) {
    dispatch(etaActions.resetETA());
    dispatch(formFlowActions.rejectETASuccess());
    dispatch(etaActions.clearPollInProgress());
    dispatch(formFlowActions.setETASuccess(false));
    dispatch(formFlowActions.setETAwasAccepted(false));
    dispatch(willCallActions.setLoadedWillCall(true));
  }
}

export const requestStatusUpdatedMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (scheduleUpdate(action, RelayCommands.REQUEST_STATUS_UPDATE, getState())) {
      const { status } = action.payload.meta;
      const state = getState();
      const { request } = state.requests;
      if (status !== request.status) {
        handleUnreserve(status, state, dispatch);
        handleReserve(status, state, dispatch);
        handleWillCall(status, state, dispatch);
      }
      dispatch(requestsActions.setStatus({ status }));
    }
  };

export const scheduleUpdatedMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (scheduleUpdate(action, RelayCommands.SCHEDULE_UPDATED, getState())) {
      dispatch(requestsActions.getSchedule());
    }
  };

export const vehicleLocationUpdateMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (scheduleUpdate(action, RelayCommands.REQUEST_LOCATION_UPDATED, getState())) {
      dispatch(mapActions.setVehicleLocation(action.payload.meta));
    }
  };

export const socketMdl = [requestStatusUpdatedMdl, scheduleUpdatedMdl, vehicleLocationUpdateMdl];
