import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ContactDispatcher from './ContactDispatcher.frame';
import * as etaActions from '../../../redux/actions/eta.actions';
import * as turndownActions from '../../../redux/actions/turndown.actions';
import { isCar } from '../../../flags/service.flag';

const ContactDispatcherContainer = props => <ContactDispatcher {...props} />;

function mapStateToProps(state) {
  return {
    user: state.user.model,
    request: state.requests.request,
    showEtaProgress: state.eta.showEtaProgress,
    rejectReason: state.eta.rejectReason,
    pollInProgressReason: state.eta.pollInProgressReason,
    turndownInProgress: state.turndown.turndownInProgress,
    requesterPhone: state.infobox.requesterPhone,
    requesterAgencyGroup: state.agency.requesterAgencyGroup,
    etaRejectedMsg: isCar(state.service) ? get(state.agency.requesterAgencyGroup, 'uberEtaRejectedMessage') : '',
  };
}
export default connect(mapStateToProps, {
  ...etaActions,
  ...turndownActions,
})(ContactDispatcherContainer);
