import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 505.7 94.2" {...props}>
      <g>
        <path
          style={{ fill: '#23C0DA' }}
          d="M100.3,25.2l0.6,8.5c4.3-4.7,10.4-10.3,19.2-10.3c8.2,0,16.7,1.7,21.8,10.3c4.3-4.9,14.2-10.3,23-10.3
		c12.7,0,26.3,4.4,26.3,31.8v36.7h-14.7V59.4c0-12.4-1.6-21.3-12.9-21.3c-9.6,0-14.9,6.7-17.5,11.6c0.1,1.7,0.2,3.4,0.2,5.4v36.7
		h-14.7V59.4c0-12.4-1.6-21.3-12.9-21.3c-9.6,0-14.9,6.7-17.5,11.6v42.1H86.5V25.2H100.3z"
        />
        <path
          style={{ fill: '#23C0DA' }}
          d="M200.7,91.9V0h14.5v33.4c4.5-6,12.4-10.2,20.8-10.2c19.4,0,34.7,15.3,34.7,35.2c0,20.3-15.3,35.2-34.7,35.2
		c-8.2,0-16.3-3.9-20.8-10l-0.7,8.3C214.5,91.9,200.7,91.9,200.7,91.9z M235.5,80c11.9,0,20.8-9.7,20.8-21.6s-8.9-21.8-20.8-21.8
		c-11.3,0-20.6,9.9-20.6,21.8S223.6,80,235.5,80z"
        />
        <path
          style={{ fill: '#23C0DA' }}
          d="M325.6,91.9l-0.6-8.4c-4.3,4.7-11.4,10.3-20.2,10.3c-12.7,0-27.1-4.2-27.1-31.8V25.2h14.7v32.5
		c0,12.4,2.3,21.3,13.6,21.3c9.6,0,15.9-6.7,18.5-11.6V25.2h14.7v66.7C339.2,91.9,325.6,91.9,325.6,91.9z"
        />
        <path style={{ fill: '#23C0DA' }} d="M349.4,91.9V0h14.5v91.9H349.4z" />
        <path
          style={{ fill: '#23C0DA' }}
          d="M387.5,25.2l0.6,8.5c4.3-4.7,11.4-10.3,20.2-10.3c12.7,0,27.1,4.2,27.1,31.8v36.7h-14.7V59.4
		c0-12.4-2.3-21.3-13.6-21.3c-9.6,0-15.9,6.7-18.5,11.6v42.1h-14.7V25.2H387.5z"
        />
        <path
          style={{ fill: '#23C0DA' }}
          d="M501.4,91.9h-60.7l37-52.4h-29.6V25.2h57.3l-36.5,52.1h32.5L501.4,91.9L501.4,91.9z"
        />
      </g>
      <g>
        <path
          style={{ fill: '#23C0DA' }}
          d="M54,15.3c-12.4,0-22.6,9.6-24.3,22.3c5.2,0.7,9.9,2.9,13.6,6.2c0-1.5,0.1-3.1,0.1-4.7c0.2-2.8,1.3-5.5,3.4-7.4
		c2-1.9,4.3-2.9,7-2.9c6.4,0.1,10.6,4.9,10.6,11.5V78h-13V65.8c0-14-11.6-25.4-25.7-25.4c-14,0-25.5,11.6-25.5,25.8S11.5,92,25.4,92
		h9V78h-9.1c-6.3,0-11.5-5.3-11.5-11.9c0-6.5,5.4-11.9,11.7-11.9c6.5,0,11.8,5.2,11.8,11.6V92h41V40.3C78.4,26.2,67.8,15.3,54,15.3z
		"
        />
      </g>
    </svg>
  );
}
