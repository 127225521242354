import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PatientBirthday from './frame';
import * as infoboxActions from '../../../../redux/actions/infobox.actions';
import * as patientActions from '../../../../redux/actions/patient.actions';
import { formatDateWithoutTimezone, formatDOB } from '../../../../helpers/dateFormatter';
import dayjs from 'dayjs';

function PatientDetailsContainer(props) {
  const handleChange = date => {
    const dayjsDate = dayjs(date);
    const minDate = dayjs().subtract(120, 'year');
    const maxDate = dayjs();
    dayjsDate && dayjsDate.isValid() && dayjsDate.isAfter(minDate) && dayjsDate.isBefore(maxDate)
      ? props.setBirthday(formatDOB(dayjsDate))
      : props.setBirthday(null);
  };

  return <PatientBirthday handleChange={handleChange} {...props} />;
}

function mapStateToProps(state) {
  return {
    patientBirthday: formatDateWithoutTimezone(state.infobox.patientBirthday),
    timezone: state.eta.timezone,
  };
}

export default connect(mapStateToProps, {
  ...infoboxActions,
  ...patientActions,
})(PatientDetailsContainer);

PatientDetailsContainer.propTypes = {
  setBirthday: PropTypes.func.isRequired,
};
