import { openMissingFieldsModal } from '../../../components/MissingFieldsModal/redux/actions';
import { setMissingFieldsBody } from '../../../components/MissingFieldsModal/redux/setMissingFieldsBody';
import { isCar } from '../../../flags/service.flag';
import * as etaActions from '../../actions/eta.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import { newETArequest, newETArequired } from '../../../messages';

export function acceptEta(getState, dispatch, etaId) {
  const { errors } = getState().missingFieldsModal;
  const hasPatientErrorsForUber = !!Object.keys(errors).filter(
    k =>
      (isCar(getState().service) || getState().requests.isNonTransport) &&
      (k.includes('patient') ||
        k.includes('address1') ||
        k.includes('city') ||
        k.includes('state') ||
        k.includes('zip') ||
        k.includes('phone')) &&
      errors[k] !== null,
  ).length;
  if (hasPatientErrorsForUber) {
    const missingFields = Object.keys(errors)
      .filter(
        k =>
          k.includes('patient') ||
          k.includes('address1') ||
          k.includes('city') ||
          k.includes('state') ||
          k.includes('zip') ||
          k.includes('phone'),
      )
      .reduce((obj, key) => ({ ...obj, [key]: errors[key] }), {});
    dispatch(setMissingFieldsBody(missingFields));
    dispatch(openMissingFieldsModal());
  } else {
    dispatch(formFlowActions.setAcceptETAInProgress(true));
    dispatch(etaActions.acceptETA(etaId));
  }
}

export const setMessage = request =>
  request.needsAssignment || request.requestedAppointmentTime ? newETArequired : newETArequest;

export default acceptEta;
