import { createAction } from '@reduxjs/toolkit';

export const asyncUnassignTripFromProviderRequest = createAction(
  '[UnassignRequest] asyncUnassignTripFromProviderRequest',
);
export const asyncUnassignTripFromProviderResponse = createAction(
  '[UnassignRequest] asyncUnassignTripFromProviderResponse',
);

export const unassignTripSuccessfullyDone = createAction('[UnassignRequest] asyncUnassignTripSuccessfullyDone');
export const unassignTripHasFailed = createAction('[UnassignRequest] asyncUnassignTripHasFailed');
