import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import AmbInputLabel from 'components/common/AmbInputLabel';

const StyledWrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 11px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: #004053;
  }
`;

const StyledInput = styled.input`
  font: inherit;
  font-size: 14px;
  color: currentColor;
  outline: none;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 2px 0;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
`;

const InputComponent = ({ id, name, value, onChange, onBlur, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = e => {
    onBlur(e);
    setIsFocused(false);
  };
  return (
    <>
      <StyledWrapper isFocused={isFocused}>
        <StyledInput
          id={id}
          name={name}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          onChange={onChange}
          disabled={disabled}
        />
      </StyledWrapper>
    </>
  );
};

const MrnInput = ({ inputLabelProps, value, disabled, onChange, onBlur }) => {
  const mrnValue = value || '';

  return (
    <>
      <AmbInputLabel htmlFor="patientMRN" shrink={inputLabelProps.shrink} style={inputLabelProps.style}>
        MRN
      </AmbInputLabel>
      <InputComponent
        id="patientMRN"
        name="patientMRN"
        value={mrnValue}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};

MrnInput.defaultProps = {
  inputLabelProps: {},
};

MrnInput.propTypes = {
  inputLabelProps: PropTypes.shape(),
  value: PropTypes.string,
};

export default MrnInput;
