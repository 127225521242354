import { createReducer } from 'redux-act';
import * as action from '../actions/epicIntegration.actions';

export const initialState = {
  isEpicDataFetched: false,
};

const epicIntegrationReducer = createReducer(
  {
    [action.fetchEpicDataSuccess]: (state, payload) => {
      return {
        ...state,
        isEpicDataFetched: !!payload,
      };
    },
  },
  initialState,
);

export default epicIntegrationReducer;
