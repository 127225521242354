import React from 'react';
import PropTypes from 'prop-types';
import { Container, Segment, TimesDetails, Status, Vehicle } from './Timeline.elements';

const Timeline = ({ segments, serviceType }) => {
  return (
    <Container aria-label="timeline">
      {segments.map(segment => (
        <Segment key={segment.id} aria-labelledby={segment.status} aria-describedby="timeline-segment-container">
          <TimesDetails times={segment.details} />
          <Vehicle type={serviceType} active={segment.active} />
          <Status active={segment.active} future={segment.future} type={segment.type}>
            {segment.status}
          </Status>
        </Segment>
      ))}
    </Container>
  );
};

Timeline.propTypes = {
  segments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  serviceType: PropTypes.string,
};

Timeline.defaultProps = {
  serviceType: 'BLS',
};

export default Timeline;
