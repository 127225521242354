export const requestStatuses = [0, 25, 50, 200, 250, 275, 300, 325, 350, 400];

const facilityLocationRelationForPlace = {
  relation: 'facilityLocation',
  scope: {
    where: { inactive: false },
    include: [
      {
        relation: 'emsFacility',
        scope: {
          where: { inactive: false },
          include: [
            'licenseType',
            {
              relation: 'entity',
              scope: {
                fields: ['id', 'name'],
              },
            },
            {
              relation: 'emsFacilityDepartments',
              scope: {
                where: { inactive: false },
                include: {
                  relation: 'department',
                  scope: {
                    where: { inactive: false },
                  },
                },
              },
            },
            {
              relation: 'type',
              scope: {
                include: {
                  relation: 'departmentEmsFacilityTypes',
                  scope: {
                    where: { inactive: false },
                    include: {
                      relation: 'department',
                      scope: {
                        where: { inactive: false },
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      },
    ],
  },
};

export const requestsFilter = creatorId => ({
  where: {
    and: [
      {
        creatorId,
      },
      {
        status: { inq: requestStatuses },
      },
    ],
  },
  order: ['created desc'],
});

export const requestFilter = () => ({
  include: [
    {
      relation: 'comments',
      scope: {
        order: 'created DESC',
        limit: 1,
      },
    },
    {
      relation: 'requestChiefComplaints',
      scope: {
        where: { inactive: false },
        order: 'created DESC',
        limit: 1,
        include: [
          {
            relation: 'chiefComplaint',
            scope: {
              include: 'type',
            },
          },
        ],
      },
    },
    {
      relation: 'requesterAgency',
      scope: {
        include: [
          'entity',
          'requesterOptions',
          {
            relation: 'requesterAgencyGroup',
            scope: {
              where: { inactive: false },
              order: 'created DESC',
              limit: 1,
              include: ['patientStoreAdapters'],
            },
          },
          {
            relation: 'payers',
            scope: {
              where: { inactive: false },
              order: 'created DESC',
              limit: 1,
            },
          },
        ],
      },
    },
    {
      relation: 'requester',
      scope: {
        include: [
          {
            relation: 'data',
            scope: {
              where: { inactive: false },
            },
          },
        ],
      },
    },
    {
      relation: 'patient',
      scope: {
        include: [
          {
            relation: 'demographics',
            scope: {
              where: { inactive: false },
            },
          },
          {
            relation: 'patientExternalId',
            scope: {
              where: { inactive: false },
            },
          },
        ],
      },
    },
    {
      relation: 'pickupPlace',
      scope: {
        fields: ['id', 'address', 'name', 'locationGps', 'isResidence', 'typeId', 'globalPlusCode', 'compoundPlusCode'],
        include: facilityLocationRelationForPlace,
      },
    },
    {
      relation: 'destinationPlace',
      scope: {
        fields: ['id', 'address', 'name', 'locationGps', 'isResidence', 'typeId', 'globalPlusCode', 'compoundPlusCode'],
        include: facilityLocationRelationForPlace,
      },
    },
    {
      relation: 'additionalData',
      scope: {
        fields: ['puRoomNumber', 'doRoomNumber'],
      },
    },
    {
      relation: 'therapistEquipment',
      scope: {
        fields: ['equipmentId'],
      },
    },
    {
      relation: 'billerVerifiedBy',
      scope: {
        fields: ['firstName', 'lastName'],
      },
    },
    {
      relation: 'requirements',
    },
    {
      relation: 'requestType',
      scope: {
        fields: ['name'],
      },
    },
    'transportReasons',
    {
      relation: 'telehealthDetail',
      scope: {
        fields: ['telehealthLink'],
      }
    }
  ],
});
