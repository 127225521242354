import ApiService from './api.service';

class PayerService {
  static async getPayers(name, { hideExternalPayers } = { hideExternalPayers: false }) {
    const params = {
      extraOptions: {
        hideExternalPayers,
      },
    };

    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Payers/search?name=${name}&${ApiService.objectToURLQueryString(
      params,
    )}`;
    const response = await ApiService.get(path);
    return response.json();
  }

  static async findFacilityPayer(facility) {
    const { name } = facility;
    const payers = await this.getPayers(name);
    const payer = payers.find(p => p.name === name || p.entity.aliases.find(alias => alias.name === name));
    if (payer) return payer;

    return this.createPayer(facility);
  }

  static async createPayer({ name, marketId }) {
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Payers/findOrCreateByType`;
    const response = await ApiService.post(path, { name, marketId });

    return response.json();
  }

  static async getPayer(payerId) {
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Payers/${payerId}`;
    const response = await ApiService.get(path);
    return response.json();
  }

  static async getPayerOptions(payerId) {
    const path = `${window.ambulnzConfig.tenantedRestApiUrl}/Payers/${payerId}/PayerOptions`;
    const response = await ApiService.get(path);

    return response.json();
  }
}

export default PayerService;
