import styled from '@emotion/styled';
import { palette } from '../../../theme';

export const NoFiles = styled.p`
  && {
    font-size: 14px;
    color: ${palette.grey.dark};
    padding-top: 10px;
    margin: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  min-width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #899ba64a;
`;

export const TypeAndDelete = styled.div`
  display: flex;
  align-items: center;
`;

export const Downloadable = styled.a`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const IconButton = styled.div`
  flex: 0 0 auto;
  padding: 0;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
`;
