import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PortalInfoFrame from './PortalInfo.frame';
import * as eligibilityActions from '../../../redux/actions/eligibility.actions';

function PortalInfoContainer(props) {
  return <PortalInfoFrame {...props} />;
}

PortalInfoContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    portalInfo: state.eligibility.portalInfo,
    expanded: state.eligibility.infoExpanded,
  };
}

export default connect(mapStateToProps, { ...eligibilityActions })(PortalInfoContainer);
