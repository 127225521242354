import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';
import OutlinedButton from '../../common/OutlinedButton';
import AmbCircularProgress from '../../common/AmbCircularProgress';
import DispatcherNoETA from './DispatcherNoETA.frame';
import RequesterNoETA from './RequesterNoETA.frame';
import AmbGrid from 'components/common/AmbGrid';
import { theme } from '../../../theme';

const ContactDispatcherFrame = props => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const outlinedPrimaryButtonStyles = {
    marginRight: 10,
    display: props.user.isRequester ? 'none' : 'inline',
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  };

  if (hovered) {
    outlinedPrimaryButtonStyles.backgroundColor = 'rgba(30, 192, 217, 0.12)';
    outlinedPrimaryButtonStyles.border = `1px solid ${theme.palette.primary.light}`;
  }

  return (
    <AmbGrid style={{ flexDirection: 'column', width: '100%' }}>
      <div
        style={{
          textAlign: 'center',
          textTransform: 'uppercase',
          backgroundColor: theme.palette.primary.red,
          padding: 5,
          fontWeight: 600,
          color: '#fff',
        }}
      >
        {`Trip: #${props.request.id}`}
      </div>
      <div style={{ padding: '0 10px' }}>
        {!props.user.isRequester && <DispatcherNoETA {...props} />}
        {props.user.isRequester && <RequesterNoETA {...props} />}
        <ListItem style={{ textAlign: 'center', paddingTop: 24 }}>
          <div style={{ width: '100%' }}>
            <OutlinedButton
              disabled={props.showEtaProgress || props.turndownInProgress || null}
              variant="outlined"
              color="primary"
              onClick={() => props.clickGetETA()}
              style={outlinedPrimaryButtonStyles}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {props.showEtaProgress ? (
                <AmbCircularProgress
                  size={20}
                  propsStyles={{
                    color: 'white',
                  }}
                />
              ) : (
                <span>Refresh ETA</span>
              )}
            </OutlinedButton>

            <OutlinedButton
              disabled={props.showEtaProgress || props.turndownInProgress || null}
              variant="outlined"
              color="secondary"
              onClick={() => props.setTurndownDialogOpen(true)}
              style={{
                marginLeft: 10,
                color: theme.palette.primary.red,
                border: `1px solid ${theme.palette.primary.red}`,
              }}
            >
              {props.turndownInProgress ? (
                <AmbCircularProgress
                  size={20}
                  propsStyles={{
                    color: 'white',
                  }}
                />
              ) : (
                <span>Turndown</span>
              )}
            </OutlinedButton>
          </div>
        </ListItem>
      </div>
    </AmbGrid>
  );
};

ContactDispatcherFrame.propTypes = {
  user: PropTypes.shape().isRequired,
  request: PropTypes.shape().isRequired,
  showEtaProgress: PropTypes.bool.isRequired,
  turndownInProgress: PropTypes.bool.isRequired,
  clickGetETA: PropTypes.func.isRequired,
  setTurndownDialogOpen: PropTypes.func.isRequired,
};

export default ContactDispatcherFrame;
