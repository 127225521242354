import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 51.1 24" {...props}>
      <title>WAV</title>
      <path
        style={{ fill: '#1ec2d8' }}
        d="M2.08,0H36.9a3.41,3.41,0,0,1,2.25,1.21A3.65,3.65,0,0,1,40,3.47.48.48,0,0,1,39.5,4H38.11l6.5,8s2.77.87,4.07,1.13a1.66,1.66,0,0,1,1.39,1.38l.78,4H45.13a3.86,3.86,0,0,0-2.68-1.56A4,4,0,0,0,39.07,19H10.34S8.92,16.9,7.1,16.9A3.65,3.65,0,0,0,3.86,19H.43V1.48A1.43,1.43,0,0,1,2.08,0Z"
        transform="translate(0 0)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M22.61.78a5.69,5.69,0,0,0-5.56,5.09,5.88,5.88,0,0,1,3.11,1.42v-1a2.38,2.38,0,0,1,.76-1.7,2.73,2.73,0,0,1,1.69-.66,2.48,2.48,0,0,1,2.45,2.64v8.58H22V12.28a5.8,5.8,0,0,0-5.84-5.75,5.87,5.87,0,0,0-5.84,5.84A5.79,5.79,0,0,0,16,18.22h2.07V15.11h-2a2.74,2.74,0,1,1,2.73-2.83v5.94h9.43V6.53A5.64,5.64,0,0,0,22.61.78"
          transform="translate(0 0)"
        />
      </g>
      <path
        style={{ fill: '#050505' }}
        d="M1,19H39.07l-.35,1.47H.61A.59.59,0,0,1,0,19.84v-.26A.59.59,0,0,1,.61,19H1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#71ccdd' }}
        d="M39.59,20.45v1.38H3.72A2.93,2.93,0,0,1,1,20.45Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#050505' }} cx="7.1" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="7.1" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="7.1" cy="20.45" r="1.65" />
      <path
        style={{ fill: '#2b242d' }}
        d="M45.13,22.09h4.68c1.21,0,1.29-2.14,1.29-2.34s-.25-1.29-.25-1.29H45.13Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#5f5f61' }} cx="7.1" cy="20.45" r="1.21" />
      <circle style={{ fill: '#050505' }} cx="42.27" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="42.27" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="42.27" cy="20.45" r="1.65" />
      <circle style={{ fill: '#5f5f61' }} cx="42.27" cy="20.45" r="1.21" />
      <path
        style={{ fill: '#ebebed' }}
        d="M37.1,13h0v-.06h0a.24.24,0,0,0-.27-.21h-.95a.25.25,0,0,0-.22.27h0v.05h0a.25.25,0,0,0,.27.21h.9C37,13.27,37.19,13.18,37.1,13Z"
        transform="translate(0 -0.67)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M32.18,4V9.39a.9.9,0,0,1-.9.9H4.73A2.7,2.7,0,0,1,2,7.59V4a.9.9,0,0,1,.9-.9H31.28A.9.9,0,0,1,32.18,4Z"
        transform="translate(0 -0.67)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M33.81,9.93V4.34a.48.48,0,0,1,.45-.45h.9a4.44,4.44,0,0,1,3.22,1.37l5.92,6.09-10.07-1A.47.47,0,0,1,33.81,9.93Z"
        transform="translate(0 -0.67)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M23,1.13a5.63,5.63,0,0,0-5.51,5,5.87,5.87,0,0,1,3.07,1.41v-1a2.34,2.34,0,0,1,.75-1.68A2.65,2.65,0,0,1,23,4.24a2.47,2.47,0,0,1,2.44,2.48v8.6H22.39v-2.8a5.73,5.73,0,0,0-5.76-5.69h0A5.84,5.84,0,0,0,10.8,12.6a5.74,5.74,0,0,0,5.68,5.79h2.07v-3.1h-2a2.7,2.7,0,1,1,2.7-2.8v5.89h9.35V6.83a5.57,5.57,0,0,0-5.44-5.7H23"
          transform="translate(0 -0.67)"
        />
      </g>
      <path
        style={{ fill: '#ededef' }}
        d="M50.75,14.93h1.32s0-.08,0-.12a2.67,2.67,0,0,0-.13-.4c-.06-.13-.12-.26-.19-.39h0a3.07,3.07,0,0,0-2.37-1.6l-.2-.05h-.05A8.16,8.16,0,0,1,50.75,14.93Z"
        transform="translate(0 -0.67)"
      />
      <path
        d="M13,15.32a.5.5,0,0,1-.48-.32l-2.3-5.83L7.93,15a.51.51,0,0,1-.48.31H7.23a.51.51,0,0,1-.49-.39L4.09,5.53a.49.49,0,0,1,.49-.65H5.87a.53.53,0,0,1,.49.37l1.29,4.93,2-5.13a.47.47,0,0,1,.47-.32h.25a.49.49,0,0,1,.48.33l2,5.12,1.26-4.92a.52.52,0,0,1,.49-.38h1.29a.52.52,0,0,1,.42.19.5.5,0,0,1,.07.46L13.7,14.94a.52.52,0,0,1-.49.38Z"
        transform="translate(0 -0.67)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M10.35,5a.23.23,0,0,1,.24.16l2.24,5.77h.06l1.43-5.59a.29.29,0,0,1,.25-.2h1.29a.25.25,0,0,1,.25.34l-2.65,9.41a.26.26,0,0,1-.25.2H13a.24.24,0,0,1-.24-.15L10.24,8.57h-.07L7.69,14.92a.24.24,0,0,1-.24.15H7.23a.26.26,0,0,1-.25-.2L4.33,5.46a.25.25,0,0,1,.25-.34H5.87a.28.28,0,0,1,.25.2l1.46,5.59h.06L9.86,5.14A.23.23,0,0,1,10.1,5h.25m0-.5H10.1a.72.72,0,0,0-.71.5L7.7,9.37,6.61,5.19a.81.81,0,0,0-.74-.57H4.58a.75.75,0,0,0-.73,1L6.5,15a.76.76,0,0,0,.73.56h.22a.75.75,0,0,0,.69-.43l2.07-5.29,2.07,5.25a.74.74,0,0,0,.71.47h.22A.76.76,0,0,0,14,15L16.59,5.6a.75.75,0,0,0-.73-1H14.57a.79.79,0,0,0-.73.56L12.77,9.36,11.06,5a.74.74,0,0,0-.71-.48Z"
        transform="translate(0 -0.67)"
      />
      <path
        d="M24.34,15.18a.64.64,0,0,1-.63-.42l-.62-1.37H19.22l-.62,1.36a.65.65,0,0,1-.63.43H16.75a.49.49,0,0,1-.44-.23.53.53,0,0,1,0-.49L20.64,5a.51.51,0,0,1,.46-.3h.14a.5.5,0,0,1,.47.3L26,14.46A.5.5,0,0,1,26,15a.49.49,0,0,1-.44.23Zm-2.17-3.85-1-2.27-1,2.27Z"
        transform="translate(0 -0.67)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M21.24,5a.24.24,0,0,1,.24.16l4.32,9.43a.25.25,0,0,1-.24.36H24.34a.4.4,0,0,1-.41-.28l-.68-1.51H19.06l-.69,1.51a.41.41,0,0,1-.4.28H16.75a.25.25,0,0,1-.24-.36l4.36-9.43A.24.24,0,0,1,21.1,5h.14m-1.49,6.6h2.81L21.16,8.5h0l-1.37,3.08m1.49-7.1H21.1a.75.75,0,0,0-.68.44l-4.36,9.44a.73.73,0,0,0,0,.73.77.77,0,0,0,.65.34H18a.93.93,0,0,0,.87-.6l.54-1.19h3.55l.55,1.22a.9.9,0,0,0,.86.57h1.22a.77.77,0,0,0,.65-.34.76.76,0,0,0,0-.73L21.94,4.93a.76.76,0,0,0-.7-.45Zm-.72,6.6.62-1.41.64,1.41Z"
        transform="translate(0 -0.67)"
      />
      <path
        d="M30.36,15.32A.48.48,0,0,1,29.9,15L25.56,5.59a.53.53,0,0,1,0-.49A.51.51,0,0,1,26,4.88h1.43a.5.5,0,0,1,.47.31l2.5,5.61,2.51-5.62a.5.5,0,0,1,.47-.3h1.43a.52.52,0,0,1,.44.22.5.5,0,0,1,0,.49L31,15a.48.48,0,0,1-.46.3Z"
        transform="translate(0 -0.67)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M34.84,5.12a.25.25,0,0,1,.24.37l-4.34,9.43a.27.27,0,0,1-.24.15h-.14a.25.25,0,0,1-.24-.15L25.79,5.49A.25.25,0,0,1,26,5.12h1.43a.27.27,0,0,1,.24.16l2.69,6h.09l2.69-6a.27.27,0,0,1,.24-.16h1.43m0-.5H33.41a.75.75,0,0,0-.7.47l-2.27,5.1L28.15,5.07a.74.74,0,0,0-.69-.45H26a.75.75,0,0,0-.69,1.08l4.33,9.42a.75.75,0,0,0,.69.45h.14a.74.74,0,0,0,.68-.43L35.53,5.7a.75.75,0,0,0-.69-1.08Z"
        transform="translate(0 -0.67)"
      />
    </svg>
  );
}
