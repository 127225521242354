import store from '../redux/store/configureStore';
import * as errorActions from '../redux/actions/error.actions';

export function setHARIntoErrorState(response, params, url) {
  const harObject = {
    log: {
      version: '1.2',
      creator: {
        name: 'requester-web',
        version: '1',
      },
      pages: [
        {
          startedDateTime: new Date().toISOString(),
          id: 'page_1',
          title: url,
          pageTimings: {},
        },
      ],
      entries: [
        {
          _initiator: {
            type: 'script',
          },
          _resourceType: 'hxr',
          cache: {},
          connection: '',

          pageref: 'page_1',
          request: {
            method: params.method,
            url: url,
            httpVersion: '',
            headers: [],
            queryString: [],
            cookies: [],
            headersSize: -1,
            bodySize: -1,
          },
          response: {
            status: response.status,
            statusText: response.statusText,
            httpVersion: '',
            headers: headersToArray(response.headers),
            cookies: [],
            content: {
              size: 0,
              mimeType: 'application/json',
              text: response.error,
            },
            redirectURL: '',
            headersSize: -1,
            bodySize: -1,
          },
          serverIPAddress: '::1',
          startedDateTime: new Date().toISOString(),
          time: 0,
          timings: {
            blocked: -1,
            dns: -1,
            ssl: -1,
            connect: -1,
            send: -1,
            wait: -1,
            receive: -1,
          },
        },
      ],
    },
  };
  const harJson = harObject;
  store.dispatch(errorActions.setHarError(JSON.stringify(harJson)));
}

export const headersToArray = headers => {
  const result = [];
  headers.forEach((value, key) => {
    result.push({ name: key, value });
  });
  return result;
};
