import { from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { parseRequest } from '../../parsers/request.parser';
import RequestService from '../../services/request.service';
import * as requestsActions from '../actions/requests.actions';
import * as epicIntegrationActions from '../actions/epicIntegration.actions';
import * as chiefComplaintActions from '../actions/chiefComplaint.actions';
import * as errorActions from '../actions/error.actions';
import { errorPipe } from './epicsUtil';
import { createShareLink, updateRequest, parseEpicIntegrationChiefComplaint } from './helpers/request.helper';
import { createNotReserved, getRequestId } from './updateRequestUtil';

export const getRequestsListEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.getRequests.match),
    switchMap(() =>
      from$(RequestService.getRequests(store.value.user.model.id)).pipe(
        map(response => requestsActions.getRequestsSuccess(response)),
        errorPipe,
      ),
    ),
  );

export const getRequestEpic = action$ =>
  action$.pipe(
    filter(requestsActions.getRequest.match),
    switchMap(action =>
      from$(RequestService.getRequest(action.payload)).pipe(
        map(response => requestsActions.getRequestSuccess(response)),
        errorPipe,
      ),
    ),
  );

export const createShareLinkEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [requestsActions.createRequestSuccess.match, requestsActions.createShareLink.match].some(match => match(action)),
    ),
    switchMap(() =>
      createShareLink(store).pipe(
        map(response => requestsActions.createShareLinkSuccess(response)),
        errorPipe,
      ),
    ),
  );

export const updateSingleToRoundTripEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.asyncRoundTripChange.match),
    filter(action => getRequestId(store) && action.payload.isRoundTrip),
    switchMap(() => {
      const { roundTrip, estimatedWaitForReturnMins, doNotWaitForReturn } = parseRequest(store.value);
      return updateRequest(store, { roundTrip, estimatedWaitForReturnMins, doNotWaitForReturn });
    }),
    switchMap(() => from$(RequestService.submit(getRequestId(store)))),
    switchMap(() =>
      createNotReserved(store.value).pipe(
        map(() => requestsActions.asyncRoundTripChangeSuccess()),
        errorPipe,
      ),
    ),
  );

export const setEpicIntegrationChiefComplaint = action$ =>
  action$.pipe(
    filter(epicIntegrationActions.fetchEpicDataSuccess.match),
    mergeMap(action => {
      if (action.payload.error) {
        return of$(errorActions.onError(action.payload));
      }
      return from$(parseEpicIntegrationChiefComplaint(action.payload)).pipe(
        map(response => chiefComplaintActions.setChiefComplaint(response)),
      );
    }),
  );
