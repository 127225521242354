import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../../common/AmbGrid';
import { SectionHeader } from '../elements';
import Button from '@mui/material/Button';
import AmbGroupToggle from '../../common/AmbGroupToggle';
import CanWalk from '../../common/icons/CanWalk';
import WheelChair from '../../common/icons/WheelChair';
import BedBound from '../../common/icons/BedBound';

export default function Mobility({
  mobilityId,
  handleMobilityChange,
  mobilities,
  walkDisabled,
  wheelchairDisabled,
  bedboundDisabled,
  svgStyles,
}) {
  const setOpacity = type => (Number(mobilityId) === type ? 1 : 0.8);

  return (
    <>
      <AmbGrid style={{ flexDirection: 'column' }}>
        <AmbGrid>
          <SectionHeader>Mobility</SectionHeader>
        </AmbGrid>
        <AmbGroupToggle value={mobilityId} onChange={({ key }) => handleMobilityChange(key)}>
          <Button selectedkey={mobilities.walk && mobilities.walk.toString()} disabled={walkDisabled || null}>
            <CanWalk style={{ ...svgStyles, marginRight: 4, opacity: setOpacity(mobilities.walk) }} />
            Can Walk
          </Button>
          <Button
            selectedkey={mobilities.wheelchair && mobilities.wheelchair.toString()}
            disabled={wheelchairDisabled || null}
          >
            <WheelChair
              style={{
                ...svgStyles,
                marginRight: 5,
                opacity: setOpacity(mobilities.wheelchair),
              }}
            />
            Wheelchair
          </Button>
          <Button
            selectedkey={mobilities.bedbound && mobilities.bedbound.toString()}
            disabled={bedboundDisabled || null}
          >
            <BedBound
              style={{
                ...svgStyles,
                marginRight: 8,
                opacity: setOpacity(mobilities.bedbound),
              }}
            />
            Bed-bound
          </Button>
        </AmbGroupToggle>
      </AmbGrid>
    </>
  );
}

Mobility.propTypes = {
  mobilityId: PropTypes.number,
  handleMobilityChange: PropTypes.func.isRequired,
  mobilities: PropTypes.shape().isRequired,
  walkDisabled: PropTypes.bool.isRequired,
  wheelchairDisabled: PropTypes.bool.isRequired,
  bedboundDisabled: PropTypes.bool.isRequired,
  svgStyles: PropTypes.shape().isRequired,
};

Mobility.defaultProps = {
  mobilityId: null,
};
