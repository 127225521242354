import ApiService from './api.service';

class UserService {
  static async getAuthenticatedUser() {
    const response = await ApiService.get(`${window.ambulnzConfig.tenantedRestApiUrl}/AmbUsers/whoami`, null, {
      noCache: true,
    });
    return response.json();
  }

  static async getUserFacilities(userId) {
    const filter = {
      include: {
        requesterAgency: ['entity', 'payers', { requesterAgencyGroup: 'patientStoreAdapters' }, 'requesterOptions'],
      },
    };
    const response = await ApiService.get(
      `${window.ambulnzConfig.tenantedRestApiUrl}/AmbUsers/${userId}/Facilities?filter=${JSON.stringify(filter)}`,
    );
    return response.json();
  }
}

export default UserService;
