import * as R from 'ramda';

export default function sortByOrder(data = [], sortProperty = 'sort', secondarySortProperty = 'id') {
  if (R.isEmpty(data)) {
    return [];
  }

  const dataWithOrder = data.filter(singleData => singleData[sortProperty] > 0);
  const dataWithoutOrder = data.filter(singleData => singleData[sortProperty] === 0);

  const sortedData = R.sort(R.compose(R.ascend(R.prop(sortProperty))), dataWithOrder).concat(
    R.sort(R.compose(R.ascend(R.prop(secondarySortProperty))), dataWithoutOrder),
  );

  return sortedData;
}
