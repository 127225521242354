import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g>
        <g>
          <circle cx="12" cy="6" r="2.5" />
          <path
            d="M9.5,14.5l-1.4,1.4c-0.8,0.8-0.8,2.1,0,2.8l0.7,0.7l0.7,0.7c0.4,0.4,1,0.4,1.4,0
				c0.4-0.4,0.4-1,0-1.4l-0.7-0.7l1.4-1.4L9.5,14.5z"
          />
          <path
            d="M14.5,14.5l-2.1,2.1l1.4,1.4l-0.7,0.7c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l0.7-0.7l0.7-0.7
				c0.8-0.8,0.8-2.1,0-2.8L14.5,14.5z"
          />
          <path
            d="M17.5,12.3c0,0-1.1-1.1-1.9-1.8C15.1,10,14,9,12,9s-3.1,1-3.7,1.5c-0.7,0.7-1.9,1.8-1.9,1.8
				c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l1.6-1.6v1.4H12h2.5v-1.4l1.6,1.6c0.4,0.4,1,0.4,1.4,0C17.9,13.3,17.9,12.7,17.5,12.3z
				"
          />
        </g>
      </g>
    </svg>
  );
}
