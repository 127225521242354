import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 51.1 24" {...props}>
      <title>BLS</title>
      <path
        style={{ fill: '#1ec2d8' }}
        d="M2.08,0H36.9a3.41,3.41,0,0,1,2.25,1.21A3.65,3.65,0,0,1,40,3.47.48.48,0,0,1,39.5,4H38.11l6.5,8s2.77.87,4.07,1.13a1.66,1.66,0,0,1,1.39,1.38l.78,4H45.13a3.86,3.86,0,0,0-2.68-1.56A4,4,0,0,0,39.07,19H10.34S8.92,16.9,7.1,16.9A3.65,3.65,0,0,0,3.86,19H.43V1.48A1.43,1.43,0,0,1,2.08,0Z"
        transform="translate(0 0)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M22.61.78a5.69,5.69,0,0,0-5.56,5.09,5.88,5.88,0,0,1,3.11,1.42v-1a2.38,2.38,0,0,1,.76-1.7,2.73,2.73,0,0,1,1.69-.66,2.48,2.48,0,0,1,2.45,2.64v8.58H22V12.28a5.8,5.8,0,0,0-5.84-5.75,5.87,5.87,0,0,0-5.84,5.84A5.79,5.79,0,0,0,16,18.22h2.07V15.11h-2a2.74,2.74,0,1,1,2.73-2.83v5.94h9.43V6.53A5.64,5.64,0,0,0,22.61.78"
          transform="translate(0 0)"
        />
      </g>
      <path
        style={{ fill: '#050505' }}
        d="M1,19H39.07l-.35,1.47H.61A.59.59,0,0,1,0,19.84v-.26A.59.59,0,0,1,.61,19H1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#71ccdd' }}
        d="M39.59,20.45v1.38H3.72A2.93,2.93,0,0,1,1,20.45Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#050505' }} cx="7.1" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="7.1" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="7.1" cy="20.45" r="1.65" />
      <path
        style={{ fill: '#2b242d' }}
        d="M45.13,22.09h4.68c1.21,0,1.29-2.14,1.29-2.34s-.25-1.29-.25-1.29H45.13Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#5f5f61' }} cx="7.1" cy="20.45" r="1.21" />
      <circle style={{ fill: '#050505' }} cx="42.27" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="42.27" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="42.27" cy="20.45" r="1.65" />
      <circle style={{ fill: '#5f5f61' }} cx="42.27" cy="20.45" r="1.21" />
      <path
        style={{ fill: '#ebebed' }}
        d="M35.78,13.35h0a.23.23,0,0,0-.26-.26h-.87a.23.23,0,0,0-.26.26h0a.23.23,0,0,0,.26.26h.87C35.69,13.61,35.86,13.52,35.78,13.35Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M36.9,4.51a1.89,1.89,0,0,1,1.56.61l4.85,6.23L33.61,10V4.51Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ededef' }}
        d="M48,12.92l.82,1.75,1.4.39-.11-.59a1.66,1.66,0,0,0-1.39-1.38Z"
        transform="translate(0 0)"
      />
      <rect style={{ fill: '#ededef' }} x="45.3" y="16.12" width="1.56" height="1.21" />
      <path
        d="M10,15.14a.52.52,0,0,1-.52-.52V5.35A.52.52,0,0,1,10,4.83h3.21a3,3,0,0,1,3.2,2.85,2.8,2.8,0,0,1-1.24,2.18,2.66,2.66,0,0,1,1.52,2.33,3.07,3.07,0,0,1-3.3,3ZM13.23,13a1,1,0,0,0,1-1,1.11,1.11,0,0,0-1.17-1H11.79v2ZM13,9a1,1,0,0,0,1-1A.89.89,0,0,0,13,7H11.79V9Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M13.21,5.08a2.75,2.75,0,0,1,2.95,2.6A2.67,2.67,0,0,1,14.62,9.9a2.53,2.53,0,0,1,1.82,2.29,2.83,2.83,0,0,1-3.05,2.7H10a.27.27,0,0,1-.27-.27V5.35A.27.27,0,0,1,10,5.08h3.21M11.54,9.2H13a1.2,1.2,0,0,0,1.2-1.26A1.14,1.14,0,0,0,13,6.75h-1.5V9.2m0,4h1.69A1.25,1.25,0,0,0,14.49,12a1.35,1.35,0,0,0-1.42-1.22H11.54v2.5m1.67-8.66H10a.78.78,0,0,0-.77.77v9.27a.77.77,0,0,0,.77.77h3.39a3.32,3.32,0,0,0,3.55-3.2,2.84,2.84,0,0,0-1.33-2.36,2.9,2.9,0,0,0,1.05-2.15,3.26,3.26,0,0,0-3.45-3.1ZM12,7.25h1a.65.65,0,0,1,.7.69.7.7,0,0,1-.7.76H12V7.25Zm0,4h1c.43,0,.92.3.92.72a.75.75,0,0,1-.76.78H12v-1.5Z"
        transform="translate(0 0)"
      />
      <path
        d="M18.64,15.14a.52.52,0,0,1-.52-.52V5.35a.52.52,0,0,1,.52-.52h1.28a.53.53,0,0,1,.52.52V13h3.32a.51.51,0,0,1,.52.51v1.15a.52.52,0,0,1-.52.52Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M19.92,5.08a.28.28,0,0,1,.27.27v7.86h3.57a.26.26,0,0,1,.27.26v1.15a.27.27,0,0,1-.27.27H18.64a.27.27,0,0,1-.27-.27V5.35a.27.27,0,0,1,.27-.27h1.28m0-.5H18.64a.77.77,0,0,0-.77.77v9.27a.76.76,0,0,0,.77.77h5.12a.77.77,0,0,0,.77-.77V13.47a.77.77,0,0,0-.77-.76H20.69V5.35a.78.78,0,0,0-.77-.77Z"
        transform="translate(0 0)"
      />
      <path
        d="M28,15.28a4.75,4.75,0,0,1-3.26-1.16.53.53,0,0,1-.11-.73l.5-.87a.57.57,0,0,1,.5-.28.61.61,0,0,1,.35.11,3.9,3.9,0,0,0,2,.82.93.93,0,0,0,1-.83c0-.55-.49-1-1.63-1.45s-2.79-1.35-2.79-3.26c0-1.41,1.07-2.94,3.4-2.94a5.27,5.27,0,0,1,3,1,.52.52,0,0,1,.22.35.66.66,0,0,1-.08.46l-.53.79a.71.71,0,0,1-.55.33.48.48,0,0,1-.29-.09,4,4,0,0,0-1.81-.73c-.67,0-1,.44-1,.73,0,.52.43.91,1.5,1.34,1.52.61,3.08,1.5,3.08,3.4A3.19,3.19,0,0,1,28,15.28Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M28,4.94a5,5,0,0,1,2.81.94.36.36,0,0,1,.08.47l-.53.79a.45.45,0,0,1-.34.22.25.25,0,0,1-.15-.05,4.62,4.62,0,0,0-1.95-.77,1.11,1.11,0,0,0-1.23,1c0,.66.52,1.11,1.65,1.57,1.36.55,2.93,1.36,2.93,3.17A3,3,0,0,1,28,15a4.52,4.52,0,0,1-3.08-1.09c-.13-.13-.2-.2-.08-.42l.51-.87a.29.29,0,0,1,.28-.16.36.36,0,0,1,.2.06,4.63,4.63,0,0,0,2.11.87,1.18,1.18,0,0,0,1.28-1.08c0-.71-.61-1.2-1.78-1.68-1.32-.53-2.64-1.37-2.64-3,0-1.24.93-2.69,3.15-2.69m0-.5c-2.5,0-3.65,1.66-3.65,3.19,0,2.3,2.22,3.2,2.95,3.49,1.29.53,1.47.91,1.47,1.22s-.34.58-.78.58a3.75,3.75,0,0,1-1.79-.76h0a.78.78,0,0,0-.49-.16.79.79,0,0,0-.71.41l-.51.87a.76.76,0,0,0,.15,1A5,5,0,0,0,28,15.53a3.44,3.44,0,0,0,3.72-3.27c0-2.33-2.27-3.24-3.24-3.63-1.18-.48-1.34-.82-1.34-1.11s.21-.48.73-.48a3.7,3.7,0,0,1,1.66.68.79.79,0,0,0,.44.14.93.93,0,0,0,.75-.44l.54-.8A.82.82,0,0,0,31.39,6a.76.76,0,0,0-.33-.51,5.45,5.45,0,0,0-3.09-1Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
