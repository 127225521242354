import { combineEpics } from 'redux-observable';
import * as agency from './agency.epics';
import * as eta from '../../components/GetETA/epics/acceptEta.epics';
import * as formFlow from './formFlow.epics';
import * as infobox from './infobox.epics';
import * as loadRequest from './loadRequest.epics';
import * as map from './map.epics';
import * as requests from './requests.epics';
import * as service from './service.epics';
import * as user from './user.epics';
import * as loadPayer from './loadPayer.epics';
import * as turndown from './turndown.epics';
import * as destination from './destination.epics';
import * as pickup from './pickup.epics';
import * as submitPatientInfo from './submitPatientInfo.epics';
import * as requester from './requester.epics';
import * as patient from './patient.epics';
import * as coverages from './coverages.epics';
import * as pickupTimeChange from './pickupTimeChange.epics';
import * as returnRequest from './returnRequest.epics';
import * as similarRequest from './similarRequest.epics';
import * as eligibilityEpics from './eligibility.epics';
import * as autoSaveEpics from './autoSave.epics';
import * as willCallEpics from './willCall.epics';
import * as requestShareEpics from './requestShare.epics';
import * as chiefComplaintEpics from './chiefComplaint.epics';
import * as scheduleEpics from './schedule.epics';
import * as duplicateRequestEpics from '../../components/Summary/DuplicateRequest/redux/duplicateRequest.epics';
import * as cancelRequestEpics from '../../components/Summary/CancelRequest/redux/epics';
import * as unassignRequestEpics from '../../components/Summary/Unassign/redux/epics';
import * as pickupDestiEpics from '../../components/PickupDestination/redux/epics/location.epics';
import * as careProvidedAtEpics from '../../components/ServiceLevel/CareProvidedAtReceivingFacility/epics';
import * as patientDocumentsEpics from '../../components/PatientDocuments/epics';
import * as getEtaEpics from '../../components/GetETA/epics';
import * as enumsEpics from './orders/enums.epics';
import * as userAccountEpics from './userAccount.epics';
import * as subscriptionEpics from './subscription.epics';
import {
  submitPCSFormEpic,
  createPCSAttachmentEpic,
} from '../../components/PhysicianCertificationStatement/redux/submitPCSForm';

const rootEpic = combineEpics(
  ...Object.values({
    ...agency,
    ...eta,
    ...formFlow,
    ...infobox,
    ...loadRequest,
    ...map,
    ...requests,
    ...service,
    ...user,
    ...loadPayer,
    ...turndown,
    ...destination,
    ...pickup,
    ...submitPatientInfo,
    ...requester,
    ...patient,
    ...coverages,
    ...pickupTimeChange,
    ...returnRequest,
    ...duplicateRequestEpics,
    ...eligibilityEpics,
    ...autoSaveEpics,
    ...willCallEpics,
    ...requestShareEpics,
    ...chiefComplaintEpics,
    ...cancelRequestEpics,
    ...unassignRequestEpics,
    ...pickupDestiEpics,
    ...careProvidedAtEpics,
    ...patientDocumentsEpics,
    ...getEtaEpics,
    ...scheduleEpics,
    ...enumsEpics,
    ...similarRequest,
    ...userAccountEpics,
    ...subscriptionEpics,
    submitPCSFormEpic,
    createPCSAttachmentEpic,
  }),
);

export default rootEpic;
