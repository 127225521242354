import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <g>
        <g>
          <g>
            <circle cx="8" cy="8" r="7.4" />
            <path
              style={{ fill: '#014053' }}
              d="M8,15.9c-4.3,0-7.9-3.5-7.9-7.9c0-4.3,3.5-7.9,7.9-7.9c4.3,0,7.9,3.5,7.9,7.9C15.9,12.4,12.3,15.9,8,15.9z
            M8,1.2C4.2,1.2,1.1,4.3,1.1,8s3.1,6.9,6.9,6.9s6.9-3.1,6.9-6.9S11.8,1.2,8,1.2z"
            />
          </g>
        </g>
        <g>
          <circle cx="8" cy="8" r="5.7" />
        </g>
        <g>
          <g>
            <path
              style={{ fill: '#014053' }}
              d="M7.2,8.8V4.7c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v3.9l2.3,1.9c0.2,0.1,0.2,0.4,0.1,0.6s-0.4,0.2-0.6,0.1
            L7.4,9.2C7.2,9,7.2,8.9,7.2,8.8L7.2,8.8z"
            />
            <path
              style={{ fill: '#014053' }}
              d="M10.1,11.3c-0.1,0-0.2,0-0.4-0.1L7.3,9.3C7.1,9.1,7.1,8.9,7.1,8.8V4.7c0-0.3,0.3-0.5,0.5-0.5
            s0.5,0.3,0.5,0.5v3.8l2.2,1.8c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.3-0.1,0.4C10.4,11.2,10.3,11.3,10.1,11.3z M7.7,4.4
            c-0.1,0-0.3,0.2-0.3,0.3v4.1c0,0.1,0,0.2,0.1,0.3L9.9,11c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1-0.1-0.1
				    L7.9,8.7V4.7C7.9,4.6,7.8,4.4,7.7,4.4z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
