import { from as from$ } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import * as etaActions from '../../../redux/actions/eta.actions';
import { errorPipe } from '../../../redux/epics/epicsUtil';
import { getRequestId } from '../../../redux/epics/updateRequestUtil';
import { createRequestFromGetEta } from '../../../services/createRequestFromGetEta.service';
import { updateReturnRequestCanBeActivated } from '../../../services/returnRequestCanBeActivated.service';

export const getETACreateRequestEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.requestETA.match),
    filter(() => !getRequestId(store)),
    switchMap(() =>
      from$(createRequestFromGetEta(store.value)).pipe(
        switchMap(request =>
          from$(updateReturnRequestCanBeActivated(request, store.value)).pipe(
            map(request => etaActions.asyncCreateNotReservedSuccess(request)),
            takeUntil(
              action$.pipe(
                filter(
                  action =>
                    etaActions.asyncCreateNotReservedSuccess.match(action) ||
                    etaActions.requestETATimeout.match(action),
                ),
              ),
            ),
            errorPipe,
          ),
        ),
      ),
    ),
  );
