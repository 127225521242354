import React, { useState, useEffect } from 'react';

const Countdown = ({ onCountdownComplete }) => {
  const [time, setTime] = useState(300);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(time => time - 1);
    }, 1000);

    if (time === 0) {
      clearInterval(timer);
      onCountdownComplete();
    }

    return () => clearInterval(timer);
  }, [time]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return (
    <div style={{ width: '100%', padding: '12px 4px 0px' }}>
      <span>The following availabilities are reserved for the next </span>
      <span style={{ fontWeight: 'bold', color: minutes < 1 ? 'red' : 'inherit' }}>
        {minutes}:{seconds < 10 ? '0' : ''}
        {seconds}
      </span>
    </div>
  );
};

export default Countdown;
