import { createReducer } from 'redux-act';
import * as action from '../actions/trackChanges.actions';

const trackStackDepth = 10;
const initialState = {
  patient: [],
};

const trackChangesReducer = createReducer(
  {
    [action.addPatientSnapshot]: (state, payload) => ({
      ...state,
      patient: [payload].concat(state.patient).slice(0, trackStackDepth),
    }),
  },
  initialState,
);
export default trackChangesReducer;
