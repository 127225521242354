import { from as from$, of as of$, concat as concat$ } from 'rxjs';
import { map, mergeMap, switchMap, filter, catchError } from 'rxjs/operators';

import * as infoboxActions from '../actions/infobox.actions';
import * as errorActions from '../actions/error.actions';
import InfoboxValidator from '../../validators/infobox.validator';
import { isCoverageDataValid, updateCoverages, checkPayerRules } from './helpers/coverage.helper';
import * as requestsActions from '../actions/requests.actions';

async function coverageValid(state) {
  const validator = new InfoboxValidator(state.infobox);
  const valid = await validator.payersValid();
  return !!valid;
}

export const validateCoveragesEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.autoSavePayerInfo.match),
    filter(() => isCoverageDataValid(store.value)),
    switchMap(() =>
      from$(coverageValid(store.value)).pipe(map(response => infoboxActions.autoSaveCoverages(response))),
    ),
  );

export const updateCoveragesEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.updateCoverages.match),
    switchMap(() => updateCoverages(store)),
    mergeMap(response =>
      concat$(
        of$(infoboxActions.updateCoveragesSuccess()),
        of$(infoboxActions.checkPayerRules()),
        of$(infoboxActions.setCoveragesDirty(false)),
        of$(errorActions.onError(response)),
      ),
    ),
    catchError(error => of$(errorActions.onUhandledError('coverages'))),
  );

export const checkPayerRulesEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [infoboxActions.checkPayerRules.match, requestsActions.updateRequestSuccess.match].some(match => match(action)),
    ),
    switchMap(() => checkPayerRules(store)),
    map(response => infoboxActions.checkPayerRulesResponse(response)),
  );
