import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../AmbGrid';
import Downshift from 'downshift';
import AmbCircularProgress from '../AmbCircularProgress';
import SuggestionsTextField from './SuggestionsTextField';
import SuggestionsOption from './SuggestionsOption';
import { theme } from '../../../theme';

export const handleSuggestionChange = (queryFn, selectFn, clearFn, stateFn) => (query, downshiftState) => {
  if (query === '' && downshiftState.type === Downshift.stateChangeTypes.changeInput) {
    downshiftState.clearSelection();
    clearFn();
  }

  if (query && downshiftState.type === Downshift.stateChangeTypes.changeInput) {
    queryFn(query);
  }

  if (query && downshiftState.type === Downshift.stateChangeTypes.clickItem) {
    selectFn(stateFn ? stateFn(downshiftState.selectedItem) : downshiftState.selectedItem);
  }

  if (
    query.inputValue &&
    (query.type === Downshift.stateChangeTypes.keyDownArrowUp ||
      query.type === Downshift.stateChangeTypes.keyDownArrowDown)
  ) {
    selectFn(stateFn ? stateFn(query.selectedItem) : query.selectedItem);
  }
};

function SuggestionsInput(props) {
  const {
    getItems,
    items,
    inputId,
    inputPlaceholder,
    required,
    selectedItem,
    onBlur,
    openOnClick,
    onInputClick,
    error,
    loading,
    name,
    helperText,
    autoFocus,
    autoComplete,
    disabled,
    subLabel,
    dataTestId,
    ...input
  } = props;
  const itemToString = i => (i ? i.name : '');
  const stateReducer = (state, changes) => {
    const highlightedItem = items[changes.highlightedIndex];
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownArrowUp:
      case Downshift.stateChangeTypes.keyDownArrowDown:
        return {
          ...changes,
          selectedItem: highlightedItem,
          inputValue: itemToString(highlightedItem),
        };
      default:
        return changes;
    }
  };

  const dropDownStyles = {
    position: 'relative',
    zIndex: theme.zIndex.modal,
    width: '100%',
    backgroundColor: '#FFF',
    paddingTop: 8,
    paddingBottom: 8,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  };

  return (
    <Downshift
      {...input}
      onStateChange={(changes, stateAndHelpers) => {
        if (
          changes.type === Downshift.stateChangeTypes.keyDownArrowUp ||
          changes.type === Downshift.stateChangeTypes.keyDownArrowDown
        ) {
          return input.onInputValueChange(
            {
              ...changes,
              inputValue: itemToString(changes.selectedItem),
            },
            stateAndHelpers,
          );
        }
        return null;
      }}
      itemToString={itemToString}
      stateReducer={stateReducer}
    >
      {({ getInputProps, getItemProps, isOpen, inputValue, highlightedIndex, openMenu }) => (
        <div style={{ minWidth: '100%' }}>
          <SuggestionsTextField
            error={error}
            disabled={disabled || null}
            helperText={helperText}
            subLabel={subLabel}
            onClick={
              onInputClick
                ? () => {
                    onInputClick();
                    openMenu();
                  }
                : null
            }
            {...getInputProps({
              placeholder: inputPlaceholder,
              id: inputId,
              autoFocus,
              required,
              onBlur,
              name,
              disabled,
              dataTestId,
            })}
          />
          {loading && (
            <div style={dropDownStyles}>
              <AmbGrid style={{ justifyContent: 'center' }}>
                <AmbCircularProgress size={23} />
              </AmbGrid>
            </div>
          )}
          {isOpen && !loading && items.length > 0 ? (
            <div style={dropDownStyles}>
              {items.map((suggestion, index) => (
                <SuggestionsOption
                  suggestion={suggestion}
                  index={index}
                  inputValue={inputValue}
                  key={`${suggestion.name}_${index}`}
                  itemProps={getItemProps({ item: suggestion })}
                  highlightedIndex={highlightedIndex}
                  showDivider={items[index + 1] && suggestion.typeof !== items[index + 1].typeof}
                />
              ))}
            </div>
          ) : null}
        </div>
      )}
    </Downshift>
  );
}

SuggestionsInput.propTypes = {
  getItems: PropTypes.func,
  inputId: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  loading: PropTypes.bool,
};

SuggestionsInput.defaultProps = {
  getItems: null,
  inputId: 'some-id',
  inputPlaceholder: '',
  loading: false,
};

export default SuggestionsInput;
