import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CloseIcon from '../common/icons/close';
import { drawerWidth, patientSearchTop, palette } from '../../theme';
import { formatDateWithoutTimezone } from '../../helpers/dateFormatter';
import TextWithLoader from '../common/TextWithLoader';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
`;

const StyledTD = styled.td`
  font-size: 12px;
  font-weight: 500;
  padding: 4px 56px 4px 24px;
  position: relative;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

const PatientRowTD = styled.td`
  font-size: 13px;
  padding: 4px 56px 4px 24px;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  color: #000;
  opacity: 0.87;
`;

class PatientSearchFrame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPatientInProgress: false,
    };
    this.root = null;
  }

  componentDidMount() {
    this.modal = document.createElement('div');
    document.body.appendChild(this.modal);
    this.renderModal(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.showMRNFields !== newProps.showMRNFields) {
      this.props.controlPatientModal(false);
      return;
    }
    this.renderModal(newProps);
  }

  componentWillUnmount() {
    // workaround to remove Warning: Attempted to synchronously unmount a root while React was already rendering. React cannot finish unmounting the root until the current render has completed, which may lead to a race condition.
    setTimeout(() => {
      if (this.root) {
        this.root.unmount();
      }
    }, 0);
    document.body.removeChild(this.modal);
  }

  getInsurancePayerByCoordinationType = (coverages, coordinationTypeId) => {
    const coverage = coverages.find(cov => cov.coordinationTypeId === coordinationTypeId);

    if (!coverage || !coverage.payer || !coverage.payer.entity) {
      return null;
    }

    return coverage.payer.entity.name;
  };

  getInsurancePayer = (coverages, coordinationTypeId) => {
    const payer = this.getInsurancePayerByCoordinationType(coverages, coordinationTypeId);

    if (!payer) {
      return <span>&mdash;</span>;
    }

    return payer;
  };

  getField = field => {
    if (field) {
      return field;
    }

    return <span>&mdash;</span>;
  };

  getPrimaryPayer = coverages => this.getInsurancePayer(coverages, window.ambulnzConfig.coordinationTypes.PRIMARY);

  getSecondaryPayer = coverages => this.getInsurancePayer(coverages, window.ambulnzConfig.coordinationTypes.SECONDARY);

  formatSsn = ssn => {
    if (!ssn) {
      return this.getField(ssn);
    }

    return this.getField(ssn.replace(/([0-9]{3})([0-9]{2})([0-9]{4})/, '$1-$2-$3'));
  };

  handleClick = (patient, props) => {
    this.setState(
      {
        selectPatientInProgress: true,
      },
      () => this.renderModal(this.props),
    );
    if (props.showMRNFields) {
      props.selectPatientFromExternal(patient);
    } else {
      props.selectPatient(patient);
    }
  };

  closeModal = props => {
    props.controlPatientModal(false);
  };

  renderModal(props) {
    const { patients, showMRNFields, showSSN } = props;
    if (!this.root) this.root = createRoot(this.modal);
    this.root.render(
      <div
        style={{
          position: 'absolute',
          left: drawerWidth,
          top: patientSearchTop,
          zIndex: 1,
          backgroundColor: '#fff',
          boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
        }}
      >
        <Container>
          {this.state.selectPatientInProgress && (
            <TextWithLoader text="Loading patient..." circleStyles={{ color: palette.secondary.main }} />
          )}
          <button
            onClick={() => this.closeModal(props)}
            tabIndex={0}
            type="button"
            style={{
              margin: 0,
              marginLeft: 'auto',
              width: 48,
              color: 'rgba(0, 0, 0, 0.54)',
              height: 48,
              padding: 0,
              fontSize: '1.5rem',
              textAlign: 'center',
              border: 0,
              cursor: 'pointer',
              display: 'inline-flex',
              outline: 'none',
              position: 'relative',
              alignItems: 'center',
              userSelect: 'none',
              verticalAlign: 'middle',
              justifyContent: 'center',
              MozAppearance: 'none',
              textDecoration: 'none',
              backgroundColor: 'transparent',
              WebkitAppearance: 'none',
              WebkitTapHighlightColor: 'transparent',
            }}
          >
            <CloseIcon style={{ width: 24, height: 24, fill: 'currentColor' }} />
          </button>
        </Container>
        <table
          style={{
            width: '100%',
            borderSpacing: 0,
            borderCollapse: 'collapse',
          }}
        >
          <thead>
            <tr
              style={{
                height: '35px',
                display: 'table',
                width: '100%',
                tableLayout: 'fixed',
                color: '#000',
                opacity: 0.54,
                verticalAlign: 'middle',
              }}
            >
              {showMRNFields && (
                <>
                  <StyledTD>MRN</StyledTD>
                  <StyledTD>Clinic</StyledTD>
                </>
              )}
              {showSSN && <StyledTD>SSN</StyledTD>}
              <StyledTD>First Name</StyledTD>
              <StyledTD>MI</StyledTD>
              <StyledTD>Last Name</StyledTD>
              <StyledTD>DOB</StyledTD>
              <StyledTD>Email</StyledTD>
              {!showMRNFields && (
                <>
                  <StyledTD style={{ paddingRight: 20 }}>Primary Insurance</StyledTD>
                  <StyledTD>Secondary Insurance</StyledTD>
                </>
              )}
            </tr>
          </thead>
          <tbody style={{ height: patients.length > 8 && 462, display: 'block', overflow: 'auto' }}>
            {patients.map((patient, i) => (
              <tr
                key={`${i}-${patient.demographics[0].id || patient.externalId}`}
                style={{
                  cursor: 'pointer',
                  display: 'table',
                  width: '100%',
                  tableLayout: 'fixed',
                  color: 'inherit',
                  height: 48,
                  verticalAlign: 'middle',
                }}
                onClick={() => this.handleClick(patient, props)}
              >
                {showMRNFields && (
                  <>
                    <PatientRowTD style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                      {patient.externalId}
                    </PatientRowTD>
                    {patient.clinic && <PatientRowTD>{patient.clinic.name}</PatientRowTD>}
                  </>
                )}
                {showSSN && <PatientRowTD>{this.formatSsn(patient.demographics[0].ssn)}</PatientRowTD>}
                <PatientRowTD>{patient.demographics[0].firstName}</PatientRowTD>
                <PatientRowTD>{this.getField(patient.demographics[0].middleName)}</PatientRowTD>
                <PatientRowTD>{patient.demographics[0].lastName}</PatientRowTD>
                <PatientRowTD>{formatDateWithoutTimezone(patient.demographics[0].dob, 'MM/DD/YYYY')}</PatientRowTD>
                <PatientRowTD>{patient.demographics[0].email}</PatientRowTD>
                {!showMRNFields && (
                  <>
                    <PatientRowTD>{this.getPrimaryPayer(patient.coverages)}</PatientRowTD>
                    <PatientRowTD>{this.getSecondaryPayer(patient.coverages)}</PatientRowTD>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {!patients.length && (
          <div style={{ textAlign: 'center', height: 36, lineHeight: '36px', opacity: 0.5 }}>
            No results have been found.
          </div>
        )}
      </div>,
    );
  }

  render() {
    return null;
  }
}

PatientSearchFrame.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectPatient: PropTypes.func.isRequired,
  controlPatientModal: PropTypes.func.isRequired,
};

PatientSearchFrame.defaultProps = {};

export default PatientSearchFrame;
