import React, { useEffect, useState, useRef } from 'react';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import ServiceLevelIcon from '../common/icons/ServiceLevel';
import * as Enums from '../../enums';
import ConfirmationDialog from '../common/ConfirmationDialog';
import CareProvidedAtReceivingFacility from './CareProvidedAtReceivingFacility';
import { SectionHeader, CareProvidedContainer } from './elements';
import Equipment from './sections/Equipment';
import Personnel from './sections/Personnel';
import Mobility from './sections/Mobility';
import Patient from './sections/Patient';
import Questions from './Questions';
import Criticality from './sections/Criticality';
import CallType from './sections/CallType';
import Service from './Service';
import RequirementWarning from './sections/RequirementWarning';
import AmbGrid from 'components/common/AmbGrid';
import { theme } from '../../theme';

const ServiceLevelFrame = props => {
  const [serviceLevelFrameState, setServiceLevelFrameState] = useState({
    expanded: props.expanded,
    hasChildErrors: false,
  });

  const prevProps = useRef(props);

  useEffect(
    () => {
      if (props.shouldCollapse) {
        setServiceLevelFrameState(prevState => ({
          ...prevState,
          expanded: false,
        }));
      }

      if (prevProps.current.expanded !== props.expanded && props.expanded && !serviceLevelFrameState.expanded) {
        setServiceLevelFrameState(prevState => ({ ...prevState, expanded: true }));
      }

      if (props.triggerValidation !== prevProps.current.triggerValidation && props.triggerValidation) {
        props.validateAll(props);
      }

      if (props.serviceId !== prevProps.current.serviceId) {
        props.validateAll(props);
      }

      if (props.patientWeight !== prevProps.current.patientWeight) {
        props.validateAll(props);
      }

      if (props.patientHeight !== prevProps.current.patientHeight) {
        props.validateAll(props);
      }

      if (props.isBariatric !== prevProps.current.isBariatric) {
        props.validateAll(props);
      }

      props.setSaveErrorMessage(props.errors);
      prevProps.current = props;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.shouldCollapse,
      props.expanded,
      props.triggerValidation,
      props.errors,
      props.serviceId,
      prevProps.current.shouldCollapse,
      prevProps.current.expanded,
      prevProps.current.triggerValidation,
      prevProps.current.errors,
      prevProps.current.serviceId,
    ],
  );

  const togglePanel = () => {
    props.validateAll(props);
    setServiceLevelFrameState(prevState => ({ ...prevState, expanded: !serviceLevelFrameState.expanded }));
  };

  const handleChildValidationError = hasError =>
    setServiceLevelFrameState(prevState => ({ ...prevState, hasChildErrors: hasError }));

  const { panelTitleDesc, serviceLevelDescription } = props;

  const { expanded } = serviceLevelFrameState;

  const lightsAndSirensStyles = {
    padding: 'unset',
    marginLeft: 5,
    whiteSpace: 'nowrap',
  };

  const lightsAndSirensActive = props.isLightsAndSirens ? { color: theme.palette.primary.alertText } : {};

  const lightsAndSirensStylesObj = {
    ...lightsAndSirensStyles,
    ...lightsAndSirensActive,
  };
  const lightsAndSirensDesc = ' + Lights and Sirens';

  const description = (
    <>
      <Translation>{t => `Service Level: ${t(serviceLevelDescription)}`}</Translation>
      <span>{props.isLightsAndSirens && <span style={lightsAndSirensStylesObj}>{lightsAndSirensDesc}</span>}</span>
    </>
  );

  const title = props.showQuestions ? 'Service Type' : description;
  const { callTypeId } = props;
  const callTypeSelected = callTypeId !== Enums.CallType.DEFAULT;

  const enabledEdit = props.disabled && props.enableTransportReasonsDispatcherEdit;
  const disabledEdit = !(!props.disabled || enabledEdit);

  return (
    <AmbExpansionPanel
      icon={<ServiceLevelIcon style={{ height: 19, width: 16, fill: theme.palette.icon.primary }} />}
      id="ServiceLevelPanel"
      title={title}
      titleDesc={props.showQuestions ? '' : panelTitleDesc}
      expanded={expanded}
      onTogglePanel={togglePanel}
      error={props.hasErrors || serviceLevelFrameState.hasChildErrors}
    >
      <AmbGrid style={{ paddingTop: 23, flexDirection: 'column' }}>
        <AmbGrid>
          <AmbGrid style={{ flexDirection: 'column' }}>
            {callTypeSelected ? (
              <SectionHeader>Transport Reason</SectionHeader>
            ) : (
              <SectionHeader>What is the reason for transport?</SectionHeader>
            )}
            <AmbGrid style={{ flexDirection: 'column' }}>
              <AmbGrid>
                <CallType
                  callTypeOptions={props.callTypeOptions}
                  callTypeId={props.callTypeId}
                  disabled={disabledEdit}
                  handleCallTypeChange={props.handleCallTypeChange}
                  hasErrors={props.hasErrors}
                  callTypeSelected={callTypeSelected}
                />

                {!props.showQuestions && (
                  <Criticality
                    isLightsAndSirensEnabled={props.isLightsAndSirensEnabled}
                    disabled={disabledEdit}
                    isLightsAndSirens={props.isLightsAndSirens}
                    lightsAndSirensChange={props.lightsAndSirensChange}
                  />
                )}
              </AmbGrid>
              {props.showCareProvidedAtReceivingFacility && (
                <CareProvidedContainer>
                  <CareProvidedAtReceivingFacility onValidationError={handleChildValidationError} />
                </CareProvidedContainer>
              )}

              {props.showQuestions && <Questions hasErrors={props.hasErrors} />}
            </AmbGrid>
          </AmbGrid>
        </AmbGrid>
        {!props.showQuestions && (
          <>
            <AmbGrid style={{ marginLeft: 1, marginTop: 7, marginBottom: 6, flexWrap: 'wrap' }}>
              <Mobility
                mobilityId={props.mobilityId}
                handleMobilityChange={props.handleMobilityChange}
                mobilities={props.mobilities}
                walkDisabled={props.walkDisabled}
                wheelchairDisabled={props.wheelchairDisabled}
                bedboundDisabled={props.bedboundDisabled}
                svgStyles={props.svgStyles}
              />
              <Patient
                patientTypeList={props.patientTypeList}
                patientTypeId={props.patientTypeId}
                handlePatientTypeChange={props.handlePatientTypeChange}
                disabledPatientTypeIds={props.disabledPatientTypeIds}
                svgStyles={props.svgStyles}
                weightHeightRequired={props.weightHeightRequired}
                handleWeightChange={props.handleWeightChange}
                handleHeightChange={props.handleHeightChange}
                handleWeightBlur={props.handleWeightBlur}
                patientWeight={props.patientWeight}
                patientHeight={props.patientHeight}
                patientHeightInches={props.patientHeightInches}
                errors={props.errors}
              />
            </AmbGrid>
            {props.showEquipment && (
              <>
                <Equipment
                  equipment={props.equipment}
                  handleEquipmentChange={props.handleEquipmentChange}
                  disabled={props.disabled || null}
                  equipmentAsService={props.equipmentAsService}
                  serviceTypeIds={props.serviceTypeIds}
                  handleEquipmentAsServiceTypeChange={props.handleEquipmentAsServiceTypeChange}
                  therapistEquipmentIds={props.therapistEquipmentIds}
                  bariatricDisabled={props.bariatricDisabled}
                  bariatricEquipmentId={props.bariatricEquipmentId}
                />
                <Personnel
                  handlePersonnelChange={props.handlePersonnelChange}
                  requirements={props.requirements}
                  requirementIds={props.requirementIds}
                  forcedCapabilities={props.forcedCapabilities}
                  disabled={props.disabled || null}
                  showRequirementWarning={props.showRequirementWarning}
                  setRequirementWarning={props.setRequirementWarning}
                />
                {props.showRequirementWarning && (
                  <RequirementWarning
                    equipment={props.equipment}
                    requirements={props.requirements}
                    forcedCapabilities={props.forcedCapabilities}
                  />
                )}
              </>
            )}
            <Service />
          </>
        )}
      </AmbGrid>
      <ConfirmationDialog
        open={props.ETADialogOpen}
        onClose={props.customCloseNewETADialog}
        title={props.ETADialogTitle}
        cancel
      >
        <div>{props.ETADialogBody}</div>
      </ConfirmationDialog>
    </AmbExpansionPanel>
  );
};

ServiceLevelFrame.propTypes = {
  answers: PropTypes.shape().isRequired,
  callTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lightsAndSirensChange: PropTypes.func.isRequired,
  handleCallTypeChange: PropTypes.func.isRequired,
  handleMobilityChange: PropTypes.func.isRequired,
  handlePatientTypeChange: PropTypes.func.isRequired,
  handlePersonnelChange: PropTypes.func.isRequired,
  handleEquipmentAsServiceTypeChange: PropTypes.func.isRequired,
  handleEquipmentChange: PropTypes.func.isRequired,
  panelTitleDesc: PropTypes.string.isRequired,
  shouldCollapse: PropTypes.bool.isRequired,
  isLightsAndSirens: PropTypes.bool.isRequired,
  isLightsAndSirensEnabled: PropTypes.bool.isRequired,
  showQuestions: PropTypes.bool.isRequired,
  showEquipment: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  mobilities: PropTypes.shape().isRequired,
  serviceTypes: PropTypes.shape().isRequired,
  serviceLevelMap: PropTypes.shape().isRequired,
  equipment: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  equipmentAsService: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  personnel: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  callTypeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  serviceTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  serviceId: PropTypes.number,
  mobilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  patientTypeId: PropTypes.number.isRequired,
  patientTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showCareProvidedAtReceivingFacility: PropTypes.bool.isRequired,
  ETADialogOpen: PropTypes.bool.isRequired,
  ETADialogTitle: PropTypes.string.isRequired,
  ETADialogBody: PropTypes.string.isRequired,
  customCloseNewETADialog: PropTypes.func.isRequired,
  therapistEquipmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  walkDisabled: PropTypes.bool.isRequired,
  wheelchairDisabled: PropTypes.bool.isRequired,
  bedboundDisabled: PropTypes.bool.isRequired,
  disabledPatientTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  triggerValidation: PropTypes.bool.isRequired,
  errors: PropTypes.shape().isRequired,
  setSaveErrorMessage: PropTypes.func.isRequired,
  validateAll: PropTypes.func.isRequired,
  hasErrors: PropTypes.bool.isRequired,
  patientTypeList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requirementIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  forcedCapabilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setRequirementWarning: PropTypes.func.isRequired,
  showRequirementWarning: PropTypes.bool.isRequired,
  serviceLevelDescription: PropTypes.string.isRequired,
  enableTransportReasonsDispatcherEdit: PropTypes.bool.isRequired,
  svgStyles: PropTypes.shape().isRequired,
};

ServiceLevelFrame.defaultProps = {
  serviceId: undefined,
  mobilityId: null,
};

export default ServiceLevelFrame;
