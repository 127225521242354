import React from 'react';

export default function (props) {
  return (
    <svg
      className={props.className}
      style={props.style}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      fill="#fff"
    >
      <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
    </svg>
  );
}
