import React from 'react';
import AmbTooltip from './AmbTooltip/AmbTooltip';

const withToolTip = (name, title) => WrappedComponent => props => (
  <AmbTooltip aria-label={`${name}-tooltip`} name={`${name}-tooltip`} title={title} placement="top">
    <span>
      <WrappedComponent {...props} />
    </span>
  </AmbTooltip>
);

export default withToolTip;
