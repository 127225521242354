import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g>
        <g>
          <circle cx="12" cy="5" r="2" />
          <path
            d="M18.1,4.8c-0.4-0.4-1-0.4-1.4,0L14.5,7C13.9,7.6,13,8,12,8s-1.9-0.4-2.5-1L7.4,4.8
				c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.6,3.6V14v6c0,0.6,0.4,1,1,1s1-0.4,1-1v-6H12h0.5v6c0,0.6,0.4,1,1,1s1-0.4,1-1v-6
				V9.8l3.6-3.6C18.5,5.8,18.5,5.2,18.1,4.8z"
          />
        </g>
      </g>
    </svg>
  );
}
