import { createReducer } from 'redux-act';
import * as action from '../actions/error.actions';
import errorMessage from '../../schemas/errorMessage.schema';

export const initialState = {
  errorMessage: errorMessage.parse({}),
};

const errorReducer = createReducer(
  {
    [action.onError]: (state, payload) => {
      return {
        ...state,
        errorMessage: state.errorMessage.message
          ? state.errorMessage
          : errorMessage.parse(payload ? payload.error : {}),
      };
    },
    [action.clearError]: state => ({
      ...state,
      errorMessage: errorMessage.parse({}),
    }),
    [action.onUhandledError]: (state, payload) => ({
      ...state,
      errorMessage: errorMessage.parse({
        name: `Failed to update ${payload}`,
        message: 'Please contact support',
        status: 500,
      }),
    }),
    [action.setHarError]: (state, payload) => ({
      ...state,
      errorHarJson: payload,
    }),
  },
  initialState,
);

export default errorReducer;
