import { createAction } from '@reduxjs/toolkit';

export const openProposedETAsModal = createAction('[ProposedETAModal] openProposedETAsModal');

export const reducer = {
  [openProposedETAsModal]: (state, payload) => ({
    ...state,
    open: payload,
  }),
};
