import { switchMap, map, filter } from 'rxjs/operators';

import { asyncCancelRequest, asyncCancelRequestResponse } from './actions';
import { fetchRequestReasonTypes, fetchRequestReasonTypesResponse } from './fetchRequestReasonTypes';
import { COMMENTS } from '../../../../enums';
import { cancelRequest } from '../../../../redux/epics/helpers/request.helper';
import { updateComments } from '../../../../redux/epics/helpers/comment.helper';
import RequestCancelReasonTypes from '../../../../services/requestCancelReasonTypes.service';

export const fetchRequestReasonTypesEpic = action$ =>
  action$.pipe(
    filter(fetchRequestReasonTypes.match),
    switchMap(() => RequestCancelReasonTypes.fetchCancelReasonTypes()),
    map(payload => fetchRequestReasonTypesResponse(payload)),
  );

export const cancelRequestEpic = (action$, store) =>
  action$.pipe(
    filter(asyncCancelRequest.match),
    switchMap(() => updateComments(store, store.value.cancelRequest.details, COMMENTS.TYPES.CANCEL)),
    switchMap(() => cancelRequest(store, store.value.cancelRequest.cancelReasonTypeId)),
    map(payload => asyncCancelRequestResponse(payload)),
  );
