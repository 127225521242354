import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import Ambulnz from '../../common/icons/Ambulnz';
import Uber from '../../common/icons/Uber';
import AmbGrid from 'components/common/AmbGrid';
import { styled } from '@mui/system';
import { theme } from '../../../theme';

const StyledButtonAccept = styled(Button)({
  borderRadius: 4,
  boxShadow: 'rgba(0, 64, 82, 0.35) 0px 1px 4px 0px',
});

const StyledButtonReject = styled(Button)({
  borderRadius: 4,
  marginRight: 8,
});

const Logo = styled('img')({
  width: 72,
  height: 24,
  objectFit: 'contain',
});

function CardHeader({
  conflict,
  handleOpenConflictDialog,
  acceptETAClick,
  rejectETA,
  multipleEtas,
  etaId,
  eta,
  shiftMsgMap,
  userType,
  type,
  attachment,
  name,
  isCar,
  noAutoEtaRequest,
  candidateWarning,
}) {
  const acceptBtn = (
    <StyledButtonAccept
      id="AcceptETA"
      variant="contained"
      color="primary"
      onClick={() => (conflict ? handleOpenConflictDialog() : acceptETAClick({ etaId }))}
    >
      ACCEPT
    </StyledButtonAccept>
  );

  const rejectBtn = (
    <StyledButtonReject id="RejectETA" color="primary" onClick={() => rejectETA({ etaIds: [etaId] })}>
      REJECT
    </StyledButtonReject>
  );

  const providerLogo = isCar ? <Uber style={{ width: 72, height: 14 }} /> : <Ambulnz style={{ width: 72 }} />;

  return (
    <>
      <AmbGrid
        style={{
          padding: '8px 16px',
          backgroundColor: theme.palette.grey.semiLight2,
          gap: '8px',
          justifyContent: 'space-between',
          width: '100%',
        }}
        data-testid={`header-${etaId}`}
      >
        <AmbGrid>
          <AmbGrid style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            {!noAutoEtaRequest && (
              <AmbGrid>
                {attachment.downloadUrl && <Logo src={attachment.downloadUrl} alt="" />}
                {!attachment.downloadUrl && name}
              </AmbGrid>
            )}

            {noAutoEtaRequest && <AmbGrid>{providerLogo}</AmbGrid>}
          </AmbGrid>
        </AmbGrid>
        {multipleEtas && (
          <AmbGrid>
            <AmbGrid style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              {eta && (
                <AmbGrid
                  style={{
                    flexShrink: 1,
                    color: theme.palette.text.warning,
                    fontSize: 12,
                    paddingLeft: 16,
                    paddingRight: 8,
                    lineHeight: 1.3,
                  }}
                >
                  {candidateWarning && <ReactMarkdown children={candidateWarning.warning} />}
                  {shiftMsgMap[userType] && <ReactMarkdown children={shiftMsgMap[userType][type]} />}
                </AmbGrid>
              )}
              {!eta && (
                <AmbGrid
                  style={{
                    flexShrink: 1,
                    color: theme.palette.text.tertiary,
                    fontSize: 14,
                    paddingLeft: 16,
                    lineHeight: '16px',
                    flexBasis: '65%',
                  }}
                >
                  <ReactMarkdown children={shiftMsgMap.noEta[type]} />
                </AmbGrid>
              )}
            </AmbGrid>
          </AmbGrid>
        )}
        {!multipleEtas && eta && candidateWarning && (
          <AmbGrid>
            <AmbGrid style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <AmbGrid
                style={{
                  flexShrink: 1,
                  color: theme.palette.text.warning,
                  fontSize: 12,
                  paddingLeft: 16,
                  paddingRight: 8,
                  lineHeight: 1.3,
                }}
              >
                {candidateWarning && <ReactMarkdown children={candidateWarning.warning} />}
              </AmbGrid>
            </AmbGrid>
          </AmbGrid>
        )}
        <AmbGrid>
          {eta && (
            <AmbGrid style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
              {!multipleEtas && !window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && (
                <AmbGrid>{rejectBtn}</AmbGrid>
              )}
              <AmbGrid>{acceptBtn}</AmbGrid>
            </AmbGrid>
          )}
        </AmbGrid>
      </AmbGrid>
    </>
  );
}

CardHeader.propTypes = {
  conflict: PropTypes.shape(),
  shiftMsgMap: PropTypes.shape().isRequired,
  multipleEtas: PropTypes.bool.isRequired,
  noAutoEtaRequest: PropTypes.bool.isRequired,
  eta: PropTypes.string,
  handleOpenConflictDialog: PropTypes.func.isRequired,
  rejectETA: PropTypes.func.isRequired,
  acceptETAClick: PropTypes.func.isRequired,
  userType: PropTypes.number.isRequired,
  etaId: PropTypes.number,
  type: PropTypes.string.isRequired,
  isCar: PropTypes.bool.isRequired,
  name: PropTypes.string,
  attachment: PropTypes.shape(),
  candidateWarning: PropTypes.oneOfType([PropTypes.shape().isRequired, PropTypes.oneOf([undefined]).isRequired]),
};

CardHeader.defaultProps = {
  conflict: null,
  eta: null,
  etaId: 1,
  attachment: {},
};

export default CardHeader;
