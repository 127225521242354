import React from 'react';
import Alarm from '../common/icons/alarm';
import styled from '@emotion/styled';
import Uber from '../common/icons/Uber';
import SummaryItem from './SummaryItem';
import AppointmentSummary from './AppointmentSummary';

const StyledListItem = styled.li`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 12px 16px 0 16px;
`;

const UberSummary = props => {
  const {
    pickupTime,
    scheduleState,
    active,
    appointmentTime,
    etaTime,
    etaDate,
    dropoffTime,
    dropoffDate,
    appointmentDate,
    scheduledMessage,
    request,
  } = props;
  const appmtSummary = appointmentTime && (
    <AppointmentSummary
      {...props}
      etaOnly
      etaTime={etaTime}
      etaDate={etaDate}
      dropoffTime={dropoffTime}
      dropoffDate={dropoffDate}
      appointmentDate={appointmentDate}
    />
  );

  return (
    <>
      <StyledListItem>
        <Uber style={{ width: 72, height: 20 }} />
      </StyledListItem>
      <SummaryItem
        title={request.isScheduled ? scheduledMessage : scheduleState}
        message={`ETA ${pickupTime}`}
        Icon={Alarm}
        active={active}
      >
        {appmtSummary}
      </SummaryItem>
    </>
  );
};

export default UberSummary;
