import moment from 'moment';
import { ETA_TYPES } from '../enums';

export const isTimeBeyondMaxDate = state => moment(state.eta.requestedArrivalTime) > state.formFlow.maxDate;

const isNoAutoEtaRequestByReqAgencyGroup = state => {
  const curbsideId =
    state.service.serviceTypeDictionary.hasOwnProperty('Curbside') &&
    state.service.serviceTypeDictionary['Curbside'].id;
  const isUberRequest = state.service.serviceTypeIds.includes(curbsideId);
  return state.user.model.isRequester && !isUberRequest && state.agency.requesterAgencyGroup.displayEta === false;
};

const isNoAutoEtaRequestByTimeConstraint = state =>
  isTimeBeyondMaxDate(state) && state.eta.etaType === ETA_TYPES.PICKUP && !state.requests.isNonTransport;

export const isNoAutoEtaRequest = state =>
  isNoAutoEtaRequestByReqAgencyGroup(state) || isNoAutoEtaRequestByTimeConstraint(state);
