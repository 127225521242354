import ApiService from './api.service';

class PayerTypeService {
  static async fetchPayerTypes() {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/PayerTypes`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default PayerTypeService;
