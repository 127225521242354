import { createAction } from '@reduxjs/toolkit';

export const checkEligibility = createAction('[Eligibility] checkEligibility');
export const checkEligibilitySuccess = createAction('[Eligibility] checkEligibilitySuccess');
export const setLoading = createAction('[Eligibility] setLoading');
export const setDisabled = createAction('[Eligibility] setDisabled');
export const setCreating = createAction('[Eligibility] setCreating');
export const resetButtons = createAction('[Eligibility] resetButtons');
export const initEligibility = createAction('[Eligibility] initEligibility');
export const setInfo = createAction('[Eligibility] setInfo');
export const setHTML = createAction('[Eligibility] setHTML');
export const socketResponseReceived = createAction('[Eligibility] socketResponseReceived');
export const error = createAction('[Eligibility] error');
export const closeErrorModal = createAction('[Eligibility] closeErrorModal');
export const closeFrame = createAction('[Eligibility] closeFrame');
export const loadPortalInfo = createAction('[Eligibility] loadPortalInfo');
export const portalInfoLoaded = createAction('[Eligibility] portalInfoLoaded');
export const setPortalInfo = createAction('[Eligibility] setPortalInfo');
export const expandPortalInfo = createAction('[Eligibility] expandPortalInfo');
