import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { serviceLevelDisabled } from '../../../flags/disabled.flag';
import CareProvidedAtReceivingFacility from './validations';
import * as actions from './actions';
import { setSaveErrorMessage } from '../../../redux/actions/infobox.actions';
import { setLightsAndSirens } from '../../../redux/actions/requests.actions';
import {
  setCallType,
  setQuestionMode,
  mobilityChange,
  serviceTypeChange,
} from '../../../redux/actions/service.actions';
import { CallType } from '../../../enums';

const CareProvidedAtReceivingFacilityContainer = props => {
  const [check, setCheck] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const change = checked => {
    props.careProvidedAtChbChange(checked);

    const callTypeSelected = props.callTypeId !== CallType.DEFAULT;

    if (checked && !callTypeSelected) {
      props.setCallType(window.ambulnzConfig.callTypes.EMERGENCY);
      props.setQuestionMode(false);
      props.mobilityChange(props.mobilities.bedbound);
      props.serviceTypeChange(props.serviceTypes.BLS);
    }
  };

  const changeRequiresNewETA = checked =>
    !checked &&
    props.changeRequiresNewETA &&
    props.callTypeId !== window.ambulnzConfig.callTypes.EMERGENCY &&
    props.isScheduled;

  const handleChange = checked => {
    if (changeRequiresNewETA(checked)) {
      setCheck(checked);
      setDialogOpen(true);
      return;
    }
    change(checked);
  };

  const handleDialogClose = ok => {
    if (ok) {
      change(check);
      props.loseETA();
    }
    setDialogOpen(false);
  };

  return (
    <CareProvidedAtReceivingFacility
      handleChange={handleChange}
      handleDialogClose={handleDialogClose}
      dialogOpen={dialogOpen}
      {...props}
    />
  );
};

function mapStateToProps(state) {
  return {
    show: window.ambulnzConfig.userSettings.isBillingEnabled,
    checked: state.requests.careProvidedAtReceivingFacility,
    description: state.requests.careProvidedAtReceivingFacilityDescription,
    disabled: serviceLevelDisabled(state),
    triggerValidation: state.formFlow.triggerValidation,
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    callTypeId: state.service.callTypeId,
    isScheduled: state.requests.request.isScheduled,
    mobilities: state.service.mobilities,
    serviceTypes: state.service.serviceTypes,
  };
}

CareProvidedAtReceivingFacilityContainer.propTypes = {
  careProvidedAtChbChange: PropTypes.func.isRequired,
  setCallType: PropTypes.func.isRequired,
  setQuestionMode: PropTypes.func.isRequired,
  setLightsAndSirens: PropTypes.func.isRequired,
  loseETA: PropTypes.func.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  callTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isScheduled: PropTypes.bool.isRequired,
  mobilityChange: PropTypes.func.isRequired,
  serviceTypeChange: PropTypes.func.isRequired,
  mobilities: PropTypes.shape().isRequired,
  serviceTypes: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps, {
  ...actions,
  setSaveErrorMessage,
  setCallType,
  setQuestionMode,
  setLightsAndSirens,
  mobilityChange,
  serviceTypeChange,
})(CareProvidedAtReceivingFacilityContainer);
