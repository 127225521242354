import '@ambulnz/amb-header';
import { Drawer, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { drawerWidth, theme, toolbarHeight } from '../../theme';
import MapWidget from '../MapWidget';
import Timeline from '../Timeline';

const AppFrame = props => {
  const isSmallScreen = useMediaQuery('(max-width:960px)');

  return (
    <div
      style={{
        width: '100%',
        zIndex: 1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: window.ambulnzConfig.isAnExternalApp ? 0 : toolbarHeight,
      }}
    >
      {window.ambulnzConfig.isAnExternalApp ? null : (
        <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 100 }}>
          <amb-header
            phone={props.phone}
            theme={window.ambulnzConfig.constants.HEADER_THEME}
            user={JSON.stringify(props.user)}
            navbar-enabled={window.ambulnzConfig.navbarEnabled}
            mobile-health-enabled={window.ambulnzConfig.constants.MOBILE_HEALTH_SERVICE_ENABLED}
          />
        </div>
      )}
      <div style={{ display: 'flex', width: '100%', height: '100%' }}>
        <Drawer
          variant="permanent"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              position: isSmallScreen ? 'fixed' : 'static',
              top: isSmallScreen ? 0 : toolbarHeight,
              left: 0,
            },
          }}
        >
          {props.children}
        </Drawer>
        <div style={{ backgroundColor: theme.palette.background.default, width: '100%' }}>
          <Timeline />
          <MapWidget />
        </div>
      </div>
    </div>
  );
};

AppFrame.propTypes = {
  phone: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default AppFrame;
