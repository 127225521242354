import React from 'react';
import MoreVert from '../../common/icons/morevert';
import { MenuList, Popover } from '@mui/material';

class SummaryMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        <MoreVert
          aria-owns={open ? 'menu-summary' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          style={{ cursor: 'pointer', marginTop: 1 }}
          id="SummaryMenuButton"
        />
        <Popover
          id="menu-appbar"
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: -30,
            horizontal: 125,
          }}
          open={open}
          onClose={this.handleClose}
          marginThreshold={5}
          PaperProps={{
            style: {
              padding: '5px 0',
            },
          }}
        >
          <MenuList>{this.props.children}</MenuList>
        </Popover>
      </>
    );
  }
}

export default SummaryMenu;
