import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar } from '@mui/material';
import * as toastActions from '../../redux/actions/toast.actions';

const ToastContainer = props => {
  const handleClose = value => {
    if (value) {
      props.clearToast();
    }
  };

  const { toastMessage } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={toastMessage.open}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      sx={{ '& .MuiSnackbarContent-message': { width: '100%', textAlign: 'center' } }}
      message={
        <span id="message-id" style={{ fontSize: '1rem' }}>
          {toastMessage.message}
        </span>
      }
    />
  );
};

ToastContainer.propTypes = {
  toastMessage: PropTypes.shape().isRequired,
  clearToast: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    toastMessage: state.toast.toastMessage,
  };
}

export default connect(mapStateToProps, { ...toastActions })(ToastContainer);
