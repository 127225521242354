import React from 'react';
import { connect } from 'react-redux';
import RequestShare from './RequestShare.frame';
import { addEmailRecipient } from './redux/addEmailRecipient';
import { addPhoneRecipient } from './redux/addPhoneRecipient';
import { resetRecipients } from './redux/resetRecipients';
import { requestShareCollapse } from '../../flags/collapse.flag';
import { deleteEmailRecipient } from './redux/deleteEmailRecipient';
import { deletePhoneRecipient } from './redux/deletePhoneRecipient';
import { formatPhone } from './RequestShare.formatter';
import { requestShareDisabled } from '../../flags/disabled.flag';

const RequestShareContainer = props => <RequestShare {...props} />;

const mapActionsToProps = {
  addEmailRecipient,
  addPhoneRecipient,
  deleteEmailRecipient,
  deletePhoneRecipient,
  resetRecipients,
};

function formatTitle(state) {
  return state.requestShare.emailRecipients.length || state.requestShare.phoneRecipients.length
    ? `${state.requestShare.emailRecipients
        .concat(state.requestShare.phoneRecipients.map(p => formatPhone(p)))
        .join(', ')}`
    : 'Sharing';
}

function mapStateToProps(state) {
  return {
    emailRecipients: state.requestShare.emailRecipients,
    phoneRecipients: state.requestShare.phoneRecipients,
    title: formatTitle(state),
    shouldCollapse: requestShareCollapse(state),
    disabled: requestShareDisabled(state),
  };
}

export default connect(mapStateToProps, mapActionsToProps)(RequestShareContainer);
