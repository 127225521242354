import { get } from 'lodash';
import { useReducer } from 'react';

function getFirstStepStatus(hasPriorAuthorization, hasMedicare, medicareSuboption, requireMedicareQuestions) {
  if (hasPriorAuthorization === 'Yes') {
    return 'Confirm';
  }

  if (hasMedicare === 'Yes' && requireMedicareQuestions) {
    return medicareSuboption === 'more than 100 days' ? 'Continue' : 'Confirm';
  }

  if (hasMedicare === 'Medicaid' || hasMedicare === 'Yes') {
    return 'Continue';
  }

  return 'Confirm';
}

export const initialState = {
  activeStep: 0,
  nextStep: 0,
  hasPriorAuthorization: 'none',
  hasMedicare: 'none',
  medicareSuboption: 'none',
  status: 'Continue',
  canProceed: new Set(),
  expandPayer: false,
  expandServiceLevelSection: false,
  payer: {},
  questions: [],
  questionsChecked: new Set(),
  conditionsWeight: 0,
  whoWillCover: 'none',
};

export default function useSection({ requestingAgencyPayer, requireMedicareQuestions, request }) {
  const [section, dispatch] = useReducer((state, action) => {
    let expandPayer;
    let payer;
    let nextStep;
    let status;
    let expandServiceLevelSection;
    let activeStepSet;

    const canProceed = can => {
      const set = new Set(state.canProceed.values());
      if (can) {
        set.add(state.activeStep);
      } else {
        set.delete(state.activeStep);
      }
      return set;
    };

    switch (action.type) {
      case 'HAS_PRIOR_AUTHORIZATION':
        return {
          ...state,
          hasPriorAuthorization: action.hasPriorAuthorization,
          status: getFirstStepStatus(
            action.hasPriorAuthorization,
            state.hasMedicare,
            state.medicareSuboption,
            requireMedicareQuestions,
          ),
        };
      case 'HAS_MEDICARE':
        nextStep = 1;
        switch (action.hasMedicare) {
          case 'Yes':
            expandPayer = true;
            payer = {
              payerId: window.ambulnzConfig.constants.MEDICARE_PAYER_ID,
              coordinationTypeId: window.ambulnzConfig.coordinationTypes.PRIMARY,
            };
            activeStepSet = canProceed(!requireMedicareQuestions);
            break;
          case 'Medicaid':
            expandPayer = true;
            payer = {
              payerId: window.ambulnzConfig.constants.MEDICAID_PAYER_ID,
              coordinationTypeId: window.ambulnzConfig.coordinationTypes.PRIMARY,
            };
            nextStep = 2;
            activeStepSet = canProceed(state.hasPriorAuthorization !== 'none');
            break;
          case 'commercial insurance':
            expandPayer = false;
            payer = {};
            activeStepSet = canProceed(state.hasPriorAuthorization !== 'none');
            break;
          case 'requesting agency covering':
            expandPayer = true;
            payer = {
              payerId: get(requestingAgencyPayer, 'payer.id', null),
              coordinationTypeId: window.ambulnzConfig.coordinationTypes.PRIMARY,
            };
            activeStepSet = canProceed(state.hasPriorAuthorization !== 'none');
            break;
          case 'self pay':
            expandPayer = true;
            payer = {
              payerId: window.ambulnzConfig.constants.BILL_PATIENT_PAYER_ID,
              coordinationTypeId: window.ambulnzConfig.coordinationTypes.PRIMARY,
            };
            activeStepSet = canProceed(state.hasPriorAuthorization !== 'none');
            break;
          default:
        }
        return {
          ...state,
          hasMedicare: action.hasMedicare,
          expandPayer,
          payer,
          canProceed: activeStepSet,
          status: getFirstStepStatus(
            state.hasPriorAuthorization,
            action.hasMedicare,
            state.medicareSuboption,
            requireMedicareQuestions,
          ),
          nextStep,
        };
      case 'MEDICARE_SUBOPTION':
        let coordinationTypeId = window.ambulnzConfig.coordinationTypes.PRIMARY;
        let findFacilityPayer = false;
        switch (action.medicareSuboption) {
          case 'less than 100 days':
            coordinationTypeId = window.ambulnzConfig.coordinationTypes.SECONDARY;
            findFacilityPayer = true;
            break;
          case 'more than 100 days':
            break;
          case 'date is unknown':
            coordinationTypeId = window.ambulnzConfig.coordinationTypes.SECONDARY;
            findFacilityPayer = true;
            break;
          default:
            break;
        }
        return {
          ...state,
          medicareSuboption: action.medicareSuboption,
          expandPayer: true,
          payer: {
            payerId: window.ambulnzConfig.constants.MEDICARE_PAYER_ID,
            coordinationTypeId,
            findFacilityPayer,
          },
          canProceed: canProceed(true),
          status: getFirstStepStatus(
            state.hasPriorAuthorization,
            state.hasMedicare,
            action.medicareSuboption,
            requireMedicareQuestions,
          ),
          nextStep: 1,
        };
      case 'CONDITION_CHECKED':
        const { checked, value, index } = action.payload;
        const questionsChecked = new Set(state.questionsChecked.values());
        const conditionsWeight = checked ? state.conditionsWeight + value : state.conditionsWeight - value;

        if (checked) {
          questionsChecked.add(index);
        } else {
          questionsChecked.delete(index);
        }
        return {
          ...state,
          conditionsWeight,
          canProceed: canProceed(questionsChecked.size > 0),
          questionsChecked,
          status: conditionsWeight ? 'Confirm' : 'Continue',
          nextStep: 2,
        };
      case 'WHO_WILL_COVER':
        payer = {};
        expandServiceLevelSection = false;
        switch (action.answer) {
          case 'requesting agency payer':
            expandPayer = true;
            status = 'Confirm';
            payer = {
              payerId: get(requestingAgencyPayer, 'payer.id', null),
              coordinationTypeId: window.ambulnzConfig.coordinationTypes.SECONDARY,
            };
            break;
          case 'other means':
            status = request.id ? 'Turndown' : 'New Request';
            break;
          case 'lower level of transport':
            status = 'OK';
            expandServiceLevelSection = true;
            break;
          case 'self pay':
            status = 'Confirm';
            payer = {
              payerId: window.ambulnzConfig.constants.BILL_PATIENT_PAYER_ID,
              coordinationTypeId: window.ambulnzConfig.coordinationTypes.SECONDARY,
            };
            break;
          default:
            status = 'Confirm';
        }
        return {
          ...state,
          whoWillCover: action.answer,
          canProceed: canProceed(true),
          payer,
          status,
          expandServiceLevelSection,
        };
      case 'SET_ACTIVE_STEP':
        return {
          ...state,
          status:
            action.activeStep === 0
              ? getFirstStepStatus(
                  state.hasPriorAuthorization,
                  state.hasMedicare,
                  state.medicareSuboption,
                  requireMedicareQuestions,
                )
              : 'Continue',
          activeStep: action.activeStep,
          questions:
            action.activeStep === 1
              ? JSON.parse(window.ambulnzConfig.constants.MEDICAL_NECESSITY_QUESTIONNAIRE).questions
              : [],
          canProceed: action.activeStep < state.activeStep && action.activeStep === 1 ? new Set([0]) : state.canProceed,
        };
      case 'SET_PAYER':
        return {
          ...state,
          payer: action.payload,
        };
      case 'RESET':
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  }, initialState);
  return [section, dispatch];
}
