import { from as from$ } from 'rxjs';
import { updateChiefComplaint } from '../../../services/chiefComplaint.service';
import { getRequestId } from '../updateRequestUtil';
import { CHIEF_COMPLAINT } from '../../../enums';

export const updateHelper = store => {
  const state = store.value.requests;
  const isNonStandard = state.chiefComplaintType === CHIEF_COMPLAINT.TYPES.NON_STANDARD;
  const payload = {
    chiefComplaintId: isNonStandard ? 0 : Number(state.chiefComplaintId),
    name: state.chiefComplaintName,
    typeId: state.chiefComplaintType,
  };
  return from$(updateChiefComplaint(getRequestId(store), payload));
};

export default updateHelper;
