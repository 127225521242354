import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import CreateRequest from './CreateRequest';

const RoutedPage = ({ match, order, request }) => (
  <Switch>
    <Route exact path="/" component={CreateRequest} />
    <Route exact path="/transport" component={CreateRequest} />
  </Switch>
);

RoutedPage.propTypes = {
  match: PropTypes.shape().isRequired,
  request: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  return {
    request: state.requests.request,
  };
}

export default connect(mapStateToProps)(RoutedPage);
