import { createReducer } from 'redux-act';
import initialState from './initialState';
import { reducer as openCancelRequestModal } from './openCancelRequestModal';
import { reducer as onDetailsChange } from './onDetailsChange';
import { reducer as onCancelReasonTypeChange } from './onCancelReasonTypeChange';
import { reducer as fetchRequestReasonTypes } from './fetchRequestReasonTypes';

const reducer = createReducer(
  {
    ...openCancelRequestModal,
    ...onDetailsChange,
    ...onCancelReasonTypeChange,
    ...fetchRequestReasonTypes,
  },
  initialState,
);

export default reducer;
