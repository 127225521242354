import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: 120,
  marginTop: 2,
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
  },
  '& .MuiInputLabel-root': {
    fontSize: 15,
    width: 130,
    color: theme.palette.grey.grey80,
    '&.Mui-focused': {
      color: theme.palette.grey.grey80,
    },
  },
}));

function AmbDropdownTimePicker(props) {
  const { times, placeholder, value, onSelect, propsStyles, disabled } = props;

  const handleClick = useCallback(
    event => {
      const { value } = event.target;
      onSelect(value);
    },
    [onSelect],
  );

  useEffect(() => {
    handleClick({
      target: {
        value: props.value,
      },
    });
  }, [props.value, handleClick]);

  return (
    <StyledTextField
      id="select-currency"
      select
      label={placeholder}
      value={value}
      variant="standard"
      onChange={handleClick}
      margin="none"
      style={propsStyles}
      disabled={disabled}
    >
      {Object.keys(times).map(time => (
        <MenuItem key={time} value={time} sx={{ '&.MuiMenuItem-root': { padding: '12px 16px' } }}>
          {times[time]}
        </MenuItem>
      ))}
    </StyledTextField>
  );
}

AmbDropdownTimePicker.defaultProps = {
  value: '',
  disabled: false,
};

AmbDropdownTimePicker.propTypes = {
  times: PropTypes.shape({}).isRequired,
  propsStyles: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AmbDropdownTimePicker;
