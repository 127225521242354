import * as mapActions from '../../actions/map.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as requestsActions from '../../actions/requests.actions';

const asyncUpdateResidenceSuccessMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    if (action && mapActions.asyncUpdateResidenceSuccess.match(action)) {
      dispatch(requestsActions.updateRequestSuccess(action.payload));
      dispatch(formFlowActions.autoSaveInProgress(false));
    }

    next(action);
  };

const middlewares = [asyncUpdateResidenceSuccessMdl];

export default middlewares;
