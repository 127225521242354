import { from as from$ } from 'rxjs';

import { createSubscriptions } from '../../../services/subscription.service';
import { requestId } from './request.helper';

function smsRecipients(requestShare) {
  return requestShare.phoneRecipients
    .filter(recipient => !requestShare.loadedRecipients.map(lr => lr.contact).includes(recipient))
    .map(recipient => ({
      contact: recipient,
      contact_type: 'sms',
      shareLinkKey: 'phoneRecipients',
    }));
}

function emailRecipients(requestShare) {
  return requestShare.emailRecipients
    .filter(recipient => !requestShare.loadedRecipients.map(lr => lr.contact).includes(recipient))
    .map(recipient => ({
      contact: recipient,
      contact_type: 'email',
      shareLinkKey: 'emailRecipients',
    }));
}

export const createEmailSubscriptionsHelper = store =>
  from$(createSubscriptions(requestId(store), emailRecipients(store.value.requestShare)));

export const createSmsSubscriptionsHelper = store =>
  from$(createSubscriptions(requestId(store), smsRecipients(store.value.requestShare)));
