import ApiService from './api.service';

// eslint-disable-next-line import/prefer-default-export
export async function getProviders() {
  const response = await ApiService.get(`${window.ambulnzConfig.tenantedRestApiUrl}/ProviderCapabilities`);
  return response.json();
}

export async function getProvidersForDaraUser(agencyId) {
  const response = await ApiService.get(
    `${window.ambulnzConfig.tenantedRestApiUrl}/RequesterAgencies/${agencyId}/providers`,
  );
  return response.json();
}
