export default function createMiddleware(middlewareAction, execFn, compareFn = () => true) {
  return ({ dispatch, getState }) =>
    next =>
    (action = {}) => {
      next(action);

      if (middlewareAction.match(action) && compareFn(action)) {
        execFn({ dispatch, getState, action, payload: action.payload });
      }
    };
}
