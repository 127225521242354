import React, { useState } from 'react';
import styled from '@emotion/styled';
import SelectArrow from 'components/common/icons/SelectArrow';

const StyledWrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  position: relative;
  font-size: 14px;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: ${props =>
      props.id.includes('select-department') && props.disabled ? 'transparent' : 'rgba(0, 0, 0, 0.42)'};
    background-image: ${props =>
      props.id.includes('select-department') && props.disabled
        ? 'linear-gradient(to right, rgba(0, 0, 0, 0.42) 33%, transparent 0%)'
        : ''};
    background-repeat: ${props => (props.id.includes('select-department') && props.disabled ? 'repeat-x' : '')};
    background-position: ${props => (props.id.includes('select-department') && props.disabled ? 'left top' : '')};
    background-size: ${props => (props.id.includes('select-department') && props.disabled ? '4px 1px' : '')};
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused || props.error ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: ${props => (props.error ? '#f44336' : '#004053')};
  }
`;

const AmbSelect = ({
  id,
  options,
  onChange,
  onBlur,
  name,
  classes,
  error,
  defaultOption,
  value,
  disabled,
  style,
  dataTestId,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }
    setIsFocused(false);
  };

  return (
    <StyledWrapper isFocused={isFocused} error={error} id={id} disabled={disabled}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#1ec0d9',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <select
          id={id}
          onChange={onChange}
          name={name}
          className={classes}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          value={value}
          disabled={disabled}
          style={{ ...style, background: isFocused ? 'rgba(0, 0, 0, .05)' : 'none' }}
          data-testid={dataTestId}
        >
          {defaultOption}
          {options}
        </select>
        <SelectArrow style={{ position: 'absolute', top: 'calc(50% - 12px)', right: 4 }} />
      </div>
    </StyledWrapper>
  );
};

export default AmbSelect;
