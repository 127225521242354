import React from 'react';
import AmbGrid from 'components/common/AmbGrid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import OfferedEtaCard from './OfferedEtaCard';
import GetETAContextContainer from '../GetETAContext.container';
import OutlinedButton from '../../common/OutlinedButton';
import { isCar } from '../../../flags/service.flag';
import * as etaActions from '../../../redux/actions/eta.actions';
import * as offerActions from '../../../redux/actions/offer.actions';
import Countdown from './Countdown';
import OfferCard from './Offer/OfferCard';
import { theme } from 'theme';

const StyledButton = styled(Button)({
  fontSize: 11,
  textDecoration: 'underline',
  whiteSpace: 'nowrap',
  color: theme.palette.primary.dark,
});

function MultiEta({
  showSeeOtherETAs,
  showCounter,
  clickSeeOtherETAs,
  showEtaInfo,
  offeredEtas,
  multipleEtas,
  agency,
  userType,
  noAutoEtaRequest,
  offers,
  createOffer,
  isRequester,
  ...props
}) {
  function sortByETA(etaA, etaB) {
    const pickupA = etaA.pickupTime;
    const pickupB = etaB.pickupTime;
    const a = pickupA ? new Date(pickupA) : null;
    const b = pickupB ? new Date(pickupB) : null;

    return (
      (a === null) - (b === null) ||
      a - b ||
      (etaB.type === 'leasedShiftProvider') - (etaA.type === 'leasedShiftProvider')
    );
  }
  function noSort(a, b) {
    return 0;
  }

  // const sort = window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED ? noSort : sortByETA;
  const sort = sortByETA;

  return (
    <>
      {showSeeOtherETAs && (
        <AmbGrid style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', gap: 0 }}>
          <AmbGrid style={{ paddingBottom: 0, flex: 1 }}>
            <StyledButton id="SeeOtherETAs" onClick={clickSeeOtherETAs}>
              <span style={{ fontSize: 11 }}>See other ETAs</span>
            </StyledButton>
          </AmbGrid>
        </AmbGrid>
      )}
      {showCounter && <Countdown onCountdownComplete={props.onCountdownComplete} />}
      {[...offeredEtas].sort(sort).map(offeredEtaPayload => (
        <GetETAContextContainer offeredEtaPayload={offeredEtaPayload} key={offeredEtaPayload.id}>
          <OfferedEtaCard
            showEtaInfo={showEtaInfo}
            multipleEtas={multipleEtas}
            agency={agency}
            userType={userType}
            key={offeredEtaPayload.id}
            noAutoEtaRequest={noAutoEtaRequest}
            {...props}
          />
        </GetETAContextContainer>
      ))}
      {!isRequester &&
        offers &&
        offers.map(offer => (
          <OfferCard
            key={offer.name}
            id={offer.id}
            attachment={offer.attachment}
            actionButtonText={offer.actionButtonText}
            descriptionText={offer.descriptionText}
            name={offer.name}
            displayName={offer.displayName}
            providerId={offer.providerId}
            createOffer={createOffer}
          />
        ))}
      {multipleEtas && !window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && (
        <AmbGrid style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', gap: 0 }}>
          <AmbGrid style={{ paddingBottom: 15, paddingRight: 16, flex: 1 }}>
            <AmbGrid style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end', gap: 0 }}>
              <AmbGrid style={{ width: '100%', minWidth: 114 }}>
                <OutlinedButton
                  id="SeeOtherETAs"
                  color="primary"
                  onClick={() => props.rejectETA({ etaIds: offeredEtas.map(oe => oe.id) })}
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  {offeredEtas.filter(e => e.eta).length > 1 ? 'REJECT ALL' : 'REJECT'}
                </OutlinedButton>
              </AmbGrid>
            </AmbGrid>
          </AmbGrid>
        </AmbGrid>
      )}
    </>
  );
}

MultiEta.defaultProps = {
  offers: [],
};

MultiEta.propTypes = {
  agency: PropTypes.shape().isRequired,
  clickSeeOtherETAs: PropTypes.func.isRequired,
  createOffer: PropTypes.func.isRequired,
  multipleEtas: PropTypes.bool.isRequired,
  noAutoEtaRequest: PropTypes.bool.isRequired,
  offeredEtas: PropTypes.arrayOf(PropTypes.object).isRequired,
  offers: PropTypes.arrayOf(PropTypes.object),
  rejectETA: PropTypes.func.isRequired,
  showEtaInfo: PropTypes.bool.isRequired,
  showSeeOtherETAs: PropTypes.bool.isRequired,
  userType: PropTypes.number.isRequired,
  isRequester: PropTypes.bool.isRequired,
};

MultiEta.defaultProps = {};

function mapStateToProps(state) {
  return {
    agency: state.agency.selectedAgency,
    etaOnly: state.formFlow.noAutoEtaRequest || isCar(state.service),
    isCar: isCar(state.service),
    isNonTransport: state.requests.isNonTransport,
    multipleEtas: state.eta.offeredEtas.length > 1,
    noAutoEtaRequest: state.formFlow.noAutoEtaRequest,
    offeredEtas: state.eta.offeredEtas,
    offers: state.eta.offers,
    pollInProgress: state.eta.pollInProgress,
    requestedAppointmentTime: state.eta.requestedAppointmentTime,
    requestedArrivalTime: state.eta.requestedArrivalTime,
    userType: state.user.model.userType,
    isRequester: state.user.model.isRequester,
  };
}

export default connect(mapStateToProps, { ...etaActions, ...offerActions })(MultiEta);
