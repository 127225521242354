export const populateCoverageFromPatientSearch = state =>
  window.ambulnzConfig.userSettings.isBillingEnabled && !state.agency.doNotSetCoverageFromPatientSearch;

export const userCanExternalPatientSearch = state =>
  state.agency.requesterAgencyGroup &&
  state.agency.requesterAgencyGroup.patientStoreAdapters &&
  state.agency.requesterAgencyGroup.patientStoreAdapters.length &&
  state.user.model.permittedRequesterAgenciesForExternalSearch.includes(state.agency.requesterAgencyId);

export const userConfiguredForExternalPatientSearch = state =>
  userCanExternalPatientSearch(state) && state.user.model.hasValidUserExternalSystemCredentials;
