import moment from 'moment';
import { uniq } from 'lodash';

import { parseGetETAParams } from './eta.parser';
import { parseRequesterUpdate } from './pickupTimeChange.parser';
import { parseRequestType } from './request.parser';

const FORCEABLE_CONFLICTS = ['shift_end_overridable', 'shift_on_posting'];

export function buildActivateCandidatePayload(state, etaId) {
  const eta = state.eta.offeredEtas.find(oe => oe.id === etaId);
  const telehealthEquipment = state.service.equipment.find(e => e.name === 'Telehealth');
  const telehealthEquipmentId = telehealthEquipment && telehealthEquipment.id;
  const pcpEquipmentIds = state.service.equipment.filter(e => e.name.includes('PCP')).map(eq => eq.id);
  const { candidatePlanId, candidateId, unitId, conflicts } = eta;
  const pickupTime = eta.pickupTime || state.eta.requestedArrivalTime;
  const shouldAllowConflictsByEquipmentType =
    (telehealthEquipment && state.service.therapistEquipmentIds.some(id => id === telehealthEquipmentId)) ||
    pcpEquipmentIds.some(id => state.service.therapistEquipmentIds.includes(id));
  const allowConflicts =
    shouldAllowConflictsByEquipmentType || !!conflicts.find(conflict => FORCEABLE_CONFLICTS.includes(conflict.type));

  return {
    planId: candidatePlanId,
    candidateId,
    unitId,
    overrideRequestIds: uniq(conflicts.map(conflict => conflict.request.id)),
    requesterUpdate: parseRequesterUpdate(state),
    pickupTime,
    requestedPickupTime: state.eta.requestedArrivalTime,
    allowConflicts,
  };
}

const DEFAULT_MANUAL_PICKUP_TIME_SECS = '1800';

export function buildManualPickupSecs(state) {
  const REQUEST_TYPE_WAIT_IN_SECS = {
    [window.ambulnzConfig.requestTypes.TELEHEALTH]: 5 * 60,
    [window.ambulnzConfig.requestTypes.MOBILE_HEALTH_SERVICE]: 15 * 60,
    [window.ambulnzConfig.requestTypes.MEDICAL_TRANSPORT]: 0,
  };

  const requestTypeParameters = parseRequestType(state);

  if (requestTypeParameters.typeId === window.ambulnzConfig.requestTypes.MEDICAL_TRANSPORT) {
    return state.location.manualPickupSecs;
  }

  let pickupSecs = Number(DEFAULT_MANUAL_PICKUP_TIME_SECS);
  let timeToAdd = 0;

  if (state.service.transportReasons && state.service.transportReasons.length) {
    pickupSecs = state.service.transportReasons
      .map(e => Number(e.pickupTimeInSec))
      .filter(e => !Number.isNaN(e) && e)
      .reduce((acc, e) => acc + e, 0);

    if (requestTypeParameters.typeId) {
      timeToAdd = REQUEST_TYPE_WAIT_IN_SECS[requestTypeParameters.typeId] || 0;
    }
  }

  return (pickupSecs + timeToAdd).toString();
}

export function buildSchedulePayload(state, withPickupTime = true) {
  const { unitId, scheduleId } = state.eta;
  const pickupTime = withPickupTime && (state.eta.pickupTime || state.eta.requestedArrivalTime);
  const { etaOptions } = parseGetETAParams(state);
  const { pickupLat, pickupLng, dropOffLng, dropOffLat } = etaOptions;
  const atPickupSeconds = buildManualPickupSecs(state).toString();
  const atDropOffSeconds = state.location.manualDropOffSecs || '1800';

  const scheduleParams = {
    unitId,
    pickupTime,
    allowConflicts: true,
    scheduleId,
    pickupLocation: {
      lat: pickupLat,
      lng: pickupLng,
    },
    dropoffLocation: {
      lat: dropOffLat,
      lng: dropOffLng,
    },
    atPickupSeconds,
    atDropOffSeconds,
    requesterUpdate: parseRequesterUpdate(state),
  };

  Object.keys(scheduleParams).forEach(key => {
    if (scheduleParams[key] === null || scheduleParams[key] === '') {
      delete scheduleParams[key];
    }
  });

  return scheduleParams;
}
