import { get } from 'lodash';
import { REQUESTER_OPTIONS_DEFAULTS } from '../enums';

export const parseAgency = requesterAgency => ({
  requesterAgencyId: get(requesterAgency, 'id', null),
  name: get(requesterAgency, 'entity.name', ''),
  payer: get(requesterAgency, 'payers[0]'),
  requesterAgencyGroup: get(requesterAgency, 'requesterAgencyGroup', {}),
  ...REQUESTER_OPTIONS_DEFAULTS,
  ...get(requesterAgency, 'requesterOptions', {}),
});
