import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Coverage from './Coverage.validations';
import coverageStateSchema from '../../../../schemas/coverageState.schema';
import eligibilityStateSchema from '../../../../schemas/eligibilityState.schema';
import * as infoboxActions from '../../../../redux/actions/infobox.actions';
import * as eligibilityActions from '../../../../redux/actions/eligibility.actions';
import { handleSuggestionChange } from '../../../common/SuggestionsInput/SuggestionsInput';
import { payer } from '../../../../schemas/infobox.schema';
import capitalize from '../../../../helpers/capitalizeHelper';
import { hasFacesheet, agencyRequiresCoverage } from '../../../../flags/valid.flag';
import { USER_TYPES } from '../../../../enums';
import { getNullCoverageFields } from '../../../../parsers/infobox.parser';

class CoverageContainer extends React.Component {
  constructor(...args) {
    super(...args);
    this.props.initEligibility(this.props.coordinationTypeId);
    this.props.initCoverage({
      coordinationTypeId: this.props.coordinationTypeId,
      isRequired: this.props.isRequired,
    });
  }

  activeHelperText = () => {
    const coverage = this.coverage();

    if (!coverage.isActive) {
      return '';
    }

    return `as of ${moment(coverage.activeAt).format('MM/DD')} by ${coverage.activatorName}`;
  };

  coverage = () => {
    const { coverages, coordinationTypeId } = this.props;

    return coverages[coordinationTypeId] || coverageStateSchema.parse({});
  };

  eligibility = () => {
    const { eligibilities, coordinationTypeId } = this.props;

    return eligibilities[coordinationTypeId] || eligibilityStateSchema.parse({});
  };

  // TODO: Remove it and refactor error creation process
  transformErrors = errors =>
    _.reduce(
      errors,
      (acc, value, key) => {
        acc[`${this.props.insuranceName.toLowerCase()}${capitalize(key)}`] = value;

        return acc;
      },
      {},
    );

  checkEligibility = () => {
    this.props.checkEligibility({
      coordinationTypeId: this.props.coordinationTypeId,
    });
  };

  isPayerInvalid = () => {
    const { patientFirstName, patientLastName, patientBirthday } = this.props;
    const { payerInput, policyNumber } = this.coverage();

    return !(patientFirstName && patientLastName && patientBirthday && payerInput && policyNumber);
  };

  clickReorder = () => {
    this.props.reorderPayer({
      swapWithCoordinationTypeId: this.props.swapWithCoordinationType.id,
      coordinationTypeId: this.props.coordinationTypeId,
    });
  };

  handlePayerChange = (query, downshiftState) => {
    const stateFn = q => ({
      payer: payer.parse(q),
      payerName: q.name,
      payerQuery: q,
      payerInput: q.name,
    });

    const { coordinationTypeId } = this.props;
    handleSuggestionChange(
      q => {
        this.props.queryPayers({
          coordinationTypeId,
          query: q,
        });
      },
      selectedItem =>
        this.props.selectPayerFromSearch({
          coordinationTypeId,
          item: selectedItem,
        }),
      () => this.props.clearPayer(coordinationTypeId),
      stateFn,
    )(query, downshiftState);
  };

  handleInputChange = event => {
    const changeObj = {
      coordinationTypeId: this.props.coordinationTypeId,
      [event.target.name]: event.target.value,
    };

    if (this.props.user.userType === USER_TYPES.REQUESTER) {
      Object.assign(changeObj, getNullCoverageFields());
    }

    this.props.setPayerInfo(changeObj);
  };

  handleInputBlur = () => {
    this.props.autoSavePayerInfo();
  };

  checkPayerActive = payload => {
    this.props.checkPayerActive({
      coordinationTypeId: this.props.coordinationTypeId,
      isActive: payload,
    });
  };

  render() {
    return (
      <Coverage
        {...this.props}
        {...this.coverage()}
        {...this.eligibility()}
        onPayerChange={this.handlePayerChange}
        onInputChange={this.handleInputChange}
        onInputBlur={this.handleInputBlur}
        checkPayerActive={this.checkPayerActive}
        activeHelperText={this.activeHelperText()}
        isPayerInvalid={this.isPayerInvalid}
        checkEligibility={this.checkEligibility}
        transformErrors={this.transformErrors}
        clickReorder={this.clickReorder}
        disabled={this.props.isBillerVerified || this.props.disabled || null}
      />
    );
  }
}

CoverageContainer.defaultProps = {
  isRequired: false,
  patientBirthday: null,
  isLineHidden: false,
  reorder: false,
  isBillerVerified: false,
  disabled: false,
  status: null,
};

CoverageContainer.propTypes = {
  coordinationTypeId: PropTypes.number.isRequired,
  coverages: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]).isRequired,
  eligibilities: PropTypes.shape().isRequired,
  isRequired: PropTypes.bool,
  queryPayers: PropTypes.func.isRequired,
  setPayerInfo: PropTypes.func.isRequired,
  autoSavePayerInfo: PropTypes.func.isRequired,
  clearPayer: PropTypes.func.isRequired,
  checkPayerActive: PropTypes.func.isRequired,
  patientFirstName: PropTypes.string.isRequired,
  patientLastName: PropTypes.string.isRequired,
  patientBirthday: PropTypes.string,
  checkEligibility: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired,
  initEligibility: PropTypes.func.isRequired,
  initCoverage: PropTypes.func.isRequired,
  insuranceName: PropTypes.string.isRequired,
  isLineHidden: PropTypes.bool,
  reorder: PropTypes.bool,
  reorderPayer: PropTypes.func.isRequired,
  selectPayerFromSearch: PropTypes.func.isRequired,
  facesheetAttached: PropTypes.bool.isRequired,
  coverageRequired: PropTypes.bool.isRequired,
  isBillerVerified: PropTypes.bool,
  user: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  status: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    coverages: state.infobox.coverages,
    eligibilities: state.eligibility.eligibilities,
    user: state.user.model,
    isBillerVerified: state.requests.isBillerVerified,
    triggerValidation: state.formFlow.triggerValidation,
    patientFirstName: state.infobox.patientFirstName,
    patientLastName: state.infobox.patientLastName,
    patientBirthday: state.infobox.patientBirthday,
    triggerPatientValidation: state.formFlow.triggerPatientValidation,
    isDispatcher: state.user.model.isDispatcher,
    facesheetAttached: hasFacesheet(state),
    coverageRequired: agencyRequiresCoverage(state),
    attachments: state.patientDocuments.attachments,
    status: state.requests.status,
  };
}

export default connect(mapStateToProps, { ...infoboxActions, ...eligibilityActions })(CoverageContainer);
