import React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';

const SummaryItem = ({ title, message, Icon, active, children }) => {
  const titleStyles = {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 500,
    color: 'black',
    transition: 'color 1s ease-in, transform 0.5s ease-in',
    active: {
      transform: 'scale(1.2)',
      color: theme.palette.primary.orange,
      transition: 'none',
      listStyle: 'none',
    },
  };

  const titleStylesObj = {
    ...titleStyles,
    ...(active && titleStyles.active),
  };

  const titleElement = (
    <li style={{ paddingTop: 16, justifyContent: 'center', listStyle: 'none' }}>
      <div style={titleStylesObj}>{title}</div>
    </li>
  );

  return (
    <>
      {title && titleElement}
      <li
        style={{
          listStyle: 'none',
          marginTop: 10,
          marginBottom: 10,
          ...(children
            ? {}
            : {
                paddingLeft: 0,
                paddingRight: 30,
                listStyle: 'none',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
              }),
        }}
      >
        {children}
        {!children && (
          <>
            <Icon style={{ marginRight: 8 }} />
            <h2 style={{ textAlign: 'center' }}>{message}</h2>
          </>
        )}
      </li>
    </>
  );
};

SummaryItem.defaultProps = {
  title: null,
};

SummaryItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  message: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.shape(), PropTypes.func]),
  active: PropTypes.bool,
  children: PropTypes.shape(),
};

export default SummaryItem;
