import React from 'react';
import { connect } from 'react-redux';
import ProposedETAModal from './ProposedETAModal.frame';
import { openProposedETAsModal } from './redux/openProposedETAsModal';

const ProposedETAModalContainer = props => <ProposedETAModal {...props} />;

function mapStateToProps(state) {
  return {
    open: state.proposedETAModal.open,
    etas: state.proposedETAModal.etas,
  };
}

export default connect(mapStateToProps, { openProposedETAsModal })(ProposedETAModalContainer);
