import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import LoadedMileageFrame from './LoadedMileageFrame';
import LinearMileageFrame from './LinearMileageFrame';

import { showHospitalToHospitalWarning } from '../../../flags/service.flag';

function DistanceContainer({ requireLinearDistance, ...props }) {
  return (
    <>
      {!requireLinearDistance && <LoadedMileageFrame {...props} />}
      {requireLinearDistance && <LinearMileageFrame {...props} />}
    </>
  );
}

function mapStateToProps(state) {
  return {
    fromWaypoint: state.map.fromWaypoint,
    toWaypoint: state.map.toWaypoint,
    grow: !!showHospitalToHospitalWarning(state),
    requireLinearDistance: get(state.agency.requesterAgencyGroup, 'requireLinearDistance', false),
  };
}

DistanceContainer.propTypes = {
  requireLinearDistance: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {})(DistanceContainer);
