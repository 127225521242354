import schema from 'schm';
import translate from 'schm-translate';

const prediction = schema(
  {
    name: String,
    id: String,
    typeof: 'prediction',
  },
  translate({
    name: 'description',
    id: 'place_id',
  }),
);

export default prediction;
