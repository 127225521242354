import { switchMap, map, filter } from 'rxjs/operators';
import { from as from$ } from 'rxjs';

import EligibilityService from '../../services/eligibility.service';
import PayerService from '../../services/payer.service';
import * as eligibilityActions from '../actions/eligibility.actions';
import createEligibilityData from '../../parsers/eligibility.parser';

export const checkEligibilityEpic = (action$, store) =>
  action$.pipe(
    filter(eligibilityActions.checkEligibility.match),
    map(({ payload }) => createEligibilityData(store.value, payload.coordinationTypeId)),
    switchMap(eligibilityData =>
      from$(EligibilityService.checkEligibility(eligibilityData)).pipe(
        map(response => ({
          ...response,
          coordinationTypeId: eligibilityData.coordinationTypeId,
        })),
      ),
    ),
    map(response => eligibilityActions.checkEligibilitySuccess(response)),
  );

export const loadPortalInfoEpic = action$ =>
  action$.pipe(
    filter(eligibilityActions.loadPortalInfo.match),
    switchMap(action => from$(PayerService.getPayerOptions(action.payload))),
    map(response => eligibilityActions.portalInfoLoaded(response)),
  );
