import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { palette } from '../../../theme';

const SubLabel = styled.div`
  font-size: ${({ fontSize }) => fontSize || 8}px;
  line-height: 1;
  font-weight: 500;
  color: ${({ warning }) => (warning ? palette.primary.red : palette.primary.main)};
`;

const FareLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const ReturnFareLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.grey.dark};
`;

const FareCurrencyLabel = styled.span`
  font-size: 14px;
  display: inline-block;
  font-weight: 400;
`;

function EtaLabel({ etaOnly, etaDate, unitId, fareAmount, returnFareAmount, warning, fontSize }) {
  const labelToShow = etaOnly ? etaDate : `Unit ${unitId}`;
  const withFare = (
    <SubLabel className="eta-label__fare">
      <FareCurrencyLabel className="eta-label__fare-currency">$</FareCurrencyLabel>
      <FareLabel className="eta-label__fare-amount">{fareAmount}</FareLabel>
      {returnFareAmount && (
        <ReturnFareLabel className="eta-label__return-fare-amount">
          &nbsp;/&nbsp;
          {`$${returnFareAmount}`}
        </ReturnFareLabel>
      )}
    </SubLabel>
  );
  const noFare = (
    <SubLabel warning={warning} fontSize={fontSize} className="eta-label__date">
      {labelToShow}
    </SubLabel>
  );

  return <>{fareAmount ? withFare : noFare}</>;
}

EtaLabel.defaultProps = {
  unitId: null,
  fareAmount: null,
  returnFareAmount: null,
  fontSize: null,
};

EtaLabel.propTypes = {
  unitId: PropTypes.string,
  etaOnly: PropTypes.bool.isRequired,
  etaDate: PropTypes.string.isRequired,
  warning: PropTypes.bool.isRequired,
  fareAmount: PropTypes.string,
  returnFareAmount: PropTypes.string,
  fontSize: PropTypes.number,
};

export default EtaLabel;
