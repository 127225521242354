import { get } from 'lodash';
import * as RequestParser from '../../../parsers/request.parser';
import * as agencyActions from '../../actions/agency.actions';
import * as asyncActions from '../../actions/async.actions';
import * as errorActions from '../../actions/error.actions';
import * as etaActions from '../../actions/eta.actions';
import * as formFlowActions from '../../actions/formFlow.actions';
import * as infoboxActions from '../../actions/infobox.actions';
import * as locationActions from '../../actions/location.actions';
import * as mapActions from '../../actions/map.actions';
import * as offerActions from '../../actions/offer.actions';
import * as patientActions from '../../actions/patient.actions';
import * as requestsActions from '../../actions/requests.actions';
import * as routeActions from '../../actions/route.actions';
import * as serviceActions from '../../actions/service.actions';
import * as subscriptionActions from '../../actions/subscription.actions';
import * as toastActions from '../../actions/toast.actions';
import * as willCallActions from '../../actions/willCall.actions';
import { parseAgency } from '../../../parsers/agency.parser';
import { REQUEST_TYPE, RequestStatus } from '../../../enums';
import { isPatientPresent } from '../../epics/helpers/patient.helper';
import * as trackChangesActions from '../../actions/trackChanges.actions';
import { scheduleUpdate, resetSchedule } from '../../actions/schedule.actions';
import * as locationComponentActions from '../../../components/PickupDestination/redux/actions/component.actions';
import { setMessage } from '../eta/etaHelper';
import { isNoAutoEtaRequest } from '../../../validators/schedule.validator';
import createMiddleware from '../helper';
import { loadAttachments } from '../../../components/PatientDocuments/redux/actions';
import { buildServiceChip } from '../../../components/MedicalService/helper/helper';
import { getDefaultDate, getMaxAllowedDate } from '../../../helpers/nonTransportHelper';

const formattedAddress = payload => () =>
  `${payload.address.street1 || ''} ${payload.address.city || ''}, ${payload.address.postalCode || ''}, ${
    payload.address.state || ''
  }`;

const parseFacilityName = ({ facilityLocation, name }) =>
  facilityLocation && facilityLocation.emsFacility ? facilityLocation.emsFacility.entity.name : name;

const parsedRoomNamber = (payload, key) =>
  payload.additionalData && payload.additionalData.length && key in payload.additionalData[0]
    ? payload.additionalData[0][key]
    : null;

function resolveDepartments(nonResolvedDepartments) {
  return nonResolvedDepartments
    .filter(d => d.department)
    .map(d => ({
      id: d.department.id,
      name: d.department.name,
    }));
}

const parseDepartments = ({ facilityLocation }) => {
  let departments = [];
  if (get(facilityLocation, 'emsFacility.emsFacilityDepartments.length')) {
    departments = resolveDepartments(facilityLocation.emsFacility.emsFacilityDepartments);

    if (departments.length) {
      return departments;
    }
  }

  if (get(facilityLocation, 'emsFacility.type.departmentEmsFacilityTypes.length')) {
    departments = resolveDepartments(facilityLocation.emsFacility.type.departmentEmsFacilityTypes);
  }

  return departments;
};

const editRequest =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (requestsActions.editRequest.match(action)) {
      dispatch(formFlowActions.setLoadRequestInProgress(true));
    }
  };

const setProvider =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (requestsActions.setProvider.match(action) && !RequestParser.providerHasAutoEta(getState())) {
      dispatch(requestsActions.setStatus({ status: RequestStatus.NEEDS_ASSIGNMENT }));
      dispatch(formFlowActions.setTriggerValidation(true));
    }
  };

const updateRequestSuccess =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    const isUpdateAction = action && requestsActions.updateRequestSuccess.match(action);

    if (isUpdateAction && isPatientPresent(getState())) {
      if (etaActions.setDateTime.match(action) === getState().requests.triggeredBy) {
        dispatch(requestsActions.setTriggeredBy(null));
        dispatch(requestsActions.asyncGetSimilarRequests());
      }
    }

    next(action);
  };

const asyncCancelSimilarRequestSuccess =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (requestsActions.asyncCancelSimilarRequestSuccess.match(action)) {
      const response = action.payload;
      const requestId = getState().requests.request.id;
      if (response.error) {
        dispatch(toastActions.onToast(response.error.message));
        dispatch(formFlowActions.setSimilarRequestFoundDialog(null));
        dispatch(errorActions.onError(response));
      } else {
        dispatch(requestsActions.createCancelComment());
        dispatch(formFlowActions.setSimilarRequestFoundDialog(null));
        dispatch(toastActions.onToast(`Trip #${requestId} Cancelled`));
        dispatch(routeActions.atEditRequest(requestId));
      }
    }
  };

const getSimilarRequestsSuccess =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    if (action && requestsActions.getSimilarRequestsSuccess.match(action)) {
      const response = action.payload;
      if (response && response.error) {
        dispatch(errorActions.onError(response));
      }
    }

    next(action);
  };

const editRequestSuccess =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (requestsActions.editRequestSuccess.match(action)) {
      const response = action.payload;
      if (response.error) {
        dispatch(toastActions.onToast(response.error.message));
        dispatch(formFlowActions.setLoadRequestInProgress(false));
        dispatch(errorActions.onError(response));
      } else {
        const shouldSetNonTransport =
          response.requestType &&
          (response.requestType.name === REQUEST_TYPE.MOBILE_HEALTH_SERVICE ||
            response.requestType.name === REQUEST_TYPE.TELEHEALTH);
        dispatch(requestsActions.setNonTransport(shouldSetNonTransport));
        dispatch(formFlowActions.setCurrentView('edit'));
        dispatch(formFlowActions.setLoadedRequest(true));
        dispatch(infoboxActions.setLoadCoverageInProgress(true));
        dispatch(requestsActions.loadCoverages());
        dispatch(patientActions.loadPatient(response.patient));
        dispatch(agencyActions.setAgency(parseAgency(response.requesterAgency)));
        dispatch(
          locationActions.selectPickupLocationSuccess({
            ...response.pickupPlace,
            ...{
              name: parseFacilityName(response.pickupPlace),
              formattedAddress: formattedAddress(response.pickupPlace),
              licenseTypeName: get(response.pickupPlace, 'facilityLocation.emsFacility.licenseType.name'),
              marketId: get(response.pickupPlace, 'facilityLocation.emsFacility.marketId'),
            },
          }),
        );
        dispatch(
          locationActions.selectDestinationLocationSuccess({
            ...response.destinationPlace,
            ...{
              name: parseFacilityName(response.destinationPlace),
              formattedAddress: formattedAddress(response.destinationPlace),
            },
          }),
        );
        dispatch(locationActions.toggleERBound(response.erBound));
        dispatch(locationActions.setRoomNumber({ puRoomNumber: parsedRoomNamber(response, 'puRoomNumber') }));
        dispatch(locationActions.setRoomNumber({ doRoomNumber: parsedRoomNamber(response, 'doRoomNumber') }));
        dispatch(
          mapActions.setResidence({
            isPickupResidence: response.pickupPlace.isResidence,
            isDestinationResidence: response.destinationPlace.isResidence,
            pickupResidenceDisabled: Object.prototype.hasOwnProperty.call(response.pickupPlace, 'facilityLocation'),
            destinationResidenceDisabled: Object.prototype.hasOwnProperty.call(
              response.destinationPlace,
              'facilityLocation',
            ),
          }),
        );
        dispatch(locationActions.getPickupDepartmentsSuccess(parseDepartments(response.pickupPlace)));
        dispatch(locationActions.getDestinationDepartmentsSuccess(parseDepartments(response.destinationPlace)));
        dispatch(locationComponentActions.selectPickupDepartment(response.pickupDepartmentId));
        dispatch(locationComponentActions.selectDestinationDepartment(response.destinationDepartmentId));
        dispatch(locationActions.loadRequestDetails(response));
        dispatch(willCallActions.setLoadedWillCall(response.status === RequestStatus.WILL_CALL));
        dispatch(serviceActions.loadRequestDetails(response));
        dispatch(serviceActions.respiratoryTherapistChange(response.isRespiratoryTherapistRequired));
        dispatch(serviceActions.toggleRequiredAttendant(response.requiredAttendantGenderId));
        dispatch(serviceActions.callTypeChange(response.callTypeId));
        dispatch(
          serviceActions.setTransportReasons({
            transportReasons: response.transportReasons.map(item => buildServiceChip(item.transportReason)),
            isToAdd: true,
            isNonTransport: true,
          }),
        );
        dispatch(serviceActions.setQuestionMode(false));
        dispatch(serviceActions.setTheraphistEquipmentIds(response.therapistEquipment));
        dispatch(requestsActions.roundTripChange(RequestParser.parseRoundTrip(response)));
        dispatch(etaActions.loadRequestDetails(response));
        dispatch(etaActions.setETAButtonDisabled(false));
        dispatch(
          formFlowActions.loadRequestDetails({
            etaAccepted: response.status === 25,
          }),
        );
        dispatch(formFlowActions.setChangeRequiresNewETA(response.status === RequestStatus.SCHEDULED));
        dispatch(formFlowActions.setETAwasAccepted(response.status === RequestStatus.SCHEDULED));
        dispatch(formFlowActions.setNoAutoEtaRequest(isNoAutoEtaRequest(getState())));
        dispatch(formFlowActions.changeDialogContent(setMessage(getState().requests.request)));
        dispatch(infoboxActions.loadRequestDetails(response));
        dispatch(infoboxActions.checkPayerRules());
        dispatch(trackChangesActions.addPatientSnapshot(getState().infobox));
        dispatch(formFlowActions.setDirty(false));
        dispatch(asyncActions.asyncLoadComments(response.id));
        dispatch(loadAttachments(response.id));
        dispatch(asyncActions.asyncGetShareLink());
        dispatch(formFlowActions.setLoadRequestInProgress(false));
        dispatch(formFlowActions.setTriggerValidation(true));
        dispatch(requestsActions.getSchedule());
        dispatch(mapActions.asyncGetVehicleLocation());
        dispatch(requestsActions.getRequestCharges(response.id));
        dispatch(serviceActions.fetchTransportReason());
        dispatch(subscriptionActions.asyncGetSubscriptions());
        dispatch(offerActions.getOffers());
      }
    }
  };

const getScheduleSuccess =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (requestsActions.getScheduleSuccess.match(action)) {
      if (!action.payload.error) {
        dispatch(scheduleUpdate(action.payload));
      }

      if (get(action, 'payload.error.status') === 404) {
        dispatch(resetSchedule());
      }
    }
  };

const triggerPatientValidationOnPatientActivation = createMiddleware(
  requestsActions.setReturnRequestCanBeActivatedByPatient,
  ({ dispatch, payload }) => {
    if (payload === true) {
      dispatch(formFlowActions.setTriggerPatientValidation(true));
    }
  },
);

const setNonTransport =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (requestsActions.setNonTransport.match(action)) {
      const state = getState();
      const requestId = state.requests.request.id;
      const shouldSetMaxDate = window.ambulnzConfig.userSettings.isMultidayTripPlanner;

      if (shouldSetMaxDate) {
        if (action.payload) {
          if (!requestId) {
            dispatch(etaActions.setDateTime(getDefaultDate(state)));
          }
          dispatch(etaActions.setMaxDateAllowed(getMaxAllowedDate(state)));
        } else {
          dispatch(etaActions.setMaxDateAllowed(null));
        }
      }
    }
  };

const middlewares = [
  editRequest,
  editRequestSuccess,
  updateRequestSuccess,
  getSimilarRequestsSuccess,
  asyncCancelSimilarRequestSuccess,
  getScheduleSuccess,
  setProvider,
  triggerPatientValidationOnPatientActivation,
  setNonTransport,
];
export default middlewares;
