import { createAction } from '@reduxjs/toolkit';

export const loadRecipients = createAction('[RequestShare] loadRecipients');

export const reducer = {
  [loadRecipients]: (state, recipients) => ({
    ...state,
    emailRecipients: recipients.filter(r => r.contact_type.name === 'email').map(r => r.contact),
    phoneRecipients: recipients.filter(r => r.contact_type.name === 'sms').map(r => r.contact),
    loadedRecipients: recipients,
  }),
};
