import React from 'react';

export default function Icon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 63 24"
      version="1.1"
      viewBox="0 0 63 24"
      xmlSpace="preserve"
    >
      <path
        fill="#24C0DA"
        d="M62.5 11.3C60.8 8 52 7.4 50.1 6.8c-.4-.1-1.4-.8-2.5-1.4C43 3.1 36.4.2 26.7.2c-6.8 0-11.6 1.4-15.8 3C7.9 4.4 2 5 1.7 5.9c-.1.3 0 .8.4 1.2 0 .1-.1.3-.1.4-.1.3-.7.5-.7 1.2s.2 2.7 0 3.3c0 .2-1.2.7-1.2 1.9v1.6c0 2.2 1.8 3.7 3.9 3.7h57c1.1 0 2-.2 2-2 0-.3.3-4.5-.5-5.9z"
      />
      <g fill="#EBEBED">
        <path d="M31 2.4l.7 3.9 14.9.7C43 4.9 36.5 3 31 2.4zM28.1 2.2c-6.3-.1-11.5.7-15.4 2.3l1.6 1 14.5.6-.7-3.9z" />
      </g>
      <path
        fill="#FFF"
        d="M19.7 1.5c-2.6 0-4.6 1.9-5 4.6 1.1.1 2 .6 2.8 1.3v-.9c0-.6.3-1.1.6-1.5.4-.4.9-.6 1.5-.6 1.3 0 2.2 1 2.2 2.4v7.7H19v-2.6c0-2.9-2.4-5.1-5.3-5.1S8.5 9 8.5 12s2.3 5.3 5.1 5.3h1.9v-2.8h-1.8c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4c1.4 0 2.4 1.1 2.4 2.4v5.2h8.5V6.7c.1-2.9-2.1-5.2-4.9-5.2"
        opacity="0.5"
      />
      <path fill="#050505" d="M.6 17.2c.6 1.2 2 2 3.5 2H61c1.1 0 2-.2 2-2H.6z" />
      <path fill="#050505" d="M18.1 19.2c.1-.3.1-.7.1-1.1 0-2.8-2.2-5-5-5s-5 2.2-5 5c0 .4 0 .7.1 1.1h9.8z" />
      <circle cx="13.2" cy="18.2" r="4.5" fill="#181919" />
      <circle cx="13.2" cy="18.2" r="2.5" fill="#DCD9DC" />
      <path fill="#050505" d="M50.1 13.2c-2.8 0-5 2.2-5 5 0 .4 0 .7.1 1.1H55c.1-.3.1-.7.1-1.1 0-2.8-2.2-5-5-5z" />
      <circle cx="50.1" cy="18.2" r="4.5" fill="#181919" />
      <circle cx="50.1" cy="18.2" r="2.5" fill="#DCD9DC" />
      <path d="M24.8 16.3c-.1 0-.2 0-.2-.1-.1-.1-.1-.2-.1-.3V8c0-.1 0-.2.1-.3.1-.1.2-.1.2-.1h1.4c.1 0 .2 0 .3.1.1.1.1.2.1.2v6.3h2.7c.1 0 .2 0 .2.1.1.1.1.2.1.3v1.3c0 .1 0 .2-.1.2-.1.1-.2.1-.3.1h-4.4z" />
      <path
        fill="#FFF"
        d="M26.2 7.7c.1 0 .1 0 .2.1 0 0 .1.1.1.2v6.4h2.9c.1 0 .1 0 .2.1 0 0 .1.1.1.2V16c0 .1 0 .1-.1.2 0 0-.1.1-.2.1h-4.5c-.1 0-.1 0-.2-.1 0 0-.1-.1-.1-.2V8c0-.1 0-.1.1-.2 0 0 .1-.1.2-.1h1.3m0-.2h-1.4c-.1 0-.2 0-.3.1-.1.1-.1.2-.1.3v7.9c0 .1 0 .2.1.3.1.1.2.1.3.1h4.5c.1 0 .2 0 .3-.1.1-.1.1-.2.1-.3v-1.3c0-.1 0-.2-.1-.3-.1-.1-.2-.1-.3-.1h-2.6V8c0-.1 0-.2-.1-.3-.1-.2-.2-.2-.4-.2z"
      />
      <path d="M30.9 16.3c-.1 0-.2 0-.2-.1-.1-.1-.1-.2-.1-.2V8c0-.1 0-.2.1-.2.1-.1.2-.1.2-.1h1.4c.1 0 .2 0 .2.1.1.1.1.2.1.2v7.9c0 .1 0 .2-.1.2-.1.1-.2.1-.2.1h-1.4z" />
      <path
        fill="#FFF"
        d="M32.4 7.7c.1 0 .1 0 .2.1 0 0 .1.1.1.2v7.9c0 .1 0 .1-.1.2 0 0-.1.1-.2.1H31c-.1 0-.1 0-.2-.1 0 0-.1-.1-.1-.2V8c0-.1 0-.1.1-.2 0 0 .1-.1.2-.1h1.4m0-.2H31c-.1 0-.2 0-.3.1-.1.1-.1.2-.1.3v7.9c0 .1 0 .2.1.3.1.1.2.1.3.1h1.4c.1 0 .2 0 .3-.1.1-.1.1-.2.1-.3V8c0-.1 0-.2-.1-.3-.1-.2-.2-.2-.3-.2z"
      />
      <g>
        <path d="M37.5 16.4c-.1 0-.2 0-.3-.2l-3.7-8.1c-.1-.2 0-.3 0-.3 0-.1.1-.2.3-.2h1.6c.2 0 .3.1.3.2l1.9 4.2 1.9-4.2c0-.1.2-.2.3-.2h1.6c.2 0 .3.1.3.1 0 .1.1.2 0 .3L38 16.2c-.1.2-.2.2-.3.2h-.2z" />
        <path
          fill="#FFF"
          d="M41.4 7.7c.1 0 .2 0 .2.1V8l-3.8 8.1c0 .1-.1.1-.2.1h-.1c-.1 0-.2 0-.2-.1L33.6 8v-.2c0-.1.1-.1.2-.1h1.6c.1 0 .2 0 .2.1l2 4.4h.1l2-4.4c0-.1.1-.1.2-.1h1.5m0-.2h-1.6c-.2 0-.4.1-.4.3l-1.8 3.9-1.8-4c-.1-.2-.2-.3-.4-.3h-1.6c-.2 0-.3.1-.4.2-.1.1-.1.3 0 .5l3.7 8.1c.1.2.2.3.4.3h.1c.2 0 .3-.1.4-.3l3.8-8.1c.1-.2.1-.3 0-.5 0 0-.2-.1-.4-.1z"
        />
      </g>
    </svg>
  );
}
