import { CHIEF_COMPLAINT } from '../enums';

export const prebillerVerifiedLocationMissing = ({ map, location }) =>
  !(
    (map.isPickupResidence || location.fromSelected.facility) &&
    (map.isDestinationResidence || location.toSelected.facility)
  );

export const patientAddressMissing = ({ agency, patient }) =>
  agency.selectedAgency &&
  agency.selectedAgency.patientAddressRequired &&
  !(patient.address1 && patient.city && patient.state && patient.zip && patient.zip.trim().length === 5);

export const prebillerVerifiedPayerMissing = ({ infobox }) =>
  !Object.values(infobox.coverages)
    .map(coverage => coverage.isActive)
    .reduce((acc, value) => acc || value, false);

export const payerNotAllowed = ({ infobox }) =>
  Object.values(infobox.coverages).some(
    c => c.payer.typeId === window.ambulnzConfig.payerTypes.EXTERNAL_NON_MATCHED_PAYER,
  );

export const disabledByPcrStatus = ({
  patientDocuments,
  service,
  requests: { isBillerVerified, request, pcrApproved },
  user: { isUserAmbAdmin },
}) => {
  const hasPcr = !!patientDocuments.attachments.some(a => a.attachmentType.type === 'PCR');
  const skipPcrCheck = JSON.parse(window.ambulnzConfig.constants.SKIP_PCR_CHECK_FOR_SERVICE_TYPES).includes(
    service.serviceId,
  );
  const pcrValid = pcrApproved || skipPcrCheck || hasPcr;
  return !isUserAmbAdmin && request.isCompleted && isBillerVerified && pcrValid;
};

export const chiefComplaintMissing = ({ requests }) =>
  !requests.chiefComplaintId && !requests.chiefComplaintName && !requests.chiefComplaintType;

export const chiefComplaintNotAllowed = ({ requests }) =>
  requests.chiefComplaintType === CHIEF_COMPLAINT.TYPES.NON_STANDARD;

export const patientInvalid = state =>
  !(state.infobox.patientFirstName && state.infobox.patientLastName && state.infobox.patientBirthday);

export const changesNotSavedYet = state =>
  !!state.formFlow.submitInProgress || !!state.formFlow.autoSaveInProgress || !!state.formFlow.dirty;

export const patientWeightHeightMissing = state =>
  !(!state.service.bariatric || !!(state.infobox.patientWeight && state.infobox.patientHeight));

const notAdmin = ({ user }) => !user.isUserAmbAdmin;

export const prebillerVerifiedDisabled = state =>
  notAdmin(state) &&
  (prebillerVerifiedLocationMissing(state) ||
    patientWeightHeightMissing(state) ||
    patientAddressMissing(state) ||
    prebillerVerifiedPayerMissing(state) ||
    payerNotAllowed(state) ||
    disabledByPcrStatus(state) ||
    chiefComplaintMissing(state) ||
    chiefComplaintNotAllowed(state) ||
    patientInvalid(state) ||
    changesNotSavedYet(state));
