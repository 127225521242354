import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import { head } from 'ramda';
import { theme } from '../../../theme';

const RequirementWarning = props => {
  const forcedCapability = head(props.forcedCapabilities);
  const requirement = props.requirements.find(
    singleRequirement => singleRequirement.id === forcedCapability.capability.id,
  );
  const forcedEquipment = props.equipment.find(
    singleEquipment => singleEquipment.id === forcedCapability.parentCapability.id,
  );

  return (
    <AmbGrid style={{ marginTop: '-2px', marginBottom: 8 }}>
      <div style={{ color: theme.palette.primary.red }}>
        {`${requirement.displayName} is required for ${forcedEquipment.label} and cannot be unselected`}
      </div>
    </AmbGrid>
  );
};

RequirementWarning.propTypes = {
  equipment: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requirements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  forcedCapabilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default RequirementWarning;
