import React from 'react';
import { connect } from 'react-redux';

import NewOrExistingFrame from './NewOrExistingFrame';
import * as newOrExistingActions from '../../redux/actions/newOrExisting.actions';

const NewOrExistingContainer = props => <NewOrExistingFrame {...props} />;

function mapStateToProps(state) {
  return {
    open: state.newOrExisting.open,
    agencyName: state.newOrExisting.agencyName,
  };
}

export default connect(
  mapStateToProps,
  { ...newOrExistingActions },
)(NewOrExistingContainer);
