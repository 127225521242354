import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 926.9 321.8" {...props}>
      <g>
        <path
          style={{ fill: '#000000' }}
          d="M53.3,229.8c3.9,10.4,9.3,19.3,16.3,26.7c6.9,7.4,15.1,13,24.6,16.9c9.5,3.9,19.8,5.9,31,5.9
		c10.8,0,21-2,30.5-6.1s17.8-9.8,24.9-17.2c7.1-7.4,12.6-16.2,16.5-26.4s5.9-21.7,5.9-34.3V0h47.5v316.4h-47V287
		c-10.5,11.1-23,19.7-37.3,25.8c-14.3,6-29.8,9-46.3,9c-16.9,0-32.6-2.9-47.2-8.8c-14.6-5.9-27.3-14.2-38-25.1S15.4,264,9.3,248.8
		C3.1,233.6,0,216.6,0,197.9V0h47.5v195.2C47.5,207.9,49.4,219.4,53.3,229.8z"
        />
        <path
          style={{ fill: '#000000' }}
          d="M332.2,0v115.2c10.5-10.5,22.7-18.9,36.6-25.1s28.9-9.3,45.2-9.3c16.9,0,32.7,3.2,47.5,9.5
		c14.8,6.3,27.6,14.9,38.4,25.8s19.4,23.7,25.8,38.4c6.3,14.8,9.5,30.4,9.5,47c0,16.6-3.2,32.2-9.5,46.8
		c-6.3,14.6-14.9,27.3-25.8,38.2c-10.8,10.8-23.7,19.4-38.4,25.8c-14.8,6.3-30.6,9.5-47.5,9.5c-16.3,0-31.4-3.1-45.4-9.3
		c-14-6.2-26.3-14.5-36.8-25.1v28.9h-45.2V0H332.2z M337.4,232.7c4.1,9.6,9.7,18.1,16.9,25.3s15.7,13,25.3,17.2
		c9.6,4.2,20,6.3,31.2,6.3c10.8,0,21.1-2.1,30.7-6.3s18-9.9,25.1-17.2c7.1-7.2,12.7-15.7,16.9-25.3c4.2-9.6,6.3-20,6.3-31.2
		s-2.1-21.6-6.3-31.4s-9.9-18.3-16.9-25.5c-7.1-7.2-15.4-12.9-25.1-16.9s-19.9-6.1-30.7-6.1c-10.8,0-21.1,2-30.7,6.1
		s-18.1,9.7-25.3,16.9c-7.2,7.2-13,15.7-17.2,25.5c-4.2,9.8-6.3,20.3-6.3,31.4C331.3,212.7,333.3,223.1,337.4,232.7z"
        />
        <path
          style={{ fill: '#000000' }}
          d="M560.8,155c6-14.5,14.3-27.2,24.9-38.2s23-19.7,37.5-26s30.1-9.5,47-9.5c16.6,0,31.9,3,46.1,9
		c14.2,6,26.4,14.4,36.6,25.1c10.2,10.7,18.2,23.4,24,38c5.7,14.6,8.6,30.5,8.6,47.7V216H597.9c1.5,9.3,4.5,18,9,26
		c4.5,8,10.2,14.9,16.9,20.8c6.8,5.9,14.5,10.5,23,13.8c8.6,3.3,17.7,5,27.3,5c27.4,0,49.6-11.3,66.4-33.9l33,24.4
		c-11.4,15.4-25.6,27.4-42.5,36.2c-16.9,8.7-35.9,13.1-56.9,13.1c-17.2,0-33.2-3-48.1-9s-27.9-14.5-38.9-25.3s-19.7-23.6-26-38.2
		s-9.5-30.5-9.5-47.7C551.8,184.8,554.8,169.5,560.8,155z M624.3,137.2c-12.8,10.7-21.3,24.9-25.5,42.7h140.6
		c-3.9-17.8-12.3-32-25.1-42.7c-12.8-10.7-27.8-16-45-16C652.1,121.1,637.1,126.5,624.3,137.2z"
        />
        <path
          style={{ fill: '#000000' }}
          d="M870.9,142.4c-9.6,10.5-14.5,24.9-14.5,42.9v131.1h-45.6V85.9H856v28.5c5.7-9.3,13.2-16.7,22.4-22.1
		c9.2-5.4,20.1-8.1,32.8-8.1h15.8v42.5h-19C892.9,126.5,880.5,131.8,870.9,142.4z"
        />
      </g>
    </svg>
  );
}
