import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Checkbox from '../common/icons/Checkbox';

const Title = styled.div`
  padding-bottom: 5px;
  font-size: 14px;
  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 2px;
  cursor: pointer;
`;

const SectionTwo = ({ dispatch, questions }) => {
  const [checkedList, setCheckedList] = useState(new Array(questions.length).fill(false));

  const handleChange = (event, option, index) => {
    dispatch({
      type: 'CONDITION_CHECKED',
      payload: {
        checked: event.target.checked,
        value: option.weight,
        index,
      },
    });
    checkedList[index] = event.target.checked;
    setCheckedList(checkedList);
  };

  return (
    <>
      <Title>Please select all conditions which apply to the patient:</Title>
      <div style={{ marginBottom: 10 }}>
        {questions.map((f, index) => (
          <StyledLabel key={f.question}>
            <span style={{ position: 'relative', color: '#1EC0D9', height: 22, paddingRight: 10 }}>
              <Checkbox checked={checkedList[index]} />
              <input
                type="checkbox"
                onChange={e => handleChange(e, f, index)}
                style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
              />
            </span>
            <span>{f.question}</span>
          </StyledLabel>
        ))}
      </div>
    </>
  );
};

SectionTwo.propTypes = {
  dispatch: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SectionTwo;
