import { map, mergeMap, switchMap, filter, debounceTime } from 'rxjs/operators';
import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import * as formFlowActions from '../actions/formFlow.actions';
import * as mapActions from '../actions/map.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as chiefComplaintActions from '../actions/chiefComplaint.actions';
import * as agencyActions from '../actions/agency.actions';
import * as etaActions from '../actions/eta.actions';
import * as serviceActions from '../actions/service.actions';
import * as locationActions from '../actions/location.actions';
import * as patientActions from '../actions/patient.actions';
import * as requestsActions from '../actions/requests.actions';
import { openMissingFieldsModal } from '../../components/MissingFieldsModal/redux/actions';
import { setMissingFieldsBody } from '../../components/MissingFieldsModal/redux/setMissingFieldsBody';
import { loadAttachments, addAttachment, deleteAttachment } from '../../components/PatientDocuments/redux/actions';
import allInputValid from '../../validators/input.validator';
import { isNoAutoEtaRequest } from '../../validators/schedule.validator';

export const setValidEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        agencyActions.fetchPlace.match,
        agencyActions.clearAgency.match,
        chiefComplaintActions.setChiefComplaint.match,
        mapActions.setMapLocationSuccess.match,
        locationActions.clearPickupLocation.match,
        locationActions.clearDestinationLocation.match,
        locationActions.selectPickupLocationSuccess.match,
        locationActions.selectDestinationLocationSuccess.match,
        etaActions.acceptETASuccess.match,
        etaActions.requestETA.match,
        etaActions.setDateTime.match,
        requestsActions.createRequestSuccess.match,
        serviceActions.bariatricChange.match,
        serviceActions.respiratoryTherapistChange.match,
        serviceActions.rtOptionChange.match,
        serviceActions.toggleRequiredAttendant.match,
        serviceActions.serviceTypeChange.match,
        serviceActions.callTypeChange.match,
        serviceActions.setTransportReasons.match,
        serviceActions.setQuestionMode.match,
        serviceActions.setAnswer.match,
        serviceActions.equipmentAsServiceTypeChange.match,
        infoboxActions.clearPayer.match,
        infoboxActions.setPayerInfo.match,
        loadAttachments.match,
        addAttachment.match,
        deleteAttachment.match,
        infoboxActions.setPatientInfo.match,
        infoboxActions.setPhone.match,
        infoboxActions.setBirthday.match,
        infoboxActions.setRequesterInfo.match,
        infoboxActions.setRequesterPhone.match,
        infoboxActions.setPatientDetails.match,
        infoboxActions.loadRequestDetails.match,
        infoboxActions.setNotes.match,
        formFlowActions.loadRequestDetails.match,
        patientActions.setPatientInfo.match,
        patientActions.setPhone.match,
      ].some(match => match(action)),
    ),
    switchMap(action => {
      const requestOrWillCall =
        store.value.requests.request.id || (store.value.requests.willCall && !store.value.service.questionMode);

      return from$(allInputValid(store.value, requestOrWillCall)).pipe(
        map(isValid => formFlowActions.setValid({ isValid, byType: action.type })),
      );
    }),
  );

export const setDirtyEpic = action$ =>
  action$.pipe(
    filter(action =>
      [
        agencyActions.fetchPlace.match,
        mapActions.setMapLocationSuccess.match,
        infoboxActions.clearPayer.match,
        infoboxActions.setPatientInfo.match,
        infoboxActions.setPhone.match,
        infoboxActions.setBirthday.match,
        infoboxActions.setNotes.match,
        etaActions.requestETA.match,
        etaActions.setDateTime.match,
        etaActions.setAppmtTime.match,
        etaActions.setPreferredTimeOfDay.match,
        formFlowActions.loadRequestDetails.match,
        serviceActions.respiratoryTherapistChange.match,
        serviceActions.rtOptionChange.match,
        serviceActions.toggleRequiredAttendant.match,
        serviceActions.serviceTypeChange.match,
        serviceActions.callTypeChange.match,
        serviceActions.setTransportReasons.match,
        locationActions.setRoomNumber.match,
      ].some(match => match(action)),
    ),
    map(() => formFlowActions.setDirty(true)),
  );

const changeRequiresNewETA = state =>
  !!(
    state.eta.offeredEtas.length ||
    state.requests.request.isScheduled ||
    state.eta.scheduled ||
    state.eta.pollInProgress
  );

export const changeRequiresNewETAEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        etaActions.acceptETA.match,
        etaActions.acceptETASuccess.match,
        etaActions.requestETASuccess.match,
        etaActions.requestETA.match,
      ].some(match => match(action)),
    ),
    map(() => formFlowActions.setChangeRequiresNewETA(changeRequiresNewETA(store.value))),
  );

export const setSubmitInProgressEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.submitPatientInfo.match),
    filter(() => !store.value.formFlow.autoSaveInProgress),
    map(() => formFlowActions.setSubmitInProgress(true)),
  );

export const rejectETASuccessEpic = action$ =>
  action$.pipe(
    filter(etaActions.rejectETASuccess.match),
    map(() => formFlowActions.rejectETASuccess()),
  );

export const toggleInProgressStateEpic = action$ =>
  action$.pipe(
    filter(action =>
      [formFlowActions.setAcceptETAInProgress.match, formFlowActions.setSubmitInProgress.match].some(match =>
        match(action),
      ),
    ),
    map(() => formFlowActions.toggleInProgressState()),
  );

export const enableAutoSaveEpic = (action$, store) =>
  action$.pipe(
    filter(formFlowActions.autoSave.match),
    filter(() => !store.value.formFlow.submitInProgress),
    debounceTime(200),
    mergeMap(() => concat$(of$(formFlowActions.autoSaveInProgress(true)), of$(infoboxActions.submitPatientInfo()))),
  );

export const setNoAutoEtaRequestEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        etaActions.setDateTime.match,
        etaActions.setEtaType.match,
        serviceActions.mobilityChange.match,
        serviceActions.serviceTypeChange.match,
      ].some(match => match(action)),
    ),
    map(() => formFlowActions.setNoAutoEtaRequest(isNoAutoEtaRequest(store.value))),
  );

export const resetSaveMessageEpic = action$ =>
  action$.pipe(
    filter(formFlowActions.setSaveMessage.match),
    filter(action => action.payload),
    debounceTime(3000),
    map(() => formFlowActions.setSaveMessage('')),
  );

export const openSaveDialogEpic = (action$, store) =>
  action$.pipe(
    filter(formFlowActions.setTriggerValidation.match),
    filter(action => action.payload && store.value.formFlow.saveClicked),
    debounceTime(400),
    mergeMap(() =>
      concat$(of$(formFlowActions.setSaveClicked(false)), of$(setMissingFieldsBody()), of$(openMissingFieldsModal())),
    ),
  );

export const openSimilarRequestFoundDialogEpic = action$ =>
  action$.pipe(
    filter(requestsActions.getSimilarRequestsSuccess.match),
    filter(action => action.payload && !action.payload.error),
    map(action => formFlowActions.setSimilarRequestFoundDialog(action.payload)),
  );
