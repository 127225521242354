import * as orderActions from '../../../actions/orders/order.actions';
import * as formFlowActions from '../../../actions/formFlow.actions';

const clickSaveButtonMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (action.type !== orderActions.saveClick.match(action)) {
      return;
    }

    dispatch(orderActions.setcreateInProgress(true));
    dispatch(orderActions.validateForm());
  };

const setValidMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (action.type !== orderActions.setValid.match(action)) {
      return;
    }

    if (action.payload.valid && !getState().missingFieldsModal.hasErrors) {
      dispatch(orderActions.create());
    } else {
      dispatch(formFlowActions.setSaveClicked(true)); // do we need this?
      dispatch(formFlowActions.setTriggerValidation(true));
      dispatch(orderActions.setcreateInProgress(false));
    }
  };

export default [clickSaveButtonMdl, setValidMdl];
