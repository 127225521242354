import React from 'react';
import { connect } from 'react-redux';
import PatientDocumentsFrame from './frame';
import * as actions from './redux/actions';
import * as infoboxActions from '../../redux/actions/infobox.actions';
import formatTitle from './helpers/titleHelper';
import useAttachmentTypes from './useAttachmentTypes.hook';
import { isPatientPresent } from '../../redux/epics/helpers/patient.helper';

function PatientDocumentsContainer({
  setAttachment,
  addAttachment,
  removeAttachment,
  deleteAttachment,
  patientDocumentsDisabled,
  attachmentTypes,
  ...props
}) {
  const onChange = file => {
    setAttachment(file);
    addAttachment(file);
  };
  const onRemove = file => {
    deleteAttachment(file);
  };

  const filteredAttachmentTypes = useAttachmentTypes(attachmentTypes, props);

  if (props.isRequester) {
    props.attachments = props.attachments.filter(att => att.attachmentType.type !== 'PCR');
  }

  return (
    <PatientDocumentsFrame
      attachmentTypes={filteredAttachmentTypes}
      onChange={onChange}
      onRemove={onRemove}
      disabled={patientDocumentsDisabled || null}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  return {
    ...state.patientDocuments,
    title: formatTitle(state),
    deletable: !state.requests.request.exportedToZoll,
    patientPresent: isPatientPresent(state),
    isRequester: state.user.model.isRequester,
  };
}

export default connect(mapStateToProps, { ...infoboxActions, ...actions })(PatientDocumentsContainer);
