import { createAction } from '@reduxjs/toolkit';

export const addEmailRecipient = createAction('[RequestShare] addEmailRecipient');

export const reducer = {
  [addEmailRecipient]: (state, recipient) => ({
    ...state,
    emailRecipients: [...state.emailRecipients, recipient.trim()],
  }),
};
