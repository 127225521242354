import {
  missingLocationMessage,
  missingPatientAddressMessage,
  missingPayerMessage,
  payerNotAllowedMessage,
  zollMsg,
  missingChiefComplaintMessage,
  chiefComplaintNotAllowedMessage,
  missingPatientName,
  changesNotSaved,
  missingPatientWeightHeight,
} from '../messages';
import {
  prebillerVerifiedLocationMissing,
  patientAddressMissing,
  prebillerVerifiedPayerMissing,
  disabledByPcrStatus,
  chiefComplaintMissing,
  chiefComplaintNotAllowed,
  payerNotAllowed,
  patientInvalid,
  changesNotSavedYet,
  patientWeightHeightMissing,
} from '../flags/prebilling.flag';

const getBillerVerifiedTooltip = state => {
  let message = [];

  if (prebillerVerifiedLocationMissing(state)) message.push(missingLocationMessage);
  if (patientAddressMissing(state)) message.push(missingPatientAddressMessage);
  if (patientWeightHeightMissing(state)) message.push(missingPatientWeightHeight);
  if (prebillerVerifiedPayerMissing(state)) message.push(missingPayerMessage);
  if (payerNotAllowed(state)) message.push(payerNotAllowedMessage);
  if (disabledByPcrStatus(state)) message = [zollMsg];
  if (chiefComplaintMissing(state)) message.push(missingChiefComplaintMessage);
  if (chiefComplaintNotAllowed(state)) message.push(chiefComplaintNotAllowedMessage);
  if (patientInvalid(state)) message.push(missingPatientName);
  if (changesNotSavedYet(state)) message.push(changesNotSaved);

  return message.join('\n');
};
export default getBillerVerifiedTooltip;
