import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

import { duplicateRequest } from '../../../../redux/epics/helpers/request.helper';
import * as duplicateRequestActions from './duplicateRequest.actions';

const duplicateRequestEpic = (action$, store) =>
  action$.pipe(
    filter(duplicateRequestActions.duplicate.match),
    filter(() => !store.value.duplicateRequest.completed),
    debounceTime(200),
    switchMap(() => duplicateRequest(store)),
    map(payload => duplicateRequestActions.complete(payload)),
  );

export default duplicateRequestEpic;
