import { isTimeBeyondMaxDate } from '../../validators/schedule.validator';
import { providerHasAutoEta } from '../../parsers/request.parser';

export const scheduledTitle = 'Trip Successfully Saved & Scheduled!';
export const scheduledBody = 'Trip saved and scheduled. It can now be closed.';
export const needsAssignmentTitle = 'Trip Successfully Saved!';
export const needsAssignmentBody = 'Trip saved but not yet scheduled.';
export const willCallTitle = 'Will Call Successfully Saved!';
export const willCallBody = 'Trip saved and can now be closed.';

export function getConfirmationText(state) {
  const { request } = state.requests;
  if (request.isScheduled || (state.formFlow.etaWasAccepted && isTimeBeyondMaxDate(state))) {
    return {
      title: scheduledTitle,
      body: scheduledBody,
    };
  }

  if (request.needsAssignment) {
    return {
      title: needsAssignmentTitle,
      body: needsAssignmentBody,
    };
  }

  if (request.isWillCallStatus) {
    return {
      title: willCallTitle,
      body: willCallBody,
    };
  }

  return {
    title: 'Trip Successfully Saved',
    body: 'Trip saved and can now be closed.',
  };
}

export const saveButtonDisabled = state =>
  !state.app.online ||
  state.infobox.isSubmitPatientInfoInProgress ||
  state.formFlow.autoSaveInProgress ||
  state.formFlow.acceptETAInProgress;

export const inProgress = state => state.formFlow.submitInProgress && state.formFlow.autoSaveInProgress;

export const showSaveButton = state =>
  !!(!inProgress(state) && (state.requests.request.id || state.requests.willCall || !providerHasAutoEta(state)));
