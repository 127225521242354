import { from as from$ } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import RequestChargeService from '../../services/requestCharge.service';
import * as asyncActions from '../actions/async.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as requestsActions from '../actions/requests.actions';
import * as routeActions from '../actions/route.actions';
import { errorPipe } from './epicsUtil';
import { getComment } from './helpers/comment.helper';
import { getCoverages } from './helpers/coverage.helper';
import { getRequestById, getSchedule, getShareLink } from './helpers/request.helper';

export const routeToLoadRequestEpic = action$ =>
  action$.pipe(
    filter(routeActions.atEditRequest.match),
    map(action => requestsActions.editRequest(action.payload)),
  );

export const loadRequestEpic = action$ =>
  action$.pipe(
    filter(requestsActions.editRequest.match),
    switchMap(action => getRequestById(action.payload)),
    map(response => requestsActions.editRequestSuccess(response)),
  );

export const getRequestChargesEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.getRequestCharges.match),
    filter(() => store.value.requests.request.isActive || store.value.requests.request.isCompleted),
    switchMap(action =>
      from$(RequestChargeService.getRequestCharges(action.payload)).pipe(
        map(response => requestsActions.getRequestChargesSuccess(response)),
      ),
    ),
  );

export const loadCommentsEpic = action$ =>
  action$.pipe(
    filter(asyncActions.asyncLoadComments.match),
    switchMap(action => getComment(action.payload)),
    map(response => asyncActions.asyncLoadCommentsSuccess(response)),
  );

export const loadShareLinkEpic = (action$, store) =>
  action$.pipe(
    filter(asyncActions.asyncGetShareLink.match),
    switchMap(() => getShareLink(store)),
    map(response => requestsActions.loadShareLink(response)),
    errorPipe,
  );

export const loadShareLinkSuccessEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.loadShareLink.match),
    filter(action => action.payload.accessToken),
    switchMap(() => getShareLink(store)),
    map(response => requestsActions.createShareLinkSuccess(response)),
    errorPipe,
  );

export const loadShareLinkFailureEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.loadShareLink.match),
    filter(action => !action.payload.accessToken),
    switchMap(() => getShareLink(store)),
    map(response => requestsActions.createShareLink(response)),
    errorPipe,
  );

export const getScheduleOnLoadEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.getSchedule.match),
    switchMap(() => getSchedule(store)),
    map(response => requestsActions.getScheduleSuccess(response)),
    errorPipe,
  );

export const getCoveragesOnLoadEpic = (action$, store) =>
  action$.pipe(
    filter(requestsActions.loadCoverages.match),
    switchMap(() => getCoverages(store)),
    map(response =>
      infoboxActions.loadCoverages({
        ...store.value.requests.request,
        coverages: response.coverages,
      }),
    ),
    errorPipe,
  );
