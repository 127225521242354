import { get, flatten } from 'lodash';
import moment from 'moment';
import { Calc, SCHEDULE_STATE, POLL_IN_PROGRESS_REASONS } from '../enums';
import requestSchema from '../schemas/request.schema';
import { getTimeFromSegment } from '../redux/reducers/schedule.reducer';
import { dateFormat, timeFormatWithTimezone } from '../helpers/dateFormatter';
import { buildManualPickupSecs } from './schedule.parser';

function parseETAData(candidateSchedule, candidateWarning) {
  const {
    unit: unitId,
    eta,
    segments,
    current_plan_id: candidatePlanId,
    candidate_id: candidateId,
    conflicts,
  } = candidateSchedule;
  const totalSeconds = segments.reduce((sum, { seconds }) => sum + seconds, 0);
  const duration = moment.duration(totalSeconds, 's');
  const taskTime = `${duration.hours()}h ${+duration.minutes()}m`;
  const totalDriveMeters = segments.reduce((sum, { meters }) => sum + (meters || 0), 0);
  const totalDrive = Math.ceil(totalDriveMeters / Calc.MILES);
  const overridableConflicts = conflicts.reduce((memo, conflict) => {
    if (conflict.request) {
      conflict.request = requestSchema.parse(conflict.request);
      memo.push(conflict);
    }
    return memo;
  }, []);
  const fareAmount = get(candidateSchedule, 'fare.amount');
  const returnFareAmount = get(candidateSchedule, 'returnRequestFare.amount');
  const atDropoffTime = getTimeFromSegment(segments, SCHEDULE_STATE.AT_DROP_OFF) || candidateSchedule.end_at;

  return {
    eta: timeFormatWithTimezone(eta),
    pickupTime: moment(eta).toISOString(),
    unitId,
    taskTime,
    totalDrive,
    showEtaProgress: false,
    pollInProgress: false,
    candidatePlanId,
    candidateId,
    conflicts: overridableConflicts,
    fareAmount,
    returnFareAmount,
    atDropoffTime,
    candidateWarning,
    displayWindowEndAt: timeFormatWithTimezone(candidateSchedule.display_window_end_at),
    displayWindowStartAt: timeFormatWithTimezone(candidateSchedule.display_window_start_at),
    displayWindowDay: dateFormat(candidateSchedule.display_window_start_at, 'ddd, MMM D'),
  };
}

export function noEtaResponse({
  rejectReason = POLL_IN_PROGRESS_REASONS.NO_RESOLVABLE_CONFLICTS,
  pollInProgressReason = POLL_IN_PROGRESS_REASONS.NO_AUTO_ETA,
} = {}) {
  return {
    showEtaProgress: false,
    pollInProgress: true,
    pollInProgressReason,
    rejectReason,
  };
}

export function appointmentTimeTooSoonResponse() {
  return noEtaResponse({
    pollInProgressReason: POLL_IN_PROGRESS_REASONS.APPMT_CANNOT_BE_MET,
    rejectReason: POLL_IN_PROGRESS_REASONS.APPMT_REJECT_REASON,
  });
}

export function parseETA(payload, candidateIndex = 0) {
  const { candidateSchedules, candidateWarnings } = payload;
  const candidate = candidateSchedules[candidateIndex];
  if (!candidate) {
    return {
      id: payload.id,
      type: payload.type,
      name: payload.name,
      displayName: payload.displayName,
      attachment: payload.attachment,
      candidateIndex,
      conflicts: [],
      candidateSchedules: [],
      candidateWarnings: [],
    };
  }

  const candidateWarning = candidateWarnings.find(
    singleCandidateWarning => singleCandidateWarning.schedule_id === candidate.id,
  );

  return {
    ...payload,
    ...parseETAData(candidate, candidateWarning),
    candidateIndex: candidateIndex + 1,
  };
}

function parseOffer(etaData) {
  if (!etaData.candidateOffers) {
    return null;
  }

  const offer = etaData.candidateOffers[0];

  if (!offer) {
    return null;
  }

  return {
    id: offer.id,
    name: etaData.name,
    displayName: etaData.displayName,
    attachment: etaData.attachment,
    providerId: etaData.id,
    actionButtonText: offer.actionButtonText || 'Offer',
    descriptionText: offer.descriptionText || '',
  };
}

export function parseSortedUnits(payload) {
  const eta = payload.find(eta => eta.type === 'provider');
  const ambulnzBest = get(eta, 'sortedUnits');
  if (!ambulnzBest) {
    return [];
  }
  return ambulnzBest;
}

export function parseETAs(etas, parseMulti) {
  const allCandidates = flatten(etas.map(eta => eta.candidateSchedules));
  const allOffers = flatten(etas.map(eta => eta.candidateOffers || []));

  if (!allCandidates.length && !allOffers.length) {
    return noEtaResponse();
  }

  try {
    return {
      offeredEtas: parseMulti
        ? allCandidates.map(eta => ({ ...eta, id: eta.candidate_id, ...parseETAData(eta) }))
        : etas.map(eta => parseETA(eta)),
      offers: etas.map(eta => parseOffer(eta)).filter(Boolean),
      showEtaProgress: false,
      pollInProgress: false,
    };
  } catch (error) {
    console.error(error);
    return noEtaResponse();
  }
}

export function parseGetETAParams(state) {
  let serviceByDate;
  if (state.eta.requestedArrivalTime) {
    [serviceByDate] = state.eta.requestedArrivalTime.split('T');
  }
  return {
    targetTime: state.eta.requestedArrivalTime,
    serviceByDate,
    etaOptions: {
      pickupLat: state.map.fromWaypoint.lat,
      pickupLng: state.map.fromWaypoint.lng,
      pickupGooglePlaceId: state.map.directions && state.map.directions.geocoded_waypoints[0].place_id,
      dropOffLng: state.map.toWaypoint.lng,
      dropOffLat: state.map.toWaypoint.lat,
      dropOffGooglePlaceId: state.map.directions && state.map.directions.geocoded_waypoints[1].place_id,
      requesterAgencyId: state.agency.requesterAgencyId,
      serviceTypeIds: state.service.serviceTypeIds,
      therapistEquipmentIds: state.service.therapistEquipmentIds,
      manualPickupSecs: +buildManualPickupSecs(state),
      manualDropOffSecs: +state.location.manualDropOffSecs,
      requirementIds: state.service.requirementIds,
      enforcePromisedWindow: state.eta.enforcePromisedWindow,
      preferredTimeOfDay: state.eta.preferredTimeOfDay.toLowerCase(),
    },
  };
}

export function parseNoAutoETAPayload(state) {
  return {
    offeredEtas: [
      {
        id: 1,
        type: 'provider',
        name: 'Ambulnz',
        conflicts: [],
        candidateSchedules: [],
        eta: timeFormatWithTimezone(state.eta.requestedArrivalTime),
        pickupTime: state.eta.requestedArrivalTime,
      },
    ],
    showEtaProgress: false,
    pollInProgress: false,
  };
}
