import { createReducer } from 'redux-act';
import * as action from '../actions/user.actions';
import user from '../../schemas/user.schema';
import { jwtDecode } from 'jwt-decode';

export const initialState = {
  model: user.parse({}),
  facilities: [],
  selectedPatientExternalId: null,
  fhirPatient: null,
  isUserAmbAdmin: false,
};

const userReducer = createReducer(
  {
    [action.fetchCurrentUserSuccess]: (state, payload) => ({
      ...state,
      model: user.parse(payload),
    }),
    [action.fetchUserFacilitiesSuccess]: (state, payload) => ({
      ...state,
      facilities: payload.facilities,
    }),
    [action.selectPatientExternalId]: (state, payload) => ({
      ...state,
      selectedPatientExternalId: payload,
    }),
    [action.fetchAccessTokenSuccessfully]: (state, payload) => {
      const {
        fhir_patient: fhirPatient,
        realm_access: { roles: realmRoles },
      } = jwtDecode(payload);
      const isUserAmbAdmin = realmRoles.includes('amb_admin');
      return {
        ...state,
        fhirPatient,
        isUserAmbAdmin,
      };
    },
  },
  initialState,
);

export default userReducer;
