import ApiService from './api.service';

export default {
  baseUrl: () => window.ambulnzConfig.tenantedRestApiUrl,

  async getRequestCharges(requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/RequestCharges/?`;
    let response = await ApiService.get(url, { requestId });

    if (response.status === 404) {
      return null;
    }

    response = response.json();

    return response;
  },
};
