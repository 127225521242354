import ApiService from '../api.service';

export default {
  baseUrl: () => window.ambulnzConfig.orderServiceUrl,

  async getLocationTypes() {
    const response = await ApiService.get(`${this.baseUrl()}location_types`);
    return response.json();
  },

  async getOrderTypes() {
    const response = await ApiService.get(`${this.baseUrl()}order_types`);
    return response.json();
  },
  async getCommentTypes() {
    const response = await ApiService.get(`${this.baseUrl()}comment_types`);
    return response.json();
  },
  async getEnums() {
    const [locationTypes, orderTypes] = await Promise.all([this.getLocationTypes(), this.getOrderTypes()]);

    const locationTypesMap = locationTypes.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [item.id]: item,
          [item.name]: item,
        }),
      {},
    );

    const orderTypesMap = orderTypes.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [item.id]: item,
          [item.name]: item,
        }),
      {},
    );

    const map = {
      locationTypesMap,
      orderTypesMap,
    };

    return map;
  },
};
