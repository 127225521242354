import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import MaskedSsnInput from './MaskedSsnInput';
import AmbInputLabel from 'components/common/AmbInputLabel';

const StyledWrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 11px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: ${props => (props.isError ? '#f44336' : '#004053')};
  }
`;

const StyledInput = styled(MaskedSsnInput)`
  font: inherit;
  font-size: 14px;
  color: currentColor;
  outline: none;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 2px 0;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
`;

const InputComponent = ({ id, name, value, onChange, onBlur, disabled, error }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = e => {
    onBlur(e);
    setIsFocused(false);
  };
  return (
    <>
      <StyledWrapper isFocused={isFocused} isError={error}>
        <StyledInput
          id={id}
          name={name}
          value={value}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          onChange={onChange}
          disabled={disabled}
        />
      </StyledWrapper>
    </>
  );
};

const SsnInput = ({ inputLabelProps, value, onChange, onBlur, error }) => (
  <>
    <AmbInputLabel htmlFor="PatientSsnInput" shrink={inputLabelProps.shrink} style={inputLabelProps.style}>
      SSN
    </AmbInputLabel>
    <InputComponent
      fullWidth
      id="PatientSsnInput"
      name="patientSsn"
      value={value}
      onChange={onChange}
      error={error}
      onBlur={onBlur}
    />
  </>
);

SsnInput.defaultProps = {
  inputLabelProps: {},
};

SsnInput.propTypes = {
  inputLabelProps: PropTypes.shape(),
};

export default SsnInput;
