import { createReducer } from 'redux-act';
import * as action from '../actions/patient.actions';
import parsePhone from '../../parsers/phone.parser';
import { parseLastSearch } from '../epics/helpers/patient.helper';

const initialState = {
  patients: [],
  selectedPatient: null,
  showPatientModal: false,
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  dirty: false,
  searchInProgress: false,
};

const last = state => state.slice(-1)[0];
const loadPatient = (patient, item) => (patient ? last(patient.demographics)[item] || '' : '');

const patientReducer = createReducer(
  {
    [action.setPatientInfo]: (state, payload) => ({
      ...state,
      ...payload,
    }),
    [action.setPhone]: (state, payload) => ({
      ...state,
      phone: parsePhone(payload),
    }),
    [action.updatePatientsList]: (state, payload) => ({
      ...state,
      patients: payload,
    }),
    [action.controlPatientModal]: (state, payload) => ({
      ...state,
      showPatientModal: payload,
    }),
    [action.selectPatient]: (state, payload) => ({
      ...state,
      selectedPatient: payload,
      dirty: false,
    }),
    [action.selectPatientFromExternal]: (state, payload) => ({
      ...state,
      selectedPatient: payload,
      dirty: false,
    }),
    [action.loadPatient]: (state, payload) => ({
      ...state,
      address1: loadPatient(payload, 'address1'),
      address2: loadPatient(payload, 'address2'),
      city: loadPatient(payload, 'city'),
      state: loadPatient(payload, 'state'),
      zip: loadPatient(payload, 'zip'),
      phone: loadPatient(payload, 'phone'),
    }),
    [action.setDirty]: (state, payload) => ({
      ...state,
      dirty: payload,
    }),
    [action.saveSearch]: (state, payload) => ({
      ...state,
      lastSearch: parseLastSearch(payload),
    }),
    [action.setSearchInProgress]: (state, payload) => ({
      ...state,
      searchInProgress: payload,
    }),
  },
  initialState,
);

export default patientReducer;
