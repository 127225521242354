import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { RequestStatus } from '../../enums';
import RequestService from '../../services/request.service';
import * as etaActions from '../actions/eta.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as requestsActions from '../actions/requests.actions';
import * as willCallActions from '../actions/willCall.actions';
import { errorPipe } from './epicsUtil';
import * as WillCallHelper from './helpers/willCall.helper';
import { getRequestId } from './updateRequestUtil';

export const updateWillCallEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [willCallActions.setWillCall.match, requestsActions.asyncSetRequestCanBeActivatedByPatient.match].some(match =>
        match(action),
      ),
    ),
    filter(() => getRequestId(store)),
    switchMap(action => {
      return WillCallHelper.updateRequest(
        getRequestId(store),
        action.payload,
        store.value.eta.requestedArrivalTime,
        store.value.requests.request.canRequestBeActivatedByPatient,
      );
    }),
    map(response => requestsActions.updateRequestSuccess(response)),
    errorPipe,
  );

export const unReserveSetToWillCallEpic = (action$, store) =>
  action$.pipe(
    filter(willCallActions.unReserveSetWillCall.match),
    switchMap(() => WillCallHelper.unReserve(getRequestId(store))),
    mergeMap(response =>
      concat$(
        of$(requestsActions.updateRequestSuccess(response)),
        of$(etaActions.resetETA()),
        of$(formFlowActions.rejectETASuccess()),
      ),
    ),
    errorPipe,
  );

const returnTripArrivalTime = state => state.requests.request.returnTripArrivalTime(state.eta.requestedArrivalTime);

export const updateReturnRequestUnsetWillCallEpic = (action$, store) =>
  action$.pipe(
    filter(willCallActions.setWillCall.match),
    filter(action => action.payload === false && store.value.requests.request.returnRequestId),
    switchMap(() =>
      from$(
        RequestService.updateRequest(store.value.requests.request.returnRequestId, {
          status: RequestStatus.NEEDS_ASSIGNMENT,
          requestedArrivalTime: returnTripArrivalTime(store.value),
        }),
      ).pipe(
        map(response => requestsActions.updateReturnRequestSuccess(response)),
        errorPipe,
      ),
    ),
  );
