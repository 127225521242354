import schema from 'schm';
import { get } from 'lodash';
import computed from 'schm-computed';

const offer = schema(
  {
    provider: Object,
    offerStatus: Object,
    trip: Object,
    time: String,
    id: Number,
  },
  computed({
    statusName: values => get(values, ['offerStatus', 'name']),
    attachment: values => get(values, ['provider', 'attachment', 'downloadUrl']),
  }),
);

export default offer;
