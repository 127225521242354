import { createAction } from '@reduxjs/toolkit';

export const deleteEmailRecipient = createAction('[RequestShare] deleteEmailRecipient');

export const reducer = {
  [deleteEmailRecipient]: (state, recipient) => ({
    ...state,
    emailRecipients: state.emailRecipients.filter(el => el !== recipient),
  }),
};
