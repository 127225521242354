import withValidations from '../../common/withValidations';
import styledFrame from './Requester.frame';

const validations = {
  requesterFirstName: value => {
    if (!value) {
      return 'Requester must have a first name';
    }
    return null;
  },
  requesterLastName: value => {
    if (!value) {
      return 'Requester must have a last name';
    }
    return null;
  },
  requesterPhone: value => {
    if (!value) {
      return 'Requester must have a phone number';
    }
    if (value.length < 14) {
      return 'Requester phone number must have all digits';
    }
    return null;
  },
};
export default withValidations(validations)(styledFrame);
