import ApiService from './api.service';

// eslint-disable-next-line import/prefer-default-export
export async function getUserAccounts() {
  const path = `${window.ambulnzConfig.orderServiceUrl}accounts/info`;
  const response = await ApiService.get(path);

  return response.json();
}

export async function getLoginMechanism() {
  const path = `${window.ambulnzConfig.orderServiceUrl}accounts/current`;
  const response = await ApiService.get(path);

  return response.json();
}

function refreshWithAccount(account) {
  const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  window.location.replace(`${url}?selectedAccountId=${account.id}`);
}

export async function applySelectedUserAccount(account) {
  refreshWithAccount(account);
}

export async function getAccountHostNames(accountId) {
  const path = `${window.ambulnzConfig.orderServiceUrl}accounts/${accountId}/hostnames`;
  const response = await ApiService.get(path);

  return response.json();
}
