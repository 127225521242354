import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuestionsFrame from './Questions.frame';
import * as serviceActions from '../../../redux/actions/service.actions';
import * as requestActions from '../../../redux/actions/requests.actions';
import * as titles from '../ServiceLevel.titles';

const QuestionsContainer = props => {
  const {
    setAnswer,
    mobilityChange,
    serviceTypeChange,
    setQuestionMode,
    roundTripChange,
    mobilities,
    serviceTypes,
  } = props;

  const handleAnswerChange = e => {
    setAnswer({ [e.target.name]: e.target.value });
  };

  const handleTravelByCarChange = e => {
    handleAnswerChange(e);

    if (+e.target.value === mobilities.walk) {
      mobilityChange(Number(mobilities.walk));
      serviceTypeChange(Number(serviceTypes.Livery) || Number(serviceTypes.Curbside));
      setQuestionMode(false);
      roundTripChange({ crewMustWaitForPatient: false });
    }
  };

  const handleWheelchairChange = e => {
    handleAnswerChange(e);

    if (+e.target.value === mobilities.wheelchair) {
      mobilityChange(Number(mobilities.wheelchair));
      setQuestionMode(false);
    }
  };

  const handleBedboundChange = e => {
    handleAnswerChange(e);
    mobilityChange(Number(e.target.value));
    setQuestionMode(false);
  };

  return (
    <QuestionsFrame
      handleTravelByCarChange={handleTravelByCarChange}
      handleWheelchairChange={handleWheelchairChange}
      handleBedboundChange={handleBedboundChange}
      canTravelByCarDisabled={!serviceTypes.Curbside && !serviceTypes.Livery}
      requiresWheelChairDisabled={!mobilities.wheelchair}
      isBedboundDisabled={!mobilities.bedbound}
      {...props}
    />
  );
};

QuestionsContainer.propTypes = {
  roundTripChange: PropTypes.func.isRequired,
  setAnswer: PropTypes.func.isRequired,
  mobilityChange: PropTypes.func.isRequired,
  serviceTypeChange: PropTypes.func.isRequired,
  setQuestionMode: PropTypes.func.isRequired,
  mobilities: PropTypes.shape().isRequired,
  serviceTypes: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  const { service } = state;
  return {
    answers: service.answers,
    callTypeId: service.callTypeId,
    mobilities: service.mobilities,
    serviceTypes: service.serviceTypes,
    canTravelByCarTitle: titles.canTravelByCarTitle(state),
    requiresWheelChairTitle: titles.requiresWheelChairTitle(state),
  };
}

export default connect(
  mapStateToProps,
  {
    ...serviceActions,
    roundTripChange: requestActions.roundTripChange,
  },
)(QuestionsContainer);
