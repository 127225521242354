import { asyncUnassignTripFromProviderResponse, unassignTripSuccessfullyDone, unassignTripHasFailed } from './actions';

const asyncUnassignRequestResponseMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (asyncUnassignTripFromProviderResponse.match(action)) {
      if (action.payload.status >= 400 || action.payload.error) {
        dispatch(unassignTripHasFailed());
      } else {
        dispatch(unassignTripSuccessfullyDone());
      }
    }
  };

export default [asyncUnassignRequestResponseMdl];
