import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EligibilityFrame from './Eligibility.frame';
import * as eligibilityActions from '../../redux/actions/eligibility.actions';

class EligibilityContainer extends React.Component {
  isLoading = () =>
    Object.values(this.props.eligibilities)
      .map(eligibility => eligibility.isEligibilityCreating && eligibility.isEligibilityLoading)
      .reduce((acc, value) => acc || value, false);

  render() {
    return <EligibilityFrame {...this.props} isLoading={this.isLoading()} />;
  }
}

EligibilityContainer.propTypes = {
  eligibilities: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  return {
    errorMessage: state.eligibility.errorMessage,
    portalInfo: state.eligibility.portalInfo,
    eligibilities: state.eligibility.eligibilities,
  };
}

export default connect(
  mapStateToProps,
  { ...eligibilityActions },
)(EligibilityContainer);
