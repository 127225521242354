import _ from 'lodash';
import { setHARIntoErrorState } from '../helpers/httpErrorHarConverter.js';
class ApiService {
  token;

  static async refreshToken() {
    const token = await window.DocgoAuth.getAccessToken();
    this.token = `Bearer ${token}`;
  }

  static async makeRequest(url, method, body, parameters = {}) {
    await this.refreshToken();

    const headers = {
      'x-ambulnz-web': true,
      'Content-Type': 'application/json',
    };

    if (parameters.noCache) {
      Object.assign(headers, {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        'If-Modified-Since': 0,
      });
    }

    if (this.token) {
      headers.Authorization = this.token;
    }

    const params = { method, headers };

    if (body) {
      params.body = JSON.stringify(body);
    }

    return fetch(url, params).then(async response => {
      if (response.status >= 400 && response.status < 600) {
        setHARIntoErrorState(response, params, url);
      }
      return response;
    });
  }

  static get(url, payload, parameters = {}) {
    if (payload) {
      url += this.objectToURLQueryString(payload);
    }

    return this.makeRequest(url, 'GET', null, parameters);
  }

  static post(url, data) {
    return this.makeRequest(url, 'POST', data);
  }

  static delete(url) {
    return this.makeRequest(url, 'DELETE');
  }

  static put(url, data) {
    return this.makeRequest(url, 'PUT', data);
  }

  static patch(url, data) {
    return this.makeRequest(url, 'PATCH', data);
  }

  static async postFile(url, data) {
    await this.refreshToken();

    const headers = {
      Authorization: this.token,
    };

    if (data.type) {
      headers['Content-Type'] = data.type;
    }

    return fetch(url, {
      method: 'POST',
      body: data,
      headers,
    });
  }

  static objectToURLQueryString(obj) {
    return Object.entries(obj)
      .map(([key, val]) => {
        if (_.isPlainObject(val)) {
          return Object.entries(val)
            .map(([nkey, nval]) => `${key}[${nkey}]=${encodeURIComponent(nval)}`)
            .join('&');
        }
        return `${key}=${encodeURIComponent(val)}`;
      })
      .join('&');
  }
}

export default ApiService;
