import agency from './agency.middleware';
import autoSave from './autoSave.middleware';
import request from './request.middleware';
import comment from './comment.middleware';
import error from './error.middleware';
import requestShare from './requestShare.middleware';

const middlewares = [...agency, ...autoSave, ...request, ...comment, ...error, ...requestShare];

export default middlewares;
