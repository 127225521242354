import * as componentActions from '../../../components/PatientInfoBoxWidget/PatientDetailsWidget/PatientAdditionalInfoWidget/PatientAdditionalInfo.actions';
import * as patientActions from '../../actions/patient.actions';

const inputChange =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (componentActions.inputChange.match(action)) {
      if (action.payload.phone) {
        return dispatch(patientActions.setPhone(action.payload.phone.replace(/\D/g, '')));
      }
      dispatch(patientActions.setPatientInfo(action.payload));
    }
  };

const middlewares = [inputChange];
export default middlewares;
