import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../../common/AmbGrid';
import { SectionHeader } from '../elements';
import AmbToggleButton from '../../common/AmbToggleButton';

const Equipment = ({
  equipment,
  handleEquipmentChange,
  disabled,
  equipmentAsService,
  serviceTypeIds,
  handleEquipmentAsServiceTypeChange,
  therapistEquipmentIds,
  bariatricEquipmentId,
  bariatricDisabled,
}) => {
  return (
    <>
      <SectionHeader>Equipment</SectionHeader>
      <AmbGrid style={{ flexWrap: 'wrap', gap: '6px', marginBottom: 16 }}>
        <>
          {equipment.map(e => (
            <AmbToggleButton
              label={e.label}
              checked={therapistEquipmentIds.includes(e.id)}
              onChange={on => handleEquipmentChange(e.id, on)}
              key={e.id}
              disabled={disabled || null}
              tooltip={e.sublabel}
            />
          ))}
        </>
        <>
          {equipmentAsService.map(e => {
            const isFrozen = bariatricDisabled && e.id === bariatricEquipmentId;
            return (
              <AmbToggleButton
                label={e.label}
                checked={serviceTypeIds.includes(e.id)}
                onChange={one => handleEquipmentAsServiceTypeChange(e.id, one)}
                key={e.id}
                disabled={disabled || null}
                isFrozen={isFrozen}
                tooltip={e.sublabel}
              />
            );
          })}
        </>
      </AmbGrid>
    </>
  );
};
Equipment.propTypes = {
  equipment: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleEquipmentChange: PropTypes.func.isRequired,
  disabled: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.oneOf([null]).isRequired]),
  equipmentAsService: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  serviceTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleEquipmentAsServiceTypeChange: PropTypes.func.isRequired,
  therapistEquipmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Equipment;
