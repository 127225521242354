import { payer as payerSchema } from '../schemas/infobox.schema';
import coverageStateSchema from '../schemas/coverageState.schema';

const last = state => state.slice(-1)[0];

export const parsePayerName = coverages => last(coverages).payer.entity.name;

export const parsePayer = (payer = {}) => {
  const payerFields = (payer.payerFields && payer.payerFields[0]) || {};

  return {
    payerId: payer.id,
    typeof: 'payer',
    payerName: (payer.entity && payer.entity.name) || payer.name,
    hidePolicyNumber: payer.hidePolicyNumber,
    hideAuthorizationNumber: payer.hideAuthorizationNumber,
    overrideAuthorizationFieldName: payer.overrideAuthorizationFieldName,
    requiresMedicalNecessity: payer.requiresMedicalNecessity,
    matchedAliases: payer.matchedAliases,
    ...payerFields,
    inactive: payer.inactive,
    typeId: payer.typeId,
  };
};

export const parseCoverageWithPayer = (coverage, payload) => {
  const { payer, policyActive, policyActiveAt, policyActivator, policy, authorizations, typeId } = coverage;

  return {
    policyNumber: policy,
    policyActive,
    policyActiveAt,
    policyActivatorId: policyActivator && policyActivator.id,
    policyActivatorName: policyActivator && `${policyActivator.firstName} ${policyActivator.lastName}`,
    authorizationNumber: authorizations && authorizations.length ? last(authorizations).authorizationNumber : null,
    isBedBound: payload.isBedBound,
    requiresOxygen: payload.requiresOxygen,
    typeId,
    medicarePartB: payload.medicarePartB,
    ...parsePayer(payer),
  };
};

function parseCoverageData(coverageWithPayer, payload, coordinationTypeId) {
  const payer = payerSchema.parse(coverageWithPayer);
  const coverage = coverageStateSchema.parse({
    activeAt: coverageWithPayer.policyActiveAt,
    activatorId: coverageWithPayer.policyActivatorId,
    activatorName: coverageWithPayer.policyActivatorName,
    authorizationNumber: coverageWithPayer.authorizationNumber,
    coordinationTypeId,
    isActive: coverageWithPayer.policyActive,
    isBedBound: payload.isBedBound,
    medicarePartB: payload.medicarePartB,
    payerId: coverageWithPayer.payerId,
    payerInput: coverageWithPayer.payerName,
    payerName: coverageWithPayer.payerName,
    payerQuery: coverageWithPayer,
    policyNumber: coverageWithPayer.policyNumber,
    requiresOxygen: payload.requiresOxygen,
  });
  coverage.payer = payer;

  delete coverage.isRequired;

  return coverage;
}

export function parseCoverage(coverage, payload, coordinationTypeId) {
  const coverageWithPayer = parseCoverageWithPayer(coverage, payload);

  return parseCoverageData(coverageWithPayer, payload, coordinationTypeId);
}

export function loadCoverages(payload) {
  return payload.coverages.reduce((acc, coverage) => {
    const { coordinationTypeId } = coverage;
    acc[coordinationTypeId] = parseCoverage(coverage, payload, coordinationTypeId);

    return acc;
  }, {});
}

export function parseLoadedPayers(payers) {
  return payers.map(payer => ({
    payerId: payer.id,
    typeof: 'payer',
    name: payer.name,
    hidePolicyNumber: payer.hidePolicyNumber,
    hideAuthorizationNumber: payer.hideAuthorizationNumber,
    overrideAuthorizationFieldName: payer.overrideAuthorizationFieldName,
    requiresMedicalNecessity: payer.requiresMedicalNecessity,
    matchedAliases: payer.matchedAliases,
    typeId: payer.typeId,
  }));
}
