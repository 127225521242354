/* eslint-disable import/prefer-default-export */

import { from as from$ } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { populatePayer } from '../../components/MedicalNecessityStepper/redux/actions';
import PayerService from '../../services/payer.service';
import * as agencyActions from '../actions/agency.actions';
import * as infoboxActions from '../actions/infobox.actions';

export const loadPayerEpic = action$ =>
  action$.pipe(
    filter(agencyActions.fetchPlace.match),
    filter(action => action.payload.payer),
    switchMap(action => from$(PayerService.getPayer(action.payload.payer.id))),
    map(response =>
      infoboxActions.selectPopulatedPayer({
        coordinationTypeId: window.ambulnzConfig.coordinationTypes.PRIMARY,
        response,
      }),
    ),
  );

export const populatePayerEpic = action$ =>
  action$.pipe(
    filter(populatePayer.match),
    filter(action => action.payload.payerId),
    switchMap(action =>
      from$(PayerService.getPayer(action.payload.payerId)).pipe(
        map(response =>
          infoboxActions.populatedPayerResponse({
            response,
            coordinationTypeId: action.payload.coordinationTypeId,
          }),
        ),
      ),
    ),
  );

export const findFacilityPayerEpic = (action$, store) =>
  action$.pipe(
    filter(populatePayer.match),
    filter(action => action.payload.findFacilityPayer),
    switchMap(() =>
      from$(PayerService.findFacilityPayer(store.value.location.fromSelected.facilityFull)).pipe(
        map(response =>
          infoboxActions.populatedPayerResponse({
            response,
            coordinationTypeId: window.ambulnzConfig.coordinationTypes.PRIMARY,
          }),
        ),
      ),
    ),
  );
