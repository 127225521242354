import React from 'react';
import { connect } from 'react-redux';
import Providers from './frame';
import { providersCollapse } from '../../flags/collapse.flag';
import * as requestsActions from '../../redux/actions/requests.actions';
import { getProvider } from '../../parsers/request.parser';

function ProvidersContainer(props) {
  return props.show && <Providers {...props} />;
}

function mapStateToProps(state) {
  return {
    providers: state.service.providers,
    provider: getProvider(state),
    show: !!(state.user.model.isDara && state.agency.requesterAgencyId && state.service.providers.length),
    expanded: true,
    shouldCollapse: providersCollapse(state),
  };
}

export default connect(mapStateToProps, {
  ...requestsActions,
})(ProvidersContainer);
