import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Time from '../common/Time';
import phoneIcon from '../common/icons/phone.png';
import ConfirmationDialog from '../common/ConfirmationDialog';
import AmbGrid from 'components/common/AmbGrid';

const HelpBtn = styled.span`
  border: 1px solid white;
  border-radius: 2px;
  padding: 1px 4px;
`;

const Dl = styled(props => <AmbGrid {...props} />)`
  margin-block-start: initial;
  margin-block-end: initial;
  flex-direction: column;
`;

// <di> tag doesn't exist but no good way to semantically group <dt> and <dd>, so using a <div>. See: https://github.com/whatwg/html/pull/1945.
const Di = styled(({ full, ...props }) => <AmbGrid style={full ? { width: '100%' } : { width: '50%' }} {...props} />)`
  && {
    margin-top: 16px;
    flex-direction: column;
  }
`;

const Dt = styled(props => <dt {...props} />)`
  && {
    opacity: 0.6;
    font-size: 12px;
  }
`;

const Dd = styled(props => <aside {...props} />)``;

function HelpFrame({ tripNumber, pickupTime, customer, driver, phone, isCar }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {isCar && (
        <HelpBtn id="help-btn" onClick={() => setOpen(true)}>
          <img src={phoneIcon} alt="" style={{ paddingRight: 4 }} />
          <span>HELP</span>
        </HelpBtn>
      )}
      {open && (
        <ConfirmationDialog
          title="Uber Support Info"
          subTitle={phone}
          onClose={() => setOpen(false)}
          okText="CLOSE"
          open={open}
          titlestyles={{ textAlign: 'center' }}
        >
          <Dl>
            <Di full>
              <Dt>Trip Number</Dt>
              <Dd>{tripNumber || '-'}</Dd>
            </Di>
            <Di full>
              <Dt>Customer</Dt>
              <Dd>{customer || '-'}</Dd>
            </Di>
            <Di full>
              <Dt>Pickup Time</Dt>
              <Dd>
                <Time datetime={pickupTime} />
              </Dd>
            </Di>
            <Di full>
              <Dt>Driver</Dt>
              <Dd>{driver || '-'}</Dd>
            </Di>
          </Dl>
        </ConfirmationDialog>
      )}
    </>
  );
}

HelpFrame.propTypes = {
  customer: PropTypes.string,
  pickupTime: PropTypes.string,
  tripNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  driver: PropTypes.string,
};

HelpFrame.defaultProps = {
  customer: '',
  pickupTime: '',
  tripNumber: '',
  driver: '',
};

export default HelpFrame;
