import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import Checkbox from 'components/common/icons/Checkbox';
import AmbGrid from 'components/common/AmbGrid';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import WaitAndReturn from '../common/icons/WaitAndReturn';
import WaitTime from './WaitTime';
import WillCallReturn from './WillCallReturn';
import CanRequestBeActivatedByPatient from './CanRequestBeActivated';
import { theme } from 'theme';

const Container = styled('div')({
  textAlign: 'center',
  paddingTop: '15px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  width: '100%',
});

const Item = styled('div')({
  marginRight: '25px',
  flexShrink: 0,
  display: 'flex',
  gap: '6px',
  paddingBottom: '5px',
});

const Label = styled('label')({
  alignSelf: 'center',
});

const DisabledText = styled('span')({
  color: '#A0A0A0',
});

const BoldText = styled('span')({
  fontWeight: 'bold',
});

function WaitAndReturnFrame({
  panelTitle,
  panelTitleExt,
  crewMustWaitForPatient,
  panelTitleDesc,
  expanded,
  handleTogglePanel,
  isRoundTrip,
  handleRoundTripChange,
  disabled,
  hour,
  handleHourChange,
  min,
  handleMinChange,
  handleWaitChange,
  isCrewMustWaitDisabled,
  isWillCallReturn,
  showWillCallReturn,
  isWaitTimeDisabled,
  handleWillCallReturnChange,
  canRequestBeActivatedByPatient,
}) {
  const getDisabledClass = disabled => (disabled ? DisabledText : 'span');
  const titleExtClass = crewMustWaitForPatient ? 'span' : BoldText;

  return (
    <AmbExpansionPanel
      icon={<WaitAndReturn style={{ height: 17, width: 19, marginTop: 3, fill: theme.palette.icon.primary }} />}
      title={panelTitle}
      titleExt={panelTitleExt}
      titleExtClass={titleExtClass}
      titleDesc={panelTitleDesc}
      expanded={expanded}
      onTogglePanel={handleTogglePanel}
      id="WaitAndReturnPanel"
    >
      <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 0 -7px 0' }}>
        <Container>
          <Item>
            <span style={{ position: 'relative', marginTop: 11, color: theme.palette.secondary.main }}>
              <Checkbox checked={isRoundTrip} />
              <input
                type="checkbox"
                checked={isRoundTrip}
                id="RoundTripCheckbox"
                disabled={disabled || null}
                style={{ position: 'absolute', top: 1, left: 5, opacity: 0 }}
                onChange={handleRoundTripChange}
              />
            </span>
            <Label htmlFor="RoundTripCheckbox">Round Trip</Label>
          </Item>
          <Item>
            {isRoundTrip && (
              <WaitTime
                id="waitTimeSelect"
                disabledClass={getDisabledClass(disabled || isWaitTimeDisabled)}
                disabled={disabled || isWillCallReturn || null}
                hour={hour}
                handleHourChange={handleHourChange}
                min={isWillCallReturn ? '' : min}
                handleMinChange={handleMinChange}
              />
            )}
          </Item>
          <Item
            style={{
              flexShrink: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'center',
            }}
          >
            {isRoundTrip && showWillCallReturn && (
              <>
                <WillCallReturn
                  disabled={disabled || crewMustWaitForPatient || null}
                  disabledClass={getDisabledClass(disabled || crewMustWaitForPatient)}
                  onChange={handleWillCallReturnChange}
                />
                {canRequestBeActivatedByPatient && (
                  <CanRequestBeActivatedByPatient
                    disabled={disabled || crewMustWaitForPatient || !isWillCallReturn || null}
                    disabledClass={getDisabledClass(disabled || crewMustWaitForPatient)}
                  />
                )}
              </>
            )}
          </Item>
        </Container>
        <AmbGrid>
          <Item>
            <span
              style={{
                position: 'relative',
                marginTop: 11,
                color: disabled || isCrewMustWaitDisabled ? '#A0A0A0' : theme.palette.secondary.main,
              }}
              className={`${getDisabledClass(disabled || isCrewMustWaitDisabled)}`}
            >
              <Checkbox checked={!!crewMustWaitForPatient} />
              <input
                type="checkbox"
                checked={!!crewMustWaitForPatient}
                id="WaitCheckbox"
                disabled={disabled || isCrewMustWaitDisabled || null}
                style={{ position: 'absolute', top: 1, left: 5, opacity: 0 }}
                onChange={handleWaitChange}
              />
            </span>
            <Label htmlFor="WaitCheckbox">Crew Must Wait With Patient</Label>
          </Item>
        </AmbGrid>
      </div>
    </AmbExpansionPanel>
  );
}

WaitAndReturnFrame.propTypes = {
  isRoundTrip: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isCrewMustWaitDisabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  crewMustWaitForPatient: PropTypes.bool.isRequired,
  panelTitle: PropTypes.string.isRequired,
  panelTitleExt: PropTypes.string.isRequired,
  panelTitleDesc: PropTypes.string.isRequired,
  handleTogglePanel: PropTypes.func.isRequired,
  handleRoundTripChange: PropTypes.func.isRequired,
  handleHourChange: PropTypes.func.isRequired,
  handleMinChange: PropTypes.func.isRequired,
  handleWaitChange: PropTypes.func.isRequired,
  handleWillCallReturnChange: PropTypes.func.isRequired,
  hour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isWillCallReturn: PropTypes.bool.isRequired,
  showWillCallReturn: PropTypes.bool.isRequired,
  isWaitTimeDisabled: PropTypes.bool.isRequired,
  canRequestBeActivatedByPatient: PropTypes.bool.isRequired,
};

export default WaitAndReturnFrame;
