import * as errorActions from '../../actions/error.actions';
import { captureException } from '../../../logger';

const onError =
  () =>
  next =>
  (action = {}) => {
    try {
      if (action && errorActions.onError.match(action)) {
        captureException(action.payload);
      }

      if (action && action.type) {
        return next(action);
      }
    } catch (err) {
      captureException({ err, action });
      return err;
    }
  };

const middlewares = [onError];
export default middlewares;
