import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AmbSwitch from '../../components/common/AmbSwitch';
import * as requestsActions from '../../redux/actions/requests.actions';

const WillCallReturn = ({
  disabled,
  disabledClass,
  isWillCallReturn,
  setWillCallReturn,
  roundTripChange,
  onChange,
}) => {
  const handleChange = e => {
    setWillCallReturn(e.target.checked);
    onChange(e);
    roundTripChange({ estimatedWaitForReturnMins: 0 });
  };

  return (
    <label id="will-call-return" className={disabledClass} style={{ marginTop: 10, marginRight: 0 }}>
      <AmbSwitch disabled={disabled || null} onChange={handleChange} checked={isWillCallReturn} />
      Will Call Return
    </label>
  );
};

WillCallReturn.propTypes = {
  disabled: PropTypes.bool,
  disabledClass: PropTypes.string.isRequired,
  isWillCallReturn: PropTypes.bool.isRequired,
  setWillCallReturn: PropTypes.func.isRequired,
  roundTripChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isWillCallReturn: state.requests.request.isWillCallReturn,
  };
}

export default connect(mapStateToProps, {
  setWillCallReturn: requestsActions.setWillCallReturn,
  roundTripChange: requestsActions.roundTripChange,
})(WillCallReturn);
