import { createAction } from '@reduxjs/toolkit';

export const mapInit = createAction('[Map] mapInit');
export const setMapLocationByCurrentUser = createAction('[Map] setMapLocationByCurrentUser');
export const setMapLocationByNavigator = createAction('[Map] setMapLocationByNavigator');
export const setMapPickupLocationByAgency = createAction('[Map] setMapPickupLocationByAgency');
export const setMapDestinationLocationByAgency = createAction('[Map] setMapDestinationLocationByAgency');
export const setMapLocationByAddress = createAction('[Map] setMapLocationByAddress');
export const setMapLocationSuccess = createAction('[Map] setMapLocationSuccess');
export const setMapFromWaypoint = createAction('[Map] setMapFromWaypoint');
export const setMapToWaypoint = createAction('[Map] setMapToWaypoint'); // delete me
export const setMapDirections = createAction('[Map] setMapDirections');
export const clearPickupPlace = createAction('[Map] clearPickupPlace');
export const clearDestinationPlace = createAction('[Map] clearDestinationPlace');

export const setResidence = createAction('[Map] setResidence');
export const setResidenceDisabled = createAction('[Map] setResidenceDisabled');
export const asyncUpdateResidence = createAction('[Map] asyncUpdateResidence');
export const asyncUpdateResidenceSuccess = createAction('[Map] asyncUpdateResidenceSuccess');

export const asyncGetVehicleLocation = createAction('[Map] asyncGetVehicleLocation');
export const asyncGetVehicleLocationSucces = createAction('[Map] asyncGetVehicleLocationSucces');
export const setVehicleLocation = createAction('[Map] setVehicleLocation');

export const resetMap = createAction('[Map] resetMap');
