import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AmbGrid from '../AmbGrid';
import { NoFiles } from './elements';
import DocumentTypes from './DocumentTypes';
import ListItem from './ListItem';

const AmbFileInput = ({ onRemove, buttonText, disabled, deletable, error, fileTypes, attachments, ...props }) => {
  const [type, setType] = useState('');
  const fileInput = useRef(null);
  const getTypeName = () => fileTypes.find(t => t.id === +type).name;

  const onChange = ({ target }) => {
    const targetFile = target.files[0];

    if (targetFile) {
      const typeName = getTypeName(type);
      const formData = new FormData();

      formData.append('data', targetFile, targetFile.name);
      formData.append('attachmentTypeId', type);
      formData.append('attachmentTypeName', typeName);

      props.onChange({
        formData,
        name: targetFile.name,
        type,
        attachmentType: { name: typeName, id: type },
      });
      setType('');
      target.value = null; // reset file input
    }
  };

  const onTypeSelected = value => {
    setType(value);
    fileInput.current.click();
  };

  return (
    <div style={{ width: '100%' }}>
      <AmbGrid style={{ flexDirection: 'column' }}>
        <AmbGrid
          style={{
            minWidth: '100%',
            alignItems: 'baseline',
            marginTop: 7,
            marginLeft: -1,
            marginRight: 20,
            paddingBottom: 8,
            borderBottom: '1px solid #899ba64a',
          }}
        >
          <DocumentTypes onTypeSelected={onTypeSelected} type={type} fileTypes={fileTypes} />
          <input
            style={{
              display: 'none',
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: 0,
            }}
            ref={fileInput}
            type="file"
            id="file-upload"
            onChange={onChange}
            disabled={disabled || null}
          />
        </AmbGrid>
        <AmbGrid style={{ flexDirection: 'column' }} id="AttachmentsContainer">
          {attachments.map((file, i) => (
            <ListItem key={`${file.name + i}`} error={error} deletable={deletable} file={file} onRemove={onRemove} />
          ))}
          {attachments.length === 0 && <NoFiles>No files have been uploaded yet</NoFiles>}
        </AmbGrid>
      </AmbGrid>
    </div>
  );
};

AmbFileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  fileTypes: PropTypes.arrayOf(PropTypes.shape()),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  deletable: PropTypes.bool,
};

AmbFileInput.defaultProps = {
  error: false,
  disabled: false,
  deletable: false,
  fileTypes: [],
};

export default AmbFileInput;
