import { createReducer } from 'redux-act';
import * as action from '../actions/userAccount.actions';
import { parseAccount } from '../../parsers/account.parser';

export const initialState = {
  selectedUserAccount: null,
};

const userAccountReducer = createReducer(
  {
    [action.fetchUserAccountsSuccess]: (state, payload) => {
      return {
        ...state,
        selectedUserAccount: parseAccount(payload[0]),
      };
    },
    [action.selectUserAccount]: (state, payload) => {
      return {
        ...state,
        selectedUserAccount: parseAccount(payload),
      };
    },
  },
  initialState,
);

export default userAccountReducer;
