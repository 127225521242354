import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as moment from 'moment-timezone';
import TimeDate from './TimeDate.frame';
import * as etaActions from '../../redux/actions/eta.actions';
import * as willActions from '../../redux/actions/willCall.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import { timeDateCollapse, timeDateExpand } from '../../flags/collapse.flag';
import { timeDateDisabled } from '../../flags/disabled.flag';

const TimeDateContainer = props => {
  const handleDateTimeChange = props.requestedAppointmentTime ? props.setAppmtTime : props.setDateTime;
  const handleCloseNewETADialog = props.requestedAppointmentTime
    ? props.clearEtaFromEtaTypeToggle
    : props.closeNewETADialog;
  return (
    <TimeDate
      {...props}
      handleDateTimeChange={handleDateTimeChange}
      handleCloseNewETADialog={handleCloseNewETADialog}
    />
  );
};

TimeDateContainer.propTypes = {
  willCall: PropTypes.bool.isRequired,
};

const isZollProviderTrip = state => {
  return state.offers.offers.some(offer => !offer.provider.providerService.allowDateChange);
};

const getPCPEquipmentIds = state => {
  const pcpEquipmentIds = state.service.equipment.filter(e => e.name.includes('PCP')).map(eq => eq.id);
  return pcpEquipmentIds;
};

const isPCP = state => {
  const pcpEquipmentIds = getPCPEquipmentIds(state);
  return pcpEquipmentIds && pcpEquipmentIds.some(id => state.service.therapistEquipmentIds.includes(id));
};

const isTelehealth = state => {
  const telehealthEq = state.service.equipment.find(e => e.name === 'Telehealth');
  if (telehealthEq) {
    return state.service.therapistEquipmentIds.includes(telehealthEq.id);
  }
  return false;
};

const getTitle = state => {
  let title = 'Requested Pickup:';
  if (state.requests.willCall) {
    title = 'Will Call';
  } else if (state.requests.isNonTransport) {
    title = 'Service Needed by:';
  } else if (state.eta.requestedAppointmentTime) {
    title = 'Appointment Time:';
  }
  return title;
};

const onPromisedWindow = state =>
  window.ambulnzConfig.constants.PROMISED_WINDOW_ENABLED && state.requests.isNonTransport;

const setDispalyEnforcePromisedWindow = state =>
  onPromisedWindow(state) &&
  window.ambulnzConfig.userSettings.isRoutePlanner &&
  window.ambulnzConfig.userSettings.isRoutePlannerDispatcher;

const isPlanner = () => {
  return (
    window.ambulnzConfig.userSettings.isRoutePlanner ||
    window.ambulnzConfig.userSettings.isRoutePlannerDispatcher ||
    window.ambulnzConfig.userSettings.isMultidayTripPlanner
  );
};

const setHideTimePicker = state =>
  onPromisedWindow(state) && isPlanner() && !state.eta.enforcePromisedWindow && !isTelehealth(state) && !isPCP(state);

const setTimezone = state => (state.eta.timezone ? state.eta.timezone : moment.tz.guess());

function mapStateToProps(state) {
  return {
    shouldCollapse: timeDateCollapse(state),
    shouldExpand: timeDateExpand(state),
    requestedTime: state.eta.requestedAppointmentTime || state.eta.requestedArrivalTime,
    requestedAppointmentTime: state.eta.requestedAppointmentTime,
    maxDateAllowed: state.eta.maxDateAllowed,
    willCall: state.requests.willCall,
    loadedWillCall: state.requests.loadedWillCall,
    expanded: state.requests.isNonTransport || state.requests.loadedWillCall,
    inProgressState: state.formFlow.inProgressState,
    maxDate: state.formFlow.maxDate,
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    ETADialogTitle: state.formFlow.ETADialogTitle,
    ETADialogBody: state.formFlow.ETADialogBody,
    title: getTitle(state),
    request: state.requests.request,
    isNonTransport: state.requests.isNonTransport,
    disabled: timeDateDisabled(state),
    etaType: state.eta.etaType,
    appointmentsEnabled: window.ambulnzConfig.constants.APPOINTMENTS_ENABLED,
    location: state.eta.requestedAppointmentTime
      ? state.location.toSelected || state.map.pickupPlace
      : state.location.fromSelected || state.map.destinationPlace,
    timezone: setTimezone(state),
    ampm: window.ambulnzConfig.userSettings.ampm,
    isZollProviderTrip: isZollProviderTrip(state),
    zollProviderDialogTitle: state.formFlow.zollProviderDialogTitle,
    zollProviderDialogBody: state.formFlow.zollProviderDialogBody,
    enforcePromisedWindow: state.eta.enforcePromisedWindow,
    dispalyEnforcePromisedWindow: setDispalyEnforcePromisedWindow(state),
    hideTimePicker: setHideTimePicker(state),
    preferredTimeOfDay: state.eta.preferredTimeOfDay,
  };
}
export default connect(mapStateToProps, {
  ...etaActions,
  ...willActions,
  ...formFlowActions,
})(TimeDateContainer);
