/* global google */
import ApiService from './api.service';
import * as mapParser from '../parsers/map.parser';

class LocationService {
  static coordinatesMatcher(string) {
    function between(number, min, max) {
      return number >= min && number <= max;
    }

    if (!string) {
      return { ok: false };
    }

    const matches = string.trim().match(/^(-?\d+(\.\d+)?)\s*[,\s]\s*(-?\d+(\.\d+)?)$/);

    if (!matches || matches.length !== 5) {
      return { ok: false };
    }

    const lat = Number(matches[1]);
    const lng = Number(matches[3]);

    if (!between(lat, -90, 90) || !between(lng, -180, 180)) {
      return { ok: false };
    }

    return { ok: true, value: { lat, lng } };
  }

  static async getAgencyAddress(facilityId) {
    const response = await ApiService.get(
      `${window.ambulnzConfig.tenantedRestApiUrl}/RequesterAgencies/${facilityId}/places`,
    );
    return response.json();
  }

  static async searchAddresses(query) {
    const getPlacePredictions = input => {
      return new Promise((resolve, reject) => {
        const service = new google.maps.places.AutocompleteService();
        service.getPlacePredictions(
          {
            input,
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
          (result, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK ||
              status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              resolve(result);
            } else {
              reject(result);
            }
          },
        );
      });
    };
    const response = await getPlacePredictions(query);
    return response;
  }

  static async getPlaceLocation(place, state) {
    const getFromGeocode = placeId => {
      return new Promise((resolve, reject) => {
        const service = new google.maps.Geocoder();

        service.geocode(
          {
            placeId,
          },
          (result, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              resolve(mapParser.getPlace(result, state));
            } else {
              reject(result);
            }
          },
        );
      });
    };
    const response = await getFromGeocode(place.id);
    return response;
  }

  static async getVehicleLocation(requestId) {
    const response = await ApiService.get(
      `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/Location/best`,
    );

    return response.json();
  }

  static async reverseGeocode(geopoint) {
    const getFromGeocode = coordinates => {
      return new Promise((resolve, reject) => {
        const service = new google.maps.Geocoder();

        service.geocode(
          {
            location: { lat: coordinates.lat, lng: coordinates.lng },
          },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const geopointPlace = mapParser.getGeopointPrediction(results, coordinates);
              resolve([geopointPlace]);
            } else {
              reject(results);
            }
          },
        );
      });
    };
    return getFromGeocode(geopoint);
  }

  static async getPlaceLocationFromGeopointPrediction(geopointPrediction, state) {
    return mapParser.getPlaceFromGeopointPrediction(geopointPrediction, state);
  }
}

export default LocationService;
