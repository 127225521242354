import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { clearPickupDepartment } from '../../components/PickupDestination/redux/actions/component.actions';
import LocationService from '../../services/location.service';
import Location from '../../values/location';
import * as locationActions from '../actions/location.actions';
import * as locationComponentActions from '../../components/PickupDestination/redux/actions/component.actions';
import * as mapActions from '../actions/map.actions';
import * as errorActions from '../actions/error.actions';
import * as epicIntegrationActions from '../actions/epicIntegration.actions';
import { autoCompletePipe, errorPipe } from './epicsUtil';
import { getDepartments, getFacilities, getEmsFacilityById } from './helpers/emsFacility.helper';
import { fetchPlaceTypesHelper, formatAddress } from './helpers/location.helper';

export const searchPickupLocationInProgressEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryPickupLocations.match),
    autoCompletePipe,
    map(() => locationActions.setSearchPickupLocationInProgress(true)),
  );

export const searchPickupFacilityOnlyInProgressEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryPickupFacilitiesOnly.match),
    map(() => locationActions.setSearchPickupLocationInProgress(true)),
  );

export const searchPickupFacilitiesEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryPickupLocations.match),
    autoCompletePipe,
    switchMap(action => {
      if (window.ambulnzConfig.constants.GEOPOINT_LOCATIONS_ENABLED) {
        const { ok, value } = LocationService.coordinatesMatcher(action.payload);
        if (ok) {
          return of$(locationActions.queryPickupLocationsByGeopoint(value)).pipe(errorPipe);
        }
      }

      return getFacilities(action.payload.split(',')[0]).pipe(
        mergeMap(result =>
          concat$(
            of$(locationActions.pickupFacilitiesRetrieved(result)),
            of$(locationActions.queryPickupLocationsByAddress(action.payload)),
          ),
        ),
        errorPipe,
      );
    }),
  );

export const searchPickupAddressesEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryPickupLocationsByAddress.match),
    switchMap(action =>
      from$(LocationService.searchAddresses(action.payload)).pipe(
        mergeMap(payload =>
          concat$(
            of$(locationActions.pickupPredictionsRetrieved(payload)),
            of$(locationActions.setSearchPickupLocationInProgress(false)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const searchPickupGeopointEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryPickupLocationsByGeopoint.match),
    switchMap(action =>
      from$(LocationService.reverseGeocode(action.payload)).pipe(
        mergeMap(payload =>
          concat$(
            of$(locationActions.pickupGeopointPredictionsRetrieved(payload)),
            of$(locationActions.setSearchPickupLocationInProgress(false)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

const getAddress = state => state.map.pickupPlace.address.street1;

export const searchPickupFacilitiesOnlyEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.queryPickupFacilitiesOnly.match),
    switchMap(() =>
      getFacilities(getAddress(store.value)).pipe(
        mergeMap(result =>
          concat$(
            of$(locationActions.pickupFacilitiesRetrieved(result)),
            of$(locationActions.pickupPredictionsRetrieved()),
            of$(locationActions.setSearchPickupLocationInProgress(false)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const selectPickupFromFacilityEpic = action$ =>
  action$.pipe(
    filter(locationActions.selectPickupLocation.match),
    filter(action => action.payload.typeof === 'facility'),
    mergeMap(action =>
      concat$(
        of$(locationActions.selectPickupLocationSuccess(action.payload)),
        of$(clearPickupDepartment()),
        of$(locationActions.getPickupDepartments(Location.fromFacilitySchema(action.payload).location)),
        of$(
          mapActions.setResidenceDisabled({
            isPickupResidence: false,
            pickupResidenceDisabled: true,
          }),
        ),
      ),
    ),
  );

export const selectPickupFromPredictionEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.selectPickupLocation.match),
    filter(action => action.payload.typeof === 'prediction'),
    switchMap(action =>
      from$(LocationService.getPlaceLocation(action.payload, store.value)).pipe(
        mergeMap(payload =>
          concat$(
            of$(locationActions.selectPickupLocationSuccess(payload)),
            of$(
              mapActions.setResidenceDisabled({
                pickupResidenceDisabled: false,
              }),
            ),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const selectPickupFromGeopointPredictionEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.selectPickupLocation.match),
    filter(action => action.payload.typeof === 'geopointPrediction'),
    switchMap(action =>
      from$(LocationService.getPlaceLocationFromGeopointPrediction(action.payload, store.value)).pipe(
        mergeMap(payload =>
          concat$(
            of$(locationActions.selectPickupLocationSuccess(payload)),
            of$(
              mapActions.setResidenceDisabled({
                pickupResidenceDisabled: false,
              }),
            ),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const setPickupAddressEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.selectPickupLocationSuccess.match),
    switchMap(action => (store.value.location.placeTypeEnum ? of$(action) : fetchPlaceTypesHelper(action))),
    mergeMap(action =>
      concat$(
        of$(locationActions.setPickupAddress(action.payload)),
        of$(mapActions.setMapFromWaypoint(action.payload)),
        of$(mapActions.setMapLocationSuccess(action.payload)),
      ),
    ),
  );

export const clearPickupLocationEpic = action$ =>
  action$.pipe(
    filter(locationActions.clearPickupLocation.match),
    mergeMap(() => concat$(of$(mapActions.clearPickupPlace()), of$(clearPickupDepartment()))),
  );

export const getPickupDepartmentsEpic = action$ =>
  action$.pipe(
    filter(locationActions.getPickupDepartments.match),
    filter(({ payload }) => payload && payload.emsFacilityId),
    switchMap(({ payload }) => getDepartments(payload.emsFacilityId)),
    map(result => locationActions.getPickupDepartmentsSuccess(result)),
    errorPipe,
  );

export const setExternalRequestPickupAddressEpic = action$ =>
  action$.pipe(
    filter(epicIntegrationActions.fetchEpicDataSuccess.match),
    switchMap(({ payload }) => {
      if (payload.error) {
        return of$(errorActions.onError(payload));
      }
      return getEmsFacilityById(payload.pickUp.emsFacility.id).pipe(
        mergeMap(response => {
          const departments = response.emsFacilityDepartments.map(depts => depts.department);
          const selectedDepartment = departments.find(dept => dept.id === payload.pickUp.department.id);

          return concat$(
            of$(
              locationActions.selectPickupLocationSuccess({
                ...response.facilityLocations[0].place,
                name: response.entity.name,
                formattedAddress: formatAddress(response.facilityLocations[0].place),
              }),
            ),
            of$(locationActions.getPickupDepartmentsSuccess(departments)),
            of$(locationComponentActions.selectPickupDepartment(selectedDepartment.id)),
            of$(locationActions.setRoomNumber({ puRoomNumber: payload.pickUp.roomNumber })),
          );
        }),
        errorPipe,
      );
    }),
  );
