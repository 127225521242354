import React, { useState } from 'react';
import styled from '@emotion/styled';
import { palette } from '../../theme';
import PropTypes from 'prop-types';

const getSubLabelColor = ({ error, disabled }) => {
  if (error) {
    return palette.primary.red;
  }

  if (disabled) {
    return palette.primary.disabled;
  }

  return palette.primary.orange;
};

const getHelperTextColor = ({ error, disabled }) => {
  if (error) {
    return palette.primary.red;
  }

  if (disabled) {
    return palette.primary.disabled;
  }

  return palette.primary.helperText;
};

const StyledHelperText = styled.p`
  color: ${props => getHelperTextColor(props)};
  margin: 0;
  font-size: 12px;
  text-align: left;
  margin-top: 8px;
  min-height: 1em;
  line-height: 1em;
`;

const SubLabel = styled.span`
  color: ${props => getSubLabelColor(props)};
  padding-top: 5px;
  line-height: 15px;
  display: block;
`;

const HelperText = ({ helperText, subLabel, error, disabled, id }) => {
  return (
    <>
      <StyledHelperText id={id} error={error} disabled={disabled || null}>
        {helperText}
        {subLabel && (
          <SubLabel error={error} disabled={disabled || null}>
            {subLabel}
          </SubLabel>
        )}
      </StyledHelperText>
    </>
  );
};

const StyledInput = styled.input`
  box-shadow: none;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  margin-top: -2px;
  padding: 6px 0 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  ::placeholder {
    font-size: 12px;
  }
`;

const StyledTextarea = styled.textarea`
  box-shadow: none;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 6px 0 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  outline: none;
  -webkit-tap-highlight-color: transparent;
`;

const Wrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 14px;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused || props.error ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: ${props => (props.error ? '#f44336' : '#004053')};
  }
`;

const AmbTextField = ({
  fullWidth,
  id,
  label,
  InputProps = {},
  InputLabelProps = {},
  error,
  onClick,
  helperText,
  subLabel,
  disabled,
  name,
  role,
  onBlur,
  multiline,
  rows,
  wrapperStyle,
  dataTestId,
  ...other
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const isRequired = other.required;
  const describedBy = `${id}-helper-text`;
  const containerStyles = {
    margin: 0,
    border: 0,
    display: 'inline-flex',
    padding: 0,
    position: 'relative',
    minWidth: 0,
    flexDirection: 'column',
    width: '100%',
    ...wrapperStyle,
  };

  const shrinkLabel = {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
  };

  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }
    setIsFocused(false);
  };

  return (
    <div
      role={role}
      aria-autocomplete={other && other['aria-autocomplete']}
      aria-expanded={other && other['aria-expanded']}
      aria-describedby={describedBy}
      style={containerStyles}
    >
      <label
        className={InputLabelProps.classes && InputLabelProps.classes.label}
        style={{ ...(InputLabelProps.shrink && shrinkLabel), ...(InputLabelProps.style && InputLabelProps.style) }}
        htmlFor={id}
      >
        {label}
        {isRequired && <span style={{ color: error ? '#f44336' : 'inherit' }}>&thinsp;*</span>}
      </label>
      <Wrapper isFocused={isFocused} error={error}>
        {multiline ? (
          <StyledTextarea
            id={id}
            type="text"
            name={name}
            className={InputProps.classes && InputProps.classes.input}
            style={InputProps.style && InputProps.style}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            rows={rows}
            {...other}
          />
        ) : (
          <StyledInput
            id={id}
            type="text"
            name={name}
            className={InputProps.classes && InputProps.classes.input}
            style={InputProps.style && InputProps.style}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            data-testid={dataTestId}
            {...other}
          />
        )}
      </Wrapper>
      <HelperText helperText={helperText} subLabel={subLabel} error={error} disabled={disabled} id={describedBy} />
    </div>
  );
};

AmbTextField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default AmbTextField;
