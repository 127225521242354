import ApiService from './api.service';

class ExternalRequestService {
  static async getExternalRequestData(groupId) {
    const url = `${window.ambulnzConfig.tenantedRestApiV2Url}/ExternalRequests/Active?requesterAgencyGroupId=${groupId}`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default ExternalRequestService;
