import '@ambulnz/amb-datepicker';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import moment from 'moment';
import ConfirmationDialog from '../common/ConfirmationDialog';
import useEventListener from '../common/useEventListener.hook';
import AmbInputLabel from '../common/AmbInputLabel';
import { MenuItem, TextField } from '@mui/material';
import { palette } from '../../theme';
import { styled } from '@mui/system';
import AmbTimePicker from '../common/AmbTimePicker';

const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: 120,
  marginTop: -1,
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
  },
  '& .MuiInputLabel-root': {
    fontSize: 15,
    width: 130,
    color: theme.palette.grey.grey80,
    '&.Mui-focused': {
      color: theme.palette.grey.grey80,
    },
  },
}));

const TimeDate = ({
  handleDateTimeChange,
  changeRequiresNewETA,
  handleCloseNewETADialog,
  requestedTime,
  timezone,
  disabled,
  hideTimePicker,
  ETADialogTitle,
  ETADialogBody,
  ampm,
  maxDateAllowed,
  isZollProviderTrip,
  zollProviderDialogTitle,
  zollProviderDialogBody,
  preferredTimeOfDay,
  setPreferredTimeOfDay,
}) => {
  const [ETADialogOpen, setETADialogOpen] = useState(false);
  const [zollDialogOpen, setZOllDialogOpen] = useState(false);
  const [newDate, setNewDate] = useState(requestedTime);
  const [newTime, setNewTime] = useState(requestedTime);
  // to force state update when 2 invalid time values are entered in a row
  const [rerenderKey, setRerenderKey] = useState(0);
  const [timeOfDay, setTimeOfDay] = useState(preferredTimeOfDay);
  const datepickerEl = useRef(null);
  const timeOfDayArr = ['Anytime', 'Morning', 'Afternoon', 'Evening'];

  const setTime = (date, time) =>
    moment(date).set({
      hour: moment(time).hours(),
      minute: moment(time).minutes(),
    });

  const setDateTime = (date, time) => {
    if (date && time) {
      const datetime = setTime(date, time);
      handleDateTimeChange(datetime);
    }
  };

  const closeNewETADialog = confirm => {
    if (confirm) {
      handleCloseNewETADialog(true);
      setDateTime(newDate, newTime);
      setPreferredTimeOfDay(timeOfDay);
    } else {
      setNewDate(requestedTime);
      setNewTime(requestedTime);
      setPreferredTimeOfDay(preferredTimeOfDay);
    }

    setETADialogOpen(false);
  };

  const closeZollDialog = confirm => {
    if (confirm) {
      setNewDate(requestedTime);
      setNewTime(requestedTime);
      setZOllDialogOpen(false);
    }
  };

  const handlePreferredTimeOfDayChange = e => {
    setTimeOfDay(e.target.value);

    if (changeRequiresNewETA) {
      setETADialogOpen(true);
      return;
    }

    setPreferredTimeOfDay(e.target.value);
  };

  const handleTimeChange = (inputValue, selectValue) => {
    if (!inputValue && !selectValue) {
      setNewTime('');
      setRerenderKey(prevKey => prevKey + 1);
    } else {
      moment.tz.setDefault(timezone);
      const timeInput = selectValue ? selectValue : inputValue;
      const formattedTime = moment(timeInput, ['h:mm A', 'HH:mm']).toString();
      setNewTime(formattedTime);

      if (changeRequiresNewETA) {
        setETADialogOpen(true);
        return;
      }

      setDateTime(newDate, formattedTime);
    }
  };

  const handleDateChange = date => {
    setNewDate(date);
    const datetime = setTime(date, newTime);
    setNewTime(datetime.toString());

    if (isZollProviderTrip) {
      setZOllDialogOpen(true);
      return;
    }

    if (changeRequiresNewETA) {
      setETADialogOpen(true);
      return;
    }

    setDateTime(date, newTime);
  };

  useEffect(() => {
    setNewDate(requestedTime);
    setNewTime(requestedTime);
  }, [requestedTime]);

  useEventListener(datepickerEl, 'valueChange', v => handleDateChange(v.detail));

  return (
    <div key={rerenderKey}>
      <AmbGrid style={{ gap: '8px', marginTop: 10 }}>
        <AmbGrid style={{ width: 130, flexDirection: 'column' }}>
          <AmbInputLabel
            htmlFor="requestedArrivalTimeDateInput"
            shrink
            style={{ fontSize: 15, color: `${palette.grey.grey80}` }}
          >
            Date
          </AmbInputLabel>
          <amb-datepicker
            ref={datepickerEl}
            id="requestedArrivalTimeDateInput"
            config={JSON.stringify({
              value: newDate,
              max: maxDateAllowed,
              timezone,
              disabled,
            })}
          />
        </AmbGrid>
        {hideTimePicker ? (
          <AmbGrid
            style={{
              width: 150,
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <AmbInputLabel
              htmlFor="preferredTimeOfDayInput"
              shrink
              style={{ fontSize: 15, color: `${palette.grey.grey80}` }}
            >
              Preferred Time Of Day
            </AmbInputLabel>
            <StyledTextField
              id="preferredTimeOfDayInput"
              select
              variant="standard"
              margin="none"
              value={timeOfDay}
              onChange={handlePreferredTimeOfDayChange}
            >
              {timeOfDayArr.map(time => (
                <MenuItem key={time} value={time} sx={{ '&.MuiMenuItem-root': { padding: '12px 16px' } }}>
                  {time}
                </MenuItem>
              ))}
            </StyledTextField>
          </AmbGrid>
        ) : (
          <AmbGrid
            style={{
              width: 130,
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <AmbTimePicker
              id="requestedArrivalTimeTimeInput"
              value={newTime}
              disabled={disabled}
              ampm={ampm}
              onChange={handleTimeChange}
              timezone={timezone}
            />
          </AmbGrid>
        )}
      </AmbGrid>
      <ConfirmationDialog
        open={ETADialogOpen}
        onClose={closeNewETADialog}
        title={ETADialogTitle}
        disableEnforceFocus
        cancel
      >
        <div>{ETADialogBody}</div>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={zollDialogOpen}
        onClose={closeZollDialog}
        title={zollProviderDialogTitle}
        disableEnforceFocus
        cancel={false}
      >
        <div>{zollProviderDialogBody}</div>
      </ConfirmationDialog>
    </div>
  );
};

TimeDate.propTypes = {
  handleDateTimeChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  expanded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  ampm: PropTypes.bool,
  handleCloseNewETADialog: PropTypes.func.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  ETADialogTitle: PropTypes.string.isRequired,
  ETADialogBody: PropTypes.string.isRequired,
  isZollProviderTrip: PropTypes.bool,
  zollProviderDialogTitle: PropTypes.string,
  zollProviderDialogBody: PropTypes.string,
};

TimeDate.defaultProps = {
  ampm: false,
  isZollProviderTrip: false,
};

export default TimeDate;
