import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AmbRadio from '../../common/AmbRadio/AmbRadio';

const Question = ({
  name,
  description,
  legend,
  value,
  yesValue,
  noValue,
  handleChange,
  yesTooltipTitle,
  yesDisabled,
  error,
  isOnlyQuestion,
}) => {
  useEffect(
    () => {
      if (yesDisabled) {
        handleChange({
          target: {
            name,
            value: 'false',
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [yesDisabled],
  );

  useEffect(
    () => {
      if (isOnlyQuestion) {
        handleChange({
          target: {
            name,
            value: yesValue,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOnlyQuestion],
  );

  return (
    <div style={{ paddingTop: 5, width: '100%' }}>
      <AmbRadio
        name={name}
        description={description}
        legend={legend}
        value={value}
        yesValue={yesValue}
        noValue={noValue}
        onChange={e => handleChange(e)}
        yesTooltipTitle={yesTooltipTitle}
        yesDisabled={yesDisabled}
        error={error}
      />
    </div>
  );
};

Question.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  yesValue: PropTypes.string,
  noValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  yesTooltipTitle: PropTypes.string,
  yesDisabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isOnlyQuestion: PropTypes.bool,
};

Question.defaultProps = {
  value: '',
  yesValue: undefined,
  noValue: undefined,
  yesTooltipTitle: undefined,
  isOnlyQuestion: false,
};

export default Question;
