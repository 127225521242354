import React from 'react';

function CanWalk(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d="M12.91,4.97c0.81,0.16,1.6-0.37,1.76-1.18c0.16-0.81-0.37-1.6-1.18-1.76s-1.6,0.37-1.76,1.18
		S12.1,4.81,12.91,4.97z"
        />
        <path
          d="M17.06,9.5h-2.08l-1.8-2.9c-0.01-0.02-0.03-0.03-0.05-0.05c-0.21-0.3-0.54-0.52-0.93-0.6
		c-0.23-0.05-0.46-0.03-0.68,0.03c-0.03,0.01-0.05,0-0.08,0.01l-4.8,1.76l-0.7,3.49c-0.11,0.54,0.24,1.07,0.79,1.18
		c0.07,0.01,0.13,0.02,0.2,0.02c0.47,0,0.89-0.33,0.98-0.8l0.48-2.39l1.76-0.65l-0.48,2.4c0,0,0,0,0,0L7.73,20.8
		c-0.11,0.54,0.24,1.07,0.79,1.18c0.54,0.11,1.07-0.24,1.18-0.79l1.41-7.1l2.32,2.32V21c0,0.55,0.45,1,1,1s1-0.45,1-1v-5.41l-3-3
		L12.94,10l0.93,1.5h3.2c0.55,0,1-0.45,1-1S17.61,9.5,17.06,9.5z"
        />
      </g>
    </svg>
  );
}

export default CanWalk;
