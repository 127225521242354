import { createAction } from '@reduxjs/toolkit';

export const setExpandPayer = createAction('[MedicalNecessity] setExpandPayer');

export const reducer = {
  [setExpandPayer]: (state, payload) => ({
    ...state,
    expandPayer: payload,
  }),
};
