import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import useSection, { initialState } from './Questionnaire.hook';
import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import { theme } from '../../theme';
import Stepper from 'components/common/icons/Stepper';
import AmbGrid from 'components/common/AmbGrid';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: '1.5em',
  fontWeight: 500,
  padding: '12px 16px',
  minWidth: 88,
}));

function getSteps() {
  return ['Step 1', 'Step 2', 'Step 3'];
}

function getStepContent(
  step,
  { requestingAgencyPayer, pickupTime, requireMedicareQuestions, admittanceDate },
  state,
  dispatch,
) {
  switch (step) {
    case 0:
      return (
        <SectionOne
          requestingAgencyPayer={requestingAgencyPayer}
          pickupTime={pickupTime}
          requireMedicareQuestions={requireMedicareQuestions}
          dispatch={dispatch}
          hasPriorAuthorization={state.hasPriorAuthorization}
          hasMedicare={state.hasMedicare}
          medicareSuboption={state.medicareSuboption}
          admittanceDate={admittanceDate}
        />
      );
    case 1:
      return <SectionTwo dispatch={dispatch} questions={state.questions} />;
    case 2:
      return (
        <SectionThree
          dispatch={dispatch}
          answer={state.whoWillCover}
          requestingAgencyPayer={requestingAgencyPayer}
          medicaid={state.hasMedicare === 'Medicaid'}
        />
      );
    default:
      return 'Unknown step';
  }
}

const StepperComponent = ({ activeStep, steps }) => {
  const labelStyles = {
    fontSize: '0.875rem',
    color: '#00838f',
    active: {
      color: '#004053',
      fontWeight: 500,
    },
  };

  const getLabelStyle = idx => {
    if (activeStep === idx) {
      return {
        ...labelStyles,
        ...labelStyles.active,
      };
    }
    return labelStyles;
  };

  return (
    <AmbGrid activeStep={activeStep} style={{ alignItems: 'center', flexDirection: 'row', padding: 24 }}>
      {steps.map((label, idx) => (
        <React.Fragment key={idx}>
          <div style={{ padding: '0 8px' }}>
            <AmbGrid>
              <span style={{ paddingRight: 8 }}>
                <Stepper
                  style={{ color: '#1EC0D9', display: 'block' }}
                  text={idx + 1}
                  textStyle={{ fill: '#fff', font: 'inherit', fontSize: '0.75rem' }}
                />
              </span>
              <span style={{ width: '100%' }}>
                <span style={getLabelStyle(idx)}>{label}</span>
              </span>
            </AmbGrid>
          </div>
          {idx !== steps.length - 1 && (
            <div style={{ flex: '1 1 auto' }}>
              <span
                style={{ display: 'block', borderColor: '#bdbdbd', borderTopStyle: 'solid', borderTopWidth: 1 }}
              ></span>
            </div>
          )}
        </React.Fragment>
      ))}
    </AmbGrid>
  );
};

const Questionnaire = props => {
  const [state, dispatch] = useSection(props);

  const handleReset = () => {
    const { activeStep, ...rest } = initialState;
    dispatch({ type: 'RESET', payload: rest });
  };

  const handleNext = () => {
    const { expandPayer, status, payer, expandServiceLevelSection } = state;
    if (status !== 'Continue') {
      handleReset();
      props.finished(status, props.request);
    } else {
      dispatch({ type: 'SET_ACTIVE_STEP', activeStep: state.nextStep });
    }
    if (expandPayer) {
      props.setExpandPayer(expandPayer);
    }
    if (expandServiceLevelSection) {
      props.setExpandServiceLevelSection(expandServiceLevelSection);
    }
    if (payer) {
      props.populatePayer(payer);
      dispatch({ type: 'SET_PAYER', payload: null });
    }
  };

  const handleBack = () => {
    const { hasMedicare, activeStep } = state;
    const step = hasMedicare === 'Yes' && activeStep === 2 ? 1 : 0;
    dispatch({
      type: 'SET_ACTIVE_STEP',
      activeStep: step,
    });
  };

  const handleCancel = () => {
    handleReset();
    props.handleCancel();
  };

  const steps = getSteps();
  const { activeStep, canProceed } = state;

  return (
    <>
      <StepperComponent activeStep={activeStep} steps={steps} />
      <div>
        <div style={{ marginTop: theme.spacing.unit, marginBottom: theme.spacing.unit }}>
          {getStepContent(activeStep, props, state, dispatch)}
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <StyledButton disabled={activeStep === 0 || null} onClick={handleBack} style={{ marginRight: 'auto' }}>
            Back
          </StyledButton>
          <StyledButton onClick={handleCancel}>Cancel</StyledButton>
          <StyledButton disabled={!canProceed.has(activeStep) || null} onClick={handleNext}>
            {state.status}
          </StyledButton>
        </div>
      </div>
    </>
  );
};

Questionnaire.propTypes = {
  request: PropTypes.shape().isRequired,
  handleCancel: PropTypes.func.isRequired,
  setExpandPayer: PropTypes.func.isRequired,
  setExpandServiceLevelSection: PropTypes.func.isRequired,
  finished: PropTypes.func.isRequired,
  populatePayer: PropTypes.func.isRequired,
  requestingAgencyPayer: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  pickupTime: PropTypes.string.isRequired,
};

export default Questionnaire;
