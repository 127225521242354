import _ from 'lodash';

import * as infoboxActions from '../../../redux/actions/infobox.actions';
import * as saveButtonActions from './saveButton.actions';

function handSubmitPatientInfoSuccess(state, dispatch) {
  if (!state.saveButton.saveConfirmationShown && state.saveButton.clicked) {
    dispatch(saveButtonActions.saveConfirmationShownChange(true));
  }
}

const clickSaveButtonMdl =
  ({ dispatch, getState }) =>
  next =>
  (action = {}) => {
    next(action);

    if (infoboxActions.updatePatientSuccess.match(action)) {
      if (!_.get(action, 'payload.error')) {
        handSubmitPatientInfoSuccess(getState(), dispatch);
      }
    }
  };

export default [clickSaveButtonMdl];
