import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Create from '../../Pages/Create';
import Edit from '../../Pages/Edit';
import CreateMobileHealth from '../../Pages/Create/CreateMobileHealth';

const RoutedForm = () => (
  <Switch>
    <Route exact path="/" component={Create} />
    <Route exact path="/transport" component={Create} />
    <Route exact path="/requests/:id/edit" component={Edit} />
    <Route exact path="/mobile-health" component={CreateMobileHealth} />
  </Switch>
);

export default RoutedForm;
