import React from 'react';
import PropTypes from 'prop-types';
import PersonnelAndVehicle from './PersonnelAndVehicle';
import UberSummary from './UberSummary';
import AmbulnzSummary from './AmbulnzSummary';
import usePersonnel from './usePersonnel.hook';
import Header from './Header';
import RequestCharges from './Charges.frame';

const SummaryFrame = props => {
  const { request, charges, showCharges } = props;
  const personnel = usePersonnel(request);

  return (
    <>
      {<Header personnel={personnel} />}
      {props.acceptedCar && <UberSummary {...props} />}
      {!props.acceptedCar && <AmbulnzSummary {...props} />}
      {personnel && (
        <PersonnelAndVehicle
          isCar={props.acceptedCar}
          displayDriverContact={props.acceptedCar && props.isActive}
          {...personnel}
        />
      )}
      {showCharges && (
        <RequestCharges requestBreakdown={charges.requestBreakdown} summary={charges.summary} requestId={request.id} />
      )}
    </>
  );
};

SummaryFrame.propTypes = {
  request: PropTypes.shape().isRequired,
  acceptedCar: PropTypes.bool.isRequired,
  showPersonnel: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  isCar: PropTypes.bool.isRequired,
  charges: PropTypes.shape(),
  showCharges: PropTypes.bool.isRequired,
};

SummaryFrame.defaultProps = {
  charges: null,
};

export default SummaryFrame;
