import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from './Header.frame';
import * as toastActions from '../../../redux/actions/toast.actions';
import { isCar, acceptedCarTrip } from '../../../flags/service.flag';
import { unitSummary } from '../summaryPresenter';

const HeaderContainer = props => <Header {...props} />;

HeaderContainer.propTypes = {
  scheduleState: PropTypes.string,
};

HeaderContainer.defaultProps = {
  scheduleState: '',
};

const countWarnings = ({ coverages }) =>
  Object.keys(coverages).reduce((acc, k) => coverages[k].warnings.length + acc, 0);

function mapStateToProps(state) {
  return {
    unitId: state.eta.unitId,
    request: state.requests.request,
    shareLink: state.requests.shareLink,
    isCar: isCar(state.service),
    unitSummary: unitSummary(state),
    unit: state.schedule.unit,
    acceptedCar: acceptedCarTrip(state),
    warnings: countWarnings(state.infobox),
  };
}

export default connect(mapStateToProps, { ...toastActions })(HeaderContainer);
