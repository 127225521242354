export const emsFacilityFilter = {
  include: [
    {
      relation: 'entity',
    },
    {
      relation: 'facilityLocations',
      scope: {
        include: {
          place: 'placeAddress',
        },
        where: {
          inactive: false,
        },
      },
    },
    {
      relation: 'emsFacilityDepartments',
      scope: {
        include: 'department',
      },
    },
  ],
};
