import { createAction } from '@reduxjs/toolkit';

export const setCallType = createAction('[Service] setCallType');
export const asyncAutoSaveCallType = createAction('[Service] asyncAutoSaveCallType');
export const asyncAutoSaveCallTypeSuccess = createAction('[Service] asyncAutoSaveCallTypeSuccess');
export const autoSaveTransportReasons = createAction('[Service] autoSaveTransportReasons');
export const autoSaveTransportReasonsSuccess = createAction('[Service] autoSaveTransportReasonsSuccess');
export const autoSaveTheraphistEquipmentIds = createAction('[Service] autoSaveTheraphistEquipmentIds');
export const autoSaveTheraphistEquipmentIdsSuccess = createAction('[Service] autoSaveTheraphistEquipmentIdsSuccess');
export const callTypeChange = createAction('[Service] callTypeChange');
export const fetchCallTypes = createAction('[Service] fetchCallTypes');
export const fetchCallTypesSuccess = createAction('[Service] fetchCallTypesSuccess');
export const fetchTransportReason = createAction('[Service] fetchTransportReason');
export const fetchAndDisableTransportReasonSuccess = createAction('[Service] fetchAndDisableTransportReasonSuccess');
export const fetchServiceTypes = createAction('[Service] fetchServiceTypes');
export const fetchPatientTypesSuccess = createAction('[Service] fetchPatientTypesSuccess');
export const fetchServiceTypesSuccess = createAction('[Service] fetchServiceTypesSuccess');
export const asyncAutoSaveLightsAndSirens = createAction('[Service] asyncAutoSaveLightsAndSirens');
export const bariatricChange = createAction('[Service] bariatricChange');
export const fetchChiefComplaints = createAction('[Service] fetchChiefComplaints');
export const loadRequestDetails = createAction('[Service] loadRequestDetails');
export const respiratoryTherapistChange = createAction('[Service] respiratoryTherapistChange');
export const rtOptionChange = createAction('[Service] rtOptionChange');
export const serviceTypeChange = createAction('[Service] serviceTypeChange');
export const toggleRequiredAttendant = createAction('[Service] toggleRequiredAttendant');
export const setQuestionMode = createAction('[Service] setQuestionMode');
export const setAnswer = createAction('[Service] setAnswer');
export const setAnswersForEmergency = createAction('[Service] setAnswersForEmergency');
export const clearAnswers = createAction('[Service] clearAnswers');
export const mobilityChange = createAction('[Service] mobilityChange');
export const patientTypeChange = createAction('[Service] patientTypeChange');
export const personnelChange = createAction('[Service] personnelChange');
export const equipmentChange = createAction('[Service] equipmentChange');
export const equipmentAsServiceTypeChange = createAction('[Service] equipmentAsServiceTypeChange');
export const fetchProviderCapabilites = createAction('[Service] fetchProviderCapabilites');
export const fetchProviderCapabilitesSuccess = createAction('[Service] fetchProviderCapabilitesSuccess');
export const preloadProviderCapabilities = createAction('[Service] preloadProviderCapabilities');
export const mapServiceTypeToPatientType = createAction('[Service] mapServiceTypeToPatientType');
export const setLockedProvider = createAction('[Service] setLockedProvider');
export const setRequirementWarning = createAction('[Service] setRequirementWarning');
export const forcedCapabilitiesChange = createAction('[Service] forcedCapabilitiesChange');
export const resetService = createAction('[Service] resetService');
export const setAgencyTransportReasons = createAction('[Service] setAgencyTransportReasons');
export const setTransportReasons = createAction('[Service] setTransportReasons');
export const setTheraphistEquipmentIds = createAction('[Service] setTheraphistEquipmentIds');
export const setTransportReasonsAndEquipmentIdsDictionary = createAction(
  '[Service] setTransportReasonsAndEquipmentIdsDictionary',
);
export const setTelehealthLink = createAction('[Service] setTelehealthLink');
