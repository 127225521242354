import schema from 'schm';
import computed from 'schm-computed';
import methods from 'schm-methods';

export const infoboxForm = schema({
  requesterFirstName: {
    type: String,
    required: true,
  },
  requesterLastName: {
    type: String,
    required: true,
  },
  requesterPhone: {
    type: String,
    required: true,
  },
  requesterExtension: {
    type: String,
  },
  patientFirstName: {
    type: String,
    required: true,
  },
  patientMiddleName: String,
  patientLastName: {
    type: String,
    required: true,
  },
  patientEmail: {
    type: String,
    required: true,
  },
  patientBirthday: {
    type: String,
    required: true,
  },
  patientSsn: {
    type: String,
    default: '',
  },
  puRoomNumber: {
    type: String,
  },
  doRoomNumber: {
    type: String,
  },
});

export const requester = schema({
  requesterFirstName: {
    type: String,
    required: true,
  },
  requesterLastName: {
    type: String,
    required: true,
  },
  requesterExtension: {
    type: String,
  },
  requesterPhone: {
    type: String,
    required: true,
  },
});

function checkPayerValid(values) {
  if (!values.hidePolicyNumber && !values.policyNumber) {
    throw new Error('policyNumber invalid');
  } else if (!values.hideAuthorizationNumber && !values.authorizationNumber) {
    throw new Error('authorizationNumber invalid');
  } else if (values.requiresMedicalNecessity && typeof values.isBedBound !== 'boolean') {
    throw new Error('isBedBound invalid');
  } else if (values.requiresMedicalNecessity && typeof values.requiresOxygen !== 'boolean') {
    throw new Error('requiresOxygen invalid');
  } else if (values.showMedicarePartB && typeof values.medicarePartB !== 'boolean') {
    throw new Error('medicarePartB invalid');
  }

  return true;
}

export const payer = schema(
  {
    authorizationNumber: String,
    hidePolicyNumber: Boolean,
    hideAuthorizationNumber: Boolean,
    isBedBound: Boolean,
    medicarePartB: Boolean,
    overrideAuthorizationFieldName: String,
    payerId: Number,
    payerName: String,
    policyActive: Boolean,
    policyActiveAt: String,
    policyActivatorId: Number,
    policyNumber: String,
    policyActivatorName: String,
    requiresMedicalNecessity: Boolean,
    requiresOxygen: Boolean,
    inactive: Boolean,
    typeId: Number,
  },
  computed({
    showPolicyNumber: values => values && values.payerId && !values.hidePolicyNumber,
    authFieldName: values =>
      values &&
      values.payerId &&
      !values.hideAuthorizationNumber &&
      (values.overrideAuthorizationFieldName || 'Authorization Number'),
    showMedicarePartB: values => values && values.typeId === 4,
  }),
  methods({
    validate: values => checkPayerValid(values),
  }),
);
