/* eslint-disable import/prefer-default-export */
import { pipe, from as from$ } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import PayerService from '../../../services/payer.service';
import * as infoboxActions from '../../actions/infobox.actions';
import { errorPipe } from '../epicsUtil';

export const searchPayer = pipe(
  switchMap(({ payload }) =>
    from$(PayerService.getPayers(payload.query, { hideExternalPayers: true })).pipe(
      map(payers =>
        infoboxActions.fetchPayersSuccess({
          payers,
          coordinationTypeId: payload.coordinationTypeId,
        }),
      ),
      errorPipe,
    ),
  ),
);
