import AmbGrid from 'components/common/AmbGrid';
import AmbSwitch from 'components/common/AmbSwitch';
import PropTypes from 'prop-types';
import React from 'react';
import AmbExpansionPanel from '../common/AmbExpansionPanel';
import Time from '../common/Time';
import TimeDateIcon from '../common/icons/WillCall';
import EtaType from './EtaType.js/EtaType';
import Picker from './TimeDatePicker';
import WillCall from './WillCall';
import { palette } from '../../theme';

class TimeDateFrame extends React.Component {
  state = {
    expanded: Boolean(this.props.expanded),
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.shouldCollapse) {
      this.setState({ expanded: false });
    }

    if (props.shouldExpand) {
      this.setState({ expanded: true });
    }
  }

  togglePanel = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    let format = 'MM/DD ';
    const timeFormat = window.ambulnzConfig.userSettings.ampm ? 'h:mm A z' : 'HH:mm z';
    format += !this.props.isNonTransport || !this.props.hideTimePicker ? timeFormat : '';

    const title = (
      <>
        {this.props.title}
        <span style={{ paddingLeft: 15 }} />
        {this.props.willCall || <Time datetime={this.props.requestedTime} format={format} />}
      </>
    );

    return (
      <AmbExpansionPanel
        icon={
          <TimeDateIcon
            style={{
              height: 16,
              width: 16,
              marginTop: 2,
              fill: palette.icon.primary,
            }}
          />
        }
        id="WillCallPanel"
        title={title}
        expanded={this.state.expanded}
        onTogglePanel={this.togglePanel}
      >
        <AmbGrid style={{ marginTop: 10, alignItems: 'baseline', gap: '2px', flexDirection: 'column' }}>
          <AmbGrid style={{ minWidth: '100%' }}>
            {!this.props.isNonTransport && <WillCall />}
            {this.props.dispalyEnforcePromisedWindow && (
              <label style={{ marginTop: 10 }}>
                <AmbSwitch
                  checked={this.props.enforcePromisedWindow}
                  id="EnforcePromisedWindowCheckbox"
                  onChange={() => this.props.setEnforcePromisedWindow(!this.props.enforcePromisedWindow)}
                />
                Choose Arrival Window
              </label>
            )}
          </AmbGrid>
          {this.props.isNonTransport && (
            <AmbGrid style={{ minWidth: '100%' }}>
              <label style={{ marginTop: 10 }}>
                Service Needed by Date{' '}
                <span
                  style={{
                    visibility:
                      !this.props.dispalyEnforcePromisedWindow || this.props.enforcePromisedWindow
                        ? 'visible'
                        : 'hidden',
                  }}
                >
                  and Time
                </span>
              </label>
            </AmbGrid>
          )}
          <AmbGrid style={{ gap: '12px', alignItems: 'center' }}>
            {!this.props.appointmentsEnabled && !this.props.isNonTransport && (
              <AmbGrid style={{ marginTop: 22 }}>
                <p style={{ margin: 0 }}>Requested Pickup:</p>
              </AmbGrid>
            )}
            {this.props.appointmentsEnabled && !this.props.isNonTransport && (
              <AmbGrid style={{ marginTop: 10 }}>
                <EtaType {...this.props} />
              </AmbGrid>
            )}
            <AmbGrid>
              <Picker
                {...this.props}
                disabled={this.props.disabled || (this.props.willCall && !this.props.loadedWillCall) || null}
                loadedWillCall={this.props.loadedWillCall}
                handleCloseNewETADialog={this.props.handleCloseNewETADialog}
                expanded={this.state.expanded}
                hideTimePicker={this.props.hideTimePicker}
              />
            </AmbGrid>
          </AmbGrid>
        </AmbGrid>
      </AmbExpansionPanel>
    );
  }
}

TimeDateFrame.propTypes = {
  handleCloseNewETADialog: PropTypes.func.isRequired,
  shouldCollapse: PropTypes.bool.isRequired,
  willCall: PropTypes.bool.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  requestedTime: PropTypes.string,
  loadedWillCall: PropTypes.bool.isRequired,
  hideTimePicker: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default TimeDateFrame;
