import { complete, error } from './duplicateRequest.actions';

export const completeMdl =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (complete.match(action)) {
      if (action.payload.error) {
        dispatch(error(action.payload.error));
      } else {
        window.open(`/requester/requests/${action.payload.id}/edit`);
      }
    }
  };

export const duplicateRequestMdl = [completeMdl];
