import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const MaskedPhoneInput = ({ inputRef, onFocus, value, ...props }) => {
  const handleFocus = e => {
    onFocus(e);

    e.stopPropagation();
    e.target.setSelectionRange(0, -1);
  };

  const handlePaste = e => {
    const pastedValue = e.clipboardData.getData('text');

    if (pastedValue.startsWith('+1')) {
      const trimmedValue = pastedValue.slice(2);
      e.target.value = trimmedValue;
    }

    e.target.value = pastedValue;
  };

  return (
    <MaskedInput
      {...props}
      ref={inputRef}
      mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      onFocus={handleFocus}
      onPaste={handlePaste}
      type="tel"
      value={value}
    />
  );
};

MaskedPhoneInput.propTypes = {
  inputRef: PropTypes.func,
};

export default MaskedPhoneInput;
