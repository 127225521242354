import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { palette } from '../../../theme';

const Label = styled.label`
  font-size: ${({ fontSize }) => fontSize || 13}px;
  font-weight: ${({ fontWeight }) => fontWeight || 800};
  margin-top: ${({ etaOnly }) => (etaOnly ? 0 : -6)}px;
  color: ${({ color }) => color || palette.grey.dark};
  text-transform: none;
`;

const Eta = styled.output`
  font-size: ${() => (window.ambulnzConfig.userSettings.ampm ? 14 : 18)}px;
  color: #004052;
  display: block;
  padding-bottom: 3px;
`;

function EtaLabel(props) {
  return (
    <>
      <Label className="eta-label__label" {...props}>
        {props.labelName}
      </Label>
      <Eta className="eta-label__eta">{props.eta}</Eta>
    </>
  );
}

EtaLabel.propTypes = {
  eta: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
};

export default EtaLabel;
