import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const SaveAndGetEta = styled.div`
  font-size: 14px;
  line-height: 1.2;
  padding: 0 5px 0 5px;
`;

function GetETALabel({ loadedWillCall, isNonTransport }) {
  return loadedWillCall ? (
    <div style={{ fontSize: 13 }}>ACTIVATE</div>
  ) : isNonTransport ? (
    <SaveAndGetEta>
      <div>GET Availability</div>
    </SaveAndGetEta>
  ) : (
    <SaveAndGetEta>
      <div>SAVE &</div>
      <div>GET ETA</div>
    </SaveAndGetEta>
  );
}

GetETALabel.propTypes = {
  loadedWillCall: PropTypes.bool.isRequired,
  isNonTransport: PropTypes.bool.isRequired,
};

export default GetETALabel;
