import { concat as concat$, of as of$ } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';
import { loseETA } from '../../components/ServiceLevel/CareProvidedAtReceivingFacility/actions';
import * as etaActions from '../actions/eta.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as requestsActions from '../actions/requests.actions';
import { unReserve } from './helpers/request.helper';

export const unReserveEpic = (action$, store) =>
  action$.pipe(
    filter(action => [loseETA.match, etaActions.unReserve.match].some(match => match(action))),
    filter(() => store.value.requests.request.isActive),
    switchMap(() => unReserve(store)),
    mergeMap(response =>
      concat$(
        of$(requestsActions.updateRequestSuccess(response)),
        of$(etaActions.resetETA()),
        of$(formFlowActions.rejectETASuccess()),
        of$(formFlowActions.setChangeRequiresNewETA(false)),
      ),
    ),
  );
