import * as AccountService from './account.service';

let singleton;

const KEYCLOAK_CLIENT_ID = 'requester';

class FHIRDataStorage {
  PATIENT_KEY = 'patient';

  DELIMITER = '--';

  SEPARATOR = '_';

  clientId = null;

  idpHint = null;

  iss = null;

  launch = null;

  patient = null;

  code = null;

  state = null;

  hostname = null;

  init(keycloakIdpHint) {
    this.parseURLParams();
    this.parseHostName();
    this.parseClientIdAndIdpHint(keycloakIdpHint);
    this.parseStateForData();
  }

  parseURLParams() {
    const urlParams = new URLSearchParams(document.location.hash.replace('#', '') || document.location.search);

    this.iss = urlParams.get('iss');
    this.launch = urlParams.get('launch');
    this.code = urlParams.get('code');
    this.patient = urlParams.get('patient');
    this.state = urlParams.get('state');
  }

  parseHostName() {
    this.hostname = document.location.hostname.split('.')[0];
  }

  parseClientIdAndIdpHint(keycloakIdpHint) {
    this.idpHint = keycloakIdpHint;
    this.clientId = KEYCLOAK_CLIENT_ID;
  }

  parseStateForData() {
    if (!this.state) {
      return;
    }

    const parsedState = this.parseStateIntoAnObject(this.state);

    this.patient = parsedState[this.PATIENT_KEY];
  }

  getState() {
    if (this.patient) {
      return this.parseObjectIntoAState({ [this.PATIENT_KEY]: this.patient });
    }

    return this.state;
  }

  parseStateIntoAnObject(state) {
    return state.split(this.DELIMITER).reduce((acc, e) => {
      const regexp = new RegExp(`^([a-z]+)${this.SEPARATOR}(.*)`);
      const match = e.match(regexp);

      if (!match) {
        return acc;
      }

      const [, key, data] = match;
      acc[key] = data;
      return acc;
    }, {});
  }

  parseObjectIntoAState(obj) {
    return Object.keys(obj)
      .map(key => `${key}${this.SEPARATOR}${obj[key]}`)
      .join(this.DELIMITER);
  }
}

export default class AuthService {
  dataStorage = new FHIRDataStorage();

  static build() {
    if (singleton) {
      return singleton;
    }

    singleton = new AuthService();

    return singleton;
  }

  async initialize() {
    const ssoConfig = await AccountService.getLoginMechanism();
    if (ssoConfig.type === 'provider') {
      window.location = '/dispatcher2/offers';
      return;
    }

    this.dataStorage.init(ssoConfig.idp_hint);

    const accountHostNames = await AccountService.getAccountHostNames(ssoConfig.account_id);
    const currentHostName = accountHostNames.find(hostname => hostname.name.includes(this.dataStorage.hostname));

    return {
      ssoConfig,
      patient: this.dataStorage.patient,
      hostname: currentHostName,
    };
  }
}
