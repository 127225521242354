import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 15 15" {...props}>
      <g>
        <g>
          <path style={{ fill: '#004051' }} d="M7.5,9C5.3,9,3.6,7,3.6,4.5S5.3,0,7.5,0s3.9,2,3.9,4.5S9.7,9,7.5,9z" />
        </g>
      </g>
      <path style={{ fill: '#FFFFFF' }} d="M7.5,8c-1.7,0-3-1.6-3-3.5S5.8,1,7.5,1s3,1.6,3,3.5S9.2,8,7.5,8z" />
      <g>
        <g>
          <path d="M7.5,7C6.3,7,5.3,5.9,5.3,4.5S6.3,2,7.5,2s2.2,1.1,2.2,2.5S8.7,7,7.5,7z" />
        </g>
      </g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path d="M1.3,14c0.1-2.1,2.2-5,6.2-5s6.1,2.9,6.3,5H1.3z" />
                <path
                  style={{ fill: '#004051' }}
                  d="M7.5,9c4.1,0,6.1,2.9,6.3,5H1.2C1.4,11.9,3.4,9,7.5,9 M7.5,8c-4.8,0-7.1,3.5-7.2,5.9L0.2,15h1.1h12.5h1.1
						  l-0.1-1.1C14.6,11.5,12.3,8,7.5,8L7.5,8z"
                />
              </g>
              <path
                style={{ fill: '#FFFFFF' }}
                d="M7.5,10c2.8,0,4.4,1.5,5,3h-10C3.1,11.5,4.7,10,7.5,10 M7.5,9c-4.1,0-6.1,2.9-6.3,5h12.5
					  C13.6,11.9,11.6,9,7.5,9L7.5,9z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
