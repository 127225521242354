import moment from 'moment';
import { createReducer } from 'redux-act';
import * as action from '../actions/formFlow.actions';
import { newETArequired, tripScheduled, cannotUpdateZollProviderTripDate } from '../../messages';

export const initialState = {
  etaAccepted: false,
  etaWasAccepted: false,
  submitInProgress: false,
  acceptETAInProgress: false,
  currentView: 'new',
  ETADialogOpen: false,
  ETADialogTitle: newETArequired.title,
  ETADialogBody: newETArequired.body,
  similarRequestDialogOpen: false,
  similarRequest: null,
  inProgressState: false,
  changeRequiresNewETA: false,
  dirty: false,
  autoSaveInProgress: false,
  loadedRequest: false,
  noAutoEtaRequest: false,
  maxDate: '',
  loadRequestInProgress: false,
  loadSimilarRequestsInProgress: false,
  scheduledMessage: tripScheduled,
  triggerValidation: false,
  triggerPatientValidation: false,
  saveMessage: '',
  saveClicked: false,
  showReloadMessage: false,
  zollDialogOpen: false,
  zollProviderDialogTitle: cannotUpdateZollProviderTripDate.title,
  zollProviderDialogBody: cannotUpdateZollProviderTripDate.body,
};

const formFlowReducer = createReducer(
  {
    [action.setChangeRequiresNewETA]: (state, payload) => ({
      ...state,
      changeRequiresNewETA: payload,
    }),
    [action.setETASuccess]: (state, payload) => ({
      ...state,
      etaAccepted: payload,
    }),
    [action.setETAwasAccepted]: (state, payload) => ({
      ...state,
      etaWasAccepted: payload,
    }),
    [action.setSubmitInProgress]: (state, payload) => ({
      ...state,
      submitInProgress: payload,
    }),
    [action.setAcceptETAInProgress]: (state, payload) => ({
      ...state,
      acceptETAInProgress: payload,
    }),
    [action.toggleInProgressState]: state => ({
      ...state,
      inProgressState: state.acceptETAInProgress || state.submitInProgress || state.loadSimilarRequestsInProgress,
    }),
    [action.loadRequestDetails]: (state, payload) => ({
      ...state,
      etaAccepted: payload.etaAccepted,
    }),
    [action.setCurrentView]: (state, payload) => ({
      ...state,
      currentView: payload,
    }),
    [action.openNewETADialog]: (state, payload) => ({
      ...state,
      ETADialogOpen: payload,
    }),
    [action.closeNewETADialog]: state => ({
      ...state,
      ETADialogOpen: false,
    }),
    [action.changeDialogContent]: (state, payload) => ({
      ...state,
      ETADialogTitle: payload.title,
      ETADialogBody: payload.body,
    }),
    [action.rejectETASuccess]: state => ({
      ...state,
      etaAccepted: false,
      acceptETAInProgress: false,
      inProgressState: false,
    }),
    [action.setDirty]: (state, payload) => ({
      ...state,
      dirty: payload,
    }),
    [action.autoSaveInProgress]: (state, payload) => ({
      ...state,
      autoSaveInProgress: payload,
    }),
    [action.setLoadedRequest]: (state, payload) => ({
      ...state,
      loadedRequest: payload,
    }),
    [action.setNoAutoEtaRequest]: (state, payload) => ({
      ...state,
      noAutoEtaRequest: payload,
    }),
    [action.setMaxDate]: (state, payload) => ({
      ...state,
      maxDate: moment().add(payload || 24, 'hours'),
      maxDateFormatted: moment()
        .add(payload || 24, 'hours')
        .format('MM/DD HH:mm'),
    }),
    [action.setLoadRequestInProgress]: (state, payload) => ({
      ...state,
      loadRequestInProgress: payload,
    }),
    [action.setScheduledMessage]: (state, payload) => ({
      ...state,
      scheduledMessage: payload,
    }),
    [action.setTriggerValidation]: (state, payload) => ({
      ...state,
      triggerValidation: payload,
    }),
    [action.setTriggerPatientValidation]: (state, payload) => ({
      ...state,
      triggerPatientValidation: payload,
    }),
    [action.setSaveMessage]: (state, payload) => ({
      ...state,
      saveMessage: payload,
    }),
    [action.setSaveClicked]: (state, payload) => ({
      ...state,
      saveClicked: payload,
    }),
    [action.setSimilarRequestFoundDialog]: (state, payload) => ({
      ...state,
      similarRequestDialogOpen: !!payload,
      similarRequest: payload,
    }),
    [action.setSimilarRequestFoundDialogOpen]: (state, payload) => ({
      ...state,
      similarRequestDialogOpen: payload,
    }),
    [action.openZollDialog]: (state, payload) => ({
      ...state,
      zollDialogOpen: payload,
    }),
    [action.closeZollDialog]: state => ({
      ...state,
      zollDialogOpen: false,
    }),
  },
  initialState,
);

export default formFlowReducer;
