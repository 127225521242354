import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import DriverContact from '../DriverContact/DriverContact.container';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const PersonnelAvatar = styled.img`
  && {
    background-color: #1ec0d9;
    margin: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    position: relative;
    overflow: hidden;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    object-fit: cover;
  }
`;

const TypeTitle = styled.p`
  && {
    font-size: 13px;
    color: #919799;
    min-height: 16px;
    margin: 0;
  }
`;

const Details = styled.p`
  && {
    font-size: 12px;
    margin: 0;
  }
`;

const Name = styled.p`
  && {
    font-size: 14px;
    margin: 0;
  }
`;

const PersonnelAndVehicle = props => {
  const { personnel, vehicle, isCar, displayDriverContact } = props;

  const handleImageError = ev => {
    ev.target.onerror = null;
    ev.target.src = ev.target.dataset.fallback;
  };

  return (
    <Container>
      {personnel
        .sort((a, b) => a.order - b.order)
        .map((p, i) => (
          <Container key={i}>
            <PersonnelAvatar
              src={p.image}
              imgProps={{
                onError: ev => {
                  if (ev.target.src !== p.fallbackImage) {
                    ev.target.src = p.fallbackImage;
                  }
                },
              }}
            />
            <DetailsSection>
              <TypeTitle>{p.type && p.type.toUpperCase()}</TypeTitle>
              <Name>{p.name}</Name>
              <Details>{!isCar && <span>{p.phone_number}</span>}</Details>
              {p.type === 'Driver' && <Details>{vehicle.details}</Details>}
              {displayDriverContact && <DriverContact />}
            </DetailsSection>
          </Container>
        ))}
    </Container>
  );
};

PersonnelAndVehicle.propTypes = {
  personnel: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  vehicle: PropTypes.shape().isRequired,
  displayDriverContact: PropTypes.bool.isRequired,
  isCar: PropTypes.bool.isRequired,
};

export default PersonnelAndVehicle;
