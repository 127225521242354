import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WillCall from './WillCall.frame';
import * as willCallActions from '../../../redux/actions/willCall.actions';
import * as formFlowActions from '../../../redux/actions/formFlow.actions';
import * as requestActions from '../../../redux/actions/requests.actions';
import { willCallDisabled } from '../../../flags/disabled.flag';

const WillCallContainer = props => {
  const [open, setOpen] = useState(false);

  const handleChange = event => {
    if (event.target.checked && props.request.isScheduled) {
      return setOpen(true);
    }

    props.setTriggerValidation(true);
    props.setRequestCanBeActivatedByPatient(false);
    props.setWillCall(Boolean(event.target.checked));
  };

  const handlePatientCanActivateChange = event => {
    props.asyncSetRequestCanBeActivatedByPatient(event.target.checked);
  };

  const handleClose = value => {
    if (value) {
      props.unReserveSetWillCall();
    }

    setOpen(false);
  };

  return (
    <WillCall
      handleChange={handleChange}
      handleClose={handleClose}
      handlePatientCanActivateChange={handlePatientCanActivateChange}
      open={open}
      {...props}
    />
  );
};

WillCallContainer.propTypes = {
  willCall: PropTypes.bool.isRequired,
  isCanRequestBeActivatedByPatientEnabled: PropTypes.bool.isRequired,
  unReserveSetWillCall: PropTypes.func.isRequired,
  setRequestCanBeActivatedByPatient: PropTypes.func.isRequired,
  asyncSetRequestCanBeActivatedByPatient: PropTypes.func.isRequired,
  setWillCall: PropTypes.func.isRequired,
  setTriggerValidation: PropTypes.func.isRequired,
  request: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  return {
    willCall: state.requests.willCall,
    request: state.requests.request,
    disabled: willCallDisabled(state),
    canRequestBeActivatedByPatient: state.requests.request.canRequestBeActivatedByPatient,
    isCanRequestBeActivatedByPatientEnabled: state.agency.selectedAgency
      ? state.agency.selectedAgency.canRequestBeActivatedByPatient
      : false,
  };
}
export default connect(mapStateToProps, {
  ...requestActions,
  ...willCallActions,
  ...formFlowActions,
})(WillCallContainer);
