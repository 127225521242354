import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 51.1 24" {...props}>
      <title>NICU-BLS</title>
      <path
        style={{ fill: '#1ec2d8' }}
        d="M2.08,0H36.9a3.41,3.41,0,0,1,2.25,1.21A3.65,3.65,0,0,1,40,3.47.48.48,0,0,1,39.5,4H38.11l6.5,8s2.77.87,4.07,1.13a1.66,1.66,0,0,1,1.39,1.38l.78,4H45.13a3.86,3.86,0,0,0-2.68-1.56A4,4,0,0,0,39.07,19H10.34S8.92,16.9,7.1,16.9A3.65,3.65,0,0,0,3.86,19H.43V1.48A1.43,1.43,0,0,1,2.08,0Z"
        transform="translate(0 0)"
      />
      <g style={{ opacity: 0.5 }}>
        <path
          style={{ fill: '#FFFFFF' }}
          d="M22.61.78a5.69,5.69,0,0,0-5.56,5.09,5.88,5.88,0,0,1,3.11,1.42v-1a2.38,2.38,0,0,1,.76-1.7,2.73,2.73,0,0,1,1.69-.66,2.48,2.48,0,0,1,2.45,2.64v8.58H22V12.28a5.8,5.8,0,0,0-5.84-5.75,5.87,5.87,0,0,0-5.84,5.84A5.79,5.79,0,0,0,16,18.22h2.07V15.11h-2a2.74,2.74,0,1,1,2.73-2.83v5.94h9.43V6.53A5.64,5.64,0,0,0,22.61.78"
          transform="translate(0 0)"
        />
      </g>
      <path
        style={{ fill: '#050505' }}
        d="M1,19H39.07l-.35,1.47H.61A.59.59,0,0,1,0,19.84v-.26A.59.59,0,0,1,.61,19H1Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#71ccdd' }}
        d="M39.59,20.45v1.38H3.72A2.93,2.93,0,0,1,1,20.45Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#050505' }} cx="7.1" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="7.1" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="7.1" cy="20.45" r="1.65" />
      <path
        style={{ fill: '#2b242d' }}
        d="M45.13,22.09h4.68c1.21,0,1.29-2.14,1.29-2.34s-.25-1.29-.25-1.29H45.13Z"
        transform="translate(0 0)"
      />
      <circle style={{ fill: '#5f5f61' }} cx="7.1" cy="20.45" r="1.21" />
      <circle style={{ fill: '#050505' }} cx="42.27" cy="20.45" r="3.55" />
      <circle style={{ fill: '#1b1c1c' }} cx="42.27" cy="20.45" r="2.95" />
      <circle style={{ fill: '#dcd9dc' }} cx="42.27" cy="20.45" r="1.65" />
      <circle style={{ fill: '#5f5f61' }} cx="42.27" cy="20.45" r="1.21" />
      <path
        style={{ fill: '#ebebed' }}
        d="M35.78,13.35h0a.23.23,0,0,0-.26-.26h-.87a.23.23,0,0,0-.26.26h0a.23.23,0,0,0,.26.26h.87C35.69,13.61,35.86,13.52,35.78,13.35Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ebebed' }}
        d="M36.9,4.51a1.89,1.89,0,0,1,1.56.61l4.85,6.23L33.61,10V4.51Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#ededef' }}
        d="M48,12.92l.82,1.75,1.4.39-.11-.59a1.66,1.66,0,0,0-1.39-1.38Z"
        transform="translate(0 0)"
      />
      <rect style={{ fill: '#ededef' }} x="45.3" y="16.12" width="1.56" height="1.21" />
      <path
        d="M9.28,2.88a.17.17,0,0,1,.18-.16h.22l3.59,3.46h0V3a.17.17,0,0,1,.17-.17h1.08A.18.18,0,0,1,14.7,3V9a.17.17,0,0,1-.17.16h-.14l-.12,0L10.72,5.58h0V8.94a.18.18,0,0,1-.17.17H9.46a.18.18,0,0,1-.17-.17Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M9.68,2.72l3.59,3.46h0V3a.17.17,0,0,1,.17-.17h1.08A.18.18,0,0,1,14.7,3V9a.17.17,0,0,1-.17.16h-.14l-.12,0L10.72,5.58h0V8.94a.18.18,0,0,1-.17.17H9.46a.18.18,0,0,1-.17-.17V2.88a.17.17,0,0,1,.18-.16h.22m.1-.25H9.46A.42.42,0,0,0,9,2.88V8.94a.43.43,0,0,0,.42.42h1.08A.42.42,0,0,0,11,8.94V6.18l3.13,3.15a.49.49,0,0,0,.3.12h.14A.41.41,0,0,0,15,9V3a.42.42,0,0,0-.42-.42H13.45A.42.42,0,0,0,13,3V5.6L9.85,2.54l-.07-.07Z"
        transform="translate(0 0)"
      />
      <path
        d="M16.25,3a.18.18,0,0,1,.17-.17H17.5a.18.18,0,0,1,.17.17v6a.18.18,0,0,1-.17.17H16.42a.18.18,0,0,1-.17-.17Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M17.5,2.81a.18.18,0,0,1,.17.17v6a.18.18,0,0,1-.17.17H16.42a.18.18,0,0,1-.17-.17V3a.18.18,0,0,1,.17-.17H17.5m0-.25H16.42A.42.42,0,0,0,16,3v6a.42.42,0,0,0,.42.42H17.5a.42.42,0,0,0,.42-.42V3a.42.42,0,0,0-.42-.42Z"
        transform="translate(0 0)"
      />
      <path
        d="M22,2.72a3,3,0,0,1,2.18.84.17.17,0,0,1,0,.25l-.7.73a.17.17,0,0,1-.23,0,1.79,1.79,0,0,0-3,1.39,1.79,1.79,0,0,0,1.77,1.83,1.88,1.88,0,0,0,1.21-.43.16.16,0,0,1,.23,0l.71.74a.2.2,0,0,1,0,.25A3.11,3.11,0,0,1,22,9.2a3.24,3.24,0,1,1,0-6.48Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M22,2.72a3,3,0,0,1,2.18.84.17.17,0,0,1,0,.25l-.7.73a.21.21,0,0,1-.12,0,.16.16,0,0,1-.11,0,1.79,1.79,0,0,0-3,1.39,1.79,1.79,0,0,0,1.77,1.83,1.88,1.88,0,0,0,1.21-.43.16.16,0,0,1,.12,0,.14.14,0,0,1,.11,0l.71.74a.2.2,0,0,1,0,.25A3.11,3.11,0,0,1,22,9.2a3.24,3.24,0,1,1,0-6.48m0-.25a3.49,3.49,0,1,0,0,7,3.33,3.33,0,0,0,2.36-.94.46.46,0,0,0,.13-.31.38.38,0,0,0-.12-.29l-.69-.74a.4.4,0,0,0-.3-.13.39.39,0,0,0-.28.1,1.68,1.68,0,0,1-1.05.37,1.53,1.53,0,0,1-1.52-1.58,1.54,1.54,0,0,1,1.52-1.6,1.58,1.58,0,0,1,1,.39.4.4,0,0,0,.28.12.41.41,0,0,0,.29-.13L24.38,4a.42.42,0,0,0,.12-.3.41.41,0,0,0-.14-.31A3.28,3.28,0,0,0,22,2.47Z"
        transform="translate(0 0)"
      />
      <path
        d="M25.19,3a.18.18,0,0,1,.17-.17h1.1a.18.18,0,0,1,.18.17V6.63a1.09,1.09,0,0,0,1.1,1.13,1.11,1.11,0,0,0,1.13-1.13V3A.17.17,0,0,1,29,2.81h1.11a.18.18,0,0,1,.17.17V6.7a2.57,2.57,0,0,1-5.13,0Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M30.15,2.81a.18.18,0,0,1,.17.17V6.7a2.57,2.57,0,0,1-5.13,0V3a.18.18,0,0,1,.17-.17h1.1a.18.18,0,0,1,.18.17V6.63a1.09,1.09,0,0,0,1.1,1.13,1.11,1.11,0,0,0,1.13-1.13V3A.17.17,0,0,1,29,2.81h1.11m0-.25H29a.42.42,0,0,0-.42.42V6.63a.86.86,0,0,1-.88.88.84.84,0,0,1-.85-.88V3a.42.42,0,0,0-.43-.42h-1.1a.42.42,0,0,0-.42.42V6.7a2.82,2.82,0,0,0,5.63,0V3a.42.42,0,0,0-.42-.42Z"
        transform="translate(0 0)"
      />
      <path
        d="M13,11a.17.17,0,0,1,.17-.17h2.1a1.81,1.81,0,0,1,1.93,1.67,1.8,1.8,0,0,1-1,1.43,1.69,1.69,0,0,1,1.21,1.46,1.84,1.84,0,0,1-2,1.74H13.18a.17.17,0,0,1-.17-.17Zm2.11,2.39a.64.64,0,0,0,.63-.65.61.61,0,0,0-.63-.61h-.71v1.26Zm.11,2.45a.65.65,0,0,0,.65-.65.72.72,0,0,0-.74-.63h-.73v1.28Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M15.28,10.81a1.81,1.81,0,0,1,1.93,1.67,1.8,1.8,0,0,1-1,1.43,1.69,1.69,0,0,1,1.21,1.46,1.84,1.84,0,0,1-2,1.74H13.18a.17.17,0,0,1-.17-.17V11a.17.17,0,0,1,.17-.17h2.1m-.87,2.56h.71a.64.64,0,0,0,.63-.65.61.61,0,0,0-.63-.61h-.71v1.26m0,2.45h.82a.65.65,0,0,0,.65-.65.72.72,0,0,0-.74-.63h-.73v1.28m.87-5.26h-2.1a.42.42,0,0,0-.42.42v6a.42.42,0,0,0,.42.42h2.23a2.09,2.09,0,0,0,2.22-2,1.73,1.73,0,0,0-1-1.49,1.83,1.83,0,0,0,.79-1.4,2,2,0,0,0-2.18-1.92Zm-.62,1.8h.46a.36.36,0,0,1,.38.36.39.39,0,0,1-.38.4h-.46v-.76Zm0,2.43h.48c.22,0,.49.17.49.38a.4.4,0,0,1-.4.4h-.57v-.78Z"
        transform="translate(0 0)"
      />
      <path
        d="M18.52,11a.17.17,0,0,1,.17-.17h1.07a.18.18,0,0,1,.17.17v4.81h2.14a.17.17,0,0,1,.17.17v1a.17.17,0,0,1-.17.17H18.69a.17.17,0,0,1-.17-.17Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M19.76,10.81a.18.18,0,0,1,.17.17v4.81h2.14a.17.17,0,0,1,.17.17v1a.17.17,0,0,1-.17.17H18.69a.17.17,0,0,1-.17-.17V11a.17.17,0,0,1,.17-.17h1.07m0-.25H18.69a.42.42,0,0,0-.42.42v6a.42.42,0,0,0,.42.42h3.38a.42.42,0,0,0,.42-.42V16a.42.42,0,0,0-.42-.42H20.18V11a.42.42,0,0,0-.42-.42Z"
        transform="translate(0 0)"
      />
      <path
        d="M22.76,16.26l.42-.75a.24.24,0,0,1,.31-.06,2.6,2.6,0,0,0,1.27.5.57.57,0,0,0,.63-.54c0-.38-.32-.67-.93-.91-.78-.31-1.75-.91-1.75-2a1.87,1.87,0,0,1,2.08-1.79,3.41,3.41,0,0,1,1.93.67.22.22,0,0,1,.05.29l-.46.7c-.06.1-.22.18-.3.11A2.88,2.88,0,0,0,24.72,12a.53.53,0,0,0-.57.46c0,.33.27.59,1,.87S27,14.11,27,15.34a2,2,0,0,1-2.12,1.86,2.87,2.87,0,0,1-2-.69C22.71,16.42,22.68,16.38,22.76,16.26Z"
        transform="translate(0 0)"
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d="M24.79,10.72a3.41,3.41,0,0,1,1.93.67.22.22,0,0,1,.05.29l-.46.7a.33.33,0,0,1-.23.14.09.09,0,0,1-.07,0A2.88,2.88,0,0,0,24.72,12a.53.53,0,0,0-.57.46c0,.33.27.59,1,.87S27,14.11,27,15.34a2,2,0,0,1-2.12,1.86,2.87,2.87,0,0,1-2-.69c-.09-.09-.12-.13,0-.25l.42-.75a.19.19,0,0,1,.18-.09.25.25,0,0,1,.13,0,2.6,2.6,0,0,0,1.27.5.57.57,0,0,0,.63-.54c0-.38-.32-.67-.93-.91-.78-.31-1.75-.91-1.75-2a1.87,1.87,0,0,1,2.08-1.79m0-.25a2.12,2.12,0,0,0-2.33,2c0,1,.66,1.73,1.91,2.22.77.31.77.59.77.68s-.19.29-.38.29a2.16,2.16,0,0,1-1.1-.43h0l0,0a.46.46,0,0,0-.24-.06.45.45,0,0,0-.39.21l-.43.75a.41.41,0,0,0,.08.55,3.07,3.07,0,0,0,2.21.77,2.22,2.22,0,0,0,2.37-2.11c0-1.5-1.49-2.09-2-2.29-.73-.29-.83-.49-.83-.64s.13-.21.32-.21a2.41,2.41,0,0,1,1.08.46l.09.05a.28.28,0,0,0,.19.06.54.54,0,0,0,.44-.26l.46-.69a.47.47,0,0,0-.14-.64,3.48,3.48,0,0,0-2.05-.71Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
