import ApiService from './api.service';

export default {
  baseUrl: () => window.ambulnzConfig.tenantedRestApiUrl,

  async getOffers(requestIds) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Offers/search`;
    let response = await ApiService.post(url, {requestIds});
    response = await response.json();
    response = response.filter(offer => ['created', 'accepted'].includes(offer.offerStatus.name));
    return response
  },

  async createOffer(providerId, requestId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Requests/${requestId}/Offer`;
    let response = await ApiService.post(url, { providerId });
    response = response.json();

    return response;
  },

  async retractOffer(offerId) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/Offers/${offerId}/retract`;
    let response = await ApiService.post(url);
    response = response.json();

    return response;
  },
};
