/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { MarkerF, OverlayView, OverlayViewF } from '@react-google-maps/api';
import Done from '../common/icons/done';
import icon from '../common/icons/map-pin-dest.png';
import { timeFormat } from '../../helpers/dateFormatter';

const DestinationMarker = ({ dropoffTime, position, complete }) => {
  const image = {
    url: icon,
    size: new google.maps.Size(124, 83),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(62, 74),
  };

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: offsetWidth + labelAnchor.x,
      y: offsetHeight + labelAnchor.y,
    };
  };

  const time = (
    <div
      style={{
        width: 62,
        height: 41,
      }}
    >
      <p
        style={{
          color: '#fff',
          fontSize: window.ambulnzConfig.userSettings.ampm ? 11 : 13,
          fontWeight: 500,
          textAlign: 'center',
          margin: 0,
        }}
      >
        {dropoffTime ? timeFormat(dropoffTime) : 'Dropoff'}
      </p>
    </div>
  );

  const done = (
    <Done
      style={{
        fill: '#fff',
        width: 45,
        height: 45,
        position: 'relative',
        top: -14,
        left: 0,
      }}
    />
  );

  const content = complete ? done : time;

  return (
    <>
      <MarkerF key="m" position={position} icon={image}></MarkerF>
      <OverlayViewF
        key="mwl"
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(x, y) => getPixelPositionOffset(-(x / 2), -(y / 2), { x: 28, y: -38 })}
      >
        <div id="pickup-marker-overlay">{content}</div>
      </OverlayViewF>
    </>
  );
};

DestinationMarker.propTypes = {
  position: PropTypes.shape().isRequired,
  dropoffTime: PropTypes.string,
  complete: PropTypes.bool.isRequired,
};

DestinationMarker.defaultProps = {
  dropoffTime: null,
};

export default DestinationMarker;
