import { from as from$ } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import RequesterUpdateTypesService from '../../services/requesterUpdateTypes.service';
import * as pickupTimeChangeActions from '../actions/pickupTimeChange.actions';
import { errorPipe } from './epicsUtil';

export const getRequesterUpdateTypesEpic = action$ =>
  action$.pipe(
    filter(pickupTimeChangeActions.getRequesterUpdateTypes.match),
    switchMap(() =>
      from$(RequesterUpdateTypesService.requesterUpdateTypes()).pipe(
        map(payload => pickupTimeChangeActions.getRequesterUpdateTypesSuccess(payload)),
        errorPipe,
      ),
    ),
  );
