import { createAction } from '@reduxjs/toolkit';

export const setExpandServiceLevelSection = createAction('[MedicalNecessity] setExpandServiceLevelSection');

export const reducer = {
  [setExpandServiceLevelSection]: (state, payload) => ({
    ...state,
    expandServiceLevelSection: payload,
  }),
};
