import * as infoboxActions from '../../actions/infobox.actions';
import * as asyncActions from '../../actions/async.actions';
import * as toastActions from '../../actions/toast.actions';
import { parseRemoteComments } from '../../../parsers/comments.parser';

const asyncLoadCommentsSuccess =
  ({ dispatch }) =>
  next =>
  (action = {}) => {
    next(action);

    if (asyncActions.asyncLoadCommentsSuccess.match(action)) {
      const response = action.payload;
      if (!response) {
        return;
      }

      if (response.error) {
        dispatch(toastActions.onToast(response.error.message));
      } else {
        dispatch(infoboxActions.loadComments(parseRemoteComments(response.comment)));
      }
    }
  };

const middlewares = [asyncLoadCommentsSuccess];
export default middlewares;
