import PropTypes from 'prop-types';
import React from 'react';
import { createRoot } from 'react-dom/client';
import styled from '@emotion/styled';
import CloseIcon from '../../common/icons/close';

const IconButton = styled.button`
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  color: red;
  padding: 0;
  font-size: 1.5rem;
  text-align: center;
  position: fixed;
  right: 15px;
  top: 55px;
  width: 20px;
  height: 20px;
  z-index: 2;
`;

class EligibilityCloseFrame extends React.Component {
  constructor(props) {
    super(props);
    this.root = null;
  }

  componentDidMount() {
    this.closeButton = document.createElement('div');
    if (!this.root) {
      this.root = createRoot(this.closeButton);
    }
    document.body.appendChild(this.closeButton);
    this.renderButton(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.renderButton(this.props);
    }
  }

  componentWillUnmount() {
    if (!this.root) {
      this.root.unmount();
    }
    document.body.removeChild(this.closeButton);
  }

  click() {
    this.props.closeFrame();
  }

  renderButton(props) {
    let element = <div />;

    if (this.props.shouldShow()) {
      element = (
        <IconButton tabIndex="0" type="button" onClick={() => this.click()} size="large">
          <span style={{ width: '100%', display: 'flex', alignItems: 'inherit', justifyContent: 'inherit' }}>
            <CloseIcon style={{ color: '#21C0D9', width: '1em', height: '1em' }} />
          </span>
        </IconButton>
      );
    }

    this.root.render(element);
  }

  render() {
    return null;
  }
}

EligibilityCloseFrame.propTypes = {
  closeFrame: PropTypes.func.isRequired,
  shouldShow: PropTypes.func.isRequired,
  html: PropTypes.string.isRequired,
  portalInfo: PropTypes.string.isRequired,
};

export default EligibilityCloseFrame;
