import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WaitAndReturn from './WaitAndReturn.frame';
import * as requestsActions from '../../redux/actions/requests.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import { waitAndReturnCollapse } from '../../flags/collapse.flag';
import { isCrewMustWaitDisabled } from '../../flags/disabled.flag';
import { isCar } from '../../flags/service.flag';

const tripTitleExt = props => ` | Crew ${props.crewMustWaitForPatient ? 'Must' : 'Will Not'} Wait With Patient`;

const tripTitleDesc = props =>
  `Appointment Duration:
    ${props.hour || '0'} hr ${props.min || '00'} min`;

class WaitAndReturnContainer extends React.Component {
  state = {
    expanded: false,
  };

  UNSAFE_componentWillReceiveProps(props) {
    if (props.shouldCollapse) {
      this.setState({ expanded: false });
    }
  }

  handleWaitChange = e => {
    if (this.props.changeRequiresNewETA) {
      this.props.keyDownETAField();
      return;
    }

    this.props.setWillCallReturn(false);
    this.props.setReturnRequestCanBeActivatedByPatient(false);
    this.props.roundTripChange({ crewMustWaitForPatient: e.target.checked });
  };

  handleWillCallReturnChange = e => {
    this.props.setWillCallReturn(e.target.checked);
    let canReturnRequestBeActivated = false;

    if (e.target.checked) {
      canReturnRequestBeActivated = this.props.defaultValueForPatientActivation;
    }

    this.props.setReturnRequestCanBeActivatedByPatient(canReturnRequestBeActivated);
  };

  handlePatientActivationChange = e => {
    this.props.roundTripChange({ canRoundTripBeActivatedByPatient: e.target.checked });
  };

  handleHourChange = e => {
    if (this.props.changeRequiresNewETA) {
      this.props.keyDownETAField();
      return;
    }

    const { value } = e.target;
    const estimatedWaitForReturnMins = Math.floor(+value * 60 + this.props.min);
    this.props.roundTripChange({ estimatedWaitForReturnMins });
  };

  handleMinChange = e => {
    if (this.props.changeRequiresNewETA) {
      this.props.keyDownETAField();
      return;
    }

    const { value } = e.target;
    const estimatedWaitForReturnMins = Math.floor(this.props.hour * 60 + +value);
    this.props.roundTripChange({ estimatedWaitForReturnMins });
  };

  handleRoundTripChange = event => {
    if (this.props.changeRequiresNewETA) {
      this.props.keyDownETAField();
      return;
    }

    this.props.asyncRoundTripChange({
      isRoundTrip: event.target.checked,
      crewMustWaitForPatient: this.props.isCar ? false : event.target.checked,
    });
  };

  handleTogglePanel = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <WaitAndReturn
        panelTitle={this.props.isRoundTrip ? 'Round Trip' : 'One Way'}
        panelTitleExt={this.props.isRoundTrip ? tripTitleExt(this.props) : ''}
        panelTitleDesc={this.props.isRoundTrip ? tripTitleDesc(this.props) : ''}
        handleTogglePanel={this.handleTogglePanel}
        handleRoundTripChange={this.handleRoundTripChange}
        handleHourChange={this.handleHourChange}
        handleMinChange={this.handleMinChange}
        handleWaitChange={this.handleWaitChange}
        handleWillCallReturnChange={this.handleWillCallReturnChange}
        handlePatientActivationChange={this.handlePatientActivationChange}
        showWillCallReturn={this.props.willCallReturnEnabled && !this.props.request.id}
        isWaitTimeDisabled={this.props.isWillCallReturn && !this.props.request.id}
        {...this.props}
        {...this.state}
      />
    );
  }
}

WaitAndReturnContainer.propTypes = {
  isRoundTrip: PropTypes.bool.isRequired,
  shouldCollapse: PropTypes.bool.isRequired,
  keyDownETAField: PropTypes.func.isRequired,
  roundTripChange: PropTypes.func.isRequired,
  setWillCallReturn: PropTypes.func.isRequired,
  setReturnRequestCanBeActivatedByPatient: PropTypes.func.isRequired,
  changeRequiresNewETA: PropTypes.bool.isRequired,
  isCrewMustWaitDisabled: PropTypes.bool.isRequired,
  willCallReturnEnabled: PropTypes.bool.isRequired,
  isWillCallReturn: PropTypes.bool.isRequired,
  defaultValueForPatientActivation: PropTypes.bool.isRequired,
  isCar: PropTypes.bool.isRequired,
  request: PropTypes.shape().isRequired,
};

function mapStateToProps(state) {
  return {
    shouldCollapse: waitAndReturnCollapse(state),
    changeRequiresNewETA: state.formFlow.changeRequiresNewETA,
    hour: Math.floor(+state.requests.estimatedWaitForReturnMins / 60),
    min: +state.requests.estimatedWaitForReturnMins % 60,
    isRoundTrip: state.requests.isRoundTrip,
    crewMustWaitForPatient: state.requests.crewMustWaitForPatient,
    request: state.requests.request,
    isCrewMustWaitDisabled: isCrewMustWaitDisabled(state),
    isWillCallReturn: state.requests.request.isWillCallReturn,
    defaultValueForPatientActivation: state.agency.selectedAgency
      ? state.agency.selectedAgency.defaultValueForPatientActivation
      : false,
    canRequestBeActivatedByPatient: state.agency.selectedAgency
      ? state.agency.selectedAgency.canRequestBeActivatedByPatient
      : false,
    isCar: isCar(state.service),
  };
}

export default connect(mapStateToProps, {
  ...requestsActions,
  ...formFlowActions,
})(WaitAndReturnContainer);
