import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ErrorOutline from '../common/icons/errorOutline';
import AmbGrid from './AmbGrid';

const Container = styled.div`
  width: 100%;
  background-color: #fffaf7;
  border: 1px solid #f76427;
  border-radius: 3px;
  margin: 12px 0;
`;

const Message = styled(({ first, ...props }) => (
  <AmbGrid style={{ borderTop: first ? '' : '1px solid #fee5d2' }}>
    <ErrorOutline style={{ color: 'f77c3e', margin: '13px 6px' }} />
    <div {...props} />
  </AmbGrid>
))`
  && {
    width: 100%;
    color: #f77c3e;
  }
`;

function createMarkup(markup) {
  return { __html: markup };
}

const AmbWarning = ({ messages = [], ...props }) =>
  messages.length > 0 ? (
    <Container {...props}>
      {messages.map((message, index) => (
        <Message key={`${index}-${message}`} dangerouslySetInnerHTML={createMarkup(message)} first={index === 0} />
      ))}
    </Container>
  ) : null;

AmbWarning.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AmbWarning;
