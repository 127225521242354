import { of as of$, from as from$ } from 'rxjs';
import _ from 'lodash';

import RequestService from '../../services/request.service';
import { buildSchedulePayload } from '../../parsers/schedule.parser';
import { parseRequestValues } from '../../parsers/request.parser';
import * as RequestHelper from './helpers/request.helper';
import { isCar } from '../../flags/service.flag';
import { parseRooms } from '../../parsers/infobox.parser';

const multidayPlanner = state =>
  state.requests.isNonTransport && window.ambulnzConfig.userSettings.isMultidayTripPlanner;

const validForUpdateSchedule = (state, payload = {}) =>
  !isCar(state.service) &&
  state.requests.request.scheduleId &&
  (state.eta.requestedArrivalTime || payload.pickupTime) &&
  !state.requests.request.isCompleted &&
  !state.eta.scheduled;

export const createNotReserved = (state, payload) => {
  const { request } = state.requests;
  return !multidayPlanner(state) && !isCar(state.service)
    ? from$(RequestService.createNotReserved(request.id, payload || buildSchedulePayload(state)))
    : of$(request);
};

export const upsertSchedule = (state, payload) => {
  const { request } = state.requests;

  if (!state.requests.request.scheduleId) {
    return createNotReserved(state, payload);
  }

  if (validForUpdateSchedule(state, payload)) {
    return RequestService.updateSchedule(request.id, payload || buildSchedulePayload(state));
  }

  return of$({ id: state.requests.request.scheduleId });
};

export const needsScheduleRecalc = state => {
  const payload = parseRequestValues(state);
  const { request } = state.requests;

  if (!request.validForUpdateSchedule) {
    return false;
  }

  const changed = (from, to) => !_.isEqual(from, to);
  const pickupChanged = changed(_.get(request.pickupPlace, 'locationGps'), _.get(payload.pickupPlace, 'locationGps'));
  const destinationChanged = changed(
    _.get(request.destinationPlace, 'locationGps'),
    _.get(payload.destinationPlace, 'locationGps'),
  );

  if (pickupChanged || destinationChanged) {
    return true;
  }

  const timeChanged = changed(new Date(request.requestedArrivalTime).toISOString(), payload.requestedArrivalTime);
  if (request.needsAssignment && timeChanged) {
    return true;
  }

  return false;
};

export const updateScheduleIfValidForUpdate = store => {
  if (validForUpdateSchedule(store.value)) {
    return RequestHelper.updateSchedule(store);
  }

  return of$({});
};

export const updateRequest = (schedule, state, params) =>
  !schedule || !schedule.error
    ? RequestService.updateRequest(state.requests.request.id, params)
    : of$(state.requests.request);

export const unreserveIfNoAutoETA = state =>
  state.formFlow.noAutoEtaRequest && state.requests.request.isScheduled
    ? RequestService.deleteSchedule(state.requests.request.id)
    : of$({});

export const getRequestId = store => store.value.requests.request.id;

export const acceptEta = (store, etaId) =>
  store.value.formFlow.noAutoEtaRequest ? RequestHelper.updateSchedule(store) : RequestHelper.acceptEta(store, etaId);

export const submitRooms = store =>
  from$(RequestService.submit(getRequestId(store), { additionalRequestData: parseRooms(store.value) }));
