import { createAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { requestETASuccess } from '../../../redux/actions/eta.actions';
import attachmentApiService from '../../../services/attachmentApi.service';
import PCSFormService from '../../../services/pcs.service';

export const submitPCSForm = createAction('[PhysicianCertificationStatement] Submit PCS Form');
export const submitPCSFormSuccess = createAction('[PhysicianCertificationStatement] Submit PCS Form Success');
export const submitPCSFormFailure = createAction('[PhysicianCertificationStatement] Submit PCS Form Failure');
export const createPCSAttachment = createAction('[PhysicianCertificationStatement] Create PCS Attachment');
export const createPCSAttachmentSuccess = createAction(
  '[PhysicianCertificationStatement] Create PCS Attachment Success',
);
export const createPCSAttachmentFailure = createAction(
  '[PhysicianCertificationStatement] Create PCS Attachment Failure',
);

export const submitPCSFormEpic = (action$, store) =>
  action$.pipe(
    ofType(submitPCSForm.type),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      return from(PCSFormService.submitPCSForm(state.physicianCertificationStatement.formData)).pipe(
        map(blob => {
          if (!(blob instanceof Blob)) {
            throw new Error('Invalid response: expected a Blob');
          }
          return submitPCSFormSuccess(blob);
        }),
        catchError(error => {
          return of(submitPCSFormFailure(error.message));
        }),
      );
    }),
    catchError(error => {
      return of(submitPCSFormFailure(error.message));
    }),
  );

export const createPCSAttachmentEpic = (action$, store) =>
  action$.pipe(
    ofType(requestETASuccess.type),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const blob = state.physicianCertificationStatement.blob;
      const requestId = state.requests.request.id;
      const attachmentType = state.patientDocuments.attachmentTypes.find(type => type.name === 'PCS');

      if (!blob) {
        return of(createPCSAttachmentFailure('No blob data available'));
      }

      return from(attachmentApiService.createAttachmentFromBlob(requestId, blob, attachmentType.id, 'PCS.pdf')).pipe(
        map(response => createPCSAttachmentSuccess(response)),
        catchError(error => {
          return of(createPCSAttachmentFailure(error.message));
        }),
      );
    }),
  );

export const reducer = {
  [submitPCSForm]: (state, payload) => ({
    ...state,
    formData: payload,
  }),
  [submitPCSFormSuccess]: (state, payload) => ({
    ...state,
    blob: payload,
    submitSuccess: true,
    submitError: null,
  }),
  [submitPCSFormFailure]: (state, payload) => ({
    ...state,
    submitSuccess: false,
    submitError: payload,
  }),
  [createPCSAttachmentSuccess]: (state, payload) => ({
    ...state,
    attachmentCreated: true,
    attachmentError: null,
  }),
  [createPCSAttachmentFailure]: (state, payload) => ({
    ...state,
    attachmentCreated: false,
    attachmentError: payload,
  }),
};
