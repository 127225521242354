import { createAction } from '@reduxjs/toolkit';

export const queryPickupLocations = createAction('[Location] queryPickupLocations');
export const queryPickupLocationsByAddress = createAction('[Location] queryPickupLocationsByAddress');
export const queryPickupLocationsByGeopoint = createAction('[Location] queryPickupLocationsByGeopoint');
export const pickupPredictionsRetrieved = createAction('[Location] pickupPredictionsRetrieved');
export const pickupGeopointPredictionsRetrieved = createAction('[Location] pickupGeopointPredictionsRetrieved');
export const pickupFacilitiesRetrieved = createAction('[Location] pickupFacilitiesRetrieved');
export const selectPickupLocation = createAction('[Location] selectPickupLocation');
export const clearPickupLocation = createAction('[Location] clearPickupLocation');
export const setPickupAddress = createAction('[Location] setPickupAddress');
export const selectPickupLocationSuccess = createAction('[Location] selectPickupLocationSuccess');
export const setSearchPickupLocationInProgress = createAction('[Location] setSearchPickupLocationInProgress');
export const queryPickupFacilitiesOnly = createAction('[Location] queryPickupFacilitiesOnly');

export const queryDestinations = createAction('[Location] queryDestinations');
export const queryDestinationsByAddress = createAction('[Location] queryDestinationsByAddress');
export const queryDestinationLocationsByGeopoint = createAction('[Location] queryDestinationLocationsByGeopoint');
export const destinationsPredictionsRetrieved = createAction('[Location] destinationsPredictionsRetrieved');
export const destinationGeopointPredictionsRetrieved = createAction(
  '[Location] destinationGeopointPredictionsRetrieved',
);
export const destinationsFacilitiesRetrieved = createAction('[Location] destinationsFacilitiesRetrieved');
export const selectDestinationLocation = createAction('[Location] selectDestinationLocation');
export const clearDestinationLocation = createAction('[Location] clearDestinationLocation');
export const setDestinationAddress = createAction('[Location] setDestinationAddress');
export const selectDestinationLocationSuccess = createAction('[Location] selectDestinationLocationSuccess');
export const setSearchDestinationInProgress = createAction('[Location] setSearchDestinationInProgress');
export const queryDestinationFacilitiesOnly = createAction('[Location] queryDestinationFacilitiesOnly');

export const clearNothing = createAction('[Location] clearNothing');
export const clearSearchResults = createAction('[Location] clearNothing');

export const setAgencyPickupLocation = createAction('[Location] setAgencyPickupLocation');
export const setAgencyDestinationLocation = createAction('[Location] setAgencyDestinationLocation');
export const toggleERBound = createAction('[Location] toggleERBound');
export const setManualPickupTimeSecs = createAction('[Location] setManualPickupTimeSecs');
export const setManualDropoffTimeSecs = createAction('[Location] setManualDropoffTimeSecs');

export const loadRequestDetails = createAction('[Location] loadRequestDetails');
export const setRoomNumber = createAction('[Location] setRoomNumber');

export const setDestinationAutoFocus = createAction('[Location] setDestinationAutoFocus');

export const asyncAutoSaveRoomNumber = createAction('[Location] asyncAutoSaveRoomNumber');
export const asyncAutoSaveRoomNumberSuccess = createAction('[Location] asyncAutoSaveRoomNumberSuccess');

export const getPickupDepartments = createAction('[Location] getPickupDepartments');
export const getPickupDepartmentsSuccess = createAction('[Location] getPickupDepartmentsSuccess');
export const getDestinationDepartments = createAction('[Location] getDestinationDepartments');
export const getDestinationDepartmentsSuccess = createAction('[Location] getDestinationDepartmentsSuccess');

export const fetchPlaceTypes = createAction('[Location] fetchPlaceTypes');
export const fetchPlaceTypesSuccess = createAction('[Location] fetchPlaceTypesSuccess');

export const resetLocation = createAction('[Location] resetLocation');
export const resetDestination = createAction('[Location] resetDestination');
export const setDestinationAsPickup = createAction('[Location] setDestinationAsPickup');
