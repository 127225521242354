import { from as from$ } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { ETA_TYPES } from '../../../enums';
import { parseRequestValues } from '../../../parsers/request.parser';
import { buildSchedulePayload } from '../../../parsers/schedule.parser';
import * as etaActions from '../../../redux/actions/eta.actions';
import { errorPipe } from '../../../redux/epics/epicsUtil';
import { getRequestId, updateRequest, upsertSchedule } from '../../../redux/epics/updateRequestUtil';
import RequestService from '../../../services/request.service';

export const getETAUpdateRequestEpic = (action$, store) =>
  action$.pipe(
    filter(etaActions.requestETA.match),
    filter(() => store.value.eta.etaType === ETA_TYPES.PICKUP),
    filter(() => getRequestId(store) && !store.value.formFlow.etaWasAccepted),
    switchMap(() => from$(upsertSchedule(store.value))),
    switchMap(schedule =>
      from$(updateRequest(schedule, store.value, parseRequestValues(store.value, true))).pipe(
        map(request =>
          etaActions.asyncUpsertScheduleSuccess({
            request: { ...request, scheduleId: schedule.id },
            schedule,
          }),
        ),
        takeUntil(
          action$.pipe(
            filter(
              action =>
                etaActions.asyncUpsertScheduleSuccess.match(action) || etaActions.requestETATimeout.match(action),
            ),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const getAppmtAutoETAEpic = (action$, store) => {
  let requestedArrivalTime;
  return action$.pipe(
    filter(etaActions.requestETA.match),
    filter(() => store.value.eta.etaType === ETA_TYPES.APPOINTMENT),
    filter(() => getRequestId(store) && !store.value.formFlow.etaWasAccepted),
    switchMap(() => from$(RequestService.getRequestedArrivalTime(getRequestId(store), store.value))),
    switchMap(response => {
      requestedArrivalTime = response.estimatedArrivalTime;
      return from$(
        upsertSchedule(store.value, {
          ...buildSchedulePayload(store.value),
          ...{ pickupTime: requestedArrivalTime },
        }),
      );
    }),
    switchMap(schedule =>
      from$(
        updateRequest(schedule, store.value, {
          requestedArrivalTime,
          requestedAppointmentTime: store.value.eta.requestedAppointmentTime,
        }),
      ).pipe(
        map(request =>
          etaActions.asyncUpsertScheduleSuccess({
            request: { ...request, scheduleId: schedule.id },
            schedule,
          }),
        ),
        errorPipe,
      ),
    ),
  );
};
