import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MaskedPhoneInput from './MaskedPhoneInput';
import { palette } from '../../theme';

const PhoneLabel = styled.label`
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  font-weight: normal;
  white-space: nowrap;
  color: ${palette.grey.grey80};
`;

const FormControl = styled.div`
  font-size: 14px;
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  width: 100%;
`;

const StyledWrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  display: inline-flex;
  position: relative;
  font-size: 11px;
  width: 100%;

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: '';
    position: absolute;
    transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.42);
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: '';
    position: absolute;
    transform: ${props => (props.isFocused || props.isError ? 'scaleX(1)' : 'scaleX(0)')};
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: ${props => (props.isError ? '#f44336' : '#004053')};
  }
`;

const StyledInput = styled(MaskedPhoneInput)`
  && {
    font: inherit;
    font-size: 14px;
    color: ${props => (props.disabled ? palette.grey.silver : palette.text.primary)};
    border: 0;
    padding: 7px 0;
    display: block;
    min-width: 0;
    box-sizing: content-box;
    background: none;
    outline: none;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
  }
`;

const InputComponent = ({ error, onBlur, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleBlur = e => {
    if (onBlur) {
      onBlur(e);
    }
    setIsFocused(false);
  };

  return (
    <>
      <StyledWrapper isFocused={isFocused} isError={error}>
        <StyledInput onFocus={() => setIsFocused(true)} onBlur={handleBlur} {...props} />
      </StyledWrapper>
    </>
  );
};

const PhoneInput = ({ inputLabelProps, required, label, id, disabled, wrapperStyle, ...props }) => {
  return (
    <>
      <FormControl style={wrapperStyle}>
        <PhoneLabel htmlFor={id} style={{ color: disabled && palette.grey.silver, ...inputLabelProps.style }}>
          {label}
          {required ? ' *' : ''}
        </PhoneLabel>
        <InputComponent name="phone" id={id} disabled={disabled} {...props} />
      </FormControl>
    </>
  );
};

PhoneInput.propTypes = {
  inputLabelProps: PropTypes.shape(),
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
};

PhoneInput.defaultProps = {
  inputLabelProps: {},
  autoFocus: false,
  id: null,
};

export default PhoneInput;
