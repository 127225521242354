import { forkJoin, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { requestId as getRequestId } from '../../helpers/helpers';
import * as requestActions from '../../redux/actions/requests.actions';
import { errorPipe } from '../../redux/epics/epicsUtil';
import { getAttachments, getSignatures } from '../../redux/epics/helpers/request.helper';
import AttachmentApiService from '../../services/attachmentApi.service';
import * as actions from './redux/actions';

const createAttachment = (requestId, attachment, { user, patientDocuments: { signatureActorTypeId } }) =>
  requestId
    ? from$(AttachmentApiService.handleCreate(requestId, attachment, user.model.id, signatureActorTypeId))
    : of$(attachment);

const createAttachments = (requestId, attachments, { user, patientDocuments: { signatureActorTypeId } }) =>
  from$(AttachmentApiService.createAttachments(requestId, attachments, user.model.id, signatureActorTypeId));

const deleteSignature = requestSignatureId => from$(AttachmentApiService.deleteSignature(requestSignatureId));

const deleteAttachment = (requestAttachmentId, id) =>
  requestAttachmentId
    ? from$(AttachmentApiService.deleteAttachment(requestAttachmentId))
    : from$(AttachmentApiService.deleteResource(id));

const handleDeleteAttachment = ({ requestAttachmentId, requestSignatureId, id }) =>
  requestSignatureId ? deleteSignature(requestSignatureId) : deleteAttachment(requestAttachmentId, id);

export const deleteAttachmentEpic = (action$, store) =>
  action$.pipe(
    filter(actions.deleteAttachment.match),
    filter(() => getRequestId(store)),
    switchMap(action =>
      handleDeleteAttachment(action.payload).pipe(
        map(() => actions.removeAttachment(action.payload)),
        errorPipe,
      ),
    ),
  );

export const removeAttachmentEpic = (action$, store) =>
  action$.pipe(
    filter(actions.deleteAttachment.match),
    filter(() => !getRequestId(store)),
    map(action => actions.removeAttachment(action.payload)),
  );

export const autoSaveAttachmentEpic = (action$, store) =>
  action$.pipe(
    filter(actions.addAttachment.match),
    switchMap(action =>
      createAttachment(getRequestId(store), action.payload, store.value).pipe(
        map(payload => actions.addAttachmentSuccess(payload)),
        errorPipe,
      ),
    ),
  );

export const createAttachmentsEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [actions.createAttachments.match, requestActions.createRequestSuccess.match].some(match => match(action)),
    ),
    filter(() => getRequestId(store)),
    switchMap(() =>
      createAttachments(getRequestId(store), store.value.patientDocuments.attachments, store.value).pipe(
        map(payload => actions.loadAttachmentsSuccess(payload)),
        errorPipe,
      ),
    ),
  );

export const loadAttachmentTypes = (action$, store) =>
  action$.pipe(
    filter(actions.loadAttachmentTypes.match),
    switchMap(() =>
      from$(AttachmentApiService.getRequestAttachmentTypes()).pipe(
        map(payload => actions.loadAttachmentTypesSuccess(payload)),
        errorPipe,
      ),
    ),
  );

export const loadSignatureActorTypes = (action$, store) =>
  action$.pipe(
    filter(actions.loadAttachmentTypes.match),
    switchMap(() =>
      from$(AttachmentApiService.getRequestSignatureActorTypes()).pipe(
        map(payload => actions.loadSignatureActorTypesSuccess(payload)),
        errorPipe,
      ),
    ),
  );

export const loadAttachmentsEpic = (action$, store) =>
  action$.pipe(
    filter(actions.loadAttachments.match),
    mergeMap(() =>
      forkJoin(getAttachments(getRequestId(store)), getSignatures(getRequestId(store))).pipe(
        map(([a, b]) =>
          actions.loadAttachmentsSuccess([...a.Resources, ...b.map(c => AttachmentApiService.presentAsResource(c))]),
        ),
      ),
    ),
  );
