import { createAction } from '@reduxjs/toolkit';

export const autoSaveCoverages = createAction('[InfoBox] autoSaveCoverages');
export const autoSavePatient = createAction('[InfoBox] autoSavePatient');
export const changeValid = createAction('[InfoBox] changeValid');
export const clearPayer = createAction('[InfoBox] clearPayer');
export const checkPayerActive = createAction('[InfoBox] checkPayerActive');
export const setPayerActive = createAction('[InfoBox] setPayerActive');
export const initCoverage = createAction('[Eligibility] initCoverage');
export const fetchPayersSuccess = createAction('[InfoBox] fetchPayersSuccess');
export const loadComments = createAction('[InfoBox] loadComments');
export const loadRequestDetails = createAction('[InfoBox] loadRequestDetails');
export const queryPayers = createAction('[InfoBox] queryPayers');
export const setAttachment = createAction('[InfoBox] setAttachment');
export const setBirthday = createAction('[InfoBox] setBirthday');
export const setFocusRequesterFirstName = createAction('[InfoBox] setFocusRequesterFirstName');
export const setNotes = createAction('[InfoBox] setNotes');
export const setPatientDetails = createAction('[InfoBox] setPatientDetails');
export const setPatientInfo = createAction('[InfoBox] setPatientInfo');
export const setPayerInfo = createAction('[InfoBox] setPayerInfo');
export const autoSavePayerInfo = createAction('[InfoBox] autoSavePayerInfo');
export const setPhone = createAction('[InfoBox] setPhone');
export const setRequester = createAction('[InfoBox] setRequester');
export const setRequesterInfo = createAction('[InfoBox] setRequesterInfo');
export const autoSaveRequesterInfo = createAction('[InfoBox] autoSaveRequesterInfo');
export const setRequesterPhone = createAction('[InfoBox] setRequesterPhone');
export const setSaveErrorMessage = createAction('[InfoBox] setSaveErrorMessage');
export const setValid = createAction('[InfoBox] setValid');
export const submitPatientInfo = createAction('[InfoBox] submitPatientInfo');
export const setSubmitPatientInfoInProgress = createAction('[InfoBox] setSubmitPatientInfoInProgress');
export const submitPatientInfoSuccess = createAction('[InfoBox] submitPatientInfoSuccess');
export const updateCoverages = createAction('[InfoBox] updateCoverages');
export const updateCoveragesSuccess = createAction('[InfoBox] updateCoveragesSuccess');
export const updateNotes = createAction('[InfoBox] updateNotes');
export const updateNotesSuccess = createAction('[InfoBox] updateNotesSuccess');
export const updatePatient = createAction('[InfoBox] updatePatient');
export const selectPatient = createAction('[InfoBox] selectPatient');
export const updatePatientInfo = createAction('[InfoBox] updatePatientInfo');
export const updatePatientSuccess = createAction('[InfoBox] updatePatientSuccess');
export const updateRequester = createAction('[InfoBox] updateRequester');
export const updateRequesterSuccess = createAction('[InfoBox] updateRequesterSuccess');
export const billerVerifiedChange = createAction('[InfoBox] billerVerifiedChange');
export const asyncAutoSaveBillerVerified = createAction('[InfoBox] asyncAutoSaveBillerVerified');
export const asyncAutoSaveBillerVerifiedSuccess = createAction('[InfoBox] asyncAutoSaveBillerVerifiedSuccess');
export const reorderPayer = createAction('[InfoBox] reorderPayer');
export const selectPopulatedPayer = createAction('[InfoBox] selectPopulatedPayer');
export const populatedPayerResponse = createAction('[InfoBox] pulatedPayerResponse');
export const selectPayerFromSearch = createAction('[InfoBox] selectPayerFromSearch');
export const setRequesterDirty = createAction('[InfoBox] setRequesterDirty');
export const setCoveragesDirty = createAction('[InfoBox] setCoveragesDirty');
export const checkPayerRules = createAction('[InfoBox] checkPayerRules');
export const checkPayerRulesResponse = createAction('[InfoBox] checkPayerRulesResponse');
export const loadCoverages = createAction('[InfoBox] loadCoverages');
export const setLoadCoverageInProgress = createAction('[InfoBox] setLoadCoverageInProgress');
export const resetInfobox = createAction('[InfoBox] resetInfobox');
