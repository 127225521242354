import { concat as concat$, of as of$ } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import * as errorActions from '../actions/error.actions';
import * as formFlowActions from '../actions/formFlow.actions';
import * as infoboxActions from '../actions/infobox.actions';
import * as requestsActions from '../actions/requests.actions';
import { errorPipe } from './epicsUtil';
import { updateComments } from './helpers/comment.helper';
import {
  createRequest,
  getRequest,
  requestId,
  updateRequestWithoutRequestedArrivalTime,
} from './helpers/request.helper';

export const createPatientInfoEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.submitPatientInfo.match),
    filter(() => !store.value.requests.request.id),
    switchMap(() => createRequest(store)),
    mergeMap(request =>
      concat$(
        of$(requestsActions.createRequestSuccess(request)),
        of$(infoboxActions.submitPatientInfoSuccess(request.id)),
        of$(formFlowActions.setCurrentView('edit')),
        of$(infoboxActions.updatePatientInfo()),
        of$(infoboxActions.checkPayerRules()),
        of$(errorActions.onError(request)),
      ),
    ),
    errorPipe,
  );

export const selectPatientEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.submitPatientInfo.match),
    filter(() => !store.value.formFlow.loadRequestInProgress),
    filter(() => store.value.requests.request.id),
    filter(() => !store.value.formFlow.etaWasAccepted),
    switchMap(() =>
      updateRequestWithoutRequestedArrivalTime(store).pipe(
        mergeMap(request =>
          concat$(
            of$(requestsActions.updateRequestSuccess(request)),
            of$(infoboxActions.submitPatientInfoSuccess(request.id)),
            of$(formFlowActions.setCurrentView('edit')),
            of$(infoboxActions.updatePatientInfo()),
            of$(errorActions.onError(request)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const selectPatientETAAcceptedEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.submitPatientInfo.match),
    filter(() => store.value.formFlow.etaWasAccepted),
    switchMap(() => getRequest(store)),
    switchMap(request =>
      concat$(
        of$(requestsActions.updateRequestSuccess(request)),
        of$(infoboxActions.submitPatientInfoSuccess(request.id)),
        of$(formFlowActions.setCurrentView('edit')),
        of$(infoboxActions.updatePatientInfo()),
        of$(errorActions.onError(request)),
      ),
    ),
    errorPipe,
  );

export const autoSaveNotesEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.setNotes.match),
    filter(() => requestId(store)),
    debounceTime(2000),
    mergeMap(() => concat$(of$(formFlowActions.autoSaveInProgress(true)), of$(infoboxActions.updateNotes()))),
  );

export const updateNotesEpic = (action$, store) =>
  action$.pipe(
    filter(infoboxActions.updateNotes.match),
    switchMap(() => updateComments(store)),
    mergeMap(response => concat$(of$(infoboxActions.updateNotesSuccess()), of$(errorActions.onError(response)))),
  );

// We don't want to show a loading icon for this one.
export const updateCommentsEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        infoboxActions.updateRequesterSuccess.match,
        infoboxActions.updatePatientSuccess.match,
        infoboxActions.updateCoveragesSuccess.match,
        infoboxActions.submitPatientInfoSuccess.match,
      ].some(match => match(action)),
    ),
    switchMap(() => updateComments(store)),
    map(response => errorActions.onError(response)),
  );
